import React from 'react';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
const getAdditionalQuestionsDataForJobRole = (
  AdditionalQuestionsScores,
  AdditionQuestionIds
) => {
  AdditionQuestionIds = AdditionQuestionIds.filter((x) => x.Value > 0);
  var data = Object.entries(AdditionalQuestionsScores);
  if (data?.length > 0)
    return (
      <div className='section'>
        <div className='job-role-container'>
          <div className='stats-title-cell'> JOB ROLE</div>

          {AdditionQuestionIds?.map((item) => (
            <div className='stats-role-cell'>Q: {item.Order}</div>
          ))}
        </div>

        {data?.map((item) => {
          let score = item[1];
          return (
            <div className='job-role-container'>
              <div className='job-role-title-cell'>{item[0]}</div>

              {score?.map((score) => {
                return <div className='job-role-cell'>{score.toFixed(2)}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
};
const QuestionScorebyJobRole = (props) => {
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>{props.title}</h1>
          <p className='section'>
            Using heatmaps in your MAT Dashboard you can apply further filters
            to identify where scores may be higher and lower.
          </p>
          {props?.HasAdditionalQuestion ? (
            <div id='add-questions'>
              <p className='table-title'>
                Table 5: Your Question Scores by Job Role
              </p>

              {getAdditionalQuestionsDataForJobRole(
                props?.AdditionalQuestionsScores,
                props?.AdditionQuestionIds
              )}
            </div>
          ) : null}
          <p className='section-bottom'>
            * Heatmaps of all filtered scores for your question can be viewed
            and downloaded from your MAT dashboard.
          </p>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};
export default QuestionScorebyJobRole;
