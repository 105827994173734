import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function BackdropLoader(props) {
  useEffect(() => {
    IsShowMessage();
  }, []);

  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isShow);
  const [isShowMessage, setIsShowMessage] = React.useState(props.isShow);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const IsShowMessage = async () => {
    await delay(3000);
    setIsShowMessage('Your information will be displayed shortly…');
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Backdrop className={classes.backdrop} open={open}>
        <div style={{}}>
          <CircularProgress color='inherit' />

          <Typography>{isShowMessage}</Typography>
        </div>
      </Backdrop>
    </div>
  );
}
