import React from 'react';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
const getHeatmapCell = (level, value, isDecile, preVal, preDecile = null) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {isDecile ? (
        <>
          {level}
          <sup>
            {level === 1
              ? 'st'
              : level === 2
              ? 'nd'
              : level === 3
              ? 'rd'
              : 'th'}
          </sup>{' '}
          {preDecile ? (
            <span className='score-superscript'>
              ({preDecile}
              <sup>
                {preDecile === 1
                  ? 'st'
                  : preDecile === 2
                  ? 'nd'
                  : preDecile === 3
                  ? 'rd'
                  : 'th'}
              </sup>
              )
            </span>
          ) : (
            ''
          )}
        </>
      ) : preVal ? (
        <>
          {value.toFixed(2)}{' '}
          <span className='score-superscript'> ({preVal.toFixed(2)})</span>
        </>
      ) : (
        value.toFixed(2)
      )}
    </div>
  );
};
const AverageScoreAllParticipants = (props) => {
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='pres-title'>{props.title}</h1>
          <p className='pres-text'>
            Heatmap 1 scores are out of 5, with higher scores showing that risks
            relating to workplace stress and poor wellbeing are more effectively
            managed. The school decile shows your performance against the school
            benchmark, with 1st Decile being in the top 10% of schools taking
            the survey with us, and 10th Decile meaning more than 90% of staff
            in other schools have scored more highly. The HSE Decile shows your
            comparative scores against the Health and Safety Executive's
            Benchmark of 136 cross sector organisations.
          </p>

          <p className='pres-text'>
            {`Comparatively high scores (3rd school decile and higher) are shaded green and comparatively lower ones (7th school decile and lower) red. Those without shading are close to the average score for schools.`}
          </p>
          <p className='table-title'>
            Heatmap 1: Average Score for All Survey Participants
          </p>
          <div className='section'>
            <div className='job-role-container'>
              <div className='stats-title-cell'>Management Standards</div>
              <div className='stats-role-cell'>Score</div>
              <div className='stats-role-cell'>School Decile</div>
              <div className='stats-role-cell'>HSE Decile</div>
            </div>
            <div className='job-role-container'>
              <div className='job-role-title-cell'>Demand</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.DemandSDecile,
                props?.AvgScoreAll?.Demand,
                false,
                props?.PreviousAvgScoreAll?.Demand,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.DemandSDecile,
                props?.AvgScoreAll?.Demand,
                true,
                null,
                props?.PreviousAvgScoreAll?.DemandSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.DemandHSCDecile,
                props?.AvgScoreAll?.DemandHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.DemandHSCDecile
              )}
            </div>
            <div className='job-role-container'>
              <div className='job-role-title-cell'>Change</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.ChangeSDecile,
                props?.AvgScoreAll?.Change,
                false,
                props?.PreviousAvgScoreAll?.Change,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ChangeSDecile,
                props?.AvgScoreAll?.Change,
                true,
                null,
                props?.PreviousAvgScoreAll?.ChangeSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ChangeHSCDecile,
                props?.AvgScoreAll?.ChangeHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.ChangeHSCDecile
              )}
            </div>

            <div className='job-role-container'>
              <div className='job-role-title-cell'>Peer Support</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.PeerSupportSDecile,
                props?.AvgScoreAll?.PeerSupport,
                false,
                props?.PreviousAvgScoreAll?.PeerSupport,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.PeerSupportSDecile,
                props?.AvgScoreAll?.PeerSupport,
                true,
                null,
                props?.PreviousAvgScoreAll?.PeerSupportSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.PeerSupportHSCDecile,
                props?.AvgScoreAll?.PeerSupportHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.PeerSupportHSCDecile
              )}
            </div>

            <div className='job-role-container'>
              <div className='job-role-title-cell'>Manager Support</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.ManagerSupportSDecile,
                props?.AvgScoreAll?.ManagerSupport,
                false,
                props?.PreviousAvgScoreAll?.ManagerSupport,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ManagerSupportSDecile,
                props?.AvgScoreAll?.ManagerSupport,
                true,
                null,
                props?.PreviousAvgScoreAll?.ManagerSupportSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ManagerSupportHSCDecile,
                props?.AvgScoreAll?.ManagerSupportHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.ManagerSupportHSCDecile
              )}
            </div>

            <div className='job-role-container'>
              <div className='job-role-title-cell'>Role</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.RoleSDecile,
                props?.AvgScoreAll?.Role,
                false,
                props?.PreviousAvgScoreAll?.Role,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.RoleSDecile,
                props?.AvgScoreAll?.Role,
                true,
                null,
                props?.PreviousAvgScoreAll?.RoleSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.RoleHSCDecile,
                props?.AvgScoreAll?.RoleHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.RoleHSCDecile
              )}
            </div>

            <div className='job-role-container'>
              <div className='job-role-title-cell'>Control</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.ControlSDecile,
                props?.AvgScoreAll?.Control,
                false,
                props?.PreviousAvgScoreAll?.Control,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ControlSDecile,
                props?.AvgScoreAll?.Control,
                true,
                null,
                props?.PreviousAvgScoreAll?.ControlSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.ControlHSCDecile,
                props?.AvgScoreAll?.ControlHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.ControlHSCDecile
              )}
            </div>
            <div className='job-role-container'>
              <div className='job-role-title-cell'>Relationships</div>
              {getHeatmapCell(
                props?.AvgScoreAll?.RelationshipsSDecile,
                props?.AvgScoreAll?.Relationships,
                false,
                props?.PreviousAvgScoreAll?.Relationships,
                null
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.RelationshipsSDecile,
                props?.AvgScoreAll?.Change,
                true,
                null,
                props?.PreviousAvgScoreAll?.RelationshipsSDecile
              )}
              {getHeatmapCell(
                props?.AvgScoreAll?.RelationshipsHSCDecile,
                props?.AvgScoreAll?.RelationshipsHSCDecile,
                true,
                null,
                props?.PreviousAvgScoreAll?.RelationshipsHSCDecile
              )}
            </div>
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default AverageScoreAllParticipants;
