import React from 'react';
import { useTranslation } from 'react-i18next';

const LearnReports = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        Click on <b>Report</b> and this takes you to a comprehensive review of
        each of the Management Standards. Here you will again find your score,
        together with a commentary, including highlighting your school strengths
        and possible areas for focus. These provide summary information of how
        you are performing against each of the Management Standards.
      </p>
      <p>
        The scores range from 1 (low) to 5 (desirable). In addition, your score
        will show how you benchmark against other schools and cross sector
        {t('organisations')}. This is important as not all scores are equal, as
        for example you may score highly in some areas, though the comparative
        score is in a low decile, and vice-versa. Without these benchmarks you
        may therefore incorrectly focus on areas where there is little prospect
        of improvement or ignore areas that need attention.
      </p>
      <p>
        Below these are your recommended actions and this is what schools told
        us they really wanted us to provide. To be guided to the action that is
        likely to make an impact for staff and the school. It is important to
        take into account the school context when reviewing the actions and you
        may also be undertaking some of them already. You may not have actions
        recommended in each area, depending on your scores.
      </p>
      <p>
        You can scroll down through your report to look at each Management
        Standard or use the menu on the left to jump to each one.
      </p>
      {/*<span className='report-pictures'>
        <img className='screenshots' src={results} alt='' />
        <img className='screenshots' src={suggestions} alt='' />
  </span>*/}

      <p>
        In addition to the six management standards, there is also a section
        presenting your scores on how well staff are able to utilise their
        skills and opportunity for career advancement.
      </p>
    </div>
  );
};

export default LearnReports;
