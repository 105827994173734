import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import { colors } from '../../../resources/theme';
import {
  ExtendedActionPlanMainWrapper,
  ExtendedSubContainer,
} from '../../account/AccountStyle';
import { RegisterWrapper } from '../../../resources/styling/appStyle';
import { AssistanceWrapper } from '../../../components/common/ActionPlanStyle';
import { DirectorLeftMenu, MainMATHeader } from '../../../components';
import { HeadTeacherAccess, WebStorageNames } from '../../../utils';
import { matGroupService, storageService } from '../../../services';
import MatAccountForm from './MatAccountForm';
import MatAdminInfoForm from './MatAdminInfoForm';

const { outrageousOrange } = colors;

export default function MatAccount() {
  const [MatRecord, SetMatRecord] = useState('');
  const [LogoValue, SetLogoValue] = useState('');
  const [AccessLevel, SetAccessLevel] = useState(0);

  const handleUpdateData = (logoValue) => {
    SetLogoValue(logoValue);
  };

  useEffect(() => {
    const groupId = storageService.getItem(WebStorageNames.MatGroupId);
    matGroupService.getMatById(groupId).then((response) => {
      console.log('MatData', response.data);
      SetMatRecord(response.data);
    });
    SetAccessLevel(storageService.getItem(WebStorageNames.AccessLevel));
  }, []);

  return (
    <ExtendedActionPlanMainWrapper>
      <RegisterWrapper>
        <div className='containerClass'>
          <div className='left-container'>
            <DirectorLeftMenu logo={LogoValue} />
          </div>

          <div className='right-container'>
            <MainMATHeader />
            <Grid container>
              <Grid item md={12} xs={12}>
                <AssistanceWrapper>
                  <div className='action-heading-wrapper'>
                    {/* <span> {MatRecord ? MatRecord.Name : ''}</span> */}
                  </div>
                </AssistanceWrapper>
              </Grid>
              <Grid item md={12} xs={12} hidden>
                <ExtendedSubContainer>
                  <div className='search-inner-sub-container'>
                    <h2>Licence Information</h2>
                    <Box className='license-information'>
                      <Grid item xs={12} lg={12} className='details'>
                        <CheckIcon
                          className='icon-check'
                          sx={{ color: green[500] }}
                        />
                        Your Account Renewal Date is{' '}
                        <span style={{ color: outrageousOrange }}>
                          {MatRecord !== null
                            ? new Date(
                                MatRecord?.school?.SchoolSubscription.EndDate
                              ).toDateString()
                            : ''}
                        </span>
                      </Grid>
                      <Grid item xs={12} lg={12} className='details'>
                        <CheckIcon
                          className='icon-check'
                          sx={{ color: green[500] }}
                        />
                        Your Licence runs from{' '}
                        <span style={{ color: outrageousOrange }}>
                          {MatRecord !== null
                            ? new Date(
                                MatRecord?.school?.SchoolSubscription.StartDate
                              ).toDateString() +
                              ' - ' +
                              new Date(
                                MatRecord?.school?.SchoolSubscription.EndDate
                              ).toDateString()
                            : ''}
                        </span>
                      </Grid>
                    </Box>
                  </div>
                </ExtendedSubContainer>
              </Grid>
              <Grid item md={12} xs={12}>
                <ExtendedSubContainer>
                  <div className='search-data-container'>
                    <div className='search-inner-container mx-auto'>
                      {AccessLevel === HeadTeacherAccess.Full && (
                        <div className='search-inner-sub-container'>
                          <MatAccountForm
                            MatRecord={MatRecord}
                            updateData={handleUpdateData}
                          />
                        </div>
                      )}
                      <MatAdminInfoForm MatRecord={MatRecord} />
                    </div>
                  </div>
                </ExtendedSubContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </RegisterWrapper>
    </ExtendedActionPlanMainWrapper>
  );
}
