import React from 'react';
import { Dropdown } from '..';
import { LatestSurveyDropdown } from '../../resources/styling/appStyle';

const SurveyVoiceDropDown = ({
  conversationsList,
  onChange,
  latestSurveyOption,
}) => {
  return (
    <div>
      <LatestSurveyDropdown>
        <div className='latest-survey'>
          <Dropdown
            name='LatestSurveyVoice'
            options={conversationsList}
            selectedOption={latestSurveyOption}
            onChange={onChange}
            value={latestSurveyOption}
          />
        </div>
      </LatestSurveyDropdown>
    </div>
  );
};

export default SurveyVoiceDropDown;
