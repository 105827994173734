import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';

const {
  whiteColor, teal
} = colors;

/*** Other Suggestion ***/
export const AssistanceWrapper = styled.div`
  width: 84%;
  left: 235px;
  position: absolute;
  top: 30px;
  z-index: 20;
  button {
    border: 0;
    background: ${teal};
    color: ${whiteColor};
    font-size: 14px;
    border-radius: 24px;
    padding: 14px 20px;
    font-weight: 900;
    font-family: Avenir !important;
    float: right;
    cursor: pointer;
    box-shadow: 3px 3px 12px rgba(0, 122, 122, 0.12);
  }
  ${media.md`
  width: 79%;
  `}
  ${media.lg`
  width: 80%;
  `}
  ${media.xl`
  width: 84%
  `}
`;
export const OtherSuggestionMainWrapper = styled.div`
 .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
.underline-title {
  text-decoration: underline !important;
  font-weight: 900;
    font-size: 20px;
}
  float: left;
  width: 100%;
  ${media.xs`
  position: relative;
    min-height: calc(100vh - 72px);
  `}
  ${media.md`
  position: relative;
    min-height: calc(100vh - 72px)
  `}
  ${media.lg`
  position: relative;
  min-height: calc(100vh - 72px);
  `}
  ${media.xl`
  position: relative;
  min-height: calc(100vh - 72px);
  `}
`;

const OtherSuggestionStyle = {
    AssistanceWrapper,
    OtherSuggestionMainWrapper  
}
export default OtherSuggestionStyle;