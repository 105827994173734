import React from 'react';
import { AppButton } from '../resources/styling'
import { storageService } from '../services';
import { WebStorageNames } from '../utils';
import { colors } from '../resources/theme/colors';
import { config } from '../utils/config';

const { mandy } = colors;


const PulseButton = ({ id, name, email, ismat }) => {
    // TODO: pulse url should be taken from config file once it is configured in trnsformtion file
    return (
        <AppButton
            color={mandy}
            onClick={() => {
                window.open(`https://pulse.welbee.co.uk/#/superadmin?name=${btoa(name)}&email=${btoa(email)}&id=${id}&ismat=${ismat}&access_token=${storageService.getItem(WebStorageNames.Token)}`, '_blank')

            }
            }
        >
            Pulse
        </AppButton>
    );
};

export default PulseButton;