import { BadgeSharp } from '@mui/icons-material';
import { config } from '../../utils/config';

const { BASE_URL } = config;

export const loginAPI = {
  api: `${BASE_URL}/Account/Login`,
};

export const errorLoggingAPI = {
  api: `${BASE_URL}/ExceptionLogger/LogError`,
};

export const surveyAPI = {
  api: `${BASE_URL}/Survey`,
  loadSurvey: (surveyToken) =>
    `${BASE_URL}/Survey/LoadSurveyQuestions/${surveyToken}`,
  submitSurvey: `${config.BASE_URL}/SurveySubmission`,
  getIndividualQuestionResults: (queryString) =>
    `${BASE_URL}/SurveyResult/GetIndividualQuestionResults/${queryString}`,
  getSchoolSurveys: (schoolId) =>
    `${BASE_URL}/Survey/GetSchoolSurveys?schoolId=${schoolId}`,
  getLatestSurvey: (schoolId) =>
    `${BASE_URL}/Survey/GetLatestSurvey?schoolId=${schoolId}`,
  getSchoolSurveysNameAndToken: (token) =>
    `${BASE_URL}/Survey/GetSurveyNameAndToken?token=${token}`,
  getSurveyNameAndTokenBySchool: (schoolId) =>
    `${BASE_URL}/Survey/GetSurveyNameAndTokenBySchool?schoolId=${schoolId}`,
  updateSurvey: `${BASE_URL}/Survey`,
  addSurveyComments: () => `${BASE_URL}/Survey/UpdateCategoryTextBySuperAdmin`,
  getSurveyName: (schoolId) =>
    `${BASE_URL}/Survey/GetSurveyName?schoolId=${schoolId}`,
  getNewMatSurveyName: (MatGroupId) =>
    `${BASE_URL}/Survey/GetMatSurveyName?MatGroupId=${MatGroupId}`,
  archiveSurvey: (surveyId) =>
    `${BASE_URL}/Survey/ArchiveSurvey?surveyId=${surveyId}`,
  checkAddtionalStaffInfo: (token) =>
    `${BASE_URL}/Survey/CheckAddtionalStaffInfo/${token}`,
  getSurveyByToken: (token) =>
    `${BASE_URL}/Survey/GetSurveyByToken?token=${token}`,
  hasSurveyStarted: (surveyToken) =>
    `${BASE_URL}/Survey/HasSurveyStarted?token=${surveyToken}`,
  IsSchoolSurveyLive: (schoolId) =>
    `${BASE_URL}/Survey/IsSchoolSurveyLive?schoolId=${schoolId}`,
  hasAddtionalQuestionFiltersIncluded: (schoolId) =>
    `${BASE_URL}/Survey/HasAddtionalQuestionFiltersIncluded?schoolId=${schoolId}`,
};

export const surveyResultAPI = {
  api: (surveyId) =>
    `${BASE_URL}/SurveyResult/GetSurveyResultByToken/?${surveyId}`,
  surveyResult: (surveyToken) =>
    `${BASE_URL}/SurveyResult/GetSurveyResultByToken/${surveyToken}`,
  headTeacherSurveyResult: (surveyToken) =>
    `${BASE_URL}/SurveyResult/GetHeadTeacherSurveyResultByToken/${surveyToken}`,
  surveyReport: (surveyToken) =>
    `${config.BASE_URL}/SurveyResult/GetSurveyReport/${surveyToken}`,
  GetSchoolSummaryReport: (surveyToken) =>
    `${config.BASE_URL}/SurveyResult/GetSchoolSummaryReport/${surveyToken}`,
  staffPresentationReport: (surveyToken) =>
    `${config.BASE_URL}/SurveyResult/GetStaffPresentationReport/${surveyToken}`,
  closeSurvey: (surveyId) => `${BASE_URL}/SurveyResult/CloseSurvey/${surveyId}`,
  closeSurveyWithToken: (surveyToken, schoolToken) =>
    `${BASE_URL}/SurveyResult/CloseSurvey?token=${surveyToken}&schoolToken=${schoolToken}`,
  reOpenSurvey: (surveyId) =>
    `${BASE_URL}/SurveyResult/ReOpenSurvey/${surveyId}`,
  GetPDFSurveyReport: (surveyToken, schoolToken) =>
    `${BASE_URL}/SurveyResult/GetPDFSurveyReport?token=${surveyToken}&schoolToken=${schoolToken}`,
  reSendSurveyInvitation: (surveyToken, schoolStaffId) =>
    `${BASE_URL}/SurveyResult/ReSendSurveyInvitation?surveyToken=${surveyToken}&schoolStaffId=${schoolStaffId}`,
  GetSchoolSummaryReportPdf: (surveyToken) =>
    `${config.BASE_URL}/SurveyResult/GetSchoolSummaryReportPdf/${surveyToken}`,
  checkSummaryReport: (schoolId) =>
    `${config.BASE_URL}/SurveyResult/CheckSummaryReport?schoolId=${schoolId}`,
};

export const surveyCommentsAPI = {
  api: (surveyToken, schoolToken) =>
    `${BASE_URL}/Survey/GetSurveyComments${surveyToken}&schoolToken=${schoolToken}`,
  setCommentAsImportant: `${BASE_URL}/SurveyComment/SetCommentAsImportant`,
  getCommentWithReplies: (id) =>
    `${BASE_URL}/SurveyComment/GetCommentWithReplies?id=${id}`,
  SubmitHeadTeacherCommentReply: `${BASE_URL}/CommentReply/SubmitHeadTeacherCommentReply`,
  SubmitStaffCommentReply: `${BASE_URL}/CommentReply/SubmitStaffCommentReply`,
  getStaffCommentWithReplies: (token) =>
    `${BASE_URL}/SurveyComment/GetStaffCommentWithReplies?token=${token}`,
  setCommentIsRead: (id) =>
    `${BASE_URL}/SurveyComment/SetCommentIsRead?id=${id}`,
  GetPDFSurveyComments: (surveyToken, schoolToken) =>
    `${BASE_URL}/Survey/GetPDFSurveyComments?token=${surveyToken}&schoolToken=${schoolToken}`,
  GetExcelSurveyComments: (surveyToken, schoolToken) =>
    `${BASE_URL}/Survey/GetExcelSurveyComments?token=${surveyToken}&schoolToken=${schoolToken}`,
  getAdditionalQuestionsForComments: (token) =>
    `${BASE_URL}/AdditionalSurveyQuestions/GetAdditionalQuestionsForComments?token=${token}`,
};

export const requestAssistanceAPI = {
  api: `${BASE_URL}/RequestAssistance`,
};

export const newSurveyAPI = {
  api: `${BASE_URL}/Survey`,
};
export const accountAPI = {
  api: `${BASE_URL}/Account/Login`,
  forgotPassword: `${BASE_URL}/Account/ForgotPassword`,
  resetPassword: `${BASE_URL}/Account/ResetPassword`,
  setPasswordForRegisteredSchool: (token, userId) =>
    `${BASE_URL}/Account/SetPasswordForRegisteredSchool?token=${token}&userId=${userId}`,
  RegisterSchoolStaff: `${BASE_URL}/Account/RegisterSchoolStaff`,
  IsRegistrationEnable: (token) =>
    `${BASE_URL}/Account/IsRegistrationEnable?token=${token}`,
  registerDirectorAccount: `${BASE_URL}/Account/RegisterDirectorAccount`,
  isDirectorRegistrationEnable: (token) =>
    `${BASE_URL}/Account/IsDirectorRegistrationEnable?token=${token}`,
  GetInCompleteRegistrationLink: (schoolId) =>
    `${BASE_URL}/Account/GetInCompleteRegistrationLink?schoolId=${schoolId}`,
  CheckCurrentRegion: () => `${BASE_URL}/Account/CheckCurrentRegion`,
};

export const surveyClosedAPI = {
  isSurveyClosed: (surveyToken) =>
    `${BASE_URL}/Survey/IsSurveyClosed/${surveyToken}`,
};

export const getSurveyIdAPI = {
  api: `${BASE_URL}/Survey/`,
};
export const schoolAPI = {
  api: `${BASE_URL}/School/`,
  getAllSchools: (schoolsWithLiveSurvey) =>
    `${BASE_URL}/School/GetAllSchools?schoolListingFlag=${schoolsWithLiveSurvey}`,
  getSchool: (queryString) =>
    `${BASE_URL}/School/GetSchoolByToken?token=${queryString}`,
  getSchoolDetailByToken: (queryString) =>
    `${BASE_URL}/School/GetSchoolNameByToken?token=${queryString}`,
  getSchoolDetail: (id, schoolToken) =>
    `${BASE_URL}/School/GetUserInfo?id=${id}&schoolToken=${schoolToken}`,
  getSchoolSubscriptions: (id) =>
    `${BASE_URL}/SchoolSubscription/GetSchoolSubscriptions?schoolId=${id}`,
  addSchoolSubscriptions: (schoolId, id) =>
    `${BASE_URL}/SchoolSubscription/AddSchoolSubscriptions?schoolId=${schoolId}&subscriptionId=${id}`,

  getInfo: (id) => `${BASE_URL}/School/GetInfo?id=${id}`,
  getSchoolDetailByRegistrationToken: (token) =>
    `${BASE_URL}/School/GetSchoolDetailByRegistrationToken?token=${token}`,
  updateSchoolDetail: `${BASE_URL}/School/`,
  UpdateSchoolOnly: `${BASE_URL}/School/UpdateSchoolOnly`,
  updateSchoolDetailByHeadTeacher: `${BASE_URL}/School/UpdateSchoolDetailsByHeadTeacher`,
  updateSchoolAdminInfoByHeadTeacher: `${BASE_URL}/School/UpdateSchoolAdminInfoByHeadTeacher`,
  archiveSchool: (schoolId) =>
    `${BASE_URL}/School/ArchiveSchool?schoolId=${schoolId}`,
  updateSchoolInvoiceInfo: `${BASE_URL}/School/UpdateSchoolInvoiceInfo`,
  SendUpgradeInvoice: `${BASE_URL}/School/SendUpgradeInvoice`,
  updateSchoolWelbeeVoice: `${BASE_URL}/School/UpdateSchoolWelbeeVoice`,
  getAddressSuggestions: (keyword) =>
    `${BASE_URL}/Account/GetAddressSuggestions?keyword=${keyword}`,
  getSchoolStaff: (schoolId, schoolToken) =>
    `${BASE_URL}/SchoolStaff/GetSchoolStaff?schoolId=${schoolId}&schoolToken=${schoolToken}`,
  deleteSchoolStaff: (id) => `${BASE_URL}/SchoolStaff/${id}`,
  updateSchoolStaff: `${BASE_URL}/SchoolStaff/UpdateSchoolStaff`,
  addHeadTeacherDetail: `${BASE_URL}/School/AddHeadTeacher`,
  getSchoolDetailBySuperAdmin: (queryString) =>
    `${BASE_URL}/School/GetSchoolDetailBySuperAdmin?id=${queryString}`,
  getNumberOfPupil: (id, token) =>
    `${BASE_URL}/School/GetNumberOfPupil?schoolId=${id}&token=${token}`,
  hideUpgradeBanner: (id) =>
    `${BASE_URL}/School/HideUpgradeBanner?schoolId=${id}`,
  getFreePeriodEnd: (id) => `${BASE_URL}/School/GetFreePeriodEnd?id=${id}`,
  getMatSchools: (groupId) =>
    `${BASE_URL}/School/GetMatSchools?groupId=${groupId}`,
  GetSchoolsForMat: `${BASE_URL}/School/GetSchoolsForMat`,
  GetSchoolsForMatWithCloseSurvey: `${BASE_URL}/School/GetSchoolsForMatWithCloseSurvey`,
  removeSchoolFromGroup: (schoolId, groupId) =>
    `${BASE_URL}/School/AddRemoveSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${true}`,
  addSchoolInGroup: (schoolId, groupId) =>
    `${BASE_URL}/School/AddRemoveSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${false}`,
  sendRegistrationEmail: () => `${BASE_URL}/Account/SendConfirmationEmail`,
  VerifyEmailConfirmationToken: (userId) =>
    `${BASE_URL}/Account/VerifyEmailConfirmationToken?userId=${userId}`,
  GetOutstandingInvoices: () => `${BASE_URL}/invoice/GetOutstandingInvoices`,
  SendInvoices: () => `${BASE_URL}/invoice/SendInvoices`,
  hasAdditionalSurvey: (id) =>
    `${BASE_URL}/SchoolSubscription/HasAdditionalSurvey?schoolId=${id}`,
  generateInvoiceForAdditionalSurveyBuy: (schoolId) =>
    `${BASE_URL}/invoice/generateInvoiceForAdditionalSurveyBuy?schoolId=${schoolId}`,
  GetAllSchoolsForSubscription: () =>
    `${BASE_URL}/School/GetAllSchoolsForSubscription`,
  UpdateSubscription: () => `${BASE_URL}/SchoolSubscription/UpdateSubscription`,
  downLoadSchoolSubscription: (
    createdOnSort,
    StartDateSort,
    endDateSort,
    name,
    archivedOnly,
    testAccountOnly
  ) =>
    `${BASE_URL}/SchoolSubscription/DownloadSchoolSubscriptions?createdOnSort=${createdOnSort}&StartDateSort=${StartDateSort}&endDateSort=${endDateSort}&name=${name}&archivedOnly=${archivedOnly}&testAccountOnly=${testAccountOnly}`,
  SendInvoices: () => `${BASE_URL}/invoice/SendInvoices`,
  hasAdditionalSurvey: (id) =>
    `${BASE_URL}/SchoolSubscription/HasAdditionalSurvey?schoolId=${id}`,
  GetOutstandingInvoices: () => `${BASE_URL}/invoice/GetOutstandingInvoices`,
  generateInvoiceForAdditionalSurveyBuy: (schoolId) =>
    `${BASE_URL}/invoice/generateInvoiceForAdditionalSurveyBuy?schoolId=${schoolId}`,
  getSentEmails: () => `${BASE_URL}/SuperAdmin/GetSentEmails`,
  downloadSESEmails: (name, from) =>
    `${BASE_URL}/SuperAdmin/DownloadSEStEmails?NameFilters=${name}&from=${from}`,
  GetSentEmailsWithDateTime: (val) =>
    `${BASE_URL}/SuperAdmin/GetSentEmailsForDateRange?from=${val}`,
};
export const headTeacherDashboardAPI = {
  api: (schoolId, schoolToken, surveyToken) =>
    `${BASE_URL}/SurveyResult/GetHeadTeacherDashboard?schoolId=${schoolId}&schoolToken=${schoolToken}&surveyToken=${surveyToken}`,
  getComments: (schoolId, schoolToken) =>
    `${BASE_URL}/SurveyResult/GetHeadTeacherDashboardComments?schoolId=${schoolId}&schoolToken=${schoolToken}`,
  getCategoryReportDeatil: (queryString) =>
    `${BASE_URL}/SurveyResult/GetCategoryReport/${queryString}`,
  GetCategoryGraphReport: (schoolId, categoryId) =>
    `${BASE_URL}/SurveyResult/GetCategoryGraphReport?schoolId=${schoolId}&categoryId=${categoryId}`,
  manuallySendSurvey: (token) =>
    `${BASE_URL}/SurveyResult/ManuallySendSurvey?token=${token}`,
  firstTimeSurveyLoggedInSuccessful: (id) =>
    `${BASE_URL}/HeadTeacher/FirstTimeSurveyLoggedInSuccessful?id=${id}`,
  GetPDFManagementStandardReport: (surveyToken, categoryId) =>
    `${BASE_URL}/SurveyResult/GetPDFManagementStandardReport?token=${surveyToken}&categoryId=${categoryId}`,
};
export const matDashboardAPI = {
  getSchoolSurveyInfoDashboard: (token, matGroupId, matCycleId) =>
    `${BASE_URL}/SurveyResult/GetSchoolSurveyInfoDashboard?token=${token}&&matGroupId=${matGroupId}&&matCycleId=${matCycleId}`,
  getMatDashboard: (token, matGroupId, matCycleId, isSurveyClosed) =>
    `${BASE_URL}/MatGroup/GetMatDashboard?token=${token}&&matGroupId=${matGroupId}&&matCycleId=${matCycleId}&&isSurveyClosed=${isSurveyClosed}`,
};
export const surveyActionPlanAPI = {
  api: `${BASE_URL}/SurveyAction/`,
  getActionsPlan: (queryString, schoolToken, currentSchoolId) =>
    `${BASE_URL}/SurveyAction/GetSurveyActionByToken?token=${queryString}&schoolToken=${schoolToken}&currentSchoolId=${currentSchoolId}`,
  markActionAsDone: (actionId) =>
    `${BASE_URL}/SurveyAction/MarkActionAsDone?actionId=${actionId}`,
};

export const suggestedActionsAPI = {
  api: `${BASE_URL}/SuggestedActions`,
};

export const superAdminAPI = {
  api: `${BASE_URL}/SuperAdmin`,
  getDashboardInfo: `${BASE_URL}/SuperAdmin/GetDashboardInfo`,
  sendRegistrationCompletionEmail: (schoolId) =>
    `${BASE_URL}/SuperAdmin/SendRegistrationCompletionEmail?schoolId=${schoolId}`,
};

export const schoolStaffAPI = {
  GetStaffFiltersBySchool: (schoolId) =>
    `${BASE_URL}/schoolstaff/GetStaffFiltersHeatMap?schoolid=${schoolId}`,
  uploadStaffExcel: `${BASE_URL}/SchoolStaff/Upload`,
  ValidateSchoolExcel: `${BASE_URL}/SchoolStaff/ValidateSchoolExcel`,
  AddSchoolStaff: `${BASE_URL}/SchoolStaff/`,
  saveSchoolStaff: `${BASE_URL}/SchoolStaff/`,
  UpdateSchoolStaffWithAssociation: `${BASE_URL}/SchoolStaff/UpdateSchoolStaffWithAssociation`,
  ManuallySendLink: `${BASE_URL}/SchoolStaff/ManuallySendLink`,
  SchoolStaffExist: (id, schoolToken) =>
    `${BASE_URL}/SchoolStaff/SchoolStaffExist?id=${id}&schoolToken=${schoolToken}`,
  SchoolStaffAccessLevel: `${BASE_URL}/SchoolStaff/SchoolStaffAccessLevel`, //RevokeStaffAccess
  RevokeStaffAccess: (id) =>
    `${BASE_URL}/SchoolStaff/RevokeStaffAccess?staffId=${id}`,
  exportSchoolStaff: (schoolId, SubscriptionId, isTemplate) =>
    `${BASE_URL}/SchoolStaff/ExportSchoolStaff?schoolId=${schoolId}&SubscriptionId=${SubscriptionId}&isTemplate=${isTemplate}`,
  getNumberOfEmployees: (schoolId) =>
    `${BASE_URL}/SchoolStaff/GetNumberOfEmployees?schoolId=${schoolId}`,
  getBySchoolId: (id) =>
    `${BASE_URL}/SchoolStaff/GetSchoolStaff?schoolId=${id}`,
  getById: (id) => `${BASE_URL}/SchoolStaff/${id}`,
  getFiltersBySchoolId: (schoolId) =>
    `${BASE_URL}/SchoolStaff/getFiltersBySchoolId/${schoolId}`,
  getFilterValuesBySchoolId: (schoolId) =>
    `${BASE_URL}/SchoolStaff/getFilterValuesBySchoolId/${schoolId}`,
  getFilterLibrary: () => `${BASE_URL}/SchoolStaff/getFilterLibrary`,
  getSchoolStaff: `${BASE_URL}/SchoolStaff/SchoolStaff`,
  exportStaffExcelTemplate: (schoolId) =>
    `${BASE_URL}/SchoolStaff/ExportTemplate/${schoolId}`,
  exportStaffExcel: (schoolId) => `${BASE_URL}/SchoolStaff/Export/${schoolId}`,
  CheckStaffAccess: (id) => `${BASE_URL}/SchoolStaff/CheckStaffAccess?id=${id}`,
};

export const staffResultHeatMapAPI = {
  api: (token) => `${BASE_URL}/HeatMap/Get?token=${token}`,
  getMatCycleHeatMap: (token, matGroupId, matCycleId, isIndividualSchool) =>
    `${BASE_URL}/HeatMap/GetMatCycleHeatMap?token=${token}&matGroupId=${matGroupId}&matCycleId=${matCycleId}&isIndividualSchool=${isIndividualSchool}`,
  getGroupHeatMap: (
    schoolToken,
    token,
    valIsMatSchoolFromHeatmap,
    matGroupId,
    matCycleId
  ) =>
    `${BASE_URL}/HeatMap/GetGroupHeatMap?schoolToken=${schoolToken}&token=${token}&IsMatSchoolFromHeatmap=${valIsMatSchoolFromHeatmap}&matGroupId=${matGroupId}&matCycleId=${matCycleId}`,
  getGroupName: (schoolToken, token) =>
    `${BASE_URL}/HeatMap/GetGroupName?schoolToken=${schoolToken}&token=${token}`,
  getStaffResultHeatMapDownload: (token, ids) =>
    `${BASE_URL}/heatmap/GetHeatmapPdf?token=${token}&heatmaps=${ids}`,
  getMATHeatMapDownload: (heatmaps, token, isAnnonymised, matId, schoolId) =>
    `${BASE_URL}/heatmap/GetMATHeatmapPdf?token=${token}&heatmaps=${heatmaps}&isAnnonymised=${isAnnonymised}&matId=${matId}&schoolId=${schoolId}`,
  getCustomHeatmaps: (token, schoolToken) =>
    `${BASE_URL}/HeatMap/CustomHeatmapByFilters?token=${token}&schoolToken=${schoolToken}`,
  saveHeatmapFilters: (token, schoolToken) =>
    `${BASE_URL}/HeatMap/SaveHeatMapFilters?token=${token}&schoolToken=${schoolToken}`,
  getCutomHeatmapById: (Id) => `${BASE_URL}/HeatMap/CustomHeatmapById?Id=${Id}`,
  GetCutomHeatmapFiltersBySchoolId: (schoolId) =>
    `${BASE_URL}/HeatMap/GetCutomHeatmapFiltersBySchoolId?schoolId=${schoolId}`,
  getMatCoreFiltersForHeatMap: (matGroupId, matCycleId) =>
    `${BASE_URL}/HeatMap/GetMatCoreFiltersForHeatMap?matGroupId=${matGroupId}&matCycleId=${matCycleId}`,
  getCustomSchoolHeatmapPdf: (Id) =>
    `${BASE_URL}/HeatMap/GetCustomSchoolHeatmapPdf?Id=${Id}`,
  UpdateHeatmapFilters: (customHeatmapId) =>
    `${BASE_URL}/HeatMap/UpdateHeatmapFilters?Id=${customHeatmapId}`,
  DeleteCustomHeatmap: (Id) =>
    `${BASE_URL}/HeatMap/DeleteCustomHeatmap?Id=${Id}`,
};

export const welbeeVoiceAPI = {
  api: `${BASE_URL}/SurveyVoice`,
  getSchoolConverstion: (id) => `${BASE_URL}/SurveyVoice/Get?id=${id}`,
  getSurveyVoiceByToken: (token, pageNo) =>
    `${BASE_URL}/SurveyVoice/GetSurveyVoiceByToken?token=${token}&pageNo=${pageNo}`,
  setVoiceConversationAsImportant: `${BASE_URL}/VoiceConversation/SetVoiceConversationAsImportant`,
  setVoiceConversationIsRead: (id) =>
    `${BASE_URL}/VoiceConversation/SetVoiceConversationIsRead?id=${id}`,
  setVoiceConversationArchive: `${BASE_URL}/SurveyVoice/SetVoiceConversationArchive`,
  getSurveyVoiceNameAndToken: (id) =>
    `${BASE_URL}/SurveyVoice/GetSurveyVoiceNameAndToken?id=${id}`,
  getConversationComments: (id) => `${BASE_URL}/VoiceComment/Get?id=${id}`,
  submitHeadTeacherVoiceReply: `${BASE_URL}/VoiceComment/SubmitHeadTeacherVoiceReply`,
  setManualInviteForSurveyVoice: `${BASE_URL}/SurveyVoice/SetManualInviteForSurveyVoice`,
  setFirstTimeVoiceLoggedInSuccessful: (id) =>
    `${BASE_URL}/HeadTeacher/FirstTimeVoiceLoggedInSuccessful?id=${id}`,
  GetConversationList: (schoolId) =>
    `${BASE_URL}/SurveyVoice/GetConversationList/?schoolId=${schoolId}`,
  getSurveyVoice: (id) => `${BASE_URL}/SurveyVoice/GetSurveyVoice/?id=${id}`,
  searchVoiceConversation: (schoolId, keywords, getAll) =>
    `${BASE_URL}/SurveyVoice/search/?schoolId=${schoolId}&keywords=${keywords}&getAll=${getAll}`,
  GetPDFComments: (surveyToken) =>
    `${BASE_URL}/SurveyVoice/GetPDFComments?token=${surveyToken}`,
  GetExcelComments: (surveyToken) =>
    `${BASE_URL}/SurveyVoice/GetExcelComments?token=${surveyToken}`,
};

export const voiceConversationAPI = {
  GetStaffVoiceConversation: (token) =>
    `${BASE_URL}/VoiceConversation/GetStaffVoiceConversation/?token=${token}`,
};

export const surveyVoiceCommentAPI = {
  SubmitVoiceCommentStaffReply: `${BASE_URL}/VoiceComment/SubmitVoiceCommentStaffReply`,
};

export const voiceUpgradeAPI = {
  upgradeRequest: (id, welbeeType) =>
    `${BASE_URL}/SurveyVoice/UpgradeRequest/?id=${id}&welbeeType=${welbeeType}`,
  upgradeRequestWithToken: (token, welbeeType) =>
    `${BASE_URL}/SurveyVoice/UpgradeRequestWithToken/?token=${token}&welbeeType=${welbeeType}`,
};

export const staffGroupAPI = {
  GetAllGroupsBySchoolId: (id) =>
    `${BASE_URL}/StaffGroup/GetAllGroupsBySchoolId/${id}`,
  AddStaffGroup: `${BASE_URL}/StaffGroup`,
  DeleteStaffGroup: (id) => `${BASE_URL}/StaffGroup/DeleteGroup?groupId=${id}`,
  UpdateStaffGroup: `${BASE_URL}/StaffGroup/UpdateStaffGroup`,
  getById: (id) => `${BASE_URL}/StaffGroup/${id}`,
  archive: (id) => `${BASE_URL}/StaffGroup/ArchiveGroup?groupId=${id}`,
};

export const matGroupAPI = {
  getMatGroupLogo: (token, matId) =>
    `${BASE_URL}/MatGroup/GetMATGroupLogo?token=${token}&&matId=${matId}`,
  getAllGroups: `${BASE_URL}/MatGroup/Get`,
  getMatGroup: (token, matCycleId) =>
    `${BASE_URL}/MatGroup/GetMatGroupSchools?token=${token}&matCycleId=${matCycleId}`,
  getMatById: (id) => `${BASE_URL}/MatGroup/GetById?id=${id}`,
  setCanCreateSurvey: `${BASE_URL}/MatGroup/SetCanCreateSurvey`,
  createMatSurvey: `${BASE_URL}/MatGroup/StartGroupSurvey`,
  closeMatSurvey: `${BASE_URL}/MatGroup/CloseGroupSurvey`,
  addMatGroup: `${BASE_URL}/MatGroup/Post`,
  getMatGroupSchoolName: (token) =>
    `${BASE_URL}/School/GetMatGroupSchoolName?token=${token}`,
  getGroupHeatMap: (id) => `${BASE_URL}/School/GetGroupHeatMap?groupId=${id}`,
  getGroupPresentation: (token) =>
    `${BASE_URL}/MatGroup/GetGroupPresentation?token=${token}`,
  getMatGroupResults: (token) =>
    `${BASE_URL}/MatGroup/GetMatGroupResults${token}`,
  getMatGroupResultsByCategoryId: (categoryId) =>
    `${BASE_URL}/MatGroup/GetMatGroupResults${categoryId}`,
  getMatSchools: (groupId) =>
    `${BASE_URL}/School/GetMatSchools?groupId=${groupId}`,
  getMatSchoolsWithOpenSurveyForCycle: (groupId) =>
    `${BASE_URL}/School/GetMatSchoolsWithOpenSurveyForCycle?groupId=${groupId}`,
  removeSchoolFromGroup: (schoolId, groupId) =>
    `${BASE_URL}/School/AddRemoveSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${true}`,
  addSchoolInGroup: (schoolId, groupId) =>
    `${BASE_URL}/School/AddRemoveSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${false}`,
  getGroupPresentation: (token, matGroupId, matCycleId, IsSurveyClosed) =>
    `${BASE_URL}/MatGroup/GetGroupPresentation?token=${token}&matGroupId=${matGroupId}&matCycleId=${matCycleId}&IsSurveyClosed=${IsSurveyClosed}`,
  getMatGroupResults: (token, matGroupId, matCycleId) =>
    `${BASE_URL}/MatGroup/GetMatGroupResults${token}&matGroupId=${matGroupId}&matCycleId=${matCycleId}`,
  getMatGroupResultsByCategoryId: (categoryId, matGroupId, matCycleId) =>
    `${BASE_URL}/MatGroup/GetMatGroupResults${categoryId}&matGroupId=${matGroupId}&matCycleId=${matCycleId}`,
  getMatSchools: (groupId) =>
    `${BASE_URL}/MatGroup/GetMatSchools?groupId=${groupId}`,
  removeSchoolFromGroup: (schoolId, groupId) =>
    `${BASE_URL}/MatGroup/AddRemoveMatSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${true}`,
  addSchoolInGroup: (schoolId, groupId) =>
    `${BASE_URL}/MatGroup/AddRemoveMatSchool?schoolId=${schoolId}&groupId=${groupId}&isRemove=${false}`,
  updateMatUsers: `${BASE_URL}/MatGroup/UpdateMATUser`,
  archiveGroup: (groupId) =>
    `${BASE_URL}/MatGroup/ArchiveGroup?groupId=${groupId}`,
  GetMatReport: (matId, matCycleId) =>
    `${BASE_URL}/MatGroup/GetMatReportForMat?matId=${matId}&matCycleId=${matCycleId}`,
  GetMatReportPdf: (matId, matCycleId) =>
    `${BASE_URL}/MatGroup/GetMATReportPdf?matId=${matId}&matCycleId=${matCycleId}`,
  getMatDetailByUserContext: () => `${BASE_URL}/MatGroup/GetByUserContext`,
};

export const matCycleApi = {
  addMatCycle: `${BASE_URL}/MatCycle/Save`,
  getById: (id) => `${BASE_URL}/MatCycle/${id}`,
  getByMatId: (matId) => `${BASE_URL}/MatCycle/getByMatId/${matId}`,
  removeCycle: (id) => `${BASE_URL}/MatCycle/${id}`,
  removeCycleSchool: (id) => `${BASE_URL}/MatCycle/DeleteCycleSchool/${id}`,
  saveCycleSchool: `${BASE_URL}/MatCycle/saveCycleSchool`,
  toggleSchool: (id) => `${BASE_URL}/MatCycle/toggleSchool/${id}`,
  getMatSurveysEndDates: () => `${BASE_URL}/MatCycle/GetMatSurveysEndDates/`,
  getMatNameAndToken: (cycleId) =>
    `${BASE_URL}/MatCycle/GetMatNameAndToken?cycleId=${cycleId}`,
  getMatCyclesByMatGroupId: (id) =>
    `${BASE_URL}/MatCycle/GetMatCyclesByMatGroupId?matGroupId=${id}`,
};

export const matStaffApi = {
  UploadMatExcel: `${BASE_URL}/MatStaff/UploadMatExcel`,
  getGroups: `${BASE_URL}/MatStaff/Group`,
  getStaff: `${BASE_URL}/MatStaff/MatStaff`,
  exportMatExcelTemplate: (matId) =>
    `${BASE_URL}/MatStaff/ExportMatExcelTemplate/${matId}`,
  exportMatExcelStaff: (matId) =>
    `${BASE_URL}/MatStaff/ExportMatExcelStaff/${matId}`,
  ValidateMatExcel: `${BASE_URL}/MatStaff/ValidateMatExcel`,
  matSchools: (matId) => `${BASE_URL}/MatStaff/MatSchools/${matId}`,
  grantAccessMATLevel: `${BASE_URL}/MatStaff/GrantAccessMATLevel`,
};

export const ActivityReportAPI = {
  api: `${BASE_URL}/ActivityReport/`,
  getAccountRegistrations: () =>
    `${BASE_URL}/ActivityReport/AccountRegistrations/`,
};

export const additionalQuestionsAPI = {
  api: `${BASE_URL}/AdditionalQuestions`,
  getQuestionBankBySchoolId: (schoolId) =>
    `${BASE_URL}/AdditionalQuestions/GetQuestionBankBySchoolId?schoolId=${schoolId}`,
  getQuestionsBySchoolId: (schoolId) =>
    `${BASE_URL}/AdditionalQuestions/GetQuestionsBySchoolId?schoolId=${schoolId}`,
  downloadHseQuestions: () =>
    `${BASE_URL}/AdditionalQuestions/GetHseSurveyQuestionsPdf`,
};

export const likertAPI = {
  api: `${BASE_URL}/Likert`,
  getLikertBySchoolId: (schoolId) =>
    `${BASE_URL}/Likert/GetLikertBySchoolId?schoolId=${schoolId}`,
  deleteLikertById: (id) => `${BASE_URL}/Likert/DeleteLikertById?id=${id}`,
};

export const matLikertAPI = {
  api: `${BASE_URL}/MatLikert`,
  getLikertByMatGroupId: (matGroupId) =>
    `${BASE_URL}/MatLikert/GetLikertByMatGroupId?matGroupId=${matGroupId}`,
};

export const additionalSurveyQuestionsAPI = {
  api: `${BASE_URL}/AdditionalSurveyQuestions`,
  getQuestionBank: (token) =>
    `${BASE_URL}/AdditionalSurveyQuestions/GetQuestionBankByToken?token=${token}`,
  getSurveyQuestions: (token) =>
    `${BASE_URL}/AdditionalSurveyQuestions/GetSurveyQuestionsByToken?token=${token}`,
  loadSurveyQuestions: (token) =>
    `${BASE_URL}/AdditionalSurveyQuestions/LoadSurveyQuestions?token=${token}`,
  checkIsSurveyEditable: (token) =>
    `${BASE_URL}/AdditionalSurveyQuestions/IsSurveyEditable?token=${token}`,
};

export const SchoolSubscriptionAPI = {
  IsActionAllowed: (schoolId, actionName) =>
    `${BASE_URL}/SchoolSubscription/IsActionAllowed?schoolId=${schoolId}&restrictedActions=${actionName}`,
  getSchoolSubscriptionsByToken: (token) =>
    `${BASE_URL}/SchoolSubscription/GetSchoolSubscriptionsByToken?token=${token}`,
  getSchoolSubscriptionsById: (schoolId) =>
    `${BASE_URL}/SchoolSubscription/GetSchoolSubscriptions?schoolId=${schoolId}`,
  GetMatSchoolsSubcription: (token) =>
    `${BASE_URL}/SchoolSubscription/IsMatSchoolsFreeSubcription?token=${token}`,
};

export const AnalyticsAPI = {
  GetSchoolAnalyticsData: () => `${BASE_URL}/Analytics/GetScchoolAnalytics/`,
  GetSchoolCharacteristicTypes: (schoolId) =>
    `${BASE_URL}/Analytics/GetSchoolCharacteristicTypes?schoolId=${schoolId}`,
};

export const MATAnalyticsAPI = {
  GetMATAnalyticsData: () => `${BASE_URL}/MATAnalytics/GetMATAnalytics/`,
  GetSchoolCharacteristicTypes: (schoolId) =>
    `${BASE_URL}/Analytics/GetSchoolCharacteristicTypes?schoolId=${schoolId}`,
  GetMatSchools: (matId) =>
    `${BASE_URL}/MATAnalytics/GetMatSchools?matId=${matId}`,
  GetMAtCategoriesAnalytics: () =>
    `${BASE_URL}/MATAnalytics/GetMgtAnalyticsAnalytics/`,
};

export const matAdditionalQuestionsAPI = {
  api: `${BASE_URL}/MatAdditionalQuestions`,
  getQuestionBankByMatGroupId: (MatGroupId) =>
    `${BASE_URL}/MatAdditionalQuestions/GetQuestionBankByMatGroupId?MatGroupId=${MatGroupId}`,
  getQuestionsByMatGroupId: (MatGroupId) =>
    `${BASE_URL}/MatAdditionalQuestions/GetQuestionsByMatGroupId?MatGroupId=${MatGroupId}`,
  downloadHseQuestions: () =>
    `${BASE_URL}/MatAdditionalQuestions/GetHseSurveyQuestionsPdf`,
};

export const matCycleAdditionalQuestionsAPI = {
  api: `${BASE_URL}/MatCycleAdditionalQuestions`,
  getQuestionBank: (id) =>
    `${BASE_URL}/MatCycleAdditionalQuestions/GetQuestionBankByCycleId?cycleId=${id}`,
  getMatCycleQuestions: (id) =>
    `${BASE_URL}/MatCycleAdditionalQuestions/GetMatCycleQuestionsByCycleId?cycleId=${id}`,
  loadMatCycleQuestions: (id) =>
    `${BASE_URL}/MatCycleAdditionalQuestions/LoadMatCycleQuestions?cycleId=${id}`,
  checkIsMatCycleEditable: (id) =>
    `${BASE_URL}/MatCycleAdditionalQuestions/IsMatCycleEditable?cycleId=${id}`,
};

export const filterLibraryAPI = {
  getFilterLibrary: `${BASE_URL}/FilterLibrary/FilterLibrary`,
  deleteFilter: (id) => `${BASE_URL}/FilterLibrary/${id}`,
  saveFilter: `${BASE_URL}/FilterLibrary/`,
  getById: (id) => `${BASE_URL}/FilterLibrary/${id}`,
};

export const automationTestAPI = {
  saveAutomationTest: `${BASE_URL}/AutomationTest/`,
};
export const matCustomHeatmapAPI = {
  getMatCustomHeatmaps: (token) =>
    `${BASE_URL}/MatCustomHeatmap/GetMatCustomHeatmap?token=${token}`,
  getMatCoreFiltersForHeatMap: (matGroupId, matCycleId) =>
    `${BASE_URL}/MatCustomHeatmap/GetMatCoreFiltersForHeatMap?matGroupId=${matGroupId}&matCycleId=${matCycleId}`,
  SaveMatHeatmapFilters: (token) =>
    `${BASE_URL}/MatCustomHeatmap/SaveMatHeatmapFilters?token=${token}`,
  GetCutomHeatmapFiltersByMatGroupId: (matGroupId) =>
    `${BASE_URL}/MatCustomHeatmap/GetCutomHeatmapFiltersByMatGroupId?matGroupId=${matGroupId}`,
  GeMatCustomHeatmapById: (Id) =>
    `${BASE_URL}/MatCustomHeatmap/GeMatCustomHeatmapById?Id=${Id}`,
  GetMatCustomSchoolHeatmapPdf: (Id) =>
    `${BASE_URL}/MatCustomHeatmap/GetMatCustomSchoolHeatmapPdf?Id=${Id}`,
  UpdateMatHeatmapFilters: (customHeatmapId) =>
    `${BASE_URL}/MatCustomHeatmap/UpdateMatHeatmapFilters?Id=${customHeatmapId}`,

  DeleteMatCustomHeatmap: (Id) =>
    `${BASE_URL}/MatCustomHeatmap/DeleteMatCustomHeatmap?Id=${Id}`,
};

export const wondeAPI = {
  api: `${BASE_URL}/WondeRequest`,
  getWondeSchoolRequestStatus: (wondeSchoolUrn) =>
    `${BASE_URL}/WondeRequest/GetWondeSchoolRequestStatus?wondeSchoolUrn=${wondeSchoolUrn}`,
  updateWondeRequestStatus: (SchoolUrnNo) =>
    `${BASE_URL}/WondeRequest/UpdateWondeRequestStatus?SchoolUrnNo=${SchoolUrnNo}`,
  updateWondeSyncedStatus: (SchoolUrnNo) =>
    `${BASE_URL}/WondeRequest/UpdateWondeSyncedStatus?SchoolUrnNo=${SchoolUrnNo}`,
};
