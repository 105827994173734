import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import { FooterWrapper } from '../../resources/styling/appStyle';

class Footer extends Component{
    render(){
        return(
            <FooterWrapper>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        Copyright 2021 Welbee. All rights reserved.
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
                </Grid>
            </FooterWrapper>
        );
    }
}

export default Footer;


