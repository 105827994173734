import { storageService } from '../services';
export const ContryRegion = {
  usRegion: 'us',
};
export const QueryStringTokens = {
  token: 'token',
  categoryId: 'categoryId',
  voiceId: 'voiceId',
  schoolToken: 'schoolToken',
  IsMatSchoolFromHeatmap: 'IsMatSchoolFromHeatmap',
  IsSurvey: 'isSurvey',
};

export const WebStorageNames = {
  UserInfo: 'userInfo',
  Token: 'token',
  SurveyToken: 'surveyToken',
  SurveyInProgress: 'surveyInProgress',
  SchoolId: 'schoolId',
  SchoolName: 'SchoolName',
  SchoolToken: 'schoolToken',
  SelectedSurveyToken: 'selectedSurveyToken',
  WelbeeType: 'WelbeeType',
  NewConverstionVoicePopUpShow: 'NewConverstionVoicePopUpShow',
  SelectedTab: 'SelectedTab',
  TempVoice: 'TempVocie',
  schoolLogoUrl: 'schoolLogoUrl',
  AccessLevel: 'AccessLevel',
  IsStaff: 'IsStaff',
  WelbeeVoiceAccountType: 'WelbeeVoiceAccountType',
  IsUpgradeDisplay: 'IsUpgradeDisplay',
  IsFirstLogin: 'IsFirstLogin',
  IsVoiceFirstLogin: 'IsVoiceFirstLogin',
  FreeTrialEndDate: 'FreeTrialEndDate',
  MatGroupId: 'MatGroupId',
  IsDisplayMatHeatMap: 'IsDisplayMatHeatMap',
  MatName: 'MatName',
  Role: 'Role',
  IsMat: 'IsMat',
  IsUpgradBannderDisplayed: 'IsUpgradBannderDisplayed',
  MenuType: 'MenuType',
  SwapName: 'SwapName',
  SortOrder: 'SortOrder',
  SubscriptionId: 'SubscriptionId',
  SchoolListFlag: 'SchoolListFlag',
  NewSubscriptionId: 'NewSubscriptionId',
  HeadTeacherId: 'HeaderTeacherId',
  IsSurveyEditable: 'IsSurveyEditable',
  AdditionalQuestions: 'AdditionalQuestions',
  MatUserId: 'MatUserId',
  UsRegion: 'UsRegion',
  Permissions: 'Permissions'
};

export const Roles = {
  SuperAdmin: 'SuperAdmin',
  HeadTeacher: 'HeadTeacher',
  GroupDirector: 'GroupDirector',
};

export const StaffTypes = {
  All: 0,
  Teaching: 1,
  Support: 2,
  TeacherWorkingInside: 4,
  TeacherWorkingOutside: 8,
  SupportStaffWorkingInside: 16,
  SupportStaffWorkingOutside: 32,
  MiddleLeader: 64,
  SeniorLeader: 128,
  Leader: 256,
  AllTeaching: 13,
};

export const SurveyTypes = {
  Any: 0,
  Teaching: 1,
  Support: 2,
};
export const CommentQuestionType = {
  StaffOpinon: 1,
  BiggestImpact: 2,
};

export const Sender = {
  Support: 1,
  You: 2,
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const UsRegion = () => {
  const usRegion = storageService.getItem(WebStorageNames.UsRegion);
  return usRegion;
};

export const UKPhaseOfSchool = [
  { value: 1, label: 'Primary' },
  { value: 2, label: 'Primary(Infants)' },
  { value: 3, label: 'Primary(Juniors)' },
  { value: 4, label: 'Secondary' },
  { value: 5, label: 'Sixth Form College' },
  { value: 6, label: 'Further Education College' },
  { value: 7, label: 'All through school' },
  { value: 8, label: 'Pupil referral unit primary' },
  { value: 9, label: 'Pupil referral unit secondary' },
  { value: 10, label: 'SEND' },
  { value: 12, label: 'Preparatory' },
  { value: 13, label: 'Middle' },
  { value: 14, label: 'Senior' },
  { value: 11, label: 'Other' },
];

export const USPhaseOfSchool = [
  { value: 1, label: 'Nursery' },
  { value: 2, label: 'Kindergarten' },
  { value: 3, label: 'Elementary' },
  { value: 4, label: 'Middle' },
  { value: 5, label: 'Junior High' },
  { value: 6, label: 'High' },
  { value: 7, label: 'Senior High' },
  { value: 8, label: 'Combined Junior-Senior High' },
  { value: 9, label: 'Other' },
];

export const PhaseOfSchool =
  UsRegion() === ContryRegion.usRegion ? USPhaseOfSchool : UKPhaseOfSchool;

export const MenuItems = {
  Dashboard: 'dashboard',
  Analytics: 'analytics',
  MATAnalytics: 'mat-analytics',
  Report: 'report',
  Presentation: 'presentation',
  Comments: 'comments',
  Actionplan: 'actionplan',
  UserGuide: 'userGuide',
  Account: 'account',
  SummaryReport: 'summary-report',
  ReportSubMenu: {
    Demands: 'demands',
    Control: 'control',
    Support: 'support',
    SupportManager: 'supportManager',
    SupportPeer: 'supportPeer',
    Relationships: 'relation',
    Change: 'change',
    Role: 'role',
    Skills: 'skillsandcareers',
    Career: 'career',
    NextSteps: 'next-steps',
  },
  Staff: 'Staff',
  Conversations: 'Conversations',
  YourAccount: 'YourAccount',
  StaffManagement: 'StaffManagement',
  MatAccount: 'mat-account',
  GroupDashboard: 'group-dashboard',
  MatHeatMap: 'mat-heat-map',
  MatPresentation: 'mat-presentation',
  MatStaffManagement: 'mat-staff-management',
  VoiceUserGuide: 'VoiceUserGuide',
  QuestionBank: 'QuestionBank',
  MatAdditionalQuestions: 'MatAdditionalQuestions',
  MatReport: 'mat-report',
};
export const UsernameReg = /^[0-9a-zA-Z]*$/;
export const SelectedPhaseOfSchool = { value: -1, label: 'Phase of school' };
export const SelectedSector = { value: -1, label: 'Sector' };
export const PasswordReg =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@£!#$%^&+="',-]).*$/;

export const UKSectorItems = [
  { value: 1, label: 'Local Authority' },
  { value: 2, label: 'Academy' },
  { value: 3, label: 'Multi-Academy Trust' },
  { value: 4, label: 'Grammar' },
  { value: 5, label: 'Free' },
  { value: 6, label: 'Independent' },
  { value: 7, label: 'International' },
  { value: 8, label: 'Other' },
];

export const USSectorItems = [
  { value: 1, label: 'Public' },
  { value: 2, label: 'Private' },
  { value: 3, label: 'International' },
  { value: 4, label: 'Other' },
];
export const SectorItems =
  UsRegion() === ContryRegion.usRegion ? USSectorItems : UKSectorItems;
export const ActionPlanCategoryList = [
  {
    value: 1,
    label: 'Demands',
  },
  {
    value: 2,
    label: 'Control',
  },
  {
    value: 3,
    label: 'Manager Support',
  },
  {
    value: 4,
    label: 'Relationships',
  },
  {
    value: 5,
    label: 'Role',
  },
  {
    value: 6,
    label: 'Change',
  },
  {
    value: 7,
    label: 'Peer Support',
  },
  {
    value: 1,
    label: 'Pre Questionnaire',
  },
  {
    value: 2,
    label: 'Running The Questionnaire',
  },
  {
    value: 3,
    label: 'Post Questionnaire',
  },
];
export const CategoryList = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'Demands',
  },
  {
    value: 2,
    label: 'Control',
  },
  {
    value: 3,
    label: 'Manager Support',
  },
  {
    value: 7,
    label: 'Peer Support',
  },
  {
    value: 4,
    label: 'Relationships',
  },
  {
    value: 5,
    label: 'Role',
  },
  {
    value: 6,
    label: 'Change',
  },

  {
    value: 8,
    label: 'Additional Questions',
  },
];

export const DateFormats = {
  format_31_12_19:
    UsRegion() === ContryRegion.usRegion ? 'MM/DD/YY' : 'DD/MM/YY',
  format_31_12_2019:
    UsRegion() === ContryRegion.usRegion ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
};

export const schoolListFlags = {
  RegistrationNotComplete: 1,
  NoSurveyAddedYet: 2,
  SchoolWithLiveSurvey: 3,
  CompletedAtLeastOneSurvey: 4,
  NotLiveSurvey: 5,
  WelbeeVoiceOnly: 6,
  WelbeeSurveyWithVoice: 7,
};
export const questions = {
  first:
    'What is your opinion of staff wellbeing? What is going well and what could be improved?',
  second:
    'What one thing would have the biggest impact on improving your wellbeing and that of other staff?',
};
export const WelbeeType = {
  WelbeeVoice: 1,
  WelbeeSurveyWithVoice: 2,
  Group: 3,
};
export const toggleOption = {
  WelbeeSurvey: 2,
  WelbeeVoice: 1,
};

export const HeadTeacherAccess = {
  Full: 0,
  View: 1,
  Admin: 2,
};

export const WelbeeVoiceAccountType = {
  Free: 0,
  Paid: 1,
};

export const FreePeriodEndingDate = '10/01/2020';
export const BannerDisplayDate = '07/28/2020';

export const UserGuideSurveyLink = 'https://knowledge.welbee.co.uk';
export const UserGuideVoiceLink = 'https://knowledge.welbee.co.uk';

export const RestrictedActions = {
  ColorHeatMaps: 1,
  ReplyComment: 2,
  FlagComment: 3,
  DownloadReport: 4,
  GrantAccess: 5,
  DashboardDecile: 6,
  Presentation: 7,
  Report: 8,
  DownloadManagementStandard: 9,
  QuestionBank: 10,
};
export const WelbeeTypes = {
  WelbeeVoiceFree: 1,
  WelbeeVoicePro: 2,
  WelbeeSurveyFree: 3,
  WelbeeSurveyPro: 4,
  WelbeeSurveyPremium: 5,
};
export const SignUpStatus = {
  SchoolDetail: 1,
  InVoiceDetail: 2,
  Completed: 3,
  WellbeeVoice: 4,
  WelbeeHeadTeacherDetail: 5,
  EmailVerification: 6,
};

export const ChartType = [
  {
    value: 1,
    label: 'Line Chart',
  },
  {
    value: 2,
    label: 'Bar Chart',
  },
  {
    value: 3,
    label: 'Management Standard Chart',
  },
];

export const CharteristicsType = {
  All: 11111,
  JobRole: 22222,
  TimeAtSchool: 33333,
  EmpolymentType: 44444,
  //Note Premium Filters will be Dynamics
};
export const ShowChartType = {
  LineChart: 1,
  BarChart: 2,
  MgtStandardChart: 3,
};

export const SurveysDisabledSchools = {
  WelbeeDemonstrationPrimarySchool: 493,
  WelbeeDemonstrationPrimarySchoolPremium: 749,
};

export const MATCharacteristics = [
  {
    id: 0,
    value: 0,
    label:
      'All Staff ' +
      (UsRegion() === 'us'
        ? storageService.getItem('groupType') || 'Group'
        : 'MAT') +
      ' Score',
  },
  { id: 1, value: 1, label: 'All Staff scores by schools' },
  { id: 2, value: 2, label: 'Job Role Scores across all schools' },
  { id: 3, value: 3, label: 'Time at school across all schools' },
  { id: 4, value: 4, label: 'Employment type across all schools ' },
  {
    id: 5,
    value: 5,
    label: 'Support staff classroom scores by schools',
  },
  {
    id: 6,
    value: 6,
    label: 'Support staff non-classroom scores by schools',
  },
  {
    id: 7,
    value: 7,
    label: 'Teaching staff classroom scores by schools',
  },
  {
    id: 8,
    value: 8,
    label: 'Teaching staff non-classroom scores by schools',
  },
  { id: 9, value: 9, label: 'Middle leader scores by schools' },
  { id: 10, value: 10, label: 'Senior leader scores by schools' },
  {
    id: 11,
    value: 11,
    label: 'Staff with less than 1 yr service scores by school',
  },
  {
    id: 12,
    value: 12,
    label: 'Staff with 1-3 yrs service scores by school',
  },
  {
    id: 13,
    value: 13,
    label: 'Staff with 4-5 yrs service scores by school',
  },
  {
    id: 14,
    value: 14,
    label: 'Staff with 6-10 yrs service scores by school',
  },
  {
    id: 15,
    value: 15,
    label: 'Staff with 11-15 yrs service scores by school',
  },
  {
    id: 16,
    value: 16,
    label: 'Staff with 16-20 yrs service scores by school',
  },
  {
    id: 17,
    value: 17,
    label: 'Staff with greater than 20 yrs service scores by school',
  },
  { id: 18, value: 18, label: 'Full time staff scores by school' },
  { id: 19, value: 19, label: 'Part Time staff scores by school' },
  // { id: 20, value: 20, label: 'Additional Filters scores across all schools' },
  { id: 21, value: 21, label: 'Job Title' },
  { id: 22, value: 22, label: 'Department' },
  {
    id: 23,
    value: 23,
    label: UsRegion() === 'us' ? 'Grade' : 'Year or Key Stage',
  },
];

export const MATCharacteristicsValues = {
  OverallMATScore: 0,
  OverallScoresBySchools: 1,
  JobRoleScoresAcrossAllSchools: 2,
  TimeAtSchoolAcrossAllSchools: 3,
  EmploymentTypeAcrossAllSchools: 4,
  SupportStaffClassroomScoresBySchools: 5,
  SupportStaffNonClassroomScoresBySchools: 6,
  TeachingStaffClassroomScoresBySchools: 7,
  TeachingStaffNonClassroomScoresBySchools: 8,
  MiddleLeaderScoresBySchools: 9,
  SeniorLeaderScoresBySchools: 10,
  StaffWithLessThan1YrServiceScoresBySchools: 11,
  StaffWithOneToThreeYrsServiceScoresBySchool: 12,
  StaffWithFourToYearServiceScoresBySchool: 13,
  StaffWith6To10YrsServiceScoresBySchool: 14,
  StaffWith11To15YrsServiceScoresBySchool: 15,
  StaffWith16To20YrsServiceScoresBySchool: 16,
  StaffWithGreaterThan20YrsServiceScoresBySchool: 17,
  FullTimeStaffScoresBySchool: 18,
  PartTimeStaffScoresBySchool: 19,
  AdditionalFiltersScoresAcrossAllSchools: 20,
  JobTitle: 21,
  Department: 22,
  PhaseOrYear: 23,
};

export const JobRoles = [
  { value: 0, label: 'All' },
  {
    value: 1,
    label: 'Teaching',
  },
  {
    value: 2,
    label: 'Support',
  },
  { value: 4, label: 'Teaching Staff (Classroom)' },
  { value: 8, label: 'Teaching Staff (Non-Classroom)' },
  { value: 16, label: 'Support Staff (Classroom)' },
  { value: 32, label: 'Support Staff (Non-Classroom)' },
  { value: 64, label: 'Middle Leader' },
  { value: 128, label: 'Senior Leader' },
];

export const TimeAtSchool = [
  { value: 0, label: 'All' },
  { value: 1, label: '< 1 Years' },
  { value: 2, label: '1 - 3 Years' },
  { value: 3, label: '4 - 5 Years' },
  { value: 4, label: '6 - 10 Years' },
  { value: 5, label: '11 - 15 Years' },
  { value: 6, label: '16 - 20 Years' },
  { value: 7, label: '20+ Years' },
];
export const EmployementTypes = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Full Time' },
  { value: 2, label: 'Part Time' },
];
export const SortOrder = {
  Newest: 'Newest',
  Oldest: 'Oldest',
  NewMsg: 'New',
  MyMessages: 'My',
  AllHeadTeachers: 'HeadTeacher',
  AllStaffReplies: 'AllStaff',
  NotReplied: 'NotReplied',
};

export const PptSlidesOrder = {
  MatNameAndLogo: 1,
  ScorebyStaffParticipation: 2,
  AverageScoreAllParticiton: 3,
  ScorebyJobRole: 4,
  GraphScorebyJobRole: 5,
  ScorebySchools: 6,
  GraphScorebySchools: 7,
  HeighestandLowestScoring: 8,
  QuestionsScorebySchool: 9,
  QuestionsScorebyJobRole: 10,
};

export const StaffJobRoles = [
  { id: 4, title: 'Teaching Staff (Classroom)' },
  { id: 8, title: 'Teaching Staff (Non-Classroom)' },
  { id: 16, title: 'Support Staff (Classroom)' },
  { id: 32, title: 'Support Staff (Non-Classroom)' },
  { id: 64, title: 'Middle Leader' },
  { id: 128, title: 'Senior Leader' },
];



export const SilideNumberAndTitles = [
  { id: 1, label: 'Mat Name and logo', subSlideIndex: 0 },
  { id: 2, label: 'Staff Participation', subSlideIndex: 0 },
  { id: 3, label: 'Average Scores for All Participants', subSlideIndex: 0 },
  { id: 4, label: 'Scores by Job Role', subSlideIndex: 0 },
  { id: 5, label: 'Changes in Scores by Job Role Since the Last Survey', subSlideIndex: 0 },
  { id: 6, label: 'Scores by Site', subSlideIndex: 0 },
  { id: 7, label: 'Changes in Scores by Site Since Last Survey', subSlideIndex: 0 },
  { id: 8, label: 'The Five Highest and Lowest Scoring Questions', subSlideIndex: 0 },
  { id: 9, label: 'Your Additional Questions', subSlideIndex: 0 },
  { id: 10, label: 'Your Question Scores by Job Role', subSlideIndex: 0 },
];

export const SurveySlideOrder = {
  Nameandlogo: 1,
  StaffParticipation: 2,
  AverageScoresAllParticipants: 3,
  ScoresbyJobRole: 4,
  HighestandLowestScoringQuestions: 5,
  YourQuestions: 6,
  Demands: 7,
  Control: 8,
  ManagerSupport: 9,
  PeerSupport: 10,
  Relationships: 11,
  Role: 12,
  Change: 13,
};

export const empType = ['Part Time', 'Full Time'];
export const experince = [
  '< 1 Years',
  '1 - 3 Years',
  '4 - 5 Years',
  '6 - 10 Years',
  '11 - 15 Years',
  '16 - 20 Years',
  '20+ Years',
];

export const ManagmentStandards = {
  All: 0,
  Demand: 1,
  Control: 2,
  ManagerSupport: 3,
  Relationships: 4,
  Role: 5,
  Change: 6,
  PeerSupport: 7,
};

export const sortFilters = (list) => {
  if (list?.length > 1) {
    list.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
  return list;
};

export const AccessPermissions = {
  Full: 'Full',
  View: 'View',
  Admin: 'Admin',
  HeadTeacher: 'HeadTeacher',
};