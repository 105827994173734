import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { colors } from '../../../resources/theme';
import { AppButton } from '../../../resources/styling';
import { WrapperMain } from '../../../resources/styling/formStyle';
import { TextArea } from '../../../components';
import { Sender } from '../../../utils/Constants';
import { DateFormatWithMonth } from '../../../utils/CommonFunctions';
import { ErrorWrapper } from '../../../resources/styling/appStyle';
import surveyVoiceCommentsService from '../../../services/api/surveyVoiceCommentsService';
import { loaderAction } from '../../../store/loaderStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CommonRepplyWrapper } from './SurveyVoiceStaffReplyStyle';

const { mandy } = colors;

const CommentsReplyCommon = ({
  voiceConversation,
  surveyVoice,
  sender,
  isConversationArchive,
  loaderActions,
}) => {
  let [
    voiceConversationWithComments,
    setVoiceConversationWithComments,
  ] = useState();
  let [surveyVoiceRecord, setSurveyVoice] = useState();

  useEffect(() => {
    setVoiceConversationWithComments(voiceConversation);
    setSurveyVoice(surveyVoice);
  }, [voiceConversation, surveyVoice]);

  return (
    <Formik
      initialValues={{
        Reply: '',
      }}
      onSubmit={(values, actions) => {
        loaderActions.dataLoading(true);
        setTimeout(() => {
          const token = window.location.search.split('?token=')[1];
          let setFalse = true;
          if (sender === Sender.You) {
            setFalse = false;
            const model = {
              ReplyText: values.Reply,
              Sender: sender,
              SurveyVoiceToken: token,
            };
            surveyVoiceCommentsService
              .submitSurveyVoiceCommentStaffReply(model)
              .then((response) => {
                loaderActions.dataLoading(false);
                const { success, data } = response;
                if (success) {
                  actions.resetForm();
                  setVoiceConversationWithComments(data);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          }
          if (sender === Sender.Support) {
            setFalse = false;
            const model = {
              ReplyText: values.Reply,
              Sender: sender,
              SurveyVoiceToken: token,
            };
            surveyVoiceCommentsService
              .submitSurveyVoiceCommentStaffReply(model)
              .then((response) => {
                loaderActions.dataLoading(false);
                const { success, data } = response;
                if (success) {
                  actions.resetForm();
                  setVoiceConversationWithComments(data);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          }
          if(setFalse){
            loaderActions.dataLoading(false);
          }

        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Reply: Yup.string().required('Reply message is required.'),
      })}
      render={({ values, handleChange, touched, errors, handleSubmit }) => {
        return !isConversationArchive ? (
          <CommonRepplyWrapper>
            <div className='search-inner-container'>
              <div className='search-inner-sub-container'>
                <div className='reply-section'>
                  <h3>
                    Please add your comments below. You cannot be identified and
                    any feedback, comments or ideas provided are fully
                    anonymous. Where possible please add your suggestions or
                    possible solutions.
                  </h3>
                  <div className='comments-body'>
                    {surveyVoiceRecord ? surveyVoiceRecord.Description : ''}
                  </div>
                </div>
                {voiceConversationWithComments &&
                voiceConversationWithComments.VoiceComments !== undefined
                  ? voiceConversationWithComments.VoiceComments.map((x) => {
                      return (
                        <>
                          <hr className='horizontal-ruler' />
                          <div
                            className={
                              Sender.Support === x.Sender
                                ? 'reply-section support-reply'
                                : 'reply-section your-reply'
                            }
                          >
                            <h3>
                              {DateFormatWithMonth(x.UpdatedDate)}
                              {Sender.Support === x.Sender
                                ? 'You'
                                : x.SenderId != null
                                ? x.HeadTeacher.AdminName
                                : 'School'}
                            </h3>
                            <div className='comments-body'>{x.ReplyText}</div>
                          </div>
                        </>
                      );
                    })
                  : null}
                <div className='reply-section'>
                  <WrapperMain>
                    <TextArea
                      placeholder='Reply'
                      name='Reply'
                      value={values.Reply}
                      onChange={handleChange}
                    />
                    <ErrorWrapper>
                      {touched.Reply && errors.Reply ? errors.Reply : ''}
                    </ErrorWrapper>
                  </WrapperMain>
                  <WrapperMain className='send-button'>
                    <AppButton
                      color={mandy}
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Send
                    </AppButton>
                  </WrapperMain>
                </div>
              </div>
            </div>
          </CommonRepplyWrapper>
        ) : (
          <>The conversation is no longer available.</>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(CommentsReplyCommon);
