import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
import { useTranslation } from 'react-i18next';
import { storageService } from '../../../services';

const getHeatmapCellMd = (level, value, isDecile) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {value >= 1 ? (
        <>
          {value.toFixed(2)}{' '}
          <span className='score-superscript'>
            ({level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
            )
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
const ScoresbySchoolPpt = (props) => {
  const { t } = useTranslation();
  const groupType = storageService.getItem('groupType');
  const GroupName = groupType || t('MAT');

  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>{props.title}</h1>
          <p className='section'>
            Heatmay 3 below shows the average scores for all staff for each
            school within the {GroupName}. Comparatively high scores (3rd school
            decile and higher) are shaded green and comparatively lower ones
            (7th school descile and lower) red. Those without shading are close
            to the average score for schools.
          </p>
          <p className='table-title'>Heatmap 3: Scores by Site</p>
          <div className='stats-table'>
            <Scrollbars
              className='scrollbar-view'
              // autoHide
              // autoHideTimeout={1000}
              // autoHideDuration={200}
            >
              <div className='job-role-container'>
                <div style={{ width: '25%', float: 'left' }}>&nbsp;</div>
                <div className='width-10'>Demands</div>
                <div className='width-10'>Control</div>
                <div className='width-10 title-txt'>Manager Support</div>
                <div className='width-10 title-txt'>Peer Support</div>
                <div className='width-10'>Relationship</div>
                <div className='width-10'>Role</div>
                <div className='width-10'>Change</div>
              </div>
              <div style={{ width: '100%' }}>
                {props.heatmapBySchool?.map((row) => {
                  return (
                    <div className='job-role-container'>
                      <div
                        style={{ width: '25%', marginLeft: '0px' }}
                        className='job-role-title-cell'
                      >
                        {row.Name}
                      </div>
                      {getHeatmapCellMd(row.DemandDecile, row.Demand, false)}
                      {getHeatmapCellMd(row.ControlDecile, row.Control, false)}
                      {getHeatmapCellMd(
                        row.ManagerSupportDecile,
                        row.ManagerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.PeerSupportDecile,
                        row.PeerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.RelationshipsDecile,
                        row.Relationships,
                        false
                      )}
                      {getHeatmapCellMd(row.RoleDecile, row.Role, false)}
                      {getHeatmapCellMd(row.ChangeDecile, row.Change, false)}
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
          </div>
          <p className='section-bottom'>
            * Heatmaps of all filtered scores by school can be viewed and
            downloaded from your {GroupName} dashboard.
          </p>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default ScoresbySchoolPpt;
