import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { AssistanceWrapper, SearchFilterWrapper, SubContainer } from '../../resources/styling/appStyle';
import { staffIcon } from '../../resources/images/index';

const {
    lightGray, whiteColor, softGrey, viking, mineShaft, lighterGrey,
    bittersweet
} = colors;

/*** Staff Results ***/
export const ExtendedSubContainer = styled(SubContainer)`
  .subcontainer-inner {
    width: 750px;
    margin: auto;
    .search-sorting {
      border-left: 1px solid rgba(83, 181, 224, 0.5);
      width: 32%;
      float: left;
      .selected-option {
        background-image: url(${staffIcon});
      }
    }
  }
  margin: 0 10px 10px 0;
  padding: 61px 0px 0 0;
  min-height: 100vh;

  .search-data-container {
    width: 100%;
    float: left;
    padding-bottom: 20px;
  }
  .search-inner-container {
    width: 750px;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .search-inner-sub-container {
    padding: 0;
    float: left;
    width: 100%;
    border-radius: 6px;
    background: ${whiteColor};
    box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);

    .searched-data-wrapper {
      width: 100%;
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid ${lighterGrey};

      .no-message-text {
        padding: 0 40px;
      }
      .search-date {
        padding: 0 0 0 40px;
      }
      .comment-data {
        padding: 0 0 0 40px;
        color: ${mineShaft};
      }
      span {
        padding: 0 0 0 40px;
        display: block;
      }
    }
  }
  .staff-type-grid {
    padding: 20px 0 5px 20px;
    ul {
      display: flex;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        flex: 1;
        font-size: 13px;
        line-height: 13px;
        font-weight: 600;
        color: ${lightGray};
        text-align: center;
      }
      li:first-child {
        flex: 2;
        text-align: left;
        flex-wrap: wrap;
      }
    }
  }

  .staff-type-grid-data {
    padding: 0 0 20px 0;
    ul:first-child {
      background: ${softGrey};
    }
    ul {
      padding: 0 0 0 20px;
      li {
        color: ${mineShaft};
        height: 55px;
        border-right: 1px solid ${whiteColor};
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        span {
          float: left;
          font-size: 12px;
          color: gray;
          font-weight: 400;
        }
      }
      li:first-child {
        justify-content: center;
        align-items: baseline;
      }
      li:last-child {
        border-right: 0;
      }
      .viking {
        background: ${viking};
      }
      .bittersweet {
        background: ${bittersweet};
      }
    }
  }

  .staff-result-heading {
    border-bottom: 1px solid ${lighterGrey};
    float: left;
    width: 100%;
    margin-bottom: 12px;
    padding: 20px;
    span:nth-child(1) {
      font-weight: 600;
      float: left;
    }
    span:nth-child(2) {
      font-weight: 400;
      float: right;
      font-size: 12px;
      color: ${lightGray};
    }
  }
`;
export const ExtendedSearchFilterWrapper = styled(SearchFilterWrapper)``;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 92.5%;
  left: 50px;
  position: absolute;
  top: -26px !important;
  ${media.md`
  width: 92.5%;
  `}
  ${media.lg`
  width: 92.5%;
  `}
  ${media.xl`
  width: 92.5%;
  top: -26px !important;
  `}
`;
export const CommentsMainWrapper = styled.div`
  .left-nav {
    max-width: 190px;
  }

  .comments-wrapper {
    max-width: 86%;
    margin-left: 190px;
    float: left;
    flex: auto;

    ${media.xl`
    max-width: 90%;

    `}
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
  float: left;
  width: 100%;
`;

const StaffResultsStyle = {
    ExtendedSubContainer,
    ExtendedSearchFilterWrapper,
    ExtendedAssistanceWrapper,
    CommentsMainWrapper
}
export default StaffResultsStyle;