import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu, MainHeader } from '../../components';
import DashboardWelcomePopup from '../dashboard/DashboardWelcomePopup';
import {
  surveyService,
  storageService,
  dashboardService,
} from '../../services';
import { WebStorageNames } from '../../utils/';
import { loaderAction } from '../../store/loaderStore';
import NewSurveyForm from '../schoolSurvey/NewSurveyForm';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import { routesObj } from '../../routes';
import {
  AssistanceWrapper,
  AssistanceWrapperHeading,
} from '../../resources/styling/appStyle';
import {
  ExtendedVoiceDashboardWrapper,
  AdminDashboardBody,
} from './AdminDashboardStyle';
import { DateFormatLocalTime } from '../../utils/CommonFunctions';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolId: 0,
      currentSurvey: null,
      SurveyCloseDialog: false,
      newSurveyDialogOpen: false,
      TempSurveyName: '',
      selectedSurvey: {},
      createdSurvey: null,
      isSurveyCreated: false,
      isFirstLogin: false,
      hasAddtionalQuestionFiltersIncluded: true,
    };
  }

  componentDidMount = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    const isFirstLogin = storageService.getItem(WebStorageNames.IsFirstLogin);
    this.setState({ isFirstLogin: isFirstLogin });
    this.AddtionalQuestionFiltersIncluded();
    // get the latest survey here
    this.props.loaderActions.dataLoading(true);
    surveyService
      .getLatestSurvey(schoolId)
      .then((response) => {
        if (response.success) {
          this.setState({ currentSurvey: response.data });
        }
        this.setState({ schoolId: schoolId });
        this.props.loaderActions.dataLoading(false);
      })
      .catch((error) => {
        //
        this.props.loaderActions.dataLoading(false);
      });

    surveyService
      .getNewSurveyName(schoolId)
      .then((response) => {
        this.setState({ TempSurveyName: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  onSurveyClose = () => {
    this.setState({ SurveyCloseDialog: true });
  };

  onSurveyCloseOk = () => {
    const { currentSurvey } = this.state;
    this.props.loaderActions.dataLoading(true);
    surveyService
      .closeSurvey(currentSurvey.Id)
      .then((response) => {
        if (response.success) {
          this.setState({ currentSurvey: null, SurveyCloseDialog: false });
        }
        this.props.loaderActions.dataLoading(false);
      })
      .catch((error) => {
        this.setState({ currentSurvey: null, SurveyCloseDialog: false });
        this.props.loaderActions.dataLoading(false);
      });
  };

  onSurveyCreate = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    this.setState({
      newSurveyDialogOpen: true,
      createdSurvey: null,
      isSurveyCreated: false,
      isLoading: true,
    });
    surveyService
      .getNewSurveyName(schoolId)
      .then((response) => {
        this.setState({ TempSurveyName: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  handleNewSurveyCancel = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, newSurveyDialogOpen: false });
  };

  handleAddSurveySubmit = (values) => {
    values.StartDate = DateFormatLocalTime(values.StartDate);
    const { schoolId } = this.state;
    values.SchoolId = schoolId;
    this.props.loaderActions.dataLoading(true);
    surveyService
      .addSurvey(values)
      .then((response) => {
        if (response.success) {
          const surveyObj = response.data;
          this.setState({
            currentSurvey: surveyObj,
            createdSurvey: surveyObj,
            isSurveyCreated: true,
          });
        }
        this.props.loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.loaderActions.dataLoading(false);
      });
    this.setState({ newSurveyDialogOpen: false });
  };

  onCloseWelcomePopup = () => {
    dashboardService
      .firstTimeSurveyLoggedInSuccessful()
      .then((response) => {
        const { success } = response;
        if (success) {
          this.setState({ isFirstLogin: false });
          storageService.setItem(WebStorageNames.IsFirstLogin, false);
        }
      })
      .catch((error) => {
        this.setState({ commentsLoader: false });
      });
  };
  onLearnMoreScreen = () => {
    storageService.setItem(WebStorageNames.IsFirstLogin, false);
    this.onCloseWelcomePopup();
    this.props.history.push(routesObj.SurveyLearnMore.path);
  };

  AddtionalQuestionFiltersIncluded = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    surveyService
      .hasAddtionalQuestionFiltersIncluded(schoolId)
      .then((response) => {
        this.setState({
          hasAddtionalQuestionFiltersIncluded:
            response.data.hasAddtionalQuestionFiltersIncluded,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      currentSurvey,
      SurveyCloseDialog,
      newSurveyDialogOpen,
      TempSurveyName,
      isSurveyCreated,
      createdSurvey,
      isFirstLogin,
    } = this.state;
    return (
      <ExtendedVoiceDashboardWrapper>
        <div className='left-container'>
          <LeftMenu />
        </div>
        {isFirstLogin ? (
          <DashboardWelcomePopup
            isOpen={isFirstLogin}
            onClose={this.onCloseWelcomePopup}
            onOk={this.onCloseWelcomePopup}
          />
        ) : (
          <></>
        )}
        <div className='right-container'>
          <MainHeader />
          <AssistanceWrapper>
            <AssistanceWrapperHeading>
              <span className='comment-heading'>Manage Survey</span>
              {/* <span> conversation list</span> */}
            </AssistanceWrapperHeading>
          </AssistanceWrapper>
          <AdminDashboardBody>
            <div className='content-body-inner-wrapper'>
              {currentSurvey !== null && (
                <ul>
                  <li>{currentSurvey.Name}</li>
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      href
                      onClick={() => this.onSurveyClose()}
                    >
                      Close survey
                    </a>
                  </li>
                </ul>
              )}
              {currentSurvey === null && (
                <ul>
                  <li>There is not any active survey.</li>
                  {TempSurveyName === null && (
                    <li>
                      <a
                        style={{ cursor: 'pointer' }}
                        href
                        onClick={() => this.onSurveyCreate()}
                      >
                        Create survey{' '}
                      </a>
                    </li>
                  )}
                </ul>
              )}
              {SurveyCloseDialog ? (
                <ConfirmPopupForm
                  onClose={() => this.setState({ SurveyCloseDialog: false })}
                  onOk={() => this.onSurveyCloseOk()}
                  isOpen={true}
                  successPopupMessage={
                    'Are you sure you want to close the survey?'
                  }
                />
              ) : (
                <div />
              )}

              {newSurveyDialogOpen && (
                <NewSurveyForm
                  title={'Create New Survey'}
                  submitButtonText={'Create Survey'}
                  isOpen={newSurveyDialogOpen}
                  onClose={this.handleNewSurveyCancel}
                  onSubmit={this.handleAddSurveySubmit}
                  surveyName={TempSurveyName}
                  isLoading={this.state.isLoading}
                  staffExist={true}
                  isAddtionalQuestionFiltersIncluded={
                    this.state.hasAddtionalQuestionFiltersIncluded
                  }
                />
              )}
            </div>
          </AdminDashboardBody>
        </div>
      </ExtendedVoiceDashboardWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(AdminDashboard));
