import request from '../request';
import { matCustomHeatmapAPI } from './urls';

const getMatCustomHeatmap = (model,token) => {
  return request({
    url: matCustomHeatmapAPI.getMatCustomHeatmaps(token),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getMatCoreFiltersForHeatMaps = (matGroupId, matCycleId) => {
  return request({
    url: matCustomHeatmapAPI.getMatCoreFiltersForHeatMap(matGroupId, matCycleId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const saveMatHeatmapFilters = (model,token) => {
    return request({
      url: matCustomHeatmapAPI.SaveMatHeatmapFilters(token),
      method: 'POST',
      data: model,
    })
      .then((response) => response)
      .catch((error) => error);
  };

  
export const getCutomHeatmapFiltersByMatGroupId = (matGroupId) => {
    return request({
      url: matCustomHeatmapAPI.GetCutomHeatmapFiltersByMatGroupId(matGroupId),
      method: 'GET',
    })
      .then((response) => response)
      .catch((error) => error);
  };

  export const geMatCustomHeatmapById = (Id) => {
    return request({
      url: matCustomHeatmapAPI.GeMatCustomHeatmapById(Id),
      method: 'GET',
    })
      .then((response) => response)
      .catch((error) => error);
  };

  export const downloadMatCustomSchoolHeatmap = (Id) => {
    return request({
      url: matCustomHeatmapAPI.GetMatCustomSchoolHeatmapPdf(Id),
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => response)
      .catch((error) => error);
  }; 
  

  const updateMatHeatmapFilters = (model, customHeatmapId) => {
    return request({
      url: matCustomHeatmapAPI.UpdateMatHeatmapFilters(customHeatmapId),
      method: 'POST',
      data: model,
    })
      .then((response) => response)
      .catch((error) => error);
  };

  export const deleteMatCustomHeatmap = (Id) => {
    return request({
      url: matCustomHeatmapAPI.DeleteMatCustomHeatmap(Id),
      method: 'DELETE',
    })
      .then((response) => response)
      .catch((error) => error);
  };

const matCustomHeatmapService = {
  getMatCustomHeatmap,
  getMatCoreFiltersForHeatMaps,
  saveMatHeatmapFilters,
  getCutomHeatmapFiltersByMatGroupId,
  geMatCustomHeatmapById,
  downloadMatCustomSchoolHeatmap,
  updateMatHeatmapFilters,
  deleteMatCustomHeatmap
};

export default matCustomHeatmapService;
