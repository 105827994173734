import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { loaderAction } from '../../store/loaderStore';
import { routesObj } from '../../routes';
import { Header, FloatingInputLabel } from '../../components';
import { ErrorWrapper, RegisterInnerMain, ExtendedAssitanceForm, RegisterWrapper } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { accountService,storageService } from '../../services';
import { PhaseOfSchool, SectorItems,WebStorageNames } from '../../utils';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { WelbeeTypes } from '../../utils/Constants';
import { RegisterInner, InputWrapper } from './SchoolRegisterStyle';

const { mandy } = colors;

class UserDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phaseOfSchool:PhaseOfSchool,
      selectedPhaseOfSchool: '',
      sector: SectorItems,
      selectedSector: '',
      emailErrorMessage: '',
      duplicateSchoolErrorMessage: '',
      addressSuggestionList: [],
      adminName: '',
      firstName: '',
      lastName: '',
      contactNumber: '',
      email: '',
      role: '',
      schoolSubscription: {
        subscriptionId:
          window.location.pathname === routesObj.VoiceFreeUrl.path
            ? WelbeeTypes.WelbeeVoiceFree
            : window.location.pathname === routesObj.VoiceProUrl.path
            ? WelbeeTypes.WelbeeVoicePro
            : window.location.pathname === routesObj.SurveyFreeUrl.path
            ? WelbeeTypes.WelbeeSurveyFree
            : window.location.pathname === routesObj.SurveyProUrl.path
            ? WelbeeTypes.WelbeeSurveyPro
            : WelbeeTypes.WelbeeSurveyPremium,
      },
    };
    this.splitName = this.splitName.bind(this);
  }
  componentDidMount() {
    this.getRegions();
    const { schoolId, SchoolInfo } = this.props;
    if (schoolId > 0 && SchoolInfo != null) {
      this.setState({ adminName: SchoolInfo.AdminName });
      this.setState({ firstName: SchoolInfo.FirstName });
      this.setState({ lastName: SchoolInfo.LastName });
      this.setState({ contactNumber: SchoolInfo.ContactNumber });
      this.setState({ email: SchoolInfo.Email });
      this.setState({ role: SchoolInfo.Role });
    }
  }
   getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);  
      })
      .catch((error) => {
        console.log(error);
      });
  };
  splitName = (fileName) => {
    var splitFileName = fileName.split('.');
    var imageName = splitFileName[0];
    var trancateString =
      imageName.length > 23
        ? imageName.substr(imageName, 23, 0) + '...'
        : fileName;
    return trancateString;
  };
  onChangeHandler = (event) => {};
  handleDropDownChange = (option) => {
    this.setState({ selectedPhaseOfSchool: option });
  };

  duplicationErrorMeassageHandle = (message) => {
    if (message === 'Email is already in use, please choose another email.') {
      this.setState({
        emailErrorMessage: message,
        duplicateSchoolErrorMessage: '',
      });
    } else if (message === 'School name already exist.') {
      this.setState({
        duplicateSchoolErrorMessage: message,
        emailErrorMessage: '',
      });
    }
  };

  addressItemClick = (item, setFieldValue) => {
    setFieldValue('Address', item.target.innerText);
    this.setState({ addressSuggestionList: [] });
  };

  render() {
    const { schoolId, SchoolInfo } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: schoolId > 0 ? schoolId : 0,
          AdminName: SchoolInfo
            ? SchoolInfo.AdminName != null
              ? SchoolInfo.AdminName
              : ''
            : '',
          FirstName: SchoolInfo
            ? SchoolInfo.FirstName != null
              ? SchoolInfo.FirstName
              : ''
            : '',
          LastName: SchoolInfo
            ? SchoolInfo.LastName != null
              ? SchoolInfo.LastName
              : ''
            : '',
          ContactNumber: SchoolInfo
            ? SchoolInfo.ContactNumber != null
              ? SchoolInfo.ContactNumber
              : ''
            : '',
          Email: SchoolInfo
            ? SchoolInfo.Email != null
              ? SchoolInfo.Email
              : ''
            : '',
          Role: SchoolInfo
            ? SchoolInfo.Role != null
              ? SchoolInfo.Role
              : ''
            : '',
          schoolSubscription: this.state.schoolSubscription,
        }}
        onSubmit={(values, actions) => {
          const { loaderActions } = this.props;
          loaderActions.dataLoading(true);
          accountService
            .HeadTecherSchooldetail(values)
            .then((response) => {
              if (response.success) {
                amplitudeLog('PageView', 'SchoolRegisterSuccessful');
                loaderActions.dataLoading(false);
                this.props.onCompletion(response.data, response.data.Id);
              } else {
                this.duplicationErrorMeassageHandle(response.message);
                loaderActions.dataLoading(false);
              }
            })
            .catch((error) => {
              loaderActions.dataLoading(false);
            });
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          ContactNumber: Yup.string()
            .trim()
            .max(50)
            .matches(/^\S*$/, "Enter numbers without space.")
            .matches(/^[0-9]{1,50}$/, {
              message: 'Phone number can only contain numbers.',
              excludeEmptyString: false,
            })
            .required('Phone number is required.'),
          Email: Yup.string()
            .trim()
            .max(60)
            .email('Provide email address like example@g.com.')
            .required('Email is required.'),
          FirstName: Yup.string()
            .trim()
            .max(50, 'First name must be at most 50 characters.')
            .required('First name is required.'),
          LastName: Yup.string()
            .trim()
            .max(50, 'Last name must be at most 50 characters.')
            .required('Last name is required.'),
          Role: Yup.string()
            .trim()
            .max(50, 'Role must be at most 50 characters.')
            .required('Role is required.'),
          IsWelbeeVoice: Yup.bool(),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          handleBlur,
        }) => (
          <div>
            <Header />

            <RegisterWrapper>
              <Grid container>
                
                <Grid item
                  lg={12}
                  sm={12}
                  md={12}
                  xs={12}
                  className='mobile-wrapper'
                >
                  <RegisterInnerMain>
                    <RegisterInner>
                      <ExtendedAssitanceForm>
                        <h2>
                          Welcome to Welbee{' '}
                          {this.state.schoolSubscription.subscriptionId ===
                          WelbeeTypes.WelbeeSurveyPremium
                            ? 'Survey Premium'
                            : this.state.schoolSubscription.subscriptionId ===
                              WelbeeTypes.WelbeeSurveyPro
                            ? 'Survey Pro'
                            : this.state.schoolSubscription.subscriptionId ===
                              WelbeeTypes.WelbeeVoicePro
                            ? 'Voice Pro'
                            : this.state.schoolSubscription.subscriptionId ===
                              WelbeeTypes.WelbeeSurveyFree
                            ? 'Survey Free'
                            : 'Voice Free'}{' '}
                          <br /> Nice to meet you!
                        </h2>
                        <p>
                          First, tell us some brief information about you so
                          we know where to send your welcome details.
                        </p>

                        <InputWrapper>
                          <div className='form-heading'>
                            <span>Your information</span>
                          </div>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='First name'                              
                              name='FirstName'
                              value={values.FirstName}
                              onChange={handleChange}
                              autoComplete='new-password'
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {touched.FirstName && errors.FirstName}
                            </ErrorWrapper>
                            </WrapperMain>
                            <WrapperMain>
                            <FloatingInputLabel
                              label='Last name'
                              name='LastName'
                              value={values.LastName}
                              onChange={handleChange}
                              autoComplete='new-password'
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {touched.LastName && errors.LastName}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Position'
                              name='Role'
                              value={values.Role}
                              onChange={handleChange}
                              autoComplete='new-password'
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {touched.Role && errors.Role}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Email'
                              name='Email'
                              value={values.Email}
                              autoComplete='new-password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {(touched.Email && errors.Email
                                ? touched.Email && errors.Email
                                : null) || this.state.emailErrorMessage}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <FloatingInputLabel
                              label='Phone (no spaces)'
                              name='ContactNumber'
                              value={values.ContactNumber}
                              onChange={handleChange}
                              autoComplete='new-password'
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {touched.ContactNumber && errors.ContactNumber}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <AppButton
                              color={mandy}
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Continue
                            </AppButton>
                          </WrapperMain>
                        </InputWrapper>

                      </ExtendedAssitanceForm>
                    </RegisterInner>
                  </RegisterInnerMain>
                </Grid>
                
              </Grid>
            </RegisterWrapper>
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(UserDetailForm));
