import React from 'react';

const LearnFeedback = () => {
  return (
    <div>
      <p>
        As we will be adding new features and developing the existing ones
        further we will from time to time ask for your feedback on what you find
        easy to use, where we can improve, and on what else we can add to make
        it easier for you.
      </p>
      <p className='email-anchor'>
        Don’t wait to be asked if you have suggestions though, simply send
        feedback to
        <a href='mailto:services@welbee.co.uk' target='_top'>
          services@welbee.co.uk
        </a>
        .
      </p>
      <p>Thank you. Your support is very much appreciated.</p>
    </div>
  );
};

export default LearnFeedback;
