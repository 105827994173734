import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import matGroupService from '../../services/api/matGroupService';
import { AppButton } from '../../resources/styling';
import { AdminHeader, Paging } from '../../components';
import { colors } from '../../resources/theme/colors';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils/Constants';
import { SchoolListMainWrapper, TableStyle } from '../../resources/styling/appStyle';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import AddSchoolInGroup from '../../components/common/AddSchoolInGroup';
import { toast } from 'react-toastify';
import { schoolService } from '../../services';
import { SchoolSurveyWrapper, SchoolSurveyInnerMain, SchoolSurveyInner, PaginationWrapper } from './MatStyle';

const { mandy } = colors;

function MatSchools(props) {
  const [groupSchoolsList, setGroupSchoolsList] = useState([]);
  const [groupId] = useState(
    storageService.getItem(WebStorageNames.MatGroupId)
  );
  const [isAddSchoolOpen, setAddSchoolPopupOpen] = useState(false);
  const [isRemovePopupOpen, setRemovePopupOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const [schoolsList, setSchoolsList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);

  useEffect(() => {
    matGroupService
      .getMatSchools(groupId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setGroupSchoolsList(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading schools.');
      });
    getSchoolsList();
  }, {});

  function getSchoolsList() {
    schoolService
      .GetSchoolsForMat()
      .then((response) => {
        const { success } = response;
        if (success) {
          setSchoolsList(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading schools.');
      });
  }

  function removeSchoolFromGroup() {
    matGroupService
      .removeSchoolFromGroup(schoolId, groupId)
      .then((response) => {
        const { success } = response;
        setRemovePopupOpen(false);
        if (success) {
          setGroupSchoolsList(response.data);
        }
      })
      .catch((error) => {
        setRemovePopupOpen(false);
        toast.error('Error removing school from group.');
      });
  }

  function addSchoolInGroup(schoolId) {
    matGroupService
      .addSchoolInGroup(schoolId, groupId)
      .then((response) => {
        const { success } = response;
        setAddSchoolPopupOpen(false);
        if (success) {
          setGroupSchoolsList(response.data);
          getSchoolsList();
        }
      })
      .catch((error) => {
        setAddSchoolPopupOpen(false);
        toast.error('Error adding school in group.');
      });
  }

  function onChangePage(pageOfItems) {
    setPageOfItems(pageOfItems);
  }
  return (
    <div>
      <AdminHeader />
      <SchoolSurveyWrapper>
        <SchoolSurveyInnerMain>
          <SchoolSurveyInner>
            <SchoolListMainWrapper>
              <h1 style={{margin: '0'}}>
                <span
                  className='back-btn'
                  onClick={() => props.history.push('/mat-accounts')}
                >
                  Back
                </span>
                <span className='heading'>Group Accounts List</span>
                <span
                  className='create-school'
                  onClick={() => setAddSchoolPopupOpen(true)}
                >
                  Add School
                </span>
              </h1>
              <TableStyle>
              <table>
                <thead>
                  <tr>
                    <th className='list-heading'>Name</th>
                    <th className='list-heading list-action'>Actions</th>
                  </tr>
                </thead>
              <tbody>
              {pageOfItems.map((item, i) => (
                <tr key={item.Id}>
                  <td>{item.Name ? item.Name : 'Not Provided'}</td>
                  <td>
                    <AppButton
                      color={mandy}
                      type='submit'
                      onClick={() => {
                        setRemovePopupOpen(true);
                        setSchoolId(item.Id);
                      }}
                    >
                      Remove
                    </AppButton>
                  </td>
                </tr>
              ))}
              </tbody>
              </table>
              </TableStyle>
              <PaginationWrapper>
                <Paging
                  items={groupSchoolsList}
                  onChangePage={(items) => onChangePage(items)}
                />
              </PaginationWrapper>
            </SchoolListMainWrapper>
          </SchoolSurveyInner>

          <ConfirmPopupForm
            isOpen={isRemovePopupOpen}
            onClose={() => {
              setRemovePopupOpen(false);
              setSchoolId(null);
            }}
            onOk={() => removeSchoolFromGroup()}
            successPopupMessage='Are you sure to remove this school from group?'
          />
          <AddSchoolInGroup
            isOpen={isAddSchoolOpen}
            onClose={() => {
              setAddSchoolPopupOpen(false);
            }}
            onOk={(schoolId) => addSchoolInGroup(schoolId)}
            schoolsList={schoolsList}
          />
        </SchoolSurveyInnerMain>
      </SchoolSurveyWrapper>
    </div>
  );
}

const mapStateToProps = ({ superAdmin: { SetSchoolsListFlag } }) => ({
  SetSchoolsListFlag,
});

export default connect(mapStateToProps, null)(withRouter(MatSchools));
