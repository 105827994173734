import React from 'react';

const ChartPercentLegend = () => {
  return (
    <div className="percent-legend">
      <div className="percent-value">
        <span className="zero-percent">0%</span>
        <span>10%</span>
      </div>
      <div className="percent-value">
        <span>20%</span>
      </div>
      <div className="percent-value">
        <span>30%</span>
      </div>
      <div className="percent-value">
        <span>40%</span>
      </div>
      <div className="percent-value">
        <span>50%</span>
      </div>
      <div className="percent-value">
        <span>60%</span>
      </div>
      <div className="percent-value">
        <span>70%</span>
      </div>
      <div className="percent-value">
        <span>80%</span>
      </div>
      <div className="percent-value">
        <span>90%</span>
      </div>
      <div className="percent-value">
        <span>100%</span>
      </div>
    </div>
  );
};

export default ChartPercentLegend;
