import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DateFormat } from '../../utils/CommonFunctions';
import { routesObj } from '../../routes';
import storageService from '../../services/storageService';
import { WebStorageNames, HeadTeacherAccess, QueryStringTokens, Roles } from '../../utils/Constants';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import { ReadActionPlan, UpcomingTasksWrapper } from '../../resources/styling/appStyle';

class UpcomingTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: 0,
    };
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount = () => {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
  };

  clickHandler(e) {
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      this.props.history.push(
        routesObj.ActionPlan.path +
          '?token=' +
          surveyToken +
          '&schoolToken=' +
          schoolToken
      );
    } 
    else
      this.props.history.push(
        routesObj.ActionPlan.path + '?token=' + surveyToken
      );
    amplitudeLog('Dashboard', 'Action Plan', '');
    e.preventDefault();
  }

  render() {
    const { actionResults } = this.props;
    const { accessLevel } = this.state;

    return (
      <UpcomingTasksWrapper>
        <h2>Upcoming Tasks</h2>
        <div className='task-list-wrapper'>
          {actionResults && actionResults.length > 0 ? (
            actionResults.map((action) => {
              return (
                <div className='task-list'>
                  <span>{action.Title}</span>
                  <span className='dates'>{DateFormat(action.DueDate)}</span>
                </div>
              );
            })
          ) : (
            <span>Click on the link below to create your action plan.</span>
          )}
        </div>
        {accessLevel !== HeadTeacherAccess.View && (
          <ReadActionPlan>
            <button onClick={this.clickHandler}>Full Action Plan</button>
          </ReadActionPlan>
        )}
      </UpcomingTasksWrapper>
    );
  }
}

export default withRouter(UpcomingTasks);
