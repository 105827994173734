import React from 'react';
import styled from 'styled-components';
import { colors } from '../../resources/theme/colors';

const { mineShaft, twilightBlue, pictonBlue, blizzBlue } = colors;

const InputFieldStyle = styled.input`
  width: 100%;
  padding: 11px 20px;
  color: ${mineShaft};
  margin-bottom: 0;
  background: ${twilightBlue};
  border: 1px solid ${blizzBlue};
  border-radius: 5px;
  box-sizing: border-box;  
  transition: all 0.5s ease;
  :focus {
    opacity: 1;    
  }
  ::-webkit-input-placeholder {
    color: transparent;
  }
  ::-moz-placeholder {
    color: transparent;
  }
  :-ms-input-placeholder {
    color: transparent;
  }
  :-moz-placeholder {
    color: transparent;
  }
`;

const AppInputField = ({
  name,
  maxLength,
  onBlur,
  placeholder,
  value,
  type,
  onChange,
  childern,
  autoComplete,
  className,
  readOnly = false,
  min,
}) => (
  <div className='floating-label'>
    <InputFieldStyle
      placeholder={placeholder}
      name={name}
      onBlur={onBlur}
      value={value}
      maxLength={maxLength}
      autoComplete={autoComplete}
      type={type}
      onChange={onChange}
      childern={childern}
      className='floating-input'
      readOnly={readOnly}
      min={min}
    />
    <label>{placeholder}</label>
  </div>
);

export default AppInputField;
