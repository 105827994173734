import React from 'react';

const LearnSteps = () => {
  return (
    <div>
      <p>
        The final section of the report provides a brief summary of the top
        strength and area for focus from each Management Standard and suggests
        the top three actions you should consider based on your scores. These
        are based on your scores, though taking into account the school context
        it may be that actions recommended under each management standard may be
        more important for you.
      </p>
      <p>You can also download the full report as a pdf.</p>
    </div>
  );
};

export default LearnSteps;
