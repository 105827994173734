import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { AppButton } from '../../resources/styling';
import { loaderAction } from '../../store/loaderStore';
import {
  AppInputField,
  FloatingInputLabel,
  Header,
  SchoolsGroupDropDown,
} from '../../components';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  MainFooter,
  ErrorWrapper,
  FieldWrapper,
  RegisterInnerMain,
  RegisterWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { footerLogo } from '../../resources/images';
import { accountService, matGroupService, schoolService } from '../../services';
import { routesObj } from '../../routes';
import { WrapperMain } from '../../resources/styling/formStyle';
import * as GroupNames from '../../components/common/GroupNames';
import { UsernameReg } from '../../utils/Constants';
import { RegisterInner, ExtendedAssitanceForm, InputWrapper } from './MatStyle';
import MuiDropDown from '../../components/common/MuiDropDown';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControlLabel } from '@mui/material';

const { mandy } = colors;

function RegisterMat(props) {
  const [schoolsList, setSchoolsList] = useState([]);
  const [filteredSchoolList, setFilteredSchoolList] = useState([]);
  const [isSuggestionDisplay, setSuggestionDisplay] = useState(false);
  const [isOtherGroup, setisOtherGroup] = useState(false);
  const [SchoolGroupName, setSchoolGroupName] = useState('');

  const [isSchoolTitleOpen, setIsSchoolTitleOpen] = useState(false);
  const [schoolTitles, setSchoolTitles] = useState([]);
  const [schoolTitleInputValue, setSchoolTitleInputValue] = useState('');
  const [queryfilters, setQueryFilter] = useState([]);
  const [matSchoolId, setMatSchoolId] = useState([]);

  const [filterDropDownMaxSize] = useState(15);

  useEffect(() => {
    getMatSchools();
  }, {});

  function getMatSchools() {
    schoolService.GetSchoolsForMatWithCloseSurvey().then((response) => {
      setSchoolsList(response.data);
    });
  }

  const schholTitleSuggestions =
    [...schoolsList] != null
      ? [...schoolsList]
          .filter((x) => x.Name !== null && x.Name !== '')
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .map((item, i) => {
            return {
              title: item.Name,
              key: item.Id,
            };
          })
      : null;

  function filterSchoolList(name) {
    setFilteredSchoolList(schoolsList.filter((x) => x.Name.indexOf(name) >= 0));
  }

  function getSchoolId(schoolName) {
    const schoolRecord = schoolsList.find((x) => x.Name === schoolName);
    if (schoolRecord) return schoolRecord.Id;
    return 0;
  }

  function setGroupName(name) {
    if (name !== 'Other') {
      setisOtherGroup(false);
      setSchoolGroupName(name);
    } else {
      setSchoolGroupName('');
      setisOtherGroup(true);
    }
  }

  const handleSchoolTitleChange = (event, values) => {
    let matId = [];
    setSchoolTitles(values);
    values.map((x) => {
      matId.push(x.key);
    });
    setMatSchoolId(matId);
  };

  const groupItems =
    GroupNames.getGroupNameCollection() !== null
      ? GroupNames.getGroupNameCollection().map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })
      : null;
  return (
    <Formik
      initialValues={{
        Id: 0,
        Name: '',
        FirstName: '',
        LastName: '',
        Email: '',
        School: '',
        SchoolId: 0,
        MatSchoolIds: [],
        GroupName: SchoolGroupName,
        Group: '',
        CustomGroup: '',
        Username: '',
        isTestAccount: false,
        File: null,
        FileName: null
      }}
      onSubmit={(values, actions) => {
        const { loaderActions } = props;
        values.MatSchoolIds = matSchoolId;
        if (values.MatSchoolIds.length === 0) {
          toast.error('Please select a valid school.');
        } else {
          loaderActions.dataLoading(true);
          matGroupService.addMatGroup(values).then((response) => {
            const { success, message } = response;
            loaderActions.dataLoading(false);
            if (success) props.history.push(routesObj.MatAccounts.path);
            else toast.error(message);
          });
        }
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        GroupName: Yup.string()
          .trim()
          .max(50, 'Custom group name must be at most 50 characters.')
          .required('Custom group name is required.'),
        Group: Yup.string().trim().required('Group is required.'),
        Name: Yup.string()
          .trim()
          .max(50, 'Group name must be at most 50 characters.')
          .required('Group name is required.'),
        Email: Yup.string()
          .trim()
          .max(60)
          .email('Provide email address like example@g.com.')
          .required('Email is required.'),
        FirstName: Yup.string()
          .trim()
          .max(50, 'First name must be at most 50 characters.')
          .required('First name  is required.'),
        LastName: Yup.string()
          .trim()
          .max(50, 'Last name must be at most 50 characters.')
          .required('Last name  is required.'),
        Username: Yup.string()
          .trim()
          .max(20, 'Username must be at most 20 characters.')
          .required('Username is required.')
          .matches(
            UsernameReg,
            'Username must contain only letter and numbers.'
          ),
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <div>
          <Header />

          <RegisterWrapper>
            <Grid container>
              <Grid
                item
                lg={12}
                sm={12}
                md={12}
                xs={12}
                className='mobile-wrapper'
              >
                <RegisterInnerMain>
                  <RegisterInner>
                    <ExtendedAssitanceForm>
                      <h2>Register Group</h2>
                      <InputWrapper>
                        <WrapperMain>
                          <MuiDropDown
                            name='Group'
                            label='Groups'
                            value={values.Group}
                            onChange={(e) => {
                              setFieldValue('Group', e.target.value);
                              setFieldValue('GroupName', e.target.value);
                              setFieldValue('CustomGroup', '');
                              setGroupName(e.target.value);
                            }}
                          >
                            {groupItems}
                          </MuiDropDown>

                          <ErrorWrapper>
                            {touched.Group && errors.Group}
                          </ErrorWrapper>
                        </WrapperMain>

                        {isOtherGroup ? (
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Custom Group Name'
                              autoComplete='new-mat-group-name'
                              name='CustomGroup'
                              value={values.CustomGroup}
                              onChange={(e) => {
                                setFieldValue('CustomGroup', e.target.value);
                                setFieldValue('GroupName', e.target.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.GroupName && errors.GroupName}
                            </ErrorWrapper>
                          </WrapperMain>
                        ) : (
                          <div />
                        )}

                        <WrapperMain>
                          <FloatingInputLabel
                            label='Group Name'
                            autoComplete='new-mat-group-name'
                            name='Name'
                            value={values.Name}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.Name && errors.Name}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='First Name'
                            autoComplete='new-name'
                            name='FirstName'
                            value={values.FirstName}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.FirstName &&
                              errors.FirstName &&
                              errors.FirstName}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Last Name'
                            name='LastName'
                            value={values.LastName}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.LastName &&
                              errors.LastName &&
                              errors.LastName}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Email'
                            name='Email'
                            value={values.Email}
                            autoComplete='new-password'
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.Email && errors.Email}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Username'
                            name='Username'
                            value={values.Username.trim()}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.Username && errors.Username}
                          </ErrorWrapper>
                        </WrapperMain>

                        <WrapperMain>
                          <div className='box'>
                            <input
                              type='file'
                              name='file-7[]'
                              id='file-7'
                              accept='image/x-png,image/jpeg'
                              className='inputfile inputfile-6'
                              data-multiple-caption='{count} files selected'
                              onChange={(event) => {
                                var files = event.target.files;
                                var file = files[0];
                                var splitFileName = file.name.split('.');
                                var imageName = splitFileName[0];
                                var trancateString =
                                  imageName.length > 23
                                    ? imageName.substr(imageName, 23, 0) + '...'
                                    : file.name;
                                if (files && file) {
                                  var reader = new FileReader();
                                  reader.onload = function (readerEvt) {
                                    var binaryString = readerEvt.target.result;
                                    setFieldValue('File', btoa(binaryString));
                                    setFieldValue('FileName', file.name);
                                    setFieldValue(
                                      'TrancateFileName',
                                      trancateString
                                    );
                                  };
                                  reader.readAsBinaryString(file);
                                }
                              }}
                            />
                            <label>
                              <span className='no-file-upload '>
                                {values.TrancateFileName
                                  ? values.TrancateFileName
                                  : 'Upload a logo'}
                              </span>
                              <strong>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='20'
                                  height='17'
                                  viewBox='0 0 20 17'
                                >
                                  <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                                </svg>{' '}
                                Choose a file&hellip;
                              </strong>
                            </label>
                          </div>
                          <ErrorWrapper>
                            {touched.File && errors.File && errors.File}
                          </ErrorWrapper>
                        </WrapperMain>
                        <h3>Add school in group</h3>
                        <div className='search floating-label'>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                              <Autocomplete
                                fullWidth
                                multiple
                                freeSolo={false}
                                name='schoolTitles'
                                open={isSchoolTitleOpen}
                                onOpen={() => {
                                  if (schoolTitleInputValue) {
                                    setIsSchoolTitleOpen(true);
                                  }
                                }}
                                onClose={() => setIsSchoolTitleOpen(false)}
                                inputValue={schoolTitleInputValue}
                                options={[
                                  ...new Set(
                                    schholTitleSuggestions.map((opt) => opt)
                                  ),
                                ]}
                                onChange={handleSchoolTitleChange}
                                onInputChange={(e, value) => {
                                  setSchoolTitleInputValue(value);
                                  if (!value) {
                                    setIsSchoolTitleOpen(false);
                                  }
                                }}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={'School Titles'}
                                    margin='normal'
                                  />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(
                                    option.title,
                                    inputValue
                                  );
                                  const parts = parse(option.title, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </div>

                        <WrapperMain>
                          <FormControlLabel
                            name='isTestAccount'
                            onChange={handleChange}
                            control={<Checkbox />}
                            label='Test Account'
                            value={values.isTestAccount}
                          />
                        </WrapperMain>

                        {isSuggestionDisplay && (
                          <ul className='options mat-options'>
                            {filteredSchoolList.map((schoolObj, index) => {
                              let className;
                              if (index === 1) {
                                //activeOption
                                className = 'option-active';
                              }
                              return (
                                <li
                                  className={className}
                                  value={schoolObj.Id}
                                  key={schoolObj.Id}
                                  onClick={(e) => {
                                    setSuggestionDisplay(false);
                                    setFieldValue('School', e.target.innerText);
                                    setFieldValue('SchoolId', e.target.value);
                                  }}
                                >
                                  {schoolObj.Name}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <FieldWrapper>
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={handleSubmit}
                          >
                            Submit
                          </AppButton>
                        </FieldWrapper>
                      </InputWrapper>
                    </ExtendedAssitanceForm>
                  </RegisterInner>
                </RegisterInnerMain>
              </Grid>
            </Grid>
            <MainFooter>
              <span>
                <img src={footerLogo} alt='logo' />
              </span>
              <span> Survey provided by</span>
            </MainFooter>
          </RegisterWrapper>
        </div>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(RegisterMat));
