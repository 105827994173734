import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import {
  LeftMenu,
  HeatmapCell,
  AdminHeader,
  MainHeader,
} from '../../components';
import {
  AssistanceWrapper,
  DownloadButtonStyle,
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { GroupDashboardMainWrapper } from '../dashboard/DashboardStyle';
import { iconDownload } from '../../resources/images/index';
import Tooltip from '@mui/material/Tooltip';
import SurveyListDropDown from '../../components/common/SurveyListDropDown';
import { MenuItems, WebStorageNames } from '../../utils';
import { parseJwt, amplitudeLog } from '../../utils/CommonFunctions';
import { SummaryReportWrapper, SurveyReportWrapper } from './SurveyReportStyle';
import { surveyReportService, storageService } from '../../services';
import { withTranslation, WithTranslation } from 'react-i18next';
//import { getQueryStringVal } from '../../utils/CommonFunctions';
//import { WebStorageNames, QueryStringTokens } from '../../utils/Constants';

class SummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: '',
      surveyName: '',
      closedOn: '',
      hasReport: false,
      additionalQuestionIds: [],
      additionalQuestionsScores: [],
      categoryAverage: [],
      hasAdditionalQuestions: false,
      hasPreviousSurvey: false,
      heatmap: [],
      lowQuestions: [],
      noOfParticipation: 0,
      noofTotalStaff: 0.0,
      percentageofParticipation: 0.0,
      topQuestions: [],
      message: '',
      hasTopManagementStandards: false,
      hasAverageManagementStandards: false,
      topManagementStandards: '',
      averageManagementStandards: '',
      jobRoleInManagementStandardsTop: [],
      jobRoleInManagementStandardsLow: [],
      region: storageService.getItem(WebStorageNames.UsRegion),
    };
  }
  loadData = (token = null) => {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const surveyToken = token ? '?token=' + token : window.location.search;
    surveyReportService
      .GetSchoolSummaryReport(surveyToken)
      .then((response) => {
        loaderActions.dataLoading(false);
        if (response != null) {
          if (response.success) {
            this.setState({
              schoolName: response.data.schoolInfo.SchoolName,
              surveyName: response.data.report.SurveyName,
              closedOn: response.data.report.ClosedOn,
              hasReport: response.data.report.HasReport,
              additionalQuestionIds: response.data.report.AdditionQuestionIds,
              additionalQuestionsScores:
                response.data.report.AdditionalQuestionsScores,
              categoryAverage: response.data.report.CategoryAverage,
              hasAdditionalQuestions:
                response.data.report.HasAdditionalQuestions,
              hasPreviousSurvey: response.data.report.HasPreviousSurvey,
              heatmap: response.data.report.Heatmap,
              lowQuestions: response.data.report.LowQuestions,
              noOfParticipation: response.data.report.NoofParticipation,
              noofTotalStaff: response.data.report.NoofTotalStaff,
              percentageofParticipation:
                response.data.report.PercentageofParticipation,
              topQuestions: response.data.report.TopQuestions,
              hasTopManagementStandards:
                response.data.report.HasTopManagementStandards,
              hasAverageManagementStandards:
                response.data.report.HasAverageManagementStandards,
              topManagementStandards:
                response.data.report.TopManagementStandards,
              averageManagementStandards:
                response.data.report.AverageManagementStandards,
              jobRoleInManagementStandardsTop:
                response.data.report.JobRoleInManagementStandardsTop,
              jobRoleInManagementStandardsLow:
                response.data.report.JobRoleInManagementStandardsLow,
            });
          } else {
            this.setState({
              message: response.message,
            });
          }
        }
      })
      .catch(() => {
        loaderActions.dataLoading(false);
        this.setState({ loaded: false });
      });
  };
  componentDidMount() {
    this.props.navigationActions.activeItemAction({
      item: MenuItems.SummaryReport,
      showReportSubMenu: true,
    });
    this.loadData();
    amplitudeLog('PageView', 'Report', '');
  }
  getHeatmapCell = (level, value, isDecile, preVal) => {
    return (
      <div
        className={
          level <= 3
            ? 'width-20-green'
            : level >= 7
            ? 'width-20-red'
            : 'width-10'
        }
      >
        {isDecile ? (
          <>
            {level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
          </>
        ) : preVal ? (
          <>
            {value.toFixed(2)}
            <span style={{ fontSize: '10px' }}> ({preVal.toFixed(2)})</span>
          </>
        ) : (
          value.toFixed(2)
        )}
      </div>
    );
  };
  getHeatmapCellMd = (level, value, isDecile) => {
    return (
      <div
        className={
          level <= 3
            ? 'width-20-green'
            : level >= 7
            ? 'width-20-red'
            : 'width-10'
        }
      >
        {value >= 1 ? (
          <>
            {' '}
            {value.toFixed(2)}
            <span style={{ fontSize: 8 }}>
              ({level}
              <sup>
                {level == 1
                  ? 'st'
                  : level == 2
                  ? 'nd'
                  : level == 3
                  ? 'rd'
                  : 'th'}
              </sup>
              )
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    );
  };
  getAdditionalQuestionsTextData() {
    return (
      <div className='section'>
        <div className='job-role-container'>
          <div className='stats-title-cell' style={{ width: '10%' }}>
            Question
          </div>
          <div className='stats-role-cell' style={{ width: '90%' }}>
            Question Text
          </div>
        </div>
        {this.state.additionalQuestionIds.map((item) => (
          <div className='job-role-container'>
            <div className='job-role-cell' style={{ width: '10%' }}>
              Q: {item.Order}
            </div>
            <div className='job-role-title-cell' style={{ width: '90%' }}>
              {item.Text}
            </div>
          </div>
        ))}
      </div>
    );
  }

  getAdditionalQuestionsDataForJobRole() {
    var data = Object.entries(this.state.additionalQuestionsScores);
    if (data.length > 0)
      return (
        <div className='section'>
          <div className='job-role-container'>
            <div className='stats-title-cell'> JOB ROLE</div>
            {this.state.additionalQuestionIds.map((item) => (
              <div className='stats-role-cell'>Q: {item.Order}</div>
            ))}
          </div>

          {data.map((item) => {
            let score = item[1];
            return (
              <div className='job-role-container'>
                <div className='job-role-title-cell'>{item[0]}</div>

                {score.map((score) => {
                  return (
                    <div className='job-role-cell'>{score.toFixed(2)}</div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
  }
  render() {
    return (
      <div>
        <AdminHeader />
        <SurveyReportWrapper className='survey-main-wrapper'>
          <div className='left-container'>
            <LeftMenu />
          </div>

          <div className='right-container'>
            <MainHeader />

            <Grid container>
              <Grid item md={12} xs={12}>
                <AssistanceWrapper>
                  <div className='header-bottom'>
                    <div className='btn-download'>
                      <Tooltip title='Download' followCursor>
                        <DownloadButtonStyle
                          data-id-div='reportDownload'
                          onClick={() => {
                            if (this.state.hasReport) {
                              loaderAction.dataLoading(true);
                              const surveyToken = window.location.search;
                              surveyReportService
                                .GetSchoolSummaryReportPdf(surveyToken)
                                .then((response) => {
                                  const url = window.URL.createObjectURL(
                                    new Blob([response], {
                                      type: 'application/pdf',
                                    })
                                  );
                                  const link = document.createElement('a');
                                  link.href = url;
                                  link.setAttribute(
                                    'download',
                                    'summary-report.pdf'
                                  );
                                  document.body.appendChild(link);
                                  loaderAction.dataLoading(false);
                                  link.click();
                                })
                                .catch(() => {
                                  loaderAction.dataLoading(false);
                                });
                            }
                          }}
                        >
                          <img src={iconDownload} alt='' />{' '}
                          <span>Download</span>
                        </DownloadButtonStyle>
                      </Tooltip>
                    </div>
                    <div className='dd-survey'>
                      <SurveyListDropDown />
                    </div>
                  </div>
                </AssistanceWrapper>
              </Grid>
              {this.state.hasReport ? (
                <Grid item lg={12} md={12} xs={12}>
                  <GroupDashboardMainWrapper>
                    <SummaryReportWrapper>
                      <div className='report-section'>
                        <div className='center-text'>
                          {this.state.schoolName}
                        </div>
                        <div className='center-text'>
                          {this.state.surveyName} / {this.state.closedOn}
                        </div>
                        <div className='table-of-content'>
                          <h2>Table of Contents</h2>
                          <ol>
                            <li>
                              <a href='#management-standard'>
                                The Management Standards
                              </a>
                            </li>
                            <li>
                              <a href='#summery-score'>Summary of Scores</a>
                            </li>
                            <li>
                              <a href='#question-score'>
                                Highest and Lowest Scoring Questions
                              </a>
                            </li>
                            {this.state.hasAdditionalQuestions ? (
                              <li>
                                <a href='#additional-question'>
                                  Additional Questions
                                </a>
                              </li>
                            ) : (
                              ''
                            )}
                            <li>
                              <a href='#next-step'>Next Steps</a>
                            </li>
                          </ol>
                        </div>
                      </div>
                      {/* The Management Standards */}
                      <div className='report-section' id='management-standard'>
                        <h1 className='report-heading'>
                          1. The Management Standards
                        </h1>
                        <p className='section'>
                          The survey used is the Health and Safety (HSE)
                          Indicator Tool. This is evidence built and DfE Staff
                          Wellbeing Charter {this.props.t('recognised')} and
                          measures how staff view their working environment
                          against the Health and Safety Executive's (HSE)
                          Management Standards.
                        </p>
                        <p className='section'>
                          These Standards can be used to define the
                          characteristics and culture of an{' '}
                          {this.props.t('organisation')} and schools, where the
                          risks from work related stress and poor staff
                          wellbeing are being effectively managed and
                          controlled. They cover the six key areas of work
                          design, that if not properly managed, are associated
                          with poor health and wellbeing, lower productivity,
                          increased sickness absence and lower staff retention.
                        </p>
                        <h3>The Six Management Standards Are:</h3>
                        <div className='section'>
                          <ul>
                            <li>
                              <strong>Demands</strong> - workload, work
                              patterns, working hours, deadlines, time given to
                              complete tasks, the {this.props.t('behviour')} of
                              students and anything else that adds demands for
                              staff.
                            </li>
                            <li>
                              <strong>Control</strong> - the level of autonomy a
                              staff member feels they have, including the say
                              they have over what they do at work, how they work
                              and the level of flexibility available.
                            </li>
                            <li>
                              <strong>Support</strong> (
                              {this.props.t('managerCW')} and Peer) -- the
                              encouragement, sponsorship and support they
                              receive from {this.props.t('managers')} and peers.
                            </li>
                            <li>
                              <strong>Relationships</strong> - the extent to
                              which staff {this.props.t('behviour')} is
                              appropriate, positive working is promoted to avoid
                              conflict and unacceptable{' '}
                              {this.props.t('behviour')} is effectively dealt
                              with.
                            </li>
                            <li>
                              <strong>Role</strong> - how well staff members
                              understand their role, what is expected of them,
                              how it fits with the role of their department or
                              function and with the overall aim of the school
                            </li>
                            <li>
                              <strong>Control</strong> - how changes at school
                              (large or small) are managed and communicated to
                              staff, including effective consultation and
                              whether opportunities are given for staff to
                              question leaders.
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* Summery of Score */}
                      <div className='report-section' id='summery-score'>
                        <h1 className='report-heading'>2. Summary of Scores</h1>
                        <p className='section'>
                          You can see further information on interpreting your
                          results in the{' '}
                          <a href='https://knowledge.welbee.co.uk/your-survey-results'>
                            <strong>user guide</strong>
                          </a>
                          .
                        </p>
                        <p className='section'>
                          {this.state.noOfParticipation} number of staff
                          participated in the survey, which is{' '}
                          {(
                            (this.state.noOfParticipation /
                              this.state.noofTotalStaff) *
                            100
                          ).toFixed(2)}{' '}
                          % of the staff invited.
                        </p>
                        <p className='section'>
                          The average scores for all your survey participants
                          for each of the Management Standards are shown in
                          heatmap 1 below. Question scores are out of 5, with 5
                          being the highest and 1 the lowest, and using the
                          Likert scale (Never, Seldom, Sometimes, Often,
                          Always). Question scores are aggregated into groups to
                          provide scores for each of the Management Standards.
                        </p>
                        <p className='section'>
                          They are ranked from the highest to the lowest
                          comparative score - that is how your scores compare to
                          the Welbee school benchmark. Staff score differently
                          in each of the Management Standards and the benchmarks
                          for each are not the same.
                        </p>
                        <p className='section'>
                          This is the best indicator of your current position,
                          rather than the total score, as it takes account of
                          how staff across the schools sector score in each of
                          the Standards.The Health and Safety (HSE) benchmark is
                          also shown and this was established across a wider
                          number of business sectors and not simply education.
                        </p>
                        <p className='section'>
                          Comparatively high scores are shaded green, in the top
                          three deciles (top 30%) when compared to the school
                          benchmark, while scores in red are in the 7th decile
                          and below (bottom 40%). Those without shading are
                          close to average.
                        </p>
                        <p className='table-title'>
                          Table 1: Staff Participation Summary of{' '}
                          {this.state.region === 'us' ? 'District' : 'MAT'}{' '}
                          Scores
                        </p>
                        <div className='section'>
                          <div className='job-role-container'>
                            <div className='stats-title-cell'>
                              Management Standards
                            </div>
                            <div className='stats-role-cell'>Score</div>
                            <div className='stats-role-cell'>School Decile</div>
                            <div className='stats-role-cell'>HSE Decile</div>
                          </div>
                          {this.state.categoryAverage.map((row) => {
                            return (
                              <div className='job-role-container'>
                                <div className='job-role-title-cell'>
                                  {row.CategoryName}
                                </div>
                                {this.getHeatmapCell(
                                  row.SchoolDecileType,
                                  row.OverallCategoryAverage,
                                  false,
                                  row.OverallCategoryAveragePrevious
                                )}
                                {this.getHeatmapCell(
                                  row.SDOverallCatAvg,
                                  row.SDOverallCatAvg,
                                  true
                                )}
                                {this.getHeatmapCell(
                                  row.HseDecileValue,
                                  row.HseDecileValue,
                                  true
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {this.state.hasPreviousSurvey ? (
                          <p style={{ fontSize: 12 }}>
                            * Scores in brackets show your previous survey score
                          </p>
                        ) : (
                          ''
                        )}
                        {this.state.hasTopManagementStandards &&
                        this.state.hasAverageManagementStandards ? (
                          <p className='section'>
                            The average scores for your {this.state.schoolName}{' '}
                            for {this.state.topManagementStandards} are in the
                            top 30% (deciles 1 - 3).{' '}
                            {this.state.averageManagementStandards} are also
                            above the average for all schools.
                          </p>
                        ) : (
                          ''
                        )}
                        {!this.state.hasTopManagementStandards &&
                        this.state.hasAverageManagementStandards ? (
                          <p className='section'>
                            The average scores for your {this.state.schoolName}{' '}
                            are above the average for{' '}
                            {this.state.averageManagementStandards}.
                          </p>
                        ) : (
                          ''
                        )}
                        <p className='section'>
                          A comprehensive report with suggested actions, where
                          appropriate, is provided for each of the Management
                          Standards to allow you to quickly see where to
                          celebrate and focus attention.
                        </p>
                        <p className='section'>
                          Scores may vary for different job roles and you can
                          see these in Heatmap 2 below. Further filters,
                          heatmaps and analytics are available to view and
                          download from your dashboard.
                        </p>
                        <p className='section'>
                          Where open questions were included in your survey, the
                          majority of staff members will have provided comments.
                          These will provide further insights into the scores
                          received, as well as identify trends and support you
                          in taking the most appropriate action.
                        </p>
                        <p className='table-title'>
                          Heatmap 2: Scores by Core Job Role
                        </p>
                        <p className='section'>
                          The average scores for participants in each of the
                          core job role filters are shown in heatmap 2 below.
                          Again comparatively high scores are shaded green and
                          comparatively lower ones red. Those without shading
                          are close to average.
                        </p>

                        <div className='job-role-container'>
                          <div style={{ width: '25%', float: 'left' }}>
                            &nbsp;
                          </div>
                          <div className='width-10'>Demands</div>
                          <div className='width-10'>Control</div>
                          <div className='width-10'>Manager Support</div>
                          <div className='width-10'>Peer Support</div>
                          <div className='width-10'>Relationship</div>
                          <div className='width-10'>Role</div>
                          <div className='width-10'>Change</div>
                        </div>
                        <div style={{ width: '100%' }}>
                          {this.state.heatmap.map((row) => {
                            return (
                              <div className='job-role-container'>
                                <div
                                  style={{ width: '25%', marginLeft: '0px' }}
                                  className='job-role-title-cell'
                                >
                                  {row.Name}
                                </div>
                                {this.getHeatmapCellMd(
                                  row.DemandDecile,
                                  row.Demand,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.ControlDecile,
                                  row.Control,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.ManagerSupportDecile,
                                  row.ManagerSupport,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.PeerSupportDecile,
                                  row.PeerSupport,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.RelationshipsDecile,
                                  row.Relationships,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.RoleDecile,
                                  row.Role,
                                  false
                                )}
                                {this.getHeatmapCellMd(
                                  row.ChangeDecile,
                                  row.Change,
                                  false
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {this.state.jobRoleInManagementStandardsTop.length >
                        0 ? (
                          <p className='section'>
                            The three highest comparative scores are:
                            <ul>
                              {this.state.jobRoleInManagementStandardsTop.map(
                                (item) => {
                                  return (
                                    <li>
                                      {item.JobRole} for{' '}
                                      {item.ManagementStandard}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </p>
                        ) : (
                          ''
                        )}
                        {this.state.jobRoleInManagementStandardsLow.length >
                        0 ? (
                          <p className='section'>
                            The three lowest comparative scores are:
                            <ul>
                              {this.state.jobRoleInManagementStandardsLow.map(
                                (item) => {
                                  return (
                                    <li>
                                      {item.JobRole} for{' '}
                                      {item.ManagementStandard}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </p>
                        ) : (
                          ''
                        )}
                        <p className='section'>
                          Using heatmaps in your dashboard you can apply further
                          filters to identify where scores may be higher and
                          lower.
                        </p>
                      </div>
                      {/* The Five Highest and Lowest Scoring Questions */}
                      <div className='report-section' id='question-score'>
                        <h1 className='report-heading'>
                          3. The Five Highest and Lowest Scoring Questions
                        </h1>
                        <p className='section'>
                          In the table below you can see the scores and how
                          these compare to the school benchmark for the five
                          highest and lowest scoring questions. The benchmark
                          differs for each of the questions - overall staff will
                          score higher or lower and it is why comparative,
                          rather than absolute scores are important in
                          considering actions.
                        </p>
                        <div className='job-role-container'>
                          <div className='heatmap-title'>
                            The Five Highest Scoring Questions
                          </div>
                          <div
                            className='heatmap-title'
                            style={{ width: '17.4%' }}
                          >
                            School Decile
                          </div>
                        </div>
                        {this.state.topQuestions.map((item) => {
                          return (
                            <div className='job-role-container'>
                              <div className='width-20-grey'>
                                {item.Category}
                              </div>
                              <div className='width-60-grey'>
                                Q{item.Order}: {item.Text}
                              </div>
                              <div className='width-18-grey'>
                                {item.Decile}
                                <sup>
                                  {item.Decile === 1
                                    ? 'st'
                                    : item.Decile == 2
                                    ? 'nd'
                                    : item.Decile == 3
                                    ? 'rd'
                                    : 'th'}
                                </sup>
                              </div>
                            </div>
                          );
                        })}
                        {this.state.lowQuestions.length > 0 ? (
                          <>
                            {' '}
                            <div className='job-role-container'>
                              <div className='heatmap-title'>
                                The Five Lowest Scoring Questions
                              </div>
                              <div
                                className='heatmap-title'
                                style={{ width: '17.4%' }}
                              >
                                School Decile
                              </div>
                            </div>
                            {this.state.lowQuestions.map((item) => {
                              return (
                                <div className='job-role-container'>
                                  <div className='width-20-grey'>
                                    {item.Category}
                                  </div>
                                  <div className='width-60-grey'>
                                    Q{item.Order} : {item.Text}
                                  </div>
                                  <div className='width-18-grey'>
                                    {item.Decile}
                                    <sup>
                                      {item.Decile === 1
                                        ? 'st'
                                        : item.Decile == 2
                                        ? 'nd'
                                        : item.Decile == 3
                                        ? 'rd'
                                        : 'th'}
                                    </sup>
                                  </div>
                                </div>
                              );
                            })}{' '}
                          </>
                        ) : (
                          <p className='table-title width-60-grey'>
                            There are no question scoring low.
                          </p>
                        )}
                      </div>
                      {this.state.hasAdditionalQuestions ? (
                        <div
                          className='report-section'
                          id='additional-question'
                        >
                          <h1 className='report-heading'>4. Your Questions</h1>
                          <p className='section'>
                            The table below shows the scores for all staff and
                            job role core filters for the additional questions
                            you chose to use in your survey. While they are not
                            benchmarked, you can see how different job roles
                            have scored, and you will need to use the Likert
                            scale and your judgement in assessing these.
                          </p>
                          <p className='section'>
                            You can see how different job roles have scored and
                            using the heatmaps in your dashboard you will be
                            able to filter this further. In addition, staff
                            comments are also likely to provide further insights
                            about these scores.
                          </p>
                          <p className='table-title'>Your Questions</p>
                          {this.getAdditionalQuestionsTextData()}
                          <p className='table-title'>
                            Your Question Scores by Job Role
                          </p>
                          {this.getAdditionalQuestionsDataForJobRole()}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Next steps */}
                      <div className='report-section' id='next-step'>
                        <h1 className='report-heading'>
                          {this.state.hasAdditionalQuestions ? '5' : '4'}. Next
                          Steps
                        </h1>
                        <p className='section'>
                          This summary report provides a snapshot of the full
                          results available in your dashboard.It is suggested
                          you read the full report first as this will provide
                          you with further details for each Management Standard.
                        </p>
                        <p className='section'>
                          You will see a horizontal bar chart before any
                          suggested action and this shows those staff groups
                          with high and low comparative scores against the
                          questions that generated the action. Use this to help
                          decide where you might want to focus energy and
                          effort.
                        </p>
                        <p className='section'>
                          You can also use the Heatmap and Analytics menus to
                          look at all filters available, as well as clicking
                          directly on your dashboard scores to see heatmaps and
                          scores for each of the questions. These together with
                          staff comments (if you used open questions in your
                          survey) will provide further insight into your scores.
                        </p>
                        <p className='section'>
                          The{' '}
                          <a
                            href='https://toolkit.welbee.co.uk/'
                            target='_blank'
                          >
                            Welbee Toolkit
                          </a>{' '}
                          has resources and training available for leaders and
                          staff to support them in building staff wellbeing into
                          the culture, as well as managing their own wellbeing.
                          All staff uploaded to Welbee have access using their
                          email address and you just need to provide the
                          following link to them. Using the first box they see
                          they will be able to validate their email and then
                          login.
                        </p>
                        <p className='section'>
                          <a
                            href='https://toolkit.welbee.co.uk/'
                            target='_blank'
                          >
                            https//toolkit.welbee.co.uk.
                          </a>
                        </p>
                        <p className='section'>
                          Please contact{' '}
                          <a href='mailto:support@welbee.co.uk' target='_blank'>
                            support
                          </a>{' '}
                          (or use the email icon top right in your dashboard) to
                          get in touch, ask questions or to book a results
                          review.
                        </p>
                      </div>
                    </SummaryReportWrapper>
                  </GroupDashboardMainWrapper>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} md={12} xs={12}>
                    <GroupDashboardMainWrapper>
                      <div
                        className='error-message'
                        style={{ marginTop: '50px' }}
                      >
                        {this.state.message}
                      </div>
                    </GroupDashboardMainWrapper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </SurveyReportWrapper>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(SummaryReport));
