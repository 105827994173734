import request from '../request';
import { errorLoggingAPI } from './urls';

class LoggerService {
  static log(message, file, line, column, errorObject) {
    const LogBody = {
      ErrorMessage:
        'Message: ' +
        message +
        ' \nFile: ' +
        file +
        ' \nLine Number: ' +
        line +
        ' \nColumn: ' +
        column +
        ' \nError Object: ' +
        errorObject,
      Module: 'webapp',
      Source: 'Welbee.WebApp',
      Url: window.location.href,
      LineNumber: line
    };
    request({
      url: errorLoggingAPI.api,
      method: 'POST',
      data: LogBody
    }).then(response => console.log(response));
  }
}

export default LoggerService;
