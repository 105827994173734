import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { SelectWrapper, DesktopLogoWrapper } from '../../resources/styling/appStyle';

const {
    mineShaft, pictonBlue, teal,
    twilightBlue,
    whiteColor,
    mandy,
    lightGreen,
    redColor    
} = colors;

/*** Input Textarea ***/
export const InputTxtareaStyle = styled.textarea`
  width: 100%;
  min-height: 180px;
  border-radius: 5px;
  border: 1px solid ${pictonBlue};
  opacity: 0.5;
  resize: none;
  padding: 20px;
  color: ${mineShaft};
  font-size: 24px;
  background: ${twilightBlue};
  font-family: 'Avenir' !important;
  font-weight: 300;
  :focus {
    opacity: 1;
    border: 2px solid ${pictonBlue};
  }

  ${media.xs`
  min-height: 125px;
  padding: 11px;
  font-size: 16px;
  `}

  ${media.sm`
  min-height: 125px;
  padding: 11px;
  font-size: 20px;
  `}

  ${media.xl`
  width: 88%;
  min-height: 220px;
  margin-top: 15px;
  `}
`;

/*** Questions ***/
export const TestDiv = styled.div`
  h3 {
    margin: 10px 0 0 0;
  }
`;

/*** Survey ***/
export const ExtendedSelectWrapper = styled(SelectWrapper)`
.selected-option{
  border: 1px solid ${pictonBlue};
  border-radius: 5px;
}

${media.lg`
  width: 84%;
    margin: 0 0 20px 49px;
  `}
  ${media.xl`
    width: 90%;
    margin: 10px 0 20px 49px;
  `}

  padding-top: 1px;
  position: relative;
  width: 90% !important;

  .error-message {
    position: absolute;
   top: 0;
    left: 10px;
    color: ${redColor}

    ${media.xs`
    top: -33px;

    left: 0;
    z-index: 1;
    width: 100%;
    font-size: 15px;
    line-height: 14px;
  `}

  ${media.md`
  top: -33px;
    left: 0;
    z-index: 1;
    width: 100%;
    font-size: 15px;
    line-height: 14px;
  `}
  ${media.lg`

    top: -17px;
    width: 98%;

  `}
  ${media.xl`
width: 98%;
`}
  }
  ${media.xs`
  width: 84% !important;
  margin: 0 20px 20px 20px;
  `}

  .category-inner-wrapper {
    width: 48.5%;
    margin: 0 20px 7px 0;
    position: relative;
    overflow: initial;
    @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
        overflow: visible;
    }
    ${media.xs`
      width: 100%;
    `}
    ${media.md`
      width: 100%;
    `}
    ${media.lg`
      width: 100%;
    `}
    ${media.xl`
      width: 100%;
    `}
    .options {
      position: absolute;
      z-index: 99999;
      width: 100%;
      border: 1px solid;
      background: ${whiteColor};
      top: 0;
      overflow-y: auto;
    }
    &:nth-child(even) {
      margin: 0 7px 7px 0;
      ${media.xs`
      margin: 0 0 15px 0;
      `}
    }
    .options {
      span{
        font-size: 14px;
      }

    }
  }

  .selected-option {
    padding: 12px 29px 12px 14px;
    font-size: 14px;
    display: flex;

    img {
      position: absolute;
      right: 14px;
    }
  }
`;
export const SlideWrapper = styled.div`
  margin-top: 0 !important;
  padding-top: 68px;
  overflow-x: hidden;
  ${media.lg`
  min-height: calc(83vh - 10px);
  `}
  ${media.xl`
  min-height: calc(88vh - 10px);
  `}
  .carousel img {
    width: auto !important;
  }
  .slide {
    background: ${twilightBlue} !important;
    border-radius: 5px;
    position: relative;
    opacity: 0.3;
    pointer-events: none;
  }

  .selected {
    opacity: 1;
    pointer-events: auto;
  }
`;
export const MobileQuestionHelpGuide = styled.div`
  margin: 0;
  padding: 10px;
  background: ${mandy};
  color: ${whiteColor};
  font-size: 12px;
  font-weight: 900;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
    z-index: 2;
  ${media.xs`
  display: block;
  padding: 20px;
    line-height: 16px;
  `}
  ${media.md`
  display: block;
  padding: 20px;
    line-height: 16px;
    font-size: 14px;
  `}
  ${media.lg`
  display: none;
  `}
`;
export const QuestionHelpGuide = styled.div`
  width: 188px;
  border: 1px solid ${lightGreen};
  color: ${teal};
  font-size: 14px;
  font-weight: 900;
  position: relative;
  left: 12px;
  top: 89px;
  border-radius: 4px;
  padding: 20px;
  line-height: 30px;
  float: left;
    z-index: 999;
  ${media.xs`
  display: block;
  width: 25px;
  height: 100px;
  overflow: hidden;
  padding: 2px;
  border:transparent;
  color: transparent;
  `}
  ${media.sm`
  display: block;
  width: 93px;
  border:transparent;
  color: transparent;
  content: '';
  `}
  ${media.md`
  display: block;
  width: 93px;
  border:transparent;
  color: transparent;
  content: '';
  `}
  ${media.lg`
  display: block;
  border: 1px solid ${lightGreen};
  color: ${teal};
  width: 188px;
  margin-right: 20px;
  `}
`;
export const SurveySubmitWrapper = styled.div`
  display: table;
  height: 69vh;
  text-align: center;
  width: 74%;
  margin: auto;
  padding: 0 45px;
  ${media.xs`
  padding: 0;
  width: 84%;
  height: 62vh;
  button{
    margin-bottom: 5px;
    padding: 23px 20px;

  }
    `}
    ${media.md`
    width: 90%;
    `}
    ${media.lg`
    width: 74%;
    `}
  h1 {
    font-size: 48px;
    ${media.sm`
    font-size: 18px;
    `}
    ${media.xs`
    font-size: 18px;
    `}

    ${media.md`
    font-size: 48px;
    `}
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 37px;
    padding: 0 100px;
    width: 80%;
    margin: 0 auto 25px auto;
    ${media.sm`
    padding: 10px 20px ;
    font-size: 16px;
    line-height: 21px;
    `}
    ${media.xs`
    padding: 10px 0;
    font-size: 16px;
    line-height: 21px;
    `}

    ${media.md`
    padding: 10px 20px;
    font-size: 20px  !important;
    line-height: 29px !important;
    width: 94%;
    `}


    ${media.lg`
    padding: 10px 20px;
    font-size: 20px  !important;
    line-height: 34px !important;
    width: 80%;
    `}
  }
`;
export const SurveySubmitForm = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
export const QuestionInnerWrapper = styled.div`  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5em;
  ${media.xs`
  justify-content: end;
  min-height: auto;
  `};
  ${media.md`
  padding: 0 15px;
  `}
  ${media.lg`
  padding: 0;
  `}

  .noteText {
    float: left !important;
    margin:-30px 0px 0px 0px !important;
    color: #363636 !important;
    text-align: left;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 1.5 !important ;
  }
  // .minHeight{
  //   min-height: 20vh !important;
  // }
`;

/*** Survey Complete ***/
export const ExtendedDesktopWraper = styled(DesktopLogoWrapper)`
  ${media.lg`
height: calc(100vh - 126px);
`}
`;
export const WelcomeHeaderWrapper = styled.div`
  > div {
    display: block;
  }
`;

const SurveyStyle = {
    InputTxtareaStyle,
    TestDiv,
    ExtendedSelectWrapper,
    SlideWrapper,
    MobileQuestionHelpGuide,
    QuestionHelpGuide,
    SurveySubmitWrapper,
    SurveySubmitForm,
    QuestionInnerWrapper,
    ExtendedDesktopWraper,
    WelcomeHeaderWrapper
}
export default SurveyStyle;