
import request from '../request';
import { staffResultHeatMapAPI } from './urls';

export const getStaffResultHeatMap = (token) => {
  return request({
    url: staffResultHeatMapAPI.api(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getMatCycleHeatMap = (
  token,
  matGroupId,
  matCycleId,
  isIndividualSchool
) => {
  const userToken = token ? token : '';
  //const individualSchoolToken = schoolToken ? schoolToken : '';
  return request({
    url: staffResultHeatMapAPI.getMatCycleHeatMap(
      userToken,
      matGroupId,
      matCycleId,
      isIndividualSchool
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
export const getGroupHeatMap = (
  schoolToken,
  token,
  IsMatSchoolFromHeatmap,
  matGroupId,
  matCycleId
) => {
  const schoolIdToken = schoolToken ? schoolToken : '';
  const userToken = token ? token : '';
  const valIsMatSchoolFromHeatmap = IsMatSchoolFromHeatmap
    ? IsMatSchoolFromHeatmap
    : 'false';
  return request({
    url: staffResultHeatMapAPI.getGroupHeatMap(
      schoolIdToken,
      userToken,
      valIsMatSchoolFromHeatmap,
      matGroupId,
      matCycleId
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getGroupName = (schoolToken, token) => {
  const schoolIdToken = schoolToken ? schoolToken : '';
  const userToken = token ? token : '';
  return request({
    url: staffResultHeatMapAPI.getGroupName(schoolIdToken, userToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
export const getStaffResultHeatMapDownload = (token, ids) => {
  return request({
    url: staffResultHeatMapAPI.getStaffResultHeatMapDownload(token, ids),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};
export const getMATHeatMapDownload = (
  data,
  heatmaps,
  token,
  isAnnonymised,
  matId,
  schoolId
) => {
  return request({
    url: staffResultHeatMapAPI.getMATHeatMapDownload(
      heatmaps,
      token,
      isAnnonymised,
      matId,
      schoolId
    ),
    method: 'POST',
    data: data,
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getCustomHeatMap = (model,token,schoolToken) => {
  return request({
    url: staffResultHeatMapAPI.getCustomHeatmaps(token,schoolToken),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const saveHeatmapFilters = (model,token,schoolToken) => {
  return request({
    url: staffResultHeatMapAPI.saveHeatmapFilters(token,schoolToken),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getCutomHeatmapById = (Id) => {
  return request({
    url: staffResultHeatMapAPI.getCutomHeatmapById(Id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getCutomHeatmapFiltersBySchoolId = (schoolId) => {
  return request({
    url: staffResultHeatMapAPI.GetCutomHeatmapFiltersBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getMatCoreFiltersForHeatMaps = (matGroupId, matCycleId) => {
  return request({
    url: staffResultHeatMapAPI.getMatCoreFiltersForHeatMap(matGroupId, matCycleId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const downloadCustomSchoolHeatmap = (Id) => {
  return request({
    url: staffResultHeatMapAPI.getCustomSchoolHeatmapPdf(Id),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
}; 

const updateHeatmapFilters = (model, customHeatmapId) => {
  return request({
    url: staffResultHeatMapAPI.UpdateHeatmapFilters(customHeatmapId),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const deleteCustomHeatmap = (Id) => {
  return request({
    url: staffResultHeatMapAPI.DeleteCustomHeatmap(Id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};


const staffResultServices = {
  getStaffResultHeatMap,
  getGroupHeatMap,
  getMatCycleHeatMap,
  getGroupName,
  getStaffResultHeatMapDownload,
  getMATHeatMapDownload,
  getCustomHeatMap,
  saveHeatmapFilters,
  getCutomHeatmapById,
  getCutomHeatmapFiltersBySchoolId,
  getMatCoreFiltersForHeatMaps,
  downloadCustomSchoolHeatmap,
  updateHeatmapFilters,
  deleteCustomHeatmap
};

export default staffResultServices;
