import React from 'react';

const LearnSupport = () => {
  return (
    <div>
      <p>
      Thank you for being part of our community and using welbee. If you have any questions or need support 
      then please use the request assistance button from within your dashboard and send a message. 
      We will respond by the close of the following working day at the latest.
      </p>
    </div>
  );
};

export default LearnSupport;
