import React from 'react';
import { limitAverageValue } from '../../utils/roundScore';
import { colors } from '../../resources/theme';
const {
    whiteColor,
    minttulip,
    yourpink,
    lightminttulip,
    lightyourpink,
} = colors;
const HeatmapCell = (props) => {
    const decileLevel = props.Decile;
    const value = props.Value;
    const isAllowed = props.Allow;
    const isAllStaff = props.AllStaff;
    const IsAnonymised = props.IsAnonymised;
    const category = props.Category;

    const staffBoxColor = () => {
        if (!isAllowed || category === 'AdditionalQuestions' || category == 'Additional Questions'){
            return whiteColor;
        }else if (decileLevel >= 1 && decileLevel <= 3 && value !== -1) {
            return lightminttulip;
        } else if (decileLevel >= 7 && decileLevel <= 10 && value !== -1) {
            return lightyourpink;
        }
        return whiteColor;
    }  

    const boxColor = () => {
        if (!isAllowed || category === 'AdditionalQuestions'|| category == 'Additional Questions'){
            return whiteColor;
        } else if (decileLevel >= 1 && decileLevel <= 3 && value !== -1) {
            return lightminttulip;
        } else if (decileLevel >= 7 && decileLevel <= 10 && value !== -1) {
            return lightyourpink;
        }
        return whiteColor;
    }

    const checkStaffValue = () => {
        if (value === -1) {
            return <span className='blank-entry'>-</span>;
        }
        return limitAverageValue(value);
    }
    const getStyle = () => {
        return {
            backgroundColor: isAllStaff ? boxColor() : staffBoxColor(),
            border: '1px solid #e2e2e2',
        };

    }
    const getAnonymisedStyle = () => {
        if (IsAnonymised)
            return {
                fontWeight: 900,
                backgroundColor: '#33E5FF',
                border: '#33E5FF 1px solid',
                borderRadius: '50%',
                textAlign: 'center',
                display: 'inline-block',
                width: '60px',
                height: '50px',
                lineHeight: '42px',
            };
        return {};
    }
    const getDecileSupText = (level) => {
        if (level === 1)
            return "st";
        if (level === 2)
            return "nd";
        if (level === 3)
            return "rd";
        return "th";
    }
    return (
        value === -1 ?
            <li style={getStyle()}>
                {checkStaffValue(value)}
            </li> :
            <li style={getStyle()}>                
                <div style={ getAnonymisedStyle()}>
                    {checkStaffValue(value)}
                    {(category !== 'AdditionalQuestions' && category !== 'Additional Questions') ? (<span className="span">{' '}({decileLevel}<sup>{getDecileSupText(decileLevel)}</sup>)</span>) : ('')}
                </div>
                
            </li>);
};
export default HeatmapCell;