import React from 'react';
import { searchIconWhite } from '../../resources/images';
import { SchoolListMainWrapper } from '../../resources/styling/appStyle';
import { AppButton } from '../../resources/styling';
import { Dropdown } from '../../components';
import 'date-fns';
import { colors } from '../../resources/theme/colors';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ActivityReportWrapper, ActivityReportInnerMain, ActivityReportInner, ButtonWrapper } from './ActivityReportStyle';

const { teal } = colors;

const activityReportHeader = (props) => {
  return (
    <div>
      <ActivityReportWrapper>
        <ActivityReportInnerMain>
          <ActivityReportInner>
            <SchoolListMainWrapper>
              <Grid container mt='30px'>
                
                  <Grid item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}                    
                  >
                    <h1 style={{ height: '60px', margin: '0px' }}>{props.title}</h1>
                  </Grid>

                  <Grid item xl={8} lg={8} md={12} sm={12}>
                    <h1 style={{ 'min-height': '60px', margin: '0px' }}>
                      <div className='search-filters status-filter-dropdown'>
                        <Dropdown
                          name='StatusSortFilter'
                          options={props.statusFilterOptions}
                          selectedOption={props.statusFilterSelectedOption}
                          onChange={props.onStatusChange}
                          value={props.statusFilterSelectedOption}
                        />
                      </div>
                      <div className='comments-search-filters'>
                        <div className='subcontainer-inner school-list-header'>
                          <div
                            className='search-filed'
                            style={{ width: '100%' }}
                          >
                            <img src={searchIconWhite} alt='search button' />
                            <input
                              id='search'
                              placeholder='Enter a keyword'
                              icon='search'
                              className='searchField'
                              onChange={props.onSearch}
                            />
                          </div>
                        </div>
                      </div>
                    </h1>
                  </Grid>
                
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="center">

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 4, mt: 4 }} >                  
                    <DatePicker
                      label='From Date'
                      inputFormat='dd-MM-yyyy'
                      value={props.fromDateValue}
                      onChange={props.setFromDate}
                      renderInput={(params) => <TextField {...params} />}
                    />                  
                    <DatePicker
                      label='To Date'
                      inputFormat='dd-MM-yyyy'
                      value={props.toDateValue}
                      onChange={props.setToDate}
                      renderInput={(params) => <TextField {...params} />}
                    />                  
                    <AppButton
                        color={teal}
                        type='button'
                        onClick={props.click}
                        style={{
                          borderBottomWidth: '10px',
                          borderRadius: '50px',
                        }}
                      >
                        Generate
                    </AppButton>
                  </Stack>                  
                </Grid>
              </LocalizationProvider>
              {props.children}
            </SchoolListMainWrapper>
          </ActivityReportInner>
        </ActivityReportInnerMain>
      </ActivityReportWrapper>
    </div>
  );
};

export default activityReportHeader;
