import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { LeftMenu, MainHeader } from '../../components';
import AccessLevel from '../../components/common/AccessLevel';
import SurveyListDropDown from '../../components/common/SurveyListDropDown';
import { iconDownload } from '../../resources/images/index';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import { storageService, surveyReportService } from '../../services';
import { navigationActions } from '../../store/headerStore';
import { loaderAction } from '../../store/loaderStore';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import { amplitudeLog, parseJwt } from '../../utils/CommonFunctions';
import { AccessPermissions, SurveyTypes, WebStorageNames } from '../../utils/Constants';
import { PageOverlay, PresentationWrapperStyle } from './SurveyReportPresentationStyle';
import PresentationV1View from './V1/PresntationV1View';
import PresentationV2View from './V2/PresentationV2View';

let pptx;

let slideColors = {
  white: 'FFFFFF',
  yellow: 'FFCF05',
  purple: '45338C',
  green: '046458',
  orange: 'ff3000',
  lightred: 'F5ABCC',
};

class Presentation extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.state = {
      selectedSlideIndex: 0,
      slide: {
        title: '',
        content: '',
      },
      isPreview: false,
      surveyResult: [],
      surveyData: [],
      selectedItem: 0,
      CategoryType: '',
      CategoryTitle: '',
      QuestionSurveyAverages: [],
      schoolInfo: '',
      surveyStartDate: new Date(),
      surveyEndDate: new Date(),
      SurveysCompletedPercentage: 0,
      message: '',
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      isRole: userInfo.role,
      TemplateId: 0,
      presentationData: []
    };
    IsActionAllowed(RestrictedActions.Presentation)
      .then((response) => {
        if (!response.data) props.history.push('/not-subscribed');
      })
      .catch();
  }

  onKeyDown = (event) => {
    if (event.keyCode) {
      const { selectedItem } = this.state;
      this.setState({ selectedItem: selectedItem + 1 });
    }
  };

  componentDidMount() {
    this.getStaffPresentationReport();
    this.props.navigationActions.activeItemAction({
      item: 'presentation',
    });
    amplitudeLog('PageView', 'Presentation', '');
  }

  getStaffPresentationReport = (token = null) => {
    const { action } = this.props;
    action.dataLoading(true);
    const surveyToken = window.location.search;

    let categoryTypes = {
      ManagerSupport: 'ManagerSupport',
      PeerSupport: 'PeerSupport',
    };

    let categoryTypesText = {
      ManagerSupport: 'Manager Support',
      PeerSupport: 'Peer Support',
      Support: 'Support',
    };

    surveyReportService
      .getStaffPresentationReport(surveyToken)
      .then((response) => {
        const { data } = response;
        if (!response.success) {
          this.setState({
            message: response.message,
          });
          action.dataLoading(false);
          return;
        }

        this.setState({
          TemplateId: data?.TemplateId,
          presentationData: data?.staffPresentationData
        });

        let surveyResult = data.staffPresentationData;
        action.dataLoading(false);
      })
      .catch((response) => {
        action.dataLoading(false);
        return response;
      });
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  handlePresentationPreview = () => {
    this.setState({ isPreview: true });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  render() {
    const {
      presentationData
    } = this.state
    return (
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
        <div>
          {this.state.isPreview && (
            <PageOverlay onClick={() => this.setState({ isPreview: false })} />
          )}

          <PresentationWrapperStyle>
            <div className='left-container'>
              <LeftMenu />
            </div>

            <div className='right-container'>
              <MainHeader />
              <Grid container>
                <Grid item md={12} xs={12}>
                  <AssistanceWrapper>
                    <div className='header-bottom'>
                      <div className='footer-buttons'>
                        {isEmpty(this.state.message) ? (
                          <Tooltip title="Download" followCursor>
                            <button onClick={() => { this.printSurveyReportPresentation(); }}>
                              <img src={iconDownload} alt='' /> <span>Download</span>
                            </button>
                          </Tooltip>
                        ) : ("")}
                      </div>
                      <div className='dd-survey'>
                        <SurveyListDropDown />
                      </div>
                    </div>
                  </AssistanceWrapper>
                </Grid>

                <Grid item md={12} xs={12}>
                  {!isEmpty(this.state.message) ? (
                    <div className='error-message'>{this.state.message}</div>
                  ) : (
                    <div className='presentation-slide'>

                      {this.state.TemplateId === 1 && (
                        <PresentationV1View staffPresentationData={presentationData}
                          setClick={click => this.printSurveyReportPresentation = click}

                        />
                      )}
                      {this.state.TemplateId === 2 && (
                        <PresentationV2View staffPresentationData={presentationData}
                          setClick={click => this.printSurveyReportPresentation = click}

                        />
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </PresentationWrapperStyle>
        </div>
      </AccessLevel>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(Presentation));
