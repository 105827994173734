import React, { Component } from 'react';
import { arrowDown, arrowUp } from '../../resources/images';
import DropDownOptions from './DropDownOptions';
import DropDownOptionsWithButton from './DropDownOptionsWithButton';
import { DropdownStyle } from '../../resources/styling/appStyle';
import { WebStorageNames } from '../../utils/';
import { storageService } from '../../services';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: false,
      accessLevel: 0,      
    };
    this.dropDownRef = React.createRef();
  }

  componentDidMount = () => {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
  }
  handleChange = () => {
    this.setState({
      showResults: !this.state.showResults
    });
  };

  handleOptionChange(option) {
    this.setState({ showOption: option });
  }

  handleClick = e => {
    if (!this.dropDownRef.current.contains(e.target)) {
      this.setState({ showResults: false });
    }
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, true);
    //document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, true);
    //document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      options,
      selectedOption,
      onChange,
      id,
      withButton = false
    } = this.props;
    const { accessLevel } = this.state;
    return (
      <DropdownStyle
        onClick={this.handleChange}        
        selectedOption={selectedOption}
        ref={this.dropDownRef}
        className='category-inner-wrapper'        
      >
        <div
          className={
            this.state.showResults
              ? 'active-option selected-option'
              : 'selected-option'
          }
          data-test-id='selectOption'
          id={id}
        >
          <label>{selectedOption.label}</label>
          {!this.state.showResults && <img src={arrowDown} alt='Arrow down' />}
          {this.state.showResults && <img src={arrowUp} alt='Arrow up' />}
        </div>

        {this.state.showResults &&
          (withButton ? (
            <DropDownOptionsWithButton options={options} onChange={onChange} accessLevel={accessLevel}/>
          ) : (
            <DropDownOptions options={options} onChange={onChange}  />
          ))}
      </DropdownStyle>
    );
  }
}

export default Dropdown;
