import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { MediaQuery } from '../../resources/styling/appStyle';

const {
    pictonBlue, barleyWhite, purple
} = colors;

/*** Registration Link ***/
export const RegistrationLinkLinkWrapper = styled.div`
  .mainWrapper {
    border-style: solid;
    height: auto;
    background-color: white;
    float: left;
    margin-bottom: 20px;
    border-width: 1px;
    width: 100%;
  }

  .link {
    width: 85%;
    float: left;
    padding: 1%;
    overflow-wrap: break-word;
  }
  .copy_link {
    cursor: pointer;
    float: right;
    color: black;
    padding: 45px 25px;    
    text-decoration: none;
    background: #45338C;
    color: #ffffff;    
  }
`;

/*** School ***/
export const SchoolSurveyWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  ${MediaQuery}

  .email-link {
    color: ${pictonBlue};
    text-decoration: underline;
    cursor: pointer;
  } 
  .list-action
  {
    text-align: left !important;
  }  
  .table{
	  font-size: 14px;	  
	  td, th{
		  vertical-align: middle;
		  padding: 15px;
	  }
	  tr > th{
		  border-bottom: none !important;
	  }
	  button{
		  margin-bottom: 0px;
		  padding: 9px 18px;
		  margin-right: 5px;
	  }
  }

  @media only screen and (max-width: 700px){
    ul.pagination{
      flex-wrap: wrap
    }

    ul.pagination li{
      height: 40px;
    }
  }
`;
export const SchoolSurveyInnerMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 216px);
`;
export const SchoolSurveyInner = styled.div`
  display: block;
  margin-top: 30px;
`;
export const PaginationWrapper = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`;

const SchoolStyle = {
    RegistrationLinkLinkWrapper,
    SchoolSurveyWrapper,
    SchoolSurveyInnerMain,
    SchoolSurveyInner,
    PaginationWrapper
}
export default SchoolStyle;