import { AllowedAccess } from "react-permission-role";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Roles, Permissions } from '../../utils/Constants';


const AccessLevel = (props) => {
    return (
        <AllowedAccess
            roles={[Roles.SuperAdmin]}
            permissions={props.permissions}
        >
            {props.children}
        </AllowedAccess>)
}

export default AccessLevel;
