import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DirectorLeftMenu, Footer, MainMATHeader } from '../../components';
import QuestionCategoryPresentation from './QuestionCategoryPresentation';
import QuestionCategoryEvaluation from './QuestionCategoryEvaluation';
import QuestionCategoryResults from './QuestionCategoryResults';
import SurveyGreetingPresentation from './SurveyGreetingPresentation';
import { map, isEmpty } from 'lodash';
import { colors } from '../../resources/theme/colors';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { parseJwt } from '../../utils/CommonFunctions';
import { welbeeslideslogo } from '../../resources/images';
import { SurveyTypes, WebStorageNames, MenuItems } from '../../utils/Constants';
import { matGroupService, storageService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import PptxGenJS from 'pptxgenjs';
import { limitAverageValue } from '../../utils/roundScore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import MatCycleDropDown from '../../components/common/MatCycleDropDown';
import { iconDownload } from '../../resources/images/index';
import Tooltip from '@mui/material/Tooltip';
import {
  PageOverlay,
  PresentationWrapperStyle,
  PresentationInnerWrapperStyle,
  PageFooterStyle,
  ExtendedAssistanceWrapper,
} from './SurveyReportPresentationStyle';

let pptx;

let slideColors = {
  white: 'FFFFFF',
  yellow: 'FFCF05',
  purple: '45338C',
  green: '046458',
  orange: 'ff3000',
  lightred: 'F5ABCC',
};

class MatPPTPreviousVersion extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.printSurveyReportPresentation =
      this.printSurveyReportPresentation.bind(this);
    this.state = {
      selectedSlideIndex: 0,
      slide: {
        title: '',
        content: '',
      },
      isPreview: false,
      surveyResult: [],
      surveyData: [],
      selectedItem: 0,
      CategoryType: '',
      CategoryTitle: '',
      QuestionSurveyAverages: [],
      schoolInfo: '',
      surveyStartDate: new Date(),
      surveyEndDate: new Date(),
      SurveysCompletedPercentage: 0,
      message: '',
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      isRole: userInfo.role,
      presentationData: this.props.matPppData,
    };
  }

  onKeyDown = (event) => {
    if (event.keyCode) {
      const { selectedItem } = this.state;
      this.setState({ selectedItem: selectedItem + 1 });
    }
  };

  componentDidMount() {
    this.getStaffPresentationReport(this.state.presentationData);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.MatPresentation,
    });
    amplitudeLog('PageView', 'MAT Presentation', '');
    this.props.setClick(this.printSurveyReportPresentation);
  }

  getStaffPresentationReport = (pptData) => {
    const { action } = this.props;
    action.dataLoading(true);

    let categoryTypes = {
      ManagerSupport: 'ManagerSupport',
      PeerSupport: 'PeerSupport',
    };

    let categoryTypesText = {
      ManagerSupport: 'Manager Support',
      PeerSupport: 'Peer Support',
      Support: 'Support',
    };
    let surveyResult = pptData?.previousStaffPptReportModel;
    this.setState({
      schoolInfo: pptData?.schoolInfo,
      surveyStartDate: pptData?.schoolInfo?.SurveyStartDate,
      surveyEndDate: pptData?.schoolInfo?.SurveyEndDate,
      SurveysCompletedPercentage:
        pptData?.schoolInfo?.SurveysCompletedPercentage,
      message: '',
    });
    const results = [];
    const greetings = {
      type: 'greetings',
      title: `${pptData?.schoolInfo.SchoolName}'s Survey Results & Commentary`,
      content:
        'Survey opened on <span class="orange-color">' +
        pptData?.schoolInfo.SurveyStartDate +
        '</span><br/>' +
        'Completed by <span class="teal-color">' +
        pptData?.schoolInfo.SurveysCompletedPercentage +
        '% of staff</span>',
    };
    results.push(greetings);
    Object.keys(surveyResult).forEach((key, index) => {
      var categoryType =
        key === categoryTypes.ManagerSupport
          ? categoryTypesText.ManagerSupport
          : key === categoryTypes.PeerSupport
          ? categoryTypesText.PeerSupport
          : key;
      const result = surveyResult[key];
      const categoryOverview = {
        type: 'overview',
        categoryType:
          categoryType === categoryTypesText.ManagerSupport
            ? categoryTypesText.Support
            : categoryType,
        id: key + 'Overview',
        content: result.CategoryTitle,
      };
      result.OverallCategoryAverage =
        result.Score.OverallCategoryAverage.toFixed(2);
      result.SchoolDecileValue = result.Score.SchoolDecileValue.toFixed(2);
      result.HseDecileValue = result.Score.HseDecileValue.toFixed(2);
      const categoryResults = {
        type: 'result',
        id: key + 'Result',
        description: result.ResultsText,
        result: result,
        schoolDecile: result.Score.SchoolDecilesCategoryAverage,
        categoryType: categoryType,
      };

      // do not show PeerSupport result slide
      if (key !== categoryTypes.PeerSupport) results.push(categoryOverview);
      results.push(categoryResults);

      if (result.TopStrengths.length > 0) {
        const categoryStrengths = {
          type: 'evaluation',
          subType: 'strength',
          value: result.TopStrengths,
          id: key + 'Strength',
          categoryType: categoryType,
          title: `Strengths`,
        };
        results.push(categoryStrengths);
      }

      if (result.TopWeaknesses.length > 0) {
        const categoryWeaknesses = {
          type: 'evaluation',
          subType: 'weakness',
          value: result.TopWeaknesses,
          fill: slideColors.green,
          categoryType: categoryType,
          id: key + 'Weakness',
          title: `Areas for Improvement`,
        };
        results.push(categoryWeaknesses);
      }
    });

    this.setState({
      surveyResult: results,
      slide: results[0],
      selectedSlideIndex: 0,
      surveyData: pptData,
    });
    action.dataLoading(false);
  };

  renderSurveryReportPresentation = (item) => {
    if (item.type === 'greetings') {
      return (
        <SurveyGreetingPresentation
          backgroundColor={colors.whiteColor}
          categoryColor={colors.purple}
          title={item.title}
          content={item.content}
          id='greetings'
          style={{ border: '1px solid red' }}
          schoolLogo={this.state.schoolInfo.SchoolLogo}
        />
      );
    }

    if (item.type === 'overview') {
      return (
        <QuestionCategoryPresentation
          backgroundColor={item.backgroundColor}
          foreColor={item.foreColor}
          category={item.categoryType}
          content={item.content}
          id={item.id}
        />
      );
    }

    if (item.type === 'result') {
      return (
        <QuestionCategoryResults
          backgroundColor='#ffdc15'
          foreColor={item.foreColor}
          category={item.categoryType}
          title={'Results'}
          schoolDecile={item.schoolDecile}
          content={item.description}
          result={item.result}
          id={item.id}
        />
      );
    }

    if (item.type === 'evaluation') {
      return (
        <QuestionCategoryEvaluation
          backgroundColor='#ffdc15'
          category={item.categoryType}
          title={item.title}
          content={item.value}
          subType={item.subType}
          id={item.id}
        />
      );
    }
  };

  renderSurveryReportPresentationForPrint = (item) => {
    if (item.type === 'greetings') {
      return `<div>greeting screen </div>`;
    }

    if (item.type === 'overview') {
      return `<div>overview  screen </div>`;
    }

    if (item.type === 'result') {
      return `<div>result screen </div>`;
    }

    if (item.type === 'evaluation') {
      return `<div>evaluation screen </div>`;
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  printSurveyReportPresentation = () => {
    const { surveyData, schoolInfo } = this.state;
    const {
      Change,
      Control,
      Demands,
      ManagerSupport,
      PeerSupport,
      Relationships,
      Role,
    } = surveyData?.previousStaffPptReportModel;
    amplitudeLog('Download ', 'Presentation', '');
    pptx = new PptxGenJS();

    let introSlide = pptx.addSlide({ sectionTitle: 'Group Presentation' });

    let headingFontSize = 40;
    let subHeadingFontSize = 19;

    introSlide.addText(
      [
        {
          text: schoolInfo.SchoolName + "'s",
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            bold: false,
            fontFace: 'Arial Black',
          },
        },
        {
          text: 'Survey Results \n& Commentary',
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            bold: false,
            fontFace: 'Arial Black',
          },
        },
        {
          text: 'Survey opened on ',
          options: {
            fontSize: subHeadingFontSize,
            color: slideColors.purple,
            y: 1.5,
          },
        },
        {
          text: schoolInfo.SurveyStartDate,
          options: {
            fontSize: subHeadingFontSize,
            color: 'EB5B41',
            breakLine: true,
          },
        },
        {
          text: 'Completed by ',
          options: {
            fontSize: subHeadingFontSize,
            color: slideColors.purple,
          },
        },
        {
          text: schoolInfo.SurveysCompletedPercentage + '% of staff',
          options: {
            fontSize: subHeadingFontSize,
            color: '007A7A',
          },
        },
      ],
      { x: 0.5, w: '100%', h: '100%' }
    );

    introSlide.addImage({
      path: welbeeslideslogo,
      x: 0.5,
      y: 5.2,
      w: 0.8,
      h: 0.3,
    });

    this.printHtml(Demands, pptx);
    this.printHtml(Control, pptx);
    this.printHtml(ManagerSupport, pptx);
    this.printHtml(PeerSupport, pptx);
    this.printHtml(Relationships, pptx);
    this.printHtml(Role, pptx);
    this.printHtml(Change, pptx);

    var options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    };
    var request = new Request(schoolInfo.SchoolLogo);
    fetch(request, options).then((response) => {
      response.blob().then((blob) => {
        var reader = new FileReader();
        reader.onload = function () {
          introSlide.addImage({
            data: this.result,
            x: '80%',
            y: '40%',
            w: 1.5,
            h: 1.5,
          });
          pptx.writeFile({ fileName: 'Group-Presentation' });
        };
        reader.readAsDataURL(blob);
      });
    });
  };

  printHtml(category, pptx) {
    const { TopStrengths, TopWeaknesses } = category;
    const categoryName = category.Score.CategoryName;

    if (category.Category !== 'PeerSupport') {
      // Overview Slide
      let categorySlide = pptx.addSlide({ sectionTitle: 'Group Presentation' });
      categorySlide.bkgd = slideColors.yellow;

      categorySlide.addText(
        [
          {
            text:
              category.Category !== 'ManagerSupport' ? categoryName : 'Support',
            options: {
              fontSize: 40,
              bold: true,
              color: slideColors.purple,
              breakLine: true,
              paraSpaceAfter: 30,
            },
          },
          {
            text: category.CategoryTitle,
            options: { x: 0.6, fontSize: 22, color: '363636' },
          },
        ],
        { x: 0.5, y: '40%' }
      );
      categorySlide.addImage({
        path: welbeeslideslogo,
        x: 0.5,
        y: 5.2,
        w: 0.8,
        h: 0.3,
      });
    }

    // Result Slide
    let categoryResultSlide = pptx.addSlide({
      sectionTitle: 'Group Presentation',
    });

    categoryResultSlide.addText(
      [
        {
          text: categoryName,
          options: {
            fontSize: 25,
            color: slideColors.purple,
            breakLine: true,
            indentLevel: 1,
          },
        },
        {
          text: 'Results',
          options: {
            x: 0.6,
            fontSize: 40,
            bold: true,
            color: slideColors.purple,
            indentLevel: 1,
          },
        },
      ],
      { h: '25%', w: '100%', fill: slideColors.yellow, margin: 1 }
    );

    let circleWH = 2;
    let circlePosition = '35%';
    let averageScoreFontSize = 33;
    let averageTypeFontSize = 14;

    categoryResultSlide.addText(
      [
        {
          text: limitAverageValue(category.OverallCategoryAverage),
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            align: 'c',
            breakLine: true,
          },
        },
        {
          text: 'Our Score',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'c',
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3%',
        y: circlePosition,
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: 'FF5939' },
      }
    );

    categoryResultSlide.addText(
      [
        {
          text: limitAverageValue(category.AverageScore),
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            align: 'c',
            breakLine: true,
          },
        },
        {
          text: 'Average Score',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'c',
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '26%',
        y: circlePosition,
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: '53B5E0' },
      }
    );

    categoryResultSlide.addText(
      [
        {
          text: category.Score.SchoolDecilesCategoryAverage,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            align: 'c',
            breakLine: true,
          },
        },
        {
          text: 'School Decile',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'c',
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '51%',
        y: circlePosition,
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: '007A7A' },
      }
    );

    categoryResultSlide.addText(
      [
        {
          text: category.Score.HseDecilesCategoryAverage,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            align: 'c',
            breakLine: true,
          },
        },
        {
          text: 'HSE Decile',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'c',
          },
        },
        {
          text: 'All Organisations',
          options: { fontSize: 10, color: slideColors.white, align: 'c' },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '75%',
        y: circlePosition,
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: 'F5ABCC' },
      }
    );

    categoryResultSlide.addImage({
      path: welbeeslideslogo,
      x: 0.5,
      y: 5.2,
      w: 0.8,
      h: 0.3,
    });

    // Top Strengths Slides

    if (TopStrengths.length > 0) {
      let categoryStrengthSlide = pptx.addSlide({
        sectionTitle: 'Group Presentation',
      });
      categoryStrengthSlide.bkgd = 'EB5B41';

      categoryStrengthSlide.addText(
        [
          {
            text: categoryName,
            options: {
              fontSize: 40,
              color: slideColors.yellow,
              breakLine: true,
            },
          },
          {
            text: 'Strengths',
            options: {
              x: 0.6,
              fontSize: 40,
              bold: true,
              color: slideColors.yellow,
            },
          },
        ],
        { x: 0.5, y: '20%', w: '100%' }
      );

      if (TopStrengths.length > 0) {
        categoryStrengthSlide.addText(
          [
            {
              text: '1)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '40%' }
        );
        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[0].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '40%', w: '90%' }
        );
        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[0].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '46.5%' }
        );
      }
      if (TopStrengths.length > 1) {
        categoryStrengthSlide.addText(
          [
            {
              text: '2)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '55%' }
        );

        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[1].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '55%', w: '90%' }
        );
        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[1].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '61.5%' }
        );
      }
      if (TopStrengths.length > 2) {
        categoryStrengthSlide.addText(
          [
            {
              text: '3)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '71.5%' }
        );
        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[2].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '71.5%', w: '90%' }
        );

        categoryStrengthSlide.addText(
          [
            {
              text: TopStrengths[2].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '78.5%' }
        );
      }
      categoryStrengthSlide.addImage({
        path: welbeeslideslogo,
        x: 0.5,
        y: 5.2,
        w: 0.8,
        h: 0.3,
      });
    }

    // Top Weaknesses Slides
    if (TopWeaknesses.length > 0) {
      let categoryWeaknessSlide = pptx.addSlide({
        sectionTitle: 'Group Presentation',
      });
      categoryWeaknessSlide.bkgd = slideColors.green;

      categoryWeaknessSlide.addText(
        [
          {
            text: categoryName,
            options: {
              fontSize: 40,
              color: slideColors.lightred,
              breakLine: true,
              fill: 'green',
            },
          },
          {
            text: 'Areas for Improvement',
            options: {
              x: 0.6,
              fontSize: 40,
              bold: true,
              color: slideColors.lightred,
              fill: slideColors.green,
            },
          },
        ],
        { x: 0.5, y: '20%' }
      );

      if (TopWeaknesses.length > 0) {
        categoryWeaknessSlide.addText(
          [
            {
              text: '1)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '40%' }
        );
        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[0].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '40%', w: '90%' }
        );
        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[0].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '46.5%' }
        );
      }
      if (TopWeaknesses.length > 1) {
        categoryWeaknessSlide.addText(
          [
            {
              text: '2)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '55%' }
        );
        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[1].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '55%', w: '90%' }
        );
        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[1].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '61.5%' }
        );
      }
      if (TopWeaknesses.length > 2) {
        categoryWeaknessSlide.addText(
          [
            {
              text: '3)',
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.5, y: '71.5%' }
        );
        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[2].Key,
              options: {
                fontSize: 16,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '71.5%', w: '90%' }
        );

        categoryWeaknessSlide.addText(
          [
            {
              text: TopWeaknesses[2].Value,
              options: {
                fontSize: 11,
                color: slideColors.white,
              },
            },
          ],
          { x: 0.9, y: '78.5%' }
        );
      }

      categoryWeaknessSlide.addImage({
        path: welbeeslideslogo,
        x: 0.5,
        y: 5.2,
        w: 0.8,
        h: 0.3,
      });
    }
  }

  handlePresentationPreview = () => {
    this.setState({ isPreview: true });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  // handleCycleChange = (cycle) => {
  //     const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
  //     this.getStaffPresentationReport(null, matGroupId, cycle.value);
  // };

  render() {
    return (
      <div>
        {this.state.isPreview && (
          <PageOverlay onClick={() => this.setState({ isPreview: false })} />
        )}
        <PresentationWrapperStyle>
          <div className='containerClass'>
            <Carousel
              autoPlay={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              useKeyboardArrows={true}
              selectedItem={this.state.selectedItem}
              onChange={(index, item) => {
                this.setState({ selectedItem: index });
              }}
            >
              {map(this.state.surveyResult, (item, index) => (
                <PresentationInnerWrapperStyle key={index}>
                  <div ref={this.slideElem}>
                    {this.renderSurveryReportPresentation(item)}
                  </div>
                </PresentationInnerWrapperStyle>
              ))}
            </Carousel>
            <PageFooterStyle mb='30px'>
              <span>
                Page {this.state.selectedItem + 1} of{' '}
                {this.state.surveyResult.length}
              </span>
            </PageFooterStyle>
          </div>
        </PresentationWrapperStyle>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MatPPTPreviousVersion));
