import React from 'react';
import { withRouter } from 'react-router-dom';
import { routesObj } from '../../routes';

const UpgradeAccountButton = (props) => {
  const redirectToRoute = () => {
    props.history.push(routesObj.VoiceUpgrade.path);
  };

  return (
    <div className='upgrade-account-button'>
      <button style={{ height: '45px' }} onClick={redirectToRoute}>
        Upgrade account
      </button>
    </div>
  );
};

export default withRouter(UpgradeAccountButton);
