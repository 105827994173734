import React, { useState, useEffect } from 'react';
import { Dropdown } from '../';
import { MultiSelectDropDownWrapper } from '../../resources/styling/appStyle';
import { storageService, matAnalyticsService } from '../../services';
import { WebStorageNames } from '../../utils/Constants';
import { MultiSelectDropDown } from '../../components';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MATSchoolsMultiSelect = (props) => {
    const [isValid, setIsValid] = useState(false);
    //const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;
    const [matCycles, setMatCycles] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [matschools, setMATSchools] = ([props.matSchoolList]);
    let options = [];
    //options.push({ title: 'All', id: 0 });
    matschools.map((item, i) => {
        options.push({
            key: i,
            title: item.label,
            id: item.value,
        });
    });

    useEffect(() => {
        setSelectedSchool(props.selectedValues);
    }, {});

    function handleChange(option) {
        props.onSelect(option);
    }

    return (
        <div>
            <MultiSelectDropDownWrapper>
                <Autocomplete
                    sx={{
                        ".MuiOutlinedInput-root": {
                            '& fieldset': {
                                borderColor: '#53B5E0',
                            },
                            "&:hover fieldset": {
                                borderColor: "#53B5E0",
                            }
                        }
                    }}
                    multiple                  
                    id='checkboxes-tags-demo'
                    options={options}
                    disableCloseOnSelect
                    onChange={(event, option) => {
                        handleChange(option);
                    }}
                    getOptionLabel={(option) => option.title}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    renderOption={(props, option, state) => {
                        const selectedCycle = selectedSchool.findIndex(
                            (x) => x.title.toLowerCase() === 'all'
                        );
                        if (selectedCycle > -1) {
                            state.selected = true;
                        }

                        return (
                            <li {...props} style={{ fontSize: '14px', fontWeight:'500' }}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8,padding:0}}
                                    checked={state.selected}
                                />
                                {option.title}
                            </li>
                        );
                    }}
                    style={{
                        width: '100%',
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='All Schools'
                            placeholder='School'
                                                  
                        />
                    )}
                />
            </MultiSelectDropDownWrapper>
            {props.children}
        </div>
    );
};
export default MATSchoolsMultiSelect;
