import request from '../request';
import { AnalyticsAPI, MATAnalyticsAPI,  } from './urls';

export const getMATAnalyticsData = (model) => {
  return request({
    url: MATAnalyticsAPI.GetMATAnalyticsData(),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};
  export const getSchoolCharacteristicTypes = (schoolId) => {
    return request({
      url: AnalyticsAPI.GetSchoolCharacteristicTypes(schoolId),
      method: 'GET',
    })
      .then((response) => response)
      .catch((response) => response);
  };
  export const getMatSchools = (matId) => {
    return request({
      url: MATAnalyticsAPI.GetMatSchools(matId),
      method: 'GET',
    })
      .then((response) => response)
      .catch((response) => response);
  };
 
  export const getMATCategoriesAnalytics = (model) => {
    return request({
      url: MATAnalyticsAPI.GetMAtCategoriesAnalytics(),
      method: 'POST',
      data: model,
    })
      .then((response) => response)
      .catch((response) => response);
  };
  
  const matAnalyticsService = {
    getMATAnalyticsData,
    getSchoolCharacteristicTypes,
    getMatSchools,
    getMATCategoriesAnalytics
  };



  export default matAnalyticsService