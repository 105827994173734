import { actionTypes } from './actionTypes';

const {
  LOGIN,
  ISFIRSTLOGIN,
  DO_SCROLL,
  ISBELL_NOTIFICATION,
  FREE_TRIAL_END_DATE,
} = actionTypes;

//////////// USER ACTIONS ///////////////

export const loginAction = ({ user }) => ({
  type: LOGIN,
  user,
});

export const isFirstLoginAction = (value) => ({
  type: ISFIRSTLOGIN,
  payload: value,
});

export const doScroll = (value) => ({
  type: DO_SCROLL,
  payload: value,
});

export const isBellNotificationAction = (value) => ({
  type: ISBELL_NOTIFICATION,
  payload: value,
});

export const freeTrailEndDateAction = (value) => ({
  type: FREE_TRIAL_END_DATE,
  payload: value,
});

export const firstLoginAction = {
  isFirstLoginAction,
  doScroll,
  isBellNotificationAction,
  freeTrailEndDateAction,
};

//////////// USER REDUCER ///////////////

const INITIAL_STATE = {
  authenticated: false,
  user: null,
  isFirstLogin: false,
  doScroll: false,
  isBellNotification: false,
  freeTrailEndDate: new Date(),
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === ISFIRSTLOGIN) {
    return { ...state, isFirstLogin: action.payload };
  } else if (action.type === DO_SCROLL) {
    return { ...state, doScroll: action.payload };
  }
  if (action.type === ISBELL_NOTIFICATION) {
    return { ...state, isBellNotification: action.payload };
  }
  if (action.type === FREE_TRIAL_END_DATE) {
    return { ...state, freeTrailEndDate: action.payload };
  }
  if (action.type) {
    return {
      ...state,
      ...action.user,
      authenticated: false,
    };
  } else {
    return state;
  }
};
