import React from 'react';
import { CalculationsStyle } from '../../resources/styling/surveyReportStyle';
import { limitAverageValue } from '../../utils/roundScore';

const ReportScores = ({ Score, id, className, isPaid=false }) => (
  <CalculationsStyle id={id} className={className}>
    <ul
      className='all-score'
      style={{
        display: 'flex',
        '-webkit-justify-content': 'center',
        '-ms-flex-pack': 'justify',
        'justify-content': 'center',
      }}
    >
      <li>
        {limitAverageValue(Score.OverallCategoryAverage)}
        <span>
          Overall <br /> Score
        </span>
      </li>
      <li>
        {isPaid
          ? Score.SchoolDecilesCategoryAverage
          : '---'}
        <span>
          School <br /> Decile
        </span>
      </li>
      <li>
        {isPaid ? Score.HseDecilesCategoryAverage : '---'}
        <span>
          HSE
          <br />
          Decile
        </span>
      </li>
    </ul>
  </CalculationsStyle>
);

export default ReportScores;
