export const questionCategoryType = {
  Demands: {
    Id: 1,
    Name: 'Demands',
    KeyWords: {}
  },
  Control: {
    Id: 2,
    Name: 'Control'
  },
  Support: {
    Id: 3,
    Name: 'Support'
  },
  Relationships: {
    Id: 4,
    Name: 'Relationships'
  },
  Role: {
    Id: 5,
    Name: 'Role'
  },
  Change: {
    Id: 6,
    Name: 'Change'
  },
  PeerSupport: {
    Id: 7,
    Name: 'PeerSupport'
  }
};
