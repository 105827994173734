import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map, isEmpty } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { welbeeslideslogo } from '../../../resources/images';
import {
  SurveyTypes,
  WebStorageNames,
  MenuItems,
  SurveySlideOrder,
} from '../../../utils/Constants';
import { storageService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { navigationActions } from '../../../store/headerStore';
import PptxGenJS from 'pptxgenjs';
import {
  amplitudeLog,
  SchoolPPTText,
  parseJwt,
} from '../../../utils/CommonFunctions';
import {
  PageOverlay,
  PresentationWrapperStyle,
  PresentationInnerWrapperStyle,
  PageFooterStyle,
} from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import StaffParticipationSummary from './StaffParticipationSummary';
import SurveyGreetingPresentationV2 from './SurveyGreetingPresentationV2';
import AverageScoreAllParticipants from './AverageScoreAllParticipants';
import ScoreByJobRoleHeatmap from './ScoreByJobRoleHeatmap';
import HighestLowestScoringQuestions from './HighestLowestScoringQuestions';
import QuestionScorebyJobRoles from './QuestionsByJobRoles';
import ManagementStandard from './ManagementStandard';
import DemandAvgScore from './DemandAvgScore';
import ControlAvgScore from './ControlAvgScore';
import ManagerSupportAvgScore from './ManagerSupportAvgScore';
import ChangeAvgScore from './ChangeAvgScore';
import PeerSupportAvgScore from './PeerSupportAvgScore';
import RoleAvgScore from './RoleAvgScore';
import RelationshipAvgScore from './RelationshipAvgScore';
import { ManagmentStandards } from '../../../utils/Constants';
import { colors } from '../../../resources/theme';
import { color } from '@mui/system';
import { Colors } from 'chart.js';

let pptx;
let slideColors = {
  white: 'FFFFFF',
  yellow: 'FFCF05',
  purple: '45338C',
  green: '046458',
  orange: 'ff3000',
  lightred: 'F5ABCC',
  balck: '000000',
};
const SilideNumberAndTitles = [
  { id: 1, label: 'Name and logo' },
  { id: 2, label: 'Staff Participation' },
  { id: 3, label: 'Average Scores for All Participants' },
  { id: 4, label: 'Scores by Job Role' },
  { id: 5, label: 'The Five Highest and Lowest Scoring Questions' },
  { id: 6, label: 'Your Additional Questions' },
  { id: 7, label: 'Demands' },
  { id: 8, label: 'Control' },
  { id: 9, label: 'Manager Support' },
  { id: 10, label: 'Peer Support' },
  { id: 11, label: 'Relationships' },
  { id: 12, label: 'Role' },
  { id: 13, label: 'Change' },
];
class PresentationV2View extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.printSurveyReportPresentation =
      this.printSurveyReportPresentation.bind(this);
    this.state = {
      selectedSlideIndex: 0,
      slide: {
        title: '',
        content: '',
      },
      presentationData: this.props.staffPresentationData,
      isPreview: false,
      surveyResult: [],
      surveyData: [],
      selectedItem: 0,
      CategoryType: '',
      CategoryTitle: '',
      QuestionSurveyAverages: [],
      schoolInfo: '',
      surveyStartDate: new Date(),
      surveyEndDate: new Date(),
      SurveysCompletedPercentage: 0,
      message: '',
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      isRole: userInfo.role,
      reportdata: {},
      matName: '',
      matType: '',
      loaded: false,
      matAvg: {},
      heatmapByRole: [],
      lowMstd: '',
      highMstd: '',
      heatmapBySchool: [],
      highestScoringSchools: [],
      lowestScoringSchools: [],
      highestScoringStandards: [],
      lowestScoringStandards: [],
      highScoreQuestions: [],
      lowScoreQuestions: [],
      JobRoleGraph: [],
      AllSchoolGraph: [],
      region:
        storageService.getItem(WebStorageNames.UsRegion) === 'us' ? 'us' : 'uk',
    };
  }
  onKeyDown = (event) => {
    if (event.keyCode) {
      const { selectedItem } = this.state;
      this.setState({ selectedItem: selectedItem + 1 });
    }
  };
  GetPresentationData = (presentationData) => {
    this.setState({ surveyResult: [] });
    var slide = SilideNumberAndTitles;
    var isQuestion = presentationData?.HasAdditionalQuestion;
    const { action } = this.props;
    action.dataLoading(true);

    if (!isQuestion) {
      slide = slide.filter((x) => x.id !== SurveySlideOrder.YourQuestions);
    }

    this.setState({ surveyResult: slide });
    action.dataLoading(false);
  };
  componentDidMount() {
    this.GetPresentationData(this.props.staffPresentationData);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.MatPresentation,
    });
    amplitudeLog('PageView', 'School Presentation', '');
    this.props.setClick(this.printSurveyReportPresentation);
  }
  renderSurveryReportPresentation = (item) => {
    if (item.id === SurveySlideOrder.Nameandlogo) {
      return (
        <SurveyGreetingPresentationV2
          welbeeslideslogo={welbeeslideslogo}
          id={item.id}
          SchoolName={`${this.state.presentationData.SchoolName}'s Survey Results & Commentary`}
          SchoolLogo={this.state.presentationData.SchoolLogo}
          Content={`${this.state.presentationData.SurveyOpen} <br /> ${this.state.presentationData.SurveyClosed} <br /> ${this.state.presentationData.CompletedBy}`}
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.StaffParticipation) {
      return (
        <StaffParticipationSummary
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData.NoOfStaffParticipated
          }
          staffParticipations={this.state.presentationData?.StaffParticipations}
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.AverageScoresAllParticipants) {
      return (
        <AverageScoreAllParticipants
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData.NoOfStaffParticipated
          }
          AvgScoreAll={this.state.presentationData?.SchoolAvgHeatmap}
          PreviousAvgScoreAll={
            this.state.presentationData?.SchoolAvgPresviousHeatmap
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.ScoresbyJobRole) {
      return (
        <ScoreByJobRoleHeatmap
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData.NoOfStaffParticipated
          }
          JobRoleHeatmap={this.state.presentationData?.JobRoleHeatmap}
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.HighestandLowestScoringQuestions) {
      return (
        <HighestLowestScoringQuestions
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData.NoOfStaffParticipated
          }
          highScoreQuestions={this.state.presentationData?.HighScoreQuestions}
          lowScoreQuestions={this.state.presentationData?.LowScoreQuestions}
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.YourQuestions) {
      return (
        <QuestionScorebyJobRoles
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          HasAdditionalQuestion={
            this.state.presentationData?.HasAdditionalQuestion
          }
          AdditionQuestionIds={this.state.presentationData?.AdditionQuestionIds}
          AdditionalQuestionsScores={
            this.state.presentationData?.AdditionalQuestionsByJobRole
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.ManagmentStandards) {
      return (
        <ManagementStandard
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          SchoolHeatMap={this.state.presentationData?.SchoolAvgHeatmap}
          SchoolName={this.state.presentationData?.SchoolName}
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.Demands) {
      return (
        <DemandAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.Demands
              ?.AverageScore
          }
          Demands={
            this.state.presentationData?.ManagmentStandards?.Demands?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.Demands
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.Demands
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.Control) {
      return (
        <ControlAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.Control
              ?.AverageScore
          }
          Control={
            this.state.presentationData?.ManagmentStandards?.Control?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.Control
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.Control
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.ManagerSupport) {
      return (
        <ManagerSupportAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.ManagerSupport
              ?.AverageScore
          }
          ManagerSupport={
            this.state.presentationData?.ManagmentStandards?.ManagerSupport
              ?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.ManagerSupport
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.ManagerSupport
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.PeerSupport) {
      return (
        <PeerSupportAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.PeerSupport
              ?.AverageScore
          }
          PeerSupport={
            this.state.presentationData?.ManagmentStandards?.PeerSupport?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.PeerSupport
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.PeerSupport
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.Relationships) {
      return (
        <RelationshipAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.Relationships
              ?.AverageScore
          }
          Relationships={
            this.state.presentationData?.ManagmentStandards?.Relationships
              ?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.Relationships
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.Relationships
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.Role) {
      return (
        <RoleAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.Role?.AverageScore
          }
          Role={this.state.presentationData?.ManagmentStandards?.Role?.Score}
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.Role?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.Role?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
    if (item.id === SurveySlideOrder.Change) {
      return (
        <ChangeAvgScore
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          TotalSchoolStaffSurveySent={
            this.state.presentationData?.TotalSchoolStaffSurveySent
          }
          NoOfStaffParticipated={
            this.state.presentationData?.NoOfStaffParticipated
          }
          AverageScore={
            this.state.presentationData?.ManagmentStandards?.Change
              ?.AverageScore
          }
          Change={
            this.state.presentationData?.ManagmentStandards?.Change?.Score
          }
          TopStrength={
            this.state.presentationData?.ManagmentStandards?.Change
              ?.TopStrengths
          }
          TopWeaknesses={
            this.state.presentationData?.ManagmentStandards?.Change
              ?.TopWeaknesses
          }
          region={this.state.region}
        />
      );
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
  StaffSummaryDataRows = (data) => {
    var column = [];
    column.push([
      {
        text: data?.StaffParticipations?.ResponseCountByJob?.Organisation,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
          bold: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob
          .SupportStaffWorkingInside,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob
          .SupportStaffWorkingOutside,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob.TeacherWorkingInside,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob.TeacherWorkingOutside,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob.MiddleLeader,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob.SeniorLeader,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text: data.StaffParticipations.ResponseCountByJob.Total,
        options: {
          fontFace: 'Avenir',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
    ]);
    return column;
  };

  StaffSummaryPresentation = (pptx, reportdata) => {
    let slide = null;
    var dataRows = this.StaffSummaryDataRows(reportdata);
    let rows = [];
    slide = pptx.addSlide();

    let staffPercent = (
      (reportdata.NoOfStaffParticipated /
        reportdata.TotalSchoolStaffSurveySent) *
      100
    ).toFixed(2);
    // Add Management Standard title
    var titilCss1 = {
      x: 0.14, // Horizontal position
      y: 0.05, // Vertical position
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      fontFace: 'Avenir',
      bold: true,
      fontSize: 20,
      color: slideColors.black,
    };
    slide.addText('Management Standards', titilCss1);

    let headerText1 = `The survey used is the Health and Safety (HSE) Indicator Tool. This is evidence built and DfE Staff Wellbeing Charter recognised and measures how staff view their working environment against the Health and Safety Executive's (HSE) Management Standards.\n\rThese Standards can be used to define the characteristics and culture of a school or organisation, where the risks from work related stress and poor staff wellbeing are being effectively managed and controlled. They cover six key areas of work design, that if not properly managed, are associated with poor health and wellbeing, lower productivity, increased sickness absence and lower staff retention.\n\rThese cover Demands, Control, Manager and Peer Support, Relationships, Role and Change. A definition for each of these is shown on the slide with individual Management Standard scores.`;
    var headerTextTable1 = {
      x: 0.14, // Horizontal position
      y: 0.98, // Vertical position
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0,
    };
    slide.addText(headerText1, headerTextTable1);

    // Add Staff Participation title
    var titilCss2 = {
      x: 0.14, // Horizontal position
      y: 2.4, // Vertical position
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      fontFace: 'Avenir',
      bold: true,
      fontSize: 20,
      color: slideColors.black,
    };
    slide.addText('Staff Participation', titilCss2);

    let headerText2 = `${reportdata.NoOfStaffParticipated} members of staff from across your MAT participated in the survey, which is ${staffPercent}% of all staff. The breakdown of this across your schools and core filters is shown in the table below. Where no number is shown, this is because less than 5 participants took part for that job role. The participation numbers for each filter may not add to the total if people have multiple job titles and because participation below 5 is not shown.`;
    var headerTextTable2 = {
      x: 0.14, // Horizontal position
      y: 2.75, // Vertical position
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0,
    };
    slide.addText(headerText2, headerTextTable2);

    // Add Table 1: Staff Participation title
    var Table1Obj = {
      x: 2.9, // Horizontal position
      y: 3.65, // Vertical position
      w: 4,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Table 1: Staff Participation', Table1Obj);

    // Add the table
    var HeaderOptions = {
      breakLine: true,
      fontFace: 'Avenir',
      align: 'center',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      fontSize: 9,
      bold: true,
    };

    rows.push([
      { text: 'Organisation', options: HeaderOptions },
      { text: 'Support Staff (Classroom)', options: HeaderOptions },
      { text: 'Support Staff (Non-Classroom)', options: HeaderOptions },
      { text: 'Teaching Staff (Classroom)', options: HeaderOptions },
      { text: 'Teaching Staff (Non-Classroom)', options: HeaderOptions },
      { text: 'Middle Leaders', options: HeaderOptions },
      { text: 'Senior Leaders', options: HeaderOptions },
      { text: 'Total', options: HeaderOptions },
    ]);

    dataRows.forEach((row, i) => {
      rows.push(row);
    });

    slide.addTable(rows, {
      x: 0.4, // Horizontal position
      y: 3.98, // Vertical position
      //h: 5.0,
      rowH: 0.38,
      colW: [2.0, 1.1, 1.2, 1.1, 1.2, 1.1, 1.0, 0.84],
      fontSize: 9,
      fontFace: 'Avenir',      
      border: { pt: 1, color: '#e2e2e2' },
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',      
    });
  };

  getDecile = (level, value, isDecile, preVal, preDecile = null) => {
    var textValue = isDecile
      ? level +
        (level === 1 ? 'st' : level === 2 ? 'nd' : level === 3 ? 'rd' : 'th') +
        (preDecile
          ? ' (' +
            preDecile +
            (preDecile === 1
              ? 'st'
              : preDecile === 2
              ? 'nd'
              : preDecile === 3
              ? 'rd'
              : 'th') +
            ')'
          : '')
      : preVal
      ? value.toFixed(2) + (' (' + preVal.toFixed(2) + ')')
      : value.toFixed(2);

    return textValue;
  };
  getHeatmapCell = (level, value, isDecile, preVal, preDecile = null) => {
    var textValue = isDecile
      ? level +
        (level === 1 ? 'st' : level === 2 ? 'nd' : level === 3 ? 'rd' : 'th') +
        (preDecile
          ? ' (' +
            preDecile +
            (preDecile === 1
              ? 'st'
              : preDecile === 2
              ? 'nd'
              : preDecile === 3
              ? 'rd'
              : 'th') +
            ')'
          : '')
      : preVal
      ? value.toFixed(2) + (' (' + preVal.toFixed(2) + ')')
      : value.toFixed(2);
    var obj = {
      text: textValue,
      options: {
        fill: {
          color: level <= 3 ? '#84C1C3' : level >= 7 ? '#f7b2a5' : '#FFFFFF',
        },
      },
    };
    return obj;
  };

  getHeatmapManagmentStandardCell = (level, value, isDecile) => {
    var textValue =
      value >= 1
        ? value.toFixed(2) +
          ' (' +
          level +
          (level === 1
            ? 'st'
            : level === 2
            ? 'nd'
            : level === 3
            ? 'rd'
            : 'th') +
          ')'
        : '-';
    var obj = {
      text: textValue,
      options: {
        superscript: false,
        fill: {
          color: level <= 3 ? '#84C1C3' : level >= 7 ? '#f7b2a5' : '#FFFFFF',
        },
      },
    };
    return obj;
  };
  AvgAllParticipaintDataRows = (AvgScoreAll, PreviousAverage) => {
    var column = [];
    var textOptions = { fontFace: 'Avenir', color: '#000000',bold:true };
    column.push([
      {
        text: 'Demand',
        options:textOptions ,        
      },
      this.getHeatmapCell(
        AvgScoreAll?.DemandSDecile,
        AvgScoreAll?.Demand,
        false,
        PreviousAverage?.Demand,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.DemandSDecile,
        AvgScoreAll?.Demand,
        true,
        null,
        PreviousAverage?.DemandSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.DemandHSCDecile,
        AvgScoreAll?.DemandHSCDecile,
        true,
        null,
        PreviousAverage?.DemandHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Change',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.ChangeSDecile,
        AvgScoreAll?.Change,
        false,
        PreviousAverage?.Change,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ChangeSDecile,
        AvgScoreAll?.Change,
        true,
        null,
        PreviousAverage?.ChangeSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ChangeHSCDecile,
        AvgScoreAll?.ChangeHSCDecile,
        true,
        null,
        PreviousAverage?.ChangeHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Peer Support',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.PeerSupportSDecile,
        AvgScoreAll?.PeerSupport,
        false,
        PreviousAverage?.PeerSupport,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.PeerSupportSDecile,
        AvgScoreAll?.PeerSupport,
        true,
        null,
        PreviousAverage?.PeerSupportSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.PeerSupportHSCDecile,
        AvgScoreAll?.PeerSupportHSCDecile,
        true,
        null,
        PreviousAverage?.PeerSupportHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Manager Support',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.ManagerSupportSDecile,
        AvgScoreAll?.ManagerSupport,
        false,
        PreviousAverage?.ManagerSupport,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ManagerSupportSDecile,
        AvgScoreAll?.ManagerSupport,
        true,
        null,
        PreviousAverage?.ManagerSupportSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ManagerSupportHSCDecile,
        AvgScoreAll?.ManagerSupportHSCDecile,
        true,
        null,
        PreviousAverage?.ManagerSupportHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Role',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.RoleSDecile,
        AvgScoreAll?.Role,
        false,
        PreviousAverage?.Role,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.RoleSDecile,
        AvgScoreAll?.Role,
        true,
        null,
        PreviousAverage?.RoleSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.RoleHSCDecile,
        AvgScoreAll?.RoleHSCDecile,
        true,
        null,
        PreviousAverage?.RoleHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Control',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.ControlSDecile,
        AvgScoreAll?.Control,
        false,
        PreviousAverage?.Control,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ControlSDecile,
        AvgScoreAll?.Control,
        true,
        null,
        PreviousAverage?.ControlSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.ControlHSCDecile,
        AvgScoreAll?.ControlHSCDecile,
        true,
        null,
        PreviousAverage?.ControlHSCDecile
      ),
    ]);

    column.push([
      {
        text: 'Relationships',
        options: textOptions
      },
      this.getHeatmapCell(
        AvgScoreAll?.RelationshipsSDecile,
        AvgScoreAll?.Relationships,
        false,
        PreviousAverage?.Relationships,
        null
      ),
      this.getHeatmapCell(
        AvgScoreAll?.RelationshipsSDecile,
        AvgScoreAll?.Relationships,
        true,
        null,
        PreviousAverage?.RelationshipsSDecile
      ),
      this.getHeatmapCell(
        AvgScoreAll?.RelationshipsHSCDecile,
        AvgScoreAll?.RelationshipsHSCDecile,
        true,
        null,
        PreviousAverage?.RelationshipsHSCDecile
      ),
    ]);
    return column;
  };
  AvgAllParticipentPresentation = (pptx, AvgScoreAll, PreviousAverage) => {
    let slide = null;
    var dataRows = this.AvgAllParticipaintDataRows(
      AvgScoreAll,
      PreviousAverage
    );
    let rows = [];
    slide = pptx.addSlide();

    var titilCss = {
      x: 0.14, // Horizontal position
      y: 0.05, // Vertical position
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir',
      color: slideColors.black,
    };
    slide.addText('Average Scores for All Participants', titilCss);
    let headerText = `Heatmap 1 scores are out of 5, with higher scores showing that risks relating to workplace stress and poor wellbeing are more effectively managed. The school decile shows your performance against the school benchmark, with 1st Decile being in the top 10% of schools taking the survey with us, and 10th Decile meaning more than 90% of staff in other schools have scored more highly. Comparatively high scores (3rd school decile and higher) are shaded green and comparatively lower ones (7th school decile and lower) red. Those without shading are close to the average score for schools. The HSE Decile shows your comparative scores against the Health and Safety Executive's Benchmark of 136 cross sector organisations.`;
    var Table1Obj1 = {
      x: 0.14, // Horizontal position
      y: 0.50, // Vertical position
      margin: 20,
      h: 1.0,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);
    var Table1Obj = {
      x: 2.5,
      y: 1.901574803,
      w: 5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText(
      'Heatmap 1: Average Score for All Survey Participants',
      Table1Obj
    );
    let headerOptions = {
      fontFace: 'Avenir',
      align: 'center',
      valign: 'middle',
      fontSize: 9,
      fill: { color: '#C9E9F7' },
      color: '#000000',
      breakLine: true,
      bold: true,
    };
    rows.push([
      { text: 'Management Standards', options: headerOptions },
      { text: 'Score', options: headerOptions },
      { text: 'School Decile', options: headerOptions },
      { text: 'HSE Decile', options: headerOptions },
    ]);

    dataRows.forEach((row, i) => {
      rows.push(row);
    });
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    slide.addTable(rows, {
      x: 0.2007874016,
      y: 2.31,
      w: 9.5984251969,
      rowH: 0.37,
      fontSize: 10,
      fontFace: 'Avenir',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',
    });
  };

  getMainText = (value, index) => {
    let num = index + 1;
    var textValue = '\n' + num + ') ' + value?.Key + '\n';
    var obj = [
      {
        text: textValue,
        options: {
          fontFace: 'Avenir',
          fontSize: 9,          
          valign: 'top',
          color: '#000',
          bold:true,
        },
      },
      {
        text: value?.Value,
        options: {
          fontSize: 8,
          fontFace: 'Avenir',
          valign: 'top',
          color: '#000',
          paraSpaceAfter: 20,
          breakLine: true
        },
      },
    ];
    return obj;
  };

  bechMarkText = (value, index) => {
    let num = index + 1;
    var textValue = num + ') ' + value + '\n';
    var obj = {
      text: textValue,
      options: {
        fontSize: 13,        
        fontFace: 'Avenir',
        valign: 'top',
        color: '#000',
      },
    };
    return obj;
  };

  DemandAvgPresentation = (pptx) => {
    let marginLeft = ' ';
    var headerText =SchoolPPTText(this.state.region, ManagmentStandards.Demand);
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.Demands?.AverageScore;
    let Demands =
      this.state.presentationData?.ManagmentStandards?.Demands?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.Demands?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.Demands?.TopWeaknesses;
    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Demands',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#45338c' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${Demands?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      Demands?.SchoolDecileType,
      Demands?.OverallCategoryAverage,
      true,
      null,
      Demands?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      Demands?.HseDecileType,
      Demands?.HseDecileType,
      true,
      null,
      Demands?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  ControlAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(
      this.state.region,
      ManagmentStandards.Control
    );
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.Control?.AverageScore;
    let Control =
      this.state.presentationData?.ManagmentStandards?.Control?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.Control?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.Control?.TopWeaknesses;

    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Control',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#E84F6B' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${Control?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      Control?.SchoolDecileType,
      Control?.OverallCategoryAverage,
      true,
      null,
      Control?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      Control?.HseDecileType,
      Control?.HseDecileType,
      true,
      null,
      Control?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  ManagerSupportAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(
      this.state.region,
      ManagmentStandards.ManagerSupport
    );
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.ManagerSupport
        ?.AverageScore;
    let ManagerSupport =
      this.state.presentationData?.ManagmentStandards?.ManagerSupport?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.ManagerSupport
        ?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.ManagerSupport
        ?.TopWeaknesses;
    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Manager Support',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#007A7B' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${ManagerSupport?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      ManagerSupport?.SchoolDecileType,
      ManagerSupport?.OverallCategoryAverage,
      true,
      null,
      ManagerSupport?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      ManagerSupport?.HseDecileType,
      ManagerSupport?.HseDecileType,
      true,
      null,
      ManagerSupport?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  PeerSupportAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(
      this.state.region,
      ManagmentStandards.PeerSupport
    );
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.PeerSupport
        ?.AverageScore;
    let PeerSupport =
      this.state.presentationData?.ManagmentStandards?.PeerSupport?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.PeerSupport
        ?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.PeerSupport
        ?.TopWeaknesses;

    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Peer Support',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#FF5939' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${PeerSupport?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      PeerSupport?.SchoolDecileType,
      PeerSupport?.OverallCategoryAverage,
      true,
      null,
      PeerSupport?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      PeerSupport?.HseDecileType,
      PeerSupport?.HseDecileType,
      true,
      null,
      PeerSupport?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  RelationshipsAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(
      this.state.region,
      ManagmentStandards.Relationships
    );
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.Relationships
        ?.AverageScore;
    let Relationships =
      this.state.presentationData?.ManagmentStandards?.Relationships?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.Relationships
        ?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.Relationships
        ?.TopWeaknesses;

    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Relationships',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#54B5E0' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${Relationships?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      Relationships?.SchoolDecileType,
      Relationships?.OverallCategoryAverage,
      true,
      null,
      Relationships?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      Relationships?.HseDecileType,
      Relationships?.HseDecileType,
      true,
      null,
      Relationships?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  ChangeAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(
      this.state.region,
      ManagmentStandards.Change
    );
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.Change?.AverageScore;
    let Change = this.state.presentationData?.ManagmentStandards?.Change?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.Change?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.Change?.TopWeaknesses;
    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: 'Change',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#FFB106' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${Change?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      Change?.SchoolDecileType,
      Change?.OverallCategoryAverage,
      true,
      null,
      Change?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      Change?.HseDecileType,
      Change?.HseDecileType,
      true,
      null,
      Change?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  RoleAvgPresentation = (pptx) => {
    var headerText = SchoolPPTText(this.state.region, ManagmentStandards.Role);
    let slide = null;
    let AverageScore =
      this.state.presentationData?.ManagmentStandards?.Role?.AverageScore;
    let Role = this.state.presentationData?.ManagmentStandards?.Role?.Score;
    let TopStrength =
      this.state.presentationData?.ManagmentStandards?.Role?.TopStrengths;
    let TopWeaknesses =
      this.state.presentationData?.ManagmentStandards?.Role?.TopWeaknesses;
    let haflWidth = '50%'; // 4.7934645669;
    let fullWidth = '100%'; //9.5869291338;
    let slideWidth =
      TopStrength?.length > 0 && TopWeaknesses?.length > 0
        ? haflWidth
        : fullWidth;
    slide = pptx.addSlide();

    slide.addText(
      [
        {
          text: ' Role',
          options: {
            fontFace: 'Avenir',
            fontSize: 22,
            bold: true,
            color: slideColors.white,
            breakLine: true,
          },
        },
      ],
      { h: '17%', w: '100%', fill: '#F5ABCC' }
    );
    var Table1Obj1 = {
      x: 0,
      y: 1,
      h: 0.5,
      w: 10,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    let circleWH = 1.4;
    let averageScoreFontSize = 26;
    let averageTypeFontSize = 14;

    slide.addText(
      [
        {
          text: 'Our\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${Role?.OverallCategoryAverage}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '1.75',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.purple },
      }
    );

    slide.addText(
      [
        {
          text: 'Average\nScore\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${AverageScore}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '3.40',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.pictonBlue },
      }
    );
    let schoolDecile = this.getDecile(
      Role?.SchoolDecileType,
      Role?.OverallCategoryAverage,
      true,
      null,
      Role?.PreviousSchoolDecilesCategoryAverage
    );
    slide.addText(
      [
        {
          text: 'School\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          } ,
        },
        {
          text: `${schoolDecile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '5.12',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.teal },
      }
    );

    let hscDescile = this.getDecile(
      Role?.HseDecileType,
      Role?.HseDecileType,
      true,
      null,
      Role?.PreviousHseDecilesCategoryAverage
    );

    slide.addText(
      [
        {
          text: 'HSE\nDecile\n',
          options: {
            fontSize: averageTypeFontSize,
            color: slideColors.white,
            align: 'center',
          },
        },
        {
          text: `${hscDescile}`,
          options: {
            fontSize: averageScoreFontSize,
            color: slideColors.white,
            bold: true,
            align: 'center',
            breakLine: true,
          },
        },
      ],
      {
        shape: pptx.shapes.OVAL,
        x: '6.81',
        y: '1.68',
        w: circleWH,
        h: circleWH,
        fill: { type: 'solid', color: colors.mandy },
      }
    );

    const strength = TopStrength?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });

    const weekness = TopWeaknesses?.map((item, index) => {
      return [...this.getMainText(item, index)];
    });
    if (TopStrength?.length > 0) {
      slide.addText(
        [
          {
            text: 'Strength',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...strength?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#84C1C3',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    /************ TopWeaknesses right Shape with Gulabi Color ***************/
    if (TopWeaknesses?.length > 0) {
      slide.addText(
        [
          {
            text: 'Areas for Improvement',
            options: {
              color: '#000000',
              fontFace: 'Avenir',
              x: 0.2,
              y: 1.94488189,
              fontSize: 20,
              bold: true,
              align: 'left',
              valign: 'top',
            },
          },
          ...weekness?.flat(),
        ],
        {
          shape: pptx.shapes.RECTANGLE,
          x: slideWidth === '50%' ? 5 : 0,
          y: 3.45,
          w: slideWidth,
          h: 2.25,
          fill: '#F7B2A5',
          color: '#000',
          fontFace: 'Avenir',
          align: 'left',
          valign: 'top',
        }
      );
    }

    slide.addText(
      [
        {
          text: '  *Please note, if strengths and areas for improvement appear to contradict, they will be referring to different staff groups or benchmarks',
          options: {
            fontSize: 8,
            color: '#000',
            breakLine: true,
            //indentLevel: 1,
          },
        },
      ],
      { x: 0, y: 5.47, h: '5%', w: '100%' }
    );
  };

  ScoreByJobRoleHeatmapPresentation = (pptx, heatmapByRole) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    var titilCss = {
      x: 0.14, // Horizontal position
      y: 0.05, // Vertical position
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir',
      color: slideColors.black,
    };
    slide.addText('Scores by Job Role', titilCss);
    let headerText = `The average scores for participants in each of the core job role filters are shown in heatmap 2 below. Again, comparatively high scores are shaded green and comparatively lower ones red. Those without shading are close to average. Using heatmaps in your school Dashboard you can apply further filters to identify where scores may be higher and lower.`;
    var Table1Obj1 = {
      x: 0.14, // Horizontal position
      y: 0.38, // Vertical position
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    var Table1Obj = {
      x: 2.5,
      y: 1.401574803,
      w: 5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Heatmap 2: Scores by Job Role', Table1Obj);
    let rowOption = {
      fontFace: 'Avenir',
      align: 'center',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      breakLine: true,
      fontSize: 9,
      bold: true,
    };
    let rowOption1 = {
      fontFace: 'Avenir',
      align: 'center',
      valign: 'middle',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      breakLine: true,
      fontSize: 9,
      bold: true,
    };
    let arrBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    let bottomText = `* Heatmaps of all filtered scores can be viewed and downloaded from your school dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);
    rows.push([
      { text: '', options: rowOption1 },
      { text: 'Demands', options: rowOption },
      { text: 'Control', options: rowOption },
      { text: 'Manager Support', options: rowOption },
      { text: 'Peer Support', options: rowOption },
      { text: 'Relationships', options: rowOption },
      { text: 'Role', options: rowOption },
      { text: 'Change', options: rowOption },
    ]);

    heatmapByRole.map((row) => {
      rows.push([
        {
          text: row.Name,
          options: { fontFace: 'Avenir', color: '#000000', bold: true ,fontSize: 10 },       
        },
        this.getHeatmapManagmentStandardCell(
          row.DemandSDecile,
          row.Demand,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ControlSDecile,
          row.Control,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ManagerSupportSDecile,
          row.ManagerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.PeerSupportSDecile,
          row.PeerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.RelationshipsSDecile,
          row.Relationships,
          false
        ),
        this.getHeatmapManagmentStandardCell(row.RoleSDecile, row.Role, false),
        this.getHeatmapManagmentStandardCell(
          row.ChangeSDecile,
          row.Change,
          false
        ),
      ]);
    });
    slide.addTable(rows, {
      x: 0.2,
      y: 1.73228346,
      rowH: 0.3,
      colW: [2.35, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03],
      fontSize: 9,
      fontFace: 'Avenir',
      border: arrBorder,
      autoPage: false,
      autoPageRepeatHeader: false,
      autoPageHeaderRows: 1,
      verbose: false,
      align: 'center',
      valign: 'middle',
    });
  };
  ScoreBySchoolHeatmapPresentation = (pptx, heatmapBySchool) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      fontSize: 20,
      fontFace: 'Avenir',
      bold: true,
      color: slideColors.purple,
    };
    slide.addText('Scores by Schools', titilCss);
    let headerText = `Heatmap 3 below shows the average scores for all staff for each school within the MAT. Comparatively high scores (3rd school decile and higher) are shaded green and comparatively lower ones (7th school descile and lower) red. Those without shading are close to the average score for schools.`;
    var Table1Obj1 = {
      x: 0.14, // Horizontal position
      y: 0.50, // Vertical position
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir',
      wrap: true,
      fontSize: 10,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    var Table1Obj = {
      x: 2.251968504,
      y: 1.200787402,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Heatmap 3:  Scores by school', Table1Obj);
    let rowOption1 = {
      fontFace: 'Avenir',
      fill: { color: '#FFFFFF' },
      fontSize: 9,
      bold: true,
      align: 'center',
      vlign: 'middle',
      color: '#000000',
      breakLine: true,
    };
    let rowOption = {
      fontFace: 'Avenir',
      fill: { color: '#C9E9F7' },
      fontSize: 9,
      bold: true,
      align: 'center',
      vlign: 'middle',
      color: '#000000',
      breakLine: true,
    };
    
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    let bottomText = `* Heatmaps of all filtered scores by school can be viewed and downloaded from your MAT dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);

    rows.push([
      { text: '', options: rowOption1 },
      { text: 'Demands', options: rowOption },
      { text: 'Control', options: rowOption },
      { text: 'Manager Support', options: rowOption },
      { text: 'Peer Support', options: rowOption },
      { text: 'Relationship', options: rowOption },
      { text: 'Role', options: rowOption },
      { text: 'Change', options: rowOption },
    ]);

    heatmapBySchool.map((row) => {
      rows.push([
        {
          text: row.Name,
          options: { fontFace: 'Avenir', color: '#000000' },
          bold:true,
          fontSize: 10,
          align: 'left',
          breakLine: true,
        },
        this.getHeatmapManagmentStandardCell(
          row.DemandDecile,
          row.Demand,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ControlDecile,
          row.Control,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ManagerSupportDecile,
          row.ManagerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.PeerSupportDecile,
          row.PeerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.RelationshipsDecile,
          row.Relationships,
          false
        ),
        this.getHeatmapManagmentStandardCell(row.RoleDecile, row.Role, false),
        this.getHeatmapManagmentStandardCell(
          row.ChangeDecile,
          row.Change,
          false
        ),
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874016,
      y: 1.527559055,
      h: 5.0,
      rowH: 0.3,
      colW: [2.35, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03],
      fontSize: 9,
      fontFace: 'Avenir',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',
    });
  };
  HighestLowestScoringPresentation = (
    pptx,
    highScoreQuestions,
    lowScoreQuestions
  ) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 18,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir',
      color: slideColors.black,
    };
    slide.addText('The Five Highest and Lowest Scoring Questions', titilCss);
    var Table1Obj = {
      x: 2.251968504,
      y: 0.8149606299,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Table 2: Highest and Lowest Question Scores', Table1Obj);
    let rowOption1 = {
      fontFace: 'Avenir',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      align: 'center',
      color: '#000000',
      fontSize: 9,
      bold: true,
      breakLine: true,
      colspan: 2,
    };
    let rowOption2 = {
      fontFace: 'Avenir',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      align: 'center',
      color: '#000000',
      bold: true,
      fontSize: 9,
      breakLine: true,
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    rows.push([
      { text: 'THE FIVE HIGHEST SCORING QUESTIONS', options: rowOption1 },
      { text: 'SCHOOL DECILE', options: rowOption2 },
    ]);
    highScoreQuestions?.map((item) => {
      let qText = `Q${item.Order}: ${item.Text}`;
      let decileText = `${item.Decile}${
        item.Decile === 1
          ? 'st'
          : item.Decile === 2
          ? 'nd'
          : item.Decile === 3
          ? 'rd'
          : 'th'
      }`;
      rows.push([
        {
          text: item.Category,
          options: {
            fill: { color: '#f2f2f2' },
            bold: true,
            fontSize: 10,
            color: '#000000',
          },
        },
        {
          text: qText,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            fontSize: 10,
          },
        },
        {
          text: decileText,
          options: {
            superscript: false,
            fill: { color: '#84C1C3' },
            fontSize: 10,            
            align: 'center',
            color: '#000000',
          },
        },
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [2.0, 6.2, 1.5],
      fontSize: 9,
      fontFace: 'Avenir',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
    });

    let lowestScore = [];
    if (lowScoreQuestions.length > 0) {
      lowestScore.push([
        { text: 'THE FIVE LOWEST SCORING QUESTIONS', options: rowOption1 },
        { text: 'SCHOOL DECILE', options: rowOption2 },
      ]);
      lowScoreQuestions?.map((item) => {
        let qText = `Q${item.Order}: ${item.Text}`;
        let decileText = `${item.Decile}${
          item.Decile === 1
            ? 'st'
            : item.Decile === 2
            ? 'nd'
            : item.Decile === 3
            ? 'rd'
            : 'th'
        }`;
        lowestScore.push([
          {
            text: item.Category,
            options: {
              fill: { color: '#f2f2f2' },
              bold: true,
              fontSize: 10,
              color: '#000000',
            },
          },
          {
            text: qText,
            options: { fill: { color: '#FFFFFF' }, color: '#000000' },
          },
          {
            text: decileText,
            options: {
              superscript: false,
              fill: { color: '#f7b2a5' },
              fontSize: 10,             
              align: 'center',
              color: '#000000',
            },
          },
        ]);
      });

      slide.addTable(lowestScore, {
        x: 0.2007874,
        y: 3.0866142,
        w: 9.7,
        rowH: 0.3,
        colW: [2.0, 6.2, 1.5],
        fontSize: 9,
        fontFace: 'Avenir',
        border: tableBorder,
        autoPage: true,
        autoPageRepeatHeader: true,
        autoPageHeaderRows: 1,
        verbose: false,
        valign: 'middle',
      });
    }
  };
  QuestionScorebyJobRolePresentation = (
    pptx,
    addtionalQuestion,
    additionalQuestionsByJobRole,
    hasAdditionalQuestion
  ) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 18,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir',
      color: slideColors.purple,
    };
    slide.addText('Your Additional Questions', titilCss);
    var Table1Obj = {
      x: 2.251968504,
      y: 0.8149606299,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Table 3: Your Questions', Table1Obj);
    let rowOption2 = {
      fontFace: 'Avenir',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      bold: true,
      breakLine: true,
      fontSize: 10,
      valign: 'middle',
      align: 'center',
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];

    rows.push([
      { text: 'Question Number', options: rowOption2 },
      { text: 'Question Text', options: rowOption2 },
    ]);
    addtionalQuestion?.map((item) => {
      let order = `Q${item.Order}`;
      let qtext = `${item.Text}`;
      rows.push([
        {
          text: order,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            align: 'center',
            bold: true,
            fontSize: 10,
            valign: 'middle',
          },
        },
        {
          text: qtext,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            fontSize: 10,
            valign: 'middle',
          },
        },
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [2.0, 7.7],
      fontSize: 9,
      fontFace: 'Avenir',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
    });

    /************************Table 4 Your Question Scores by School or Site slide*********************************/
    slide = pptx.addSlide();
    let dataRows = [];
    slide.addText('Your Additional Questions', titilCss);

    var Table1Obj1 = {
      x: 2.251968504,
      y: 0.8307087,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir',
      fontSize: 10,
      color: '#000000',
    };
    slide.addText('Table 4: Your Question Scores by Job Role', Table1Obj1);
    let headerRowOption = {
      fontFace: 'Avenir',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      valign: 'middle',
      align: 'center',
      fontSize: 9,
      bold: true,
      breakLine: true,
    };
    let JobTitleOptions = {
      fontFace: 'Avenir',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      fontSize: 10,
      valign: 'middle',
      align: 'center',
      breakLine: true,
      bold:true
    };
    let dataRowsOptions = {
      fontFace: 'Avenir',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      fontSize: 10,
      valign: 'middle',
      align: 'center',
      breakLine: true,
    };
    let bottomText = `* Heatmaps of all filtered scores by school for your question can be viewed and downloaded from your school dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);
    var data = Object.entries(
      hasAdditionalQuestion ? additionalQuestionsByJobRole : null
    );

    let jobRoleHeaderCell = [];
    let questionRow = addtionalQuestion?.map((item) => ({
      text: 'Q: ' + item.Order,
      options: headerRowOption,
    }));
    jobRoleHeaderCell.push({ text: 'JOB ROLE', options: headerRowOption });
    questionRow.forEach((item, i) => {
      jobRoleHeaderCell.push(item);
    });
    dataRows.push(jobRoleHeaderCell);

    data?.map((item) => {
      let jobROle = [];
      let scores = item[1];
      jobROle.push({ text: item[0], options: JobTitleOptions });
      scores?.map((score) => {
        jobROle.push({ text: score.toFixed(2), options: dataRowsOptions });
      });
      dataRows.push(jobROle);
    });
    slide.addTable(dataRows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [3.7, 2.0, 2.0, 2.0],
      fontSize: 9,
      fontFace: 'Avenir',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      aling: 'center',
      valign: 'middle',
    });
  };

  removeSpanTags = (text) => {
    const startIndex = text.indexOf('>') + 1;
    const endIndex = text.lastIndexOf('<');
    return text.substring(startIndex, endIndex);
  };

  printSurveyReportPresentation = async () => {
    const { action } = this.props;
    action.dataLoading(true);
    const presentationData = this.props.staffPresentationData;
    amplitudeLog('Download ', 'Presentation', '');
    pptx = new PptxGenJS();
    let introSlide = pptx.addSlide();

    let surveyOpen = this.removeSpanTags(presentationData?.SurveyOpen);
    let surveyClosed = this.removeSpanTags(presentationData?.SurveyClosed);
    let CompletedBy = this.removeSpanTags(presentationData?.CompletedBy);
    var logoPath = presentationData.SchoolLogo.replace('http://', 'https://');

    let headingFontSize = 40;
    let subHeadingFontSize = 19;
    return new Promise(async (resolve, reject) => {
      try {
           introSlide.addText(
      [
        {
          text: presentationData.SchoolName + "'s",
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            fontWeight: 900,
            fontFace: 'Avenir',
          },
        },
        {
          text: 'Survey Results \n& Commentary',
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            fontWeight: 900,
            fontFace: 'Avenir',
          },
        },
        {
          text: 'Survey opened on ',
          options: {
            fontSize: subHeadingFontSize,
            color: slideColors.purple,
            fontWeight: 400,
            fontFace: 'Avenir',
            y: 1.5,
          },
        },
        {
          text: surveyOpen,
          options: {
            fontSize: subHeadingFontSize,
            color: 'EB5B41',
            breakLine: true,
            fontWeight: 400,
            fontFace: 'Avenir',
          },
        },
        {
          text: 'and closed on ',
          options: {
            fontSize: subHeadingFontSize,
            color: slideColors.purple,
            fontWeight: 400,
            fontFace: 'Avenir',
          },
        },
        {
          text: surveyClosed,
          options: {
            fontSize: subHeadingFontSize,
            color: 'EB5B41',
            breakLine: true,
            fontWeight: 400,
            fontFace: 'Avenir',
          },
        },
        {
          text: 'Completed by ',
          options: {
            fontSize: subHeadingFontSize,
            color: slideColors.purple,
            fontWeight: 400,
            fontFace: 'Avenir',
          },
        },
        {
          text: CompletedBy,
          options: {
            fontSize: subHeadingFontSize,
            color: '007A7A',
            fontWeight: 400,
            fontFace: 'Avenir',
          },
        },
      ],
      { x: 0.5, w: '100%', h: '100%' }
    );

    introSlide.addImage({
      path: welbeeslideslogo,
      x: 0.6,
      y: 5.2,
      w: 0.8,
      h: 0.3,
    });
       var options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    };
    var request = new Request(logoPath);
    const response = await fetch(request, options).catch((error) => {
      console.log('Error fetching SchoolLogo:', error);
      action.dataLoading(false);
    });

    if (response) {
      const blob = await response.blob().catch((error) => {
        console.log('Error getting blob from response:', error);
      });

      if (blob) {
        var reader = new FileReader();
        reader.onload = function () {
          introSlide.addImage({
            data: this.result,
            x: '80%',
            y: '50%',
            w: 1.5,
            h: 1.5,
            sizing: { type: "contain", w:1.8, h:1 }
          });
        };
       
        reader.readAsDataURL(blob);
      } else {
        console.log('SchoolLogo blob is null');
       
      }
    } else {
      console.log('Response is undefined');
      
    }
    this.StaffSummaryPresentation(pptx, presentationData);

    this.AvgAllParticipentPresentation(
      pptx,
      this.state.presentationData?.SchoolAvgHeatmap,
      this.state.presentationData?.SchoolAvgPresviousHeatmap
    );
    this.ScoreByJobRoleHeatmapPresentation(
      pptx,
      this.state.presentationData?.JobRoleHeatmap
    );
    this.HighestLowestScoringPresentation(
      pptx,
      this.state.presentationData?.HighScoreQuestions,
      this.state.presentationData?.LowScoreQuestions
    );
    if (this.state.presentationData?.HasAdditionalQuestion) {
      this.QuestionScorebyJobRolePresentation(
        pptx,
        this.state.presentationData?.AdditionQuestionIds,
        this.state.presentationData?.AdditionalQuestionsByJobRole,
        this.state.presentationData?.HasAdditionalQuestion
      );
    }

    this.DemandAvgPresentation(pptx);
    this.ControlAvgPresentation(pptx);
    this.ManagerSupportAvgPresentation(pptx);
    this.PeerSupportAvgPresentation(pptx);
    this.RelationshipsAvgPresentation(pptx);
    this.RoleAvgPresentation(pptx);
    this.ChangeAvgPresentation(pptx);

        pptx.writeFile({ fileName: 'School-Presentation' });
        action.dataLoading(false);
        resolve();
      } catch (error) {
        action.dataLoading(false);
        console.log('PPT Dowonload Error', error);
        reject(error);
      }
    });


 
  };

  handlePresentationPreview = () => {
    this.setState({ isPreview: true });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }
  render() {
    return (
      <div>
        {this.state.isPreview && (
          <PageOverlay onClick={() => this.setState({ isPreview: false })} />
        )}
        <PresentationWrapperStyle>
          <div className='containerClass'>
            <Carousel
              autoPlay={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              useKeyboardArrows={true}
              selectedItem={this.state.selectedItem}
              onChange={(index, item) => {
                this.setState({ selectedItem: index });
              }}
            >
              {map(this.state.surveyResult, (item, index) => (
                <PresentationInnerWrapperStyle key={index}>
                  <div ref={this.slideElem}>
                    {this.renderSurveryReportPresentation(item)}
                  </div>
                </PresentationInnerWrapperStyle>
              ))}
            </Carousel>
            <PageFooterStyle mb='30px'>
              <span>
                Page {this.state.selectedItem + 1} of{' '}
                {this.state.surveyResult?.length}
              </span>
            </PageFooterStyle>
          </div>
        </PresentationWrapperStyle>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PresentationV2View));
