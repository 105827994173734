import React from 'react';
import { CalculationsStyle } from '../../resources/styling/surveyReportStyle';
import { limitAverageValue,scoreNotAvailableValue } from '../../utils/roundScore';


const RoleWiseReportScores = ({ Score, id, className }) => (
  <CalculationsStyle id={id} className={className}>      
    <ul className='all-score' style={{ display: "flex", "-webkit-justify-content": "center","-ms-flex-pack": "justify","justify-content": "center", "align-items": "center" }}>
    {scoreNotAvailableValue(limitAverageValue(Score.SupportStaffClassCatAvg)) !== "-1.00" ? 
      (             
      <li>    
      {scoreNotAvailableValue(
          limitAverageValue(
            Score.SupportStaffClassCatAvg)
        )}
        <span style={{ width: "100px"}}>Support <br />Staff <br />(classroom)</span>
      </li>
      ):""
    }
    {scoreNotAvailableValue(limitAverageValue(Score.SupportStaffNonClassCatAvg)) !== "-1.00" ? 
      (  
      <li>
       {scoreNotAvailableValue(
          limitAverageValue(Score.SupportStaffNonClassCatAvg)
        )}
        <span style={{ width: "100px"}}>Support <br />Staff <br />(non-classroom)</span>
      </li>
      ):""
    }
    {scoreNotAvailableValue(limitAverageValue(Score.TeachingStaffClassCatAvg)) !== "-1.00" ? 
      (  
      <li>      
      {scoreNotAvailableValue(
          limitAverageValue(Score.TeachingStaffClassCatAvg)
        )}
        <span style={{ width: "100px"}}>Teaching <br />Staff <br />(classroom)</span>
      </li>
      ):""
    }
    {scoreNotAvailableValue(limitAverageValue(Score.TeachingStaffNonClassCatAvg)) !== "-1.00" ? 
      (  
      <li>
      {scoreNotAvailableValue(
          limitAverageValue(Score.TeachingStaffNonClassCatAvg)
        )}
        <span style={{ width: "100px"}}>Teaching <br />Staff <br />(non-classroom)</span>
      </li>
       ):""
      }
    {scoreNotAvailableValue(limitAverageValue(Score.MiddleLeaderCatAvg)) !== "-1.00" ? 
      (  
      <li>
      {scoreNotAvailableValue(
          limitAverageValue(Score.MiddleLeaderCatAvg)
        )}
        <span style={{ width: "100px"}}>Middle <br />Leader</span>
      </li>
      ):""
    }
    {scoreNotAvailableValue(limitAverageValue(Score.SeniorLeaderCatAvg)) !== "-1.00" ? 
      (  
      <li>
      {scoreNotAvailableValue(
          limitAverageValue(Score.SeniorLeaderCatAvg)
        )}
        <span style={{ width: "100px"}}>Senior <br />Leader</span>
      </li>
      ):""
    }
    </ul>
  </CalculationsStyle>
);

export default RoleWiseReportScores;
