import React from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Tooltip } from '@mui/material';
import html2canvas from 'html2canvas';
Chart.register(ChartDataLabels);

const GetTitlesForBarChart = (data) => {
  const value = data.filter(d => (d))
    .map(x => x.label)
    .join(", ");
  return value
};

function MATAnlyticeBarChart(props) {
  const [isLoader, setIsLoader] = React.useState(false);
  const [topPX, setTopPX] = React.useState('25px');
  const downloadChart = (id) => {
    setIsLoader(true);
    setTopPX('0px')
    return new Promise(async (resolve, reject) => {
      try {
        const chartId = "barChart"+id;
        const devElement = document.getElementById(chartId);

        if (!devElement) {
          throw new Error(`Element with id '${chartId}' not found in the DOM.`);
        }

        const canvas = await html2canvas(devElement);

        if (!(canvas instanceof HTMLCanvasElement)) {
          throw new Error(`Element with id '${chartId}' is not a canvas.`);
        }

        const padding = 20;
        const newCanvas = document.createElement('canvas');
        const context = newCanvas.getContext('2d');
        newCanvas.width = canvas.width + 2 * padding;
        newCanvas.height = canvas.height + 2 * padding;
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);
        context.drawImage(canvas, padding, padding);

        const imageLink = document.createElement('a');
        imageLink.download = `${chartId}.png`;
        imageLink.href = newCanvas.toDataURL('image/png');
        imageLink.click();

        setIsLoader(false);
        resolve();
        setTopPX('25px')
      } catch (error) {
        setIsLoader(false);
        setTopPX('25px')
        console.error('Error occurred during chart download:', error);
        reject(error);
      }
    });
  };
  const downloadChartA = (id) => {
    setIsLoader(true);
    let chartId = "barChart"+id;
    const imageLink = document.createElement('a');
    const canvas = document.getElementById(chartId);
  
    if (!canvas) {
      console.error(`Canvas element with id '${chartId}' not found.`);
      return;
    }
    const padding = 10; 
  
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = canvas.width + 2 * padding;
    newCanvas.height = canvas.height + 2 * padding;
    context.fillStyle = '#ffffff'; // Set the background color to white
    context.fillRect(0, 0, newCanvas.width, newCanvas.height);
  
    context.drawImage(canvas, padding, padding);
    imageLink.download = 'BarChart.png';
    imageLink.href = canvas.toDataURL('image/png', 1);
    imageLink.href = newCanvas.toDataURL('image/png');
    imageLink.click();
    setIsLoader(false);
  };
  const options = {
    categoryPercentage:props?.categoryPercentage,
    barPercentage: props?.barPercentage,   
    responsive: true,
    scales: {
      y: {
        suggestedMin: 1,
        suggestedMax: 5,
        ticks: {
          color: '#000',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold'
          },
        }
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display:true,
          color: '#000',
          weight: 'bold',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold'
          },
        }
      },
    },
    plugins: {
      title: {
        font: {
          size: 14,
          family: 'Avenir LT Pro'
        },
        color: '#000',
        display: true,
        text:props?.isSchoolTitle? props?.Chartlabel[0]:props?.Chartlabel ,
      },
      datalabels: {
        responsive: true,
        color: '#000',
        labels: {
          title: {
            align: 'top',
            anchor: 'end',
            font: {
              family: 'Avenir LT Pro',
              weight: 'bold'
            }
          },
          value: {
            font: {
              family: 'Avenir LT Pro',
              size:9,
              weight: 'bold'
            },
            align: 'top',
            anchor: 'top',
            color: '#fff',
            formatter: function (value, context) {
              return (context.chart.data.datasets[context.datasetIndex].decileData[context.dataIndex]);
            },
          }
        }
      },
      legend: {
        display: false,
        position: 'bottom'
      },
      tooltip: {
        callbacks: {
          label(context) {
            return context.chart.data.datasets[context.datasetIndex].label + ': ' + context.formattedValue + ' Decile (' + context.chart.data.datasets[context.datasetIndex].decileData[context.dataIndex] + ')';
          }
        }
      }
    }
  };
  let data ={
    labels:props.datalables,
    datasets:props.datasets
  };
  return (
    <>
    {props.children}
    <div className='large-download-btn'>
  
      <Tooltip title="Download Chart" arrow>
      {isLoader ? (
            <CircularProgress />
          ) : (
            <ArrowCircleDownIcon
              className='large-graph-btn'
              fontSize='inherit'
              onClick={() =>downloadChart(props?.id)}
            />
          )}
      </Tooltip>
    </div>
  
   <div id={`barChart${props.id}`}>
     <h5
       style={{
         display: 'inline-block',      
         margin: '0',
         textAlign: 'center',
         width: '100%',
         top: topPX,
         fontSize: '14px',
         fontWeight: 'bold'
       }}
     >
       {props?.mainLabel}
     </h5>
     {props.datasets !== '' ? (
       <Bar type='bar' responsive={true} min={0} max={5} height={props.height} data={data} options={options} />
     ) : (
       <Box sx={{ display: 'flex' }}>
         <CircularProgress />
       </Box>
     )}
   </div>
  </>
  );
};
export default MATAnlyticeBarChart;