import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle
} from '../../resources/styling/otherSuggestionStyle';
import {useTranslation} from  'react-i18next';
const RelationshipSuggestion = () => {
  const {t} = useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle>
          <h2>Further actions to address risks to your management standards</h2>
          <h3>4. Relationships</h3>

          <ManagementStandardStyle>
            <li>
              Strained relationship or friction between colleagues is normal in
              any school or {t('organisation')} and it is how it is dealt with that is
              key. It usually develops over time and can lead to dislike,
              mistrust, deteriorating work performance and increased staff
              absence and turnover.
              <p>
                The best way of dealing with it is to prevent it from happening
                or escalating by having clear expectations and policies in place
                about staff {t('behviour')} and what to do in the case of conflict or
                disagreement.
              </p>
              <p>
                It also needs effective leaders who meet with their team members
                and can clearly see when things aren’t quite right and notice
                things such as {t('behviour')} changes and when staff might be upset
                or angry. This also means holding regular meetings and 1 to 1s
                and asking how staff are feeling.
              </p>
              <p>
                Where there is already friction or strain it is important to
                Identify the causes and then frame these as things that need to
                be solved, with no winners or losers. Conflicts usually come
                down to unfulfilled needs or that staff members prefer to
                operate in different ways, rather than personalities.
                Understanding what isn’t being met for individuals is therefore
                important.
              </p>
              <p>
                The relevant leader has to sit down with those involved and let
                them air grievances, understand what staff need from each other
                and then find a way forward and gain commitment to agreed
                action. They then need to follow up to ensure that what is
                agreed takes place.
              </p>
              <p>
                For some leaders to do this effectively they may need support or
                training and we can advise on this when or if it is needed.
              </p>
            </li>

            <li>
              As your score suggests staff in your school are subject to greater
              harassment or bullying than those in the majority of schools
              {t('organisations')} the following is provided by way of information.
              <p>
                The ACAS guide for {t('managers')} and employers defines harassment and
                bullying of staff as usually something has happened to them that
                is unwelcome, unwarranted and causes a detrimental effect.
              </p>
              <p>
                In addition make sure leaders are aware of and demonstrate those
                competencies proven to prevent and reduce staff stress. It is
                often how {t('linemanagers')} approach workload, for example how
                deadlines are set, the empathy they show and how regularly they
                check in with team members that makes a difference.
              </p>
              <p>
                Harassment is defined in the Equalities Act 2010 as unwanted
                conduct related to a relevant protected characteristic, which
                has the purpose or effect of violating an individual’s dignity
                or creating an intimidating, hostile, degrading, humiliating or
                offensive environment for that individual.
              </p>
              <p>
                Bullying may be characterised as offensive, intimidating,
                malicious or insulting {t('behviour')}, an abuse or misuse of power
                through means that undermine, humiliate, denigrate or injure the
                recipient.
              </p>
              <p>
                While extreme examples are easy to identify, there are instances
                where it may be more difficult and where one person’s harassment
                and bullying is another person’s firm action. It can therefore
                be hard to recognise and we can provide examples if needed.
              </p>
              <p>
                It does not necessarily occur face to face and can take place
                through multiple channels.
              </p>
              <p>
                The first step is to ensure there are workplace policies and
                procedures in place and that these have been effectively
                communicated. It is likely you will have done this.
              </p>
              <p>
                It is important that all {t('linemanagers')} understand what is likely
                to constitute harassment and bullying, both as good role models
                and so they can identify if it might be taking place.
              </p>
              <p>
                If any instances are suspected then these need to be dealt with
                in line with your policies and procedures and promptly. This
                should include provision for confidentiality and for those
                involved to have the support of a representative of their
                choice.
              </p>
              <p>
                There are recommended approaches to deal with these and we can
                provide you with further information if needed.
              </p>
              <p>
                To reduce and prevent instances of harassment and bullying, 
                {t('linemanagers')} understanding and adopting those {t('behviours')} proven to
                prevent and reduce stress in staff, will help.
              </p>
            </li>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default RelationshipSuggestion;
