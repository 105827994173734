import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, getQueryStringVal, getInvoicePrice } from '../../utils/CommonFunctions';
import { storageService, schoolService } from '../../services';
import SchoolRegisterWrapper from '../../components/common/SchoolRegisterWrapper';
import SchoolDetailForm from './UserDetailForm';
import RegisterAdminPassword from './RegisterAdminPassword';
import RegisterFee from './RegisterFee';
import OptionsDetail from '../welbeeoptions/OptionsDetail';
import { loaderAction } from '../../store/loaderStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WelbeeType } from '../../utils';

function SchoolDetails(props) {
  const [schoolInfo, setSchoolInfo] = useState(null);
  const [headTeacherEmail, setHeadTeacherEmail] = useState(null);
  const [schoolId, setSchoolId] = useState(-1);
  const [isSchoolDetailCompleted, setIsSchoolDetailCompleted] = useState(false);
  const [isWelbeeTypeCompleted, setIsWelbeeTypeCompleted] = useState(false);
  const [isRegisterFeeCompleted, setIsRegisterFeeCompleted] = useState(false);
  const [isBackButtonEnable, setIsBackButtonEnable] = useState(false);
  const [price, setPrice] = useState(247);

  useEffect(() => {
    amplitudeLog('PageView', 'Register_SchoolDetails', '');
    storageService.clear();
    const token = getQueryStringVal('token');
    if (token) {
      let { loaderActions } = props;
      loaderActions.dataLoading(true);
      schoolService
        .getSchoolDetailByRegistrationToken(token)
        .then((response) => {
          loaderActions.dataLoading(false);
          if (response.success) {
            let schoolInfoObj = response.data;
            setSchoolInfo(schoolInfoObj);
            // School details part of school registration is complete
            if (schoolInfoObj.SignUpStatus === 1) {
              onSchoolDetailFormCompletion(schoolInfoObj, schoolInfoObj.Id);
            }
            if (schoolInfoObj.SignUpStatus === 4) {
              onSchoolDetailFormCompletion(schoolInfoObj, schoolInfoObj.Id);
            }
            // School invoice part of school registration is complete
            else if (schoolInfoObj.SignUpStatus === 2) {
              setHeadTeacherEmail(schoolInfoObj.HeadTeachers[0].User.Email);
              setSchoolId(schoolInfoObj.Id);
              setIsSchoolDetailCompleted(true);
              setIsWelbeeTypeCompleted(true);
              setIsRegisterFeeCompleted(true);
            }
          }
        })
        .catch((error) => loaderActions.dataLoading(false));
    }
  }, {});

  const onSchoolDetailFormCompletion = (schoolInfoObj, schoolId) => {
    setSchoolInfo(schoolInfoObj);
    // if we are coming from school detail view
    if (schoolInfoObj.Email) setHeadTeacherEmail(schoolInfoObj.Email);
    // if we are using school registration completion link to complete registration
    else setHeadTeacherEmail(schoolInfoObj.HeadTeachers[0].User.Email);
    setSchoolId(schoolId);
    calculateTotalPrice(schoolInfoObj.NumberOfPupil, schoolInfoObj.SchoolSubscription.SubscriptionId);
    setIsSchoolDetailCompleted(true);
    if (schoolInfoObj.WelbeeType === WelbeeType.WelbeeVoice) {
      setIsWelbeeTypeCompleted(true);
      setIsRegisterFeeCompleted(true);
    }
    if (schoolInfoObj.WelbeeType === WelbeeType.WelbeeSurveyWithVoice) {
      setIsWelbeeTypeCompleted(true);
    }
  };

  const onInvoiceFormCompletion = () => {
    setIsRegisterFeeCompleted(true);
    setIsBackButtonEnable(false);
  };
  const onWelbeeVoiceOptionCompletion = (welbeeType) => {
    setIsWelbeeTypeCompleted(true);
    setIsBackButtonEnable(true);
    if (welbeeType === WelbeeType.WelbeeVoice) {
      setIsRegisterFeeCompleted(true);
    }
    if (welbeeType === WelbeeType.WelbeeSurveyWithVoice) {
      setIsRegisterFeeCompleted(false);
    }
  };

  const calculateTotalPrice = (numberOfPupil, subscriptionId) => {
    const price = getInvoicePrice(numberOfPupil, subscriptionId);

    setPrice(price);
  };

  const onBackButtonPress = () => {
    setIsWelbeeTypeCompleted(false);
    setIsBackButtonEnable(false);
  };
  return (
    <SchoolRegisterWrapper
      onBack={isBackButtonEnable ? onBackButtonPress : null}
    >
      {!isSchoolDetailCompleted ? (
        <SchoolDetailForm onCompletion={onSchoolDetailFormCompletion} />
      ) : !isWelbeeTypeCompleted ? (
        <OptionsDetail
          SchoolInfo={schoolInfo}
          onCompletion={onWelbeeVoiceOptionCompletion}
        />
      ) : !isRegisterFeeCompleted ? (
        <RegisterFee
          schoolName={schoolInfo ? schoolInfo.Name : ''}
          schoolId={schoolId}
          price={price}
          onCompletion={onInvoiceFormCompletion}
        />
      ) : (
        <RegisterAdminPassword
          Email={headTeacherEmail}
          SchoolInfo={schoolInfo}
        />
      )}
    </SchoolRegisterWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(SchoolDetails));
