import styled from 'styled-components';
import { colors } from '../../../resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { HeadTeacherReplyWrapper } from '../../../resources/styling/appStyle';

const {
    mandy, whiteColor, teal, lightGray, lighterGrey, mineShaft
} = colors;

/*** Comments Reply Common ***/
export const CommonRepplyWrapper = styled.div`
  .search-inner-container {
    width: 850px;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
    ${media.xs`
        width:100%;
    `}
  }

  .search-inner-sub-container {
    hr.horizontal-ruler {
      color: ${lighterGrey};
      border-top: 1px solid ${lighterGrey};
      margin-bottom: 30px;
    }
    .reply-section {
      padding: 0 40px;
      h3 {
        font-size: 14px;
      }
    }
    textarea {
      padding: 34px 34px 13px 20px;
      font-size: 16px;
    }
    padding: 20px 0;
    float: left;
    width: 100%;
    border-radius: 6px;
    background: ${whiteColor};
    box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);

    h3 {
      font-size: 12px;
      color: ${mineShaft};
    }

    span {
      font-size: 12px;
      color: ${lightGray};
    }
    .comments-body {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      line-height: 24px;
      padding-right: 50px;
      margin-bottom: 25px;
      color: ${teal};
    }
    .send-button {
      button {
        float: right;
        margin-bottom: 15px;
      }
    }

    .your-reply {
      .comments-body {
        color: ${mandy};
      }
    }
    .your-reply,
    .support-reply {
      .comments-body {
        font-size: 16px;
      }

      color: ${mandy};
      h3 {
        margin-bottom: 0;
        color: ${mandy};
        font-weight: 400;
      }
    }
    .support-reply {
      color: ${teal};
      h3 {
        color: ${teal};
      }
    }
  }
`;

/*** Survey Voice Staff Reply ***/
export const ExtendedHeadTeacherReplyWrapper = styled(HeadTeacherReplyWrapper)`
  .mobile-wrapper {
      ${media.xs`
  min-height: auto;
  width: 86%;

  padding-bottom: 0;
  `}
  ${media.sm`
  min-height: auto;
  width: 86%;

  padding-bottom: 0;
  `}
  > div {
    ${media.xs`
  min-height: auto;
  width: 86%;
  margin: 30px auto 5px auto;
  `}
   ${media.sm`
  min-height: auto;
  width: 86%;
  margin: 30px auto 5px auto;
  `}

  }
    ${media.md`
      margin-right: 21px;

    `}
    ${media.lg`
      margin-right: 0;
    `}
      ${media.xl`
      margin-right: 0;
    `}


  }

  .reply-guide {
    ${media.md`
     padding: 8px !important;
     margin-left: 11px;
    `}
  }
  .reply-guide {
    ${media.md`
     max-width: 160px;
       padding: 16px;
       width: 100%;
    `}
  }
  .search-inner-container {
    max-width: 850px;
    width: 100%;
    margin-bottom: 0;
  }
`;
export const WelcomeHeaderWrapper = styled.div`
  > div {
    display: block;
  }
`;

const SurveyVoiceStaffReplyStyle = {
    CommonRepplyWrapper,
    ExtendedHeadTeacherReplyWrapper,
    WelcomeHeaderWrapper
}
export default SurveyVoiceStaffReplyStyle;