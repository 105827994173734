import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { loaderAction } from '../../store/loaderStore';
import { Header } from '../../components';
import { RegisterInnerMain, RegisterWrapper } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { accountService, schoolService } from '../../services';
import { RegisterInner, InputWrapper, ExtendedAssitanceForm } from './SchoolRegisterStyle';

const { mandy } = colors;

class WelbeeVoiceProFree extends Component {
  constructor(props) {
    super(props);
  }
  handleSubmit = () => {
    this.props.onCompletion();
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const schoolinfo = this.props.SchoolInfo;
    schoolService
      .updateSchoolWelbeeVoice(schoolinfo)
      .then((response) => {
        loaderActions.dataLoading(false);
      })
      .catch((error) => loaderActions.dataLoading(false));
  };

  render() {
    var todayDate = new Date(); //Year, Month, Date
    var beforeSeptember = new Date('2020/09/30');
    return (
      <div>
        <Header />

        <RegisterWrapper>
          <Grid container>
            
              <Grid item
                lg={12}
                sm={12}
                md={12}
                xs={12}
                className='mobile-wrapper'
              >
                <RegisterInnerMain>
                  <RegisterInner>
                    <ExtendedAssitanceForm>
                      {todayDate < beforeSeptember ? (
                        <h2>Welbee Voice Pro free until 30th September 2020</h2>
                      ) : (
                        <h2>30 days of Welbee Voice (Pro) for free</h2>
                      )}
                      <p>
                        Try Welbee Voice (Pro) for free. At the end of your
                        trial you will be able to:
                      </p>
                      <ul>
                        <li>
                          Keep using a Welbee Voice (Free) account with reduced
                          features at no cost.
                        </li>
                        <li>
                          Subscribe to keep Welbee Voice (Pro) - from £100 to
                          £250 + VAT per year, depending on school size.
                        </li>
                        <li>
                          Upgrade to include a staff wellbeing survey and keep
                          Welbee Voice (Pro) at no additional cost. Survey
                          subscriptions range from just £247 to £647 + VAT per
                          year, depending on school size.
                        </li>
                      </ul>
                      <p>
                        Discounts are available for groups of schools and MATs.
                      </p>
                      <InputWrapper>
                        <WrapperMain>
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={() => {
                              this.handleSubmit();
                            }}
                          >
                            Continue
                          </AppButton>
                        </WrapperMain>
                      </InputWrapper>
                    </ExtendedAssitanceForm>
                  </RegisterInner>
                </RegisterInnerMain>
              </Grid>
            
          </Grid>
        </RegisterWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(WelbeeVoiceProFree));
