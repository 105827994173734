import React from 'react';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  InputWrapper,
  AssitanceForm,
  NewSurveyWrapper,
  ErrorWrapper
} from '../../resources/styling';
import Grid from '@mui/material/Grid';
import FooterButtons from '../../components/common/FooterButtons';
import { FloatingInputLabel } from '../../components';
import { ExtendedFieldWrapper } from './SchoolSurveyStyle';

const SurveyCommentsForm = ({
  SurveyId,
  handleSubmit,
  title,
  submitButtonText,
  isOpen,
  onClose,
  survey
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Id: SurveyId,
        DemandCustomAdminText: survey.DemandCustomAdminText,
        ControlCustomAdminText: survey.ControlCustomAdminText,
        ChangeCustomAdminText: survey.ChangeCustomAdminText,
        RoleCustomAdminText: survey.RoleCustomAdminText,
        RelationshipsCustomAdminText: survey.RelationshipsCustomAdminText,
        PeerCustomAdminText: survey.PeerCustomAdminText,
        ManagerCustomAdminText: survey.ManagerCustomAdminText
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values);
        // actions.resetForm();
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        DemandCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        ControlCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        ChangeCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        RoleCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        RelationshipsCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        PeerCustomAdminText: Yup.string()
          .trim()
          .nullable(),
        ManagerCustomAdminText: Yup.string()
          .trim()
          .nullable()
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        successPopup,
        setFieldValue
      }) => {
        return (
          <AppModel           
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                   
                    <Grid item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className='form-column-wrapper'
                    >
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <h2>{title}</h2>
                            <InputWrapper>
                              <ExtendedFieldWrapper className='new-survey-placeholder'>
                                <FloatingInputLabel
                                  label='Demand Comments'
                                  name='Demand Comments'
                                  value={values.DemandCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'DemandCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />

                                <ErrorWrapper>
                                  {touched.DemandCustomAdminText &&
                                    errors.DemandCustomAdminText &&
                                    errors.DemandCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper className='new-survey-placeholder'>
                                <FloatingInputLabel
                                  label='Control Comments'
                                  name='Control Comments'
                                  value={values.ControlCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'ControlCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.ControlCustomAdminText &&
                                    errors.ControlCustomAdminText &&
                                    errors.ControlCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Manager Support Comments'
                                  name='Manager Support Comments'
                                  value={values.ManagerCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'ManagerCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.ManagerCustomAdminText &&
                                    errors.ManagerCustomAdminText &&
                                    errors.ManagerCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Peer Support Comments'
                                  name='Peer Support Comments'
                                  value={values.PeerCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'PeerCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.PeerCustomAdminText &&
                                    errors.PeerCustomAdminText &&
                                    errors.PeerCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Relationships Comments'
                                  name='Relationships Comments'
                                  value={values.RelationshipsCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'RelationshipsCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.RelationshipsCustomAdminText &&
                                    errors.RelationshipsCustomAdminText &&
                                    errors.RelationshipsCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Role Comments'
                                  name='Role Comments'
                                  value={values.RoleCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'RoleCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.RoleCustomAdminText &&
                                    errors.RoleCustomAdminText &&
                                    errors.RoleCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Change Comments'
                                  name='Change Comments'
                                  value={values.ChangeCustomAdminText}
                                  onChange={e =>
                                    setFieldValue(
                                      'ChangeCustomAdminText',
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorWrapper>
                                  {touched.ChangeCustomAdminText &&
                                    errors.ChangeCustomAdminText &&
                                    errors.ChangeCustomAdminText}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                            </InputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                   
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                submitButtonText={submitButtonText}
                handleSubmit={handleSubmit}
                onClose={onClose}
              />
            }
          />
        );
      }}
    />
  );
};

export default SurveyCommentsForm;
