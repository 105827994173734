import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import schoolService from '../../services/api/schoolService';
import { AppButton } from '../../resources/styling';
import { AdminHeader, Paging } from '../../components';
import { colors } from '../../resources/theme/colors';
import { WelbeeTypes } from '../../utils/Constants';
import { searchIconWhite } from '../../resources/images';
import DayPicker from 'react-day-picker';
import Grid from '@mui/material/Grid';
import { iconDownload } from '../../resources/images/index';
import Moment from 'moment';
import {
  CheckBox,
  MediaQuery,
  SchoolListMainWrapper,
  DownloadButtonStyle,
  TableStyle,
} from '../../resources/styling/appStyle';
import { toast } from 'react-toastify';
import { InputWrapper } from '../../components/common/loginStyle';

import {
  WrapperMain,
  DatePickerWrapper,
} from '../../resources/styling/formStyle';
import { AppInputField } from '../../components';
import { DateFormat } from '../../utils/CommonFunctions';
const { teal, pictonBlue } = colors;

export const SchoolSurveyWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  ${MediaQuery}

  .email-link {
    color: ${pictonBlue};
    text-decoration: underline;
    cursor: pointer;
  }
  .list-action {
    text-align: left !important;
  }
  .table {
    font-size: 14px;
    td,
    th {
      vertical-align: middle;
      padding: 15px;
    }
    tr > th {
      border-bottom: none !important;
    }
    button {
      margin-bottom: 0px;
      padding: 9px 18px;
      margin-right: 5px;
    }
  }
  .comments-search-filters {
    right: 0;
  }
  .test-checkbox {
    display: inline-block;
    width: 30%;
    padding-top: 7px;
  }

  @media only screen and (max-width: 700px) {
    .test-checkbox {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1224px) {
    .comments-search-filters {
      width: 100%;
    }
    .floating-label [type='number'] {
      padding: 17px 0px 13px 4px;
    }
    .super-admin-header {
      padding: 0px 20px;
    }
    .test-checkbox {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) {
    .styled-checkbox + label {
      margin-top: 11px;
    }
  }
`;

export const SchoolSurveyInnerMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 216px);
`;

export const SchoolSurveyInner = styled.div`
  display: block;
  margin-top: 30px;
`;

const PaginationWrapper = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
`;

class SchoolSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageOfItems: [],
      schoolsList: [],
      filteredList: [],
      editStartList: [],
      editEndList: [],
      editList: [],
      selectTestAccount: false,
      selectArchived: false,
      nameSearchValue: '',
      isChanged: false,
      createdDateSort: 0,
      startDateSort: 0,
      endDateSort: 0,
    };
    this.updateSubscriptions = this.updateSubscriptions.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleNameSearchChange = this.handleNameSearchChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getColumnText = this.getColumnText.bind(this);
    this.getAllSchools = this.getAllSchools.bind(this);
    this.showHideDatePicker = this.showHideDatePicker.bind(this);
    this.setDate = this.setDate.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.AutoRenewalCheckChange = this.AutoRenewalCheckChange.bind(this);
  }
  AutoRenewalCheckChange(val, idx) {
    let newArr = this.state.filteredList;
    newArr[idx].SchoolSubscription.IsAutoRenewal = val;
    let editList = this.state.editList;
    editList[idx] = newArr[idx].Id;
    this.setState(
      { filteredList: newArr, editList: editList },
      this.applyFilters()
    );
  }
  componentDidMount() {
    this.getAllSchools();
  }
  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems });
  }
  getAllSchools() {
    schoolService.GetAllSchoolsForSubscription().then((response) => {
      if (response.data !== null) {
        let ar = [];
        for (var i = 0; i < response.data.length; i++) {
          ar.push(false);
        }
        this.setState(
          {
            schoolsList: response.data,
            editStartList: [...ar],
            editEndList: [...ar],
            editList: [],
          },
          this.applyFilters
        );
      }
    });
  }
  updateSubscriptions() {
    let notDone = '';
    let newList = [];
    this.state.editList.map((val, idx) => {
      if (val) {
        let sSubscription = this.state.filteredList.find((x) => x.Id === val);
        if (
          Moment(sSubscription.SchoolSubscription.StartDate) <
          Moment(sSubscription.SchoolSubscription.EndDate)
        )
          newList.push(sSubscription.SchoolSubscription);
        else notDone = sSubscription.Name;
      }
    });
    if (newList.length > 0 && notDone === '') {
      schoolService
        .UpdateSubscription(newList)
        .then((response) => {
          if (response.success) {
            toast.success(response.message);
            let ar = [];
            for (var i = 0; i < this.state.filteredList.length; i++) {
              ar.push(false);
            }
            this.setState({
              editStartList: [...ar],
              editEndList: [...ar],
              editList: [],
            });
          } else toast.error(response.message);
        })
        .catch((error) => console.log('error', error));
    } else if (newList.length === 0 && notDone === '')
      toast.warning('No Changes made');
    else if (notDone !== '')
      toast.error(
        'Start Date must be before End Date  for the school ' + notDone
      );
  }
  showHideDatePicker(dateType, idx) {
    if (dateType === 's') {
      let newArr = this.state.editStartList;
      let val = newArr[idx];
      newArr.map((sh) => {
        sh = false;
      });
      newArr[idx] = !val;
      this.setState({ editStartList: newArr });
    } else {
      let newArr = this.state.editEndList;
      let val = newArr[idx];
      newArr.map((sh) => {
        sh = false;
      });
      newArr[idx] = !val;
      this.setState({ editEndList: newArr });
    }
  }
  setDate(dateType, date, idx) {
    let newArr = this.state.filteredList;
    if (dateType === 's') newArr[idx].SchoolSubscription.StartDate = date;
    else newArr[idx].SchoolSubscription.EndDate = date;
    let editList = this.state.editList;
    editList[idx] = newArr[idx].Id;
    this.setState({ filteredList: newArr, editList: editList });
    this.showHideDatePicker(dateType, idx);
  }
  setStartDate(val, item) {
    let newArr = this.state.filteredList;
    newArr[newArr.findIndex((x) => x.Id === item.Id)].StartDate = val;
    this.setState({ filteredList: newArr });
  }
  setEndDate(val, item) {
    let newArr = this.state.filteredList;
    newArr[newArr.findIndex((x) => x.Id === item.Id)].EndDate = val;
    this.setState({ filteredList: newArr });
  }
  getColumnText(item) {
    return item.SchoolSubscription.SubscriptionId === WelbeeTypes.WelbeeVoicePro
      ? 'Voice Pro'
      : item.SchoolSubscription.SubscriptionId === WelbeeTypes.WelbeeSurveyPro
      ? 'Survey Pro'
      : 'Survey Premium';
  }
  applyFilters() {
    const { nameSearchValue, schoolsList, selectTestAccount, selectArchived } =
      this.state;

    let searchList = schoolsList;
    if (this.state.createdDateSort === 1)
      searchList = searchList.sort((a, b) => {
        return new Date(a.AddedDate) - new Date(b.AddedDate);
      });
    if (this.state.createdDateSort === 2)
      searchList = searchList.reverse((a, b) => {
        return new Date(a.AddedDate) - new Date(b.AddedDate);
      });

    if (this.state.startDateSort === 1)
      searchList = searchList.sort((a, b) => {
        return (
          new Date(a.SchoolSubscription.StartDate) -
          new Date(b.SchoolSubscription.StartDate)
        );
      });
    if (this.state.startDateSort === 2)
      searchList = searchList.reverse((a, b) => {
        return (
          new Date(a.SchoolSubscription.StartDate) -
          new Date(b.SchoolSubscription.StartDate)
        );
      });

    if (this.state.endDateSort === 1)
      searchList = searchList.sort((a, b) => {
        return (
          new Date(a.SchoolSubscription.EndDate) -
          new Date(b.SchoolSubscription.EndDate)
        );
      });
    if (this.state.endDateSort === 2)
      searchList = searchList.reverse((a, b) => {
        return (
          new Date(a.SchoolSubscription.EndDate) -
          new Date(b.SchoolSubscription.EndDate)
        );
      });

    searchList = searchList.filter((x) =>
      x.Name.toLocaleLowerCase().includes(nameSearchValue.toLocaleLowerCase())
    );
    searchList = searchList.filter(
      (x) =>
        x.IsTestAccount === selectTestAccount && x.IsArchived === selectArchived
    );

    this.setState({ filteredList: [] }, () => {
      this.setState({ filteredList: searchList });
    });
  }
  handleNameSearchChange = (e) => {
    this.setState({ nameSearchValue: e.target.value }, this.applyFilters);
  };
  downloadPdf() {
    schoolService
      .downLoadSchoolSubscription(
        this.state.createdOnSort,
        this.state.StartDateSort,
        this.state.endDateSort,
        this.state.nameSearchValue.toLocaleLowerCase(),
        this.state.selectArchived,
        this.state.selectTestAccount
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Schools-Subscriptions.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch();
  }
  render() {
    return (
      <div>
        <AdminHeader />
        <SchoolSurveyWrapper>
          <SchoolSurveyInnerMain>
            <SchoolSurveyInner>
              <SchoolListMainWrapper>
                <Grid container>
                  <Grid item xl={4} lg={4} md={12} sm={12}>
                    <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                      School Subscriptions{' '}
                    </h1>
                  </Grid>
                  <Grid item xs={12} xl={2} lg={2} md={12} sm={12}>
                    <h1
                      style={{ minHeight: '60px', padding: 0 }}
                      className='super-admin-header'
                    >
                      <div
                        className='comments-search-filters test-checkbox'
                        style={{ width: '60%', height: '60px' }}
                      >
                        <CheckBox>
                          <input
                            className='styled-checkbox'
                            type='checkbox'
                            id='selectIsArchiveCheckbox'
                            value='value1'
                            checked={this.state.selectArchived}
                            onChange={(e) =>
                              this.setState(
                                { selectArchived: e.target.checked },
                                this.applyFilters
                              )
                            }
                          />
                          <label for='selectIsArchiveCheckbox'>
                            <span
                              className='checkbox-wrapper'
                              style={{ color: 'white' }}
                            >
                              Only Archives
                            </span>
                          </label>
                        </CheckBox>
                      </div>
                    </h1>
                  </Grid>
                  <Grid item xs={12} xl={2} lg={2} md={12} sm={12}>
                    <h1 className='super-admin-header' style={{ padding: 0 }}>
                      <div
                        className='test-checkbox'
                        style={{ width: '60%', height: '60px' }}
                      >
                        <CheckBox>
                          <input
                            className='styled-checkbox'
                            type='checkbox'
                            id='selectTestAccountCheckbox'
                            value='value1'
                            checked={this.state.selectTestAccount}
                            onChange={(e) =>
                              this.setState(
                                { selectTestAccount: e.target.checked },
                                this.applyFilters
                              )
                            }
                          />
                          <label for='selectTestAccountCheckbox'>
                            <span
                              className='checkbox-wrapper'
                              style={{ color: 'white' }}
                            >
                              Test Accounts
                            </span>
                          </label>
                        </CheckBox>
                      </div>
                    </h1>
                  </Grid>
                  <Grid col={4} xl={4} lg={4} md={4} sm={4} className='px-0'>
                    <h1
                      style={{ minHeight: '60px' }}
                      className='super-admin-header'
                    >
                      <div
                        className='comments-search-filters'
                        style={{ width: '100%' }}
                      >
                        <div className='subcontainer-inner school-list-header'>
                          <div
                            className='search-filed'
                            style={{ width: '100%' }}
                          >
                            <img src={searchIconWhite} alt='search button' />
                            <input
                              id='searchInputField'
                              placeholder='Enter a keyword'
                              onChange={(e) => {
                                e.preventDefault();
                                this.handleNameSearchChange(e);
                              }}
                              icon='search'
                              className='searchField'
                            />
                          </div>
                        </div>
                      </div>
                    </h1>
                  </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} px='0px'>
                  <TableStyle>
                    <table>
                      <thead>
                        <tr
                          style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: 'White',
                            zIndex: 999,
                          }}
                        >
                          <th className='list-heading'>School Name</th>
                          <th
                            className='list-heading'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                startDateSort: 0,
                                endDateSort: 0,
                              });
                              this.state.createdDateSort === 0
                                ? this.setState(
                                    { createdDateSort: 1 },
                                    this.applyFilters
                                  )
                                : this.state.createdDateSort === 1
                                ? this.setState(
                                    { createdDateSort: 2 },
                                    this.applyFilters
                                  )
                                : this.setState(
                                    { createdDateSort: 1 },
                                    this.applyFilters
                                  );
                            }}
                          >
                            Created On{' '}
                            {this.state.createdDateSort === 0 ? (
                              <span>&#x2191;&#x2193;</span>
                            ) : this.state.createdDateSort === 2 ? (
                              <span>&#x2193;</span>
                            ) : (
                              <span>&#x2191;</span>
                            )}
                          </th>
                          <th className='list-heading'>Subscription</th>
                          <th className='list-heading'>No.Of Pupils</th>
                          <th
                            className='list-heading'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                createdDateSort: 0,
                                endDateSort: 0,
                              });
                              this.state.startDateSort === 0
                                ? this.setState(
                                    { startDateSort: 1 },
                                    this.applyFilters
                                  )
                                : this.state.startDateSort === 1
                                ? this.setState(
                                    { startDateSort: 2 },
                                    this.applyFilters
                                  )
                                : this.setState(
                                    { startDateSort: 1 },
                                    this.applyFilters
                                  );
                            }}
                          >
                            Start Date{' '}
                            {this.state.startDateSort === 0 ? (
                              <span>&#x2191;&#x2193;</span>
                            ) : this.state.startDateSort === 2 ? (
                              <span>&#x2193;</span>
                            ) : (
                              <span>&#x2191;</span>
                            )}
                          </th>
                          <th
                            className='list-heading'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                startDateSort: 0,
                                createdDateSort: 0,
                              });
                              this.state.endDateSort === 0
                                ? this.setState(
                                    { endDateSort: 1 },
                                    this.applyFilters
                                  )
                                : this.state.endDateSort === 1
                                ? this.setState(
                                    { endDateSort: 2 },
                                    this.applyFilters
                                  )
                                : this.setState(
                                    { endDateSort: 1 },
                                    this.applyFilters
                                  );
                            }}
                          >
                            End Date{' '}
                            {this.state.endDateSort === 0 ? (
                              <span>&#x2191;&#x2193;</span>
                            ) : this.state.endDateSort === 2 ? (
                              <span>&#x2193;</span>
                            ) : (
                              <span>&#x2191;</span>
                            )}
                          </th>
                          <th>Auto Renewal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pageOfItems.map((item, i) => (
                          <tr key={item.Id}>
                            <td>{item.Name ? item.Name : 'Not Provided'}</td>
                            <td>
                              {item.AddedDate
                                ? new Date(
                                    Date.parse(item.AddedDate)
                                  ).toDateString()
                                : 'Not Provided'}
                            </td>
                            <td>{this.getColumnText(item)}</td>
                            <td>{item.NumberOfPupil}</td>
                            <td>
                              <InputWrapper>
                                <WrapperMain>
                                  <div
                                    onClick={() =>
                                      this.showHideDatePicker('s', i)
                                    }
                                  >
                                    <AppInputField
                                      placeholder='Start Date'
                                      name='StartDate'
                                      value={DateFormat(
                                        item.SchoolSubscription.StartDate
                                      )}
                                    />
                                  </div>
                                  <DatePickerWrapper
                                    style={{ left: '30%', zIndex: 999 }}
                                  >
                                    {this.state.editStartList[i] && (
                                      <DayPicker
                                        style={{
                                          position: 'absolute',
                                          zIndex: 999,
                                        }}
                                        showOutsideDays
                                        onDayClick={(date) =>
                                          this.setDate('s', date, i)
                                        }
                                        dayPickerProps={{
                                          selectedDays: new Date(),
                                        }}
                                      />
                                    )}
                                  </DatePickerWrapper>
                                </WrapperMain>
                              </InputWrapper>
                            </td>
                            <td>
                              <InputWrapper>
                                <WrapperMain>
                                  <div
                                    onClick={() =>
                                      this.showHideDatePicker('', i)
                                    }
                                  >
                                    <AppInputField
                                      placeholder='End Date'
                                      name='EndDate'
                                      value={DateFormat(
                                        item.SchoolSubscription.EndDate
                                      )}
                                      autoComplete='off'
                                    />
                                  </div>
                                  <DatePickerWrapper
                                    style={{
                                      left: '30%',
                                      zIndex: 999,
                                      positio: 'absolute',
                                    }}
                                  >
                                    {this.state.editEndList[i] && (
                                      <DayPicker
                                        onDayClick={(date) =>
                                          this.setDate('', date, i)
                                        }
                                      />
                                    )}
                                  </DatePickerWrapper>
                                </WrapperMain>
                              </InputWrapper>
                            </td>
                            <td>
                              <CheckBox>
                                <input
                                  className='styled-checkbox'
                                  type='checkbox'
                                  id={'selectAutoRenewalCheckbox' + i}
                                  value={'value' + i}
                                  checked={
                                    item.SchoolSubscription.IsAutoRenewal
                                  }
                                  onChange={(e) =>
                                    this.AutoRenewalCheckChange(
                                      e.target.checked,
                                      i
                                    )
                                  }
                                />
                                <label for={'selectAutoRenewalCheckbox' + i}>
                                  <span
                                    className='checkbox-wrapper'
                                    style={{ color: 'white' }}
                                  >
                                    Auto
                                  </span>
                                </label>
                              </CheckBox>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableStyle>
                </Grid>
                <PaginationWrapper>
                  <Paging
                    items={this.state.filteredList}
                    onChangePage={this.onChangePage}
                    isChanged={this.state.isChanged}
                  />
                </PaginationWrapper>
                <Grid>
                  <Grid col={6} xl={6} lg={6} md={6} sm={6}>
                    <AppButton
                      color={teal}
                      style={{ marginBottom: '30px' }}
                      type='Update Subscriptions'
                      onClick={this.updateSubscriptions}
                    >
                      Update Subscriptions
                    </AppButton>
                  </Grid>
                  <Grid col={6} xl={6} lg={6} md={6} sm={6}>
                    <DownloadButtonStyle
                      style={{ float: 'right', position: 'relative' }}
                      data-id-div='reportDownload'
                      onClick={this.downloadPdf}
                    >
                      <img src={iconDownload} alt='' />
                    </DownloadButtonStyle>
                  </Grid>
                </Grid>
              </SchoolListMainWrapper>
            </SchoolSurveyInner>
          </SchoolSurveyInnerMain>
        </SchoolSurveyWrapper>
      </div>
    );
  }
}

export default withRouter(SchoolSubscriptions);
