export const actionTypes = {
  //-------------------------Loader----------------------//
  DATA_LOADING: 'DATA_LOADING',

  //-------------------------Account----------------------//
  LOGIN: 'LOGIN',
  ISFIRSTLOGIN: 'ISFIRSTLOGIN',
  ISBELL_NOTIFICATION: 'ISBELL_NOTIFICATION',

  // -------------------- Survey Actiontypes -----------------------------//

  GET_SURVEY_DATA: 'GET_SURVEY_DATA',
  SET_SURVEY_DATA: 'GET_SURVEY_DATA',

  NAVIGATION_CHANGE: 'NAVIGATION_CHANGE',
  SHOW_REPORT_SUB_MENU: 'SHOW_REPORT_SUB_MENU',
  TOGGLE_BUTTON_SWITCH: 'TOGGLE_BUTTON_SWITCH',
  SHOW_NEW_CONVERSATION_COUNT: 'SHOW_NEW_CONVERSATION_COUNT',

  //------------------------School-----------------------//

  SCHOOL_LIST: 'SCHOOL_LIST',
  SET_SCHOOL_LOGO: 'SET_SCHOOL_LOGO',
  FREE_TRIAL_END_DATE: 'FREE_TRIAL_END_DATE',
  //------------------------ActionPlan-----------------------//

  OPEN_CREATEACTION_POPUP: 'OPEN_CREATEACTION_POPUP',

  //----------------------- Super Admin --------------------//
  SCHOOLS_LIST_FLAG: 'SCHOOLS_LIST_FLAG',

  //----------------------- Left Menu --------------------//
  SHOW_NEW_COMMENT_COUNT: 'SHOW_NEW_COMMENT_COUNT',
  //----------------------- To scroll to upload staff list ----------- //
  DO_SCROLL: 'DO_SCROLL',
  //----------------------- Set Selected Conversation --------------------//
  SET_SELECTED_CONVERSATION: 'SET_SELECTED_CONVERSATION',
  SURVEY_COMMENTS: 'SURVEY_COMMENTS',
  SELECTED_COMMENT: 'SELECTED_COMMENT',
  SELECTED_QUESTION: 'SELECTED_QUESTION',
};
