
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
const getAdditionalQuestionsTextData = (AdditionQuestionIds) =>
{
  return (
    <div className='section'>
      <div className='additional-question-table'>
        <Scrollbars className='scrollbar-view'>
          <div className='job-role-container'>
            <div className='stats-title-cell' style={{ width: '14%' }}>
              Question Number
            </div>
            <div className='stats-role-cell' style={{ width: '86%', justifyContent: 'start', paddingLeft: '12px' }}>
              Question Text
            </div>
          </div>
        
      {AdditionQuestionIds?.map((item) => (
        <div className='job-role-container'>
          <div className='job-role-title-cell' style={{ width: '14%' }}>
            Q: {item.Order}
          </div>
          <div className='width-60-grey' style={{ width: '86%', textAlign: 'left !important' }}>
            {item.Text}
          </div>
        </div>
      ))}
      </Scrollbars>
      </div>
    </div>
  );
}

const getAdditionalQuestionsDataForSchool=(AdditionalQuestionsScores, allScore, AdditionQuestionIds) =>
  {
    var data = Object.entries(AdditionalQuestionsScores?AdditionalQuestionsScores:null);
    var allScoreRow = Object.entries(allScore)[0];
    if (data?.length > 0)
      return (
        <div className='section score-table'>
          <Scrollbars
            className='scrollbar-view'
          >
            <div className='job-role-container'>
              <div className='stats-title-cell'>SCHOOLS</div>
              {AdditionQuestionIds?.map((item) => (
                <div className='stats-role-cell'>Q: {item.Order}</div>
              ))}
            </div>

            <div className='job-role-container'>
              <div className='job-role-title-cell'>{allScoreRow[0]}</div>

              {allScoreRow[1]?.map((score) =>
              {
                return <div className='job-role-cell'>{score.toFixed(2)}</div>;
              })}
            </div>
            {data?.map((item) =>
            {
              let score = item[1];
              return (
                <div className='job-role-container'>
                  <div className='job-role-title-cell'>{item[0]}</div>

                  {score?.map((score) =>
                  {
                    return (
                      <div className='job-role-cell'>{score.toFixed(2)}</div>
                    );
                  })}
                </div>
              );
            })}
          </Scrollbars>
        </div>
      );
  }

  const getAdditionalQuestionsDataForJobRole = (AdditionalQuestionsScores, AdditionQuestionIds) =>
  {
    var data = Object.entries(AdditionalQuestionsScores);
    if (data?.length > 0)
      return (
        <div className='section'>
          <div className='additional-question-table'>
            <Scrollbars className='scrollbar-view'>
              <div className='job-role-container'>
                <div className='stats-title-cell'> JOB ROLE</div>
                
                {AdditionQuestionIds?.map((item) => (
                  <div className='stats-role-cell'>Q: {item.Order}</div>
                ))}
              </div>

              {data?.map((item) =>
              {
                let score = item[1];
                return (
                  <div className='job-role-container'>
                    <div className='job-role-title-cell'>{item[0]}</div>

                    {score?.map((score) =>
                    {
                      return (
                        <div className='job-role-cell'>{score.toFixed(2)}</div>
                      );
                    })}
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      );
  }
const QuestionScorebyJobRoles = (props) => {
    return (
        <SurveyGreetingPresentationStyle className="survey-greeting" id={props.id}>
            <MatReportWrapper>
                <div className='mat-presentation-section' id='summery-score'>                  
                    {/* {props.SchoolName} {'MAT Presentation'}
                    <AppLogo type="overview" color={colors.lightPink} width="100" /> 
                    */}
                    <span className='app-logo'><img src={props.welbeeslideslogo} alt=""/></span>
                    <h1 className='pres-title'>{props.title}</h1>
                    {props?.HasAdditionalQuestion ? (
                          <div id='add-questions'>
                       
                            <p className='table-title'>
                              Table 3: Your Questions
                            </p>
                            {getAdditionalQuestionsTextData(
                              props?.AdditionQuestionIds
                            )}                        
                                <p className='table-title'>
                              Table 4: Your Question Scores by Job Role
                            </p>
                            
                            {getAdditionalQuestionsDataForJobRole(
                             props?.AdditionalQuestionsScores,
                             props?.AdditionQuestionIds
                            )}
                          </div>
                        ) : null}   

                           <p className='section-bottom'>* Heatmaps of all filtered scores for your questions can be viewed and downloaded from your dashboard.</p>                          
                </div>
            </MatReportWrapper>
     
        </SurveyGreetingPresentationStyle>
    );
};
export default QuestionScorebyJobRoles;