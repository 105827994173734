import React from 'react';
import { useTranslation } from 'react-i18next';

const LearnPresentation = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        You have access to a presentation of your results. You can use this to
        share information with staff as appropriate. Sharing the results with
        staff is an important step as they have provided the feedback and should
        be part of solutions too.
      </p>
      <p>
        This can be used directly from your dashboard or can be downloaded as a
        PowerPoint. You can then adapt and share the information you want.
      </p>
      <p>
        In addition to scores it shares strengths and development opportunities
        from each of the management standards. On occasions results can appear
        to contradict with a strength and development area showing for the same
        item. If this happens it will be because one is compared to other
        schools and the other to all cross sector {t('organisations')}. It is
        possible to score highly against other schools and have a low
        comparative score against all {t('organisations')} and vice-versa.
      </p>
    </div>
  );
};

export default LearnPresentation;
