import React from 'react';
import { WrapperMain } from '../../resources/styling/formStyle';

const TextArea = ({
  name,
  maxLength,
  onBlur,
  value,
  onChange,
  placeholder,
  className
}) => (
  <WrapperMain className='floating-label'>
    <textarea
      name={name}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      rows={4}
      cols={50}
      className='floating-input'
    />
    {placeholder === 'Reply'? <label className='Label'>{placeholder}</label> : <label className='Label1'>{placeholder}</label>}
  </WrapperMain>
);

export default TextArea;
