import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DayPicker from 'react-day-picker';
import { Formik } from 'formik';
import {
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  NewSurveyWrapper,
  AssitanceForm,
  ErrorWrapper,
} from '../../resources/styling';
import { DatePickerWrapper } from '../../resources/styling/formStyle';
import Grid from '@mui/material/Grid';
import FooterButtons from '../../components/common/FooterButtons';
import { FloatingInputLabel } from '../../components';
import { DateFormat } from '../../utils/CommonFunctions';
import { withRouter } from 'react-router-dom';
import { LoaderOverlay } from '../../resources/styling/appStyle';
import {
  ExtendedFieldWrapper,
  ExtendedNewSurveyInputWrapper,
} from './MatStyle';
import MuiDropDown from '../../components/common/MuiDropDown';
import MenuItem from '@mui/material/MenuItem';
import {
  ExtendedHourWrapper,
  ExtendedMinWrapper,
} from '../schoolSurvey/SchoolSurveyStyle';

const defaultTime = {
  value: -1,
  label: '',
  Selected: false,
};
const NewSurveyForm = (props) => {
  const {
    Name,
    StartDate,
    SchoolId,
    onSubmit,
    title,
    submitButtonText,
    isOpen,
    onClose,
    EndDate,
    surveyName,
    isLoading,
    selectAllSchool,
    matSchoolsGroup,
    schoolCheckboxChange,
    allSelected,
    GetMatSurveyName,
    TaggedTimeToPopUp,
  } = props;
  const [isStartDateCalenderOpen, setIsStartDateCalendarOpen] = useState(false);

  const handleStartDateCalendar = () => {
    setIsStartDateCalendarOpen(true);
  };

  const closeCalendarPopups = () => {
    setIsStartDateCalendarOpen(false);
  };
  const getTimeOptions = (date) => {
    let hoptions = [];
    let startFromHour =
      new Date().toDateString() === date.toDateString()
        ? new Date().getHours()
        : 0;
    if (new Date().getMinutes() > 54) startFromHour++;
    if (startFromHour > 23) return;
    for (let i = startFromHour; i <= 23; i++) {
      let label = i < 10 ? '0' + i : i;
      hoptions.push({ label: label, value: i });
    }

    setSelectedHour(defaultTime);
    setHourOptions(hoptions);
  };
  const getMinOptions = (date, hOption) => {
    let moptions = [];
    let startFromMin =
      new Date().getMinutes() > 54
        ? 5
        : date.toDateString() == new Date().toDateString() &&
          hOption.target.value === new Date().getHours()
        ? new Date().getMinutes() + 5
        : 0;
    for (let i = startFromMin; i <= 59; i++) {
      let label = i < 10 ? '0' + i : i;
      moptions.push({ label: label, value: i });
    }

    setSelectedMin(defaultTime);
    setMinOptions(moptions);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState(defaultTime);
  const [selectedMin, setSelectedMin] = useState([]);
  const [hourOptions, setHourOptions] = useState([]);
  const [minOptions, setMinOptions] = useState([]);
  const [showFooterButton, setShowFooterButton] = useState(false);

  useEffect(() => {
    getTimeOptions(new Date());
    GetMatSurveyName(new Date());
  }, []);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Name: surveyName || Name,
        StartDate: StartDate,
        SchoolId: SchoolId,
        EndDate: EndDate,
        isCalenderOpen: false,
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        values.StartDate.setHours(selectedHour);
        values.StartDate.setMinutes(selectedMin);
        onSubmit(values);
        actions.resetForm();
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Name: Yup.string().trim().max(150).required('Survey name is required.'),
        StartDate: Yup.date('Date is not in correct format!').required(
          'Start date is required.'
        ),
        //EndDate: Yup.date('Close Date is not in correct format!').required(
        //  'Close date is required.'
        //),
      })}
      render={({
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        selectedDay,
      }) => {
        let todayDate = moment().toDate();

        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className='form-column-wrapper'
                    >
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <h2>{title}</h2>
                            {isLoading && (
                              <LoaderOverlay>
                                <div className='comment-loader'>
                                  <div id='loading' />
                                </div>
                              </LoaderOverlay>
                            )}
                            <ExtendedNewSurveyInputWrapper>
                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Start date'
                                  name='Start date'
                                  value={DateFormat(startDate)}
                                  onClick={handleStartDateCalendar}
                                  autoComplete='off'
                                />
                                <DatePickerWrapper>
                                  {isStartDateCalenderOpen && (
                                    <DayPicker
                                      showOutsideDays
                                      disabledDays={{ before: todayDate }}
                                      onDayClick={(date) => {
                                        GetMatSurveyName(date);
                                        setFieldValue('StartDate', date);
                                        setIsStartDateCalendarOpen(false);
                                        setStartDate(date);
                                        getTimeOptions(date);
                                      }}
                                      dayPickerProps={{
                                        selectedDays: selectedDay,
                                        disabledDays: {
                                          daysOfWeek: [0, 6],
                                        },
                                      }}
                                    />
                                  )}
                                </DatePickerWrapper>
                                <ErrorWrapper>
                                  {touched.StartDate &&
                                    errors.StartDate &&
                                    errors.StartDate}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedHourWrapper>
                                <MuiDropDown
                                  name='Hour'
                                  label='Start Time - Hour'
                                  //options={hourOptions}
                                  selectedOption={selectedHour}
                                  onClick={closeCalendarPopups}
                                  onChange={(option) => {
                                    setSelectedHour(option.target.value);
                                    setFieldValue(
                                      'Start Time - Hour',
                                      option.target.value
                                    );
                                    getMinOptions(values.StartDate, option);
                                    TaggedTimeToPopUp(
                                      option.target.value,
                                      'Hour'
                                    );
                                  }}
                                  values={values.Hour}
                                >
                                  {hourOptions.map((item) => (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.value}
                                    </MenuItem>
                                  ))}
                                </MuiDropDown>

                                <ErrorWrapper>
                                  {touched.Hour && errors.Hour && errors.Hour}
                                </ErrorWrapper>
                              </ExtendedHourWrapper>
                              <span
                                style={{
                                  float: 'left',
                                  width: '2%',
                                  textAlign: 'center',
                                  'font-weight': 'bold',
                                  'font-size': '25px',
                                  'padding-top': '10px',
                                }}
                              >
                                :
                              </span>
                              <ExtendedMinWrapper>
                                <MuiDropDown
                                  name='Minutes'
                                  label='Start Time - Minutes'
                                  selectedOption={selectedMin}
                                  value={values.Minutes}
                                  onChange={(option) => {
                                    setFieldValue(
                                      'Start Time - Minutes',
                                      option.target.value
                                    );
                                    setSelectedMin(option.target.value);
                                    TaggedTimeToPopUp(
                                      option.target.value,
                                      'Minutes'
                                    );
                                    setShowFooterButton(true);
                                  }}
                                >
                                  {minOptions.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.value}
                                      </MenuItem>
                                    );
                                  })}
                                </MuiDropDown>
                                <ErrorWrapper>
                                  {touched.Minutes &&
                                    errors.Minutes &&
                                    errors.Minutes}
                                </ErrorWrapper>
                              </ExtendedMinWrapper>
                              <ExtendedFieldWrapper className='new-survey-placeholder'>
                                {values.Name &&
                                  <FloatingInputLabel
                                    label='Survey title'                                  
                                    name='Survey title'
                                    value={values.Name}                                  
                                    onChange={(e) => {
                                      setFieldValue('Name', e.target.value);
                                      closeCalendarPopups();
                                    }}
                                    onFocus={closeCalendarPopups}
                                  />
                                }
                                <ErrorWrapper>
                                  {touched.Name && errors.Name && errors.Name}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>

                              <p class='info-text'>
                                Select which sites you would like to involve in
                                this survey:
                              </p>
                              <div class='school-list-wrapper'>
                                <div className='school-list-heading'>
                                  <span>
                                    <input
                                      checked={allSelected}
                                      type='checkbox'
                                      onChange={(e) => {
                                        selectAllSchool(e.target.checked);
                                      }}
                                    />
                                  </span>
                                  <span class='school-title'>Schools</span>
                                  <span class='staff-members-title'>
                                    Staff members
                                  </span>
                                </div>
                                <div className='school-list-body-outer'>
                                  {matSchoolsGroup &&
                                    matSchoolsGroup.map((item) => {
                                      let cantCreate =
                                        item.hasActiveSurvey ||
                                        item.wasInPreviouseGroupSurvey;
                                      return (
                                        <div className='school-list-body'>
                                          <span>
                                            <input
                                              type='checkbox'
                                              checked={item.IsChecked}
                                              onChange={(e) => {
                                                if (cantCreate)
                                                  e.target.checked = false;
                                                schoolCheckboxChange(
                                                  item,
                                                  !item.IsChecked
                                                );
                                              }}
                                            />
                                          </span>
                                          <span
                                            className={
                                              !cantCreate
                                                ? 'school-title'
                                                : 'disabled'
                                            }
                                          >
                                            {item.Name}
                                          </span>
                                          <span
                                            className={
                                              'staff-members-count ' +
                                              (!cantCreate ? '' : 'disabled')
                                            }
                                          >
                                            {item.noOfStaff}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </ExtendedNewSurveyInputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                submitButtonText={submitButtonText}
                handleSubmit={() => {
                  closeCalendarPopups();
                  handleSubmit();
                }}
                onClose={onClose}
                showFooterButton={showFooterButton}
              />
            }
          />
        );
      }}
    />
  );
};

export default withRouter(NewSurveyForm);
