import React from 'react';
import { InputTxtareaStyle } from './SurveyStyle';

const InputTextarea = ({ placeholder, onChange, ...rest }) => {
  return (
    <InputTxtareaStyle
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  );
};

export default InputTextarea;
