import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import matGroupService from '../../../services/api/matGroupService';
import { matCycleService } from '../../../services';
import { AppButton } from '../../../resources/styling';
import { AdminHeader, Paging } from '../../../components';
import { colors } from '../../../resources/theme/colors';
import { storageService } from '../../../services';
import { WebStorageNames } from '../../../utils/Constants';
import {
  SchoolListMainWrapper,
  TableStyle,
} from '../../../resources/styling/appStyle';
import ConfirmPopupForm from '../../../components/common/ConfirmPopupForm';
import { toast } from 'react-toastify';
import AddCycle from './AddCycle';
import EditCycle from './EditCycle';
import { routesObj } from '../../../routes';
import {
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
  PaginationWrapper,
} from './MatCycleStyle';

const { mandy, teal } = colors;

const MatCycles = (props) => {
  const [groupId] = useState(
    storageService.getItem(WebStorageNames.MatGroupId)
  );
  const [isShowAddCycle, setIsShowAddCycle] = useState(false);
  const [isShowEditCycle, setIsShowEditCycle] = useState(false);
  const [isRemovePopupOpen, setRemovePopupOpen] = useState(false);
  const [matCycleId, setMatCycleId] = useState('');
  const [matCycles, setMatCycles] = useState([]);
  const [matCycleSchools, setMatCycleSchools] = useState([]);
  const [matCycleSchoolsWithOpensurvey, setMatCycleSchoolsWithOpensurvey] =
    useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [isShowTopHeaderAddCycleBtn, setIsShowTopHeaderAddCycleBtn] =
    useState(true);

  useEffect(() => {
    getMatCycles();
    getMatSchools();
    getMatSchoolsWithOpenSurvey();
  }, {});

  const getMatSchools = () => {
    matGroupService
      .getMatSchools(groupId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setMatCycleSchools(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading schools.');
      });
  };

  const getMatSchoolsWithOpenSurvey = () => {
    matGroupService
      .getMatSchoolsWithOpenSurveyForCycle(groupId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setMatCycleSchoolsWithOpensurvey(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading schools.');
      });
  };
  const getMatCycles = () => {
    matCycleService
      .getByMatGroupId(groupId)
      .then((response) => {
        const { success } = response;
        if (success) {
          let totalCycles = response.data.length;
          setMatCycles([]);
          setMatCycles(response.data);
          if (totalCycles >= 1) {
            setIsShowTopHeaderAddCycleBtn(response.data[0].IsMatCycleClosed);
          }
        }
      })
      .catch((error) => {
        toast.error('Error loading mat cycles.');
      });
  };

  function removeCycle() {
    matCycleService
      .removeCycle(matCycleId)
      .then((response) => {
        const { success } = response;
        setRemovePopupOpen(false);
        toast.success('Cycle remove sucessfully');
        if (success) {
          getMatCycles();
        }
      })
      .catch(() => {
        setRemovePopupOpen(false);
        toast.error('Error removing cycle.');
      });
  }

  const handleCycleSubmit = () => {
    setIsShowAddCycle(false);
    setIsShowEditCycle(false);
    getMatCycles();
  };

  function onChangePage(pageOfItems) {
    setPageOfItems(pageOfItems);
  }
  return (
    <div>
      <AdminHeader />
      <SchoolSurveyWrapper>
        <SchoolSurveyInnerMain>
          <SchoolSurveyInner>
            <SchoolListMainWrapper>
              <h1 style={{ margin: '0' }}>
                <span
                  className='back-btn'
                  onClick={() => props.history.push('/mat-accounts')}
                >
                  Back
                </span>
                <span className='heading'>Mat Cycles</span>
                {isShowTopHeaderAddCycleBtn ? (
                  <span
                    className='create-school'
                    onClick={() => setIsShowAddCycle(true)}
                  >
                    Add Cycle
                  </span>
                ) : (
                  <span className='create-school'>
                    To Create New, Close Existing Cycle First
                  </span>
                )}
              </h1>
              <TableStyle>
                <table>
                  <thead>
                    <tr>
                      <th className='list-heading'>Name</th>
                      <th className='list-heading list-action'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems.map((item, i) => (
                      <tr key={item.Id}>
                        <td>{item.Name ? item.Name : 'Not Provided'}</td>
                        <td>
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={() => {
                              setMatCycleId(item.Id);
                              setIsShowEditCycle(true);
                            }}
                          >
                            Edit
                          </AppButton>
                          <AppButton
                            color={teal}
                            title='first add school by clicking tag school button from dashboard'
                            type='submit'
                            onClick={() => {
                              props.history.push({
                                pathname: routesObj.MatCycleDetail.path,
                                state: { id: item.Id },
                              });
                            }}
                          >
                            Add School
                          </AppButton>

                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={() => {
                              setRemovePopupOpen(true);
                              setMatCycleId(item.Id);
                            }}
                          >
                            Remove
                          </AppButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableStyle>
              <PaginationWrapper>
                <Paging
                  items={matCycles}
                  onChangePage={(items) => onChangePage(items)}
                />
              </PaginationWrapper>
            </SchoolListMainWrapper>
          </SchoolSurveyInner>

          <ConfirmPopupForm
            isOpen={isRemovePopupOpen}
            onClose={() => {
              setRemovePopupOpen(false);
              setMatCycleId(null);
            }}
            onOk={() => removeCycle()}
            successPopupMessage='Are you sure to remove this mat cycle?'
          />
          <AddCycle
            isOpen={isShowAddCycle}
            onClose={() => {
              setIsShowAddCycle(false);
            }}
            schoolsList={matCycleSchoolsWithOpensurvey}
            handleSubmit={handleCycleSubmit}
          />

          <EditCycle
            isOpen={isShowEditCycle}
            onClose={() => {
              setIsShowEditCycle(false);
            }}
            schoolsList={matCycleSchools}
            handleSubmit={handleCycleSubmit}
            matCycleId={matCycleId}
          />
        </SchoolSurveyInnerMain>
      </SchoolSurveyWrapper>
    </div>
  );
};

const mapStateToProps = ({ superAdmin: { SetSchoolsListFlag } }) => ({
  SetSchoolsListFlag,
});
export default connect(mapStateToProps, null)(withRouter(MatCycles));
