import request from '../request';
import { matAdditionalQuestionsAPI } from './urls';

export const getQuestionBankByMatGroupId = (matGroupId) => {
  return request({
    url: matAdditionalQuestionsAPI.getQuestionBankByMatGroupId(matGroupId),
    method: 'GET'
  })
    .then(response => response)
    .catch(error => error);
};

export const getQuestionsByMatGroupId = (matGroupId) => {
  return request({
    url: matAdditionalQuestionsAPI.getQuestionsByMatGroupId(matGroupId),
    method: 'GET'
  })
    .then(response => response)
    .catch(error => error);
};

export const setMatQuestions = (question) => {
  return request({
    url: matAdditionalQuestionsAPI.api,
    method: 'POST',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const deleteMatQuestions = (question) => {
  return request({
    url: matAdditionalQuestionsAPI.api,
    method: 'DELETE',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const downloadHseQuestions = () => {
  return request({
    url: matAdditionalQuestionsAPI.downloadHseQuestions(),
    method: 'GET',
    responseType: 'blob'
  })
    .then(response => response)
    .catch(error => error);
};

const matAdditionalQuestionService = {
  setMatQuestions,
  deleteMatQuestions,
  getQuestionsByMatGroupId,
  getQuestionBankByMatGroupId,
  downloadHseQuestions
};

export default matAdditionalQuestionService;
