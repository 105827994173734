import React, { Component } from 'react';
import { activityReportService } from '../../services';
import ActivityReportHeader from './ActivityReportHeader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import SortIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import { customStyles } from './ActivityReportStyle';

const calculateDays = (addedDate) => {
  return Math.floor((new Date() - new Date(addedDate)) / 86400000);
  // 24 * 60 * 60 * 1000 = 86400000
};

const columns = [
  {
    name: 'School',
    selector: 'Name',
    sortable: true,
    wrap: true,
    conditionalCellStyles: [
      {
        when: (row) =>
          calculateDays(row.AddedDate) > 6 && row.SchoolStaffs.length < 1,
        style: {
          backgroundColor: 'rgb(232, 79, 107)',

          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    ],
  },
  {
    name: 'Full Name',
    selector: (d) => {
      return d.HeadTeachers.length ? d.HeadTeachers[0].FullName : null;
    },
    sortable: true,
    wrap: true,
    width: '10%',
  },
  {
    name: 'Email',
    selector: (d) => {
      return d.HeadTeachers.length ? d.HeadTeachers[0].User.Email : null;
    },
    sortable: true,
    wrap: true,
    width: '15%',
  },
  {
    name: 'Account Type',
    selector: 'WelbeeType',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Status',
    selector: 'SignUpStatus',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Registration Date',
    selector: (d) => {
      return d.AddedDate == null
        ? null
        : moment(d.AddedDate).local().format('DD-MM-YY');
    },
    sortable: true,
  },
  {
    name: 'MAT Group',
    selector: (d) => {
      return d.MatGroup === null ? null : d.MatGroup.Name;
    },
    sortable: true,
    wrap: true,
  },
  {
    name: 'Survey Start',
    selector: (d) => {
      let survey = d.Surveys.slice(-1)[0];
      return survey === null || survey === undefined
        ? null
        : survey.StartDate === null || survey.StartDate === undefined
        ? null
        : moment(survey.StartDate).local().format('DD-MM-YY');
    },
    wrap: true,
  },
  {
    name: 'Survey End',
    selector: (d) => {
      let survey = d.Surveys.slice(-1)[0];
      return survey === null || survey === undefined
        ? null
        : survey.EndDate === null || survey.EndDate === undefined
        ? null
        : moment(survey.EndDate).local().format('DD-MM-YY');
    },

    wrap: true,
  },

  {
    name: 'First Submission',
    selector: (d) => {
      let submissionDate = null;
      d.Surveys.map((x) => {
        let submission = x.SurveySubmissions.slice(-1)[0];
        submissionDate =
          submission === null || submission === undefined
            ? null
            : submission.SubmissionDate === null ||
              submission.SubmissionDate === undefined
            ? null
            : moment(submission.SubmissionDate).local().format('DD-MM-YY');
      });
      return submissionDate;
    },

    wrap: true,
  },

  {
    name: 'Survey Closed',

    selector: (d) => {
      let survey = d.Surveys.slice(-1)[0];
      let result =
        survey == null || survey === undefined
          ? null
          : survey.IsSurveyClosed.toString();
      return result === 'true' ? 'Yes' : result === 'false' ? 'No' : null;
    },
  },
  {
    name: 'Updated Date',
    selector: (d) => {
      return d.UpdatedDate == null
        ? null
        : moment(d.UpdatedDate).local().format('DD-MM-YY');
    },
    sortable: true,
  },
];

const statusFilterOptions = [
  { label: 'All', value: 'All' },
  { label: 'SchoolDetail', value: 'SchoolDetail' },
  { label: 'InVoiceDetail', value: 'InVoiceDetail' },
  { label: 'Completed', value: 'Completed' },
  { label: 'WellbeeVoice', value: 'WellbeeVoice' },
  { label: 'WelbeeHeadTeacherDetail', value: 'WelbeeHeadTeacherDetail' },
  { label: 'EmailVerification', value: 'EmailVerification' },
];

class AccountRegistrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      rowPerPage: 10,
      currentPage: 1,
      start: 0,
      filterText: null,
      fromDate: new Date(),
      toDate: new Date(),
      testOnly:false,
      archiveOnly:false,
      statusFilterSelectedOption: { label: 'All', value: 'All' },
    };
  }

  async componentDidMount() {
    const { rowPerPage } = this.state;
  }

  statusChangeHandler = (statusFilterSelectedOption) => {
    this.setState({
      statusFilterSelectedOption,
    });
  };

  filterHandler = (filterText) => {
    this.setState({ loading: true, filterText: filterText });
    const { rowPerPage, currentPage, start } = this.state;

    let skip = 0;
    if (currentPage > 1) {
      skip = rowPerPage * (currentPage - 1);
    }

    const model = {
      Length: rowPerPage,
      Draw: currentPage,
      Start: skip,
      Search: {
        Value: filterText,
      },
      Columns: [
        {
          Name: 'FromDate',
          Search: {
            Value: this.state.fromDate,
          },
        },
        {
          Name: 'ToDate',
          Search: {
            Value: this.state.toDate,
          },
        },
        {
          Name: 'Status',
          Search: {
            Value: this.state.statusFilterSelectedOption.value,
          },
        },
        {
          Name: 'OnlyTestAccounts',
          Search: {
            Value: this.state.testOnly,
          }
        },
        {
          Name: 'OnlyArchives',
          Search: {
            Value: this.state.archiveOnly,
          }
        },
      ],
    };

    const response = activityReportService
      .getAccountRegistrations(model)
      .then((response) => {
        this.setState({
          data: response.data.Data,
          totalRows: response.data.RecordsFiltered,
          start: response.data.Start,
          loading: false,
        });
      });
  };
  rowPerPageHandler = async (rowPerPage) => {
    this.setState({ rowPerPage: rowPerPage }, () => {
      this.filterHandler();
    });
  };

  pageChangeHandler = async (page) => {
    this.setState({ currentPage: page }, () => {
      this.filterHandler();
    });
  };
  ArchivedClicked(e){
    this.setState({
      archiveOnly:e.target.checked
    },this.filterHandler);
  }
  TestAccountCheckedClicked(e){
    this.setState({
      testOnly:e.target.checked
    },this.filterHandler);
  }
  render() {
    const { loading, data, totalRows } = this.state;

    return (
      <div>
        <ActivityReportHeader
          title='Account Registrations Report'
          fromDateValue={this.state.fromDate}
          setFromDate={(date) => {
            this.setState({ fromDate: date });
          }}
          toDateValue={this.state.toDate}
          setToDate={(date) => {
            this.setState({ toDate: date });
          }}
          click={(e) => {
            this.filterHandler();
          }}
          onSearch={(e) => {
            this.filterHandler(e.target.value);
          }}
          statusFilterOptions={statusFilterOptions}
          statusFilterSelectedOption={this.state.statusFilterSelectedOption}
          onStatusChange={this.statusChangeHandler}
          onArchivedChange={(e)=>this.ArchivedClicked(e)}
          isArchived={this.isArchived}
          onTestAccountChange={(e)=>{this.TestAccountCheckedClicked(e)}}
          IsTestAccount={this.IsTestAccount}
        >
          {/* Data table Starts */}

          <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            //Pagination Server Side
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={this.rowPerPageHandler}
            onChangePage={this.pageChangeHandler}
            //styling
            sortIcon={<SortIcon />}
            striped
            highlightOnHover
            customStyles={customStyles}            
          />
          {/* Data table Ends */}
        </ActivityReportHeader>
      </div>
    );
  }
}

export default connect()(withRouter(AccountRegistrations));
