import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
const getHeatmapCellMd = (level, value, isDecile) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {value >= 1 ? (
        <>
          {value.toFixed(2)}{' '}
          <span style={{ fontSize: 8 }}>
            ({level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
            )
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
const HighestLowestScoringQuestionsPpt = (props) => {
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          {/* {props.SchoolName} {'MAT Presentation'}
                    <AppLogo type="overview" color={colors.lightPink} width="100" /> 
                      */}
          <span className='app-logo'><img src={props.welbeeslideslogo} alt=""/></span>
          <h1 className='matpres-title'>{props.title}</h1>
          <p className='table-title'>
            Table 2: Highest and Lowest Question Scores
          </p>

          <div className='question-table'>
            <Scrollbars
              className='scrollbar-view'
              // autoHide
              // autoHideTimeout={1000}
              // autoHideDuration={200}
            >
              <div className='job-role-container'>
                <div className='heatmap-title'>
                  The Five Highest Scoring Questions
                </div>
                <div className='heatmap-title' style={{ width: '17.4%' }}>
                  School Decile
                </div>
              </div>
              {props.highScoreQuestions?.map((item) => {
                return (
                  <div className='job-role-container'>
                    <div className='width-20-grey'>{item.Category}</div>
                    <div className='width-60-grey'>
                      Q{item.Order}: {item.Text}
                    </div>
                    <div className='width-18-grey'>
                      {item.Decile}
                      <sup>
                        {item.Decile === 1
                          ? 'st'
                          : item.Decile == 2
                          ? 'nd'
                          : item.Decile == 3
                          ? 'rd'
                          : 'th'}
                      </sup>
                    </div>
                  </div>
                );
              })}
              {props.lowScoreQuestions?.length > 0 ? (
                <>
                  {' '}
                  <div className='job-role-container'>
                    <div className='heatmap-title'>
                      The Five Lowest Scoring Questions
                    </div>
                    <div className='heatmap-title' style={{ width: '17.4%' }}>
                      School Decile
                    </div>
                  </div>
                  {props.lowScoreQuestions?.map((item) => {
                    return (
                      <div className='job-role-container'>
                        <div className='width-20-grey'>{item.Category}</div>
                        <div className='width-60-grey'>
                          Q{item.Order}: {item.Text}
                        </div>
                        <div className='width-18-grey'>
                          {item.Decile}
                          <sup>
                            {item.Decile === 1
                              ? 'st'
                              : item.Decile == 2
                              ? 'nd'
                              : item.Decile == 3
                              ? 'rd'
                              : 'th'}
                          </sup>
                        </div>
                      </div>
                    );
                  })}{' '}
                </>
              ) : (
                <p className='table-title width-60-grey'>
                  There are no question scoring low.
                </p>
              )}
            </Scrollbars>
          </div>
       
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default HighestLowestScoringQuestionsPpt;
