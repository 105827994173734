import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { reducer } from '../store';

const rootStore = createStore(
  reducer,
  {},
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

export default rootStore;
