import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle,
  RiskFactorsStyle
} from '../../resources/styling/otherSuggestionStyle';
import { useTranslation } from 'react-i18next';

const RoleSuggestion = () => {
  const {t} =useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle className="role-wrapper">
          <h2>Further actions to address risks to your management standards</h2>
          <h3>5. Role</h3>
          <ManagementStandardStyle>
            <li>
              An effective vision shares with staff how their work fits with the
              overall aim of the Trust and school. Your teaching staff across
              the Trust, or within some schools may lack clarity on this. It
              could be due to:
              <div class="secondaryList">
                <RiskFactorsStyle>
                  <li>
                    They were not involved in creating their school vision or
                    for the Trust.
                  </li>
                  <li>
                    The school vision has not been effectively communicated to
                    them or they do not understand it.
                  </li>
                  <li>
                    They have differing views as to what the overall aim should
                    be.
                  </li>
                  <li>
                    They are disaffected or distracted in some way, for example,
                    by high workload or their relationship with their {t('linemanager')}.
                    
                  </li>
                </RiskFactorsStyle>
              </div>
              <p>
                There could be other reasons and through discussion with staff
                you can establish what the cause might be. You might want to
                take the opportunity to review the vision and aims with staff
                and ask them what it means for them.
              </p>
              <p>
                As part of this you can answer questions and clarify how it fits
                with the work of your teaching staff. You may also receive
                useful feedback which you can add to or adopt into the vision.
              </p>
            </li>

            <li>
              Setting effective performance management objectives is an
              important step for staff in understanding:
              <div class="secondaryList">
                <RiskFactorsStyle>
                  <li>What is expected of them at work.</li>
                  <li>In knowing how to get their job done.</li>
                  <li>
                    In being clear about their duties and responsibilities.
                  </li>
                  <li>
                    In understanding the goals and objectives for their
                    department.
                  </li>
                </RiskFactorsStyle>
              </div>
              <p>
                In addition developing and delivering an effective communication
                plan, 1 to 1 conversations and focused meetings on this topic
                are all actions that will lead to improvements. These should
                also cover job descriptions and how these fit with individual,
                departmental and school objectives.
              </p>
            </li>

            <li>
              Staff lacking clarity on expectations can often be down to one of:
              <div class="secondaryList">
                <RiskFactorsStyle>
                  <li>
                    There has been some significant change (for example in
                    leadership, in working practices or the way things are being
                    done).
                  </li>
                  <li>
                    There is uncertainty or expected change, for example
                    redundancies.
                  </li>
                  <li>
                    Different or contradictory messages are being given, for
                    example through different {t('linemanagers')}, from the Trust and
                    individual school leadership or from different school
                    departments or disciplines.
                  </li>
                  <li>
                    Staff are waiting for something, for fearful, for example an
                    Ofsted inspection is expected, and they are unsure of what
                    action to take.
                  </li>
                </RiskFactorsStyle>
              </div>
              <p>
                It may be none of these and through further questions you can
                ascertain what staff need for greater clarity of what is
                expected from them and provide this.
              </p>
            </li>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default RoleSuggestion;
