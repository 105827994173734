export const ViewIndividualAnswers = 'View by question and all staff filters';
export const ViewFurtherSuggestions = 'View Further Suggestions';
export const CompareToStaffInOtherSchools =
  'Compared to staff in other schools:';
export const CompareToStaffInOtherOrganisations =
  'Compared to staff in all other organisations:';
export const ComparativeStrengths = 'Comparative Strengths:';
export const AreasForReviewAndPossibleFocus =
  'Areas for Review and Possible Focus:';
export const AreasForReviewEmptyMessage =
  'Compared to other schools and all organisations there are no areas identified for review and the management standard for this area is effective. Your time will be better spent in other areas.';
export const NoWeaknessesTextSchool =
  'There are no areas for improvement compared to the school benchmark.';
export const NoWeaknessesTextHse =
  'There are no areas for improvement compared to the HSE benchmark.';
export const NoStrengthsTextSchool =
  'There are no strengths compared to the school benchmark.';
export const NoStrengthsTextHse =
  'There are no strengths compared to the HSE benchmark.';
