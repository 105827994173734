import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { routesObj } from '../../routes';
import { HeadTeacherAccess, WebStorageNames } from '../../utils';
import { storageService } from '../../services';
import Tooltip from '@mui/material/Tooltip';
import { iconRequest } from '../../resources/images/index';

const RequestAssistanceButton = props => {
  const[accessLevel] = useState(storageService.getItem(WebStorageNames.AccessLevel));
  const redirectToRoute = () => {
    props.history.push(routesObj.RequestAssistance.path);
  };

  return(
    accessLevel === HeadTeacherAccess.Full ? <Tooltip title="Request Assistance" followCursor><button onClick={redirectToRoute}><img src={iconRequest} alt='' /></button></Tooltip>: ''
  );
};

export default withRouter(RequestAssistanceButton);
