import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { loaderAction } from '../../store/loaderStore';
import { routesObj } from '../../routes';
import AutoComplete from './AutoComplete';
import {
  AppInputField,
  Header,
  FloatinLabelDropDown,
  FloatingInputLabel,
} from '../../components';
import {
  ErrorWrapper,
  RegisterInnerMain,
  RegisterWrapper,
  SelectStyles,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { accountService, storageService } from '../../services';
import {
  PhaseOfSchool,
  SectorItems,
  WebStorageNames,
  USPhaseOfSchool,
} from '../../utils';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  RegisterInner,
  ExtendedAssitanceForm,
  InputWrapper,
} from './SchoolRegisterStyle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { withTranslation } from 'react-i18next';

const { mandy } = colors;

class SchoolDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phaseOfSchool: PhaseOfSchool,
      selectedPhaseOfSchool: '',
      sector: SectorItems,
      selectedSector: '',
      emailErrorMessage: '',
      duplicateSchoolErrorMessage: '',
      addressSuggestionList: [],
      name: '',
      address: '',
      numberOfPupil: 0,
      fileName: '',
      isMatShow: false,
      region: storageService.getItem(WebStorageNames.UsRegion),
      isIndividualSchool: true,
    };
    this.splitName = this.splitName.bind(this);
  }

  componentDidMount() {
    const { SchoolInfo } = this.props;
    if (SchoolInfo) {
      // let getPhaseofSchool = PhaseOfSchool.find(
      //     (o) => o.value === SchoolInfo.PhaseOfSchool
      // );
      // let getSector = SectorItems.find((o) => o.value === SchoolInfo.Sector);
      // if (isEmpty(getPhaseofSchool)) getPhaseofSchool = '';
      // if (isEmpty(getSector)) getSector = '';
      this.setState({
        // selectedPhaseOfSchool: getPhaseofSchool,
        // selectedSector: getSector,
        selectedPhaseOfSchool: SchoolInfo.PhaseOfSchool,
        selectedSector: SchoolInfo.Sector,
        name: SchoolInfo.Name,
        address: SchoolInfo.Address,
        numberOfPupil: SchoolInfo.NumberOfPupil,
      });
    }
  }

  splitName = (fileName) => {
    var splitFileName = fileName.split('.');
    var imageName = splitFileName[0];
    var trancateString =
      imageName.length > 23
        ? imageName.substr(imageName, 23, 0) + '...'
        : fileName;
    return trancateString;
  };
  onChangeHandler = (event) => {
    console.log(event.target.files[0]);
  };
  handleDropDownChange = (option) => {
    this.setState({ selectedPhaseOfSchool: option });
  };

  duplicationErrorMeassageHandle = (message) => {
    if (message === 'Email is already in use, please choose another email.') {
      this.setState({
        emailErrorMessage: message,
        duplicateSchoolErrorMessage: '',
      });
    } else if (message === 'School name already exist.') {
      this.setState({
        duplicateSchoolErrorMessage: message,
        emailErrorMessage: '',
      });
    }
  };

  addressItemClick = (item, setFieldValue) => {
    setFieldValue('Address', item.target.innerText);
    this.setState({ addressSuggestionList: [] });
  };

  render() {
    let { t } = this.props;
    const {
      address,
      selectedPhaseOfSchool,
      selectedSector,
      numberOfPupil,
      welbeeType,
    } = this.state;
    const { schoolId, SchoolInfo } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: schoolId,
          Name: this.state.name,
          Address: address,
          PhaseOfSchool:
            selectedPhaseOfSchool === ''
              ? ''
              : selectedPhaseOfSchool
                ? selectedPhaseOfSchool
                : '',
          Sector:
            selectedSector === '' ? '' : selectedSector ? selectedSector : '',
          File: '',
          NumberOfStaff: 0,
          NumberOfPupil: numberOfPupil,
          HearAboutUs: '',
          FileName: SchoolInfo
            ? SchoolInfo.Logo != null
              ? SchoolInfo.Logo.FileName
              : ''
            : '',
          TrancateFileName: SchoolInfo
            ? SchoolInfo.Logo != null
              ? this.splitName(SchoolInfo.Logo.FileName)
              : ''
            : '',
          SchoolOrTrustName: this.state.name,
          //IsMat: false
          IsMat: !this.state.isIndividualSchool,
        }}
        onSubmit={(values, actions) => {
          const { loaderActions } = this.props;
          loaderActions.dataLoading(true);
          values.Name = values.SchoolOrTrustName;
          accountService
            .SchoolRegister(values)
            .then((response) => {
              if (response.success) {
                amplitudeLog('PageView', 'SchoolRegisterSuccessful');
                loaderActions.dataLoading(false);
                this.props.onCompletion(response.data, response.data.Id);
              } else {
                this.duplicationErrorMeassageHandle(response.message);
                loaderActions.dataLoading(false);
              }
            })
            .catch((error) => loaderActions.dataLoading(false));
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          SchoolOrTrustName: Yup.string()
            .trim()
            //.max(50, 'School/Trust name must be at most 50 characters.')
            .required(t('SchoolName') + ' is required.'),
          Address: Yup.string()
            .trim()
            .required(t('zip_code') + ' is required.'),
          PhaseOfSchool: Yup.string()
            .trim()
            .min(1, 'Phase of school is required.')
            .required('Phase of school is required.'),
          Sector: Yup.string()
            .trim()
            .min(1, 'Sector is required.')
            .required('Sector is required.'),
          NumberOfPupil: Yup.number()
            .max(10000, 'Max number is 10000.')
            .required('Number of pupils is required.')
            .integer(
              'Please enter a number greater than 0, no fractions allowed.'
            )
            .min(1, 'Please enter a number greater than 0'),
          IsWelbeeVoice: Yup.bool(),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
          handleBlur,
        }) => (
          <div>
            <Header />
            <RegisterWrapper>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  md={12}
                  xs={12}
                  className='mobile-wrapper'
                >
                  <RegisterInnerMain>
                    <RegisterInner>
                      <ExtendedAssitanceForm>
                        <h2>
                          Welcome to Welbee{' '}
                          {window.location.pathname ===
                            routesObj.VoiceFreeUrl.path
                            ? 'Voice Free'
                            : window.location.pathname ===
                              routesObj.VoiceProUrl.path
                              ? 'Voice Pro'
                              : window.location.pathname ===
                                routesObj.SurveyFreeUrl.path
                                ? 'Survey Free'
                                : window.location.pathname ===
                                  routesObj.SurveyProUrl.path
                                  ? 'Survey Pro'
                                  : 'Survey Premium'}
                          <br />
                          Nice to meet you!
                        </h2>
                        <p>
                          Now tell us a little about your school or{' '}
                          {this.state.region === 'us' ? 'District' : 'MAT'}
                        </p>

                        <InputWrapper>
                          <div className='form-heading'>
                            <span>School information</span>
                          </div>
                          <WrapperMain>
                            <FloatingInputLabel
                              label={t('SchoolName')}
                              placeholder={t('SchoolName')}
                              name='SchoolOrTrustName'
                              value={values.SchoolOrTrustName}
                              onChange={(e) => {
                                setFieldValue(
                                  'SchoolOrTrustName',
                                  e.target.value
                                );
                                this.setState({ name: e.target.value });
                              }}
                              autoComplete='new-password'
                              onBlur={handleBlur}
                            />
                            <ErrorWrapper>
                              {(touched.SchoolOrTrustName &&
                                errors.SchoolOrTrustName) ||
                                this.state.duplicateSchoolErrorMessage}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Number of pupils'
                              placeholder='Number of pupils'
                              name='NumberOfPupil'
                              value={
                                values.NumberOfPupil !== 0
                                  ? values.NumberOfPupil
                                  : ''
                              }
                              onChange={(e) => {
                                setFieldValue('NumberOfPupil', e.target.value);
                                this.setState({
                                  numberOfPupil: e.target.value,
                                });
                              }}
                              type='number'
                              autoComplete='new-password'
                              onBlur={handleBlur}
                              min='0'
                            />
                            <ErrorWrapper>
                              {touched.NumberOfPupil && errors.NumberOfPupil}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <AutoComplete
                              name='Address'
                              options={[]}
                              value={values.Address}
                              onValueChange={(input, inputValue) => {
                                setFieldValue('Address', inputValue);
                                input.preventDefault();
                                this.setState({
                                  address: inputValue,
                                });
                              }}
                            />
                            <ErrorWrapper>
                              {touched.Address && errors.Address}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <FormControl fullWidth>
                              <InputLabel id='phase-of-school-label'>
                                Phase of school
                              </InputLabel>
                              <SelectStyles
                                labelId='phase-of-school-label'
                                label='Phase of school'
                                id='phase-of-school'
                                value={this.state.selectedPhaseOfSchool}
                                onChange={(option) => {
                                  setFieldValue(
                                    'PhaseOfSchool',
                                    option.target.value
                                  );
                                  this.setState({
                                    selectedPhaseOfSchool: option.target.value,
                                  });
                                }}
                                input={
                                  <OutlinedInput label='Phase of school' />
                                }
                              >
                                {this.state.phaseOfSchool.map((item) => {
                                  return (
                                    <MenuItem value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </SelectStyles>
                            </FormControl>
                            <ErrorWrapper>
                              {touched.PhaseOfSchool && errors.PhaseOfSchool}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FormControl fullWidth>
                              <InputLabel id='phase-of-sector-label'>
                                Sector
                              </InputLabel>
                              <SelectStyles
                                labelId='phase-of-sector-label'
                                label='Phase of school'
                                id='sector-of-school'
                                value={this.state.selectedSector}
                                onChange={(option) => {
                                  setFieldValue('Sector', option.target.value);
                                  this.setState({
                                    selectedSector: option.target.value,
                                    isMatShow: option.value === 3,
                                  });
                                }}
                                input={<OutlinedInput label='Sector' />}
                              >
                                {this.state.sector.map((item) => {
                                  return (
                                    <MenuItem value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </SelectStyles>
                            </FormControl>
                            <ErrorWrapper>
                              {touched.Sector && errors.Sector}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <div className='box'>
                              <input
                                type='file'
                                name='file-7[]'
                                id='file-7'
                                accept='image/x-png,image/jpeg'
                                className='inputfile inputfile-6'
                                data-multiple-caption='{count} files selected'
                                onChange={(event) => {
                                  var files = event.target.files;
                                  var file = files[0];
                                  var trancateString = this.splitName(
                                    file.name
                                  );
                                  if (files && file) {
                                    var reader = new FileReader();
                                    reader.onload = function (readerEvt) {
                                      var binaryString =
                                        readerEvt.target.result;
                                      setFieldValue('File', btoa(binaryString));
                                      setFieldValue('FileName', file.name);
                                      setFieldValue(
                                        'TrancateFileName',
                                        trancateString
                                      );
                                    };
                                    reader.readAsBinaryString(file);
                                  }
                                }}
                              />
                              <label>
                                <span className='no-file-upload '>
                                  {values.TrancateFileName
                                    ? values.TrancateFileName
                                    : 'Upload a logo (Optional)'}
                                </span>
                                <strong>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='17'
                                    viewBox='0 0 20 17'
                                  >
                                    <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                                  </svg>{' '}
                                </strong>
                              </label>
                            </div>
                            <ErrorWrapper>
                              {touched.File && errors.File}
                            </ErrorWrapper>
                            {/* <span className='optional-label'>optional</span> */}
                          </WrapperMain>


                          <WrapperMain> {/*  WL-2340 */}
                            <input
                              class='styled-checkbox'
                              name='isIndividualSchool'
                              type='checkbox'
                              checked={this.state.isIndividualSchool}
                              onChange={(e) => {
                                this.setState({
                                  isIndividualSchool: e.target.checked,
                                });
                              }}
                            />
                              <label for='styled-checkbox-1'>
                              <span style={{ fontSize:'small', color:'black', paddingTop:10, textAlign:'left'}}>
                              I am registering an account for an individual school.
                              </span>
                              <p  style={{fontWeight:'bolder', fontSize:'small', paddingTop:10, textAlign:'left' , margin:0}}>
                                If you would like to register for Welbee for a MAT or group of schools, 
                                please email support@welbee.co.uk, and we’ll get your account set up.</p>
                            </label>
                          </WrapperMain>
                          {/*<WrapperMain>
                            <TextArea
                              placeholder='Where did you hear about us'
                              name='HearAboutUs'
                              value={values.HearAboutUs}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.HearAboutUs &&
                                errors.HearAboutUs &&
                                errors.HearAboutUs}
                            </ErrorWrapper>
                              </WrapperMain>*/}
                          {/* <WrapperMain>
                            <CheckBox>
                              <input
                                class='styled-checkbox'
                                name='IsWelbeeVoice'
                                type='checkbox'
                                value={values.IsWelbeeVoice}
                                onChange={handleChange}
                              />
                              <label for='styled-checkbox-1'>
                                <span className='checkbox-wrapper'>
                                  Please enable Welbee voice feature
                                </span>
                              </label>
                            </CheckBox>
                          </WrapperMain> */}
                          {/*this.state.isMatShow && welbeeType === WelbeeType.WelbeeSurveyWithVoice && <WrapperMain>
                            <CheckBox>
                              <input
                                class='styled-checkbox'
                                name='IsWelbeeVoice'
                                type='checkbox'
                                value={values.IsMat}
                                onChange={(e) => {
                                  setFieldValue("IsMat", e.target.checked);
                                }}
                              />
                              <label
                                for='styled-checkbox-1'
                                className='register-checkbox-wrapper'
                              >
                                <span className='checkbox-wrapper'>
                                  <strong>Would you like to register other schools as part of a group? </strong>
                                  If you have checked the box we will contact you to register them for you.
                                </span>
                              </label>
                            </CheckBox>
                              </WrapperMain>*/}

                          <WrapperMain>
                            <AppButton
                              color={mandy}
                              type='submit'
                              onClick={handleSubmit}
                            >
                              {window.location.pathname ===
                                routesObj.SurveyFreeUrl.path ||
                                window.location.pathname ===
                                routesObj.VoiceFreeUrl.path
                                ? 'Create Account'
                                : 'Continue'}
                            </AppButton>
                          </WrapperMain>
                        </InputWrapper>
                      </ExtendedAssitanceForm>
                    </RegisterInner>
                  </RegisterInnerMain>
                </Grid>
              </Grid>
            </RegisterWrapper>
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(SchoolDetailForm)));
