import React, { useState, useEffect } from 'react';
import { LoginFormWrapper, PageWrapper, AppButton } from '../../resources/styling/appStyle';
import { storageService, schoolService } from '../../services';
import { WebStorageNames, WelbeeTypes } from '../../utils';
import Grid from '@mui/material/Grid';
import { welbeeLoginLogo } from '../../resources/images/index';
import { colors } from '../../resources/theme/colors'
import { ExtendedLoginFormInner, ExtendedMainWrapper } from './SchoolSubscriptionsStyle';

const { mandy } = colors;

export default function UpgradeToFreePopup(props) {
    const [schoolRecord, setSchoolRecord] = useState({ Name: '' });
    useEffect(() => {
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        schoolService
            .getInfo(schoolId)
            .then((response) => {
                if (response.success) {
                    setSchoolRecord(response.data);
                }
            })
            .catch(() => { });
    }, {});

    return (
        <ExtendedMainWrapper>
            <PageWrapper>
                <div className='mobile-warning-wrapper'>
                    <span>&#9888;</span>
                    Please use desktop to view Welbee
                </div>
                <Grid container spacing={2} className='login-main-warpper'>
                    <Grid item
                        lg={6}
                        sm={12}
                        md={6}
                        xs={12}
                        className='login-mobile-wrapper login-left-panel'
                    >
                        <img src={welbeeLoginLogo} alt='' />
                    </Grid>
                    <Grid item
                        lg={6}
                        sm={12}
                        xs={12}
                        md={6}
                        className='logo-container welcome-right-panel registeration-right-panel'
                    >
                        <LoginFormWrapper>
                            <ExtendedLoginFormInner>
                                <h2>
                                    {schoolRecord.Name}
                                </h2>
                                <span>
                                    <p className='fee-detail'>
                                        There is no charge associated with upgrading from {storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeVoiceFree ? 'Voice Free' :
                                            storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeSurveyFree ? 'Survey Free' :
                                                storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                                                    storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium'} to {
                                            storageService.getItem(WebStorageNames.NewSubscriptionId) === WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                                                storageService.getItem(WebStorageNames.NewSubscriptionId) === WelbeeTypes.WelbeeSurveyFree ? 'Survey Free' :
                                                    storageService.getItem(WebStorageNames.NewSubscriptionId) === WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium'} {'. '}
                                    </p><p> If you are happy to proceed, please click the 'Confirm Upgrade' button below.
                                    Once you click the button your account will be upgraded.
                                    </p>
                                    <p className='fee-detail'>
                                        Thank you
                                    </p>
                                    <AppButton
                                        color={mandy} style={{width:'100%'}}
                                        type='submit'
                                        onClick={props.onCompletion}>
                                        Confirm Upgrade
                                    </AppButton>
                                </span>
                            </ExtendedLoginFormInner>
                        </LoginFormWrapper>
                    </Grid>
                </Grid>
            </PageWrapper>
        </ExtendedMainWrapper>
    );
}