import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { AppModel } from '../../components';
import { AppButton, FooterButtonsWrapper } from '../../resources/styling';
import { colors } from '../../resources/theme/colors';
import { schoolService, storageService } from '../../services';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens, WebStorageNames } from '../../utils/Constants';
import { InstructionWrapper } from './DashboardStyle';

const { purple, lightGray, outrageousOrange } = colors;

const useStyles = makeStyles({
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: '#007A7A',
      color: 'white',
    },
  },
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  inputRoot: {
    color: '#45338C',
    fontFamily: 'Avenir',
    fontWeight: '500',
    fontSize: '12px',
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#45338C',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#45338C',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#45338C',
    },
  },
});

const ReSendSurveyInvitation = (props) => {
  const [isValid, setIsValid] = useState(false);
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [schoolStaffs, setSchoolStaffs] = useState([]);
  let options = [];

  useEffect(() => {
    getSchoolStaff();
  }, []);

  const getSchoolStaff = () => {
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    schoolService.getSchoolStaff(schoolId, schoolToken).then((response) => {
      setSchoolStaffs(response.data);
    });
  };
  options.push({ title: 'All', id: 0 });

  var item = schoolStaffs?.length
    ? schoolStaffs?.map((item, i) => {
        item.map((data, i) => {
          options.push({
            key: i,
            title:
              data.SchoolStaffs?.FirstName + ' ' + data.SchoolStaffs?.LastName,
            id: data.SchoolStaffs.Id,
          });
        });
      })
    : null;

  const handleOnChange = (staff) => {
    const arrayLastElement = staff[staff.length - 1];
    if (staff.length) {
      if (
        staff.some((x) => x.title.toLowerCase() === 'all') &&
        staff.length > 1 &&
        arrayLastElement.title.toLowerCase() !== 'all'
      ) {
        let ids = [];
        staff.map((y) => {
          if (y.id !== 0) {
            ids.push(y.id);
          }
        });
        const newArray = options.filter((x) => {
          return !ids.includes(x.id) && x.id !== 0;
        });

        setIsValid(true);
        setSelectedStaff(newArray);
      } else if (
        staff.length > 1 &&
        arrayLastElement.title.toLowerCase() === 'all'
      ) {
        setIsValid(true);
        setSelectedStaff(
          staff.filter((x) => x.title === arrayLastElement.title)
        );
      } else {
        setIsValid(true);
        setSelectedStaff(staff);
      }
    } else {
      setIsValid(false);
      setSelectedStaff(staff);
    }
  };

  options = options.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );
  return (
    <AppModel
      open={props.isOpen}
      onClose={props.onClose}
      bodyText={
        <Container className='form-inner-wrapper'>
          <Grid container>
            <Grid
              item
              xs={12}
              col={12}
              lg={12}
              sm={12}
              md={12}
              mdx={12}
              className='form-column-wrapper'
            >
              <InstructionWrapper>
                <h2>Resend Survey Invitation</h2>
                <div className='resend-survey-invitation-instructions'>
                  Please select the required staff members. You are advised to
                  only use the 'All' option after both survey reminders have
                  been sent.
                  <p>
                    Note that reminders will only be sent to staff members who
                    have not yet taken the survey.
                  </p>
                </div>
              </InstructionWrapper>
            </Grid>
            <Grid
              item
              xs={12}
              col={12}
              lg={12}
              sm={12}
              md={12}
              mdx={12}
              className='form-column-wrapper'
            >
              <Autocomplete
                classes={classes}
                value={selectedStaff}
                multiple
                id='checkboxes-tags-demo'
                options={options}
                disableCloseOnSelect
                onChange={(event, option) => {
                  handleOnChange(option);
                }}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option, state) => {
                  const selectEmailIndex = selectedStaff.findIndex(
                    (x) => x.title.toLowerCase() === 'all'
                  );
                  if (selectEmailIndex > -1) {
                    state.selected = true;
                  } else if (selectedStaff.some((x) => x.id === option.id)) {
                    state.selected = true;
                  } else {
                    state.selected = false;
                  }

                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={isValid ? state.selected : false}
                      />
                      {option.title}
                    </li>
                  );
                }}
                style={{
                  width: '70%',
                  float: 'left',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search'
                    placeholder='Staff Members'
                  />
                )}
              />
              <FooterButtonsWrapper
                style={{
                  width: '30%',
                }}
              >
                <AppButton
                  color={
                    isValid && !props.isBusy ? outrageousOrange : lightGray
                  }
                  type='button'
                  onClick={() => {
                    props.reSendSurveyEmail(selectedStaff);
                  }}
                  disabled={!isValid && props.isBusy}
                  style={{
                    cursor: !isValid ? 'auto' : 'pointer',
                    marginTop: '5px',
                    float: 'left',
                    marginLeft: '10px',
                  }}
                >
                  Send Invitation
                </AppButton>
                {props.isBusy ? <CircularProgress /> : ''}
              </FooterButtonsWrapper>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};

export default ReSendSurveyInvitation;
