import React from 'react';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  NewSurveyWrapper,
  AssitanceForm,
  ErrorWrapper,
  NewSurveyInputWrapper,
} from '../../resources/styling';
import Grid from '@mui/material/Grid';
import FooterButtons from '../../components/common/FooterButtons';
import { FloatingInputLabel } from '../../components';
import FloatingLabelTextArea from '../../components/common/FloatingLabelTextArea';
import { withRouter } from 'react-router-dom';
import { LoaderOverlay } from '../../resources/styling/appStyle';
import { getLoggedUserId } from '../../utils/CommonFunctions';
import { ExtendedFieldWrapper } from './WelbeeVoiceStyle';

const NewSurveyVoiceForm = (props) => {
  const {
    onSubmit,
    isLoading,
    submitButtonText,
    isOpen,
    onClose,
    voiceRecord,
    closeButtonText = 'Cancel',
  } = props;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Title: voiceRecord !== null ? voiceRecord.Title : 'Your feedback',
        Description:
          voiceRecord !== null
            ? voiceRecord.Description
            : 'Please provide feedback on your wellbeing and if or where you feel you need more support. \n\nTell us when we do something well so we can do more of it, what we can do better, and share your ideas. Thank you.',
        SchoolId: 0,
        IsFirstEmailSent:
          voiceRecord !== null ? voiceRecord.IsFirstEmailSent : false,
        LoggedUserId: getLoggedUserId(),
        IsAllStaffInviteVoiceSurvey:
          voiceRecord !== null
            ? voiceRecord.IsAllStaffInviteSurveyVoice
            : false,
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values);
        actions.resetForm();
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Title: Yup.string().trim().max(150).required('Title is required.'),
        Description: Yup.string().trim().required('Voice message is required.'),
        IsFirstEmailSent: Yup.bool(),
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className='form-column-wrapper'
                    >
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            {isLoading && (
                              <LoaderOverlay>
                                <div className='comment-loader'>
                                  <div id='loading' />
                                </div>
                              </LoaderOverlay>
                            )}
                            <NewSurveyInputWrapper>
                              <ExtendedFieldWrapper className='new-survey-placeholder'>
                                <span>Subject: (Subject editable)</span>
                                <FloatingInputLabel
                                  placeholder='Voice Title'
                                  name='Subject'
                                  value={values.Title}
                                  onChange={(e) => {
                                    setFieldValue('Title', e.target.value);
                                  }}
                                />
                                <ErrorWrapper>
                                  {touched.Title &&
                                    errors.Title &&
                                    errors.Title}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                              <ExtendedFieldWrapper className='new-survey-placeholder'>
                                <span>Message: (Message editable)</span>
                                <FloatingLabelTextArea
                                  placeholder='Voice'
                                  name='Description'
                                  value={values.Description}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'Description',
                                      e.target.value
                                    );
                                  }}
                                />
                                <ErrorWrapper>
                                  {touched.Description &&
                                    errors.Description &&
                                    errors.Description}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                            </NewSurveyInputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                submitButtonText={submitButtonText}
                closeButtonText={closeButtonText}
                handleSubmit={() => {
                  handleSubmit();
                }}
                onClose={() => {
                  values.IsFirstEmailSent = true;
                  handleSubmit();
                }}
              />
            }
          />
        );
      }}
    />
  );
};

export default withRouter(NewSurveyVoiceForm);
