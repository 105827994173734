import React, { Component } from 'react';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../resources/styling';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import 'react-day-picker/lib/style.css';
import { AppModel } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { actionPlanService, additionalQuestionService } from '../../services';
import { actionPlanActions } from '../../store/actionPlanStore';
import {
  NewSurveyWrapper,
  NewSurveyInnerMain,
} from '../../resources/styling/appStyle';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';

import {
  AdditionalQuestionsFormWrapper,
  InputWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  FooterButtonsWrapper,
  RadioGroupWrapper,
} from './AdditionalQuestionStyle';

const { mandy } = colors;

class AdditionalQuestionLikertForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isListOpen: false,
      isSignListOpen: true,
      isEdit: props.isEdit,
      isEmpty: true,
      isLikert: props.isEdit ? props.editRecord.Type === 0 : true,
      type: props.editRecord.Type,
      signType: props.editRecord.SignType,
      likertFormat: props.isEdit ? props.editRecord.LikertId : 1,
      likertData: [],
      maxLikertId: 0,
    };
  }

  componentDidMount() {
    this.getLikerts();
  }

  getLikerts() {
    let likertData = [];
    additionalQuestionService
      .getLikertsBySchoolId(this.props.schoolId)
      .then((res) => {
        const maxLikertId = res.data.reduce((prev, current) =>
          prev.Id > current.Id ? prev : current
        );
        console.log('maxLikertId', maxLikertId);
        res.data.map((item) => {
          const {
            Id,
            LikertOption1,
            LikertOption2,
            LikertOption3,
            LikertOption4,
            LikertOption5,
          } = item;
          likertData.push({
            id: Id,
            opts: [
              LikertOption1,
              LikertOption2,
              LikertOption3,
              LikertOption4,
              LikertOption5,
            ],
            isUpdate: false,
            isDelete: false,
          });
        });
        console.log('likertData', likertData);
        this.setState({
          likertData: likertData,
          maxLikertId: maxLikertId.Id,
        });
      });
  }

  render() {
    const { isOpen, onClose, handleSubmit, editRecord, surveyId, schoolId } =
      this.props;
    console.log(editRecord, 'editRecord');
    const { likertFormat, likertData } = this.state;
    console.log(this.state.likertFormat, 'likertFormat');
    const classes = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Order: editRecord.Order,
          QuestionNumber: editRecord.QuestionNumber,
          QuestionText: editRecord.QuestionText,
          Type: editRecord.Type,
          Category: editRecord.Category,
          IsIncluded: editRecord.IsIncluded,
          IsArchive: false,
          SurveyId: surveyId,
          SchoolId: schoolId,
          SignType: editRecord.SignType,
          LikertFormat: editRecord.LikertId || 1,
          Likert: {
            Id: editRecord.LikertId || 1,
          },
        }}
        onSubmit={(values, actions) => {
          const { isEdit } = this.state;
          if (!isEdit) values.Id = 0;
          actions.setSubmitting(true);
          handleSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        render={({ handleSubmit, setFieldValue }) => (
          <div className='styles_modal__gNwvD'>
            <AppModel
              open={isOpen}
              onClose={onClose}
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <NewSurveyInnerMain>
                          <NewSurveyInner>
                            <AdditionalQuestionsFormWrapper>
                              <h2>Choose Likert Format</h2>
                              <div className='action-plan-instructions'>
                                Pick up the relevent likert format for the
                                question.
                              </div>
                              <InputWrapper>
                                <Grid
                                  item
                                  lg={12}
                                  sm={12}
                                  md={12}
                                  xs={12}
                                  className='form-column-wrapper radio-group'
                                >
                                  <RadioGroupWrapper>
                                    <FormControl component='fieldset'>
                                      <FormLabel component='legend'>
                                        Answer Format
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label='likertScale'
                                        name='likertScale'
                                        value={likertFormat}
                                        onChange={(event) => {
                                          var questionFormat = Number(
                                            event.target.value
                                          );
                                          setFieldValue(
                                            'Likert.Id',
                                            questionFormat
                                          );
                                          this.setState({
                                            likertFormat: questionFormat,
                                          });
                                        }}
                                      >
                                        {Object.entries(likertData).map(
                                          ([key, value]) => (
                                            <FormControlLabel
                                              value={value.id}
                                              control={<Radio />}
                                              label={value.opts.join(', ')}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </RadioGroupWrapper>
                                </Grid>
                              </InputWrapper>
                            </AdditionalQuestionsFormWrapper>
                          </NewSurveyInner>
                        </NewSurveyInnerMain>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={
                <FooterButtonsWrapper>
                  <AppButton color={mandy} type='button' onClick={handleSubmit}>
                    Save
                  </AppButton>
                </FooterButtonsWrapper>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionPlanService, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AdditionalQuestionLikertForm);
