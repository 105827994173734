import request from '../request';
import { ActivityReportAPI } from './urls';

export const getAccountRegistrations = (model) => {
  return request({
    url: ActivityReportAPI.getAccountRegistrations(),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

const activityReportService = {
  getAccountRegistrations,
};

export default activityReportService;
