import React from 'react';
import {
  WrapperStyle,
  DescriptionStyle,
  Title,
  SeparatorStyle,
} from '../../resources/styling/surveyReportStyle';
import ReportScores from './ReportScores';
import RoleWiseReportScores from './RoleWiseReportScores';
import SuggestedPriorityActionsView from './SuggestedPriorityActionsView';
import ReportResultsSection from './ReportResultsSection';
import {
  ViewIndividualAnswers,
  AreasForReviewAndPossibleFocus,
} from './Constants';
import {
  limitAverageValue,
  scoreNotAvailableValue,
} from '../../utils/roundScore';

const ControlReport = ({ report, onButtonClick, isPaid }) => {
  const {
    ResultsText,
    HseStrengths,
    HseWeaknesses,
    SchoolStrengths,
    SchoolWeaknesses,
    SuggestedPriorityActions,
    Score,
  } = report;
  return (
    <WrapperStyle>
      <h1 className='report-title'>2. Control</h1>
      <p id='controlTitle'>
        How much say a staff member has in the way they do their work.
      </p>
      <button onClick={onButtonClick}>{ViewIndividualAnswers}</button>
      <p id='demandsTitle' style={{ 'text-align': 'center' }}>
        Overall Scores and Deciles
      </p>
      <ReportScores
        Score={Score}
        id='controlScore'
        className='report-score'
        style={{ marginTop: '15px;' }}
        isPaid={isPaid}
      />
      {scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.MiddleLeaderCatAvg)) ===
        '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.SeniorLeaderCatAvg)) ===
        '-1.00' ? (
        ''
      ) : (
        <>
          <br />
          <p id='demandsTitle' style={{ 'text-align': 'center' }}>
            Scores by Job Role
          </p>
          <RoleWiseReportScores
            Score={Score}
            id='RoleWisecontrolScore'
            className='report-score'
          />
        </>
      )}
      <DescriptionStyle id='controlDescription'>
        <h1 className='report-list-wrapper'>Description</h1>
        <Title className='description-title'>
          The Management Standard is that:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            Staff should indicate that they are able to have a say about the way
            they do their work.
          </li>
          <li>
            Systems are in place locally to respond to any individual concerns.
          </li>
        </ul>
        <Title className='description-title'>
          This means that where possible:
        </Title>
        <ul className='report-list-wrapper'>
          <li>Staff have control over their pace of work.</li>
          <li>
            They are encouraged to use their skills and initiative to do their
            work.
          </li>
          <li>
            The school encourages and supports them to develop their skills to
            help them undertake new and challenging pieces of work.
          </li>
          <li>
            They have some say over what and how they work and what they do when
            taking a break (they are at least consulted and listened to).
          </li>
          <li>
            They are consulted over their work patterns and working hours.
          </li>
        </ul>
        <p className='control-text'>
          Given the nature of teaching and the school day some of these are very
          challenging to deliver.
        </p>
      </DescriptionStyle>

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <ReportResultsSection
            id='controlResults'
            ResultsText={ResultsText}
            SchoolStrengths={SchoolStrengths}
            SchoolWeaknesses={SchoolWeaknesses}
            HseStrengths={HseStrengths}
            HseWeaknesses={HseWeaknesses}
            AreasForReviewAndPossibleFocus={AreasForReviewAndPossibleFocus}
          />
        </div>
      ) : null}

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <SuggestedPriorityActionsView
            id='controlSuggestions'
            heading='Suggested Priority Actions for Control'
            SuggestedPriorityActions={SuggestedPriorityActions}
          />
        </div>
      ) : null}

      {isPaid ? <div id='support' /> : <div id='supportManager' />}
    </WrapperStyle>
  );
};

export default ControlReport;
