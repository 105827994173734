import { actionTypes } from './actionTypes';

const { DATA_LOADING } = actionTypes;

//////////// USER ACTIONS ///////////////

export const dataLoading = value => ({
  type: DATA_LOADING,
  payload: value
});

export const loaderAction = {
  dataLoading
};

//////////// USER REDUCER ///////////////
const INITIAL_STATE = {
  isDataLoading: false
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === DATA_LOADING) {
    return { ...state, isDataLoading: action.payload };
  } else {
    return state;
  }
};
