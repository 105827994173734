import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Highlight from 'react-highlight';
import { toast } from 'react-toastify';
import {
  DateFormatWithMonth,
  totalNoOfPage,
} from '../../utils/CommonFunctions';
import { routesObj } from '../../routes';
import {
  LeftMenu,
  Dropdown,
  MainHeader,
  MultiLevelDropdown,
} from '../../components';
import {
  StaffTypes,
  SurveyTypes,
  HeadTeacherAccess,
  SortOrder,
} from '../../utils/Constants';
import {
  welbeeVoiceService,
  storageService,
  schoolService,
} from '../../services';
import NewWelbeeVoiceForm from '../welbeeVoice/NewWelbeeVoiceForm';
import VoiceOptions from '../../components/common/VoiceOptions';
import { navigationActions } from '../../store/headerStore';
import { loaderAction } from '../../store/loaderStore';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import {
  DownloadButtonStyle,
  AssistanceWrapperHeading,
} from '../../resources/styling/appStyle';
import {
  searchIcon,
  unfillarrownext,
  unfillarrowprev,
  newMessage,
  iconDownload,
  adminReplied,
  staffReplied,
} from '../../resources/images/index';
import {
  WebStorageNames,
  RestrictedActions,
  IsActionAllowed,
} from '../../utils';
import {
  ExtendedVoiceDashboardWrapper,
  ExtendedVoiceNextPreviousButtons,
  ExtendedAssistanceWrapper,
  ExtendedSearchFilterWrapper,
} from './DashboardStyle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

class VoiceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Loading...',
      searchValue: '',
      selectedOption: { value: StaffTypes.All, label: 'All staff' },
      sortingSelectedOption: { value: SortOrder.Newest, label: 'Latest First' },
      latestVoiceOption: { value: '', label: '' },
      conversationOptions: [{ value: SurveyTypes.any, label: '' }],
      converstion: [],
      openConverstionPopUp: false,
      isFlag: false,
      totalVoiceRecord: 0,
      totalNumberOfPage: 0,
      currentpage: 1,
      notReplied: 0,
      voiceRecord: null,
      noOfStaff: 0,
      isConfirmInvitation: false,
      accessLevel: 0,
      IsReplyAllowed: false,
      IsFlagAllowed: false,
      anchorEl: false,
    };
  }

  componentDidMount() {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
    this.getSurveyVoiceByToken();
    IsActionAllowed(RestrictedActions.ReplyComment)
      .then((response) => this.setState({ IsReplyAllowed: response.data }))
      .catch();
    IsActionAllowed(RestrictedActions.FlagComment)
      .then((response) => this.setState({ IsFlagAllowed: response.data }))
      .catch();
  }

  getSurveyVoiceByToken = (page = null) => {
    const tokenValue = window.location.search.split('token=')[1];
    const { currentpage } = this.state;
    const pageNo = page != null ? page : currentpage;
    welbeeVoiceService
      .getSurveyVoiceByToken(tokenValue, pageNo)
      .then((response) => {
        if (response.success) {
          const noOfPage = totalNoOfPage(response.data.TotalVoiceConverstion);
          let reponseTitle = response.data.Title;
          if (reponseTitle.length > 30) {
            reponseTitle =
              reponseTitle.substring(0, 15) +
              '...' +
              reponseTitle.substring(reponseTitle.length - 12);
          }
          this.setState({
            converstion: response.data,
            totalVoiceRecord: response.data.TotalVoiceConverstion,
            totalNumberOfPage: noOfPage,
            latestVoiceOption: {
              value: tokenValue,
              label: reponseTitle,
            },
            notReplied: response.data.StaffNotReplied,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  displayRemainingVoice() {
    const { currentpage, totalNumberOfPage, totalVoiceRecord } = this.state;
    if (totalVoiceRecord <= 10) return totalVoiceRecord;
    else if (currentpage < totalNumberOfPage) {
      return `${currentpage * 10 - 9} - ${currentpage * 10}`;
    } else if (currentpage === totalNumberOfPage) {
      return `${currentpage * 10 - 9} - ${totalVoiceRecord}`;
    }
  }

  handleVoiceValueChange = (latestVoiceOption) => {
    this.setState({ latestVoiceOption });
    window.location.search = '?token=' + latestVoiceOption.value;
    this.getDropDownValue(latestVoiceOption.value);
  };

  importantFlag = (voiceDetail, flag) => {
    const { accessLevel } = this.state;
    if (accessLevel === HeadTeacherAccess.Full) {
      voiceDetail.importantFlag = flag;
      welbeeVoiceService
        .setVoiceConversationAsImportant(voiceDetail)
        .then((response) => {
          const { converstion } = this.state;
          let converstionCopy = JSON.parse(
            JSON.stringify(this.state.converstion)
          );
          const index = converstion.VoiceConversations.findIndex(
            (con) => con.Id === voiceDetail.Id
          );
          converstionCopy.VoiceConversations[index].ImportantFlag = flag;
          this.setState({ converstion: converstionCopy });
        })
        .catch((error) =>
          console.log('getting call failed with error: ', error)
        );
    }
  };

  headTeacherVoiceReply = (voiceDetail) => {
    const { accessLevel } = this.state;
    if (accessLevel === HeadTeacherAccess.Full) {
      welbeeVoiceService
        .setVoiceConversationIsRead(voiceDetail.Id)
        .then((response) => {
          if (!voiceDetail.IsRead) {
            const remainingNewComments =
              this.props.newConversationCount - 1 > 0
                ? this.props.newConversationCount - 1
                : 0;

            this.props.navigationActions.showNewConversationCount({
              item: remainingNewComments,
            });
          }
        })
        .catch((error) =>
          console.log('getting call failed with error: ', error)
        );
    }
    this.props.history.push({
      pathname: routesObj.HeadteacherVoiceConversation.path,
      search: '?id=' + voiceDetail.Id,
      state: {
        data: voiceDetail,
        voiceDescription: this.state.converstion.Description,
      },
    });
  };
  closePopUp() {
    this.setState({ openConverstionPopUp: false });
  }

  redirectToStaffManagment() {
    this.props.history.push(routesObj.StaffManagement.path);
  }

  filterBySearchText = (searchText, conversations) => {
    if (searchText) {
      conversations = conversations.filter((conversation) => {
        return (
          conversation.VoiceComments[0].ReplyText.toLowerCase().indexOf(
            searchText.toLowerCase()
          ) >= 0
        );
      });
    }

    return conversations;
  };

  filterByImportant = (comments) => {
    return comments.filter((item) => item.ImportantFlag === true);
  };

  getFilteredConversation = () => {
    const { searchValue, sortingSelectedOption, converstion, isFlag } =
      this.state;
    let filterVoiceComments = JSON.parse(JSON.stringify(converstion));

    if (filterVoiceComments.VoiceConversations !== undefined) {
      if (filterVoiceComments.VoiceConversations.length > 0) {
        if (sortingSelectedOption) {
          if (
            sortingSelectedOption.value == SortOrder.Newest ||
            sortingSelectedOption.value == SortOrder.Oldest
          ) {
            this.filterSortByOrder(
              sortingSelectedOption,
              filterVoiceComments.VoiceConversations
            );
          } else
            filterVoiceComments.VoiceConversations = this.filterByCommentReply(
              sortingSelectedOption,
              filterVoiceComments.VoiceConversations
            );
        }
        if (isFlag) {
          filterVoiceComments = this.filterByImportant(
            filterVoiceComments.VoiceConversations
          );
          if (searchValue) {
            filterVoiceComments = this.filterBySearchText(
              searchValue,
              filterVoiceComments
            );
          }
        } else {
          if (searchValue) {
            filterVoiceComments = this.filterBySearchText(
              searchValue,
              filterVoiceComments.VoiceConversations
            );
          }
        }
      }
    }
    return filterVoiceComments;
  };

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  prevPage = () => {
    const { currentpage } = this.state;
    if (currentpage > 1) {
      const page = currentpage - 1;
      this.setState({ currentpage: page });
      this.getSurveyVoiceByToken(page);
    }
  };

  nextPage = () => {
    const { currentpage, totalNumberOfPage } = this.state;
    if (currentpage < totalNumberOfPage) {
      const page = currentpage + 1;
      this.setState({ currentpage: page });
      this.getSurveyVoiceByToken(page);
    }
  };
  getConverstion = (searchValue, VoiceDetail) => {
    const { accessLevel } = this.state;
    const isFlag = VoiceDetail.ImportantFlag;
    const maxData = VoiceDetail.VoiceComments.reduce((a, b) => {
      return new Date(a.AddedDate) > new Date(b.AddedDate) ? a : b;
    });
    return (
      <>
        <div
          className={`searched-data-wrapper ${
            isFlag ? 'searched-data-wrapper-highlight' : ' '
          }`}
        >
          {!VoiceDetail.IsRead &&
            !VoiceDetail.AdminReplied &&
            !VoiceDetail.StaffReplied && (
              <span className='new-message-label'>
                <img src={newMessage} alt='' />
              </span>
            )}
          {!VoiceDetail.IsRead &&
            VoiceDetail.AdminReplied &&
            !VoiceDetail.StaffReplied && (
              <span
                className='new-message-label'
                style={{ backgroundColor: '#45338C' }}
              >
                <img src={adminReplied} alt='' />
              </span>
            )}
          {!VoiceDetail.IsRead &&
            !VoiceDetail.AdminReplied &&
            VoiceDetail.StaffReplied && (
              <span
                className='new-message-label'
                style={{ backgroundColor: '#007A7A' }}
              >
                <img src={staffReplied} alt='' />
              </span>
            )}

          <div className='search-date'>
            {DateFormatWithMonth(maxData.AddedDate)}
          </div>
          <div className='search-heighlight'>
            <Highlight
              style={{
                cursor:
                  accessLevel === HeadTeacherAccess.Full ? 'pointer' : 'normal',
              }}
              search={searchValue}
              onClick={() =>
                accessLevel === HeadTeacherAccess.Full &&
                this.headTeacherVoiceReply(VoiceDetail)
              }
            >
              {VoiceDetail.VoiceComments[0].ReplyText}
            </Highlight>
          </div>

          <span className='data-icons'>
            {!this.state.IsFlagAllowed ? (
              <button className={`flag-icon disabled-flag-btn tooltip`}>
                <span className='tooltiptext'>
                  You can flag comments by upgrading your account.
                </span>
              </button>
            ) : VoiceDetail.ImportantFlag ? (
              <button
                className='active-flag'
                onClick={() => this.importantFlag(VoiceDetail, false)}
              ></button>
            ) : (
              <button
                className='flag-icon'
                onClick={() => this.importantFlag(VoiceDetail, true)}
              ></button>
            )}

            {!this.state.IsReplyAllowed ? (
              <button
                className={`unread-reply unread-reply-active disabled-replay-btn tooltip`}
              >
                <span className='tooltiptext'>
                  You can reply to comments by upgrading your account.
                </span>
              </button>
            ) : !VoiceDetail.IsRead ? (
              <button
                className='unread-reply unread-reply-active'
                onClick={() => this.headTeacherVoiceReply(VoiceDetail)}
              ></button>
            ) : (
              <button
                className='reply-icon'
                onClick={() => this.headTeacherVoiceReply(VoiceDetail)}
              ></button>
            )}
          </span>
        </div>
      </>
    );
  };
  handleSearchChange = () => {
    let searchInputField = document.getElementById('searchInputField');
    const searchText = searchInputField.value;
    this.setState({ searchValue: searchText });
  };
  filterSortByOrder = (sortingSelectedOption, voiceComments) => {
    voiceComments.sort((a, b) => {
      const firstDate = new Date(a.AddedDate);
      const secondDate = new Date(b.AddedDate);
      if (sortingSelectedOption.value === SortOrder.Newest) {
        return secondDate - firstDate; //newest sort
      }
      return firstDate - secondDate; //oldest sort
    });
  };

  filterByCommentReply = (sortingSelectedOption, comments) => {
    const { value, label } = sortingSelectedOption;
    let commentsAdmin = comments.filter((x) => x.AdminReplied);
    let commentsStaff = comments.filter((x) => x.StaffReplied);
    let commentsNew = comments.filter(
      (x) => !x.IsRead && !x.AdminReplied && !x.StaffReplied
    );
    let otherComments = comments.filter(
      (x) => x.IsRead && !x.AdminReplied && !x.StaffReplied
    );
    comments = commentsAdmin
      .concat(commentsStaff)
      .concat(commentsNew)
      .concat(otherComments);
    comments = comments.filter((item) => {
      if (sortingSelectedOption.value === SortOrder.NewMsg) {
        return !item.IsRead && !item.StaffReplied && !item.AdminReplied;
      }
      if (sortingSelectedOption.value === SortOrder.AllHeadTeachers) {
        return item.AdminReplied;
      }
      if (sortingSelectedOption.value === SortOrder.AllStaffReplies) {
        return item.StaffReplied || item.AdminReplied;
      }
      if (sortingSelectedOption.value === SortOrder.MyMessages) {
        let userId = storageService.getItem(WebStorageNames.HeadTeacherId);
        let headTeacher = item.VoiceComments.find(
          (x) => x.HeadTeacher
        )?.HeadTeacher;
        return (
          headTeacher != null &&
          (label === 'My Replies' ? item.AdminReplied : item.StaffReplied) &&
          headTeacher.Id === userId
        );
      }
      if (sortingSelectedOption.value === SortOrder.NotReplied) {
        return !item.StaffReplied && !item.AdminReplied;
      }
    });
    return comments;
  };
  onAllStaffClick(values) {
    const { noOfStaff } = this.state;
    if (!values.IsFirstEmailSent) {
      if (noOfStaff >= 8)
        this.setState({ isConfirmInvitation: true, voiceRecord: values });
      else {
        this.setState({ voiceRecord: values });
        toast.error(
          <div>
            You must select a minimum of 8 staff members to be able to send an
            invitation for a{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              new
            </span>{' '}
            conversation.
          </div>
        );
      }
    } else {
      values.Id = 0;
      storageService.setItem(WebStorageNames.TempVoice, values);
      this.props.history.push(
        routesObj.WelbeeVoiceNotification.path + '?voiceId=0'
      );
    }
  }

  onNewConversationClick = () => {
    const { loaderActions } = this.props;
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    loaderActions.dataLoading(true);
    this.setState({ openConverstionPopUp: true });
    schoolService
      .getSchoolStaff(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          this.setState({ noOfStaff: data.length });
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  onCreateConverstionClick() {
    const { loaderActions } = this.props;
    const { voiceRecord } = this.state;
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    voiceRecord.SchoolId = schoolId;
    storageService.setItem(WebStorageNames.NewConverstionVoicePopUpShow, false);
    loaderActions.dataLoading(true);
    this.setState({ isConfirmInvitation: false });
    welbeeVoiceService
      .createConverstion(voiceRecord)
      .then((response) => {
        if (response.success) {
          if (voiceRecord.IsFirstEmailSent) {
            this.props.history.push(
              routesObj.WelbeeVoiceNotification.path +
                '?voiceId=' +
                response.data.Id
            );
            this.setState({
              openConverstionPopUp: false,
              converstionList: [],
              voiceRecord: null,
            });
          } else {
            toast.success('Invitations sent successfully.');
            this.setState({
              openConverstionPopUp: false,
              isConfirmInvitation: false,
              voiceRecord: null,
            });
          }
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }

  downloadComments = () => {
    this.setState({ showDownloadOption: false });
    const tokenValue = window.location.search.split('token=')[1];
    welbeeVoiceService
      .GetPDFComments(tokenValue)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        let schoolName = document.getElementsByClassName(
          'dashboard-school-name'
        )[0].children[0].innerText;
        link.setAttribute('download', schoolName + '.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };
  downloadCommentsInExcel = () => {
    const tokenValue = window.location.search.split('token=')[1];
    welbeeVoiceService.GetExcelComments(tokenValue).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      let schoolName = document.getElementsByClassName(
        'dashboard-school-name'
      )[0].children[0].innerText;
      link.setAttribute('download', schoolName + '.xlsx');
      document.body.appendChild(link);
      link.click();
    });
    //    .catch((error) => { });
  };
  render() {
    const {
      searchValue,
      isSearchDelete,
      sortingSelectedOption,
      converstion,
      openConverstionPopUp,
      totalVoiceRecord,
      latestVoiceOption,
      notReplied,
      isFlag,
      isConfirmInvitation,
      noOfStaff,
    } = this.state;
    let filterVoiceComments =
      converstion.VoiceConversations !== null &&
      converstion.VoiceConversations !== undefined
        ? this.getFilteredConversation()
        : [];
    const { currentpage, totalNumberOfPage } = this.state;
    return (
      <ExtendedVoiceDashboardWrapper>
        <div className='left-container'>
          <LeftMenu />
        </div>
        <div className='right-container'>
          <MainHeader />
          <ExtendedAssistanceWrapper>
            <AssistanceWrapperHeading>
              <span className='comment-heading'>Welbee voice</span>
              <span> Comments </span>
            </AssistanceWrapperHeading>
            <div className='btn-download'>
              <DownloadButtonStyle
                onClick={(event) => {
                  this.setState({
                    showDownloadOption: true,
                    anchorEl: event.currentTarget,
                  });
                }}
              >
                <img src={iconDownload} alt='' /> Download
              </DownloadButtonStyle>
              <Menu
                anchorEl={this.state.anchorEl}
                open={this.state.showDownloadOption}
                onClose={() => {
                  this.setState({ showDownloadOption: false });
                }}
              >
                <MenuItem onClick={this.downloadComments}>Pdf</MenuItem>
                <MenuItem onClick={this.downloadCommentsInExcel}>
                  Excel
                </MenuItem>
              </Menu>
            </div>
            <VoiceOptions
              showConversationDropDown={true}
              addVoiceButtonClick={() => this.onNewConversationClick()}
              onChange={(v) => {
                window.location.search = '?token=' + v.value;
                let itemLabel = v.label;
                if (itemLabel.length > 30) {
                  itemLabel =
                    itemLabel.substring(0, 15) +
                    '...' +
                    itemLabel.substring(itemLabel.length - 12);
                }
                this.setState({
                  latestVoiceOption: { value: v.value, label: itemLabel },
                });
              }}
              latestSurveyOption={latestVoiceOption}
            />
          </ExtendedAssistanceWrapper>
          <ExtendedSearchFilterWrapper>
            <div className='subcontainer-inner'>
              <div className='search-filed'>
                <img src={searchIcon} alt='search button' />
                <input
                  id='searchInputField'
                  placeholder='Enter a keyword '
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleSearchChange();
                  }}
                  icon='search'
                  className='searchField'
                  onFocus={this.handleInputFocus}
                  onBlur={this.handleInputBlur}
                  value={searchValue}
                />
                {isSearchDelete ? (
                  <div className='cross' onClick={this.handleInputValue}>
                    X
                  </div>
                ) : (
                  <div />
                )}
              </div>

              <div
                className={`search-filters ${isFlag ? 'active' : ''}`}
                onClick={() => {
                  this.setState({ isFlag: !this.state.isFlag });
                }}
              ></div>
              <div className='search-sorting'>
                <MultiLevelDropdown
                  name='SortFilter'
                  selectedOption={sortingSelectedOption}
                  onChange={this.handleSortingValueChange}
                  value={sortingSelectedOption}
                />
              </div>
            </div>
          </ExtendedSearchFilterWrapper>

          <ExtendedAssistanceWrapper>
            <Link to='/welbee-voice' className='back-btn'>
              Go back to conversations
            </Link>
          </ExtendedAssistanceWrapper>

          <div className='content-body-inner-wrapper'>
            <div className='voice-subject'>
              <div className='voice-conv-desc'>{`Subject: ${converstion.Title}`}</div>
              <div
                className='voice-conv-desc'
                style={{
                  paddingBottom: '0',
                  lineHeight: '22px',
                  fontWeight: '500',
                }}
              >
                {converstion.Description}
              </div>
            </div>

            {filterVoiceComments.VoiceConversations !== null &&
            filterVoiceComments.VoiceConversations !== undefined
              ? filterVoiceComments.VoiceConversations.map((item) =>
                  this.getConverstion(searchValue, item)
                )
              : filterVoiceComments.map((item) =>
                  this.getConverstion(searchValue, item)
                )}
          </div>
          <div className='voice-staff-reply'>
            <span>
              <span>{notReplied} </span>
            </span>
            staff {notReplied > 1 ? 'members are' : 'member is'} yet to reply
          </div>
          <div className='content-body-pagination '>
            <span className='prev' onClick={() => this.prevPage()}>
              <img
                src={unfillarrowprev}
                title='Previous Page'
                alt='previous button'
              />
            </span>
            <ExtendedVoiceNextPreviousButtons>
              <div className='Buttons-wrapper'>
                {currentpage > 1 ? (
                  <>
                    <a
                      href='#'
                      rel='noopener noreferrer'
                      title='Previous Page'
                      onClick={() => this.prevPage()}
                      className='Previous-btn'
                    >
                      <span>&#8249;</span> Previous
                    </a>
                  </>
                ) : (
                  <></>
                )}
                <span className='active-pages'>
                  {this.displayRemainingVoice()}{' '}
                </span>{' '}
                of {totalVoiceRecord}
                {currentpage < totalNumberOfPage ? (
                  <>
                    <a
                      href='#'
                      rel='noopener noreferrer'
                      title='Next Page'
                      onClick={() => this.nextPage()}
                      className='next-btn'
                    >
                      Next <span>&#8250;</span>
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </ExtendedVoiceNextPreviousButtons>
            <span className='next' onClick={() => this.nextPage()}>
              <img src={unfillarrownext} title='Next Page' alt='next button' />
            </span>
          </div>
        </div>

        {openConverstionPopUp && (
          <NewWelbeeVoiceForm
            onSubmit={(value) => {
              this.onAllStaffClick(value);
            }}
            isLoading={false}
            submitButtonText={'Invite all staff'}
            closeButtonText={'Select staff to invite'}
            isOpen={this.state.openConverstionPopUp}
            onClose={() => {
              this.closePopUp();
            }}
            onSelectStaff={() => this.redirectToStaffManagment()}
            voiceRecord={this.state.voiceRecord}
          />
        )}
        <ConfirmPopupForm
          isOpen={isConfirmInvitation}
          onClose={() => this.setState({ isConfirmInvitation: false })}
          onOk={() => this.onCreateConverstionClick()}
          successPopupMessage={
            'You are about to send your invitation to (' +
            noOfStaff +
            ') staff members.'
          }
          okButtonText='Confirm'
        />
      </ExtendedVoiceDashboardWrapper>
    );
  }
}
const mapStateToProps = ({ header: { newConversationCount } }) => ({
  newConversationCount,
});

const mapDispatchToProps = (dispatch) => ({
  welbeeService: bindActionCreators(welbeeVoiceService, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VoiceDashboard));
