import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DirectorLeftMenu, MainMATHeader } from '../../components';
import { isEmpty } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { parseJwt } from '../../utils/CommonFunctions';
import {
  WebStorageNames,
  MenuItems,
  SilideNumberAndTitles,
} from '../../utils/Constants';
import { matGroupService, storageService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import MatCycleDropDown from '../../components/common/MatCycleDropDown';
import { iconDownload } from '../../resources/images/index';
import Tooltip from '@mui/material/Tooltip';
import {
  PageOverlay,
  PresentationWrapperStyle,
  ExtendedAssistanceWrapper,
} from './SurveyReportPresentationStyle';
import MatPPTPreviousVersion from './MatPPTPreviousVersion';
import MatPPTNewVersion from '../mat/matPresentation/MatPPTNewVersion';

import _ from 'lodash';

class MatPresentation extends Component {
  constructor(props) {
    super(props);
    this.matRef = React.createRef();
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.state = {
      selectedSlideIndex: 0,
      slide: {
        title: '',
        content: '',
      },
      TemplateId: 0,
      MatPPTData: [],
      NumberOfSlides: [],
      JobRoleGraph: [],
      AllSchoolGraph: [],
      message: '',
      surveyCounts: 0,
    };
  }
  onKeyDown = (event) => {
    if (event.keyCode) {
      const { selectedItem } = this.state;
      this.setState({ selectedItem: selectedItem + 1 });
    }
  };
  clearJsonState = () => {
    this.setState({ MatPPTData: [], NumberOfSlides: [],
      JobRoleGraph: [],
      AllSchoolGraph: []});
    this.setState({ TemplateId: 0 ,surveyCounts: 0 });
  };

  divideSchoolChartData = (originalData, slideData, surveyCount) => {
    this.setState({ NumberOfSlides: [] });
  
    if (slideData.length > 0) {
      const uniqueDats = slideData.filter((item, index, self) => {
        return index === self.findIndex((t) => t.id === item.id);
      });
      slideData = uniqueDats;
    }
  
    const chunkCount = surveyCount > 1 ? 6 : 10;
    const AllSchoolGraphChunks = _.chunk(originalData, chunkCount);
  
    if (AllSchoolGraphChunks.length > 1) {
      const firstSlide = slideData.find((x) => x.id === 7);
  
      if (firstSlide) {
        firstSlide.label = surveyCount>1? `Changes in Scores by Site Since Last Survey (1 of ${AllSchoolGraphChunks.length})` : `Scores by Site (1 of ${AllSchoolGraphChunks.length})`;
      }
  
      for (let i = 1; i < AllSchoolGraphChunks.length; i++) {
        slideData.splice(7, 0, {
          id: 7,
          label:surveyCount>1?  `Changes in Scores by Site Since Last Survey (${i + 1} of ${AllSchoolGraphChunks.length})` : `Scores by Site (${i + 1} of ${AllSchoolGraphChunks.length})`,
          subSlideIndex: i,
        });
      }
    }
    
    slideData?.sort((a, b) => {
      if (a.id === b.id) {
        return a.subSlideIndex - b.subSlideIndex;
      }
      return a.id - b.id;
    });

    this.setState({ NumberOfSlides: slideData });
    return AllSchoolGraphChunks;
  };
  
  reformatChartData = (originalData) => {
    const reformattedData = {};
    if (originalData?.length > 0) {
      originalData?.forEach((entry) => {
        const label = entry.label;
        const characteristicsType = entry.characteristicsType;
        // Skip entries with characteristicsType other than 1-7, as we want to focus on these categories
        if (characteristicsType < 1 || characteristicsType > 7) {
          return;
        }
        // Create or update the entry in the reformatted data
        if (!reformattedData[label]) {
          reformattedData[label] = { label };
        }
        // Add the score for the current category for both value1 and value2
        reformattedData[label][`${entry.title}_value1`] = entry.value1;
        reformattedData[label][`${entry.title}_value2`] = entry.value2;
        reformattedData[label][
          `${entry.title}_decileValue1`
        ] = `(${entry.decileValue1})`;
        reformattedData[label][
          `${entry.title}_decileValue2`
        ] = `(${entry.decileValue2})`;
      });
    }
    const result = Object.values(reformattedData);
    return result;
  };
  handleCycleChange = (selectedCycle) => {
    const search = window.location.search;
    let queryToken = '';
    if (search) {
      const paramList = search.split('=');
      if (paramList.length > 0) {
        if (paramList[0] === '?token') {
          queryToken = paramList[1];
        }
      }
    }
    this.setState({ selectedCycle: selectedCycle.value });
    this.setState({ selectedCycle: selectedCycle.value });
    this.clearJsonState();
    this.GetPresentationData(queryToken, selectedCycle.value);
  };
  GetPresentationData = (token, cycleId) => {
    this.setState({ message: '',  NumberOfSlides: [], JobRoleGraph: [], AllSchoolGraph: [] },
    );
    const { action } = this.props;
    const IsSurveyClosed = false;
    action.dataLoading(true);
    matGroupService
      .getGroupPresentationReport(
        token,
        storageService.getItem(WebStorageNames.MatGroupId),
        cycleId,
        IsSurveyClosed
      )
      .then((response) => {
        if (response != null && response.success) {
       
          if (response.data.TemplateId === 2) {
            
            var schoolChartData = this.reformatChartData(
              response.data.matPpptJson.getAllSchoolGhraph[0]?.datasets
            );
          
            var dividedChartData = this.divideSchoolChartData(
              schoolChartData,
              SilideNumberAndTitles,
              response.data.matPpptJson.getAllSchoolGhraph[0]?.surveyCount
            );
            var jobRoleChartData = this.reformatChartData(
              response.data.matPpptJson.getJobRoleGraphAllSchools[0]?.datasets
            );
            this.setState({ AllSchoolGraph: dividedChartData });
            this.setState({ JobRoleGraph: jobRoleChartData });
            this.setState({ surveyCounts: response.data.matPpptJson.getAllSchoolGhraph[0]?.surveyCount });
          }
          this.setState({
            MatPPTData: response.data.matPpptJson,
            TemplateId: response.data.TemplateId,
          });
          action.dataLoading(false);
        } else {
          this.setState({
            message: response.message,
          });
          action.dataLoading(false);
          return;
        }
      })
      .catch(() => {
        this.setState({ loaded: false });
      });
  };
  componentDidMount() {
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    const search = window.location.search;
    let queryToken = '';
    if (search) {
      const paramList = search.split('=');
      if (paramList.length > 0) {
        if (paramList[0] === '?token') {
          queryToken = paramList[1];
        }
      }
    }
    this.GetPresentationData(queryToken, 0);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.MatPresentation,
    });
    amplitudeLog('PageView', 'MAT Presentation', '');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  handlePresentationPreview = () => {
    this.setState({ isPreview: true });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  render() {
    return (
      <div>
        {this.state.isPreview && (
          <PageOverlay onClick={() => this.setState({ isPreview: false })} />
        )}
        <PresentationWrapperStyle>
          <div className='containerClass'>
            <div className='left-container'>
              <DirectorLeftMenu />
            </div>
            {/*Header Row */}
            <div className='right-container'>
              <ExtendedAssistanceWrapper>
                <AssistanceWrapper style={{ zIndex: '999' }}>
                  <MainMATHeader />
                  <div className='mat-header-bottom'>
                    <div
                      className='footer-buttons'
                      style={{ marginLeft: '8px' }}
                    >
                      <Tooltip title='Download' followCursor>
                        <button
                          onClick={() => {
                            this.printSurveyReportPresentation();
                          }}
                        >
                          <img src={iconDownload} alt='' />{' '}
                          <span>Download</span>
                        </button>
                      </Tooltip>
                    </div>
                    <div className='dd-survey'>
                      <MatCycleDropDown
                        handleOnChange={this.handleCycleChange}
                      />
                    </div>
                  </div>
                </AssistanceWrapper>
              </ExtendedAssistanceWrapper>
              {!isEmpty(this.state.message) ? (
                <div className='error-message'>{this.state.message}</div>
              ) : (
                <>
                  {this.state.TemplateId === 2 && (
                    <MatPPTNewVersion
                      NumberOfSlides={this.state.NumberOfSlides}
                      JobRoleGraph={this.state.JobRoleGraph}
                      AllSchoolGraph={this.state.AllSchoolGraph}
                      matPppData={this.state.MatPPTData}
                      setClick={(click) =>
                        (this.printSurveyReportPresentation = click)
                      }
                    />
                  )}
                  {this.state.TemplateId === 1 && (
                    <MatPPTPreviousVersion
                      matPppData={this.state.MatPPTData}
                      setClick={(click) =>
                        (this.printSurveyReportPresentation = click)
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </PresentationWrapperStyle>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(MatPresentation));
