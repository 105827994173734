import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { welbeeLogoCircle } from '../../resources/images/index';
import { storageService } from '../../services';
import { WebStorageNames, QueryStringTokens } from '../../utils/Constants';
import { dashboardService, matGroupService } from '../../services';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { firstLoginAction } from '../../store/userStore';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { setTextLengthLimit } from '../../utils/CommonFunctions';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

class TopMatHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matGroupName: '',
      stickyheader: false,
      MatUserEmail: '',
      MatUserFirstName: '',
      MatUserLastName: '',
    };
  }

  componentDidMount() {
    this.MatDashboardHeaderData();
  }

  MatDashboardHeaderData() {
    let token = getQueryStringVal(QueryStringTokens.token);
    if (!token) token = storageService.getItem(WebStorageNames.MatUserId);
    matGroupService.getMatGroup(token).then((response) => {
      if (response.success && response.data) {
        storageService.setItem(
          WebStorageNames.IsDisplayMatHeatMap,
          response.data.isDisplayMatHeatMap
        );
        storageService.setItem(WebStorageNames.MatName, response.data.Name);
        this.setState({
          matGroupName: response.data.Name,
        });
        this.setState({
          MatUserEmail: response.data.MatHeaderTopInfo.Email,
        });
        this.setState({
          MatUserFirstName: response.data.MatHeaderTopInfo.FirstName,
        });
        this.setState({
          MatUserLastName: response.data.MatHeaderTopInfo.LastName,
        });
      }
    });
  }

  ChangeBackground = () => {
    if (window.scrollY >= 10) {
      this.setState({ stickyheader: true });
    } else {
      this.setState({ stickyheader: false });
    }
  };

  render() {
    const {
      matGroupName,
      stickyheader,
      MatUserEmail,
      MatUserFirstName,
      MatUserLastName,
    } = this.state;

    window.addEventListener('scroll', this.ChangeBackground);

    return (
      <AssistanceWrapper>
        <Grid container>
          <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <AppBar
              className={stickyheader ? 'stickyheader sticky' : 'stickyheader'}
            >
              <div className='main-app-header-group'>
                <div className='dashboard-school-name'>
                  <h1>{setTextLengthLimit(matGroupName)}</h1>
                  <span>Staff Wellbeing Overview</span>
                </div>
                <div className='main-app-user'>
                  <div className='user-account'>
                    <Avatar
                      sx={{
                        bgcolor: '#fff',
                        float: 'left',
                        marginRight: '8px',
                        width: 45,
                        height: 45,
                        borderRadius: '8px',
                      }}
                      variant='rounded'
                    >
                      <img src={welbeeLogoCircle} alt='' />
                    </Avatar>
                    <Box sx={{ float: 'left' }}>
                      <span className='username'>
                        Welcome{' '}
                        {setTextLengthLimit(
                          MatUserFirstName + ' ' + MatUserLastName
                        )}
                      </span>
                      <span className='useremail'>
                        {setTextLengthLimit(MatUserEmail)}
                      </span>
                    </Box>
                  </div>
                  <div className='btn-request-assistance'>
                    <RequestAssistanceButton />
                  </div>
                </div>
                {/* <div className='main-app'>
                  <div className='btn-request-assistance'>
                    <RequestAssistanceButton />
                  </div>
                </div> */}
              </div>
            </AppBar>
          </Grid>
        </Grid>
      </AssistanceWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(dashboardService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});

export default connect(mapDispatchToProps)(withRouter(TopMatHeader));
