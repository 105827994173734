import React, { useState, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import { storageService, matCustomHeatmapService } from '../../../services';
import debounce from 'lodash.debounce';

import {
  JobRoles,
  WebStorageNames,
  QueryStringTokens,
  empType,
  experince,
} from '../../../utils/Constants';
import { Box, Grid } from '@mui/material';
import AppModel from '../../../components/common/AppModel';
import { AppButton, ErrorWrapper } from '../../../resources/styling';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  StaffWrapper,
  WelbeeTextField,
  customStyles,
  ExtendedSubContainer,
} from '../../../resources/styling/appStyle';
import { colors } from '../../../resources/theme/colors';
import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
  getQueryStringVal,
  getCoreFilterList,
  combineAllHeatMapModels,
  filterCustomHeatmap,
  coreFiltersGroupMappings,
} from '../../../utils/CommonFunctions';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

const { lightBlue } = colors;

const MatFiltersSearchBox = (props) => {
  const [dataList, setDataList] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [heatMapName, setHeatmapName] = useState('');
  const [customOptions, setCustomOptions] = useState([]);
  const [customHeatmapId, setCustomHeatmapId] = useState(false);
  const [isDownloadVisible, SetDownloadVisible] = useState(false);
  const [SavedFilters, SetSaveFilters] = useState([]);
  const [isUpdate, SetUpdate] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSaveButtonShow, SetSaveButtonShow] = useState(false);
  const [combineHeatmaps, SetcombineHeatmaps] = useState([]);
  const [selectedCustomOption, setSelectedCustomOption] = useState(null);
  const [isSaveHandlerCalled, setIsSaveHandlerCalled] = useState(false);

  const CustomOption = ({ children, isSelected, ...props }) => (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span>{children}</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className='save-heatmap-icon'
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleEditCustomheatmap(props.data);
            }}
          >
            <EditIcon sx={{ fontSize: 18, marginRight: 1 }} />
          </div>
          <div
            className='save-heatmap-icon'
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleDeleteOption(props.data, event);
            }}
          >
            <DeleteIcon sx={{ fontSize: 18 }} />
          </div>
        </div>
      </div>
    </components.Option>
  );

  const handleDeleteOption = (option, event) => {
    setIsLoading(true);
    event.stopPropagation();
    matCustomHeatmapService
      .deleteMatCustomHeatmap(option.value)
      .then((response) => {
        const { success, data, message } = response;
        if (success) {
          GetCutomHeatmapFiltersByMatGroupId();
          setSelectedValues([]);
          setIsLoading(false);
          toast.success(message);
        } else {
          setIsLoading(false);
          toast.success(message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.success(error);
      });
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const bg = isFocused ? '#eee' : isSelected ? '#B2D4FF' : 'transparent';
    const style = {
      alignItems: 'center',
      backgroundColor: bg,
      color: 'inherit',
      display: 'flex',
    };
    const props = {
      ...innerProps,

      style,
    };

    return (
      <div className='heatmap-option'>
        <components.Option
          {...rest}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          getStyles={getStyles}
          innerProps={props}
        >
          <Checkbox
            checked={isSelected}
            color='success'
            size='small'
            className='option-checkbox'
            readOnly
          />
          {children}
        </components.Option>
      </div>
    );
  };
  const showCustomHeatmap = (selectedOptions) => {
    const filterDataResult = filterCustomHeatmap(
      combineHeatmaps,
      selectedOptions
    );

    props.handleCustomHeatmap(filterDataResult, '');
    if (selectedOptions?.length > 0) {
      SetSaveButtonShow(true);
    } else {
      SetSaveButtonShow(false);
    }
  };

  const getFilterAndHeatmapList = useCallback((data, selectedFilter) => {
    var options = getCoreFilterList(data, selectedFilter);
    console.log(options);
    setFilteredOptions(options);
    var combineData = combineAllHeatMapModels(data, selectedFilter);
    SetcombineHeatmaps(combineData);
    setDataList(options);
  }, []);

  useEffect(() => {
    getFilterAndHeatmapList(props?.matCoreFilters, 'MAT');
    GetCutomHeatmapFiltersByMatGroupId();
  }, []);

  useEffect(() => {
    if (customOptions.length > 0 && isSaveHandlerCalled) {
      setSelectedCustomOption(customOptions[customOptions.length - 1]);
      setIsSaveHandlerCalled(false);
      setCustomHeatmapId(customOptions[customOptions.length - 1]?.value);
    }
  }, [customOptions, isSaveHandlerCalled]);

  const handleInputChange = (inputValue) => {
    if (inputValue) {
      const filteredOption = dataList
        .map((group) => ({
          label: group.label,
          options: group.options.filter((item) =>
            item.value
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          ),
        }))
        .filter((group) => group.options.length > 0);
      setFilteredOptions(filteredOption);
    }
    setSearchValue(inputValue);
  };

  const handleClearOnMouse = () => {
    setFilteredOptions(dataList);
    setSearchValue('');
  };

  const selectedFilters = (selectedValues) => {
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    const matCycleId = props?.matCycleId?.value;
    const selectedItems = {
      MatGroupId: matGroupId,
      MatCycleId: matCycleId,
      Name: heatMapName,
      Filters: {
        JobRole: [],
        Department: [],
        JobTitle: [],
        EmploymentType: [],
        TimeAtSchool: [],
        PhaseOrYears: [],
        Schools: [],
        AdditionalFilters: [],
        TechingStaffBySchools: [],
        NonClassTeachingStaffBySchools: [],
        SupportStaffBySchools: [],
        NonClassSupportStaffBySchools: [],
        MiddleLeaderBySchools: [],
        LeaderBySchools: [],
        FullTimeBySchools: [],
        PartTimeBySchools: [],
        Less1YearsbySchools: [],
        OneTo3YearsBySchools: [],
        FourT5yearsBySchools: [],
        SixTo10YearsBySchools: [],
        ElevenTo15YearsBySchools: [],
        SixteenTo20yearsBySchools: [],
        Greater20YearsBySchools: [],
      },
    };

    selectedValues.forEach((option) => {
      const [group, ...items] = option.value.split(':');   
      const trimmedGroup = group.trim();

      if (coreFiltersGroupMappings.hasOwnProperty(trimmedGroup)) {
        const propertyName = coreFiltersGroupMappings[trimmedGroup];
        const trimmedItems = items.map((item) => item.trim());
        selectedItems.Filters[propertyName].push(...trimmedItems);
      } else if (selectedItems.Filters.hasOwnProperty(trimmedGroup)) {
        selectedItems.Filters[trimmedGroup].push(option?.label?.trim());
      } else {
        // If the group name is not matched, add it as an AdditionalFilter
        const filterName = trimmedGroup;
        const filterTypes = items.map((item) => item.trim());
        selectedItems.Filters.AdditionalFilters.push({
          FilterName: filterName,
          FilterTypes: filterTypes,
        });
      }
    });
  
    Object.keys(selectedItems.Filters).forEach((key) => {
      if (selectedItems.Filters[key]?.length === 0) {
        delete selectedItems.Filters[key];
      }
    });

    Object.keys(selectedItems).forEach((key) => {
      if (selectedItems[key]?.length === 0) {
        delete selectedItems[key];
      }
    });;
    return selectedItems;
  };

  const handleChangeFilters = (selectedOptions, { action, removedValue }) => {
    if (action === 'clear') {
      setSelectedCustomOption(null);
      SetUpdate(false);
      SetSaveButtonShow(false);
      SetDownloadVisible(false);
      const newSelectedOptions = selectedOptions.filter(
        (option) => option !== removedValue
      );
      setSelectedValues(newSelectedOptions);
    }
    setSelectedValues(selectedOptions);
    showCustomHeatmap(selectedOptions);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleShowPopup = () => {
    setOpen(true);
  };
  const handleSaveFilters = () => {
    let token = getQueryStringVal(QueryStringTokens.token);
    setIsLoading(true);
    const model = selectedFilters(selectedValues);
    matCustomHeatmapService
      .saveMatHeatmapFilters(model, token)
      .then((response) => {
        SetDownloadVisible(true);
        props.SetMainDownloadVisisble(false);
        getCustomOptions(response.data.CustomHeatmaps);
        setIsSaveHandlerCalled(true);
        toast.success('Custom heatmap has been saved successfully.');
        setIsLoading(false);
        handleClose();
        setSelectedValues([]);
      })
      .catch((error) => setIsLoading(false));
  };

  const getCustomOptions = (data) => {
    const customOptions = data.map((item) => ({
      label: item.Name,
      value: item.Id,
    }));
    setCustomOptions(customOptions);
  };

  const handleChangeHeatmap = (selectValue, { action }) => {
    if (action === 'clear') {
      setSelectedCustomOption(null);
      setSelectedValues([]);
      SetUpdate(false);
      SetDownloadVisible(false);
      props.SetMainDownloadVisisble(true);
      props.handleCustomHeatmap(null, '');
    } else {
      setIsLoading(true);
      props.handleSetLoading(true);
      let Id = selectValue?.value;
      setCustomHeatmapId(selectValue?.value);
      matCustomHeatmapService
        .geMatCustomHeatmapById(Id)
        .then((response) => {
          const { success, data, message } = response;
          if (success) {
            console.log(data.CustomHeatmap);
            props.SetMainDownloadVisisble(false);
            props.handleCustomHeatmap(data.CustomHeatmap, '');
            SetSaveFilters(response.data.Filters.Filters);
            setSelectedCustomOption(selectValue);
            SetDownloadVisible(true);
            props.handleSetLoading(false);
          } else {
            props.SetMainDownloadVisisble(true);
            props.handleCustomHeatmap('', message);
            SetDownloadVisible(false);
            props.handleSetLoading(false);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          props.handleCustomHeatmap('', error);
          setIsLoading(false);
          props.handleSetLoading(false);
        });
    }
  };

  const GetCutomHeatmapFiltersByMatGroupId = () => {
    const matId = storageService.getItem(WebStorageNames.MatGroupId);
    matCustomHeatmapService
      .getCutomHeatmapFiltersByMatGroupId(matId)
      .then((response) => {
        getCustomOptions(response?.data?.CustomHeatmap);
      })
      .catch((error) => console.log(error));
  };
  const handleClear = () => {
    props.handleCustomHeatmap([], '');
  };

  const handelDownload = () => {
    setIsLoading(true);
    if(customHeatmapId === 0 || customHeatmapId === '') {
      setIsLoading(false);
      toast.error('Please select at least one custom heatmap to download.');
      return;
    }
    matCustomHeatmapService
      .downloadMatCustomSchoolHeatmap(customHeatmapId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'welbee-mat-customheatmap.pdf');
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleUpdateCustomHeatmap = () => {
    setIsLoading(true);
    const model = selectedFilters(selectedValues);
    matCustomHeatmapService
      .updateMatHeatmapFilters(model, customHeatmapId)
      .then((response) => {
        props.SetMainDownloadVisisble(false);
        props.handleCustomHeatmap(response.data.CustomHeatmap, '');
        toast.success('Custom heatmap has been updated successfully.');
        setIsLoading(false);
        handleClose();
        setSelectedValues([]);
        SetUpdate(false);
        SetSaveButtonShow(false);
        SetDownloadVisible(true);
      })
      .catch((error) => setIsLoading(false));
  };

const reverseCoreFiltersGroupMappings = {};
Object.entries(coreFiltersGroupMappings).forEach(([lhs, rhs]) => {
  reverseCoreFiltersGroupMappings[rhs] = lhs;
});

const handleEditCustomheatmap = (option) => {
  setSelectedCustomOption(null);
  setIsLoading(true);
  props.handleSetLoading(true);
  let Id = option?.value;
  setCustomHeatmapId(Id);
  matCustomHeatmapService
    .geMatCustomHeatmapById(Id)
    .then((response) => {
      const { success, data, message } = response;
      if (success) {
        props.SetMainDownloadVisisble(false);
        props.handleCustomHeatmap(data.CustomHeatmap, '');
        SetSaveFilters(response.data.Filters.Filters);
        setSelectedCustomOption(option);
        SetDownloadVisible(true);
        props.handleSetLoading(false);
        const defaultValues = [];
        if (response.data.Filters.Filters) {
          Object.keys(response.data.Filters.Filters)?.forEach((group) => {
            response.data.Filters.Filters[group]?.forEach((item) => {
              const propName = reverseCoreFiltersGroupMappings[group];
              const replacedGroup = propName || group;
        
              if (group === 'AdditionalFilters') {
                // Check if item is an array (AdditionalFilters) and iterate through it
                if (Array.isArray(item.FilterTypes)) {
                  item.FilterTypes.forEach((filterType) => {
                    defaultValues.push({
                      label: filterType,
                      value: `${item.FilterName}: ${filterType}`,
                    });
                  });
                }
              } else {
                defaultValues.push({
                  label: item,
                  value: `${replacedGroup}: ${item}`,
                });
              }
            });
          });
          SetUpdate(true);
        }
        console.log(defaultValues);
        setSelectedValues(defaultValues || []);
      } else {
        props.SetMainDownloadVisisble(true);
        props.handleCustomHeatmap('', message);
        SetDownloadVisible(false);
        props.handleSetLoading(false);
      }
      setIsLoading(false);
    })
    .catch((error) => {
      props.handleCustomHeatmap('', error);
      setIsLoading(false);
      props.handleSetLoading(false);
    });
};

  return (
    <ExtendedSubContainer style={{ padding: '20px 0px !important' }}>
      <div className='custom-heatmap'>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='flex-custom-heatmap'>
              {customOptions?.length > 0 && (
                <div>
                  <Select
                    className='custom-heatmap-dropdown'
                    placeholder='Saved heatmaps'
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name='customHeatmap'
                    onClear={() => {props.SetMainDownloadVisisble(true); handleClear()}}
                    onChange={handleChangeHeatmap}
                    options={customOptions}
                    value={selectedCustomOption}
                    components={{
                      Option: CustomOption,
                    }}
                  />
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'releative',
                        marginTop: '5px',
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <form>
                <div>
                  <Select
                    isSearchable={true}
                    onClear={handleClear}
                    className='custom-filter-heatmap-dropdown'
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti
                    options={filteredOptions}
                    onChange={handleChangeFilters}
                    value={selectedValues}
                    placeholder='Search Filters...'         
                    isClearable={true}
                    onInputChange={handleInputChange}
                    onMenuClose={handleClearOnMouse}
                    components={{
                      Option: InputOption,
                    }}
                  />
                </div>
                {isUpdate === false && isSaveButtonShow === true && (
                  <div>
                    <Tooltip
                      title='Click here to save custom heatmap selected filters'
                      followCursor
                    >
                      <AppButton
                        className='heatmap-button'
                        type='button'
                        onClick={handleShowPopup}
                      >
                        {'Save'}
                      </AppButton>
                    </Tooltip>
                  </div>
                )}
                {isUpdate && (
                  <div>
                    <AppButton
                      className='heatmap-button'
                      type='button'
                      onClick={handleUpdateCustomHeatmap}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'releative',
                            marginTop: '5px',
                          }}
                        />
                      ) : (
                        'Update'
                      )}
                    </AppButton>
                  </div>
                )}

                {isDownloadVisible && (
                  <div>
                    <Tooltip
                      title='Click here to download custom selected heatmap'
                      followCursor
                    >
                      <AppButton
                        className='heatmap-button'
                        data-id-div='reportDownload'
                        onClick={(e) => { e.preventDefault(); handelDownload()}}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'releative',
                              marginTop: '5px',
                            }}
                          />
                        ) : (
                          <>Download</>
                        )}
                      </AppButton>
                    </Tooltip>
                  </div>
                )}
              </form>
            </div>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            heatMapName: '',
          }}
          validateOnBlur={false}
          validateOnChange={true}
          validationSchema={Yup.object().shape({
            heatMapName: Yup.string()
              .trim()
              .required('Heatmap Name is required.'),
          })}
          onSubmit={(values, formProps) => {
            const { heatMapName } = values;
            const labelExists = customOptions?.some(
              (option) => option.label === heatMapName
            );

            if (labelExists) {
              formProps.setFieldError(
                'heatMapName',
                'The heatmap name already exists. Please try another.'
              );
            } else {
              handleSaveFilters(heatMapName);
              formProps.resetForm();
            }
          }}
          render={({
            values,
            touched,
            errors,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                <AppModel
                  onClose={handleClose}
                  styles={customStyles}
                  open={isOpen}
                  titleText={'Save Custom Heatmap'}
                  bodyText={
                    <div>

                      <Box
                        component='form'
                        sx={{
                          '& .MuiTextField-root': { width: '100%' },
                        }}
                        autoComplete='off'
                      >
                        <StaffWrapper>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <WelbeeTextField
                                fullWidth
                                name='heatMapName'
                                id='heatMapName'
                                value={values.heatMapName}
                                label='Custom Heatmap Name'
                                onChange={(e) => {
                                  setFieldValue('heatMapName', e.target.value);
                                  setHeatmapName(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault(); 
                                    handleSubmit();
                                  }
                                }}
                              />
                              <ErrorWrapper>
                                {touched.heatMapName && errors.heatMapName}
                              </ErrorWrapper>
                            </Grid>
                          </Grid>
                          <AppButton
                            color={lightBlue}
                            type='button'
                            onClick={handleSubmit}
                            style={{ marginTop: '20px' }}
                          >
                            {isLoading ? (
                              <CircularProgress
                                size={24}
                                sx={{
                                  position: 'releative',
                                  marginTop: '5px',
                                }}
                              />
                            ) : (
                              'Save Heatmap'
                            )}
                          </AppButton>
                        </StaffWrapper>
                      </Box>
                    </div>
                  }
                />
              </>
            );
          }}
        />
      </div>
    </ExtendedSubContainer>
  );
};

export default MatFiltersSearchBox;
