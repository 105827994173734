import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import schoolService from '../../services/api/schoolService';
import { AppButton } from '../../resources/styling';
import { AdminHeader, Paging } from '../../components';
import { colors } from '../../resources/theme/colors';
import { WelbeeTypes } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import { searchIconWhite } from '../../resources/images';
import { CheckBox, SchoolListMainWrapper, TableStyle } from '../../resources/styling/appStyle';
import { toast } from 'react-toastify';
import { InputWrapper } from '../../components/common/loginStyle';
import { WrapperMain } from '../../resources/styling/formStyle';
import { AppInputField } from '../../components';
import { getInvoicePriceBySubscription, getInvoicePriceForUpgrade,getAdditionalSurveyPrice } from '../../utils/CommonFunctions';
import { SchoolSurveyWrapper, SchoolSurveyInnerMain, SchoolSurveyInner, PaginationWrapper } from './SuperAdminStyle';

const { teal } = colors;

class Invoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOfItems: [],
            schoolsInvoiceList: [],
            filteredList: [],
            selectTestAccount: false,
            nameSearchValue: '',
            isChanged: false,
        };
        this.computeDiscount = this.computeDiscount.bind(this);
        this.setDiscountText = this.setDiscountText.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.setSendInvoiceCheck = this.setSendInvoiceCheck.bind(this);
        this.sendInvoices = this.sendInvoices.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.handleNameSearchChange = this.handleNameSearchChange.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.ReplacementEmail = this.ReplacementEmail.bind(this);
        this.getColumnText = this.getColumnText.bind(this);
    }

    componentDidMount() {
        this.getAllOutstandingInvoices();
    }
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
    getAllOutstandingInvoices() {
        schoolService
            .GetOutstandingInvoices()
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        schoolsInvoiceList: response.data
                    }, this.applyFilters);
                }
            });
    }
    sendInvoices() {
        if (this.state.schoolsInvoiceList.length > 0) {
            schoolService
                .SendInvoices(this.state.filteredList.filter(x => x.IsSelected))
                .then((response) => {
                    if (response.success) {
                        toast.success('Invoice email(s) sent.');
                        this.getAllOutstandingInvoices();
                    }
                    else toast.error(response.message);
                })
                .catch((error) =>
                    console.log('error', error));
        }
        else
            toast.error("No invoices found");
    }
    setSendInvoiceCheck(val, item) {
        let newArr = this.state.filteredList;
        let i = newArr.findIndex(x => x.Id === item.Id);
        newArr[i].SuppressEmail = val;
        this.setState({ schoolsInvoiceList: newArr });
    }
    setSelected(val, item, selectAll) {
        let newArr = this.state.filteredList;
        if (selectAll) {
            newArr.forEach(item => item.IsSelected = val);
        }
        else {
            newArr[newArr.findIndex(x => x.Id === item.Id)].IsSelected = val;
        }
        this.setState({ filteredList: newArr });
    }
    setDiscountText(val, item) {
        let newArr = this.state.filteredList;
        newArr[newArr.findIndex(x => x.Id === item.Id)].DiscountText = val;
        this.setState({ filteredList: newArr });
    }
    ReplacementEmail(val, item) {
        let newArr = this.state.filteredList;
        newArr[newArr.findIndex(x => x.Id === item.Id)].ReplacementEmail = val;
        this.setState({ filteredList: newArr });
    }
    getColumnText(item) {
        let colText = '';
            if (item.IsUpgradeRequest) {
                let oldSubs = item.UpgradeFrom == WelbeeTypes.WelbeeVoiceFree ? 'Voice Free' :
                    item.UpgradeFrom == WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                        item.UpgradeFrom == WelbeeTypes.WelbeeSurveyFree ? 'Survey Free' :
                            item.UpgradeFrom == WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium';
                let newSubs = item.UpgradeTo == WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                    item.UpgradeTo == WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium';
                colText = oldSubs + ' - ' + newSubs;
            }
            else {
                colText = item.School.SchoolSubscription.SubscriptionId == WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                    item.School.SchoolSubscription.SubscriptionId == WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium';
            }
        return colText;
    }
    computeDiscount(val, item, type) {
        if (this.state.filteredList.length > 0) {
            let newArr = this.state.filteredList;
            let rowIndex = newArr.findIndex(x => x.Id === item.Id);
            let rowData = newArr[rowIndex]
            let percentageValue = 0;
            let value = 0;
            let total = rowData.InvoiceType === 1 ? getAdditionalSurveyPrice(rowData.School.NumberOfPupil) : rowData.IsUpgradeRequest ? getInvoicePriceForUpgrade(rowData.School.NumberOfPupil,rowData.UpgradeFrom, rowData.UpgradeTo) : getInvoicePriceBySubscription(rowData.School.NumberOfPupil, rowData.School.SchoolSubscription.SubscriptionId);
            if (type === 'p') {
                value = total - (val / 100) * total;
                percentageValue = val;
            }
            if (type === 'v') {
                percentageValue = (100 - ((val / total) * 100)).toFixed(2);
                value = val;
            }
            newArr[rowIndex].DiscountPercentage = percentageValue;
            newArr[rowIndex].DiscountAmount = value;
            this.setState({ filteredList: newArr });
        }
    }
    applyFilters() {
        const {
            nameSearchValue,
            schoolsInvoiceList,
            selectTestAccount
        } = this.state;
        let searchList = schoolsInvoiceList.filter(x => (x.School.Name.toLocaleLowerCase().includes(
            nameSearchValue.toLocaleLowerCase()
        )) && x.School.IsTestAccount === selectTestAccount);
        this.setState({ filteredList: searchList });
    }
    handleNameSearchChange = (e) => {
        this.setState({ nameSearchValue: e.target.value }, this.applyFilters);
    }
    render() {
        return (
            <div>
                <AdminHeader />
                <SchoolSurveyWrapper>
                    <SchoolSurveyInnerMain>
                        <SchoolSurveyInner>
                            <SchoolListMainWrapper>
                                <Grid container>
                                    
                                        <Grid item
                                            xl={3}
                                            lg={3}
                                            md={12}
                                            sm={12} style={{ 'height': '60px' }}>
                                            <h1 style={{ margin: '0px' }}>
                                                Outstanding Invoices
                                            </h1>
                                        </Grid>

                                        <Grid item
                                            xl={9}
                                            lg={9}
                                            md={12}
                                            sm={12}>
                                            <h1 style={{ minHeight: '60px' }} className='super-admin-header'>

                                                <div className='comments-search-filters'>
                                                    <div className='subcontainer-inner school-list-header'>

                                                        <div className='test-checkbox'>
                                                            <CheckBox >
                                                                <input
                                                                    className='styled-checkbox'
                                                                    type='checkbox'
                                                                    id='selectTestAccountCheckbox'
                                                                    value='value1'
                                                                    checked={this.state.selectTestAccount}
                                                                    onChange={(e) => this.setState({ selectTestAccount: e.target.checked }, this.applyFilters)}
                                                                />
                                                                <label for='selectTestAccountCheckbox'>
                                                                    <span className='checkbox-wrapper' style={{ color: 'white' }}>
                                                                        Test Accounts
                                                                    </span>
                                                                </label>
                                                            </CheckBox>
                                                        </div>

                                                        <div className='search-filed'>
                                                            <img src={searchIconWhite} alt='search button' />
                                                            <input
                                                                id='searchInputField'
                                                                placeholder='Enter a keyword'
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleNameSearchChange(e);
                                                                }}
                                                                icon='search'
                                                                className='searchField'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </h1>
                                        </Grid>
                                    

                                </Grid>
                                <TableStyle>
                                <table>
                                    <thead>
                                        <tr style={{ position: 'sticky', top: 0, backgroundColor: 'White', zIndex: 999 }}>
                                            <th className='list-heading'>
                                                <CheckBox>
                                                    <input
                                                        className='styled-checkbox'
                                                        id='styled-checkbox-all'
                                                        type='checkbox'
                                                        value='value1' onChange={(e) => this.setSelected(e.target.checked, 0, true)}
                                                    /> <label for='styled-checkbox-all'>
                                                    </label>
                                                </CheckBox>
                                            </th>
                                            <th className='list-heading list-action'>School Name</th>
                                            <th className='list-heading list-action'>Order Date</th>
                                            <th className='list-heading list-action'>Subscription</th>
                                            <th className='list-heading list-action'>No.Of Pupils</th>
                                            <th className='list-heading list-action'>Generated From</th>
                                            <th className='list-heading list-action'>Total Price</th>
                                            <th className='list-heading list-action'>Discount %</th>
                                            <th className='list-heading list-action'>Discount Value</th>
                                            <th className='list-heading list-action'>Invoice Discount Text</th>
                                            <th className='list-heading list-action'>Replacement Email</th>
                                            <th className='list-heading list-action'>Suppress Email</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                {this.state.pageOfItems.map((item, i) => (
                                    <tr key={item.Id}>
                                        <td>
                                            <CheckBox>
                                                <input
                                                    className='styled-checkbox'
                                                    id={'styled-checkbox-' + i}
                                                    type='checkbox'
                                                    value='value1' onChange={(e) => this.setSelected(e.target.checked, item, false)} checked={this.state.pageOfItems[i].IsSelected} />
                                                <label for={'styled-checkbox-'+i}>
                                                </label>
                                            </CheckBox>
                                        </td>
                                        <td>{item.School.Name ? item.School.Name : 'Not Provided'}</td>
                                        <td>{item.OrderDate ? new Date(Date.parse(item.OrderDate)).toDateString() : 'Not Provided'}</td>
                                        <td>{this.getColumnText(item)}</td>
                                        <td>{item.School.NumberOfPupil}</td>
                                        <td>{item.InvoiceType==1?'Additional Survey Charge':item.IsUpgradeRequest ? 'Account Upgrade':'New Account'}</td>
                                        <td>{item.InvoiceType === 1 ?  getAdditionalSurveyPrice(item.School.NumberOfPupil):item.IsUpgradeRequest ? getInvoicePriceForUpgrade(item.School.NumberOfPupil, item.UpgradeFrom, item.UpgradeTo) : getInvoicePriceBySubscription(item.School.NumberOfPupil, item.School.SchoolSubscription.SubscriptionId)}
                                        </td>
                                        <td>
                                            <AppInputField type='number' onChange={(e) => this.computeDiscount(e.target.value, item, 'p')} value={item.DiscountPercentage}/>
                                        </td>
                                        <td>
                                            <AppInputField type='number' onChange={(e) => this.computeDiscount(e.target.value, item, 'v')} value={item.DiscountAmount}/>
                                        </td>
                                        <td>
                                            <AppInputField onChange={(e) => this.setDiscountText(e.target.value, item)}/>
                                        </td>
                                        <td>
                                            <AppInputField onChange={(e) => this.ReplacementEmail(e.target.value, item)}/>
                                        </td>
                                        <td>
                                            <CheckBox>
                                                <input
                                                    className='styled-checkbox'
                                                    id={'styled-checkbox-supress' + i}
                                                    type='checkbox'
                                                    value='value1' onChange={(e) => this.setSendInvoiceCheck(e.target.checked, item)}
                                                />
                                                <label for={'styled-checkbox-supress' + i}>
                                                </label>
                                            </CheckBox>                                            
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                </table>
                                </TableStyle>
                                <PaginationWrapper>
                                    <Paging
                                        items={this.state.filteredList}
                                        onChangePage={this.onChangePage}
                                        isChanged={this.state.isChanged}
                                    />
                                </PaginationWrapper>
                                <div className='btn-send-invoice'>
                                    <AppButton
                                        color={teal}
                                        type='Send Invoices'
                                        onClick={this.sendInvoices}
                                    >
                                        Send Invoices
                                    </AppButton>
                                </div>
                            </SchoolListMainWrapper>
                        </SchoolSurveyInner>
                    </SchoolSurveyInnerMain>
                </SchoolSurveyWrapper>
            </div>
        );
    }
}

export default (withRouter(Invoices));
