import React, { useState, useEffect } from 'react';
import { AppButton } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import AdditionalSurveyConfirm from '../additionalSurveys/AdditionalSurveyConfirm';
import { WebStorageNames, WelbeeTypes } from '../../utils';
import { schoolService, storageService } from '../../services';
import CreateSurveyButton from '../../views/dashboard/CreateSurveyButton';
const { mandy } = colors;
const AdditionalSurvey = () => {
  const [showBuyConfirm, setShowBuyConfirm] = useState(false);
  const [showCreateSurveyForm, isShowCreateSurveyForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAdditionalSurvey, setHasAdditionalSurvey] = useState(false);
  const [schoolName, setSchoolName] = useState('');
  const [noOfPupil, setNoOfPupil] = useState(0);
  const [isExpired, setIsExpired] = useState(false);
  const subscriptionId = storageService.getItem(WebStorageNames.SubscriptionId);

  const closePopup = () => {
    setShowBuyConfirm(false);
    setFlags(true);
  };
  const setFlags = (agreed) => {
    schoolService
      .hasAdditionalSurvey(storageService.getItem(WebStorageNames.SchoolId))
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setSchoolName(response.data.Name);
        setNoOfPupil(response.data.NumberOfPupil);
        if (response.data.additionalSurveyStatus == 0) {
          setHasAdditionalSurvey(false);
        }
        if (response.data.additionalSurveyStatus == 1) {
          setHasAdditionalSurvey(false);
        }
        if (response.data.additionalSurveyStatus == 2) {
          if (agreed) isShowCreateSurveyForm(true);
          setHasAdditionalSurvey(true);
        }
        if (response.data.additionalSurveyStatus == 3) {
          setIsExpired(true);
          setHasAdditionalSurvey(false);
        }
      })
      .catch();
  };
  useEffect(() => {
    setFlags(false);
  }, []);

  return !isExpired ? (
    <div>
      <div className='survey-button-wrapper create-survey-button-wrapper'>
        {subscriptionId === WelbeeTypes.WelbeeSurveyPremium ||
        (subscriptionId === WelbeeTypes.WelbeeSurveyPro &&
          hasAdditionalSurvey) ? (
          <CreateSurveyButton openCreateForm={showCreateSurveyForm} />
        ) : subscriptionId === WelbeeTypes.WelbeeSurveyPro &&
          !hasAdditionalSurvey ? (
          <AppButton
            className='close-survey-button'
            color={mandy}
            type='submit'
            onClick={() => {
              setShowBuyConfirm(true);
            }}
          >
            Create Survey
          </AppButton>
        ) : (
          ''
        )}
      </div>
      {showBuyConfirm ? (
        <AdditionalSurveyConfirm
          onClose={closePopup}
          SchoolName={schoolName}
          NoOfPupil={noOfPupil}
        />
      ) : null}
    </div>
  ) : (
    <div className='survey-completion'>Your subscription has expired.</div>
  );
};

export default AdditionalSurvey;
