import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteWrapper } from './MatAdditionalQuestionStyle';

class MatAutoComplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    isListOpen: PropTypes.bool,
    onFocus: PropTypes.instanceOf(Function),
    onValueChange: PropTypes.instanceOf(Function),
    selectedVal: PropTypes.string
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);
    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: this.props.suggestions,
      // Whether or not the suggestion list is shown
      showSuggestions: this.props.isListOpen,
      // What the user has entered
      userInput: this.props.selectedVal
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      userInput: nextProps.selectedVal
    });
    if (nextProps.isListOpen !== this.props.isListOpen) {
      this.setState({
        showSuggestions: nextProps.isListOpen
      });
    }
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
    this.props.onValueChange(e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  onClick = (e, id) => {
    const { suggestions } = this.props;
    // Update the user input and reset the rest of the state
    this.setState({
      filteredSuggestions: suggestions,
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    this.props.onValueChange(e.currentTarget.innerText, id);
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const filteredActiveSuggestion = filteredSuggestions.map(x => [x.name]);
    const filteredActiveSuggestionId = filteredSuggestions.map(x => [x.id]);
    const activeSuggestionId = filteredActiveSuggestionId[activeSuggestion];

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      let userInputVal =
        filteredActiveSuggestion[activeSuggestion].length > 0
          ? filteredActiveSuggestion[activeSuggestion][0]
          : null;

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: userInputVal
      });
      this.props.onValueChange(userInputVal, activeSuggestionId[0]);

      return;
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class='suggestions'>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                // className = 'suggestion-active';
              }

              return (
                <li
                  className={className}
                  key={suggestion.id}
                  onClick={e => onClick(e, suggestion.id)}
                >
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class='no-suggestions'>
            <em>No suggestions!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <AutocompleteWrapper>
          <input
            type='text'
            placeholder=' '
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={this.props.onFocus}
            onClick={this.props.onFocus}
            value={userInput}
            className='floating-input'
          />
          <label>{this.props.dropDownLabel}</label>
          {suggestionsListComponent}
        </AutocompleteWrapper>
      </Fragment>
    );
  }
}

export default MatAutoComplete;
