import { voiceConversationAPI } from './urls';
import request from '../request';

export const getVoiceConversation = (token) => {
  return request({
    url: voiceConversationAPI.GetStaffVoiceConversation(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const voiceConversationService = {
  getVoiceConversation,
};

export default voiceConversationService;
