import { actionTypes } from './actionTypes';
import { toggleOption } from '../utils';

const {
  NAVIGATION_CHANGE,
  SHOW_REPORT_SUB_MENU,
  SHOW_NEW_COMMENT_COUNT,
  TOGGLE_BUTTON_SWITCH,
  SET_SELECTED_CONVERSATION,
  SHOW_NEW_CONVERSATION_COUNT,
  SET_SCHOOL_LOGO,
} = actionTypes;

//////////// USER ACTIONS ///////////////

export const activeItemAction = ({
  item,
  showReportSubMenu,
  disableReportMenu,
}) => (dispatch) => {
  dispatch({
    type: NAVIGATION_CHANGE,
    payload: { item, showReportSubMenu, disableReportMenu },
  });
};

export const showReportSubMenu = (showReportSubMenu) => (dispatch) => {
  dispatch({ type: SHOW_REPORT_SUB_MENU, payload: showReportSubMenu });
};
export const showNewCommentCount = (showNewCommentCount) => (dispatch) => {
  dispatch({ type: SHOW_NEW_COMMENT_COUNT, payload: showNewCommentCount });
};
export const toggleButtonSwitch = (button) => (dispatch) => {
  dispatch({ type: TOGGLE_BUTTON_SWITCH, payload: button });
};
export const setSelectedConversation = (conversationId) => (dispatch) => {
  dispatch({ type: SET_SELECTED_CONVERSATION, payload: conversationId });
};
export const setSchoolLogoUrl = (logoUrl) => (dispatch) => {
  dispatch({ type: SET_SCHOOL_LOGO, payload: logoUrl });
};
export const showNewConversationCount = (showNewConversationCount) => (
  dispatch
) => {
  dispatch({
    type: SHOW_NEW_CONVERSATION_COUNT,
    payload: showNewConversationCount,
  });
};

export const navigationActions = {
  activeItemAction,
  showReportSubMenu,
  showNewCommentCount,
  toggleButtonSwitch,
  showNewConversationCount,
  setSelectedConversation,
  setSchoolLogoUrl,
};

//////////// USER REDUCER ///////////////
const INITIAL_STATE = {
  activeItem: 'dashboard',
  newCommentCount: 0,
  showReportSubMenu: false,
  disableReportMenu: false,
  toggleButton: toggleOption.WelbeeSurvey,
  newConversationCount: 0,
  selectedConversation: null,
  logoURL: null,
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === NAVIGATION_CHANGE) {
    return {
      ...state,
      activeItem: action.payload.item,
      showReportSubMenu: action.payload.showReportSubMenu,
      disableReportMenu: action.payload.disableReportMenu,
    };
  }
  if (action.type === SHOW_NEW_COMMENT_COUNT) {
    return {
      ...state,
      newCommentCount: action.payload.item,
    };
  }
  if (action.type === TOGGLE_BUTTON_SWITCH) {
    return {
      ...state,
      toggleButton: action.payload,
    };
  }
  if (action.type === SHOW_NEW_CONVERSATION_COUNT) {
    return {
      ...state,
      newConversationCount: action.payload.item,
    };
  }
  if (action.type === SET_SELECTED_CONVERSATION) {
    return {
      ...state,
      selectedConversation: action.payload,
    };
  }
  if (action.type === SET_SCHOOL_LOGO) {
    return {
      ...state,
      logoURL: action.payload,
    };
  } else {
    return state;
  }
};
