import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle,
} from '../../resources/styling/otherSuggestionStyle';
import { useTranslation } from 'react-i18next';

const ChangeSuggestion = () => {
  const { t } = useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle>
          <h2>Further actions to address risks to your management standards</h2>
          <h3>6. Change</h3>

          <ManagementStandardStyle>
            <li>
              Make sure there are sufficient opportunities given to staff to
              question leaders whenever you make change. This usually needs very
              explicit and regular communication as often very busy staff will
              not see the communication about change that is given to them.
              <p>
                As well as overcommunicating, tell them when they have
                opportunities to ask questions and provide clear and specific
                answers. If you hold 1 to 1s, meetings or briefings then always
                say, this is your opportunity to ask questions about the change
                being discussed.
              </p>
              <p>
                Then make sure questions are asked and if there aren't any
                forthcoming have some prepared and answer these. You might also
                create opportunities for more informal drop in or other
                sessions.
              </p>
            </li>
            <li>
              Change management requires a clear communication plan and
              following an established process helps if you want staff to feel
              they have been consulted and that they understand how the change
              will work out in practice.
              <p>
                The most well-known and effective change management process is
                that developed by John Kotter, the leading expert in this area.
                It covers eight specific steps to follow and it is often a
                surprise given the amount and rate of change in schools that few
                leaders have had training in this important area (from our
                experience).
              </p>
              <p>
                We can provide details of Kotter’s approach and training if this
                is of interest.
              </p>
              <p className='sub-heading'>Leadership {t('behvioursCaptial')}</p>
              <p>
                A big part of staff wellbeing relates to the {t('behviour')} of
                {t('linemanagers')} and research has identified twelve
                leadership competencies that have been proven to prevent and
                reduce stress in staff. In addition to the actions above and if
                you haven’t already, we would encourage your leaders and{' '}
                {t('linemanagers')} to undertake the self-assessment
                questionnaires relating to the twelve competencies identified.
                Or these can be used as a further whole staff questionnaire.
              </p>
              <p>
                This will help with the recommended actions and these are
                available through the on-line platform you have access to.
              </p>
              <p>
                As these are undertaken you could discuss as a leadership team
                and identify key {t('behviour')}s and actions that will support
                you in further improving the wellbeing of staff (with all its
                associated cost and performance benefits). We believe it is the
                actions and {t('behviours')} of {t('linemanagers')} that will
                have the most immediate and biggest impact.
              </p>
            </li>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default ChangeSuggestion;
