export const limitScoreValue = result => {
  const D1st = result.D1st.toFixed(2);
  const D2nd = result.D2nd.toFixed(2);
  const D3rd = result.D3rd.toFixed(2);
  const D4th = result.D4th.toFixed(2);
  const D5th = result.D5th.toFixed(2);
  const D6th = result.D6th.toFixed(2);
  const D7th = result.D7th.toFixed(2);
  const D8th = result.D8th.toFixed(2);
  const D9th = result.D9th.toFixed(2);
  const D10th = result.D10th.toFixed(2);

  return {
    D1st,
    D2nd,
    D3rd,
    D4th,
    D5th,
    D6th,
    D7th,
    D8th,
    D9th,
    D10th
  };
};
export const limitAverageValue = value => {
  if (value === 'N/A') return value;
  if (value === null || value === undefined) return '';

  var parsedValue = parseFloat(value);
  const result = parsedValue.toFixed(2);
  return result;
};
export const notAvailableValue = value => {
  return value === '0.00' ? 'N/A' : value;
};
export const scoreNotAvailableValue = value => {
  return value === '0.00' ? '-1.00' : value;
};
