import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import {
  schoolService,
  storageService,
  schoolSubscriptionService,
  surveyService,
} from '../../services';
import {
  WebStorageNames,
  QueryStringTokens,
  SurveyTypes,
} from '../../utils/Constants';
import { dashboardService } from '../../services';
import SingleSurveyDashboard from './SingleSurveyDashboard';
import MultiSurveyDashboard from './MultiSurveyDashboard';
import {
  amplitudeLog,
  getQueryStringVal,
  getTransformedSurveyRecordList,
} from '../../utils/CommonFunctions';
import { firstLoginAction } from '../../store/userStore';
import { routesObj } from '../../routes';
import GuidedTourPro from '../../components/common/GuidedTourPro';
import GuidedTourPremium from '../../components/common/GuidedTourPremium';
import AccessLevel from '../../components/common/AccessLevel';
import {
  AccessPermissions,
  Roles
} from '../../utils/Constants';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: '',
      strongestScore: [{}],
      weakestScore: [{}],
      activeSurveyDetails: '',
      comments: [],
      commentsLoader: false,
      IsMultiSurvey: false,
      welbeeType: false,
      isFirstLogin: false,
      accessLevel: 0,
      highestScore: 0,
      schoolSubscription: '',
      surveyOptions: [{ value: SurveyTypes.Any, label: 'Latest survey' }],
    };
  }

  setSubscription = (schoolId) => {
    schoolService.getSchoolSubscriptions(schoolId).then((response) => {
      if (response.success) {
        this.setState({
          schoolSubscription: response.data.SubscriptionId,
        });
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );
      }
    });
  };

  getDashboardTiles = (schoolToken, surveyToken) => {
    const { loaderActions, navigationActions } = this.props;
    dashboardService
      .getDashoboardItemAction(schoolToken, surveyToken)
      .then((response) => {
        if (response.success) {
          loaderActions.dataLoading(false);
          const previousSurveyToken = storageService.getItem(
            WebStorageNames.SurveyToken
          );

          const obj = {
            schoolName: response.data.schoolName,
            surveyToken: response.data.activeSurveyDetails.surveyToken,
            schoolToken,
          };
          storageService.setItem(`cache_${response.data.schoolName}`, obj);

          if (
            previousSurveyToken !==
            response.data.activeSurveyDetails.surveyToken
          ) {
            storageService.removeItem(WebStorageNames.SurveyInProgress);
          }
          storageService.setItem(
            WebStorageNames.SurveyToken,
            response.data.activeSurveyDetails.surveyToken
          );
          storageService.setItem(
            WebStorageNames.WelbeeType,
            response.data.welbeeType
          );
          navigationActions.setSchoolLogoUrl(response.data.logoUrl);
          storageService.setItem(
            WebStorageNames.schoolLogoUrl,
            response.data.logoUrl
          );
          this.setState({
            schoolName: response.data.schoolName,
            welbeeType: response.data.welbeeType,
            strongestScore: response.data.strongestScore,
            weakestScore: response.data.weakestScore,
            surveyResultCategory: response.data.surveyResultCategory,
            activeSurveyDetails: response.data.activeSurveyDetails,
            IsMultiSurvey: response.data.IsMultiSurvey,
            ProgressGraphData: response.data.ProgressGraphData,
            actionResults: response.data.actionResults,
            isFirstLogin: response.data.isFirstLogin,
            highestScore: response.data.highestScore,
            hasAdditionalQuestions: response.data.hasAdditionalQuestions,
          });
        } else {
          navigationActions.setSchoolLogoUrl(response.data.logoUrl);
          storageService.setItem(
            WebStorageNames.schoolLogoUrl,
            response.data.logoUrl
          );
          storageService.removeItem(WebStorageNames.SurveyToken);

          const obj = {
            schoolName: response.data.schoolName,
            surveyToken: null,
            schoolToken,
          };
          storageService.setItem(`cache_${response.data.schoolName}`, obj);

          this.setState({
            schoolName: response.data.schoolName,
            welbeeType: response.data.welbeeType,
            strongestScore: response.data.strongestScore,
            weakestScore: response.data.weakestScore,
            activeSurveyDetails: response.data.activeSurveyDetails,
            IsMultiSurvey: response.data.IsMultiSurvey,
            actionResults: response.data.actionResults,
            isFirstLogin: response.data.isFirstLogin,
            highestScore: response.data.highestScore,
            hasAdditionalQuestions: response.data.hasAdditionalQuestions,
          });
          loaderActions.dataLoading(false);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  getSurveyOptions = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    surveyService
      .getSurveyNameAndTokenBySchool(schoolId)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        this.setState({ surveyOptions: surveyRecordList });

        const token = window.location.search;
        var selectedRecord = surveyRecordList.filter(
          (item) => '?token=' + item.value === token
        );
        if (selectedRecord.length > 0) {
          this.setState({ surveyOptions: selectedRecord[0] });
        }
        if (surveyRecordList.length > 0) {
          this.getDashboardTiles(
            schoolToken,
            surveyRecordList[surveyRecordList.length - 1].value
          );
        } else {
          this.getDashboardTiles(schoolToken, null);
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    let schoolId = '';
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        schoolId = response.data.Id;
        //setSubscription(schoolId); No need for this because we already have subscriptionId in below call
        schoolSubscriptionService
          .getSchoolSubscriptionsById(schoolId)
          .then((response) => {
            this.setState({
              schoolSubscription: response.data.SubscriptionId,
            });
            //Code refactoring, no need of calling setSubscription in above, just use the code of this method in here
            storageService.setItem(
              WebStorageNames.SubscriptionId,
              response.data.SubscriptionId
            );
          })
          .catch((error) => console.log(error));
      });
    } else {
      const schoolId = storageService.getItem(WebStorageNames.SchoolId);
      this.setSubscription(schoolId);
    }

    const { loaderActions, navigationActions } = this.props;
    navigationActions.activeItemAction({
      item: 'dashboard',
    });
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    storageService.setItem(WebStorageNames.MenuType, 'Survey');
    this.setState({ commentsLoader: true, accessLevel: accessLevel });
    loaderActions.dataLoading(true);

    this.getSurveyOptions();

    dashboardService
      .getDashoboardCommentsAction(schoolToken)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          this.setState({
            comments:
              data.surveyComments.DashboardComments !== undefined
                ? data.surveyComments.DashboardComments
                : [],
            commentsLoader: false,
          });
          this.props.navigationActions.showNewCommentCount({
            item: response.data.surveyComments.UnReadCount,
          });
        }
      })
      .catch((error) => {
        this.setState({ commentsLoader: false });
      });

    amplitudeLog('PageView', 'Dashboard', '');
  }

  onCloseWelcomePopup = () => {
    dashboardService
      .firstTimeSurveyLoggedInSuccessful()
      .then((response) => {
        const { success } = response;
        if (success) {
          this.setState({ isFirstLogin: false });
          storageService.setItem(WebStorageNames.IsFirstLogin, false);
        }
      })
      .catch((error) => {
        this.setState({ commentsLoader: false });
      });
  };
  onLearnMoreScreen = () => {
    this.onCloseWelcomePopup();
  };
  onWelbeeVoiceScreen = () => {
    this.props.history.push(routesObj.WelbeeVoice.path);
  };
  onUploadStaffClick = () => {
    this.props.userAction.doScroll(true);
    this.props.history.push(routesObj.Account.path);
  };

  onSurveyChange = (surveyToken) => {
    this.getDashboardTiles(null, surveyToken);
  };
  render() {
    const { isFirstLogin, IsMultiSurvey, schoolSubscription, surveyOptions } =
      this.state;
    return IsMultiSurvey ? (
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View, AccessPermissions.Admin]} >

        <div>
          <MultiSurveyDashboard
            data={this.state}
            actions={this.props}
            isFirstLogin={isFirstLogin}
            isOpen={isFirstLogin}
            onClose={this.onCloseWelcomePopup}
            onOk={this.onLearnMoreScreen}
            onWelbeeVoiceOK={this.onWelbeeVoiceScreen}
            onUploadStaffClick={this.onUploadStaffClick}
            onSurveyChange={this.onSurveyChange}
            surveyOptions={surveyOptions}
          />

          {isFirstLogin ? (
            schoolSubscription === 5 ? (
              <GuidedTourPremium />
            ) : (
              <GuidedTourPro />
            )
          ) : null}
        </div>
      </AccessLevel>
    ) : (
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View, AccessPermissions.Admin]} >
        <div>
          <SingleSurveyDashboard
            data={this.state}
            actions={this.props}
            isFirstLogin={isFirstLogin}
            isOpen={isFirstLogin}
            onClose={this.onCloseWelcomePopup}
            onOk={this.onLearnMoreScreen}
            onWelbeeVoiceOK={this.onWelbeeVoiceScreen}
            onUploadStaffClick={this.onUploadStaffClick}
            onSurveyChange={this.onSurveyChange}
            surveyOptions={surveyOptions}
          />
          {isFirstLogin ? (
            schoolSubscription === 5 ? (
              <GuidedTourPremium />
            ) : (
              <GuidedTourPro />
            )
          ) : null}
        </div>
      </AccessLevel>

    );
  }
}
const mapStateToProps = ({ header: { newCommentCount } }) => {
  return {
    newCommentCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(dashboardService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
