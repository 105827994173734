import request from '../request';
import { headTeacherDashboardAPI, matGroupAPI, matDashboardAPI } from './urls';
import { storageService } from '../../services';
//////////// USER ACTIONS ///////////////

export const getDashoboardItemAction = (
  schoolToken = null,
  surveyToken = null
) => {
  let schoolId = storageService.getItem('schoolId');
  schoolToken = schoolToken === null ? '' : schoolToken;
  surveyToken = surveyToken === null ? '' : surveyToken;
  return request({
    url: headTeacherDashboardAPI.api(schoolId, schoolToken, surveyToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getMatDashoboardItem = (
  token = null,
  matGroupId,
  matCycleId,
  isSurveyClosed
) => {
  token = token === null ? '' : token;
  return request({
    url: matDashboardAPI.getMatDashboard(
      token,
      matGroupId,
      matCycleId,
      isSurveyClosed
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getDashoboardCommentsAction = (schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  let schoolId = storageService.getItem('schoolId');
  return request({
    url: headTeacherDashboardAPI.getComments(schoolId, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatGroupSchoolName = (token = null) => {
  token = token === null ? '' : token;
  return request({
    url: matGroupAPI.getMatGroupSchoolName(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getMatSurveySchoolDashoboard = (
  token = null,
  matGroupId,
  matCycleId
) => {
  token = token === null ? '' : token;
  return request({
    url: matDashboardAPI.getSchoolSurveyInfoDashboard(
      token,
      matGroupId,
      matCycleId
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getDashoboardCategoryDetail = (token) => {
  return request({
    url: headTeacherDashboardAPI.getCategoryReportDeatil(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getDashoboardCategoryProgressReport = (categoryId) => {
  let schoolId = storageService.getItem('schoolId');
  return request({
    url: headTeacherDashboardAPI.GetCategoryGraphReport(schoolId, categoryId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const manuallySendSurvey = (token) => {
  return request({
    url: headTeacherDashboardAPI.manuallySendSurvey(token),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const firstTimeSurveyLoggedInSuccessful = () => {
  let schoolId = storageService.getItem('schoolId');
  return request({
    url: headTeacherDashboardAPI.firstTimeSurveyLoggedInSuccessful(schoolId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((response) => response);
};
const GetPDFManagementStandardReport = (surveyToken, categoryId) => {
  return request({
    url: headTeacherDashboardAPI.GetPDFManagementStandardReport(
      surveyToken,
      categoryId
    ),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};
const dashboardService = {
  getDashoboardItemAction,
  getDashoboardCommentsAction,
  getDashoboardCategoryDetail,
  getDashoboardCategoryProgressReport,
  manuallySendSurvey,
  firstTimeSurveyLoggedInSuccessful,
  getMatGroupSchoolName,
  getMatSurveySchoolDashoboard,
  getMatDashoboardItem,
  GetPDFManagementStandardReport,
};

export default dashboardService;
