import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import Axios, { CancelToken } from 'axios';
import { schoolAPI } from '../../services/api/urls';
import { AutoCompleteWrapper } from './SchoolRegisterStyle'; 
import { FloatingInputLabel } from '../../components';
import { withTranslation } from 'react-i18next';

let cancel;
export class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.getAddressSuggestionsThrottled = debounce(
      500,
      this.getAddressSuggestions
    );
  }

  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired,
    onValueChange: PropTypes.instanceOf(Function),
  };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: '',
    options: '',
  };

  getAddressSuggestions = () => {
    const keyword = this.state.userInput;
    if (cancel !== undefined) {
      cancel();
    }

    Axios.get(schoolAPI.getAddressSuggestions(keyword), {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        const suggestions = response.data.data;
        if (suggestions != null) {
          this.setState({ addressSuggestionList: suggestions });

          const filteredOptions = suggestions;

          this.setState({
            activeOption: 0,
            filteredOptions,
            showOptions: true,
          });
        } else {
          this.setState({
            addressSuggestionList: [],
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
          });
        }
      })
      .catch((error) => {});
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ userInput: nextProps.value });
    if (nextProps.options.length !== this.props.options.length) {
      this.setState({ options: nextProps.options, showOptions: true });
    }
  }

  onChange = (e) => {
    const userInput = e.currentTarget.value;

    if (userInput.length > 3) {
      this.getAddressSuggestionsThrottled(userInput);
    } else {
      this.setState({ filteredOptions: [] });
    }
    this.props.onValueChange(e, e.currentTarget.value);
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText,
    });

    this.props.onValueChange(e, e.currentTarget.innerText);
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions, showOptions } = this.state;

    if (!showOptions) return;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption],
      });

      this.props.onValueChange(e, filteredOptions[activeOption]);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        console.log(activeOption);
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  render() {
    let {t}= this.props;
    const {
      onChange,
      onClick,
      onKeyDown,

      state: { activeOption, filteredOptions, showOptions, userInput },
    } = this;

    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <ul className='options'>
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              }
              return (
                <li className={className} key={optionName} onClick={onClick}>
                  {optionName}
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
          <div className='no-options'>
            <em>No Option!</em>
          </div>
        );
      }
    }
    return (
      <AutoCompleteWrapper>
        <div className='search floating-label'>
            <FloatingInputLabel
            label={t('zip_code')}
            placeholder=''
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          />
        </div>
        {optionList}
      </AutoCompleteWrapper>
    );
  }
}

export default withTranslation()(Autocomplete);
