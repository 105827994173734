import React from 'react';
import { SurveyGreetingPresentationStyle } from '../SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
import {SchoolPPTText} from '../../../utils/CommonFunctions';
import {ManagmentStandards } from '../../../utils/Constants';


const getHeatmapCellMd = (level, value, isDecile) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {value >= 1 ? (
        <>
          {' '}
          {value.toFixed(2)}
          <span className='score-superscript'>
            ({level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
            )
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
const ManagementStandard = (props) => {
  const pptText = SchoolPPTText(props?.region , ManagmentStandards.All);
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>{props.title}</h1>
          <div id='score-job'>
            <p className='section'>
                {pptText}
            </p>
          
            <p className='table-title'></p>
            <div className='section'>
              <div className='job-role-container'>
                <div style={{ width: '25%', float: 'left' }}>&nbsp;</div>
                <div className='stats-role-cell'>Demands</div>
                <div className='stats-role-cell'>Control</div>
                <div className='stats-role-cell'>Manager Support</div>
                <div className='stats-role-cell'>Peer Support</div>
                <div className='stats-role-cell'>Relationships</div>
                <div className='stats-role-cell'>Role</div>
                <div className='stats-role-cell'>Change</div>
              </div>
              <div style={{ width: '100%' }}>
                <div className='job-role-container'>
                  <div
                    style={{ width: '25%', marginLeft: '0px' }}
                    className='job-role-title-cell'
                  >
                    {props.SchoolName}
                  </div>
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.DemandSDecile,
                    props.SchoolHeatMap.Demand,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.ControlSDecile,
                    props.SchoolHeatMap.Control,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.ManagerSupportSDecile,
                    props.SchoolHeatMap.ManagerSupport,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.PeerSupportSDecile,
                    props.SchoolHeatMap.PeerSupport,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.RelationshipsSDecile,
                    props.SchoolHeatMap.Relationships,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.RoleSDecile,
                    props.SchoolHeatMap.Role,
                    false
                  )}
                  {getHeatmapCellMd(
                    props.SchoolHeatMap.ChangeSDecile,
                    props.SchoolHeatMap.Change,
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default ManagementStandard;
