import React from 'react';
import { HeadTeacherAccess } from '../../utils';

const DropDownOptionsWithButton = ({ options, onChange, accessLevel }) => {
  return (
    <div className='options action-plan-options'>
      {options.map((category) => {
        return (
          <div className='suggested-action-list-main'>
            <div className='action-category-heading'>
              {category.SuggestedActionCategory}
            </div>
            {category.SuggestedActions.map((option) => {
              return (
                option.value !== -1 && (
                  <span
                    key={option.value}
                    onClick={() => {
                      if (accessLevel === HeadTeacherAccess.Full)
                        onChange(option);
                    }}
                    className='action-plan-detail'
                    style={{
                      cursor:
                        accessLevel === HeadTeacherAccess.Full
                          ? 'cursor'
                          : 'normal',
                    }}
                  >
                    {option.Title}
                    {accessLevel === HeadTeacherAccess.Full && (
                      <i className='add-action-plan'>Add to your plan</i>
                    )}
                  </span>
                )
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DropDownOptionsWithButton;
