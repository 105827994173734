import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  customStyles,
  ErrorWrapper,
  StaffWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { filterLibraryService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { StaffJobRoles } from '../../utils/Constants';

const { purple } = colors;

const EditFilter = (props) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [department, setDepartment] = useState('');
  const [phaseYear, setPhaseYear] = useState('');
  const [isApproved, setIsApproved] = useState(true);
  const [secondFilter, setSecondFilter] = useState('')

  useEffect(() => {
    initialValues(props.filterId);
  }, []);

  const initialValues = (id) => {
    filterLibraryService
      .getById(id)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setJobTitle(data.KeyWord);
          setJobRole(data.JobRole);
          setDepartment(data.Department);
          setPhaseYear(data.PhaseYear);
          setIsApproved(data.IsApproved);
          setSecondFilter(data.SecondFilter && data.SecondFilter.KeyWord)
        }
      })
      .catch((error) => { });
  };

  const handleJobRoleChange = (e, v) => {
    setJobRole(v);
  };

  const handleSecondFilterChange = (e, v) => {
    setSecondFilter(v);
  };

  const jobRoleSuggestions =
    StaffJobRoles !== null
      ? StaffJobRoles.sort((a, b) => (a.title > b.title ? 1 : -1)).map(
        (item, i) => {
          return {
            title: item.title === null ? 'None' : item.title,
            key: item.id,
          };
        }
      )
      : null;

  const secondFilterSuggestions = [
    { title: 'Head of Deparment', key: 1 },

  ];


  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: props.filterId !== null ? props.filterId : '',
        jobTitle: jobTitle !== null ? jobTitle : '',
        jobRole: jobRole !== null ? jobRole : '',
        department: department !== null ? department : '',
        phaseYear: phaseYear !== null ? phaseYear : '',
        isApproved: isApproved ? true : false,
        secondFilter: secondFilter !== null ? secondFilter : '',

      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        jobTitle: Yup.string().trim().required('Job Title is required.'),
        jobRole: Yup.string().trim().required('Job Role is required.'),
      })}
      onSubmit={(values, formProps) => {
        setBtnDisabled(true);
        let otherFilter = ''
        if (values.secondFilter) {
          otherFilter = {
            KeyWord: values.secondFilter
          };
        }
        let model = {
          Id: values.id,
          KeyWord: values.jobTitle,
          JobRole: values.jobRole,
          Department: values.department,
          PhaseYear: values.phaseYear,
          IsApproved: values.isApproved,
          SecondFilter: otherFilter,
        };
        filterLibraryService
          .saveFilter(model)
          .then((response) => {
            setBtnDisabled(false);
            if (response.success) {
              toast.success('Record Updated successfully.');
              props.handleClose();
              props.getFilterLibrary();
            } else if (
              response.errors.KeyWord[0] === 'job title already exist'
            ) {
              formProps.setErrors({ jobTitle: 'job title already exist' });
            } else {
              toast.error('Record not added. Please try again later.');
            }
          })
          .catch((error) => {
            setBtnDisabled(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        const { handleClose } = props;
        return (
          <Grid item xs={12}>
            <AppModel
              onClose={handleClose}
              styles={customStyles}
              open={true}
              titleText={'Update Filter'}
              bodyText={
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '25px' }}>
                    Update your existing Filter.
                  </p>
                  <Box
                    component='form'
                    sx={{
                      '& .MuiTextField-root': { width: '100%' },
                    }}
                    autoComplete='off'
                  >
                    <StaffWrapper>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='jobTitle'
                            id='job-title'
                            value={jobTitle}
                            label='Job Title'
                            onChange={(e) => {
                              setFieldValue('jobTitle', e.target.value);
                              setJobTitle(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.jobTitle && errors.jobTitle}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              name='jobRole'
                              value={jobRole}
                              id='core-jobRole'
                              options={[
                                ...new Set(
                                  jobRoleSuggestions.map((opt) => opt.title)
                                ),
                              ]}
                              onChange={(e, v) => {
                                setFieldValue('jobRole', v);
                                handleJobRoleChange(e, v);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField {...params} label={'Job Role'} />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue);
                                const parts = parse(option, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                            <ErrorWrapper>
                              {touched.jobRole && errors.jobRole}
                            </ErrorWrapper>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='department'
                            value={department}
                            id='department'
                            label='Department'
                            onChange={(e) => {
                              setFieldValue('department', e.target.value);
                              setDepartment(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.department && errors.department}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='phaseYear'
                            value={phaseYear}
                            id='phaseYear'
                            label='PhaseYear'
                            onChange={(e) => {
                              setFieldValue('phaseYear', e.target.value);
                              setPhaseYear(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.phaseYear && errors.phaseYear}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              name='secondFilter'
                              value={secondFilter}
                              id='core-secondFilter'
                              options={[
                                ...new Set(
                                  secondFilterSuggestions.map((opt) => opt.title)
                                ),
                              ]}
                              onChange={(e, v) => {
                                setFieldValue('secondFilter', v);
                                handleSecondFilterChange(e, v);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField {...params} label={'Second Filter'} />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue);
                                const parts = parse(option, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                            <ErrorWrapper>
                              {touched.secondFilter && errors.secondFilter}
                            </ErrorWrapper>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label='Is Approved'
                              checked={isApproved}
                              name='isApproved'
                              onChange={(e) => {
                                setFieldValue('isApproved', e.target.checked);
                                setIsApproved(e.target.checked);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      <AppButton
                        color={purple}
                        type='button'
                        onClick={handleSubmit}
                        style={{ marginTop: '20px' }}
                      >
                        {btnDisabled ? 'Saving...' : 'Save'}
                      </AppButton>
                    </StaffWrapper>
                  </Box>
                </div>
              }
            />
          </Grid>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(EditFilter);
