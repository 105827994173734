import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel, FloatingInputLabel } from '../../../components';
import {
  AppButton,
  AssitanceForm,
  ErrorWrapper,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInner,
  NewSurveyInnerMain,
  NewSurveyWrapper
} from '../../../resources/styling';
import { WrapperMain } from '../../../resources/styling/formStyle';
import { colors } from '../../../resources/theme/colors';
import { matCycleService, storageService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { WebStorageNames } from '../../../utils/Constants';
import { InputWrapper } from './MatCycleStyle';

const { outrageousOrange } = colors;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCycle = (props) => {
  useEffect(() => {
    if (props.isOpen) {
      initialValues();
    }
  }, [props.isOpen]);

  const [startDate, setStartDate] = useState(new Date());
  const [cycleName, setCycleName] = useState('');
  const [schools, setSchools] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [matGroupId] = useState(
    storageService.getItem(WebStorageNames.MatGroupId)
  );

  const schoolItems =
    props.schoolsList !== null
      ? props.schoolsList.map((item) => (
        <MenuItem key={item.Id} value={item.Name}>
          {item.Name}
        </MenuItem>
      ))
      : null;

  const handleSchoolChange = (event) => {
    const {
      target: { value },
    } = event;
    setSchools(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const initialValues = () => {
    setCycleName('');
    setStartDate(new Date());
    setSchools([]);
    setDisable(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Id: 0,
        MatGroupId: matGroupId !== null ? matGroupId : '',
        Name: cycleName !== null ? cycleName : '',
        Schools: schools !== null ? schools : [],
        StartDate: startDate !== null ? startDate : '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Name: Yup.string().required('name is required.').nullable(),
        Schools: Yup.array().min(1, 'schools are required.'),
        Name: Yup.string().required('name is required.').nullable(),
        StartDate: Yup.string().required('start date is required.').nullable(),
      })}
      onSubmit={(values, formProps) => {
        setDisable(true);

        let selectedSchools = values.Schools.map((data, i) => {
          return props.schoolsList.find((element) => element.Name === data);
        });

        let matCycleSchools = selectedSchools.map((item) => ({
          SchoolId: item.Id,
          IsIncluded: true,
        }));

        let model = {
          Id: values.Id,
          Name: values.Name,
          StartDate: values.StartDate,
          MatGroupId: values.MatGroupId,
          MatCycleSchools: matCycleSchools,
        };
        matCycleService
          .saveCycle(model)
          .then((response) => {
            if (response.success) {
              formProps.resetForm();
              toast.success('cycle added successfully.');
              props.handleSubmit();
              initialValues();
            } else {
              toast.error('cycle not added. Please try again later.');
              setDisable(false);
            }
          })
          .catch(() => {
            setDisable(false);
          });
      }}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div>
            <AppModel
              open={props.isOpen}
              onClose={props.onClose}
              titleText='Add Cycle'
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        mt='15px'
                        className='form-column-wrapper'
                      >
                        <InputWrapper>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Name'
                              autoComplete='nope'
                              name='Name'
                              value={values.Name}
                              onChange={(e) => {
                                setFieldValue('Name', e.target.value);
                                setCycleName(e.target.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.Name && errors.Name}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <FormControl sx={{ width: '100%' }}>
                              <InputLabel id='mat-school-lblId'>
                                Schools
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId='mat-school-lblId'
                                id='mat-school'
                                name='Schools'
                                multiple
                                value={schools}
                                onChange={(e) => {
                                  setFieldValue(
                                    'Schools',
                                    typeof e.target.value === 'string'
                                      ? e.target.value.split(',')
                                      : e.target.value
                                  );
                                  handleSchoolChange(e);
                                }}
                                input={
                                  <OutlinedInput
                                    id='select-multiple-chip'
                                    label='Chip'
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {schoolItems}
                              </Select>
                              <ErrorWrapper>
                                <div className='error-school'>
                                  {touched.Schools && errors.Schools}
                                </div>
                              </ErrorWrapper>
                            </FormControl>
                          </WrapperMain>

                          <WrapperMain>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                            >
                              <Box />
                              <DatePicker
                                label='Start Date'
                                inputFormat='dd-MM-yyyy'
                                value={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ width: 1 }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <ErrorWrapper>
                              {touched.StartDate && errors.StartDate}
                            </ErrorWrapper>
                          </WrapperMain>
                          <AppButton
                            style={{
                              cursor: disabled
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            disabled={disabled}
                            color={outrageousOrange}
                            type='submit'
                            onClick={handleSubmit}
                          >
                            {disabled ? 'Saving...' : 'Save'}
                          </AppButton>
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={<FooterButtonsWrapper></FooterButtonsWrapper>}
            />
          </div>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(AddCycle);
