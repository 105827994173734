import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { routesObj } from '../../routes';
import { storageService } from '../../services';
import {
  WebStorageNames,
  QueryStringTokens,
  Roles,
} from '../../utils/Constants';
import { navigationActions } from '../../store/headerStore';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import Comments from './Comments';
import { DashboardCommentsWrapper } from '../../resources/styling/appStyle';
import { display } from '@mui/system';

class DashboardComments extends Component {
  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(e) {
    this.props.navigationActions.activeItemAction({
      item: 'comments',
    });
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      this.props.history.push(
        routesObj.SurveyComments.path +
          '?token=' +
          surveyToken +
          '&schoolToken=' +
          schoolToken
      );
    } else
      this.props.history.push(
        routesObj.SurveyComments.path + '?token=' + surveyToken
      );
    amplitudeLog('Dashboard', 'Comments', '');
  }
  render() {
    const { comments, newCommentCount, commentsLoader } = this.props;
    const allComments = comments.flat();
    const unreadComments = allComments.filter((x) => x.IsRead === false);
    return (
      newCommentCount > 0 ? (       
      <DashboardCommentsWrapper>
        <h2>Latest Comments</h2>
        <div className='comments-wrapper'>
          {commentsLoader ? (
            <div className='comment-loader'>
              <div id='loading' />
            </div>
          ) : (
            ''
          )}
          <h2>
            Latest Comments
            {comments && (
              <span>
                Showing {unreadComments.length} of {allComments.length}
              </span>
            )}
          </h2>
          {newCommentCount > 0 ? (
            <div className='new-dashboard-comments signle-dashboard-newmsg'>
              {' '}
              You have a new reply!{' '}
            </div>
          ) : (
            <></>
          )}
          <Scrollbars
            renderThumbVertical={(props) => (
              <div {...props} className='thumb-vertical' />
            )}
            className='scrollbar-view'
          >
            <div className='comments-outer'>
              <Comments
                comments={comments}
                noCommentsMessage='When staff complete the survey their comments will display here.'
              />
            </div>
          </Scrollbars>
          <div className='see-all-comments'>
            <button onClick={this.clickHandler}>See All Comments</button>
          </div>
        </div>
      </DashboardCommentsWrapper>  
      ) : (
        <></>
    ));
  }
}

const mapStateToProps = ({ header: { activeItem, newCommentCount } }) => ({
  activeItem,
  newCommentCount,
});

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardComments));
