import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, getQueryStringVal, getInvoicePrice } from '../../utils/CommonFunctions';
import { storageService, schoolService, accountService } from '../../services';
import SchoolRegisterWrapper from '../../components/common/SchoolRegisterWrapper';
import HeadTecherDetailForm from './UserDetailForm';
import RegisterAdminPassword from './RegisterAdminPassword';
import RegisterConfirmEmailSent from './RegisterConfirmEmailSent';
import RegisterFee from './RegisterFee';
import SchoolDetailForm from './SchoolDetailForm';
import { loaderAction } from '../../store/loaderStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SignUpStatus, WebStorageNames } from '../../utils/Constants';

function SurveyWithVoiceSchoolRegistration(props) {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showEmailSend, setEmailSend] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState(null);
    const [schoolToken, setSchoolToken] = useState('');
    const [headTeacherEmail, setHeadTeacherEmail] = useState(null);
    const [schoolId, setSchoolId] = useState(-1);
    const [
        isHeadTeacherDetailCompleted,
        setIsHeadTeacherDetailCompleted,
    ] = useState(false);
    const [isSchoolDetailCompleted, setIsSchoolDetailCompleted] = useState(false);
    const [isRegisterFeeCompleted, setIsRegisterFeeCompleted] = useState(false);
    const [isBackButtonEnable, setIsBackButtonEnable] = useState(false);
    const [isUSRegion, setisUSRegion] = useState(false);
    const [price, setPrice] = useState(247);

    useEffect(() => {
        amplitudeLog('PageView', 'Register_SchoolDetails', '');
        storageService.clear();
        const token = getQueryStringVal('token');
        const userId = getQueryStringVal('userid');
        getRegions();
        if (userId) {//incase user comes from the email verification
            let { loaderActions } = props;
            loaderActions.dataLoading(true);
            let response = accountService.VerifyEmailConfirmationToken(userId).then((response) => {
                loaderActions.dataLoading(false);
                if (response.success) {
                    setHeadTeacherEmail(response.data);
                    setIsHeadTeacherDetailCompleted(true);
                    setIsSchoolDetailCompleted(true);
                    setIsRegisterFeeCompleted(true);
                    setEmailSend(true);
                    if (response.message === "Email confirmed Already")
                        setIsEmailVerified(true);
                    setShowPassword(false);
                }
            }).catch((error) => loaderActions.dataLoading(false));
        }
        else {//incase of new registration
            setSchoolToken(token);
            if (token) {
                let { loaderActions } = props;
                loaderActions.dataLoading(true);
                schoolService
                    .getSchoolDetailByRegistrationToken(token)
                    .then((response) => {
                        loaderActions.dataLoading(false);
                        if (response.success) {
                            let schoolInfoObj = response.data;
                            setSchoolInfo(schoolInfoObj);
                            if (schoolInfoObj.SignUpStatus === SignUpStatus.Completed) {
                                setIsEmailVerified(true);
                                setHeadTeacherEmail(schoolInfoObj.Email);
                                setIsHeadTeacherDetailCompleted(true);
                                setIsSchoolDetailCompleted(true);
                                setIsRegisterFeeCompleted(true);
                                console.log(SignUpStatus.Completed);
                            }
                            // School details part of school registration is complete
                            if (schoolInfoObj.SignUpStatus === SignUpStatus.WelbeeHeadTeacherDetail) {
                                onHeadTeacherDetailFormCompletion(
                                    schoolInfoObj,
                                    schoolInfoObj.Id
                                );
                            }
                            if (schoolInfoObj.SignUpStatus === SignUpStatus.SchoolDetail) {
                                onSchoolDetailFormCompletion(schoolInfoObj, schoolInfoObj.Id);
                                setHeadTeacherEmail(schoolInfoObj.Email);
                            }
                            if (schoolInfoObj.SignUpStatus === SignUpStatus.WellbeeVoice) {
                                onSchoolDetailFormCompletion(schoolInfoObj, schoolInfoObj.Id);
                                setHeadTeacherEmail(schoolInfoObj.Email);
                            }

                            // School invoice part of school registration is complete
                            else if (schoolInfoObj.SignUpStatus === SignUpStatus.InVoiceDetail) {
                                setHeadTeacherEmail(schoolInfoObj.Email);
                                setSchoolId(schoolInfoObj.Id);
                                setIsHeadTeacherDetailCompleted(true);
                                setIsSchoolDetailCompleted(true);
                                setIsRegisterFeeCompleted(true);
                                setEmailSend(true);
                                setShowPassword(false);
                            }
                        }
                    })
                    .catch((error) => loaderActions.dataLoading(false));
            }
        }
    }, {});
    const getRegions = () => {
        accountService
          .checkCurrentRegion()
          .then((response) => {
            setisUSRegion(response.data==='us'? true : false);
            storageService.setItem(WebStorageNames.UsRegion, response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };

    const onSchoolDetailFormCompletion = (schoolInfoObj, schoolId) => {
        setSchoolInfo(schoolInfoObj);
        calculateTotalPrice(schoolInfoObj.NumberOfPupil, schoolInfoObj.SchoolSubscription.SubscriptionId);
        setSchoolId(schoolId);
        setIsHeadTeacherDetailCompleted(true);
        setIsSchoolDetailCompleted(true);
        setIsBackButtonEnable(true);
        if(isUSRegion)
        {
            setIsRegisterFeeCompleted(true);
        }
    };

    const onHeadTeacherDetailFormCompletion = (schoolInfoObj, schoolId) => {
        if (schoolInfoObj.Email) setHeadTeacherEmail(schoolInfoObj.Email);
        setSchoolInfo(schoolInfoObj);
        setSchoolId(schoolId);
        setIsHeadTeacherDetailCompleted(true);
        setIsBackButtonEnable(true);
    };

    const onInvoiceFormCompletion = () => {
        setIsRegisterFeeCompleted(true);
        setIsBackButtonEnable(false);
    };

    const calculateTotalPrice = (numberOfPupil, subscriptionId) => {
        const price = getInvoicePrice(numberOfPupil, subscriptionId);

        setPrice(price);
    };

    const onBackButtonPress = () => {
        if (isHeadTeacherDetailCompleted && isSchoolDetailCompleted) {
            setIsSchoolDetailCompleted(false);
        } else if (isHeadTeacherDetailCompleted && !isSchoolDetailCompleted) {
            setIsHeadTeacherDetailCompleted(false);
            setIsBackButtonEnable(false);
        }
    };
    return (
        <SchoolRegisterWrapper
            onBack={isBackButtonEnable ? onBackButtonPress : null}
        >
            {!isHeadTeacherDetailCompleted ? (
                <HeadTecherDetailForm
                    onCompletion={onHeadTeacherDetailFormCompletion}
                    schoolId={schoolId}
                    SchoolInfo={schoolInfo}
                />
            ) : !isSchoolDetailCompleted ? (
                <SchoolDetailForm
                    onCompletion={onSchoolDetailFormCompletion}
                    schoolId={schoolId}
                    SchoolInfo={schoolInfo}
                />
            ) : !isRegisterFeeCompleted && isUSRegion===false ? (
                <RegisterFee
                    schoolName={schoolInfo ? schoolInfo.Name : ''}
                    SchoolInfo={schoolInfo}
                    schoolId={schoolId}
                    price={price}
                    onCompletion={onInvoiceFormCompletion}
                />
            ) : !showEmailSend ? (
                <RegisterConfirmEmailSent
                    Email={headTeacherEmail} AccType='S'
                />) : showPassword ? (<div>Invalid request</div>) : (<RegisterAdminPassword
                    Email={headTeacherEmail}
                    SchoolInfo={schoolInfo}
                    isEmailVerified={isEmailVerified}
                />
                )}
        </SchoolRegisterWrapper>
    );
}

const mapDispatchToProps = (dispatch) => ({
    loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(SurveyWithVoiceSchoolRegistration));
