import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, } from '../../utils/CommonFunctions';
import { AppButton, LoginFormWrapper, ErrorWrapper, CheckBox } from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { AppInputField } from '../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { storageService, schoolService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { LoginFormInner, InputWrapper, FieldWrapper } from '../../components/common/loginStyle';
import { WrapperMain } from '../../resources/styling/formStyle';
import { routesObj } from '../../routes';
const { mandy, pictonBlue } = colors;

const style = {
  optionalLable: {
    textAlign: 'left',
    display: 'block',
    marginTop: '-10px',
    fontSize: '13px',
    color: pictonBlue,
    paddingLeft: '10px',
  },
};

function RegisterFee(props) {
  const [isInvoiceInfoChecked, setIsInvoiceInfoChecked] = useState(false);

  useEffect(() => {
    amplitudeLog('PageView', 'Register_Fee', '');
    storageService.clear();
  }, {});
  return (
    <Formik
      initialValues={{
        PurchaseOrderNo: '',
        InvoiceAddressName: '',
        InvoiceAddressEmail: '',
        InvoiceSent:false,
        Discount:0,
        SchoolId: props.schoolId,
        IsUpgradeRequest: false,
        UpgradeFrom:0,
        InvoiceType:0,
      }}
      onSubmit={(values, actions) => {
        props.loaderActions.dataLoading(true);

        setTimeout(() => {
          actions.setSubmitting(false);
          schoolService
            .updateSchoolInvoiceInfo(values)
            .then((response) => {
              const { success } = response;
              props.loaderActions.dataLoading(false);
              if (success) {
                props.onCompletion();
              }
            })
            .catch((error) => console.log(error));
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={
        isInvoiceInfoChecked
          ? Yup.object().shape({
              InvoiceAddressName: Yup.string().required(
                'Full name field is required.'
              ),
              InvoiceAddressEmail: Yup.string()
                .trim()
                .max(60)
                .email('Provide email address like example@g.com.')
                .required('Email is required.'),
            })
          : Yup.object().shape({})
      }
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        onBack,
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSubmit();
          }}
        >
          <LoginFormWrapper>
            <LoginFormInner>
              <h2>Price for {props.schoolName}</h2>
              <div className='fee-big-number'>
                <span>
                  &#xa3;{props.price} <i className='price-vat'>+VAT</i>
                </span>
              </div>
                          {(window.location.pathname === routesObj.SurveyPremiumUrl.path || window.location.pathname === routesObj.SurveyProUrl) ?

                              <p className='fee-detail'>
                                  This includes running your survey, in-depth analysis, benchmarks, filters, strengths, areas for focus,
                                  recommended actions and on-line dashboard. You can also use Welbee Voice (Pro) at no additional cost.
                                  As this is your first survey we also offer a full guarantee. If for any reason you do not get the value we promise from
                                  joining our community of schools then we will refund your investment.
                            </p> : (window.location.pathname === routesObj.VoiceProUrl.path ?

              <p className='fee-detail'>
                              This includes unlimited conversations, inviting groups of staff, as well as all staff, to take part, and multi-user access.
              </p> : '')}
              <p className='fee-detail'>
                              If you are ready to proceed then please click the button to continue, and, if you require a PO number (optional), 
                              then enter that below. We will send an invoice to your email address or to the one below.
              </p>
              <p className='fee-detail'>
                Thank you for allowing us to support you.
              </p>
              <InputWrapper>
                <WrapperMain>
                  <AppInputField
                    placeholder='Purchase order no.'
                    autoComplete='new-purchase-order'
                    type='text'
                    name='PurchaseOrderNo'
                    value={values.PurchaseOrderNo}
                    onChange={handleChange}
                  />
                  <span style={style.optionalLable}>optional</span>
                </WrapperMain>
                <WrapperMain>
                  <CheckBox>
                    <input
                      class='styled-checkbox'
                      id='styled-checkbox-1'
                      type='checkbox'
                      value='value1'
                      onChange={() =>
                        setIsInvoiceInfoChecked(!isInvoiceInfoChecked)
                      }
                    />
                    <label for='styled-checkbox-1'>
                      <span className='checkbox-wrapper'>
                        Please tick to use a different name and email for the
                        invoice.
                      </span>
                    </label>
                  </CheckBox>
                </WrapperMain>
                {isInvoiceInfoChecked ? (
                  <Fragment>
                    <WrapperMain className='admin-fields'>
                      <AppInputField
                        placeholder='Full name'
                        autoComplete='new-name'
                        type='text'
                        name='InvoiceAddressName'
                        value={values.InvoiceAddressName}
                        onChange={handleChange}
                      />
                      <ErrorWrapper>
                        {touched.InvoiceAddressName &&
                          errors.InvoiceAddressName &&
                          errors.InvoiceAddressName}
                      </ErrorWrapper>
                    </WrapperMain>
                    <WrapperMain>
                      <AppInputField
                        placeholder='Email'
                        autoComplete='new-email'
                        type='text'
                        name='InvoiceAddressEmail'
                        value={values.InvoiceAddressEmail}
                        onChange={handleChange}
                      />
                      <ErrorWrapper>
                        {touched.InvoiceAddressEmail &&
                          errors.InvoiceAddressEmail &&
                          errors.InvoiceAddressEmail}
                      </ErrorWrapper>
                    </WrapperMain>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                <FieldWrapper>
                  <Grid container>
                    <Grid item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      className='agree-button-wrapper'
                    >
                      <AppButton
                        color={mandy}
                        type='submit'
                        onClick={handleSubmit}
                      >
                        Agree to be invoiced
                      </AppButton>
                    </Grid>
                  </Grid>
                </FieldWrapper>
              </InputWrapper>
            </LoginFormInner>
          </LoginFormWrapper>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(RegisterFee));
