import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { storageService, actionPlanService } from '../../services';
import {
  WebStorageNames,
  CategoryList,
  HeadTeacherAccess,
  Roles,
  QueryStringTokens,
} from '../../utils';
import { routesObj } from '../../routes';
import ActionsPlanForm from '../actionPlan/ActionsPlanForm';
import { DateFormat, getQueryStringVal } from '../../utils/CommonFunctions';
import { ExtendedUpcomingTasksWrapper } from './DashboardStyle';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { toast } from 'react-toastify';

class AssociatedActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createActionFormDialogOpen: false,
      actionPlanData: [],
      assigneeData: [],
      emailData: [],
      accessLevel: 0,
    };
  }
  componentDidMount() {
    this.getAssociatedActions();
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
  }

  getAssociatedActions() {
    const queryString = window.location.search;
    const categoryId = parseInt(queryString.split('categoryId=')[1], 10);
    var categoryName = CategoryList.filter((x) => x.value === categoryId)[0]
      .label;
    const token = window.location.search.split('&categoryId=')[0];

    actionPlanService
      .getActionPlan(token)
      .then((response) => {
        if (response.success) {
          var rec = response.data;
          const filterRecord = rec.filter((x) => x.Category === categoryName);
          const updatedRecord = filterRecord.sort((a, b) => {
            return new Date(b.AddedDate) - new Date(a.AddedDate);
          });
          // get actions with unique email and assignee
          let uniqueActions = this.getUniqueActions(rec);

          const emailRec = uniqueActions
            .filter((x) => x.Email !== '' && x.Email !== null)
            .map((x) => {
              return { id: x.Id, name: x.Email };
            });
          const assigneeRec = uniqueActions
            .filter((x) => x.Assignee !== '' && x.Assignee !== null)
            .map((x) => {
              return { id: x.Id, name: x.Assignee };
            });

          this.setState({
            actionPlanData: updatedRecord,
            assigneeData: assigneeRec,
            emailData: emailRec,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  getUniqueActions(actionRecords) {
    let filteredActions = [];
    actionRecords.map((element) => {
      let existingRecords = filteredActions.filter(
        (item) =>
          (element.Email === item.Email) & (element.Assignee === item.Assignee)
      );
      if (existingRecords.length === 0) filteredActions.push(element);
    });

    return filteredActions;
  }

  handleCreateAction = () => {
    this.setState({
      createActionFormDialogOpen: true,
    });
  };

  onActionPlanClickHandler = (e) => {
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      this.props.history.push(
        routesObj.ActionPlan.path +
          '?token=' +
          surveyToken +
          '&schoolToken=' +
          schoolToken
      );
    } else
      this.props.history.push(
        routesObj.ActionPlan.path + '?token=' + surveyToken
      );

    e.preventDefault();
  };

  suggestedActionsCancel = (event) => {
    event.preventDefault();
    this.setState({ createActionFormDialogOpen: false });
  };

  handleAddSurveySubmit = (values) => {
    const tokenURl = window.location.search.split('&categoryId=')[0];
    values.token = tokenURl.split('?token=')[1];
    actionPlanService
      .addActionPlanAction(values)
      .then((response) => {
        this.setState({ createActionFormDialogOpen: false });
        toast.success('Action are added successfully.');
        this.getAssociatedActions();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const {
      createActionFormDialogOpen,
      actionPlanData,
      emailData,
      assigneeData,
      accessLevel,
    } = this.state;
    return (
      <div className='multi-survey-action'>
        <h3>
          Associated actions{' '}
          {accessLevel === HeadTeacherAccess.Full && (
            <button onClick={this.handleCreateAction} className='add-btn'>
              +
            </button>
          )}
        </h3>
        <ExtendedUpcomingTasksWrapper>
          <Scrollbars
            className='scrollbar-view'
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            {actionPlanData && actionPlanData.length > 0
              ? actionPlanData.slice(0, 5).map((action) => {
                  return (
                    <div className='task-list-wrapper'>
                      <div className='task-list'>
                        <span>{action.Title}</span>
                        <span className='dates'>
                          {DateFormat(action.DueDate)}
                        </span>
                      </div>
                    </div>
                  );
                })
              : accessLevel === HeadTeacherAccess.Full && (
                  <div class='action-plan-body plus-icon'>
                    Use the + icon to create your own actions to improve staff
                    wellbeing for this management standard.
                  </div>
                )}
          </Scrollbars>
        </ExtendedUpcomingTasksWrapper>
        {accessLevel === HeadTeacherAccess.Full && (
          <div className='action-footer'>
            <a href='#' onClick={this.onActionPlanClickHandler}>
              Read full action plan
            </a>
          </div>
        )}
        {createActionFormDialogOpen ? (
          <ActionsPlanForm
            title={'Create action'}
            submitButtonText={'Add Survey'}
            isOpen={createActionFormDialogOpen}
            onClose={this.suggestedActionsCancel}
            handleSubmit={this.handleAddSurveySubmit}
            emailRecord={emailData}
            assigneeRecord={assigneeData}
            actionPlanData={this.state.actionPlanData}
            className={'xyz'}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(AssociatedActions);
