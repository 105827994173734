import { storageService } from '../../services';
import { ContryRegion, WebStorageNames } from '../../utils/Constants';

const UsRegion = () => {
  const usRegion = storageService.getItem(WebStorageNames.UsRegion);
  return usRegion;
};

const USGroups = [
  { id: 'District', name: 'District' },
  { id: 'Group', name: 'Group' },
  { id: 'County', name: 'County' },
  { id: 'State', name: 'State' },
  { id: 'Other', name: 'Other' },
];
const UKGroups = [
  { id: 'Group', name: 'Group' },
  { id: 'MAT', name: 'MAT' },
  { id: 'Other', name: 'Other' },
];

const groups = () => {
  return UsRegion() === ContryRegion.usRegion ? USGroups : UKGroups;
};
export const getGroupNameCollection = () => groups();
