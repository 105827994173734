import React from 'react';
// import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ModalCustomStyles } from '../../resources/styling/appStyle';

const AppModel = ({ open, onClose, titleText, bodyText, footer }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        modal: 'customModal',
      }}
    >
      <Box>
        <h2 className='title'>{titleText}</h2>
        <div className='body'>{bodyText}</div>
        {footer}
      </Box>
    </Modal>
  );
};

export default AppModel;
