import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { LeftMenu } from '../../components';
import { colors } from '../../resources/theme';
import { toast } from 'react-toastify';
import { AppButton } from '../../resources/styling/appStyle';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { WelbeeTypes } from '../../utils/Constants';
import { AssistanceWrapper } from '../../components/common/ActionPlanStyle';
import { RegisterWrapper } from '../../resources/styling/appStyle';
import { WebStorageNames } from '../../utils';
import { schoolService, storageService } from '../../services';
import { amplitudeLog } from '../../utils/CommonFunctions';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { bindActionCreators } from 'redux';
import { navigationActions } from '../../store/headerStore';
import { HeaderWrapper, ExtendedActionPlanMainWrapper, ExtendedSubContainer } from './SchoolSubscriptionsStyle';

const { pictonBlue, mandy, mineShaft } = colors;

class SchoolSubscriptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schoolId: storageService.getItem(WebStorageNames.SchoolId),
            subscriptionId: -1
        };
    }

    componentDidMount() {
        const { loaderActions, navigationActions } = this.props;
        loaderActions.dataLoading(true);;
        if (this.state.schoolId) {
            schoolService
                .getSchoolSubscriptions(this.state.schoolId)
                .then((response) => {
                    loaderActions.dataLoading(false);
                    if (response.success) {
                        if (response.data != null) {
                            this.setState({
                                subscriptionId: response.data.SubscriptionId
                            });
                        }
                    }
                })
                .catch((error) => console.log(error));
        }
        amplitudeLog('PageView', 'Subscriptions', '');
    }
    onSubscribed(id) {
        if (this.state.schoolId) {
            schoolService
                .addSchoolSubscriptions(this.state.schoolId,id)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            subscriptionId: id
                        });
                        toast.success('Record added successfully.');
                    }
                    else
                        toast.error('Record not added. Please try again later.');
                })
                .catch((error) => console.log(error));
        } 
    }
    render() {
        const { SchoolRecord, welbeeType, accessLevel, isStaff, welbeeAccountType } = this.state;
        return (
            <ExtendedActionPlanMainWrapper>
                <RegisterWrapper>
                    <div className='containerClass'>
                        
                            <div className='left-container'>
                                <LeftMenu />
                            </div>

                            <div className='right-container'>
                                <AssistanceWrapper>
                                    <div className='action-heading-wrapper'>
                                        <span className='action-heading'>School Subscriptions</span>
                                        <span >Welbee Subscriptions</span>
                                    </div>
                                    <RequestAssistanceButton />
                                </AssistanceWrapper>
                                <ExtendedSubContainer>
                                    <div className='search-data-container'>
                                        <div className='search-inner-container'>
                                            <Grid container>
                                                {Object.keys(WelbeeTypes).map(key =>
                                                    <Grid item
                                                        lg={3}
                                                        sm={12}
                                                        md={12}
                                                        xs={12}>
                                                        <Card style={{ marginTop: 10, }}><HeaderWrapper value={pictonBlue}>
                                                        </HeaderWrapper>
                                                            <CardHeader
                                                                title={WelbeeTypes[key].Title}
                                                            />
                                                            <CardContent>
                                                                {WelbeeTypes[key].Desc}
                                                            </CardContent>
                                                            <CardActions>
                                                                {WelbeeTypes[key].Value > this.state.subscriptionId ? 
                                                                    (<AppButton color={mandy} onClick={this.onSubscribed.bind(this, WelbeeTypes[key].Value)} >Subscribe</AppButton>) :
                                                                    (<AppButton color={mineShaft}>Subscribed</AppButton>)}

                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>



                                        </div>
                                    </div>
                                </ExtendedSubContainer>
                            </div>
                       
                    </div>
                </RegisterWrapper>
            </ExtendedActionPlanMainWrapper>
        );
    }
}

const mapStateToProps = ({ user: { doScroll } }) => {
    return {
        doScroll,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loaderActions: bindActionCreators(loaderAction, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
    userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SchoolSubscriptions));
