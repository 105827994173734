import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu, AnalyticsDropdown, MainHeader } from '../../components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils/';
import { navigationActions } from '../../store/headerStore';
import { storageService } from '../../services';
import {
  AnalyticsChartWrapper,
  AssistanceWrapper,
  ChartWrapperError,
  AnalyticsChartPopupWrapper,
} from '../../resources/styling/appStyle';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import { FloatinLabelDropDown } from '../../components';
import {
  CategoryList,
  CharteristicsType,
  ChartType,
  ShowChartType,
} from '../../utils/Constants';
import { analyticsService } from '../../services';
import AnalyticsLineChart from '../../components/Charts/AnalyticsLineChart';
import AnalyticsBarChart from '../../components/Charts/AnalyticsBarChart';
import CharateristicsTypeAnalytics from './CharateristicsTypeAnalytics';
import { Tooltip } from '@mui/material';
import { chartColors } from '../../utils/CommonFunctions';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import AccessLevel from '../../components/common/AccessLevel';
import { AccessPermissions, Roles } from '../../utils/Constants';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: 0,
      characteristicsTypes: [],
      selectedCharateristicsType: {
        value: CharteristicsType.All,
        label: 'All',
      },
      allStaffSchoolAnalyticsData: [],
      GraphDataSearched: [],
      selectedCategory: {
        value: 0,
        label: 'All',
      },
      selectedCategoryId: 0,
      categorylable: 'All Management Standards',
      showAnalytics: CharteristicsType.All,
      characteristicsValues: [],
      selectedCharacteristicsValues: {
        value: 0,
        label: 'All',
      },
      selectedChartType: {
        value: 2,
        label: 'Bar Chart',
      },
      isChartType: 2,
      PageColumnSize: 12,
      searchedCharateristValue: 0,
      graphColors: chartColors(0),
      Chartlabel: '',
      isPopupShowing: false,
      datasets: [],
      datalables: [],
      emptyMessage: null,
      isEmptyMessage: false,
      surveyCount: 0,
      mainTitle: '',
    };
  }
  componentDidMount() {
    ChartType.splice(2, 1);
    CategoryList.splice(8, 1);
    const { loaderAction, navigationActions } = this.props;
    loaderAction.dataLoading(true);
    const SchoolId = storageService.getItem(WebStorageNames.SchoolId);
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const model = {
      SchoolId: SchoolId,
      CategoryId: this.state.selectedCategoryId,
      ChartType: this.state.isChartType,
      CharacteristcsTypes: CharteristicsType.All,
    };
    navigationActions.activeItemAction({
      item: MenuItems.Analytics,
    });

    this.setState({ graphColors: chartColors(0) });
    this.setState({ accessLevel: accessLevel });
    this.GetSchoolCharacteristicTypes(SchoolId);
    this.GetSchoolAnalytcsData(model);
    //loaderAction.dataLoading(false);
  }

  GetSchoolAnalytcsData = (model) => {
    const { loaderAction } = this.props;
    loaderAction.dataLoading(true);
    this.setState({ characteristicsValues: [] });
    analyticsService
      .getSchoolAnalyticsData(model)
      .then((response) => {
        const { success, data, message } = response;
        if (success) {
          var columnSize = data.AllStaffSchoolAnalyticsData[0]?.surveyCount > 3 ? 12 : 6;
          this.setState({ PageColumnSize: columnSize });
          this.setState({
            isEmptyMessage: false,
            GraphDataSearched: data.AllStaffSchoolAnalyticsData,
            surveyCount: data.AllStaffSchoolAnalyticsData[0].surveyCount,
            characteristicsValues: data.characteristicsTypesValue,
            datasets: data.AllStaffSchoolAnalyticsData[0].datasets,
            datalables: data.AllStaffSchoolAnalyticsData[0].labels,
            mainTitle: data.AllStaffSchoolAnalyticsData[0].mainTitle
          });

        } else {
          if (
            this.state.characteristicsValues.length <= 1 &&
            this.state.showAnalytics !== CharteristicsType.All
          ) {
            this.setState({ isEmptyMessage: false });
          } else {
            this.setState({ isEmptyMessage: true, emptyMessage: message });
          }
        }
        loaderAction.dataLoading(false);
      })
      .catch((error) => {
        loaderAction.dataLoading(false);
      });
  };

  GetSchoolCharacteristicTypes = (schoolId) => {
    analyticsService
      .getSchoolCharacteristicTypes(schoolId)
      .then((response) => {
        const region = storageService.getItem(WebStorageNames.UsRegion);
        const addFilters = response.data.map((obj) => {
          if (obj.label === 'Phase Or Year') {
            if (region === 'us') return { ...obj, label: 'Grade' };
            else return { ...obj, label: 'Year or Key Stage' };
          }
          return obj;
        });
        this.setState({ characteristicsTypes: addFilters });
      })
      .catch((error) => {
      });
  };

  handlerSelectedCharacteristicsType = (option) => {
    const SchoolId = storageService.getItem(WebStorageNames.SchoolId);
    const model = {
      SchoolId: SchoolId,
      CategoryId: 0,
      ChartType: this.state.isChartType,
      CharacteristcsTypes: option.value,
    };
    this.GetSchoolAnalytcsData(model);
    this.setState({ searchedCharateristValue: 0 });
    this.setState({ graphColors: chartColors(0) });
    this.setState({
      selectedCategory: {
        value: 0,
        label: 'All',
      },
    });
    this.setState({ selectedCategoryId: 0 });
    this.setState({
      selectedCharacteristicsValues: {
        value: 0,
        label: 'All',
      },
    });
    this.setState({ categorylable: 'All Management Standards' });
    this.setState({
      characteristicsTypeLabel: this.GetLabelText(
        this.state.characteristicsTypes,
        option.value
      ),
    });
    CategoryList.splice(8, 1);
  };
  GetLabelText = (data, id) => {
    const text = data.filter((d) => d.value === id).map((x) => x.label);
    return text;
  };
  handlerCatergorySearch = (option) => {
    this.setState({ selectedCategoryId: option.value });
    this.setState({ graphColors: chartColors(option.value) });
    this.setState({ selectedCategory: option });
    const SchoolId = storageService.getItem(WebStorageNames.SchoolId);
    const model = {
      SchoolId: SchoolId,
      CategoryId: option.value,
      ChartType: this.state.isChartType,
      CharacteristcsTypes: this.state.showAnalytics,
    };
    this.GetSchoolAnalytcsData(model);
  };

  handlerCharacteristicsTypeSearch = (option) => {
    let searchData = [];
    searchData = this.state.GraphDataSearched;
    if (option.value !== 0) {
      searchData = searchData[0].datasets
        .filter((x) => x.characteristicsType === option.value)
        .map((data) => data);

      if (this.state.selectedCategoryId !== 0) {
        searchData = searchData[0].datasets
          .filter((x) => x.CategoryId === option.value)
          .map((data) => data);
      }
      this.setState({ datasets: searchData });
      this.setState({ datalables: this.state.GraphDataSearched[0].labels });
    } else {
      this.setState({ datasets: this.state.GraphDataSearched[0].datasets });
      this.setState({ datalables: this.state.GraphDataSearched[0].labels });
    }
    this.setState({ selectedCharacteristicsValues: option });
  };

  handlerSetColumnSize = (option) => {
    if (option.value === 0) {
      var columnSize = this.state.surveyCount > 3 ? 12 : 6;
      this.setState({ PageColumnSize: columnSize });
      this.setState({ searchedCharateristValue: 0 });
    } else {
      this.setState({ searchedCharateristValue: option.value });
      this.setState({ PageColumnSize: 12 });
    }
  };

  handlerSelectChartType = (option) => {
    const SchoolId = storageService.getItem(WebStorageNames.SchoolId);
    const model = {
      SchoolId: SchoolId,
      CategoryId: this.state.selectedCategoryId,
      ChartType: option.value,
      CharacteristcsTypes: this.state.showAnalytics,
    };
    this.GetSchoolAnalytcsData(model);
    this.setState({ isChartType: option.value });
    this.setState({ selectedChartType: option });
  };
  GetTitlesForBarChart = (data) => {
    const value = data
      .filter((d) => d)
      .map((x) => x.label)
      .join(', ');
    return value;
  };
  showGraphPopup = () => {
    this.setState({ isPopupShowing: true });
  };

  closePopup = () => {
    this.setState({ isPopupShowing: false });
  };
  render() {
    const {
      allStaffSchoolAnalyticsData,
      selectedCategory,
      characteristicsTypes,
      selectedCharateristicsType,
      categorylable,
      showAnalytics,
      characteristicsValues,
      selectedCharacteristicsValues,
      PageColumnSize,
      searchedCharateristValue,
      selectedChartType,
      isChartType,
      graphColors,
      characteristicsTypeLabel,
      isPopupShowing,
      datalables,
      datasets,
      emptyMessage,
      isEmptyMessage,
      mainTitle
    } = this.state;
    return (
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
        <div>
          <div className='containerClass'>
            <div className='left-container'>
              <LeftMenu />
            </div>
            <div className='right-container'>
              <MainHeader />
              <Grid container>
                <Grid item lg={12} xs={12} md={12}>
                  <h2 style={{ margin: '0' }}>Analytics</h2>
                  <p style={{ marginTop: '5px' }}>
                    Compare survey results across all characteristics, but note
                    that scores are only reported where there are 5 or more
                    participants in that category.
                  </p>
                </Grid>

                <Grid item lg={12} md={12} xs={12} mt='15px'>
                  <ExtendedWrapperMain className='form-inner'>
                    <div className='analytics-dropdown'>
                      <AnalyticsDropdown
                        name='CharacteristicsType'
                        label='Characteristics Types'
                        options={characteristicsTypes}
                        value={selectedCharateristicsType.value}
                        onChange={(option) => {
                          this.setState({
                            selectedCharateristicsType: option.target,
                          });
                          this.handlerSelectedCharacteristicsType(option.target);
                          this.setState({ showAnalytics: option.target.value });
                        }}
                      />
                    </div>
                    {showAnalytics !== CharteristicsType.All &&
                      this.state.characteristicsValues.length > 1 && (
                        <div className='analytics-dropdown'>
                          <AnalyticsDropdown
                            name='CharacteristicsTypeValue'
                            label='Characteristics Values'
                            options={characteristicsValues}
                            value={selectedCharacteristicsValues.value}
                            onChange={(option) => {
                              this.setState({
                                selectedCharacteristicsValues: option.target,
                              });
                              this.handlerSetColumnSize(option.target);
                              this.handlerCharacteristicsTypeSearch(
                                option.target
                              );
                            }}
                          />
                        </div>
                      )}
                    <div className='analytics-dropdown'>
                      <AnalyticsDropdown
                        name='CharacteristicsType'
                        label='Management Standards'
                        options={CategoryList}
                        value={selectedCategory.value}
                        onChange={(option) => {
                          this.setState({
                            categorylable: this.GetLabelText(
                              CategoryList,
                              option.target.value
                            ),
                          });
                          this.handlerCatergorySearch(option.target);
                        }}
                      />
                    </div>
                    <div className='analytics-dropdown'>
                      <AnalyticsDropdown
                        name='ChartType'
                        label='Chart Type'
                        options={ChartType}
                        value={selectedChartType.value}
                        onChange={(option) => {
                          this.handlerSelectChartType(option.target);
                        }}
                      />
                    </div>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item xs={12} md={12}>
                  {isEmptyMessage ? (
                    <ChartWrapperError>
                      <span className='nodata'>{emptyMessage}</span>
                    </ChartWrapperError>
                  ) : (
                    <>
                      {showAnalytics === CharteristicsType.All ? (
                        <>
                          {isChartType === ShowChartType.LineChart && (
                            <AnalyticsChartWrapper>
                              <Box sx={{ marginBottom: '30px' }}>
                                <h5
                                  style={{ display: 'inline-block', margin: '0' }}
                                >
                                  Total Score
                                </h5>
                                <Tooltip title='Full Screen View' arrow>
                                  <FullscreenIcon
                                    class='large-graph-btn'
                                    style={{
                                      display: 'inline-block',
                                      float: 'right',
                                    }}
                                    fontSize='inherit'
                                    onClick={this.showGraphPopup}
                                  />
                                </Tooltip>
                              </Box>
                              <AnalyticsLineChart
                                AnalyticsLineChartData={
                                  allStaffSchoolAnalyticsData
                                }
                                colorValue={graphColors}
                                datalables={datalables}
                                datasets={datasets}
                                mainTitle={mainTitle}
                                height={'250'}
                              />
                            </AnalyticsChartWrapper>
                          )}
                          {isChartType === ShowChartType.BarChart && (
                            <AnalyticsChartWrapper>
                              <Box sx={{ marginBottom: '30px' }}>
                                <h5
                                  style={{ display: 'inline-block', margin: '0' }}
                                >
                                  Total Score
                                </h5>
                                <div className='large-btn'>
                                  <Tooltip title='Full Screen View' arrow>
                                    <FullscreenIcon
                                      class='large-graph-btn'
                                      style={{
                                        display: 'inline-block',
                                        float: 'right',
                                      }}
                                      fontSize='inherit'
                                      onClick={this.showGraphPopup}
                                    />
                                  </Tooltip>
                                </div>
                              </Box>

                              <AnalyticsBarChart
                                AnalyticsBarChartData={
                                  allStaffSchoolAnalyticsData
                                }
                                colorValue={graphColors}
                                mainTitle={mainTitle}
                                Chartlabel={''}
                                datalables={datalables}
                                datasets={datasets}
                                height={'110'}
                              />
                            </AnalyticsChartWrapper>
                          )}
                        </>
                      ) : (
                        <CharateristicsTypeAnalytics
                          allStaffSchoolAnalyticsData={
                            allStaffSchoolAnalyticsData
                          }
                          categoryLabel={categorylable}
                          characteristicsTypeValues={characteristicsValues}
                          pageColumnSize={PageColumnSize}
                          SearchTypeValue={searchedCharateristValue}
                          chartType={isChartType}
                          colorsValue={graphColors}
                          characteristicsTypeLabel={characteristicsTypeLabel}
                          datalables={datalables}
                          datasets={datasets}
                          mainTitle={mainTitle}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          {isPopupShowing ? (
            <GraphPopup>
              <div className='graph-container' id='large-graph'>
                <div className='redCrossButtom'>
                  <CloseIcon className='closeIcon' onClick={this.closePopup} />
                </div>
                <>
                  {isChartType === ShowChartType.LineChart && (
                    <AnalyticsChartPopupWrapper>
                      <Box sx={{ marginBottom: '0px' }}>
                        <h5 style={{ display: 'inline-block', margin: '0' }}>
                          Total Score
                        </h5>
                      </Box>
                      <AnalyticsLineChart
                        colorValue={graphColors}
                        datalables={datalables}
                        datasets={datasets}
                        height={'110'}
                      />
                    </AnalyticsChartPopupWrapper>
                  )}
                  {isChartType === ShowChartType.BarChart && (
                    <AnalyticsChartPopupWrapper>
                      <Box sx={{ marginBottom: '0px' }}>
                        <h5 style={{ display: 'inline-block', margin: '0' }}>
                          Total Score
                        </h5>
                      </Box>
                      <AnalyticsBarChart
                        colorValue={graphColors}
                        Chartlabel={''}
                        datalables={datalables}
                        datasets={datasets}
                        height={'110'}
                      />
                    </AnalyticsChartPopupWrapper>
                  )}
                </>
              </div>
            </GraphPopup>
          ) : (
            ''
          )}
        </div>
      </AccessLevel>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderAction: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(Analytics));
