import request from '../request';
import { surveyCommentsAPI } from './urls';

const getSurveyComments = (surveyToken, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: surveyCommentsAPI.api(surveyToken, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getCommentWithReplies = (id) => {
  return request({
    url: surveyCommentsAPI.getCommentWithReplies(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const SubmitHeadTeacherCommentReply = (model) => {
  return request({
    url: surveyCommentsAPI.SubmitHeadTeacherCommentReply,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const SubmitStaffCommentReply = (model) => {
  return request({
    url: surveyCommentsAPI.SubmitStaffCommentReply,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const setCommentAsImportant = (model) => {
  return request({
    url: surveyCommentsAPI.setCommentAsImportant,
    method: 'PUT',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const setCommentIsRead = (id) => {
  return request({
    url: surveyCommentsAPI.setCommentIsRead(id),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getStaffCommentWithReplies = (token) => {
  return request({
    url: surveyCommentsAPI.getStaffCommentWithReplies(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const GetPDFSurveyComments = (surveyToken, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: surveyCommentsAPI.GetPDFSurveyComments(surveyToken, schoolToken),
    method: 'GET',
    responseType:'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const GetExcelSurveyComments = (surveyToken, schoolToken = null) => {
    schoolToken = schoolToken === null ? '' : schoolToken;
    return request({
        url: surveyCommentsAPI.GetExcelSurveyComments(surveyToken, schoolToken),
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => response)
        .catch((error) => error);
};

const getAdditionalQuestionsForComments = (token) => {
    return request({
        url: surveyCommentsAPI.getAdditionalQuestionsForComments(token),
        method: 'GET',
    })
        .then((response) => response)
        .catch((error) => error);
};
const surveyCommentService = {
  getSurveyComments,
  setCommentAsImportant,
  getCommentWithReplies,
  SubmitHeadTeacherCommentReply,
  getStaffCommentWithReplies,
  SubmitStaffCommentReply,
  setCommentIsRead,
  GetPDFSurveyComments,
  GetExcelSurveyComments,
  getAdditionalQuestionsForComments,
};

export default surveyCommentService;
