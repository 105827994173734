import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { PageWrapper, AppButton } from '../../resources/styling/appStyle';
import { welbeeLoginLogo } from '../../resources/images/index';
import { ExtendedMainWrapper } from './loginStyle';
import { routesObj } from '../../routes';

class SchoolRegisterWrapper extends Component {
  render() {
    return (
      <ExtendedMainWrapper>
        <PageWrapper>
          <div className='mobile-warning-wrapper'>
            <span>&#9888;</span>
            Please use desktop to view Welbee
          </div>
          <Grid container className='login-main-warpper'>
            <Grid item
              lg={6}
              sm={12}
              md={6}
              xs={12}
              className='login-mobile-wrapper login-left-panel'
            >
              <span className='login-button'>
                <AppButton
                  onClick={() => this.props.history.push(routesObj.Login.path)}
                >
                  Login
                </AppButton>
                {this.props.onBack !== null ? (
                  <AppButton onClick={this.props.onBack}>Back</AppButton>
                ) : (
                  <></>
                )}
              </span>

              <img src={welbeeLoginLogo} alt='' />
            </Grid>
            <Grid item
              lg={6}
              sm={12}
              xs={12}
              md={6}
              className='logo-container welcome-right-panel registeration-right-panel'
            >
              {this.props.children}
            </Grid>
          </Grid>
        </PageWrapper>
      </ExtendedMainWrapper>
    );
  }
}

export default withRouter(SchoolRegisterWrapper);
