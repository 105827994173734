import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import WelbeeVoiceUpgradePopup from './WelbeeVoiceUpgradePopup';
import { LeftMenu } from '../../components';
import ImagePopup from './ImagePopup';
import { colors } from '../../resources/theme';
import { VoiceUpgradeService, storageService, schoolService } from '../../services';
import { WebStorageNames, WelbeeType, QueryStringTokens, WelbeeVoiceAccountType } from '../../utils';
import UpgradeInvoiceLeftPanel from './updragePrice/UpgradeInvoiceLeftPanel';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { AppButton } from '../../resources/styling/appStyle';
import { dasboardsmall, dasboardlarge, dasboardheatsmall, dasboardheatlarge, removethin } from '../../resources/images/index';
import { routesObj } from '../../routes';
import { VoiceUpgradeDashboardWrapper } from './WelbeeVoiceStyle';

const { mandy 
} = colors;

class VoiceUpgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpgradePopupShow: false,
      isDashboardHeatShow: false,
      isWelbeeDashboardShow: false,
      isUpgradeInvoiceShow: false,
      numberOfPupil: 0,
      userInfo: null,
      token: null,
      selectedWelbeeType: 0,
      welbeeAccountType: 0,
      };
      this.backToConversationClick = this.backToConversationClick.bind(this);
  }

  componentDidMount() {
    const userInfo = storageService.getItem(WebStorageNames.UserInfo);
    const token = getQueryStringVal(QueryStringTokens.token);
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    const isStaff = storageService.getItem(WebStorageNames.IsStaff);
    let welbeeAccountType = storageService.getItem(WebStorageNames.WelbeeVoiceAccountType);
    let welbeeType = storageService.getItem(WebStorageNames.WelbeeType);
    if (isStaff || welbeeType === WelbeeType.WelbeeSurveyWithVoice) {
      this.props.history.push(routesObj.Login.path);
    }
    this.setState({ token: token, schoolId: schoolId, userInfo: userInfo, welbeeAccountType: welbeeAccountType });
    schoolService
      .getNumberOfPupil(schoolId, token)
      .then((response) => {
        if (response.success) {
          const schoolObj = response.data;
          this.setState({ numberOfPupil: schoolObj.NumberOfPupil });
          storageService.setItem(WebStorageNames.SchoolId, schoolObj.Id);
          welbeeAccountType = schoolObj.WelbeeVoiceAccountType;
          welbeeType = schoolObj.WelbeeType;
          if(welbeeType === WelbeeType.WelbeeSurveyWithVoice) {
            this.props.history.push(routesObj.Login.path);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  backToConversationClick = () => {
    this.props.history.push(routesObj.Login.path);
  };

  hideInvoicePopup = () => {
    this.setState({ isUpgradeInvoiceShow: false });
  };

  onSurveyAndVoiceUpgrade = () => {
        this.props.history.push(routesObj.SubscriptionNotAvailable.path);
        return;
    this.setState({
      isUpgradeInvoiceShow: true,
      selectedWelbeeType: WelbeeType.WelbeeSurveyWithVoice,
    });
  };

    onVoiceUpgrade = () => {
        this.props.history.push(routesObj.SubscriptionNotAvailable.path);
        return;
    this.setState({
      isUpgradeInvoiceShow: true,
      selectedWelbeeType: WelbeeType.WelbeeVoice,
    });
  };

  onUpgradeRequest = () => {
    const { selectedWelbeeType } = this.state;
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    VoiceUpgradeService.upgradeRequest(schoolId, selectedWelbeeType)
      .then((response) => {
        if (response.success) {
          toast.success(
            selectedWelbeeType === WelbeeType.WelbeeSurveyWithVoice
              ? 'Survey & Voice pro upgrade request sent successfully.'
              : 'Voice pro upgrade request sent successfully.'
          );
          storageService.setItem(WebStorageNames.IsUpgradeDisplay, false);
          storageService.setItem(WebStorageNames.WelbeeVoiceAccountType, WelbeeVoiceAccountType.Paid);
          storageService.setItem(WebStorageNames.WelbeeType, selectedWelbeeType);
          this.setState({ isUpgradePopupShow: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      isUpgradePopupShow,
      isDashboardHeatShow,
      isWelbeeDashboardShow,
      isUpgradeInvoiceShow,
      userInfo,
      token,
      selectedWelbeeType,
      welbeeAccountType,
    } = this.state;
    return (
      <VoiceUpgradeDashboardWrapper>
        {token == null && <LeftMenu />}
        <div
          className='content-wrapepr'
          style={{ marginLeft: token == null ? 189 : 0 }}
        >
          <div className='upgrade-body-content'>
            <h2>Ready to take your staff wellbeing to the next level?</h2>
            <p>
              You can continue to use Welbee Voice with multi-user access and
              group functionality, and add a comprehensive and evidence-based
              staff wellbeing survey. There are discounts for MATs and groups of
              schools that work together.
            </p>
            <span className='title'>Choose one of the following:</span>

            <div className='survey-voice-box'>
              <div className='heading'>
                <span>Survey &amp; Voice Pro</span>
                <div className='button-wrapper'>
                  <AppButton
                    color={mandy}
                    type='button'
                    onClick={() => this.onSurveyAndVoiceUpgrade()}
                  >
                    Upgrade
                  </AppButton>
                </div>
              </div>
              <div className='sub-heading'>
                <p>
                  Welbee Survey software with full access to Welbe Voice Pro,
                  including multi-user access and group functionality.
                </p>
                <span className='amount-VAT'>Price excludes VAT</span>
              </div>

              <ul>
                <li>
                  <span>
                    Up to <i>200</i> pupils
                  </span>
                  <span>£247</span>
                </li>

                <li>
                  <span>
                    <i>201 - 500</i> pupils
                  </span>
                  <span>£347</span>
                </li>
                <li>
                  <span>
                    <i>501 - 1000</i> pupils
                  </span>
                  <span>£447</span>
                </li>
                <li>
                  <span>
                    <i>1001 - 1500</i> pupils
                  </span>
                  <span>£547</span>
                </li>            
                <li>
                  <span>
                    <i>1500+</i> pupils
                  </span>
                  <span>£647</span>
                </li>
              </ul>
            </div>
            {welbeeAccountType !== WelbeeVoiceAccountType.Paid &&
            <div className='survey-voice-box voice-pro'>
              <div className='heading'>
                <span>Voice Pro</span>
                <div className='button-wrapper'>
                  <AppButton
                    color={mandy}
                    type='button'
                    onClick={() => this.onVoiceUpgrade()}
                  >
                    Upgrade
                  </AppButton>
                </div>
              </div>
              <div className='sub-heading'>
                <p>
                  Anonymous conversation software with multi-user access and
                  group functionality.
                </p>
                <span className='amount-VAT'>Price excludes VAT</span>
              </div>
              <ul>
                <li>
                  <span>
                    Up to <i>200</i> pupils
                  </span>
                  <span>£97</span>
                </li>

                <li>
                  <span>
                    <i>201 - 500</i> pupils
                  </span>
                  <span>£147</span>
                </li>
                <li>
                  <span>
                    <i>501 - 1000</i> pupils
                  </span>
                  <span>£197</span>
                </li>
                <li>
                  <span>
                    <i>1001 - 1500</i> pupils
                  </span>
                  <span>£247</span>
                </li>                
                <li>
                  <span>
                    <i>1500+</i> pupils
                  </span>
                  <span>£297</span>
                </li>
              </ul>
            </div>}

            <span className='title'>Example screens</span>

            <div className='screen-short'>
              <img src={dasboardsmall} alt='small dashboard' />
              <div className='image-enlarge'>
                <span>Survey dashboard</span>
                {/* <span
                onClick={() => this.setState({ isWelbeeDashboardShow: true })}
              >
                Enlarge
              </span> */}
              </div>
            </div>

            <div className='screen-short'>
              <img src={dasboardheatsmall} alt='small dashboard' />
              <div className='image-enlarge'>
                <span>Heatmaps</span>
                {/* <span
                onClick={() => this.setState({ isWelbeeDashboardShow: true })}
              >
                Enlarge
              </span> */}
              </div>
            </div>
          </div>
          <div className='upgrade-suggested-pic'>
            <div className='upgrade-check-list'>
              <h2>Upgrade to Survey</h2>
              <ul>
                <li>Everything in Welbee Voice Pro (below)</li>
                <li>
                  An evidence built anonymous survey using the Health and Safety
                  Executive’s Management Standards
                </li>
                <li>
                  Benchmarked scores against other schools and cross-sector
                  organisations
                </li>
                <li>
                  Drill down into the detail using our heat maps and filters
                </li>
                <li>
                  Priority actions recommended against management standards
                </li>
                <li>Collection of staff comments to provide deeper insight</li>
                <li>
                  Respond to your staff members comments anonymously to address
                  concerns and seek solutions
                </li>
                <li>
                  A downloadable presentation to make sharing results with all
                  stakeholders easier
                </li>
                <li>Remote support to answer questions and give advice</li>
                <li>
                  Full money back guarantee if you don’t get value from the
                  survey - so it’s risk free!
                </li>
              </ul>
            </div>

            <div className='upgrade-check-list'>
              <h2>Upgrade to Voice Pro</h2>
              <ul>
                <li>No limit to the number of school users</li>
                <li>
                  Allocate staff to multiple groups to make it easy to get the
                  feedback you need
                </li>
                <li>
                  Invite specific groups of staff as well as all staff to any
                  conversation
                </li>
                <li>
                  Seek feedback, follow up on actions and create regular open
                  conversations
                </li>
                <li>
                  An automated process following set up so it is easy on your
                  time and simple to use
                </li>
                <li>
                  All results reported through an online and accessible
                  dashboard
                </li>
              </ul>
            </div>

            {/* <div className='screen-main-wrapper'>
              <div className='screen-short'>
                <img src={dasboardsmall} alt='small dashboard' />
              </div>
              <div className='image-enlarge'>
                <span>Welbee dashboard</span>
                <span
                  onClick={() => this.setState({ isWelbeeDashboardShow: true })}
                >
                  Enlarge
                </span>
              </div>
            </div>

            <div className='screen-main-wrapper'>
              <div className='screen-short'>
                <img src={dasboardheatsmall} alt='small heat dashboard' />
              </div>
              <div className='image-enlarge'>
                <span>Breakdown of results by roles and characteristics</span>
                <span
                  onClick={() => this.setState({ isDashboardHeatShow: true })}
                >
                  Enlarge
                </span>
              </div>
            </div> */}
            {/*
            <div className='button-wrapper'>
              <AppButton
                color={teal}
                type='button'
                onClick={() => this.setState({ isUpgradeInvoiceShow: true })}
              >
                Upgrade now
              </AppButton>
            </div> */}
          </div>
        </div>
        {isWelbeeDashboardShow ? (
          <ImagePopup>
            <button
              className='close'
              onClick={() => this.setState({ isWelbeeDashboardShow: false })}
            >
              <img src={removethin} alt='remove button' />
            </button>
            <img src={dasboardlarge} alt='dashboard hear' />
          </ImagePopup>
        ) : (
          ''
        )}
        {isDashboardHeatShow ? (
          <ImagePopup>
            <button
              className='close'
              onClick={() => this.setState({ isDashboardHeatShow: false })}
            >
              <img src={removethin} alt='remove button' />
            </button>
            <img src={dasboardheatlarge} alt='dashboard hear' />
          </ImagePopup>
        ) : (
          ''
        )}
        {isUpgradePopupShow ? (
          <WelbeeVoiceUpgradePopup
            isLoggedIn={userInfo !== null}
            isOpen={isUpgradePopupShow}
            onClose={this.backToConversationClick}
            onOk={this.backToConversationClick}
            welbeeType={selectedWelbeeType}
          />
        ) : null}

        {isUpgradeInvoiceShow ? (
          <ImagePopup>
            <button className='close' onClick={this.hideInvoicePopup}>
              <img src={removethin} alt='remove button' />
            </button>
            <div className='upgrade-popup-inner'>
              <UpgradeInvoiceLeftPanel
                onBackBtnClick={this.hideInvoicePopup}
                isLoggedIn={userInfo !== null}
                onCompletion={() => {
                  this.hideInvoicePopup();
                  this.onUpgradeRequest();
                }}
                welbeeType={selectedWelbeeType}
              />
            </div>
          </ImagePopup>
        ) : null}
      </VoiceUpgradeDashboardWrapper>
    );
  }
}

const mapStateToProps = ({ user: { isFirstLogin } }) => {
  return {
    isFirstLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  welbeeService: bindActionCreators(VoiceUpgradeService, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VoiceUpgrade));
