import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { RegisterWrapper } from '../../resources/styling/appStyle';
import Stack from '@mui/material/Stack';
import { LeftMenu, MainHeader } from '../../components';
import { colors } from '../../resources/theme';
import { removethin } from '../../resources/images/index';
import { toast } from 'react-toastify';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import { AppButton } from '../../resources/styling';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { blueBg, manBg } from '../../resources/images/index';
import ImagePopup from '../welbeeVoice/ImagePopup';
import UpgradeInvoiceLeftPanel from '../welbeeVoice/updragePrice/UpgradeInvoiceLeftPanel';
import WelbeeVoiceUpgradePopup from '../welbeeVoice/WelbeeVoiceUpgradePopup';
import {
  VoiceUpgradeService,
  storageService,
  schoolService,
} from '../../services';
import { WebStorageNames, WelbeeTypes } from '../../utils';
import { routesObj } from '../../routes';
import { CancelOutlined } from '@mui/icons-material';
import UpgradeToFreePopup from '../schoolSubscriptions/UpgradeToFreePopup';
import {
  AccountExtendedSubContainer,
  ExtendedActionPlanMainWrapper,
} from './AccountStyle';

const { mandy, purple, seldom, never, often } = colors;

const voiceFreeText = {
  id: WelbeeTypes.WelbeeVoiceFree,
  title: 'Voice Free',
  topLine: 'Anonymous conversations with all staff',
  benefitsSm: [
    'Anonymous staff conversations',
    'Unlimited number of feedback requests',
    'Invite all staff to participate',
    'Respond to staff comments',
  ],
  benefitsLg: [
    'Anonymous staff conversations to seek timely feedback',
    'Unlimited number of feedback requests to receive ongoing information',
    'Invite all staff to participate in regular conversations',
    'Quick and easy to use',
    'Respond to staff comments while maintaining their anonymity',
    'Use to follow up on survey actions',
    'Use before or following change',
    'Use to consult or where anonymous feedback will help',
    'Single user',
  ],
  color: purple,
};
const voiceProText = {
  id: WelbeeTypes.WelbeeVoicePro,
  title: 'Voice Pro',
  topLine: 'Anonymous conversations with staff groups',
  benefitsSm: [
    'Anonymous staff conversations',
    'Unlimited number of feedback requests',
    'Invite all staff to participate',
    'Respond to staff comments',
  ],
  benefitsLg: [
    'Anonymous staff conversations to seek timely feedback',
    'Unlimited number of feedback requests to receive ongoing information',
    'Invite all staff to participate in regular conversations',
    'Invite groups of staff for specific feedback',
    'Quick and easy to use',
    'Respond to staff comments while maintaining their anonymity',
    'Use to follow up on survey actions',
    'Use before or following change',
    'Use to consult or where anonymous feedback will help',
    'Multiple users',
    'Full money back guarantee for first time use',
  ],
  color: mandy,
};
const surveyFreeText = {
  id: WelbeeTypes.WelbeeSurveyFree,
  title: 'Survey Free',
  topLine: 'Measure and track your performance',
  benefitsSm: [
    'Annual anonymous staff survey',
    'Scores reported against Management Standards',
    'Summary report',
    'Table of scores filtered by key staff attributes',
  ],
  benefitsLg: [
    'Annual anonymous staff survey',
    "Meet commitment 11 of the DfE's Staff Wellbeing Charter",
    "Dashboard of scores against the Health and Safety Executive's Management Standards",
    'Receive a summary report',
    'Table of scores filtered by key staff attributes',
    'View staff comments',
    'Includes Voice Free',
    'Single user',
  ],
  color: often,
};
const surveyProText = {
  id: WelbeeTypes.WelbeeSurveyPro,
  title: 'Survey Pro',
  topLine: 'Track benchmarked scores',
  benefitsSm: [
    'Annual anonymous staff survey',
    'Benchmarked scores against Management Standards',
    'Summary report, recommended actions and PowerPoint presentation ',
    'Heatmaps filtered by key staff attributes',
  ],
  benefitsLg: [
    'Annual anonymous staff survey',
    "Meet commitment 11 of the DfE's Staff Wellbeing Charter",
    'Additional surveys available at low cost',
    "Dashboard of scores against the Health and Safety Executive's Management Standards",
    'Benchmarked scores against other schools and organisations',
    'Receive a summary report with strengths and areas for focus',
    'Celebrate areas of success and target areas of need',
    'Recommended actions to focus on what will deliver the greatest impact',
    'Heatmaps filtered by key staff attributes',
    'View and anonymously respond to staff comments for immediate engagement',
    'Includes Voice Pro for ongoing conversations',
    'Multiple users',
    'Full money back guarantee for first time use',
  ],
  color: never,
};
const surveyPremiumText = {
  id: WelbeeTypes.WelbeeSurveyPremium,
  title: 'Survey Premium',
  topLine: 'Track scores and add your own questions and staff filters',
  benefitsSm: [
    'Multiple anonymous staff surveys',
    'Benchmarked scores against Management Standards',
    'Add your own questions and additional staff filters',
    'Summary report, recommended actions and PowerPoint presentation',
  ],
  benefitsLg: [
    'Annual anonymous staff survey',
    "Meet commitment 11 of the DfE's Staff Wellbeing Charter",
    'Unlimited surveys',
    "Dashboard of scores against the Health and Safety Executive's Management Standards",
    'Benchmarked scores against other schools and organisations',
    'Receive a summary report with strengths and areas for focus',
    'Celebrate success and target areas of need',
    'Recommended actions to focus on what will deliver the greatest impact',
    'Heatmaps filtered by key staff attributes',
    'View and anonymously respond to staff comments for immediate engagement',
    'Add your own questions to target other feedback you want to hear',
    'Choose additional staff filters to further target action',
    'Filters could include subject, department and even line manager...you choose',
    'Voice Pro for ongoing conversations',
    'Multiple users',
    'Full money back guarantee for first time use',
  ],
  color: seldom,
};

//Owl Carousel Settings
const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ['<', '>'],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 2,
    },
    1366: {
      items: 3,
    },
  },
};

class AccountSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionId: storageService.getItem(WebStorageNames.SubscriptionId),
      open: false,
      compareOpen: false,
      isUpgradeInvoiceShow: false,
      newSubscriptionId: 0,
      userInfo: storageService.getItem(WebStorageNames.UserInfo),
      selectedWelbeeType: 0,
      showDetailFor: voiceFreeText,
      confirmFreeUpgrade: false,
      subscriptionEndDate: null,
    };
    this.state = {
      subscriptionId: storageService.getItem(WebStorageNames.SubscriptionId),
      open: false,
      compareOpen: false,
      isUpgradeInvoiceShow: false,
      newSubscriptionId: 0,
      userInfo: storageService.getItem(WebStorageNames.UserInfo),
      selectedWelbeeType: 0,
      showDetailFor: voiceFreeText,
      confirmFreeUpgrade: false,
    };
  }

  componentDidMount() {
    schoolService
      .getSchoolSubscriptions(storageService.getItem(WebStorageNames.SchoolId))
      .then((response) => {
        if (response.success) {
          this.setState({
            subscriptionEndDate:
              new Date(response.data.EndDate).getDate() +
              '/' +
              (new Date(response.data.EndDate).getMonth() + 1) +
              '/' +
              new Date(response.data.EndDate).getFullYear(),
          });
        }
      })
      .catch((error) => console.log('error', error));
  }
  backToConversationClick() {
    this.props.history.push(routesObj.Login.path);
  }
  upgradeSubscription(newSubscriptionId) {
    this.setState({ open: false, compareOpen: false });
    storageService.setItem(
      WebStorageNames.NewSubscriptionId,
      newSubscriptionId
    );
    if (newSubscriptionId === WelbeeTypes.WelbeeSurveyFree) {
      this.setState({
        selectedWelbeeType: newSubscriptionId,
        confirmFreeUpgrade: true,
      });
    } else {
      this.setState({
        isUpgradeInvoiceShow: true,
        selectedWelbeeType: newSubscriptionId,
      });
    }
  }
  handleClickOpen(all, id) {
    let subscriptionCardType =
      id === 1
        ? voiceFreeText
        : id === 2
        ? voiceProText
        : id === 3
        ? surveyFreeText
        : id === 4
        ? surveyProText
        : surveyPremiumText;

    if (all) {
      this.setState({ open: true });
    } else
      this.setState({ compareOpen: true, showDetailFor: subscriptionCardType });
  }

  handleClose(all) {
    if (all) this.setState({ open: false });
    else this.setState({ compareOpen: false });
  }
  hideInvoicePopup() {
    this.setState({ isUpgradeInvoiceShow: false });
  }

  onUpgradeRequest() {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    VoiceUpgradeService.upgradeRequest(schoolId, this.state.selectedWelbeeType)
      .then((response) => {
        if (response.success) {
          toast.success('Upgrade request sent successfully.');
          this.setState({ isUpgradePopupShow: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSubscriptionCard(key) {
    let subscriptionDetails =
      WelbeeTypes[key] === WelbeeTypes.WelbeeVoiceFree
        ? voiceFreeText
        : WelbeeTypes[key] === WelbeeTypes.WelbeeVoicePro
        ? voiceProText
        : WelbeeTypes[key] === WelbeeTypes.WelbeeSurveyFree
        ? surveyFreeText
        : WelbeeTypes[key] === WelbeeTypes.WelbeeSurveyPro
        ? surveyProText
        : surveyPremiumText;
    return (
      <Card className='subscription-card'>
        <Grid container>
          <Grid item md={12} xs={12}>
            <div
              className='subscription-title'
              style={{ backgroundColor: subscriptionDetails.color }}
            >
              <div className='title'>{subscriptionDetails.title}</div>
              <div className='subTitle'>{subscriptionDetails.topLine}</div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className='featureTitle'>Features</div>
              </Grid>
              <div className='subscription-content'>
                {subscriptionDetails.benefitsSm.map((item) => {
                  return (
                    <Grid item xs={12} lg={12} className='details'>
                      <CheckIcon className='icon-check' />
                      {item}
                    </Grid>
                  );
                })}
              </div>
            </Grid>
            <Grid item lg={8} xs={8} mx='auto' style={{ marginTop: 7 }}>
              <div
                className='link'
                onClick={this.handleClickOpen.bind(
                  this,
                  false,
                  WelbeeTypes[key]
                )}
              >
                More Details{' '}
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container>
              {WelbeeTypes[key] === this.state.subscriptionId ? (
                <Grid item md={8} xs={8} mx='auto'>
                  <div className='current-plan'>Current Plan</div>
                </Grid>
              ) : (
                <Grid item md={8} xs={8} mx='auto'>
                  <button
                    className='upgrade-plan'
                    onClick={this.upgradeSubscription.bind(
                      this,
                      WelbeeTypes[key]
                    )}
                  >
                    Upgrade Plan
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
  render() {
    return (
      <ExtendedActionPlanMainWrapper>
        <RegisterWrapper>
          <div className='containerClass'>
            <div className='left-container'>
              <LeftMenu />
            </div>
            <div className='right-container'>
              <MainHeader />
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose.bind(this, true)}
                    fullWidth={false}
                    maxWidth='1280'
                  >
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        <div
                          style={{
                            height: 2953,
                            width: 1223,
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              width: 1223,
                              height: 300,
                              color: 'white',
                              background: '#53B5E0',
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              backgroundImage: 'url(' + blueBg + ')',
                              backgroundSize: 'cover',
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    fill: 'red',
                                    fontSize: 40,
                                    backgroundColor: 'white',
                                    borderRadius: 10,
                                    marginTop: -15,
                                    marginRight: -10,
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                  }}
                                  onClick={this.handleClose.bind(this, true)}
                                />
                              </Grid>
                              <Grid
                                md={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  fontSize: 30,
                                  fontWeight: 'bold',
                                }}
                              >
                                {' '}
                                Compare plans
                              </Grid>
                            </Grid>
                          </div>

                          <div
                            style={{
                              width: '100%',
                              padding: 20,
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: '97%',
                                padding: 20,
                                boxShadow:
                                  '0px 4px 10px rgba(83, 181, 224, 0.15)',
                                textAlign: 'center',
                                borderRadius: 10,
                                borderColor: 'gray',
                                background: 'white',
                                position: 'absolute',
                                top: -200,
                              }}
                            >
                              <Stack direction='row' spacing={2}>
                                <Grid
                                  item
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  style={{
                                    backgroundImage: 'url(' + manBg + ')',
                                    backgroundSize: 'cover',
                                  }}
                                ></Grid>
                                {Object.keys(WelbeeTypes).map((key) =>
                                  WelbeeTypes[key] >=
                                  this.state.subscriptionId ? (
                                    <Grid item lg={2} xs={2} sm={2} md={2}>
                                      <h5 style={{ whiteSpace: 'pre-line' }}>
                                        {WelbeeTypes[key] === voiceFreeText.id
                                          ? voiceFreeText.title
                                          : WelbeeTypes[key] === voiceProText.id
                                          ? voiceProText.title
                                          : WelbeeTypes[key] ===
                                            surveyFreeText.id
                                          ? surveyFreeText.title
                                          : WelbeeTypes[key] ===
                                            surveyProText.id
                                          ? surveyProText.title
                                          : surveyPremiumText.title}
                                      </h5>
                                      <h6 style={{ fontSize: 'small' }}>
                                        {WelbeeTypes[key] === voiceFreeText.id
                                          ? voiceFreeText.topLine
                                          : WelbeeTypes[key] === voiceProText.id
                                          ? voiceProText.topLine
                                          : WelbeeTypes[key] ===
                                            surveyFreeText.id
                                          ? surveyFreeText.topLine
                                          : WelbeeTypes[key] ===
                                            surveyProText.id
                                          ? surveyProText.topLine
                                          : surveyPremiumText.topLine}
                                      </h6>
                                      {WelbeeTypes[key] >
                                      this.state.subscriptionId ? (
                                        <button
                                          onClick={this.upgradeSubscription.bind(
                                            this,
                                            WelbeeTypes[key]
                                          )}
                                          style={{
                                            color: 'white',
                                            backgroundColor: mandy,
                                            border: 0,
                                            padding: 10,
                                            fontWeight: 600,
                                            borderRadius: 15,
                                            width: 120,
                                          }}
                                        >
                                          Upgrade plan
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </Stack>

                              <div
                                style={{
                                  background: '#EFEFEF',
                                  color: '#aaaaaa',
                                  marginTop: 10,
                                  textAlign: 'left',
                                  paddingLeft: 10,
                                }}
                              >
                                Benefits
                              </div>

                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Easy to set up
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Advice on achieving good staff participation
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Anonymous feedback
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Invitation management included
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Largely automated process
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Multiple users
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Use once annually
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Unlimited use during your subscription
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                    <br />
                                    <small>Additional cost</small>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Start and continue ongoing feedback
                                  conversations
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Full annual survey
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Invite all staff to participate
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Invite smaller groups of staff
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Online dashboard
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Responses securely stored
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Respond anonymously to staff comments
                                  (two-way)
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Staff filters & heatmaps to target support as
                                  needed
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Staff filters and table of scores
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Benchmark results against schools and
                                  organisations
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Track scores after each use for staff,
                                  governors and inspectors
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Detailed report with strengths & areas for
                                  focus
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Recommended actions to focus on areas of
                                  greatest impact
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  PowerPoint Presentation to make result sharing
                                  easy
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Add your own questions (or use the question
                                  bank)
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Add your own filters, e.g department, subject,
                                  phase or line manager (to further target
                                  action)
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Welbee toolkit to aid implementation
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  On-going remote support to make things easier
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                    <br />
                                    <small>Email Only</small>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                    <br />
                                    <small>Email Only</small>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                              <Stack
                                direction='row'
                                spacing={2}
                                style={{
                                  borderBottom: 'solid',
                                  borderBottomColor: '#eceaea',
                                  paddingBlockStart: 20,
                                  paddingBlockEnd: 20,
                                  borderBottomWidth: 1,
                                  borderColor: '#eeeeee',
                                }}
                              >
                                <Grid
                                  item
                                  style={{ textAlign: 'left' }}
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                >
                                  Full money back guarantee
                                </Grid>
                                {voiceFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                    <br />
                                    <small>Free</small>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {voiceProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyFreeText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CancelOutlined
                                      style={{ fill: 'red', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyProText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {surveyPremiumText.id >=
                                this.state.subscriptionId ? (
                                  <Grid item lg={2} xs={2} sm={2} md={2}>
                                    <CheckCircleOutlineOutlinedIcon
                                      style={{ fill: 'green', fontSize: 30 }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Stack>

                              <Grid container>
                                <Grid
                                  item
                                  lg={2}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  mt={5}
                                ></Grid>
                                {Object.keys(WelbeeTypes).map((key) =>
                                  WelbeeTypes[key] >=
                                  this.state.subscriptionId ? (
                                    <Grid
                                      item
                                      lg={2}
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      mt={5}
                                      style={{ textAlign: 'end' }}
                                    >
                                      {WelbeeTypes[key] >
                                      this.state.subscriptionId ? (
                                        <button
                                          onClick={this.upgradeSubscription.bind(
                                            this,
                                            WelbeeTypes[key]
                                          )}
                                          style={{
                                            color: 'white',
                                            backgroundColor: mandy,
                                            border: 0,
                                            padding: 10,
                                            fontWeight: 600,
                                            borderRadius: 15,
                                            width: 120,
                                          }}
                                        >
                                          Upgrade plan
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                  <Dialog
                    open={this.state.compareOpen}
                    onClose={this.handleClose.bind(this, false)}
                    fullWidth={true}
                    maxWidth='sm'
                  >
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        <div
                          style={{
                            height: 600,
                            width: '100%',
                            borderRadius: 10,
                            zIndex: 950,
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: 200,
                              color: 'white',
                              background: '#53B5E0',
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              display: 'flex-end',
                              backgroundImage: 'url(' + blueBg + ')',
                              backgroundSize: 'contain',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <CloseIcon
                                style={{
                                  fill: 'red',
                                  fontSize: 40,
                                  backgroundColor: 'white',
                                  borderRadius: 10,
                                  marginTop: -15,
                                  marginRight: -10,
                                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                }}
                                onClick={this.handleClose.bind(this, false)}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              padding: 20,
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: '93%',
                                padding: 20,
                                boxShadow:
                                  '0px 4px 10px rgba(83, 181, 224, 0.15)',
                                textAlign: 'center',
                                borderRadius: 10,
                                borderColor: 'gray',
                                background: 'white',
                                position: 'absolute',
                                top: -150,
                                marginBottom: 20,
                              }}
                            >
                              <div>
                                <h2 style={{ whiteSpace: 'pre-line' }}>
                                  {this.state.showDetailFor.title}
                                </h2>
                                <h5>{this.state.showDetailFor.topLine}</h5>
                                {this.state.subscriptionId ==
                                this.state.showDetailFor.id ? (
                                  ''
                                ) : (
                                  <button
                                    style={{
                                      color: 'white',
                                      backgroundColor: mandy,
                                      border: 0,
                                      padding: 10,
                                      fontWeight: 600,
                                      borderRadius: 15,
                                      width: 120,
                                    }}
                                    onClick={this.upgradeSubscription.bind(
                                      this,
                                      this.state.showDetailFor.id
                                    )}
                                  >
                                    Upgrade plan
                                  </button>
                                )}
                              </div>

                              <div
                                style={{
                                  background: '#EFEFEF',
                                  color: '#aaaaaa',
                                  marginTop: 10,
                                  textAlign: 'left',
                                  paddingLeft: 10,
                                }}
                              >
                                Features
                              </div>

                              {this.state.showDetailFor.benefitsLg.map(
                                (item) => {
                                  return (
                                    <Stack
                                      direction='row'
                                      spacing={2}
                                      style={{
                                        borderBottom: 'solid',
                                        borderBottomColor: '#eceaea',
                                        paddingBlockStart: 20,
                                        paddingBlockEnd: 20,
                                        borderBottomWidth: 1,
                                        borderColor: '#eeeeee',
                                      }}
                                    >
                                      <Grid item lg={2} xs={2} sm={2} md={2}>
                                        <CheckCircleOutlineOutlinedIcon
                                          style={{
                                            fill: 'green',
                                            fontSize: 30,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        style={{ textAlign: 'left' }}
                                        lg={10}
                                        xs={10}
                                        sm={10}
                                        md={10}
                                      >
                                        {item}
                                      </Grid>
                                    </Stack>
                                  );
                                }
                              )}
                              <Grid container>
                                <Grid item lg={12} xs={12} sm={12} md={12}>
                                  <button
                                    style={{
                                      color: 'white',
                                      backgroundColor: mandy,
                                      border: 0,
                                      padding: 10,
                                      fontWeight: 600,
                                      borderRadius: 15,
                                      marginTop: 20,
                                      marginBottom: 20,
                                      width: 120,
                                    }}
                                    onClick={this.upgradeSubscription.bind(
                                      this,
                                      this.state.showDetailFor.id
                                    )}
                                  >
                                    Upgrade plan
                                  </button>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>

                  <AccountExtendedSubContainer>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <h3 className='orangeTitle'>Your Subscription</h3>
                      </Grid>

                      <Grid lg={12} md={12} sm={12} xs={12}>
                        <OwlCarousel className='owl-carousel' {...options}>
                          {Object.keys(WelbeeTypes).map((key) =>
                            WelbeeTypes[key] >= this.state.subscriptionId ? (
                              <div>{this.getSubscriptionCard(key)}</div>
                            ) : (
                              <></>
                            )
                          )}
                        </OwlCarousel>
                      </Grid>

                      <Grid item md={4} xs={12} mx='auto' mt='20px'>
                        {this.state.subscriptionId <
                        WelbeeTypes.WelbeeSurveyPremium ? (
                          <AppButton
                            className='compare-plan'
                            type='submit'
                            onClick={this.handleClickOpen.bind(this, true)}
                          >
                            Compare Plans
                          </AppButton>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </AccountExtendedSubContainer>
                </Grid>
              </Grid>
              {this.state.confirmFreeUpgrade ? (
                <ImagePopup>
                  <button
                    className='crossButton'
                    onClick={() => {
                      this.setState({ confirmFreeUpgrade: false });
                    }}
                  >
                    <img src={removethin} alt='remove button' />
                  </button>
                  <div
                    style={{
                      width: '80%',
                      zIndex: 9999,
                      height: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <UpgradeToFreePopup
                      onBackBtnClick={() => {
                        this.setState({ confirmFreeUpgrade: false });
                      }}
                      isLoggedIn={this.state.userInfo !== null}
                      onCompletion={() => {
                        this.setState({ confirmFreeUpgrade: false });
                        this.onUpgradeRequest();
                      }}
                    />
                  </div>
                </ImagePopup>
              ) : (
                <div />
              )}
              {this.state.isUpgradePopupShow ? (
                <WelbeeVoiceUpgradePopup
                  isOpen={this.state.isUpgradePopupShow}
                  onClose={this.backToConversationClick.bind(this)}
                  onOk={this.backToConversationClick.bind(this)}
                  welbeeType={this.state.selectedWelbeeType}
                />
              ) : null}
              {this.state.isUpgradeInvoiceShow ? (
                <ImagePopup>
                  <button
                    className='crossButton'
                    onClick={this.hideInvoicePopup.bind(this)}
                  >
                    <img src={removethin} alt='remove button' />
                  </button>
                  <div
                    style={{
                      width: '80%',
                      zIndex: 9999,
                      height: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <UpgradeInvoiceLeftPanel
                      onBackBtnClick={this.hideInvoicePopup.bind(this)}
                      isLoggedIn={this.state.userInfo !== null}
                      onCompletion={() => {
                        this.hideInvoicePopup();
                        this.onUpgradeRequest();
                      }}
                      welbeeType={this.state.selectedWelbeeType}
                    />
                  </div>
                </ImagePopup>
              ) : null}
            </div>
          </div>
        </RegisterWrapper>
      </ExtendedActionPlanMainWrapper>
    );
  }
}
export default withRouter(AccountSubscription);
