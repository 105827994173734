import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  DirectorLeftMenu,
  SurveyDropDown,
  MainMATHeader,
} from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils';
import Box from '@mui/material/Box';
import { navigationActions } from '../../store/headerStore';
import MatCycleAdditionalQuestions from './MatCycleAdditionalQuestions';
import MatAdditionalQuestionBank from './MatAdditionalQuestionBank';
import { storageService, matCycleService } from '../../services';
import {
  AssistanceWrapper,
  AdditionalQuestionsMainWrapper,
} from '../../components/common/SchoolQuestionStyle';
import {
  parseJwt,
  getTransformedSurveyRecordList,
} from '../../utils/CommonFunctions';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';

class MatAdditionalQuestions extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);

    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      latestSurveyOption: { label: 'MAT Question Bank', value: 0 },
      createDialogOpen: false,
      latestCycleOptions: [{ label: 'MAT Question Bank', value: 0 }],
      isRole: userInfo.role,
      isSurveyProgressing: false,
      cycleExists: false,
    };
  }

  componentDidMount() {
    const cycle = window.location.search.split('?cycleId=')[1];
    this.props.navigationActions.activeItemAction({
      item: MenuItems.QuestionBank,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    if (cycle != null) {
      this.getMatNamesAndCycleByCycleId(cycle);
      this.setState({ cycleExists: true });
    } else {
      const matGroupId = window.location.search.split('?matGroupId=')[1];
      this.getMatCyclesByMatGroupId(matGroupId);
      this.setState({ cycleExists: false });
    }
  }

  getMatNamesAndCycleByCycleId(cycle) {
    cycle = Number(cycle);
    matCycleService
      .getCyclesNameAndToken(cycle)
      .then((response) => {
        const cycleRecordList = getTransformedSurveyRecordList(response.data);
        cycleRecordList.push({ label: 'MAT Question Bank', value: 0 });
        this.setState({ latestCycleOptions: cycleRecordList });
        var selectedRecord = cycleRecordList.filter(
          (item) => item.value === cycle
        );
        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }
      })
      .catch((error) => console.log(error));
  }

  getMatCyclesByMatGroupId(matGroupId) {
    matGroupId = Number(matGroupId);
    matCycleService
      .getMatCyclesByMatGroupId(matGroupId)
      .then((response) => {
        const cycleRecordList = [{ label: 'MAT Question Bank', value: 0 }];
        var transformedList = getTransformedSurveyRecordList(response.data);
        cycleRecordList.push(...transformedList);
        this.setState({ latestCycleOptions: cycleRecordList });
        var selectedRecord = cycleRecordList.filter((item) => item.value === 0);
        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: cycleRecordList[0] });
        }
      })
      .catch((error) => console.log(error));
  }

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    const MatGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    if (latestSurveyOption.value === 0) {
      window.location.search = '?matGroupId=' + MatGroupId;
    } else {
      window.location.search = '?cycleId=' + latestSurveyOption.value;
    }
  };

  render() {
    const { latestSurveyOption, latestCycleOptions, isRole, cycleExists } =
      this.state;

    return (
      <AdditionalQuestionsMainWrapper>
        <div className='left-container'>
          <DirectorLeftMenu />
        </div>

        <div className='right-container'>
          <MainMATHeader />

          <Box
            sx={{
              display: 'inline-block',
              marginBottom: '30px',
              width: '100%',
            }}
          >
            <AssistanceWrapper>
              <div className='action-heading-wrapper'>
                <span className='action-heading'>My Additional Questions</span>
              </div>
            </AssistanceWrapper>

            <AssistanceWrapper>
              {/* <div className='btn-request-assistance'>
                    <RequestAssistanceButton />
                  </div> */}
              <div className='dd-survey'>
                <SurveyDropDown
                  isRole={isRole}
                  latestSurveyOptions={latestCycleOptions}
                  latestSurveyOption={latestSurveyOption}
                  handleSurveyValueChange={this.handleSurveyValueChange}
                />
              </div>
            </AssistanceWrapper>
          </Box>

          {cycleExists ? (
            <MatCycleAdditionalQuestions />
          ) : (
            <MatAdditionalQuestionBank />
          )}
        </div>
      </AdditionalQuestionsMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MatAdditionalQuestions));
