import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import { loaderAction } from '../../store/loaderStore';
import GroupManagementStandardHeatMap from './GroupManagementStandardHeatMap';
import { IndividualChart, MultiSurveyManagement } from './index';
import { matCycleAdditionalQuestionService, matGroupService, storageService } from '../../services';
import {
  SurveyTypes,
  CategoryList,
  WebStorageNames,
} from '../../utils/Constants';
import { navigationActions } from '../../store/headerStore';
import {
  AssistanceWrapperHeading,
  AssistanceWrapper,
} from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { DirectorLeftMenu, MainMATHeader } from '../../components';
import { schoolSubscriptionService } from '../../services';
import { CommentsMainWrapper, ExtendedSubContainer } from './DashboardStyle';
import { find, isEmpty, isNull } from 'lodash';
import { Tooltip } from '@mui/material';
import { getLikertOptions } from '../../utils/CommonFunctions';

const { mineShaft } = colors;

const Styles = {
  color: '#363636',
  fontweight: '500',
  fontsize: '12px',
  cursor: 'pointer',
  float: 'right',
  width: '150px',
};

class GroupManagementStandards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualResults: null,
      heatMapData: null,
      catogorySurveyAverage: null,
      harrastmentselectiondata: null,
      bullyselectiondata: null,
      IsMultiSurvey: false,
      progressGraphData: null,
      message: 'Loading...',
      searchValue: '',
      latestSurveyOption: [{ value: SurveyTypes.any, label: '' }],
      latestSurveyOptions: [{ value: SurveyTypes.any, label: '' }],
      nextCategory: '',
      previousCategory: '',
      IsSurveyClosed: true,
      currentSurveyId: 0,
      userToken: '',
      isProgressGraphHidden: true,
      groupName: '',
      isMatSchoolsSubscription: false,
      options: {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },

        title: {
          text: '',
        },
        xaxis: {
          categories: ['Results'],
          labels: {
            formatter: function (val) {
              return val + '%';
            },
          },
        },
        colors: ['#FF5939', '#53B5E0', '#FFCF05', '#007A7A', '#F5ABCC'],
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },

        legend: {
          show: false,
        },
      },
    };
  }
  GetMatSchoolsSubscriptions(token) {
    schoolSubscriptionService
      .getMatSchoolSubscriptionsByToken(token)
      .then((response) => {
        let value = response.data;
        this.setState({ isMatSchoolsSubscription: value });
        console.log(this.state.isMatSchoolsSubscription);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(window.location.search);
    const categoryId = urlParams.get('categoryId');
    const token = urlParams.get('token');
    const { loaderAction } = this.props;
    loaderAction.dataLoading(true);
    this.setState({ currentSurveyId: categoryId, userToken: token });
    this.getPreviousAndNextCategory(categoryId);
    this.GetMatSchoolsSubscriptions(token);
    if (token === null)
      this.matGroupServiceDataByCategoryId(queryString, loaderAction);
    else this.matGroupServiceData(queryString, loaderAction);
  }

  matGroupServiceDataByCategoryId(queryString, loaderAction) {
    let matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let matCycleId = storageService.getItem(WebStorageNames.MatCycleId);
    matGroupService
      .getMatGroupResultsByCategoryId(queryString, matGroupId, matCycleId)
      .then((response) => {
        const { success, data } = response;
        loaderAction.dataLoading(false);
        if (success) {
          this.setState({
            individualResults: data.matIndividualResults,
            heatMapData: data.matHeatMapData,
            catogorySurveyAverage: data.matCategoryAverageByCategory,
            harrastmentselectiondata: data.harrastmentselectionList,
            bullyselectiondata: data.bullyselectionList,
            IsSurveyClosed: true,
            groupName: data.GroupName,
          });   
          if (data.matCategoryAverageByCategory.Category === 8)
            this.getAdditionalQuestions(data.matIndividualResults, matCycleId);
          else
            this.setState({individualResults: data.matIndividualResults});     
        } else {
          this.setState({ message: response.message, IsSurveyClosed: false });
        }
      });
  }

  matGroupServiceData(queryString, loaderActions) {
    let matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let matCycleId = storageService.getItem(WebStorageNames.MatCycleId);

    matGroupService
      .getMatGroupResults(queryString, matGroupId, matCycleId)
      .then((response) => {
        const { success, data } = response;
        loaderActions.dataLoading(false);
        if (success) {
          this.setState({
            heatMapData: data.matHeatMapData,
            catogorySurveyAverage: data.matCategoryAverageByCategory,
            harrastmentselectiondata: data.harrastmentselectionList,
            bullyselectiondata: data.bullyselectionList,
            IsSurveyClosed: true,
            groupName: data.GroupName,
          });
          if (data.matCategoryAverageByCategory.Category === 8)
            this.getAdditionalQuestions(data.matIndividualResults, matCycleId);
          else
            this.setState({individualResults: data.matIndividualResults});
          console.log("harrastment data : " + this.state.harrastmentselectiondata)
          console.log("bully data : *" + this.state.bullyselectiondata + "*")
        } else {
          this.setState({ IsSurveyClosed: false });
        }
      });
  }

  getAdditionalQuestions(matIndividualResults, matCycleId) {
    matCycleAdditionalQuestionService.getMatCycleQuestions(matCycleId).then(response => {
      const { success, data } = response;
      if (success) {
        matIndividualResults.QuestionAverages.map((question) => {
          var matQuestion = find(data, (matQuestion) => matQuestion.Order === question.QuestionId);
          console.log('Likehart Format : '+matQuestion.LikertFormat)
          var likertOptions = getLikertOptions(matQuestion.LikertFormat);
          question.LikertOptions = likertOptions.map(option => option.Answer);
          return question;
        });
        this.setState({ individualResults: matIndividualResults });
      }
    });
  }

  getPreviousAndNextCategory = (categoryId) => {
    let previousCategory = null;
    let nextCategory = null;
    var currentIndex = CategoryList.findIndex((x) => x.value == categoryId);
    if (categoryId != 1)
      previousCategory = CategoryList.find((x, index) => {
        if (index == currentIndex - 1) return x;
      });
    if (categoryId != 8)
      nextCategory = CategoryList.find((x, index) => {
        if (index == currentIndex + 1) return x;
      });
    this.setState({
      previousCategory: previousCategory,
      nextCategory: nextCategory,
    });
  };

  handleCategoryPrevNextChange = (token, categoryId) => {
    if (token !== null)
      window.location.search = '?token=' + token + '&categoryId=' + categoryId;
    else window.location.search = '?categoryId=' + categoryId;
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    const categoryId = parseInt(
      window.location.search.split('categoryId=')[1],
      10
    );
    const userToken = window.location.search.split('?token=')[1];
    window.location.search =
      '?token=' + userToken + '&categoryId=' + categoryId;
    this.matGroupServiceData(window.location.search);
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  questionGraphDataPrint = () => {
    let graph = '';
    this.state.matIndividualResults.QuestionSurveyAverages.map((question) => {
      graph += `<div style="background: #FFFFFF; -webkit-print-color-adjust: exact; color: #363636; margin: 15px 0 0; padding: 0;box-sizing: border-box;box-shadow: 3px 3px 24px rgba(0,89,122,0.10);border-radius: 6px;width: 100%; float: left;">
        <div style="box-sizing: border-box; overflow: hidden; max-width: 80%; width: 80%; float: left;">
            <div style="float: left; font-size: 20px;font-weight: 400;padding:0 20px;line-height: 24px;">
              <span style="font-size: 30px;color: #fd92c2; font-weight: 900; float: left; width: 100%; margin-bottom: 17px; padding-top:20px;">
                Q${question.QuestionId}.
              </span>
              ${question.QuestionText}
            </div>
            <div style="width: 93%; float: left; position: relative; font-style: normal; font-style: normal; margin: 50px 20px;">
              <span style="padding-left: 0px; position: absolute; left: -26px;  font-size: 12px; font-weight: 400; padding-right: 0px; height: 69%; top: 1px; transform: rotate(-90deg);">
                <i style="padding-top: 30px; font-style: normal; display: inherit;">
                  Results
                </i>
              </span>
              <div style="display: flex; position: relative; margin-left: 30px; border-left: 1px solid; padding: 10px 0;">`;

      if (question.NeverPercentage > 0) {
        graph += `<div style="width: ${question.NeverPercentage}%; display: inline-block; background: #ff5937; -webkit-print-color-adjust: exact; text-align: center; padding: 27px 0; position: relative;">
          <span style="color: #636363; font-weight: 400; font-size: 10px; position: absolute; top: -22px; width: 100%; text-align: center; left: 0;">
            ${question.NeverPercentage}%
          </span>
        </div>`;
      }

      if (question.SeldomPercentage > 0) {
        graph += `<div style="width:${question.SeldomPercentage}%; display: inline-block; background: #52b5df; -webkit-print-color-adjust: exact; text-align: center; padding: 27px 0; position: relative;">
                    <span style="color: #636363; font-weight: 400; font-size: 10px; position: absolute; top: -22px; width: 100%; text-align: center; left: 0;">
                      ${question.SeldomPercentage}%
                    </span>
                  </div>`;
      }
      if (question.SometimesPercentage > 0) {
        graph += `<div style="width: ${question.SometimesPercentage}%;  display: inline-block; background: #fecf03; -webkit-print-color-adjust: exact; text-align: center; padding: 27px 0; position: relative;">
          <span style="color: #636363; font-weight: 400; font-size: 10px; position: absolute; top: -22px; width: 100%; text-align: center; left: 0;">
            ${question.SometimesPercentage}%
          </span>
        </div>`;
      }
      if (question.OftenPercentage > 0) {
        graph += `<div style="width: ${question.OftenPercentage}%; display: inline-block; background: #007876; -webkit-print-color-adjust: exact; text-align: center; padding: 27px 0; position: relative;">
          <span style=" color: #636363; font-weight: 400;font-size: 10px; position: absolute; top: -22px; width: 100%; text-align: center; left: 0;">
            ${question.OftenPercentage}%
          </span>
        </div>`;
      }
      if (question.AlwaysPercentage > 0) {
        graph += `<div style="width: ${question.AlwaysPercentage}%; display: inline-block; background: #f6abcc; -webkit-print-color-adjust: exact; text-align: center; padding: 27px 0; position: relative;">
          <span style="color: #636363; font-weight: 400; font-size: 10px; position: absolute; top: -22px; width: 100%; text-align: center; left: 0;">
            ${question.AlwaysPercentage}%
          </span>
        </div>`;
      }
      graph += `</div>
              <div style="display: flex; position: relative; margin-top: 0; margin-left: 30px; border-top: 1px solid;">
                <div
                  style="position: relative; width: 10%; display: inline-block; text-align: right; height: 6px;">
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; left: 0; height: 5px; width:2px; border-left: 1px solid #363636;"/>
                  <span style=" text-align: left !important; float: left !important;  padding: 12px 0 0 0;font-size: 12px; font-weight: 400; ">
                    0%
                  </span>
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 5px; float: right">
                    10%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style=" position: relative; width: 10%;  display: inline-block; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400;padding: 12px 0 0 14px; float: right">
                    20%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style=" position:relative;   width: 10%;  display: inline-block;  text-align: right;  height: 6px;">
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 14px; float: right">
                    30%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style=" position: relative; width: 10%; display: inline-block; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400;padding: 12px 0 0 14px; float: right">
                    40%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="width: 10%; display: inline-block; position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 14px; float: right">
                    50%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="width: 10%;  display: inline-block; position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 14px; float: right">
                    60%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="width: 10%;  display: inline-block; position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 14px; float: right">
                    70%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="width: 10%;  display: inline-block; position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400;  padding: 12px 0 0 14px; float: right">
                    80%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="    width: 10%;  display: inline-block; position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400; padding: 12px 0 0 14px; float: right">
                    90%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
                <div style="    width: 10%;  display: inline-block;  position: relative; text-align: right; height: 6px;">
                  <span style="font-size: 12px; font-weight: 400;  padding: 12px 0 0 14px; float: right">
                    100%
                  </span>
                  <img src="../../resources/images/line.jpg" style="position: absolute; top: 0; right: 1px; height: 5px; width:2px; border-right: 1px solid #363636;"/>
                </div>
              </div>
            </div>
          </div>`;

      graph += `<div style=" box-sizing: border-box; width: 100px; text-align: center;color: #7B7B7B;  font-size: 18px;  font-weight: 900; float: right;">
            <div style="float: left;padding: 30px 0; border-left: 1px solid #F3EEEE; border-bottom: 1px solid #F3EEEE; width: 100%;">
            ${question.QuestionAverage}
              <span style="color: #7B7B7B;font-weight: 400; font-size: 12px; display: block; width: 100%;">
                Your score
              </span>
            </div>
          </div>
          <div style="box-sizing: border-box; width: 100px; text-align: center;color: #7B7B7B; font-size: 18px; font-weight: 900;  float: right;">
            <div style="float: left;padding: 30px 0; border-left: 1px solid #F3EEEE; border-bottom: 1px solid #F3EEEE; width: 100%;">
            ${question.SchoolDecilesAverage}
              <span style="color: #7B7B7B;font-weight: 400; font-size: 12px; display: block; width: 100%;">
                School decile
              </span>
            </div>
          </div>
          <div style="box-sizing: border-box; width: 100px; text-align: center;color: #7B7B7B; font-size: 18px; font-weight: 900;  float: right; ">
            <div style="float: left;padding: 30px 0; border-left: 1px solid #F3EEEE; border-bottom: 0; width: 100%;">
            ${question.HseDecilesAverage}
              <span style="color: #7B7B7B;font-weight: 400; font-size: 12px; display: block; width: 100%;">
                HSE decile
              </span>
            </div>
          </div>
        </div>`;
      return graph;
    });

    return graph;
  };

  print = () => {
    const { catogorySurveyAverage } = this.state;
    var html = `
      <html>
        <head>
          <title>Management Standard Graph</title>
        </head>
        <body>
        <div style="width: 100%; float: left; margin-bottom: 25px;">
        <div style="display: block;font-weight: 900; font-size: 24px; color: #363636;margin-bottom: 20px;"> ${
          catogorySurveyAverage !== null
            ? catogorySurveyAverage.CategoryName
            : ''
        } </div>
        <div style="width: 100%; float: left; font-size: 14px; color: #363636; margin-bottom: 20px;">
          This includes issues such as workload, work patterns, working hours and the work environment.
        </div>
        <div style="color: #007A7A;border-top: 2px solid #53B5E0;border-bottom: 2px solid #53B5E0; padding: 24px 60px; width: 100%; margin: 40px 0 40px 0; display: flex; -webkit-box-pack: justify; -webkit-justify-content: space-between;  -ms-flex-pack: justify;
    justify-content: space-between;">
    <ul style="list-style: none;width: 100%;padding: 0; margin: 0;">
	    <li style="color: #53B5E0; font-size: 24px;width: 20%;font-weight: 900;float: left;text-align: center; ">
       ${
         catogorySurveyAverage !== null
           ? catogorySurveyAverage.SchoolDecilesCategoryAverage
           : ''
       }
	   <span style="display: block;width: 71px; font-size: 12px;font-weight: 400;margin: auto; line-height: 19px;margin-top: 10px;">School Decile</span>
	  </li>
	  <li style="color: #53B5E0; font-size: 24px; width: 20%; font-weight: 900; float: left; text-align: center; ">
       ${
         catogorySurveyAverage !== null
           ? catogorySurveyAverage.HseDecilesCategoryAverage
           : ''
       }
	   <span style="display: block;width: 71px; font-size: 12px; font-weight: 400; margin: auto; line-height: 19px; margin-top: 10px; ">HSE Decile</span>
	  </li>

	  <li style="color: #53B5E0; font-size: 24px;width: 20%;font-weight: 900;float: left;text-align: center; ">
       ${
         catogorySurveyAverage !== null
           ? catogorySurveyAverage.OverallCategoryAverage
           : ''
       }
	   <span style="display: block;width: 71px; font-size: 12px;font-weight: 400; margin: auto; line-height: 19px; margin-top: 10px; ">Overall Score</span>
	  </li>
	  <li style="color: #53B5E0; font-size: 24px; width: 20%; font-weight: 900; float: left; text-align: center; ">
    ${
      catogorySurveyAverage !== null
        ? catogorySurveyAverage.SupportStaffCategoryAverage
        : ''
    }
	   <span style="display: block;width: 71px; font-size: 12px;font-weight: 400;margin: auto; line-height: 19px;margin-top: 10px;">Support Staff Score</span>
	  </li>
	  <li style="color: #53B5E0; font-size: 24px;width: 20%;font-weight: 900;float: left;text-align: center; ">
    ${
      catogorySurveyAverage !== null
        ? catogorySurveyAverage.TeachingStaffCategoryAverage
        : ''
    }
	   <span style="display: block;width: 71px; font-size: 12px;font-weight: 400;margin: auto; line-height: 19px;margin-top: 10px; ">Teaching Staff Score</span>
	  </li>
	  </ul>
    </div>
    </div>
          <div style="font-size: 16px; font-weight: 900;  width:100%; margin: auto;">
            Individual questions
            <span style="float: right; height: 31px; width: 486px;">
              <ul style="list-style: none; float: right; width: 100%; text-align: right; font-weight: 400; margin: 5px 0 0 0; padding: 0; ">
                <li style="margin-left: 10px; display: inline-block;">
                <span style="width: 10px; height: 10px; background: #FF5939; -webkit-print-color-adjust: exact; border-radius: 50%; margin-right: 10px; float: left; margin-top: 2px; "></span>
              <span style="font-size: 12px;float: left;">Never</span>
              </li>
              <li style="margin-left: 10px; display: inline-block;">
              <span style="width: 10px; height: 10px; background: #53B5E0; -webkit-print-color-adjust: exact; border-radius: 50%; margin-right: 10px; float: left; margin-top: 2px; "></span>
                <span style="font-size: 12px;float: left;">Seldom</span>
              </li>
              <li style="margin-left: 10px; display: inline-block;">
              <span style="width: 10px; height: 10px; background: #FFCF05; -webkit-print-color-adjust: exact; border-radius: 50%; margin-right: 10px; float: left; margin-top: 2px; "></span>
                <span style="font-size: 12px;float: left;">Sometimes</span>
              </li>
              <li style="margin-left: 10px; display: inline-block;">
              <span style="width: 10px; height: 10px; background: #007A7A; -webkit-print-color-adjust: exact; border-radius: 50%; margin-right: 10px; float: left; margin-top: 2px; "></span>
                <span style="font-size: 12px; float: left;">Often</span>
              </li>
              <li style="margin-left: 10px; display: inline-block;">
              <span style="width: 10px; height: 10px; background: #F5ABCC; -webkit-print-color-adjust: exact; border-radius: 50%; margin-right: 10px; float: left; margin-top: 2px; "></span>
                <span style="font-size: 12px;float: left;">Always</span>
              </li>
              </ul>
            </span>`;

    html += this.questionGraphDataPrint();
    html += `</div>
        </body>
      </html>`;

    var oldPage = document.body.innerHTML;
    document.body.innerHTML = html;
    window.print();
    document.body.innerHTML = oldPage;
    window.location.reload();
  };

  render() {
    const {
      latestSurveyOption,
      individualResults,
      heatMapData,
      catogorySurveyAverage,
      harrastmentselectiondata,
      bullyselectiondata,
      progressGraphData,
      nextCategory,
      previousCategory,
      currentSurveyId,
      userToken,
      isProgressGraphHidden,
      groupName,
    } = this.state;

    return (
      <CommentsMainWrapper>
        <div className='containerClass'>
          <div className='left-container'>
            <DirectorLeftMenu />
          </div>

          <div className='right-container'>
            <MainMATHeader />

            <AssistanceWrapper>
              <AssistanceWrapperHeading>
                <span className='comment-heading'>
                  {catogorySurveyAverage !== null
                    ? catogorySurveyAverage.CategoryName
                    : ''}
                </span>
                <span>{currentSurveyId} of 8 Management Standards</span>
              </AssistanceWrapperHeading>
            </AssistanceWrapper>

            <ExtendedSubContainer
              value={`${
                catogorySurveyAverage != null
                  ? catogorySurveyAverage.CategoryColorName
                  : mineShaft
              }`}
            >
              <div className='inner-white-wrapper'>
                <MultiSurveyManagement
                  individualResults={individualResults}
                  catogorySurveyAverage={catogorySurveyAverage}
                  ProgressGraphData={progressGraphData}
                  IsProgressGraphHidden={isProgressGraphHidden}
                  isAllowed={this.state.isMatSchoolsSubscription}
                />
                {individualResults != null && heatMapData ? (
                  <GroupManagementStandardHeatMap
                    questions={individualResults.QuestionAverages}
                    heatMapData={heatMapData}
                    groupName={groupName}
                    categoryName={catogorySurveyAverage.CategoryName}
                  />
                ) : (
                  <></>
                )}

                <div className='induidual-question-wrapper'>
                  Individual questions
                  {individualResults != null
                    ? individualResults.QuestionAverages.map(
                        (question, index) => {
                          return (
                            <div>
                              <span>
                                {question.Likert ? (
                                  <ul>
                                    <li>{question.Likert.LikertOption1}</li>
                                    <li>{question.Likert.LikertOption2}</li>
                                    <li>{question.Likert.LikertOption3}</li>
                                    <li>{question.Likert.LikertOption4}</li>
                                    <li>{question.Likert.LikertOption5}</li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>Never</li>
                                    <li>Seldom</li>
                                    <li>Sometimes</li>
                                    <li>Often</li>
                                    <li>Always</li>
                                  </ul>
                                )}
                              </span>

                              <div className='graph-main-wrapper'>
                                <div className='graph-wrapper'>
                                  <div
                                    className='question-wrapper'
                                    style={{ width: '95%' }}
                                  >
                                    <span>
                                      Q{question.QuestionId}.
                                      {question.QuestionId === 5 &&
                                      !isNull(
                                        this.state.harrastmentselectiondata
                                      ) &&
                                      !isEmpty(
                                        this.state.harrastmentselectiondata
                                      ) ? (
                                        <span
                                          style={{
                                            fontSize: '13px',
                                            color: 'rgb(54, 54, 54)',
                                            fontweight: '800',
                                            float: 'right',
                                            width: '48%',
                                            lineHeight: 'initial',
                                          }}
                                        >
                                          Number of staff selecting Often or
                                          Always
                                          <select style={Styles}>
                                            <option
                                              value='default'
                                              disabled
                                              selected
                                            >
                                              Harrasment
                                            </option>
                                            {this.state.harrastmentselectiondata.map(
                                              (harrItem) => (
                                                //  <Tooltip title={harrItem}>
                                                <option
                                                  key={harrItem}
                                                  value={harrItem}
                                                  disabled='true'
                                                >
                                                  {harrItem}
                                                </option>
                                                //  </Tooltip>
                                              )
                                            )}
                                          </select>
                                        </span>
                                      ) : question.QuestionId === 21 &&
                                        !isNull(
                                          this.state.bullyselectiondata
                                        ) &&
                                        !isEmpty(
                                          this.state.bullyselectiondata
                                        ) ? (
                                        <span
                                          style={{
                                            fontSize: '13px',
                                            color: 'rgb(54, 54, 54)',
                                            fontweight: '800',
                                            float: 'right',
                                            width: '48%',
                                            lineHeight: 'initial',
                                          }}
                                        >
                                          Number of staff selecting Often or
                                          Always
                                          <select style={Styles}>
                                            <option
                                              value='default'
                                              disabled
                                              selected
                                            >
                                              Bullying
                                            </option>
                                            {this.state.bullyselectiondata.map(
                                              (bullItem) => (
                                                // <Tooltip title={bullItem}>
                                                <option
                                                  key={bullItem}
                                                  value={bullItem}
                                                  disabled='true'
                                                >
                                                  {bullItem}
                                                </option>
                                                // </Tooltip>
                                              )
                                            )}
                                          </select>
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </span>
                                    {question.QuestionText}
                                  </div>
                                  <IndividualChart
                                    options={this.state.options}
                                    data={question}
                                    index={index + 1}
                                  />
                                </div>
                                <div className='results-wrapper'>
                                  <div className='results-wrapper-inner'>
                                    {question.QuestionAverage}
                                    <span>Your Score</span>
                                  </div>
                                  <div className='results-wrapper-inner'>
                                    {question.SchoolDecilesAverage}
                                    <span>School decile</span>
                                  </div>
                                  <div className='results-wrapper-inner'>
                                    {question.HseDecilesAverage}
                                    <span>HSE decile</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : null}
                </div>
                <div className='footer-navigation'>
                  <div
                    className='previous-btn'
                    onClick={() =>
                      this.handleCategoryPrevNextChange(
                        userToken,
                        previousCategory.value
                      )
                    }
                  >
                    {previousCategory !== null ? previousCategory.label : ''}
                  </div>
                  <div
                    className='next-btn'
                    onClick={() =>
                      this.handleCategoryPrevNextChange(
                        userToken,
                        nextCategory.value
                      )
                    }
                  >
                    {nextCategory !== null ? nextCategory.label : ''}
                  </div>
                </div>
              </div>
            </ExtendedSubContainer>
          </div>
        </div>
      </CommentsMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderAction: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(GroupManagementStandards));
