import { actionTypes } from './actionTypes';

const { OPEN_CREATEACTION_POPUP } = actionTypes;

//////////// USER ACTIONS ///////////////

export const openPopUp = value => ({
  type: OPEN_CREATEACTION_POPUP,
  payload: value
});

export const Action = value => ({
  type: OPEN_CREATEACTION_POPUP,
  payload: value
});

export const actionPlanActions = {
  openPopUp
};

//////////// USER REDUCER ///////////////
const INITIAL_STATE = {
  suggestedActionsDialogOpen: false
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === OPEN_CREATEACTION_POPUP) {
    return { ...state, suggestedActionsDialogOpen: action.payload };
  }
  if (action.type === OPEN_CREATEACTION_POPUP) {
    return { ...state, suggestedActionsDialogOpen: action.payload };
  } else {
    return state;
  }
};
