import React, { Component } from 'react';
import { arrowDown, arrowUp } from '../../resources/images';
import { DropdownStyle } from '../../resources/styling/appStyle';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils';
import ActionPlanDropDownOptions from './ActionPlanDropDownOptions';
import ActionPlanDropDownOptionsWithButton from './ActionPlanDropDownOptionsWithButton';

class ActionPlanDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: true,
      accessLevel: 0,
    };
    this.dropDownRef = React.createRef();
  }

  componentDidMount = () => {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
  };
  handleChange = () => {
    this.setState({
      showResults: !this.state.showResults,
    });
  };

  //   handleOptionChange(option) {
  //     //this.setState({ showOption: option });
  //   }

  handleClick = (e) => {
    // if (!this.dropDownRef.current.contains(e.target)) {
    //this.setState({ showResults: false });
    //  }
  };

  render() {
    const {
      options,
      selectedOption,
      onChange,
      id,
      withButton = false,
    } = this.props;

    const { accessLevel } = this.state;
    return (
      <DropdownStyle
        selectedOption={selectedOption}
        ref={this.dropDownRef}
        className='category-inner-wrapper'
      >
        <div
          onClick={this.handleChange}
          className={
            this.state.showResults
              ? 'active-option selected-option'
              : 'selected-option'
          }
          data-test-id='selectOption'
          id={id}
        >
          <label>{selectedOption.label}</label>
          {!this.state.showResults && <img src={arrowDown} alt='Arrow down' />}
          {this.state.showResults && <img src={arrowUp} alt='Arrow up' />}
        </div>

        {this.state.showResults &&
          (withButton ? (
            <ActionPlanDropDownOptionsWithButton
              options={options}
              onChange={onChange}
              selectedOption={selectedOption}
              accessLevel={accessLevel}
              region={this.state.region}
            />
          ) : (
            <ActionPlanDropDownOptions options={options} onChange={onChange} />
          ))}
      </DropdownStyle>
    );
  }
}

export default ActionPlanDropdown;
