import React from 'react';
import { withRouter,NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { ErrorPageWrapper } from '../../components/common/PageNotFoundStyle';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';

const styles =theme => ({
   
  root: {
    display: 'flex',  
    margin:'10% 3% 10% 3%',
    width:'100%',
   
  },
 
})
const NotFound = ({classes}) =>
 <ErrorPageWrapper>
<div className="error-main-div">
<Grid container spacing={2}>

 
<Card className={classes.root}>
<Grid item
  lg={8}
  sm={6}
  xs={6}
  md={8}
  className="error-left-div"
>
<h1>Sorry, we could not find this page</h1>
                   
                   <p> To log in, please click <NavLink to="/"   > here</NavLink>    </p>
                   <p>  To visit our User Guide, please click <a href="https://knowledge.welbee.co.uk/"   target="_self" rel="noopener noreferrer">here</a>   </p>    
                   <p> To find out more about Welbee, please visit our website <a href="https://knowledge.welbee.co.uk/"   target="_self" rel="noopener noreferrer">here</a>  
              </p>
</Grid>

<Grid item
  lg={4}
  sm={6}
  xs={6}
  md={4}
  className="image"
>
  
</Grid>
</Card>



</Grid>
</div>      
 </ErrorPageWrapper>



export default withRouter((withStyles(styles)(NotFound)));