import React from 'react';
import { CircleStyle, SurveyGreetingPresentationStyle } from '../SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
import { QuestionCategoryStyle } from '../../../resources/styling/appStyle';
import { SchoolPPTText } from '../../../utils/CommonFunctions';
import { ManagmentStandards } from '../../../utils/Constants';
import { colors } from '../../../resources/theme';
const getHeatmapCell = (level, value, isDecile, preVal, preDecile = null) => {
  return (
    <div
      className={
       ''
      }
    >
      {isDecile ? (
        <>
          {level}
          <sup>
            {level === 1
              ? 'st'
              : level === 2
              ? 'nd'
              : level === 3
              ? 'rd'
              : 'th'}
          </sup>{' '}
          {preDecile ? (
            <span className='score-superscript'>
              ({preDecile}
              <sup>
                {preDecile === 1
                  ? 'st'
                  : preDecile === 2
                  ? 'nd'
                  : preDecile === 3
                  ? 'rd'
                  : 'th'}
              </sup>
              )
            </span>
          ) : (
            ''
          )}
        </>
      ) : preVal > 0 ? (
        <>
          {value?.toFixed(2)}{' '}
          <span className='score-superscript'> ({preVal?.toFixed(2)})</span>
        </>
      ) : (
        value?.toFixed(2)
      )}
    </div>
  );
};
const ChangeAvgScore = (props) => {
  const pptText = SchoolPPTText(props?.region, ManagmentStandards.Change);
  let slideWidth = (props?.TopStrength?.length>0 && props?.TopWeaknesses?.length>0)? '50%' :'100%';
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section mgt' id='summery-score'>

          <h1 className='change-title mgt-title'>{props.title}</h1>
          <p className='pres-text'>{pptText}</p>

          <div className='section management-standards'>
            <div className='management-scores'>
              <CircleStyle backgroundColor={colors.purple}>
                <div className='circle-text'>
                <span>Our<br/>Score</span>
                  <span className='decile-type circle-scrore'>
                  {getHeatmapCell(
                    props?.Change?.SDOverallCatAvg,
                    props?.Change?.OverallCategoryAverage,
                    false,
                    null,
                    null
                  )}
                  </span>             
                </div>
              </CircleStyle>
              <CircleStyle backgroundColor={colors.pictonBlue}>
                <div className='circle-text'>
                <span>Average<br/>Score</span>
                  <span className='decile-type circle-scrore'>
                  {getHeatmapCell(
                    props?.Change.SDOverallCatAvg,
                    props?.AverageScore,
                    false,
                    null,
                    null
                  )}
                  </span>              
                </div>
              </CircleStyle>

              <CircleStyle backgroundColor={colors.teal}>
                <div className='circle-text'>
                <span>School<br/>Decile</span>
                  <span className='decile-type'>   {getHeatmapCell(
                    props?.Change?.SchoolDecileType,
                    props?.Change?.OverallCategoryAverage,
                    true,
                    null,
                    props?.Change?.PreviousSchoolDecilesCategoryAverage
                  )} </span>            
                </div>
              </CircleStyle>

              <CircleStyle backgroundColor={colors.mandy}>
                <div className='circle-text'>
                <span>HSE<br/>Decile</span>
                  <span className='decile-type'>
                  {getHeatmapCell(
                    props?.Change?.HseDecileType,
                    props?.Change?.HseDecileType,
                    true,
                    null,
                    props?.Change?.PreviousHseDecilesCategoryAverage
                  )}
                  </span>            
                </div>
              </CircleStyle>
            </div>
            {props?.TopStrength?.length > 0 && (
            <div className={'strenght'}>
              <QuestionCategoryStyle
                className='survey-category-slide survey-evaluation'
                subType={'strength'}
                id={1}
                dynamicWidth ={slideWidth}
              >
                <h1 className='big-title'>{'Strength'}</h1>
                {props?.TopStrength?.map((item, index) => {
                  return (
                    <p className='content number-counter' key={index}>
                      <span className='silde-content'>
                        <i className='counter-number'> {index + 1}) </i>
                        {item.Key}
                      </span>
                      <span className='small-percentag'>{item.Value}</span>
                    </p>
                  );
                })}
              </QuestionCategoryStyle>
            </div>)}
            {props?.TopWeaknesses?.length > 0 && (
            <div className='weekness'>
              <QuestionCategoryStyle
                className='survey-category-slide survey-evaluation'
                subType={'weakness'}
                id={1}
                dynamicWidth ={slideWidth}
              >
                <h1 className='big-title'>{'Areas for Improvement'}</h1>
                {props?.TopWeaknesses?.map((item, index) => {
                  return (
                    <p className='content number-counter' key={index}>
                      <span className='silde-content'>
                        <i className='counter-number'> {index + 1}) </i>
                        {item.Key}
                      </span>
                      <span className='small-percentag'>{item.Value}</span>
                    </p>
                  );
                })}
              </QuestionCategoryStyle>
            </div>
            )}
          </div>
          <span className='app-logo mgt-logo'><img src={props.welbeeslideslogo} alt="" /></span>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default ChangeAvgScore;
