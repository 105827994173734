import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { storageService } from '../../services';
import LoginWrapper from '../../components/common/LoginWrapper';
import SchoolDetailForm from './UserDetailForm';

function SchoolDetails(props) {
  useEffect(() => {
    amplitudeLog('PageView', 'SchoolDetails', '');
    storageService.clear();
  }, {});

  return (
    <LoginWrapper>
      <SchoolDetailForm />
    </LoginWrapper>
  );
}

export default withRouter(SchoolDetails);
