import React from 'react';
import { AppLogo } from '../../components';
import { colors } from '../../resources/theme/colors';
import { QuestionCategoryPresentationStyle } from '../../resources/styling/appStyle';

const QuestionCategoryPresentation = ({ category, content, id }) => {
  return (
    <QuestionCategoryPresentationStyle
      className="survey-category-slide"
      id={id}
    >
      <div>
        <div>
          <h1 className="big-title">{category}</h1>
          <p className="content">{content}</p>
        </div>
      </div>
      <AppLogo type="overview" color={colors.blackColor} width="100" />
    </QuestionCategoryPresentationStyle>
  );
};

export default QuestionCategoryPresentation;
