import React from 'react';
import {
  WrapperStyle,
  DescriptionStyle,
  Title,
  SeparatorStyle,
} from '../../resources/styling/surveyReportStyle';
import ReportScores from './ReportScores';
import RoleWiseReportScores from './RoleWiseReportScores';
import SuggestedPriorityActionsView from './SuggestedPriorityActionsView';
import ReportResultsSection from './ReportResultsSection';
import {
  limitAverageValue,
  scoreNotAvailableValue,
} from '../../utils/roundScore';
import {
  ViewIndividualAnswers,
  AreasForReviewAndPossibleFocus,
} from './Constants';

const RoleReport = ({ report, onButtonClick, isPaid }) => {
  const {
    ResultsText,
    HseStrengths,
    HseWeaknesses,
    SchoolStrengths,
    SchoolWeaknesses,
    SuggestedPriorityActions,
    Score,
  } = report;
  return (
    <WrapperStyle>
      <h1 className='report-title'>{isPaid ? 5 : 6}. Role</h1>
      <p id='roleTitle'>
        Whether people understand their role and whether the school ensures that
        they do not have conflicting roles.
      </p>
      <button onClick={onButtonClick}>{ViewIndividualAnswers}</button>
      <p id='demandsTitle' style={{ 'text-align': 'center' }}>
        Overall Scores and Deciles
      </p>
      <ReportScores
        Score={Score}
        id='roleScore'
        className='report-score'
        isPaid={isPaid}
      />
      {scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.MiddleLeaderCatAvg)) ===
        '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.SeniorLeaderCatAvg)) ===
        '-1.00' ? (
        ''
      ) : (
        <>
          <br />
          <p id='demandsTitle' style={{ 'text-align': 'center' }}>
            Scores by Job Role
          </p>
          <RoleWiseReportScores
            Score={Score}
            id='RoleWiseroleScore'
            className='report-score'
          />
        </>
      )}
      <DescriptionStyle id='roleDescription'>
        <h1 className='report-list-wrapper'>Description</h1>
        <Title className='description-title'>
          The Management Standard is that:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            Staff indicate that they understand their role and responsibilities.
          </li>
          <li>
            Systems are in place locally to respond to any individual concerns.
          </li>
        </ul>
        <Title className='description-title'>
          This means that where possible:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            The school ensures that, as far as possible, the different
            requirements it places upon staff are compatible.
          </li>
          <li>
            The school provides information to enable staff to understand their
            role and responsibilities.
          </li>
          <li>
            The school ensures that, as far as possible, the requirements it
            places upon staff are clear.
          </li>
          <li>
            Systems are in place to enable staff to raise concerns about any
            uncertainties or conflicts they have in their role and
            responsibilities.
          </li>
        </ul>
      </DescriptionStyle>

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <ReportResultsSection
            id='roleResults'
            ResultsText={ResultsText}
            SchoolStrengths={SchoolStrengths}
            SchoolWeaknesses={SchoolWeaknesses}
            HseStrengths={HseStrengths}
            HseWeaknesses={HseWeaknesses}
            AreasForReviewAndPossibleFocus={AreasForReviewAndPossibleFocus}
          />
        </div>
      ) : null}

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <SuggestedPriorityActionsView
            id='roleSuggestions'
            heading='Suggested Priority Actions for Role'
            SuggestedPriorityActions={SuggestedPriorityActions}
          />
        </div>
      ) : null}
      <div id='change' />
    </WrapperStyle>
  );
};

export default RoleReport;
