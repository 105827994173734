import request from '../request';
import { voiceUpgradeAPI } from './urls';

export const upgradeRequest = (id, welbeeType) => {
  return request({
    url: voiceUpgradeAPI.upgradeRequest(id, welbeeType),
    method: 'POST',
  })
    .then((response) => response)
    .catch((response) => response);
};

const VoiceUpgradeService = {
  upgradeRequest,
};

export default VoiceUpgradeService;
