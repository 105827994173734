import React from 'react';
import { colors } from '../../resources/theme';
import { AppButton } from '../../resources/styling';
import { iconTaskless } from '../../resources/images/index';
import { HeadTeacherAccess } from '../../utils/'
import { FooterButtonsWrapper, CreateActionPlanWrapper } from './ActionPlanStyle';
import AccessLevel from '../../components/common/AccessLevel';
import {
  AccessPermissions,
  Roles
} from '../../utils/Constants';

const { yourpink } = colors;

const CreateActionPlan = ({ isOpen, isArchived, accessLevel }) => {
  return (
    <CreateActionPlanWrapper>
      {!isArchived ? (
        <div className="create-action-container">
          <img src={iconTaskless} alt="Create Action" />
          <h2>You’re currently taskless!</h2>
          <p>
            Click below to create your own action or choose one from our
            suggested actions.
          </p>
          <FooterButtonsWrapper>
            <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.Admin]} >
              <AppButton color={yourpink} type="button" onClick={isOpen}>
                Create action
              </AppButton>
            </AccessLevel>
          </FooterButtonsWrapper>
        </div>
      ) : (
        <span>No archived actions.</span>
      )}
    </CreateActionPlanWrapper>
  );
};

export default CreateActionPlan;
