import React from 'react';
import 'chart.js';
import { Line } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Tooltip } from '@mui/material';


export default function AnalyticsLineChart(props) {
  const [isLoader, setIsLoader] = React.useState(false);
  const downloadChart = (id) => {
    setIsLoader(true);
    let chartId = "lineChart"+id;
    const imageLink = document.createElement('a');
    const canvas = document.getElementById(chartId);
    imageLink.download = 'LineChart.png';
    imageLink.href = canvas.toDataURL('image/png', 1);
    
    if (!canvas) {
      console.error(`Canvas element with id '${chartId}' not found.`);
      return;
    }
    const padding = 10; 
  
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = canvas.width + 2 * padding;
    newCanvas.height = canvas.height + 2 * padding;
    context.fillStyle = '#ffffff'; // Set the background color to white
    context.fillRect(0, 0, newCanvas.width, newCanvas.height);
  
    context.drawImage(canvas, padding, padding);
  
    imageLink.download = 'lineChart.png';
    imageLink.href = newCanvas.toDataURL('image/png');
  
    imageLink.click();
    setIsLoader(false);
  };
  const options = {
    spanGaps: true,
    responsive: true,
    font: {
      family: 'Avenir LT Pro'
    },
    scales: {
      y: {
        suggestedMin: 1,
        suggestedMax: 5,
        ticks: {
          color: '#000',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold'
          },
        }
      },
      x: {
        display: true,
        grid: {
          display: true,
          zeroLineColor: "transparent"
        },
        ticks: {
          display:true,
          color: '#000',
          weight: 'bold',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold'
          },
        }
      },
    
    },
    plugins: {
      spanGaps: true,
      font: {
        family: 'Avenir LT Pro'
      },
      title: {
        font: {
          size: 14,
          family: 'Avenir LT Pro'
        },
        color: '#000',
        display: true,
        text: props.Chartlabel,
      },
      datalabels: {
        display: false,
        color: "black"
      },
      legend: {
        labels: {
          color: '#000',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold'
          }
        },
        position: 'bottom'
      },
      tooltip: {
        callbacks: {
          label(context) {
            return context.chart.data.datasets[context.datasetIndex].label + ': ' + context.formattedValue + ' Decile (' + context.chart.data.datasets[context.datasetIndex].decileData[context.dataIndex] + ')';
          }
        }
      }
    }
  };

  let data = {
    labels: props.datalables,
    datasets: props.datasets
  }
  return (
    <>
      {props.children}
      <div className='large-download-btn'>
        <Tooltip title="Download Chart" arrow>
        {isLoader ? (
            <CircularProgress />
          ) : (
            <ArrowCircleDownIcon
              className='large-graph-btn'
              fontSize='inherit'
              onClick={() =>downloadChart(props?.id)}
            />
          )}
        </Tooltip>
      </div>
      {props.datasets !== 0 && props.datasets !== 'undefined' ? (
        <Line type='line' id={"lineChart"+props?.id} height={110} data={data} options={options} />
      ) : (<Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>)}
    </>
  );
};
