import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Routes } from './routes';
import { store } from './store';
import ReactGA from 'react-ga';
import amplitude from 'amplitude-js';
import { config } from './utils/config';
import { hotjar } from 'react-hotjar';
import AppLoader from './components/loader/AppLoader';
import LoggerService from './services/api/LoggerService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { PermissionProvider } from "react-permission-role";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Avenir LT Pro',
    ].join(','),
  }
});

ReactGA.initialize(config.GOOGLE_ANALYTICS_KEY, {
  gaOptions: { allowLinker: true },
});

ReactGA.pageview(window.location.pathname + window.location.search);
hotjar.initialize(config.HOTJAR.hjid, config.HOTJAR.hjsv);
amplitude.init(config.AMPLITUDE_KEY);

class App extends Component {
  componentDidMount() {
    ReactGA.set('user_id', '5');
    window.onerror = (message, file, line, column, errorObject) => {
      LoggerService.log(message, file, line, column, errorObject);
    };
  }
  render() {
    return (
      <PermissionProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <AppLoader />
            <Routes />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Provider>
        </ThemeProvider>
      </PermissionProvider>
    );
  }
}
export default App;
