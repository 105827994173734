import { matGroupAPI } from './urls';
import request from '../request';

export const getAllMatGroups = () => {
  return request({
    url: matGroupAPI.getAllGroups,
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatGroup = (token = null, matCycleId = 0) => {
  token = token === null ? '' : token;
  return request({
    url: matGroupAPI.getMatGroup(token, matCycleId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const createMatSurvey = (data) => {
  return request({
    url: matGroupAPI.createMatSurvey,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const closeMatSurvey = (data) => {
  return request({
    url: matGroupAPI.closeMatSurvey,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const addMatGroup = (model) => {
  return request({
    url: matGroupAPI.addMatGroup,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const deleteMatGroup = (id) => {
  // DO SOMETHING
};

export const getGroupHeatMap = (id) => {
  return request({
    url: matGroupAPI.getGroupHeatMap(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatById = (id) => {
  return request({
    url: matGroupAPI.getMatById(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const setCanCreateSurvey = (model) => {
  return request({
    url: matGroupAPI.setCanCreateSurvey,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getGroupPresentationReport = (
  token,
  matGroupId,
  matCycleId,
  IsSurveyClosed
) => {
  return request({
    url: matGroupAPI.getGroupPresentation(
      token,
      matGroupId,
      matCycleId,
      IsSurveyClosed
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatGroupResults = (token, matGroupId, matCycleId) => {
  return request({
    url: matGroupAPI.getMatGroupResults(token, matGroupId, matCycleId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatGroupResultsByCategoryId = (
  categoryId,
  matGroupId,
  matCycleId
) => {
  return request({
    url: matGroupAPI.getMatGroupResultsByCategoryId(
      categoryId,
      matGroupId,
      matCycleId
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getMatSchools = (groupId = 0) => {
  return request({
    url: matGroupAPI.getMatSchools(groupId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getMatSchoolsWithOpenSurveyForCycle = (groupId = 0) => {
  return request({
    url: matGroupAPI.getMatSchoolsWithOpenSurveyForCycle(groupId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const removeSchoolFromGroup = (schoolId, groupId) => {
  return request({
    url: matGroupAPI.removeSchoolFromGroup(schoolId, groupId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const addSchoolInGroup = (schoolId, groupId) => {
  return request({
    url: matGroupAPI.addSchoolInGroup(schoolId, groupId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateMatUsers = (model) => {
  return request({
    url: matGroupAPI.updateMatUsers,
    method: 'PUT',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const archiveGroup = (groupId) => {
  return request({
    url: matGroupAPI.archiveGroup(groupId),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getMatGroupLogo = (token, matId) => {
  return request({
    url: matGroupAPI.getMatGroupLogo(token, matId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getMatGroupReport = (matId, matCycleId) => {
  return request({
    url: matGroupAPI.GetMatReport(matId, matCycleId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const GetMatReportPdf = (matId, matCycleId) => {
  return request({
    url: matGroupAPI.GetMatReportPdf(matId, matCycleId),
    method: 'POST',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatDetailByUserContext = (matId, matCycleId) => {
  return request({
    url: matGroupAPI.getMatDetailByUserContext(matId, matCycleId),
    method: 'GET'
  })
    .then((response) => response)
    .catch((response) => response);
};

const matGroupService = {
  getMatGroupLogo,
  getAllMatGroups,
  getMatGroup,
  createMatSurvey,
  closeMatSurvey,
  addMatGroup,
  deleteMatGroup,
  getGroupHeatMap,
  getMatById,
  setCanCreateSurvey,
  getGroupPresentationReport,
  getMatGroupResults,
  getMatGroupResultsByCategoryId,
  getMatSchools,
  removeSchoolFromGroup,
  addSchoolInGroup,
  updateMatUsers,
  archiveGroup,
  getMatGroupReport,
  GetMatReportPdf,
  getMatSchoolsWithOpenSurveyForCycle,
  getMatDetailByUserContext,
};

export default matGroupService;
