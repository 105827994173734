import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { useTranslation } from 'react-i18next';
// import { LeftSectionStyle } from '../../resources/styling/appStyle';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';

const getHeatmapCell = (level, value, isDecile, preVal, preDecile = null) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10' 
      }
    >
      {isDecile ? (
        <>
          {level}
          <sup>
            {level === 1
              ? 'st'
              : level === 2
              ? 'nd'
              : level === 3
              ? 'rd'
              : 'th'}
          </sup>{' '}
          {preDecile ? (
            <span className='score-superscript'>
              ({preDecile}
              <sup>
                {preDecile === 1
                  ? 'st'
                  : preDecile === 2
                  ? 'nd'
                  : preDecile === 3
                  ? 'rd'
                  : 'th'}
              </sup>
              )
            </span>
          ) : (
            ''
          )}
        </>
      ) : preVal ? (
        <>
          {value.toFixed(2)}{' '}
          <span className='score-superscript'> ({preVal.toFixed(2)})</span>
        </>
      ) : (
        value.toFixed(2)
      )}
    </div>
  );
};
const AverageScoresAllParticipantsPpt = (props) => {
  const { t } = useTranslation();
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>{props.title}</h1>
          <p className='section'>
            Heatmap 1 scores are out of 5, with higher scores showing that risks
            relating to workplace stress and poor wellbeing are more effectively
            managed. The school decile shows your performance against the school
            benchmark, with 1st Decile being in the top 10% of schools taking
            the survey with us, and 10th Decile meaning more than 90% of staff
            in other schools have scored more highly. Comparatively high scores
            (3rd school decile and higher) are shaded green and comparatively
            lower ones (7th school decile and lower) red. Those without shading
            are close to the average score for schools. The HSE Decile shows
            your comparative scores against the Health and Safety Executive's
            Benchmark of 136 cross sector {t('organisations')}.
          </p>
          <p className='table-title'>
            Heatmap 1: Average Score for All Survey Participants
          </p>
          <div className='section'>
            <div className='job-role-container'>
              <div className='stats-title-cell'>Management Standards</div>
              <div className='stats-role-cell'>Score</div>
              <div className='stats-role-cell'>School Decile</div>
              <div className='stats-role-cell'>HSE Decile</div>
            </div>
            {props.matAvg.map((row) => {
              return (
                <div className='job-role-container'>
                  <div className='job-role-title-cell'>{row.CategoryName}</div>
                  {getHeatmapCell(
                    row.SchoolDecileType,
                    row.OverallCategoryAverage,
                    false,
                    row.PreviousAverage,
                    null
                  )}
                  {getHeatmapCell(
                    row.SchoolDecileType,
                    row.OverallCategoryAverage,
                    true,
                    null,
                    row.PreviousSchoolDecileType
                  )}
                  {getHeatmapCell(
                    row.HseDecileType,
                    row.HseDecileType,
                    true,
                    null,
                    row.PresviousHseDecileType
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default AverageScoresAllParticipantsPpt;
