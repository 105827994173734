import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { bindActionCreators } from 'redux';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  iconAccount,
  iconActionPlan,
  iconAnalytics,
  iconComments,
  iconConversation,
  iconDashboard,
  iconHeatmap,
  iconPresentation,
  iconQuestionBank,
  iconReport,
  iconStaffManagement,
  iconUserGuide,
  welbeeDashboardLogo,
} from '../../resources/images/index';
import { routesObj } from '../../routes';
import {
  schoolSubscriptionService,
  staffResultServices,
  storageService,
  accountService,
  schoolService,
  surveyReportService,
} from '../../services';
import { navigationActions } from '../../store/headerStore';
import {
  HeadTeacherAccess,
  MenuItems,
  QueryStringTokens,
  toggleOption,
  WebStorageNames,
} from '../../utils';
import {
  amplitudeLog,
  DateFormatWithMonth,
  freePeriodNoOfDaysLeft,
  getQueryStringVal,
  isUpgradeVoideDisplay,
} from '../../utils/CommonFunctions';
import {
  Roles,
  UserGuideSurveyLink,
  UserGuideVoiceLink,
  WelbeeTypes,
  AccessPermissions,
} from '../../utils/Constants';

import GuidedTourPremium from '../../components/common/GuidedTourPremium';
import GuidedTourPro from '../../components/common/GuidedTourPro';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import IdleTimerContainer from './IdleTimerContainer';
import AccessLevel from '../../components/common/AccessLevel';


const styles = {
  contentHeaderMenuLink: {
    textDecoration: 'none',
    color: '#ffffff',
    background: '#FF5939',
    width: 38,
    height: 38,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '53px',
    borderRadius: '8px',
  },
};

const mql = window.matchMedia('(min-width: 1000px)');

class LeftMenu extends Component {
  constructor(props) {
    super();

    this.state = {
      navActiveMenu:
        this.toggleButton === toggleOption.WelbeeVoice
          ? MenuItems.Conversations
          : '',
      voiceMenu: false,
      schoolLogo: props.logo || null,
      accessLevel: 0,
      isUpgrade: true,
      welbeeType: 0,
      showAccount: true,
      freePeriodEndDate: new Date(),
      role: '',
      isMat: false,
      MatHeatMap: 'Mat Heat Map',
      hasQuestionBank: false,
      isDisplayMatHeatMap: false,
      isPaid: false,
      docked: mql.matches,
      open: false,
      openGuidedTour: false,
      schoolSubscription: '',
      showReportMenu: false,
      hasSummaryReport: false,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  setSubscription = (schoolId) => {
    schoolSubscriptionService
      .getSchoolSubscriptionsById(schoolId)
      .then((response) => {
        this.setState({
          schoolSubscription: response.data.SubscriptionId,
        });
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );

        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.getRegions();
    this.checkSummaryReport();
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const { data } = response;
        if (data) {
          var schoolId = response.data.Id;
          if (response.data.MatGroupId == null) {
            storageService.setItem(
              WebStorageNames.IsDisplayMatHeatMap,
              'false'
            );
          }

          schoolSubscriptionService
            .getSchoolSubscriptionsById(schoolId)
            .then((response) => {
              this.setState({
                schoolSubscription: response.data.SubscriptionId,
              });

              if (response.data.MatGroupId == null) {
                storageService.setItem(
                  WebStorageNames.IsDisplayMatHeatMap,
                  'false'
                );
              }
            })
            .catch((error) => console.log(error));
        }
      });
    } else {
      const schoolId = storageService.getItem(WebStorageNames.SchoolId);
      this.setSubscription(schoolId);
    }
  }
  getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false,
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }
  checkSummaryReport() {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    surveyReportService.checkSummaryReport(schoolId).then((response) => {
      this.setState({ hasSummaryReport: response.data });
    });
    //this.setState({ hasSummaryReport: true });
  }

  componentWillMount() {
    if (!isEmpty(window.location.search)) {
      let surveyToken = getQueryStringVal(QueryStringTokens.token);
      if (surveyToken != null) {
        storageService.setItem(WebStorageNames.SurveyToken, surveyToken);
      }
      let currentSchoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
      if (currentSchoolToken != null && currentSchoolToken != 'null') {
        storageService.setItem(WebStorageNames.SchoolToken, currentSchoolToken);
      }
    }
    IsActionAllowed(RestrictedActions.QuestionBank)
      .then((response) => this.setState({ hasQuestionBank: response.data }))
      .catch();
    IsActionAllowed(RestrictedActions.Report)
      .then((response) => this.setState({ isPaid: response.data }))
      .catch();
    //let schoolToken = storageService.getItem(WebStorageNames.SchoolToken);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let userToken = getQueryStringVal(QueryStringTokens.token);
    let welbeeType = storageService.getItem(WebStorageNames.SubscriptionId);
    this.setState({ welbeeType: welbeeType });
    let schoolLogoUrl =
      this.props.logo || storageService.getItem(WebStorageNames.schoolLogoUrl);
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const isStaff = storageService.getItem(WebStorageNames.IsStaff);
    const freeTrialEndDate = storageService.getItem(
      WebStorageNames.FreeTrialEndDate
    );
    const IsUpgradBannderDisplayed = storageService.getItem(
      WebStorageNames.IsUpgradBannderDisplayed
    );
    const role = storageService.getItem(WebStorageNames.Role);
    const isMat = storageService.getItem(WebStorageNames.IsMat);

    this.setState({
      isDisplayMatHeatMap: storageService.getItem(
        WebStorageNames.IsDisplayMatHeatMap
      ),
    });
    console.log('schoolLogoUrl', schoolLogoUrl);
    this.setState({ schoolLogo: schoolLogoUrl, role: role });
    if (
      welbeeType === WelbeeTypes.WelbeeVoiceFree ||
      welbeeType === WelbeeTypes.WelbeeVoicePro
    ) {
      this.setState({
        voiceMenu: true,
        isUpgrade: true,
        freePeriodEndDate: freeTrialEndDate,
        IsUpgradBannderDisplayed: IsUpgradBannderDisplayed,
        navActiveMenu: MenuItems.UserGuide,
      });
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    }
    if (welbeeType === WelbeeTypes.WelbeeVoicePro) {
      this.setState({
        voiceMenu: true,
        navActiveMenu: MenuItems.UserGuide,
      });
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    }
    const selectedTab = storageService.getItem(WebStorageNames.SelectedTab);
    if (selectedTab === 1) {
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    } else {
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeSurvey);
    }
    this.setState({
      accessLevel: accessLevel,
      welbeeType: welbeeType,
      isStaff: isStaff,
      isMat: isMat,
    });
    this.countDown();

    staffResultServices
      .getGroupName(schoolToken, userToken)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          this.setState({
            MAT: data.GroupName,
          });
        }
      })
      .catch();

    //This is copied here from duplicated ConpunentWillMount function for code refactoring
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.logo !== this.props.logo) {
      this.setState({
        schoolLogo: nextProps.logo,
      });
    }
  };

  countDown() {
    let secound = 5;
    let myInterval = setInterval(() => {
      if (secound > 0) {
        secound = secound - 1;
      }
      if (secound === 0) {
        this.setState({ showAccount: false });
        storageService.setItem(WebStorageNames.IsUpgradBannderDisplayed, true);
        clearInterval(myInterval);
      }
    }, 1000);
  }

  redirectToRoute(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const route = routeName.split('?')[0];
        const { data } = response;
        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
        if (data) {
          //var obj = storageService.getItem(`cache_${response.data.Name}`);
          //const { surveyToken } = obj;

          this.props.history.push(
            `${route}?token=${surveyToken}&schoolToken=${schoolToken}`
          );
        } else {
          this.props.history.push(`${route}?schoolToken=${schoolToken}`);
        }
      });
    } else {
      this.props.history.push(routeName);
    }
  }

  redirectToRouteWithToken(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const { data } = response;
        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
        if (data) {
          var obj = storageService.getItem(`cache_${response.data.Name}`);
          const { schoolToken, surveyToken } = obj;
          this.redirectToRoute(
            routeName + '?schoolToken=' + schoolToken + '&token=' + surveyToken
          );
        } else {
          let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
          this.redirectToRoute(routeName + '?token=' + surveyToken);
        }
      });
    } else {
      let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
      this.redirectToRoute(routeName + '?token=' + surveyToken);
    }
  }

  redirectToRouteWithSchoolId(routeName) {
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);
    this.redirectToRoute(routeName + '?schoolId=' + schoolId);
  }

  redirectToRouteWithSchoolToken(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    var route = `${routeName}?schoolToken=${schoolToken}&IsMatSchoolFromHeatmap=false`;
    console.log('route', route);
    this.redirectToRoute(route);
  }

  redirectToRouteWithSurveyandSchoolToken(routeName, schoolTokenParam) {
    console.log('schoolTokenParam', schoolTokenParam);
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    if (schoolTokenParam)
      schoolService.getSchool(schoolTokenParam).then((response) => {
        // var obj = storageService.getItem(`cache_${response.data.Name}`);
        // const { schoolToken, surveyToken } = obj;
        var route = `${routeName}?schoolToken=${schoolTokenParam}&token=${surveyToken}`;
        console.log('route', route);
        this.redirectToRoute(route);
      });
  }

  onClickHandler = ({ name, showReportSubMenu }) => {
    if (name === MenuItems.Report && this.props.disableReportMenu === true)
      return;
    this.props.actions.activeItemAction({
      item: name,
      showReportSubMenu,
    });
  };

  onClickHandlerReportMenu = ({ name, showReportMenu }) => {
    if (name === MenuItems.Report && this.props.disableReportMenu === true)
      return;

    this.setState({ showReportMenu: showReportMenu });
  };
  onLogoutClickHandler = () => {
    storageService.clear();
    this.props.history.push(routesObj.Login.path);
  };

  navigateBackToReport() {
    const pathName = window.location.pathname;
    if (pathName === routesObj.ManagementStandards.path) {
      this.redirectToRouteWithToken(routesObj.SurveyReport.path);
    }
  }

  ontoggleButtonSwitch = (option) => {
    storageService.setItem(WebStorageNames.SelectedTab, option);
    this.props.actions.toggleButtonSwitch(option);
  };
  schoolTokenExist() { }
  getStaffManagementMenu(eventName, navActiveMenu) {
    return (
      <li className='voice-nav menu-staff'>
        <a
          href
          onClick={() => {
            this.onClickHandler({
              name: MenuItems.StaffManagement,
              showReportSubMenu: false,
            });
            amplitudeLog(eventName, 'SideMenu' + eventName, '');
            if (this.state.role === Roles.GroupDirector) {
              this.redirectToRouteWithSchoolToken(
                routesObj.StaffManagement.path
              );
            } else this.redirectToRoute(routesObj.StaffManagement.path);
          }}
          className={
            navActiveMenu === MenuItems.StaffManagement ? 'active' : ''
          }
        >
          <img src={iconStaffManagement} alt='' /> <span>Staff Management</span>
        </a>
      </li>
    );
  }

  TourClickHandler = () => {
    this.setState({ openGuidedTour: true });
  };

  closeGuidedTour = () => {
    this.setState({ openGuidedTour: false });
  };

  toggleTour() {
    if (
      this.state.schoolSubscription !== 3 &&
      this.state.schoolSubscription !== 1
    ) {
      return (
        <li className='btn-tour'>
          <a
            href
            onClick={() => {
              this.TourClickHandler();
            }}
          >
            Start Tour
          </a>
        </li>
      );
    }
  }

  getLogoutMenu() {
    return (
      <li className='btn-logout'>
        <a
          href
          onClick={() => {
            this.onLogoutClickHandler();
          }}
        >
          Logout
        </a>
      </li>
    );
  }

  getUpgradePlan() {
    return this.state.welbeeType === WelbeeTypes.WelbeeSurveyPremium ? null : (
      <li className='btn-upgrade'>
        <a
          href
          onClick={() => {
            this.props.history.push(routesObj.AccountSubscription.path);
          }}
        >
          Upgrade Plan
        </a>
      </li>
    );
  }

  onUpgradeXClick = () => {
    storageService.setItem(WebStorageNames.IsUpgradeDisplay, false);
    this.setState({ isUpgrade: false });
  };

  getDashOffSet = () => {
    const totalNoOfDaysLeft = freePeriodNoOfDaysLeft(
      this.state.freePeriodEndDate
    );
    return 'calc(' + 7 * (63 - totalNoOfDaysLeft) + ')';
  };
  getMatAnalyticsMenu = () => {
    const { isMat, accessLevel } = this.state;
    const { isDisplayMatHeatMap } = this.state;
    const { navActiveMenu } = this.props;
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);

    if (
      (accessLevel === HeadTeacherAccess.Full &&
        isMat &&
        isDisplayMatHeatMap) ||
      (matGroupId !== null && isDisplayMatHeatMap)
    ) {
      return (
        <li>
          <a
            href
            onClick={() => {
              this.onClickHandler({
                name: MenuItems.MATAnalytics,
                showReportSubMenu: false,
              });
              let schoolToken = getQueryStringVal(
                QueryStringTokens.schoolToken
              );
              if (
                this.state.role === Roles.GroupDirector ||
                schoolToken !== null
              ) {
                this.redirectToRouteWithSchoolToken(
                  routesObj.MATAnalytics.path
                );
              } else {
                this.redirectToRoute(routesObj.MATAnalytics.path);
              }
            }}
            className={navActiveMenu === MenuItems.MATAnalytics ? 'active' : ''}
          >
            {this.state.MAT}
          </a>
        </li>
      );
    }
  };
  getMatMenu = () => {
    const { isMat, accessLevel } = this.state;
    const { isDisplayMatHeatMap } = this.state;
    const { navActiveMenu } = this.props;
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);

    if (
      (accessLevel === HeadTeacherAccess.Full &&
        isMat &&
        isDisplayMatHeatMap) ||
      (matGroupId !== null && isDisplayMatHeatMap)
    ) {
      return (
        <li>
          <a
            href
            onClick={() => {
              this.onClickHandler({
                name: MenuItems.MatHeatMap,
                showReportSubMenu: false,
              });
              let schoolToken = getQueryStringVal(
                QueryStringTokens.schoolToken
              );
              if (
                this.state.role === Roles.GroupDirector ||
                schoolToken !== null
              ) {
                this.redirectToRouteWithSchoolToken(routesObj.MatHeatMap.path);
              } else {
                this.redirectToRoute(routesObj.MatHeatMap.path);
              }
            }}
            className={navActiveMenu === MenuItems.MatHeatMap ? 'active' : ''}
          >
            <img src={iconHeatmap} alt='' /> {this.state.MAT}
          </a>
        </li>
      );
    }
  };

  render() {
    const contentHeader = (
      <div className='main-header-content'>
        <div>
          {!this.state.docked && (
            <a
              onClick={this.toggleOpen}
              href='#'
              style={styles.contentHeaderMenuLink}
            >
              <MenuIcon />
            </a>
          )}
        </div>

        <div className='mobile-logo'>
          <img
            src={
              this.state.schoolLogo !== null
                ? this.state.schoolLogo
                : welbeeDashboardLogo
            }
            alt=''
          />
        </div>
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              xl: 'none',
              lg: 'none',
            },
          }}
        >
          <div className='btn-request-assistance'>
            <RequestAssistanceButton />
          </div>
        </Box>
      </div>
    );

    const {
      activeItem: navActiveMenu,
      showReportSubMenu,
      newCommentCount,
      toggleButton,
      newConversationCount,
      logoURL,
    } = this.props;
    const {
      accessLevel,
      isUpgrade,
      welbeeType,
      isStaff,
      showAccount,
      freePeriodEndDate,
      IsUpgradBannderDisplayed,
      isMat,
      schoolSubscription,
      showReportMenu,
    } = this.state;
    const pathName = window.location.pathname;
    const eventName =
      pathName === '/survey-report'
        ? 'Report'
        : pathName === '/dashboard'
          ? 'Dashboard'
          : pathName === '/staff-presentation'
            ? 'Presentation'
            : pathName === '/survey-comments'
              ? 'Comments'
              : pathName === '/learn-more'
                ? 'LearnMore'
                : pathName === '/account'
                  ? 'account'
                  : pathName === '/staff-management'
                    ? 'StaffManagement'
                    : 'Dashboard';
    return (
      <div>
        <IdleTimerContainer />
        <Sidebar
          className='leftsidebar'
          sidebar={
            <Scrollbars
              className='scrollbar-view'
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className='leftsidemenu'>
                <ul data-id-ul='reportLeftMenus'>
                  <li align='center'>
                    <img
                      src={
                        this.state.schoolLogo !== null
                          ? this.state.schoolLogo
                          : welbeeDashboardLogo
                      }
                      alt=''
                      className='school-logo'
                    />
                  </li>
                  <li className='voice-sruvey-options menu-feature'>
                    {this.state.voiceMenu ? (
                      <span className='survey-disabled'>Survey</span>
                    ) : (
                      <span
                        onClick={() => {
                          this.ontoggleButtonSwitch(toggleOption.WelbeeSurvey);
                          let schoolToken = getQueryStringVal(
                            QueryStringTokens.schoolToken
                          );
                          if (
                            this.state.role === Roles.GroupDirector ||
                            (schoolToken && schoolToken !== null)
                          ) {
                            this.redirectToRoute(
                              routesObj.Dashboard.path +
                              '?schoolToken=' +
                              schoolToken
                            );
                          } else {
                            if (accessLevel === HeadTeacherAccess.Admin)
                              this.redirectToRoute(
                                routesObj.AdminDashboard.path
                              );
                            else this.redirectToRoute(routesObj.Dashboard.path);
                          }
                        }}
                        className={`${toggleButton === toggleOption.WelbeeSurvey
                          ? 'active-toggle'
                          : ''
                          }`}
                      >
                        Survey
                      </span>
                    )}
                    <span
                      onClick={() => {
                        this.ontoggleButtonSwitch(toggleOption.WelbeeVoice);
                        let schoolToken = getQueryStringVal(
                          QueryStringTokens.schoolToken
                        );
                        if (
                          this.state.role === Roles.GroupDirector ||
                          schoolToken !== null
                        ) {
                          this.redirectToRoute(
                            routesObj.WelbeeVoice.path +
                            '?schoolToken=' +
                            schoolToken
                          );
                        } else this.redirectToRoute(routesObj.WelbeeVoice.path);
                      }}
                      className={`${toggleButton === toggleOption.WelbeeVoice
                        ? 'active-toggle'
                        : ''
                        }`}
                    >
                      Voice
                    </span>
                  </li>
                  {toggleButton === toggleOption.WelbeeSurvey ? (
                    <>
                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View, AccessPermissions.Admin]} >
                        <li className='menu-dashboard'>
                          <a
                            href
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Dashboard,
                                showReportSubMenu: false,
                              });

                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken != null
                              ) {
                                this.redirectToRouteWithSchoolToken(
                                  routesObj.Dashboard.path
                                );
                              } else {
                                if (accessLevel === HeadTeacherAccess.Admin) {
                                  this.redirectToRoute(
                                    routesObj.AdminDashboard.path
                                  );
                                } else
                                  this.redirectToRoute(routesObj.Dashboard.path);
                              }
                              amplitudeLog(eventName, 'SideMenu' + eventName, '');
                            }}
                            className={
                              navActiveMenu === MenuItems.Dashboard
                                ? 'active'
                                : ''
                            }
                          >
                            <img src={iconDashboard} alt='' />{' '}
                            <span>Dashboard</span>
                          </a>
                        </li>
                      </AccessLevel>


                      {this.state.hasSummaryReport && (
                        <>
                          <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
                            <li className='menu-report'>
                              <a
                                href
                                onClick={() => {
                                  this.onClickHandlerReportMenu({
                                    showReportMenu: !showReportMenu,
                                  });
                                }}
                                className='menu-report'
                              >
                                <img src={iconReport} alt='' />{' '}
                                <span style={{ paddingTop: '3px' }}>
                                  Report
                                </span>
                                <span className='icon-expand'>
                                  <ExpandMoreIcon fontSize='small' />
                                </span>
                              </a>

                              {showReportMenu &&
                                this.state.hasSummaryReport && (
                                  <ul className='sub-list'>
                                    {this.state.hasSummaryReport && (
                                      <li>
                                        <a
                                          className={
                                            navActiveMenu ===
                                              MenuItems.SummaryReport ||
                                              eventName.toLowerCase() ===
                                              MenuItems.SummaryReport
                                              ? 'active'
                                              : ''
                                          }
                                          href
                                          onClick={() => {
                                            this.onClickHandler({
                                              name: MenuItems.SummaryReport,
                                              showReportSubMenu: false,
                                            });
                                            let schoolToken = getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            );
                                            if (
                                              this.state.role ===
                                              Roles.GroupDirector ||
                                              schoolToken !== null
                                            ) {
                                              this.redirectToRouteWithSurveyandSchoolToken(
                                                routesObj.SummaryReport.path,
                                                schoolToken
                                              );
                                            } else {
                                              this.redirectToRouteWithToken(
                                                routesObj.SummaryReport.path
                                              );
                                            }
                                            amplitudeLog(
                                              eventName,
                                              'SideMenu' + eventName,
                                              ''
                                            );
                                          }}
                                          style={{ borderLeft: '0 !important' }}
                                        >
                                          Summary
                                        </a>
                                      </li>
                                    )}

                                    <li>
                                      <a
                                        className={
                                          navActiveMenu === MenuItems.Report ||
                                            eventName.toLowerCase() ===
                                            MenuItems.Report
                                            ? 'active'
                                            : ''
                                        }
                                        href
                                        onClick={() => {
                                          this.onClickHandler({
                                            name: MenuItems.Report,
                                            showReportSubMenu:
                                              !showReportSubMenu,
                                          });
                                          let schoolToken = getQueryStringVal(
                                            QueryStringTokens.schoolToken
                                          );
                                          if (
                                            this.state.role ===
                                            Roles.GroupDirector ||
                                            schoolToken !== null
                                          ) {
                                            this.redirectToRouteWithSurveyandSchoolToken(
                                              routesObj.SurveyReport.path,
                                              schoolToken
                                            );
                                          } else {
                                            this.redirectToRouteWithToken(
                                              routesObj.SurveyReport.path
                                            );
                                          }
                                          amplitudeLog(
                                            eventName,
                                            'SideMenu' + eventName,
                                            ''
                                          );
                                        }}
                                        style={{ borderLeft: '0 !important' }}
                                      >
                                        Full Report
                                      </a>
                                    </li>
                                  </ul>
                                )}
                            </li>
                          </AccessLevel>
                        </>

                      )}

                      {/* dosra list item */}
                      {!this.state.hasSummaryReport && (
                        <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
                          <li>
                            <a
                              className={
                                navActiveMenu === MenuItems.Report ||
                                  eventName.toLowerCase() === MenuItems.Report
                                  ? 'active'
                                  : ''
                              }
                              href
                              onClick={() => {
                                this.onClickHandler({
                                  name: MenuItems.Report,
                                  showReportSubMenu: !showReportSubMenu,
                                });
                                let schoolToken = getQueryStringVal(
                                  QueryStringTokens.schoolToken
                                );
                                if (
                                  this.state.role === Roles.GroupDirector ||
                                  schoolToken !== null
                                ) {
                                  this.redirectToRouteWithSurveyandSchoolToken(
                                    routesObj.SurveyReport.path,
                                    schoolToken
                                  );
                                } else {
                                  this.redirectToRouteWithToken(
                                    routesObj.SurveyReport.path
                                  );
                                }

                                amplitudeLog(
                                  eventName,
                                  'SideMenu' + eventName,
                                  ''
                                );
                              }}
                              style={{ borderLeft: '0 !important' }}
                            >
                              <img src={iconReport} alt='' />{' '}
                              <span style={{ paddingTop: '3px' }}>Report</span>
                            </a>
                          </li>
                        </AccessLevel>
                      )}

                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
                        <li className='menu-heatmaps'>
                          <a
                            href
                            className={
                              navActiveMenu === MenuItems.Staff
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Staff,
                                showReportSubMenu: false,
                              });
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken !== null
                              ) {
                                this.redirectToRouteWithSurveyandSchoolToken(
                                  routesObj.StaffRoleHeatmap.path,
                                  schoolToken
                                );
                              } else {
                                this.redirectToRouteWithToken(
                                  routesObj.StaffRoleHeatmap.path
                                );
                              }
                            }}
                          >
                            <img src={iconHeatmap} alt='' />{' '}
                            <span>Heatmaps</span>
                          </a>
                        </li>
                        <li className='menu-comments'>
                          <a
                            href
                            className={
                              navActiveMenu === MenuItems.Comments
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Comments,
                                showReportSubMenu: false,
                              });
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken !== null
                              ) {
                                this.redirectToRouteWithSurveyandSchoolToken(
                                  routesObj.SurveyComments.path,
                                  schoolToken
                                );
                              } else {
                                this.redirectToRouteWithToken(
                                  routesObj.SurveyComments.path
                                );
                              }
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                            }}
                          >
                            <img src={iconComments} alt='' />{' '}
                            <span>Comments</span>
                          </a>
                          {newCommentCount > 0 ? (
                            <div className='comment-badge'>
                              {newCommentCount}
                            </div>
                          ) : (
                            <></>
                          )}
                        </li>
                      </AccessLevel>

                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
                        <li className='menu-analytics'>
                          <a
                            href
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Analytics,
                                showReportSubMenu: false,
                              });
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken != null
                              ) {
                                this.redirectToRouteWithSchoolToken(
                                  routesObj.SchoolAnalytics.path
                                );
                              } else {
                                if (accessLevel === HeadTeacherAccess.Admin) {
                                  this.redirectToRoute(
                                    routesObj.SchoolAnalytics.path
                                  );
                                } else
                                  this.redirectToRoute(
                                    routesObj.SchoolAnalytics.path
                                  );
                              }
                              amplitudeLog(eventName, 'SideMenu' + eventName, '');
                            }}
                            className={
                              navActiveMenu === MenuItems.Analytics
                                ? 'active'
                                : ''
                            }
                          >
                            <img src={iconAnalytics} alt='' />{' '}
                            <span>Analytics</span>
                          </a>
                        </li>
                      </AccessLevel>

                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
                        <li>
                          <a
                            href
                            className={
                              navActiveMenu === MenuItems.Presentation
                                ? 'active'
                                : 'menu-presentation'
                            }
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Presentation,
                                showReportSubMenu: false,
                              });
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken !== null
                              ) {
                                this.redirectToRouteWithSurveyandSchoolToken(
                                  routesObj.StaffPresentation.path,
                                  schoolToken
                                );
                              } else {
                                this.redirectToRouteWithToken(
                                  routesObj.StaffPresentation.path
                                );
                              }
                            }}
                          >
                            <img src={iconPresentation} alt='' />{' '}
                            <span>Presentation</span>
                          </a>
                        </li>
                      </AccessLevel>

                      {this.getMatMenu()}
                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >

                        <li className='menu-actionplan'>
                          <a
                            href
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Actionplan,
                                showReportSubMenu: false,
                              });
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken !== null
                              ) {
                                this.redirectToRouteWithSurveyandSchoolToken(
                                  routesObj.ActionPlan.path,
                                  schoolToken
                                );
                              } else {
                                this.redirectToRouteWithToken(
                                  routesObj.ActionPlan.path
                                );
                              }
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                            }}
                            className={
                              navActiveMenu === MenuItems.Actionplan
                                ? 'active'
                                : ''
                            }
                          >
                            <img src={iconActionPlan} alt='' />{' '}
                            <span>Action Plan</span>
                            <span className='tooltiptext'>
                              Use this to plan before and after running your
                              survey, to share actions with others directly to
                              their email address.
                            </span>
                          </a>
                        </li>
                      </AccessLevel>

                      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher,]} >
                        {this.state.hasQuestionBank && (
                          <li className='menu-question'>
                            <a
                              href
                              className={
                                navActiveMenu === MenuItems.QuestionBank
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => {
                                this.onClickHandler({
                                  name: MenuItems.QuestionBank,
                                  showReportSubMenu: false,
                                });

                                let schoolToken = getQueryStringVal(
                                  QueryStringTokens.schoolToken
                                );
                                if (schoolToken)
                                  this.redirectToRouteWithToken(
                                    routesObj.AdditionalQuestions.path
                                  );
                                else
                                  this.redirectToRouteWithSchoolId(
                                    routesObj.AdditionalQuestions.path
                                  );
                              }}
                            >
                              <img src={iconQuestionBank} alt='' />{' '}
                              <span>Question Bank</span>
                              <span className='tooltiptext'>
                                Add your own questions to the survey.
                              </span>
                            </a>
                          </li>
                        )}
                      </AccessLevel>

                      {this.getStaffManagementMenu(eventName, navActiveMenu)}

                      <li className='account-main menu-account'>
                        <a
                          href
                          onClick={() => {
                            this.onClickHandler({
                              name: MenuItems.Account,
                              showReportSubMenu: false,
                            });
                            amplitudeLog(eventName, 'SideMenu' + eventName, '');
                            let schoolToken = getQueryStringVal(
                              QueryStringTokens.schoolToken
                            );
                            if (
                              this.state.role === Roles.GroupDirector ||
                              schoolToken !== null
                            )
                              this.redirectToRouteWithSchoolToken(
                                routesObj.Account.path
                              );
                            else {
                              this.redirectToRoute(routesObj.Account.path);
                            }
                          }}
                          className={`${navActiveMenu === MenuItems.Account ? 'active' : ''
                            } ${this.state.role === Roles.GroupDirector
                              ? 'disable'
                              : ''
                            }`}
                        >
                          <img src={iconAccount} alt='' /> <span>Account</span>
                        </a>
                      </li>

                      <li className='menu-userguide'>
                        <a
                          href
                          className={
                            navActiveMenu === MenuItems.LearnMore
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.onClickHandler({
                              name: MenuItems.LearnMore,
                              showReportSubMenu: false,
                            });
                            var win = window.open(
                              UserGuideSurveyLink,
                              '_blank'
                            );
                            win.focus();
                            amplitudeLog(eventName, 'SideMenu' + eventName, '');
                          }}
                        >
                          <img src={iconUserGuide} alt='' />{' '}
                          <span>User Guide</span>
                        </a>
                      </li>
                      <div className='btn-bottom'>
                        {this.toggleTour()}
                        {this.getUpgradePlan()}
                        {this.getLogoutMenu()}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {toggleButton === toggleOption.WelbeeVoice ? (
                    <>
                      {accessLevel !== HeadTeacherAccess.Admin && (
                        <li className='voice-nav'>
                          <a
                            href
                            id='conversation-menu'
                            className={
                              navActiveMenu === MenuItems.Conversations
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Conversations,
                                showReportSubMenu: false,
                              });
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken !== null
                              )
                                this.redirectToRouteWithSchoolToken(
                                  routesObj.WelbeeVoice.path
                                );
                              else {
                                this.redirectToRoute(
                                  routesObj.WelbeeVoice.path
                                );
                              }
                            }}
                          >
                            <img src={iconConversation} alt='' />{' '}
                            <span>Conversations</span>
                          </a>
                          {newConversationCount > 0 ? (
                            <div className='comment-badge'>
                              {newConversationCount}
                            </div>
                          ) : (
                            <></>
                          )}
                        </li>
                      )}
                      <li className='voice-nav'>
                        <a
                          href
                          className={
                            navActiveMenu === MenuItems.VoiceUserGuide
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.onClickHandler({
                              name: MenuItems.VoiceUserGuide,
                              showReportSubMenu: false,
                            });
                            var win = window.open(UserGuideVoiceLink, '_blank');
                            win.focus();
                          }}
                        >
                          <img src={iconUserGuide} alt='' />{' '}
                          <span>User Guide</span>
                        </a>
                      </li>

                      {/* <li className='voice-nav'>
                            <a href='https://welbee.co.uk/' target='_blank'>
                              Visit Welbee
                            </a>
                          </li> */}
                      <li className='voice-nav'>
                        <a
                          href
                          onClick={(e) => {
                            e.preventDefault();
                            this.onClickHandler({
                              name: MenuItems.Account,
                              showReportSubMenu: false,
                            });
                            amplitudeLog(eventName, 'SideMenu' + eventName, '');
                            let schoolToken = getQueryStringVal(
                              QueryStringTokens.schoolToken
                            );
                            if (
                              this.state.role === Roles.GroupDirector ||
                              schoolToken !== null
                            )
                              return;
                            else {
                              this.redirectToRoute(routesObj.Account.path);
                            }
                          }}
                          className={`${navActiveMenu === MenuItems.Account ? 'active' : ''
                            } ${this.state.role === Roles.GroupDirector
                              ? 'disable'
                              : ''
                            }`}
                        >
                          <img src={iconAccount} alt='' />{' '}
                          <span>Your account</span>
                        </a>
                      </li>
                      {/* <li className='voice-nav welbee-url'>
                            <a
                              href='https://welbee.co.uk/'                       
                              className={
                                navActiveMenu === MenuItems.Account ? 'active' : ''
                              }
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              www.welbee.co.uk
                            </a>
                          </li> */}

                      {this.getStaffManagementMenu(eventName, navActiveMenu)}
                      <div className='btn-bottom'>
                        {this.getUpgradePlan()}
                        {this.getLogoutMenu()}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {isUpgradeVoideDisplay(freePeriodEndDate) &&
                    isUpgrade &&
                    welbeeType === WelbeeTypes.WelbeeVoiceFree &&
                    accessLevel === HeadTeacherAccess.Full && (
                      <div
                        className={`account-upgrade ${showAccount && !IsUpgradBannderDisplayed
                          ? 'show-account'
                          : ''
                          }`}
                      >
                        <span
                          className='close-box'
                          onClick={this.onUpgradeXClick}
                        >
                          &#215;
                        </span>
                        <div className='box'>
                          <div className='percent'>
                            <svg>
                              <circle cx='70' cy='70' r='70'></circle>
                              <circle
                                cx='70'
                                cy='70'
                                r='70'
                                style={{
                                  strokeDashoffset: this.getDashOffSet(),
                                  //strokeDashoffset: 'calc(0 + (44 * 5))', // 440 full blank
                                }}
                              ></circle>
                            </svg>
                            <div className='number'>
                              Only
                              <h2>
                                {freePeriodNoOfDaysLeft(freePeriodEndDate)}
                              </h2>
                              days left
                            </div>
                            <div className='premium-alert'>
                              Your premium features will end on{' '}
                              {DateFormatWithMonth(freePeriodEndDate)}To
                              continue using Voice Pro please take a look at our{' '}
                              {accessLevel == HeadTeacherAccess.Full && (
                                <a
                                  href
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (isStaff) {
                                      this.onLogoutClickHandler();
                                      return;
                                    }
                                    this.redirectToRoute(
                                      routesObj.VoiceUpgrade.path
                                    );
                                  }}
                                >
                                  upgrade options.
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </ul>
              </div>
            </Scrollbars>
          }
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
        >
          <div className='main-header'>{contentHeader}</div>
        </Sidebar>
        {this.state.openGuidedTour ? (
          this.state.schoolSubscription === 5 ? (
            <GuidedTourPremium
              closeGuidedTour={this.closeGuidedTour}
              showQuestionBank={this.state.hasQuestionBank}
            />
          ) : (
            <GuidedTourPro closeGuidedTour={this.closeGuidedTour} />
          )
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = ({
  header: {
    activeItem,
    showReportSubMenu,
    disableReportMenu,
    newCommentCount,
    toggleButton,
    newConversationCount,
    logoURL,
  },
  user: { freeTrailEndDate },
}) => ({
  activeItem,
  showReportSubMenu,
  disableReportMenu,
  newCommentCount,
  toggleButton,
  newConversationCount,
  logoURL,
  freeTrailEndDate,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeftMenu));
