import React, { Fragment, useState } from 'react';
import { AppButton, PageWrapper, LoginFormWrapper, CheckBox, ErrorWrapper, WelbeeTextField } from '../../resources/styling/appStyle';
import ImagePopup from '../welbeeVoice/ImagePopup';
import { colors } from '../../resources/theme/colors';
import { Row, Col } from 'styled-bootstrap-grid';
import { welbeeLoginLogo, removethin } from '../../resources/images/index';
import { LoginFormInner, InputWrapper, FieldWrapper, ExtendedMainWrapper } from '../../components/common/loginStyle';
import { schoolService, storageService } from '../../services';
import { WebStorageNames } from '../../utils';
import styled from 'styled-components';
import { getAdditionalSurveyPrice } from '../../utils/CommonFunctions';
import { Formik } from 'formik';
import { WrapperMain } from '../../resources/styling/formStyle';
import { AppInputField } from '../../components';
import * as Yup from 'yup';
const style = {
    optionalLable: {
        textAlign: 'left',
        display: 'block',
        marginTop: '4px',
        fontSize: '13px',
        color: '#53B5E0',
        paddingLeft: '0px',
    },
};

const { mandy, whiteColor, pictonBlue } = colors;

const ExtendedLoginFormInner = styled(LoginFormInner)`
  width: 95% !important;
  h1{
      font-size: 2rem;
    }
`;

const AdditionalSurveyConfirm = ({ onClose, SchoolName, NoOfPupil }) => {

    const [isInvoiceInfoChecked, setIsInvoiceInfoChecked] = useState(false);
    return (
        <ImagePopup>
            <button className='crossButton' onClick={() => onClose()} >
                <img src={removethin} alt='remove button' />
            </button>
            <div style={{ width: '80%', zIndex: 9999, height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                <ExtendedMainWrapper>
                    <PageWrapper>
                        <div className='mobile-warning-wrapper'>
                            <span>&#9888;</span>
                            Please use desktop to view Welbee
                        </div>
                        <Row className='login-main-warpper'>
                            <Col
                                col={6}
                                lg={6}
                                sm={12}
                                md={6}
                                mdx={12}
                                xs={12}
                                className='login-mobile-wrapper login-left-panel'
                            >   <img src={welbeeLoginLogo} alt='' />
                            </Col>
                            <Col
                                col={6}
                                lg={6}
                                sm={12}
                                xs={12}
                                md={6}
                                mdx={12}
                                className='welcome-right-panel registeration-right-panel'
                            >
                                <Formik
                                    initialValues={{
                                        PurchaseOrderNo: '',
                                        InvoiceAddressName: '',
                                        InvoiceAddressEmail: '',
                                        InvoiceSent: false,
                                        Discount: 0,
                                        SchoolId: storageService.getItem(WebStorageNames.SchoolId),
                                        IsUpgradeRequest: false,
                                        UpgradeFrom: 0,
                                        InvoiceType: 1
                                    }}
                                    onSubmit={(values, actions) => {

                                        setTimeout(() => {
                                            actions.setSubmitting(false);
                                            schoolService
                                                .updateSchoolInvoiceInfo(values)
                                                .then((response) => {
                                                    const { success } = response;
                                                    if (success) {
                                                        onClose();
                                                    }
                                                })
                                                .catch((error) => console.log(error));
                                        }, 1000);
                                    }}
                                    validateOnBlur={false}
                                    validateOnChange={true}
                                    validationSchema={
                                        isInvoiceInfoChecked
                                            ? Yup.object().shape({
                                                InvoiceAddressName: Yup.string().required(
                                                    'Full name field is required.'
                                                ),
                                                InvoiceAddressEmail: Yup.string()
                                                    .trim()
                                                    .max(60)
                                                    .email('Provide email address like example@g.com.')
                                                    .required('Email is required.'),
                                            })
                                            : Yup.object().shape({})
                                    }
                                    render={({
                                        values,
                                        handleChange,
                                        touched,
                                        errors,
                                        handleSubmit,
                                        onBack,
                                    }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) handleSubmit();
                                            }}
                                        >
                                            <LoginFormWrapper>
                                                <ExtendedLoginFormInner>
                                                    <h1>Price for your additional survey for {SchoolName}</h1>
                                                    <div className='fee-big-number'>
                                                        <span>
                                                            &#xa3;{getAdditionalSurveyPrice(NoOfPupil)} <i className='price-vat'>+VAT</i>
                                                        </span>
                                                    </div>
                                                    <p className='fee-detail'>
                                                        If you are ready to proceed then please click the button to continue, and, if you require a PO number (optional),
                                                        then enter that below. We will send an invoice to your email address or to the one below.
                                                    </p>
                                                    <p className='fee-detail'>
                                                        Thank you for allowing us to support you.
                                                    </p>
                                                    <InputWrapper>
                                                        <WrapperMain>
                                                            <WelbeeTextField
                                                                fullWidth
                                                                label='Purchase order no.'
                                                                autoComplete='new-purchase-order'
                                                                type='text'
                                                                name='PurchaseOrderNo'
                                                                value={values.PurchaseOrderNo}
                                                                onChange={handleChange}
                                                            />
                                                            <span style={style.optionalLable}>optional</span>
                                                        </WrapperMain>
                                                        <WrapperMain>
                                                            <CheckBox style={{ marginBottom: '0px' }}>
                                                                <input
                                                                    class='styled-checkbox'
                                                                    id='styled-checkbox-1'
                                                                    type='checkbox'
                                                                    value='value1'
                                                                    onChange={() =>
                                                                        setIsInvoiceInfoChecked(!isInvoiceInfoChecked)
                                                                    }
                                                                />
                                                                <label for='styled-checkbox-1'>
                                                                    <span className='checkbox-wrapper'>
                                                                        Please tick to use a different name and email for the
                                                                        invoice.
                                                                    </span>
                                                                </label>
                                                            </CheckBox>
                                                        </WrapperMain>
                                                        {isInvoiceInfoChecked ? (
                                                            <Fragment>
                                                                <WrapperMain className='admin-fields'>
                                                                    <WelbeeTextField
                                                                        fullWidth
                                                                        label='Full name'
                                                                        autoComplete='new-name'
                                                                        type='text'
                                                                        name='InvoiceAddressName'
                                                                        value={values.InvoiceAddressName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <ErrorWrapper>
                                                                        {touched.InvoiceAddressName &&
                                                                            errors.InvoiceAddressName &&
                                                                            errors.InvoiceAddressName}
                                                                    </ErrorWrapper>
                                                                </WrapperMain>
                                                                <WrapperMain>
                                                                    <WelbeeTextField
                                                                        fullWidth
                                                                        label='Email'
                                                                        autoComplete='new-email'
                                                                        type='text'
                                                                        name='InvoiceAddressEmail'
                                                                        value={values.InvoiceAddressEmail}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <ErrorWrapper>
                                                                        {touched.InvoiceAddressEmail &&
                                                                            errors.InvoiceAddressEmail &&
                                                                            errors.InvoiceAddressEmail}
                                                                    </ErrorWrapper>
                                                                </WrapperMain>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment />
                                                        )}

                                                        <FieldWrapper>
                                                            <Row>
                                                                <Col
                                                                    col={12}
                                                                    lg={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    md={12}
                                                                    mdx={12}
                                                                    className='agree-button-wrapper'
                                                                >
                                                                    <AppButton
                                                                        color={mandy}
                                                                        type='submit'
                                                                        onClick={handleSubmit}
                                                                    >
                                                                        Agree to be invoiced
                                                                    </AppButton>
                                                                </Col>
                                                            </Row>
                                                        </FieldWrapper>
                                                    </InputWrapper>
                                                </ExtendedLoginFormInner>
                                            </LoginFormWrapper>
                                        </form>
                                    )}
                                />
                            </Col>
                        </Row>
                    </PageWrapper>
                </ExtendedMainWrapper>

            </div>
        </ImagePopup>
    );
};
export default AdditionalSurveyConfirm;