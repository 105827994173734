import React from 'react';
import { colors } from '../../resources/theme';
import { AppButton } from '../../resources/styling';
import { createactionicon } from '../../resources/images/index';
import { HeadTeacherAccess } from '../../utils'
import { FooterButtonsWrapper, AddSchoolQuestionWrapper } from './MatAdditionalQuestionStyle';

const { mandy } = colors;

const AddMatQuestion = ({ isOpen, isArchived, accessLevel }) => {
  return (
    <AddSchoolQuestionWrapper>
      {!isArchived ? (
        <div className="create-action-container">
          <img src={createactionicon} alt="create action icon" />
          <h2>Start adding additional questions!</h2>
          <p>
            Click below to create your own survey additional questions or add from our existing question bank.
          </p>
          {accessLevel === HeadTeacherAccess.Full &&
          <FooterButtonsWrapper>
            <AppButton color={mandy} type="button" onClick={isOpen}>
              Create/Add Question
            </AppButton>
          </FooterButtonsWrapper>
          }
        </div>
      ) : (
        <span>No archived actions.</span>
      )}
    </AddSchoolQuestionWrapper>
  );
};

export default AddMatQuestion;
