import React, { useState, useEffect } from 'react';
import { StrongestTiles } from './index';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';

const ScoreTiles = (props) => {
  const [isloading, setIsLoading] = useState(true);
  const { loaderActions, navigationActions } = props;
  loaderActions.dataLoading(true);  
  return (
    <div className='tiles-wrapper scores-step'>
      <div className='inner-main'>
        <div className='strongest-tiles' />
        <div className='first-row tiles-main'>
          <span className='strongest-label'>strongest</span>
          <StrongestTiles
            score={props.strongestScore}
            IsHeatMap={props.IsHeatMap}
            IsDirector={props.IsDirector}
            isGroupDashboard={props.isGroupDashboard}
            isActionAllowed={props.isActionAllowed}
            isShowResults={props.isShowResults}
            hasAdditionalQuestions={props.hasAdditionalQuestions}
          />
        </div>

        <div className='tiles-main'>
          <div className='weakest-tiles' />
          <span className='weakest-label '>weakest</span>
          <StrongestTiles
            score={props.weakestScore}
            IsHeatMap={props.IsHeatMap}
            IsDirector={props.IsDirector}
            isGroupDashboard={props.isGroupDashboard}
            isActionAllowed={props.isActionAllowed}
            isQuestionBankAvailable={props.isQuestionBankAvailable}
            isShowResults={props.isShowResults}
            hasAdditionalQuestions={props.hasAdditionalQuestions}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ header: { activeItem, newCommentCount } }) => ({
  activeItem,
  newCommentCount,
});
const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScoreTiles));
