import { combineReducers } from 'redux';
import {
  userStore,
  loaderStore,
  headerStore,
  surveyStore,
  actionPlanStore,
  superAdminStore,
  commentsStore,
} from '../store';

export default combineReducers({
  user: userStore,
  loader: loaderStore,
  header: headerStore,
  survey: surveyStore,
  actionPlan: actionPlanStore,
  superAdmin: superAdminStore,
  surveyComments: commentsStore,
});
