import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ExtendedSubContainer } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  SurveyTypes,
  WebStorageNames,
  QueryStringTokens,
} from '../../utils/Constants';
import { limitAverageValue } from '../../utils/roundScore';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import {
  GroupCommentsMainWrapper,
  SuperExtendedSubContainer,
} from './DashboardStyle';
import { storageService } from '../../services';
import { HeatmapCell } from '../../components';
const { whiteColor, minttulip, yourpink, lightminttulip, lightyourpink } =
  colors;

const SortOrder = {
  Newest: 'Support staff',
  Oldest: 'Teaching staff',
};

const QuestionDirection = ({ question, text }) => {
  return (
    <div className='questionDetail'>
      <span>{question}</span>
      <span>{text}</span>
    </div>
  );
};

class GroupManagementStandardHeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingSelectedOption: {
        value: SortOrder.Newest,
        label: 'Filter by staff type',
      },
      selectedSchoolOption: { value: '', label: 'All schools' },
      schoolNameOptions: [{ value: SurveyTypes.any, label: 'All schools' }],
      errorMessage: null,
      collapseJobRole: false,
      collapseTimeAtSchool: false,
      collapseEmploymentType: false,
      collapseSchoolName: false,
      schoolName: 'All schools',
      isMat: false,
      isSchoolNameShow: true,
      loggedRole: '',
      collapseAdditionalFilters: [],
      additionalFilters: props.heatMapData.AdditionalFilters,
      region: storageService.getItem(WebStorageNames.UsRegion),
    };
  }

  componentDidMount() {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let userToken = getQueryStringVal(QueryStringTokens.token);
    const isMat =
      (storageService.getItem(WebStorageNames.IsMat) && schoolToken === null) ||
      userToken !== null;
    const loggedRole = storageService.getItem(WebStorageNames.Role);

    this.setState({ isMat: isMat, loggedRole: loggedRole });

    var filterCollapse = [];
    this.state.additionalFilters.map((item, index) => {
      filterCollapse[item.FilterName] = false;
    });

    this.setState({
      collapseAdditionalFilters: filterCollapse,
    });
  }

  boxColor(colorValue, boxValue) {
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return minttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return yourpink;
    }
    return whiteColor;
  }

  staffBoxColor(colorValue, boxValue) {
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return lightminttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return lightyourpink;
    }
    return whiteColor;
  }
  checkStaffValue(value) {
    if (value === -1) {
      return <span className='blank-entry'>-</span>;
    }
    return limitAverageValue(value);
  }

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  getSchoolsForDropDown = () => {
    const { allSchools } = this.state;
    const transformedSchoolList = [{ value: '', label: 'All schools' }];
    allSchools.map((item) => {
      transformedSchoolList.push({ value: item.Token, label: item.Name });
    });
    this.setState({ schoolNameOptions: transformedSchoolList });
  };

  handleFilterChange = (filterName, IsFilterBenchMarksAvailable) => {
    let collapseState = this.state.collapseAdditionalFilters;
    collapseState[filterName] =
      !collapseState[filterName] && IsFilterBenchMarksAvailable;
    this.setState({ collapseAdditionalFilters: collapseState });
  };

  render() {
    const { errorMessage } = this.state;
    let token = getQueryStringVal(QueryStringTokens.token);
    const { questions, heatMapData, groupName, categoryName } = this.props;
    return (
      <GroupCommentsMainWrapper>
        {errorMessage !== null ? (
          <SuperExtendedSubContainer>
            <div className='search-data-container'>
              <div
                className='staff-result-innercontainer'
                style={{ width: '100%' }}
              >
                <div className='search-inner-sub-container'>
                  <div className='staff-type-grid'>
                    <div className='heatmap-error-msg'>{errorMessage}</div>
                  </div>
                </div>
              </div>
            </div>
          </SuperExtendedSubContainer>
        ) : (
          heatMapData.AllStaff !== null && (
            <SuperExtendedSubContainer>
              <div className='search-data-container'>
                <div
                  className='staff-result-innercontainer'
                  style={{ width: '100%' }}
                >
                  <div className='search-inner-sub-container'>
                    <div className='staff-type-grid'>
                      <span className='heat-map-heading'>Filters</span>

                      <span className='heat-map-grid-heading'>
                        <ul className='upper-heading'>
                          <li>
                            Management Standards: {categoryName} Questions
                          </li>
                        </ul>
                        <ul className='questionNoWrapper'>
                          {questions.map((x) => {
                            return (
                              <li>
                                {`Q${x.QuestionId}`}
                                <QuestionDirection
                                  question={`Q${x.QuestionId}`}
                                  text={x.QuestionText}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </span>
                    </div>
                    <div className='staff-type-grid-data staff-type-grid'>
                      <span className='grid-role-heading'>
                        <ul>
                          <li>{groupName} score</li>
                        </ul>
                      </span>
                      <span className='grid-data grid-data-heading '>
                        {heatMapData.MatAverage && (
                          <ul>
                            {questions.map((x) => {
                              return (
                                <HeatmapCell
                                  Decile={
                                    heatMapData.MatAverage.QuestionsAvg[
                                      `Q${x.QuestionId}Color`
                                    ]
                                  }
                                  Value={
                                    heatMapData.MatAverage.QuestionsAvg[
                                      `Q${x.QuestionId}`
                                    ]
                                  }
                                  Allow={true}
                                  AllStaff={true}
                                  Category={categoryName}
                                />
                              );
                            })}
                          </ul>
                        )}
                      </span>
                      <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={400} style={{ width: '100%' }}>
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state.collapseSchoolName ? '' : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseSchoolName:
                                  !this.state.collapseSchoolName,
                              });
                            }}
                          >
                            School Overall Scores
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseSchoolName &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <div className='staff-type-grid-data staff-type-grid'>
                                  <span className='grid-role-heading'>
                                    <ul>
                                      <li>{item.Name}</li>
                                    </ul>
                                  </span>
                                  <span className='grid-data'>
                                    <ul>
                                      {questions.map((x) => {
                                        return (
                                          <HeatmapCell
                                            Decile={
                                              item.HeatMapSchoolAverage
                                                .QuestionsAvg[
                                                `Q${x.QuestionId}Color`
                                              ]
                                            }
                                            Value={
                                              item.HeatMapSchoolAverage
                                                .QuestionsAvg[
                                                `Q${x.QuestionId}`
                                              ]
                                            }
                                            Allow={true}
                                            AllStaff={false}
                                            Category={categoryName}
                                          />
                                        );
                                      })}
                                    </ul>
                                  </span>
                                </div>
                              );
                            }
                          )}
                        <span
                          className={`job-role-heading ${
                            !this.state.collapseJobRole &&
                            heatMapData.JobRole.IsFilterBenchMarksAvailable
                              ? ''
                              : 'greyStrep'
                          }`}
                          onClick={() => {
                            this.setState({
                              collapseJobRole:
                                !this.state.collapseJobRole &&
                                heatMapData.JobRole.IsFilterBenchMarksAvailable,
                            });
                          }}
                        >
                          Job Role Scores Across all Schools
                          {!heatMapData.JobRole.IsFilterBenchMarksAvailable ? (
                            <em className='nodata'>
                              No data is available to display
                            </em>
                          ) : (
                            <></>
                          )}
                        </span>
                        {this.state.collapseJobRole &&
                        heatMapData.JobRole.IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {heatMapData.JobRole.HeatMapModel.map(
                                  (item) => {
                                    return (
                                      <>
                                        {item.IsBenchMarkAvailable ? (
                                          <li>
                                            {item.StaffType}{' '}
                                            <span>{item.ClassRoom}</span>
                                          </li>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {heatMapData.JobRole.HeatMapModel.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        <span
                          className={`job-role-heading ${
                            !this.state.collapseTimeAtSchool &&
                            heatMapData.TimeAtSchool.IsFilterBenchMarksAvailable
                              ? ''
                              : 'greyStrep'
                          }`}
                          onClick={() => {
                            this.setState({
                              collapseTimeAtSchool:
                                !this.state.collapseTimeAtSchool &&
                                heatMapData.TimeAtSchool
                                  .IsFilterBenchMarksAvailable,
                            });
                          }}
                        >
                          Time at school Across all Schools
                          {!heatMapData.TimeAtSchool
                            .IsFilterBenchMarksAvailable ? (
                            <em className='nodata'>
                              No data is available to display
                            </em>
                          ) : (
                            <></>
                          )}
                        </span>
                        {this.state.collapseTimeAtSchool &&
                        heatMapData.TimeAtSchool.IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {heatMapData.TimeAtSchool.HeatMapModel.map(
                                  (item) => {
                                    return (
                                      <>
                                        {item.IsBenchMarkAvailable ? (
                                          <li>
                                            {item.StaffType}{' '}
                                            <span>{item.ClassRoom}</span>
                                          </li>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {heatMapData.TimeAtSchool.HeatMapModel.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}

                        <span
                          className={`job-role-heading ${
                            !this.state.collapseEmploymentType &&
                            heatMapData.EmploymentType
                              .IsFilterBenchMarksAvailable
                              ? ''
                              : 'greyStrep'
                          }`}
                          onClick={() => {
                            this.setState({
                              collapseEmploymentType:
                                !this.state.collapseEmploymentType &&
                                heatMapData.EmploymentType
                                  .IsFilterBenchMarksAvailable,
                            });
                          }}
                        >
                          Employment type Across all Schools
                          {!heatMapData.EmploymentType
                            .IsFilterBenchMarksAvailable ? (
                            <em className='nodata'>
                              No data is available to display
                            </em>
                          ) : (
                            <></>
                          )}
                        </span>
                        {this.state.collapseEmploymentType &&
                        heatMapData.EmploymentType
                          .IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {heatMapData.EmploymentType.HeatMapModel.map(
                                  (item) => {
                                    return (
                                      <>
                                        {item.IsBenchMarkAvailable ? (
                                          <li>
                                            {item.StaffType}{' '}
                                            <span>{item.ClassRoom}</span>
                                          </li>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {heatMapData.EmploymentType.HeatMapModel.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseSupportStaffClassroomBySchoolName &&
                              heatMapData.AllSchools
                                .IsSupportStaffClassRoomAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseSupportStaffClassroomBySchoolName:
                                  !this.state
                                    .collapseSupportStaffClassroomBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsSupportStaffClassRoomAvailable,
                              });
                            }}
                          >
                            Support Staff Classroom Scores by School
                            {!heatMapData.AllSchools
                              .IsSupportStaffClassRoomAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseSupportStaffClassroomBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 0 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseSupportStaffClassroomBySchoolName &&
                          heatMapData.AllSchools
                            .IsSupportStaffClassRoomAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapSupportStaffClasroom
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item
                                                    .HeatMapSupportStaffClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item
                                                    .HeatMapSupportStaffClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseSupportStaffNonClassroomBySchoolName &&
                              heatMapData.AllSchools
                                .IsSupportStaffNonClassRoomAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseSupportStaffNonClassroomBySchoolName:
                                  !this.state
                                    .collapseSupportStaffNonClassroomBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsSupportStaffNonClassRoomAvailable,
                              });
                            }}
                          >
                            Support Staff Non-Classroom Scores by School
                            {!heatMapData.AllSchools
                              .IsSupportStaffNonClassRoomAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseSupportStaffNonClassroomBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 1 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseSupportStaffNonClassroomBySchoolName &&
                          heatMapData.AllSchools
                            .IsSupportStaffNonClassRoomAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapSupportStaffNonClasroom
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item
                                                    .HeatMapSupportStaffNonClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item
                                                    .HeatMapSupportStaffNonClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseTeachingStaffClassroomBySchoolName &&
                              heatMapData.AllSchools
                                .IsTeachingStaffClassRoomAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseTeachingStaffClassroomBySchoolName:
                                  !this.state
                                    .collapseTeachingStaffClassroomBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsTeachingStaffClassRoomAvailable,
                              });
                            }}
                          >
                            Teaching Staff Classroom Scores by School
                            {!heatMapData.AllSchools
                              .IsTeachingStaffClassRoomAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseTeachingStaffClassroomBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 2 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseTeachingStaffClassroomBySchoolName &&
                          heatMapData.AllSchools
                            .IsTeachingStaffClassRoomAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapTeachingStaffClasroom
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item
                                                    .HeatMapTeachingStaffClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item
                                                    .HeatMapTeachingStaffClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseTeachingStaffNonClassroomBySchoolName &&
                              heatMapData.AllSchools
                                .IsTeachingStaffNonClassRoomAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseTeachingStaffNonClassroomBySchoolName:
                                  !this.state
                                    .collapseTeachingStaffNonClassroomBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsTeachingStaffNonClassRoomAvailable,
                              });
                            }}
                          >
                            Teaching Staff Non-Classroom Scores by School
                            {!heatMapData.AllSchools
                              .IsTeachingStaffNonClassRoomAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseTeachingStaffNonClassroomBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 3 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseTeachingStaffNonClassroomBySchoolName &&
                          heatMapData.AllSchools
                            .IsTeachingStaffNonClassRoomAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapTeachingStaffNonClasroom
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item
                                                    .HeatMapTeachingStaffNonClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item
                                                    .HeatMapTeachingStaffNonClasroom
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}

                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseMiddleLeaderScoreBySchoolName &&
                              heatMapData.AllSchools
                                .IsMiddleLeaderScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseMiddleLeaderScoreBySchoolName:
                                  !this.state
                                    .collapseMiddleLeaderScoreBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsMiddleLeaderScoreAvailable,
                              });
                            }}
                          >
                            Middle Leader Scores by School
                            {!heatMapData.AllSchools
                              .IsMiddleLeaderScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseMiddleLeaderScoreBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 4 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseMiddleLeaderScoreBySchoolName &&
                          heatMapData.AllSchools.IsMiddleLeaderScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapMiddleLeader
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapMiddleLeader
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapMiddleLeader
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseSeniorLeaderScoresBySchoolName &&
                              heatMapData.AllSchools
                                .IsSeniorLeaderScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseSeniorLeaderScoresBySchoolName:
                                  !this.state
                                    .collapseSeniorLeaderScoresBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsSeniorLeaderScoreAvailable,
                              });
                            }}
                          >
                            Senior Leader Scores by School
                            {!heatMapData.AllSchools
                              .IsSeniorLeaderScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseSeniorLeaderScoresBySchoolName &&
                          heatMapData.JobRole.HeatMapModel.map(
                            (item, index) => {
                              if (index === 5 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseSeniorLeaderScoresBySchoolName &&
                          heatMapData.AllSchools.IsSeniorLeaderScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapSeniorLeader
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapSeniorLeader
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapSeniorLeader
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseLessThan1YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWithLessThan1YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseLessThan1YearServiceBySchoolName:
                                  !this.state
                                    .collapseLessThan1YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWithLessThan1YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH Less Than 1 YR SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWithLessThan1YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseLessThan1YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 0 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseLessThan1YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWithLessThan1YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapLessThan1Year
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapLessThan1Year
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapLessThan1Year
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseBetween1to3YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWith1To3YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseBetween1to3YearServiceBySchoolName:
                                  !this.state
                                    .collapseBetween1to3YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWith1To3YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH 1-3 YRS SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWith1To3YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween1to3YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 1 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween1to3YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWith1To3YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapWithin1to3Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapWithin1to3Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapWithin1to3Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseBetween4to5YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWith4To5YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseBetween4to5YearServiceBySchoolName:
                                  !this.state
                                    .collapseBetween4to5YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWith4To5YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH 4-5 YRS SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWith4To5YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween4to5YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 2 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween4to5YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWith4To5YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapWithin4to5Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapWithin4to5Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapWithin4to5Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseBetween6to10YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWith6To10YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseBetween6to10YearServiceBySchoolName:
                                  !this.state
                                    .collapseBetween6to10YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWith6To10YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH 6-10 YRS SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWith6To10YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween6to10YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 3 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween6to10YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWith6To10YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapWithin6to10Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapWithin6to10Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapWithin6to10Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseBetween11to15YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWith11To15YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseBetween11to15YearServiceBySchoolName:
                                  !this.state
                                    .collapseBetween11to15YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWith11To15YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH 11-15 YRS SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWith11To15YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween11to15YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 4 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween11to15YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWith11To15YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapWithin11to15Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapWithin11to15Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapWithin11to15Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseBetween16to20YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWith16To20YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseBetween16to20YearServiceBySchoolName:
                                  !this.state
                                    .collapseBetween16to20YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWith16To20YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH 16-20 YRS SERVICE SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWith11To15YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween16to20YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 5 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseBetween16to20YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWith16To20YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapWithin16to20Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapWithin16to20Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapWithin16to20Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseGretterThan20YearServiceBySchoolName &&
                              heatMapData.AllSchools
                                .IsStaffWithMoreThan20YearServiceScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseGretterThan20YearServiceBySchoolName:
                                  !this.state
                                    .collapseGretterThan20YearServiceBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsStaffWithMoreThan20YearServiceScoreAvailable,
                              });
                            }}
                          >
                            STAFF WITH Greater Than 20 YRS SERVICE SCORES BY
                            SCHOOL
                            {!heatMapData.AllSchools
                              .IsStaffWithMoreThan20YearServiceScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseGretterThan20YearServiceBySchoolName &&
                          heatMapData.TimeAtSchool.HeatMapModel.map(
                            (item, index) => {
                              if (index === 6 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state
                            .collapseGretterThan20YearServiceBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWithMoreThan20YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapGreaterThan20Years
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapGreaterThan20Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapGreaterThan20Years
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapseFullTimeStaffScoresBySchoolName &&
                              heatMapData.AllSchools
                                .IsFullTimeStaffScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapseFullTimeStaffScoresBySchoolName:
                                  !this.state
                                    .collapseFullTimeStaffScoresBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsFullTimeStaffScoreAvailable,
                              });
                            }}
                          >
                            FULL TIME STAFF SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsFullTimeStaffScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseFullTimeStaffScoresBySchoolName &&
                          heatMapData.EmploymentType.HeatMapModel.map(
                            (item, index) => {
                              if (index === 0 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapseFullTimeStaffScoresBySchoolName &&
                          heatMapData.AllSchools
                            .IsStaffWithMoreThan20YearServiceScoreAvailable &&
                          heatMapData.AllSchools.SchoolQuestionHeatMaps.map(
                            (item) => {
                              return (
                                <>
                                  {item.HeatMapFullTimeStaff
                                    .IsBenchMarkAvailable ? (
                                    <div className='staff-type-grid-data staff-type-grid'>
                                      <span className='grid-role-heading'>
                                        <ul>
                                          <li>{item.Name}</li>
                                        </ul>
                                      </span>
                                      <span className='grid-data'>
                                        <ul>
                                          {questions.map((x) => {
                                            return (
                                              <HeatmapCell
                                                Decile={
                                                  item.HeatMapFullTimeStaff
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}Color`
                                                  ]
                                                }
                                                Value={
                                                  item.HeatMapFullTimeStaff
                                                    .QuestionsAvg[
                                                    `Q${x.QuestionId}`
                                                  ]
                                                }
                                                Allow={true}
                                                AllStaff={false}
                                                Category={categoryName}
                                              />
                                            );
                                          })}
                                        </ul>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        {this.state.isSchoolNameShow && (
                          <span
                            className={`job-role-heading ${
                              !this.state
                                .collapsePartlTimeStaffScoresBySchoolName &&
                              heatMapData.AllSchools
                                .IsPartTimeStaffScoreAvailable
                                ? ''
                                : 'greyStrep'
                            }`}
                            onClick={() => {
                              this.setState({
                                collapsePartlTimeStaffScoresBySchoolName:
                                  !this.state
                                    .collapsePartlTimeStaffScoresBySchoolName &&
                                  heatMapData.AllSchools
                                    .IsPartTimeStaffScoreAvailable,
                              });
                            }}
                          >
                            PART TIME STAFF SCORES BY SCHOOL
                            {!heatMapData.AllSchools
                              .IsPartTimeStaffScoreAvailable ? (
                              <em className='nodata'>
                                No data is available to display
                              </em>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                        {this.state.isSchoolNameShow &&
                          this.state.collapsePartlTimeStaffScoresBySchoolName &&
                          heatMapData.EmploymentType.HeatMapModel.map(
                            (item, index) => {
                              if (index === 1 && item.IsBenchMarkAvailable)
                                return (
                                  <div className='staff-type-grid-data staff-type-grid'>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        <li>All Schools</li>
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                            <HeatmapCell
                                              Decile={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}Color`
                                                ]
                                              }
                                              Value={
                                                item.QuestionsAvg[
                                                  `Q${x.QuestionId}`
                                                ]
                                              }
                                              Allow={true}
                                              AllStaff={false}
                                              Category={categoryName}
                                            />
                                          );
                                        })}
                                      </ul>
                                    </span>
                                  </div>
                                );
                            }
                          )}

                        <span>
                          {heatMapData.AdditionalFilters.map((items, index) => {
                            return (
                              <span>
                                {items.IsFilterBenchMarksAvailable ? (
                                  <>
                                    <span
                                      className={`job-role-heading ${
                                        !this.state.collapseAdditionalFilters[
                                          items.FilterName
                                        ] && items.IsFilterBenchMarksAvailable
                                          ? ''
                                          : 'greyStrep'
                                      }`}
                                      onClick={this.handleFilterChange.bind(
                                        this,
                                        items.FilterName,
                                        items.IsFilterBenchMarksAvailable
                                      )}
                                    >
                                      {this.state.region === 'us'
                                        ? items.FilterName.toLowerCase() ===
                                          'phase or year'
                                          ? 'Grade'
                                          : items.FilterName
                                        : items.FilterName.toLowerCase() ===
                                          'phase or year'
                                        ? 'Year or Key Stage'
                                        : items.FilterName}{' '}
                                      {!items.IsFilterBenchMarksAvailable ? (
                                        <em className='nodata'>
                                          No data is available to display
                                        </em>
                                      ) : (
                                        <></>
                                      )}
                                    </span>

                                    {this.state.isSchoolNameShow &&
                                      this.state.collapseAdditionalFilters[
                                        items.FilterName
                                      ] &&
                                      items.HeatMapModel.map((item, index) => {
                                        if (
                                          index === 1 &&
                                          item.IsBenchMarkAvailable
                                        )
                                          return (
                                            <div className='staff-type-grid-data staff-type-grid'>
                                              <span className='grid-role-heading'>
                                                <ul>
                                                  <li>All Schools</li>
                                                </ul>
                                              </span>
                                              <span className='grid-data'>
                                                <ul>
                                                  {questions.map((x) => {
                                                    return (
                                                      <HeatmapCell
                                                        Decile={
                                                          item.QuestionsAvg[
                                                            `Q${x.QuestionId}Color`
                                                          ]
                                                        }
                                                        Value={
                                                          item.QuestionsAvg[
                                                            `Q${x.QuestionId}`
                                                          ]
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        Category={categoryName}
                                                      />
                                                    );
                                                  })}
                                                </ul>
                                              </span>
                                            </div>
                                          );
                                      })}

                                    {this.state.isSchoolNameShow &&
                                    this.state.collapseAdditionalFilters[
                                      items.FilterName
                                    ] &&
                                    items.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {items.HeatMapModel.map(
                                              (filter, index) => {
                                                return (
                                                  <>
                                                    {filter.IsBenchMarkAvailable ? (
                                                      <li>
                                                        {filter.StaffType}
                                                      </li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {items.HeatMapModel.map(
                                            (item, index) => {
                                              return (
                                                <>
                                                  {item.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      {questions.map((x) => {
                                                        return (
                                                          <HeatmapCell
                                                            Decile={
                                                              item.QuestionsAvg[
                                                                `Q${x.QuestionId}Color`
                                                              ]
                                                            }
                                                            Value={
                                                              item.QuestionsAvg[
                                                                `Q${x.QuestionId}`
                                                              ]
                                                            }
                                                            Allow={true}
                                                            Category={
                                                              categoryName
                                                            }
                                                          />
                                                        );
                                                      })}
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </span>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </SuperExtendedSubContainer>
          )
        )}
      </GroupCommentsMainWrapper>
    );
  }
}

export default connect(null, null)(withRouter(GroupManagementStandardHeatMap));
