import React from 'react';
import { HeadTeacherAccess } from '../../utils';
import { Tooltip, Switch } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessLevel from '../../components/common/AccessLevel';
import {
  AccessPermissions,
  Roles
} from '../../utils/Constants';
const AdditionalQuestionDropDownOptionsWithButton = ({
  options,
  handleAddRemoveQuestion,
  accessLevel,
  region,
}) => {
  return (
    <div className='options action-plan-options'>
      {options.map((category) => {
        return (
          <div className='suggested-action-list-main'>
            <Accordion className='accordion'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='accordion-header'
              >
                <div className='action-category-heading'>
                  {region === 'us' &&
                    category.SchoolQuestionsCategory === 'Faith'
                    ? 'Equity, Diversity, and Inclusion (Belonging)'
                    : category.SchoolQuestionsCategory}
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordion-detail'>
                {category.SchoolQuestions.map((option) => {
                  return (
                    option.value !== -1 && (
                      <div className='question-list'>
                        <div style={{ width: '93%', float: 'left' }}>
                          <span
                            key={option.value}
                            className='action-plan-detail'
                            style={{
                              cursor:
                                accessLevel === HeadTeacherAccess.Full
                                  ? 'cursor'
                                  : 'normal',
                            }}
                          >
                            {option.QuestionText}
                            {accessLevel === HeadTeacherAccess.Full && (
                              <i className='add-action-plan'>
                                {option.TypeDescription}
                              </i>
                            )}
                          </span>
                        </div>
                        <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher]} >
                          <div
                            style={{ width: '7%', float: 'left' }}
                            className='material-ui-switch'
                          >
                            <Tooltip
                              title='Slide to add / remove the question'
                              aria-label='add'
                            >
                              <Switch
                                checked={option.IsIncluded}
                                onChange={(e) => {
                                  option.IsIncluded = e.target.checked;
                                  handleAddRemoveQuestion(option, options);
                                }}
                                color='primary'
                                name={option.QuestionNumber}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </Tooltip>
                          </div>
                        </AccessLevel>

                      </div>
                    )
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalQuestionDropDownOptionsWithButton;
