import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  adaptV4Theme, createTheme, StyledEngineProvider, ThemeProvider
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../resources/theme/colors';

const { whiteColor, pictonBlue, blackColor, redColor, purple } = colors;

const DatePickerWrapper = styled.div`  
  .MuiButton-textPrimary {
    color: ${purple} !important;
  }
  div {    
    label {
      :focus {
        color: ${purple} !important;
      }
    }
    div {
      :after {
        border-bottom: 1px solid transparent !important;
      }
      :before {
        border-bottom: transparent !important;
      }
      :hover {
        :before {
          border-bottom: transparent !important;
        }
      }
    }
  }
`;
const styles = {
  floatingLabelFocusStyle: {
    color: blackColor,
  },
  inputLabel: {
    '&:hover': {
      color: pictonBlue,
    },
    '&:error': {
      color: pictonBlue,
    },
  },
  input: {
    width: '100%',
    fontWeight: '500',
    fontSize: '15px',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'transparent',
    },
  },
};

const materialTheme = createTheme(
  adaptV4Theme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      colorPrimary: {
        color: pictonBlue,
      },
      MuiTypography: {
        colorPrimary: {
          color: pictonBlue,
        },
        title: {
          color: whiteColor,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: `${pictonBlue}`,
          color: redColor,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {},
      },
      MuiPickersDay: {
        day: {
          '&$selected': {
            backgroundColor: pictonBlue,
            '&:hover': {
              backgroundColor: pictonBlue,
            },
          },
        },
        current: {
          color: `${pictonBlue}`,
        },
        isSelected: {
          backgroundColor: `${pictonBlue} !important`,
        },
      },
      MuiButton: {
        textPrimary: {
          color: `${pictonBlue} !important`,
        },
      },

      MuiInput: {
        input: {
          // color: `${pictonBlue} !important`
        },
      },
      MuiInputBase: {
        input: {
          height: 'inherit !important',
          color: `${purple}`,
          border: `transparent !important`,
          '&::placeholder': {
            color: `${purple} !important`,
          },
          '&:focus': {
            color: `${purple} !important`,
            border: `transparent !important`,
            background: `transparent`,
          },
        },
        root: {
          fontFamily: 'Avenir',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: pictonBlue,
          '&:hover': {
            backgroundColor: pictonBlue,
          },
        },
      },
    },
  })
);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerCustom = ({ label, classes, onChange, value, ...rest }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={materialTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerWrapper>
          <DatePicker            
            label={label}
            value={value ? moment(value) : null}
            onChange={onChange}
            {...rest}
            labelFunc={
              value ? (date) => moment(date).format('DD/MM/YYYY') : null
            }
            InputLabelProps={{ className: classes.inputLabel }}
            InputProps={{
              className: classes.input,
              root: { error: { color: blackColor } },
            }}
            disableFuture={false}
            error={false}
          />
        </DatePickerWrapper>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default withStyles(styles)(DatePickerCustom);
