import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  StaffWrapper,
  customStyles,
  ErrorWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { schoolStaffService, storageService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import {
  StaffTypes,
  DateFormats,
  StaffJobRoles,
  WebStorageNames,
} from '../../utils/Constants';

const { purple } = colors;

const EditStaffMember = (props) => {
  const [matSchool, setMatSchool] = useState('');
  const [type, setType] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [reinitialize, setReinitialize] = useState(true);

  const [staffFilters, setStaffFilters] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [schoolFilters, setSchoolFilters] = useState([]);
  const [reminingSchoolFilters, setReminingSchoolFilters] = useState([]);
  const { isMat } = props.isMat;
  const { schoolId } = props.schoolId;
  const { loaderActions } = props;
  const [staffData, setStaffData] = useState(null);
  const [filterLibrary, setFilterLibrary] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [phaseYears, setPhaseYears] = useState([]);
  const [jobTitleInputValue, setJobTitleInputValue] = useState('');
  const [phaseYearInputValue, setPhaseYearInputValue] = useState('');
  const [queryfilters, setQueryFilter] = useState([]);
  const [filterDropDownMaxSize] = useState(500);
  const [submitedAddFilters, setSubmitedAddFilters] = useState([]);

  useEffect(() => {
    const id = props.staffId;
    getFilterLibrary();
    initialValues(id);
  }, []);

  useEffect(() => {
    let filters =
      [...filterLibrary] !== null
        ? [...filterLibrary]
          .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
          .filter((x) =>
            x.KeyWord.toLowerCase().includes(jobTitleInputValue.toLowerCase())
          )
        : null;
    setQueryFilter([...filters].slice(0, filterDropDownMaxSize));
  }, [jobTitleInputValue]);

  useEffect(() => {
    let filters =
      [...filterLibrary] !== null
        ? [...filterLibrary]
          .filter((x) => x.PhaseYear !== null && x.PhaseYear !== '')
          .filter((x) =>
            x.PhaseYear.toLowerCase().includes(
              phaseYearInputValue.toLowerCase()
            )
          )
        : null;
    setQueryFilter([...filters].slice(0, filterDropDownMaxSize));
  }, [phaseYearInputValue]);

  const initialValues = (id) => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getById(id)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setId(data.Id);
          setFirstName(data.FirstName);
          setLastName(data.LastName);
          setEmail(data.Email);
          setStartDate(data.StartDate);
          setType(data.EmploymentType);
          setMatSchool(data.SchoolId);
          setStaffFilters(data.StaffFilters);
          getSchoolFilters(data.SchoolId, data.StaffFilters);
          getFilterValues(data.SchoolId);
          setStaffData(data);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const getFilterValues = (schoolId) => {
    schoolStaffService
      .getFilterValuesBySchoolId(schoolId)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setFilterValues(data);
        }
      })
      .catch((error) => { });
  };

  const getFilterLibrary = () => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFilterLibrary()
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setFilterLibrary(data);
          setQueryFilter([...data].slice(0, filterDropDownMaxSize));
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const filterValueSuggestions =
    filterValues !== null
      ? filterValues
        .sort((a, b) => (a.Value > b.Value ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.Value,
            key: item.FilterId,
          };
        })
      : null;

  const jobTitleSuggestions =
    [...queryfilters] !== null
      ? [...queryfilters]
        .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
        .sort((a, b) => (a.KeyWord > b.KeyWord ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.KeyWord,
            key: i,
          };
        })
      : null;

  const jobRoleSuggestions =
    StaffJobRoles !== null
      ? StaffJobRoles.filter((x) => x.title !== null && x.title !== '')
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.title === null ? 'None' : item.title,
            key: item.id,
          };
        })
      : null;

  const departmentSuggestions =
    [...queryfilters] !== null
      ? [...queryfilters]
        .filter((x) => x.Department !== null && x.Department !== '')
        .sort((a, b) => (a.Department > b.Department ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.Department,
            key: i,
          };
        })
      : null;

  const phaseyearSuggestions =
    [...queryfilters] !== null
      ? [...queryfilters]
        .filter((x) => x.PhaseYear !== null && x.PhaseYear !== '')
        .sort((a, b) => (a.PhaseYear > b.PhaseYear ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.PhaseYear,
            key: i,
          };
        })
      : null;

  const getSchoolFilters = (schoolId, staffFilters) => {
    let names =
      staffFilters !== null
        ? staffFilters.map((item) => {
          return item.FilterValue.Filter.Attribute;
        })
        : null;

    schoolStaffService
      .getFiltersBySchoolId(schoolId)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setSchoolFilters(data);
          let filters = data.filter((x) => !names.includes(x.Attribute));
          setReminingSchoolFilters([...filters]);
        }
      })
      .catch((error) => { });
  };

  const getJobTitleDefault = () => {
    if (
      staffData === null &&
      staffData.CoreFilters === null &&
      staffData.CoreFilters.length < 1
    ) {
      return undefined;
    }

    let array = [];
    [staffData.CoreFilters].flat().map((opt) => {
      if (opt.JobTitle !== null) array.push(opt.JobTitle);
    });
    return array;
  };

  const getJobRoleDefault = () => {
    if (
      staffData === null &&
      (staffData.CoreFilters === null) !== null &&
      staffData.CoreFilters.length < 1
    ) {
      return undefined;
    }

    return [staffData.CoreFilters].flat().map((opt) => {
      switch (opt.JobRole) {
        case StaffTypes.TeacherWorkingInside:
          return 'Teaching Staff (Classroom)';
        case StaffTypes.TeacherWorkingOutside:
          return 'Teaching Staff (Non-Classroom)';
        case StaffTypes.SupportStaffWorkingInside:
          return 'Support staff (Classroom)';
        case StaffTypes.SupportStaffWorkingOutside:
          return 'Support staff (Non-Classroom)';
        case StaffTypes.MiddleLeader:
          return 'Middle Leader';
        case StaffTypes.SeniorLeader:
          return 'Senior Leader';
        default:
          return 'None';
      }
    });
  };

  const getDepartmentDefault = () => {
    if (
      staffData === null &&
      (staffData.CoreFilters === null) !== null &&
      staffData.CoreFilters.length < 1
    ) {
      return undefined;
    }
    let array = [];
    [staffData.CoreFilters].flat().map((opt) => {
      if (opt.Department !== null) array.push(opt.Department);
    });
    return array;
  };

  const getPhaseYearDefault = () => {
    if (
      staffData === null &&
      (staffData.CoreFilters === null) !== null &&
      staffData.CoreFilters.length < 1
    ) {
      return undefined;
    }
    let array = [];
    [staffData.CoreFilters].flat().map((opt) => {
      if (opt.PhaseYear !== null) array.push(opt.PhaseYear);
    });
    return array;
  };

  const reInitializeForm = (setFieldValue) => {
    setReinitialize(false);
    setFieldValue('firstName', firstName);
    setFieldValue('lastName', lastName);
    setFieldValue('email', email);
    setFieldValue('startDate', startDate);
    setFieldValue('type', type);
    //Core Filters
    setFieldValue('jobTitles', jobTitles);
    setFieldValue('phaseYears', phaseYears);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleJobTitleChange = (event, values) => {
    setJobTitles(values);
  };

  const handleJobRoleChange = (event, values) => {
    setJobRoles(values);
  };

  const handleDepartmentChange = (event, values) => {
    setDepartments(values);
  };

  const handlePhaseYearChange = (event, values) => {
    setPhaseYears(values);
  };

  function getCoreFiltersOnSubmit(values) {
    let totalLength = [];
    let coreFilters = [];
    let titles = values.jobTitles;
    let roles = values.jobRoles;
    let departments = values.departments;
    let phaseyears = values.phaseYears;

    totalLength.push({ y: titles?.length });
    totalLength.push({ y: roles?.length });
    totalLength.push({ y: departments?.length });
    totalLength.push({ y: phaseyears?.length });
    let maxLength = Math.max(...totalLength.map((o) => o.y));

    if (titles.length > 0) {
      for (let i = 0; i < maxLength; i++) {
        if (titles[i] === null || titles[i] === '' || titles[i] === undefined)
          continue;
        let coreFilter = {
          JobTitle: titles[i],
          JobRole: getRole(roles[i]),
          Department: departments[i],
          PhaseYear: phaseyears[i],
        };
        coreFilters.push(coreFilter);
      }
      return coreFilters;
    }
  }

  function getRole(jobRole) {
    switch (jobRole) {
      case 'Teaching Staff (Classroom)':
        return StaffTypes.TeacherWorkingInside;

      case 'Teaching Staff (Non-Classroom)':
        return StaffTypes.TeacherWorkingOutside;

      case 'Support staff (Classroom)':
        return StaffTypes.SupportStaffWorkingInside;

      case 'Support staff (Non-Classroom)':
        return StaffTypes.SupportStaffWorkingOutside;

      case 'Middle Leader':
        return StaffTypes.MiddleLeader;

      case 'Senior Leader':
        return StaffTypes.SeniorLeader;

      default:
        return 0;
    }
  }

  function getFiltersOnSubmit(values) {
    let filterValues = [];
    schoolFilters.map((item) => {
      let opt = item.Attribute;
      let filterValue = values[opt];
      if (
        filterValue !== undefined &&
        (filterValue !== null || filterValue !== '')
      ) {
        let filterValueModel = {
          FilterId: filterValue.key,
          Value: filterValue.value,
          SchoolId: values.matSchool,
        };
        filterValues.push(filterValueModel);
      }
    });
    setSubmitedAddFilters(filterValues);
    return filterValues;
  }

  const schoolItems =
    props.matSchools !== null
      ? props.matSchools.map((item) => (
        <MenuItem key={item.Id} value={item.Id}>
          {item.Name}
        </MenuItem>
      ))
      : null;

  return (
    <Formik
      enableReinitialize={reinitialize}
      initialValues={{
        staffId: id,
        firstName: firstName !== null ? firstName : '',
        lastName: lastName !== null ? lastName : '',
        email: email !== null ? email : '',
        matSchool: matSchool !== null ? matSchool : '',
        type: type !== null ? type : '',
        startDate: startDate !== null ? startDate : new Date(),
        jobTitles: jobTitles !== null ? jobTitles : [],
        jobRoles: jobRoles !== null ? jobRoles : [],
        departments: departments !== null ? departments : [],
        phaseYears: phaseYears !== null ? phaseYears : [],
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().trim().required('first name is required.'),
        lastName: Yup.string().trim().required('last name is required.'),
        email: Yup.string()
          .trim()
          .email('Provide email address like username@gmail.com.')
          .required('Email is required.'),
        matSchool: Yup.string().trim().required('school is required.'),
      })}
      onSubmit={(values, formProps) => {
        //  Additional Filter
        let filterValues = getFiltersOnSubmit(values);

        //Core Filter
        let coreFilters = getCoreFiltersOnSubmit(values);

        let model = {
          Id: values.staffId,
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          SchoolId: isMat ? schoolId : values.matSchool,
          StartDate: values.startDate,
          EmploymentType: values.type,
          CoreFilters: coreFilters,
          FilterValues: filterValues?.length > 0 ? filterValues : submitedAddFilters,
        };

        schoolStaffService
          .saveSchoolStaff(model)
          .then((response) => {
            // loaderActions.dataLoading(false);
            if (response.success) {
              toast.success('Record added successfully.');
              if (!props.isMat) props.getStaff();
              else props.getMatStaff();
              props.handleClose();
            } else if (
              response.message === 'email already exist' ||
              response.message === 'email should be example@domain.com'
            ) {
              formProps.setErrors({ email: response.message });
            } else {
              toast.error('Record not updated. Please try again later.');
            }
          })
          .catch((error) => {
            // loaderActions.dataLoading(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Grid item xs={12}>
            <AppModel
              onClose={props.handleClose}
              styles={customStyles}
              open={true}
              titleText={'Edit Staff Member'}
              bodyText={
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '25px' }}>
                    Update staff member.
                  </p>

                  <Box
                    component='form'
                    sx={{
                      '& .MuiTextField-root': { width: '100%' },
                    }}
                    autoComplete='off'
                  >
                    {props.isMat ? (
                      <FormControl fullWidth>
                        <InputLabel id='mat-schools'>School</InputLabel>
                        <Select
                          name='matSchool'
                          labelId='mat-schools'
                          id='schools'
                          value={matSchool}
                          label='School'
                          disabled
                          onChange={(e) => {
                            setFieldValue('matSchool', e.target.value);
                          }}
                        >
                          {schoolItems}
                        </Select>
                        <div style={{ marginLeft: 8, marginTop: 5 }}>
                          <ErrorWrapper>
                            {touched.matSchool && errors.matSchool}
                          </ErrorWrapper>
                        </div>
                      </FormControl>
                    ) : null}

                    <StaffWrapper>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                          {true ? null : (
                            <TextField
                              fullWidth
                              value={id}
                              name='staffId'
                              id='staff-id'
                              label=''
                              hidden={true}
                            />
                          )}
                          <TextField
                            fullWidth
                            value={firstName}
                            name='firstName'
                            id='first-name'
                            label='First Name'
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setFieldValue('firstName', e.target.value);
                            }}
                          />
                          <div style={{ marginLeft: 8, marginTop: 5 }}>
                            <ErrorWrapper>
                              {touched.firstName && errors.firstName}
                            </ErrorWrapper>
                          </div>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            value={lastName}
                            name='lastName'
                            id='last-name'
                            label='Last Name'
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                              setLastName(e.target.value);
                            }}
                          />
                          <div style={{ marginLeft: 8, marginTop: 5 }}>
                            <ErrorWrapper>
                              {touched.lastName && errors.lastName}
                            </ErrorWrapper>
                          </div>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            value={email}
                            name='email'
                            id='staff-email'
                            label='Email'
                            type='email'
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setFieldValue('email', e.target.value);
                            }}
                          />
                          <div style={{ marginLeft: 8, marginTop: 5 }}>
                            <ErrorWrapper>
                              {touched.email && errors.email}
                            </ErrorWrapper>
                          </div>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat={DateFormats.format_31_12_2019}
                              label='Start Date'
                              name='startDate'
                              openTo='year'
                              value={startDate}
                              onChange={(date) => {
                                setFieldValue('startDate', date);
                                handleDateChange(date);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id='staff-type'>Type</InputLabel>
                            <Select
                              name='type'
                              id='type'
                              value={type}
                              label='Type'
                              onChange={(e) => {
                                setFieldValue('type', e.target.value);
                                handleTypeChange(e);
                              }}
                            >
                              <MenuItem value={0}>None</MenuItem>
                              <MenuItem value={1}>Full Time</MenuItem>
                              <MenuItem value={2}>Part Time</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            {staffData !== null ? (
                              <Autocomplete
                                name='JobTitles'
                                fullWidth
                                multiple
                                freeSolo={false}
                                id='core-jobTitle'
                                placeholder=''
                                options={[
                                  ...new Set(
                                    jobTitleSuggestions.map((opt) => opt.title)
                                  ),
                                ]}
                                onInputChange={(e, value) => {
                                  setJobTitleInputValue(value);
                                }}
                                onChange={(e, v) => {
                                  setFieldValue('jobTitles', v);
                                  handleJobTitleChange(e, v);
                                }}
                                defaultValue={(e) => {
                                  let data = getJobTitleDefault();
                                  if (data !== null || data !== undefined) {
                                    setFieldValue('jobTitles', data);
                                    setJobTitles(data);
                                    return data;
                                  }
                                }}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField {...params} label={'Job Titles'} />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(option, inputValue);
                                  const parts = parse(option, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            {staffData !== null ? (
                              <Autocomplete
                                fullWidth
                                multiple
                                freeSolo={false}
                                name='phaseYears'
                                id='core-phaseyear'
                                options={[
                                  ...new Set(
                                    phaseyearSuggestions.map((opt) => opt.title)
                                  ),
                                ]}
                                onInputChange={(e, value) => {
                                  setPhaseYearInputValue(value);
                                }}
                                onChange={(e, v) => {
                                  setFieldValue('phaseYears', v);
                                  handlePhaseYearChange(e, v);
                                }}
                                defaultValue={(e) => {
                                  let data = getPhaseYearDefault();
                                  if (data !== null || data !== undefined) {
                                    setFieldValue('phaseYears', data);
                                    setPhaseYears(data);
                                    return [...new Set(data)];
                                  }
                                }}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={
                                      storageService.getItem(
                                        WebStorageNames.UsRegion
                                      ) === 'us'
                                        ? 'Grade'
                                        : 'Year or Key Stage'
                                    }
                                  />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(option, inputValue);
                                  const parts = parse(option, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            {staffData !== null ? (
                              <Autocomplete
                                multiple
                                readOnly
                                disabled
                                name='jobRoles'
                                id='core-jobRole'
                                options={[
                                  ...new Set(
                                    jobRoleSuggestions.map((opt) => opt.title)
                                  ),
                                ]}
                                onChange={(e, v) => {
                                  setFieldValue('jobRoles', v);
                                  handleJobRoleChange(e, v);
                                }}
                                defaultValue={(e) => {
                                  let data = getJobRoleDefault();
                                  if (data !== 0 || data !== undefined) {
                                    setFieldValue('jobRoles', data);
                                    setJobRoles(data);
                                    return [...new Set(data)];
                                  }
                                }}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField {...params} label={'Job Roles'} />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(option, inputValue);
                                  const parts = parse(option, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            {staffData !== null ? (
                              <Autocomplete
                                multiple
                                readOnly
                                disabled
                                name='departments'
                                id='core-department'
                                options={[
                                  ...new Set(
                                    departmentSuggestions.map(
                                      (opt) => opt.title
                                    )
                                  ),
                                ]}
                                onChange={(e, v) => {
                                  setFieldValue('departments', v);
                                  handleDepartmentChange(e, v);
                                }}
                                defaultValue={(e) => {
                                  let data = getDepartmentDefault();
                                  if (data !== 0 || data !== undefined) {
                                    setFieldValue('departments', data);
                                    setDepartments(data);
                                    return [...new Set(data)];
                                  }
                                }}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={'Departments'}
                                  />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(option, inputValue);
                                  const parts = parse(option, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            ) : null}
                          </FormControl>
                        </Grid>

                        {staffFilters.map((obj, index) => (
                          <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                              freeSolo={true}
                              name={obj.FilterValue.Filter.Attribute}
                              defaultValue={{
                                title: obj.FilterValue.Value,
                                key: obj.FilterValue.FilterId,
                              }}
                              options={[
                                ...new Set(
                                  filterValueSuggestions
                                    .filter(
                                      (x) => x.key == obj.FilterValue.FilterId
                                    )
                                    .sort((a, b) => (a.name > b.name ? -1 : 1))
                                ),
                              ]}
                              onInputChange={(e, v) => {
                                setFieldValue(
                                  obj.FilterValue.Filter.Attribute,
                                  {
                                    value: v,
                                    key: obj.FilterValue.Filter.Id,
                                  }
                                );
                                reInitializeForm(setFieldValue);
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={obj.FilterValue.Filter.Attribute}
                                />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.title, inputValue);
                                const parts = parse(option.title, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                          </Grid>
                        ))}

                        {reminingSchoolFilters.map((obj, index) => (
                          <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                              freeSolo={true}
                              name={obj.Attribute}
                              options={[
                                ...new Set(
                                  filterValueSuggestions.filter(
                                    (x) => x.key == obj.Id
                                  )
                                ),
                              ]}
                              onInputChange={(e, v) => {
                                setFieldValue(obj.Attribute, {
                                  value: v,
                                  key: obj.Id,
                                });
                                reInitializeForm(setFieldValue);
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField {...params} label={obj.Attribute} />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.title, inputValue);
                                const parts = parse(option.title, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <AppButton
                        color={purple}
                        type='button'
                        onClick={handleSubmit}
                        style={{ marginTop: '20px' }}
                      >
                        Save
                      </AppButton>
                    </StaffWrapper>

                    {/* Core Filters Start */}
                  </Box>
                </div>
              }
            />
          </Grid>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(EditStaffMember);
