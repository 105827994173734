import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../resources/styling';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FloatingTextareaLabel } from '../../components';
import { colors } from '../../resources/theme/colors';
import 'react-day-picker/lib/style.css';
import { AppModel } from '../../components';
import Grid from '@mui/material/Grid';
import { loaderAction } from '../../store/loaderStore';
import { actionPlanService, additionalQuestionService } from '../../services';
import { actionPlanActions } from '../../store/actionPlanStore';
import {
  NewSurveyWrapper,
  NewSurveyInnerMain,
} from '../../resources/styling/appStyle';
import { styled } from '@mui/material/styles';
import { FormControl, TextField, Tooltip } from '@mui/material';
import {
  AdditionalQuestionsFormWrapper,
  InputWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  FooterButtonsWrapper,
} from './AdditionalQuestionStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { HeadTeacherAccess } from '../../utils';
import { toast } from 'react-toastify';

const { mandy, outrageousOrange } = colors;

/*** Table Style ***/
const purple = {
  50: '#45338C',
  100: '#45338C',
};
const Root = styled('div')(
  ({ theme }) => `
      table {	  
        font-size: 14px !important;
        border-collapse: collapse;
        width: 100%;
        font-weight: 500 !important;
        margin-top: 15px;
        margin-bottom: 0 !important;
      }
    
      td,
      th {	  	 
        padding: 8px !important; 
        font-size: 14px !important; 
      text-align: left;
      }
  
      tr:nth-of-type(odd){
          background-color: ${theme.palette.action.hover};
      }
    
      th {
        background-color: ${
          theme.palette.mode === 'dark' ? purple[50] : purple[100]
        };
        color: ${theme.palette.common.white};
      }
      `
);
class AddLikertForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      likertId: '',
      newOpt: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: '',
      schoolId: props.schoolId || 0,
      isValid: false,
      likertData: [], //likertConstData,
      accessLevel: 0,
    };
  }

  componentDidMount() {
    this.getLikerts();
  }

  getLikerts() {
    let likertData = [];
    additionalQuestionService
      .getLikertsBySchoolId(this.state.schoolId)
      .then((res) => {
        res.data.map((item) => {
          console.log('data', item);
          const {
            Id,
            LikertOption1,
            LikertOption2,
            LikertOption3,
            LikertOption4,
            LikertOption5,
          } = item;

          likertData.push({
            id: Id,
            opts: [
              LikertOption1,
              LikertOption2,
              LikertOption3,
              LikertOption4,
              LikertOption5,
            ],
            isUpdate: Id > 4 ? true : false,
            isDelete: Id > 4 ? true : false,
          });
        });
        this.setState({
          likertData: likertData,
        });
      });
  }

  checkIfValid = (e) => {
    setTimeout(() => {
      const { option1, option2, option3, option4, option5 } = this.state;
      this.setState({
        isValid: option1 && option2 && option3 && option4 && option5,
      });
    }, 0);
  };

  handleSubmitCustomLikert(values) {
    this.addCustomLikert(values);
  }

  addCustomLikert(options) {
    const { Id, Option1, Option2, Option3, Option4, Option5 } = options;
    const obj = {
      Id,
      LikertOption1: Option1,
      LikertOption2: Option2,
      LikertOption3: Option3,
      LikertOption4: Option4,
      LikertOption5: Option5,
      SchoolId: this.state.schoolId,
    };
    additionalQuestionService
      .setCustomLikert(obj)
      .then((response) => {
        if (response.success) {
          const { data, message } = response;
          if (data) {
            toast.success(message);
            const { likertData } = this.state;
            const Id = Math.max(...likertData.map((x) => x.id)) + 1;
            if (obj.Id > 0) {
              //Update
              this.setState({
                likertData: likertData.map((x) =>
                  x.id === obj.Id && x.isUpdate
                    ? {
                        ...x,
                        opts: [
                          obj.LikertOption1,
                          obj.LikertOption2,
                          obj.LikertOption3,
                          obj.LikertOption4,
                          obj.LikertOption5,
                        ],
                      }
                    : x
                ),
                isEdit: false,
              });
            } else {
              //add
              this.setState((prevData) => ({
                likertData: [
                  ...prevData.likertData,
                  {
                    id: Id,
                    opts: [
                      obj.LikertOption1,
                      obj.LikertOption2,
                      obj.LikertOption3,
                      obj.LikertOption4,
                      obj.LikertOption5,
                    ],
                    isUpdate: true,
                    isDelete: true,
                  },
                ],
              }));
            }

            this.setState({
              option1: '',
              option2: '',
              option3: '',
              option4: '',
              option5: '',
            });
          } else {
            toast.error(message);
          }
        } else {
          toast.error('Something went wrong.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleLikertDelete(id) {
    additionalQuestionService
      .deleteCustomLikert(id)
      .then((response) => {
        if (response.success) {
          //display toast message
          toast.success('Likert deleted successfully.');
          const { likertData } = this.state;
          this.setState({
            likertData: likertData.filter((x) => x.id !== id),
          });
        } else {
          toast.error('Something went wrong.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleLikertUpdate(id) {
    const { likertData } = this.state;
    const { opts } = likertData.find((x) => x.id === id && x.isUpdate);
    this.setState({
      likertId: id,
      option1: opts[0],
      option2: opts[1],
      option3: opts[2],
      option4: opts[3],
      option5: opts[4],
      isEdit: true,
    });
    this.checkIfValid();
  }

  render() {
    const { isOpen, onClose } = this.props;
    const {
      likertId,
      option1,
      option2,
      option3,
      option4,
      option5,
      isEdit,
      likertData,
      accessLevel,
      isValid,
    } = this.state;
    const classes = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Id: likertId || 0,
          Option1: option1 || '',
          Option2: option2 || '',
          Option3: option3 || '',
          Option4: option4 || '',
          Option5: option5 || '',
        }}
        onSubmit={(values, actions) => {
          const { isEdit } = this.state;
          if (!isEdit) values.Id = 0;
          actions.setSubmitting(true);
          this.handleSubmitCustomLikert(values);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          Option1: Yup.string().trim().required('Likert Option 1 required.'),
          Option2: Yup.string().trim().required('Likert Option 2 required.'),
          Option3: Yup.string().trim().required('Likert Option 3 required.'),
          Option4: Yup.string().trim().required('Likert Option 4 required.'),
          Option5: Yup.string().trim().required('Likert Option 5 required.'),
        })}
        render={({ values, handleSubmit, setFieldValue }) => (
          <div className='styles_modal__gNwvD'>
            <AppModel
              open={isOpen}
              onClose={onClose}
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <NewSurveyInnerMain>
                          <NewSurveyInner>
                            <AdditionalQuestionsFormWrapper>
                              <h2>Add Likert</h2>
                              <div className='action-plan-instructions'>
                                Type your Likerts in the boxes below.
                              </div>

                              <InputWrapper>
                                <Grid container spacing={2}>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <TextField
                                        value={values.Option1}
                                        name='Option1'
                                        id='likert-option'
                                        label='Likert Option 1'
                                        style={{ width: '350px' }}
                                        onChange={(e) => {
                                          var option = e.target.value;
                                          setFieldValue('Option1', option);
                                          this.setState({
                                            option1: option,
                                          });
                                          this.checkIfValid();
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <TextField
                                        value={values.Option2}
                                        name='Option2'
                                        id='likert-option'
                                        label='Likert Option 2'
                                        style={{ width: '350px' }}
                                        onChange={(e) => {
                                          var option = e.target.value;
                                          setFieldValue('Option2', option);
                                          this.setState({
                                            option2: option,
                                          });
                                          this.checkIfValid();
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <TextField
                                        value={values.Option3}
                                        name='Option1'
                                        id='likert-option'
                                        label='Likert Option 3'
                                        style={{ width: '350px' }}
                                        onChange={(e) => {
                                          var option = e.target.value;
                                          setFieldValue('Option3', option);
                                          this.setState({
                                            option3: option,
                                          });
                                          this.checkIfValid();
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <TextField
                                        value={values.Option4}
                                        name='Option2'
                                        id='likert-option'
                                        label='Likert Option 4'
                                        style={{ width: '350px' }}
                                        onChange={(e) => {
                                          var option = e.target.value;
                                          setFieldValue('Option4', option);
                                          this.setState({
                                            option4: option,
                                          });
                                          this.checkIfValid();
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <TextField
                                        value={values.Option5}
                                        name='Option5'
                                        id='likert-option'
                                        label='Likert Option 5'
                                        style={{ width: '350px' }}
                                        onChange={(e) => {
                                          var option = e.target.value;
                                          setFieldValue('Option5', option);
                                          this.setState({
                                            option5: option,
                                          });
                                          this.checkIfValid();
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </InputWrapper>
                            </AdditionalQuestionsFormWrapper>
                          </NewSurveyInner>
                        </NewSurveyInnerMain>
                        <AppButton
                          color={outrageousOrange}
                          type='button'
                          isDisable={!isValid}
                          onClick={handleSubmit}
                          style={{ float: 'right' }}
                        >
                          {isEdit ? 'Update' : 'Create'}
                        </AppButton>
                      </Grid>

                      {/* Table */}
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        style={{ marginTop: '0px' }}
                      >
                        <div className='search-inner-sub-container'>
                          <h3>Likert Options</h3>
                          <Root>
                            <table>
                              <thead>
                                <tr>
                                  <th>SR#</th>
                                  <th>Options</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {likertData.map((opt, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{opt.opts.join(', ')}</td>
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-evenly',
                                        }}
                                      >
                                        {opt.isUpdate && (
                                          <Tooltip
                                            title='Delete Option'
                                            aria-label='add'
                                          >
                                            <DeleteIcon
                                              style={{
                                                color: '#E84F6B',
                                                cursor: 'pointer',
                                              }}
                                              sx={{ fontSize: '20px' }}
                                              onClick={() => {
                                                if (
                                                  accessLevel ===
                                                    HeadTeacherAccess.Full &&
                                                  opt.isDelete
                                                ) {
                                                  this.handleLikertDelete(
                                                    opt.id
                                                  );
                                                }
                                              }}
                                            />
                                          </Tooltip>
                                        )}

                                        {accessLevel ===
                                          HeadTeacherAccess.Full &&
                                        opt.isUpdate ? (
                                          <Tooltip
                                            title='Edit question'
                                            aria-label='add'
                                          >
                                            <EditIcon
                                              color='primary'
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              sx={{ fontSize: '20px' }}
                                              onClick={() => {
                                                if (
                                                  accessLevel ===
                                                    HeadTeacherAccess.Full &&
                                                  opt.isUpdate
                                                ) {
                                                  this.handleLikertUpdate(
                                                    opt.id
                                                  );
                                                }
                                              }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Root>
                        </div>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionPlanService, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AddLikertForm);
