import { actionTypes } from './actionTypes';

const { SET_SURVEY_DATA } = actionTypes;

////////////////////// SURVEY ACTIONS //////////////////////
export const setSurveyRecord = value => ({
  type: SET_SURVEY_DATA,
  payload: value
});
//////////// SURVEY REDUCER ///////////////
const INITIAL_STATE = {
  surveyRecord: []
};
export const surveyAction = {
  setSurveyRecord
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_SURVEY_DATA) {
    return { ...state, surveyRecord: action.payload };
  } else {
    return state;
  }
};
