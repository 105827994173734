import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { GridActionsCellItem } from '@mui/x-data-grid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import {
  HeaderDataGrid,
  SchoolListMainWrapper,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import { filterLibraryService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { getSelectedRow } from '../../utils/CommonFunctions';
import { SchoolSurveyInner, SchoolSurveyWrapper } from '../school/SchoolStyle';
import AddFilter from './AddFilter';
import EditFilter from './EditFilter';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

const FilterLibrary = (props) => {
  const getFilterLibrary = () => {
    if (pageState !== undefined) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        data: old.data,
        total: old.total,
        queryOptions: old.queryOptions,
        filterOptions: old.filterOptions,
        value: old.value,
      }));
    }

    let filter = null;
    if (pageState?.filterOptions?.field !== '') {
      filter = [
        {
          Field: pageState.filterOptions.field,
          Value: pageState.filterOptions.value,
        },
      ];
    }

    let order = null;
    if (pageState?.queryOptions?.field !== '') {
      order = [
        {
          Field: pageState.queryOptions.field,
          Sort: pageState.queryOptions.sort,
        },
      ];
    }
    const model = {
      Start: (pageState.page - 1) * pageState.pageSize,
      Length: pageState.pageSize,
      Order: order,
      Filter: filter,
      Search: {
        Value: pageState.value,
      },
      Columns: [],
    };

    filterLibraryService
      .getFilterLibrary(model)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          const dataRow = [...data.Data].map((item, index) => {
            return {
              id: item.Id,
              keyWord: item.JobTitle,
              jobRole: item.JobRole,
              department: item.Department,
              phaseYear: item.PhaseYear,
              school: item?.School?.Name,
              isApproved: item.IsApproved,
              isHOD: item.IsHOD,
            };
          });
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: dataRow,
            total: data.RecordsFiltered,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
          }));
        }
      })
      .catch((error) => {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: old.data,
          total: old.total,
        }));
        // loaderActions.dataLoading(false);
      });
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    accessLevel: false,
  });
  const [filterId, setFilterId] = useState(0);
  const [isShowEditFilter, setIsShowEditFilter] = useState(false);
  const [isShowAddFilter, setIsShowAddFilter] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [filterModel, setFilterModel] = useState(null);
  const [isUnApproved, setIsUnApproved] = useState(false);

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    queryOptions: { field: '', sort: '' },
    value: null,
    filterOptions: { field: '', value: 0 },
  });
  const [snackbar, setSnackbar] = React.useState(null);

  useEffect(() => {
    getFilterLibrary();
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.queryOptions,
    pageState.value,
    pageState.filterOptions,
  ]);

  const handleClose = () => {
    setIsShowAddFilter(false);
    setIsShowEditFilter(false);
    setIsApprovePopupOpen(false);
  };
  const handleCloseSnackbar = () => setSnackbar(null);

  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        queryOptions: {
          field: sortModel[0].field,
          sort: sortModel[0].sort,
        },
      }));
    }
  }, []);

  const handleSearch = (value) => {
    if (value?.length > 2 || value === '') {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        value: value,
      }));
    }
  };

  const handleIsApproveChange = (e) => {
    if (e) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        filterOptions: {
          field: 'IsUnApprove',
          value: e,
        },
      }));
    } else {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        filterOptions: {
          field: 'IsUnApprove',
          value: e,
        },
      }));
    }
  };
  const onConfirmApprove = () => {
    filterLibraryService
      .saveFilter(filterModel)
      .then((response) => {
        if (response.success) {
          toast.success('Record Updated successfully.');
          getFilterLibrary();
          handleClose();
        }
        // else if (
        //   response.message === 'email already exist' ||
        //   response.message === 'email should be example@domain.com'
        // )
        // {
        //   formProps.setErrors({ email: response.message });
        // }
        else {
          toast.error('Record not Updated. Please try again later.');
        }
      })
      .catch((error) => { });
  };

  const handleDelete = (id) => {
    filterLibraryService
      .deleteFilter(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success('Record deleted successfully.');
          setIsRemovePopupOpen(false);
          getFilterLibrary();
        }
      })
      .catch((error) => { });
  };

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'keyWord',
      headerName: 'Job Title',
      flex: 0.3,
      editable: true,

      preProcessEditCellProps: (params) => {
        const hasError = params.props.value.length < 3;
        return { ...params.props, error: hasError };
      },
    },

    {
      field: 'jobRole',
      headerName: 'Job Role',
      flex: 0.3,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value.length < 3;
        return { ...params.props, error: hasError };
      },
      type: 'singleSelect',
      valueOptions: [
        'Teaching Staff (Classroom)',
        'Teaching Staff (Non-Classroom)',
        'Support Staff (Classroom)',
        'Support Staff (Non-Classroom)',
        'Middle Leader',
        'Senior Leader',
      ],
    },
    {
      field: 'department',
      headerName: 'Department',
      flex: 0.3,
      editable: true,
    },
    {
      field: 'phaseYear',
      headerName: 'PhaseYear',
      flex: 0.3,
      editable: true,
    },
    {
      field: 'school',
      headerName: 'School',
      flex: 0.3,
      editable: true,
    },
    {
      field: 'isHOD',
      headerName: 'HOD',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.value === true ? (
              <GridActionsCellItem icon={<CheckIcon />} label='Yes' />
            ) : (
              <GridActionsCellItem icon={<CloseIcon />} label='No' />
            )}
          </>
        );
      },
    },
    {
      field: 'isApproved',
      headerName: 'Approved',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.value === true ? (
              <GridActionsCellItem icon={<CheckIcon />} label='Approved' />
            ) : (
              <GridActionsCellItem icon={<CloseIcon />} label='Not Approved' />
            )}
          </>
        );
      },
      cellClassName: (params) => {
        return clsx('filter-lib-isApproved', {
          negative: params.value === false,
        });
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        const handleUpdate = (e) => {
          let row = getSelectedRow(params);
          setFilterId(JSON.stringify(row.id, null, 4));
          setIsShowEditFilter(true);
        };

        const onDeleteClick = (e) => {
          e.stopPropagation();
          let row = getSelectedRow(params);
          let id = JSON.stringify(row.id, null, 4);
          setFilterId(id);
          setIsRemovePopupOpen(true);
        };

        const handleApprove = (e) => {
          let row = getSelectedRow(params);

          if (row.keyWord === null || row.jobRole === null) {
            setSnackbar({
              children:
                row.keyWord === null
                  ? 'Job Title is required'
                  : 'Job Role is required',
              severity: 'error',
            });
          } else {
            setIsApprovePopupOpen(true);
            let model = {
              Id: row.id,
              KeyWord: row.keyWord,
              JobRole: row.jobRole,
              Department: row.department,
              PhaseYear: row.phaseYear,
              IsApproved: true,
              isApproval: true,
            };
            setFilterModel(model);
          }
        };

        return (
          <div>
            {params.row.isApproved ? (
              <GridActionsCellItem
                icon={<EditIcon onClick={handleUpdate} />}
                label='Edit'
              />
            ) : (
              <Tooltip title='Approve Filter'>
                <GridActionsCellItem
                  icon={<AddTaskIcon onClick={handleApprove} />}
                  label='Approve'
                />
              </Tooltip>
            )}

            <Tooltip title='Delete'>
              <GridActionsCellItem
                icon={<DeleteIcon onClick={onDeleteClick} />}
                label='Delete'
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <SchoolSurveyWrapper>
        <SchoolSurveyInner>
          <SchoolListMainWrapper>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <div className='filter-header'>
                  <h1>Filter Library</h1>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                    spacing={2}
                    display='inline-flex'
                    style={{ float: 'right' }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: '#ffffff',
                              '&.Mui-checked': {
                                color: '#ffffff',
                              },
                            }}
                          />
                        }
                        label='Unapproved'
                        checked={isUnApproved}
                        name='Unapproved'
                        onChange={(e) => {
                          handleIsApproveChange(e.target.checked);
                          setIsUnApproved(e.target.checked);
                        }}
                      />
                    </FormGroup>
                    <WelbeeTextField
                      className='staff-search-txtfield'
                      size='small'
                      name='search'
                      id='search'
                      label='Search'
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />

                    <Button
                      style={{
                        backgroundColor: '#e84f6b',
                      }}
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        setIsShowAddFilter(true);
                      }}
                    >
                      Add new filter
                    </Button>
                  </Stack>
                </div>
              </Grid>
              <Grid lg={12} md={12} xs={12} mb='20px' mx='20px'>
                <Box
                  spacing={2}
                  sx={{
                    width: '100%',
                    '& .filter-lib-isApproved.negative': {
                      backgroundColor: 'rgba(255, 99, 71, 0.6)',
                    },

                    flexGrow: 1,
                  }}
                >
                  <Grid container>
                    <Grid item lg={12} md={12} xs={12} mt='20px'>
                      <div style={{ height: 511, width: '100%' }}>
                        <HeaderDataGrid
                          isCellEditable={(params) =>
                            params.row.isApproved !== true
                          }
                          rows={pageState.data}
                          rowCount={pageState.total}
                          loading={pageState.isLoading}
                          sortingMode='server'
                          onSortModelChange={handleSortModelChange}
                          rowsPerPageOptions={[10, 30, 50, 70, 100]}
                          pagination
                          page={pageState.page - 1}
                          pageSize={pageState.pageSize}
                          paginationMode='server'
                          onPageChange={(newPage) => {
                            setPageState((old) => ({
                              ...old,
                              page: newPage + 1,
                            }));
                          }}
                          onPageSizeChange={(newPageSize) =>
                            setPageState((old) => ({
                              ...old,
                              pageSize: newPageSize,
                            }))
                          }
                          rowHeight={40}
                          columns={columns}
                          disableColumnMenu
                          disableColumnFilter={true}
                          disableColumnSelector={true}
                          disableDensitySelector={true}
                          disableExportButton={true}
                          disableSelectionOnClick={true}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                              ? 'even'
                              : 'odd'
                          }
                          columnVisibilityModel={columnVisibilityModel}
                          onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                          }
                          components={{
                            NoRowsOverlay: () => (
                              <Stack
                                height='100%'
                                alignItems='center'
                                justifyContent='center'
                              >
                                No record
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height='100%'
                                alignItems='center'
                                justifyContent='center'
                              >
                                No record
                              </Stack>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </SchoolListMainWrapper>
        </SchoolSurveyInner>
      </SchoolSurveyWrapper>
      {isShowAddFilter ? (
        <AddFilter
          handleClose={handleClose}
          getFilterLibrary={getFilterLibrary}
        />
      ) : (
        <div></div>
      )}
      {isShowEditFilter ? (
        <EditFilter
          handleClose={handleClose}
          filterId={filterId}
          getFilterLibrary={getFilterLibrary}
        />
      ) : (
        <div></div>
      )}
      {isRemovePopupOpen ? (
        <ConfirmPopupForm
          isOpen={isRemovePopupOpen}
          onClose={() => {
            setIsRemovePopupOpen(false);
            setFilterId('');
          }}
          onOk={() => {
            handleDelete(filterId);
          }}
          successPopupMessage='Are you sure to remove this item?'
        />
      ) : (
        <div></div>
      )}
      {isApprovePopupOpen ? (
        <ConfirmPopupForm
          isOpen={isApprovePopupOpen}
          onClose={() => {
            setIsApprovePopupOpen(false);
            setFilterModel(null);
          }}
          onOk={() => {
            onConfirmApprove(filterModel);
          }}
          successPopupMessage='Are you sure to approve this record?'
        />
      ) : (
        <div></div>
      )}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(FilterLibrary);
