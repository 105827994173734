import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme';
import { MediaQuery } from '../../resources/styling/appStyle';
const { pictonBlue, whiteColor, teal, mandy, blackColor } = colors;

export const AssistanceWrapper = styled.div`
  width: 100%;
  position: relative;
  button {
    border: 0;
    background: ${teal};
    color: ${whiteColor};
    font-size: 14px;
    border-radius: 8px;
    float: right;
    cursor: pointer;
    width: 45px;
    height: 45px;
    line-height: 49px;
    img {
      height: 14px;
    }

    ${media.xs`
      float:none;
      margin-top: 0px;
      padding: 9px 18px;        
    `}
  }

  .action-heading-wrapper {
    font-size: 20px;
    font-weight: 900;
    float: left;
    .action-heading {
      display: block;
      font-weight: 900;
      font-size: 24px;
      color: ${blackColor};
    }
    span {
      color: ${blackColor};
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 600px) {
    .action-heading-wrapper {
      width: 100%;
    }
  }

  @media only screen and (max-width: 700px) {
    .btn-request-assistance button {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
    .dd-survey .latest-survey {
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const ActionPlanMainWrapper = styled.div`
  .mobile-wrapper {
    padding-bottom: 0;
  }
  .latest-survey {
    .options {
      max-height: inherit;
    }
    .selected-option {
      border: 0;
      font-weight: 500;
    }
    .active-option {
      background: #45338c;
      color: ${whiteColor};
    }

    float: right;
    margin-right: 8px;
    > div {
      margin: 0;
      border-radius: 8px;
      min-height: 45px;
      min-width: 123px;
      background: #45338c;
      color: ${whiteColor} !important;
      .selected-option {
        min-height: 45px;
        padding: 13px 25px;
        label {
          color: ${whiteColor} !important;
        }
      }
    }

    .options {
      border-radius: 5px;
      background: #45338c;
      color: ${whiteColor};
      padding: 10px 0;
      margin: -10px 0 0 0;
      z-index: 9999;
      span {
        font-size: 12px;
        padding: 7px 11px 7px 26px;
        color: ${whiteColor};
      }
    }
    .selected-option {
      padding: 13px 25px;
      font-size: 14px;
      img {
        margin-left: 17px;
        width: 12px;
        margin-top: 6px;
      }
    }
  }
  mark {
    background: transparent !important;
    color: ${mandy} !important;
    font-weight: 900;
  }
  .selected-option {
    cursor: pointer;
  }
  .suggested-actions-wrapper {
    margin: 20px 0 15px 0;
    float: left;
    width: 100%;
    .action-plan-options {
      .action-plan-detail {
        float: left;
        width: 100%;
        position: relative;
        padding-right: 20px;
        box-sizing: border-box;
        z-index: 1;
      }
      .add-action-plan {
        padding-left: 0;
        padding-right: 0;
        font-weight: 900;
        float: right;
        font-style: normal;
        z-index: 2;
        text-decoration: underline;
        cursor: pointer;
      }
      .action-category-heading {
        font-size: 16px;
        font-weight: 900;
        padding: 30px 39px 15px 20px;
        cursor: auto;
      }
    }
    .action-plan-options span {
      font-size: 15px;
      line-height: 24px;
      padding-left: 20px;
      cursor: pointer;
      :hover {
        background: rgba(83, 181, 224, 0.25);
      }
    }
  }
  .searched-data-wrapper {
    display: none;
  }

  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
  float: left;
  width: 100%;
  ${media.xs`
    float: none;
  `}
  ${media.sm`
    float: none;
  `}
  .staff-search-txtfield {
    margin-right: 8px;
    label {
      font-size: 14px;
      color: ${blackColor} !important;
    }
  }
  .MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
    .MuiDataGrid-sortIcon {
      opacity: 1 !important;
      font-size: 15px;
      visibility: visible !important;
    }
  }
  .staff-search-dd{
    .css-2ef9og-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
      font-size: 14px;      
    }
    label {
      font-size: 14px;
      color: ${blackColor} !important;
    }
  }
`;
