import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import { LeftMenu, Dropdown, SurveyDropDown } from '../../components';
import { SurveyTypes } from '../../utils/Constants';
import { staffResultServices } from '../../services';
import { AssistanceWrapperHeading } from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { ExtendedSubContainer, ExtendedSearchFilterWrapper, ExtendedAssistanceWrapper, CommentsMainWrapper } from './StaffResultsStyle';

const {  
  whiteColor, minttulipLight, yourpinkLight  
} = colors;

const SortOrder = {
  Newest: 'Support staff',
  Oldest: 'Teaching staff',
};

const sortingOptions = [
  { value: SortOrder.Newest, label: 'Support staff' },
  { value: SortOrder.Oldest, label: 'Teaching staff' },
];

class StaffResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingSelectedOption: {
        value: SortOrder.Newest,
        label: 'Filter by staff type',
      },
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: '' }],
      griddata: [],
    };
  }

  componentDidMount() {
    const token = window.location.search.split('token=')[1];
    staffResultServices
      .getStaffResultHeatMap(token)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          this.setState({ griddata: data });
        }
      })
      .catch();
  }

  boxColor(value) {
    if (value >= 1 && value <= 3) {
      return minttulipLight;
    } else if (value >= 7 && value <= 10) {
      return yourpinkLight;
    }
    return whiteColor;
  }

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    window.location.search = '?token=' + latestSurveyOption.value;
    this.getSurveyCommentsData(latestSurveyOption.value);
  };

  render() {
    const {
      sortingSelectedOption,
      latestSurveyOptions,
      latestSurveyOption,
      griddata,
    } = this.state;
    return (
      <CommentsMainWrapper>
        <div className='containerClass'>
          
            <div className='left-container'>
              <LeftMenu />
            </div>

            <div className='right-container'>
              <ExtendedAssistanceWrapper>
                <AssistanceWrapperHeading>
                  <span className='comment-heading'>Staff Results</span>
                  <span>
                    {/* {this.countComments()} */} comments from past surveys
                  </span>
                </AssistanceWrapperHeading>
                <RequestAssistanceButton />
                <SurveyDropDown
                  // isRole={isRole}
                  latestSurveyOptions={latestSurveyOptions}
                  latestSurveyOption={latestSurveyOption}
                  handleSurveyValueChange={this.handleSurveyValueChange}
                />
              </ExtendedAssistanceWrapper>
              <ExtendedSubContainer>
                <ExtendedSearchFilterWrapper>
                  <div className='subcontainer-inner'>
                    <div className='search-sorting'>
                      <Dropdown
                        name='SortFilter'
                        options={sortingOptions}
                        selectedOption={sortingSelectedOption}
                        onChange={this.handleSortingValueChange}
                        value={sortingSelectedOption}
                      />
                    </div>
                  </div>
                </ExtendedSearchFilterWrapper>

                <div className='search-data-container'>
                  <div className='search-inner-container'>
                    <div className='search-inner-sub-container'>
                      <div className='staff-result-heading'>
                        <span>Results by staff type</span>
                        <span>
                          Scores are only reported where there are 5 or more
                          participants in that category.
                        </span>
                      </div>
                      <div className='staff-type-grid'>
                        <ul>
                          <li>Staff type</li>
                          <li>Demands</li>
                          <li>Control</li>
                          <li>Manager support</li>
                          <li>Peer support</li>
                          <li>Relationships</li>
                          <li>Role</li>
                          <li>Change</li>
                        </ul>
                      </div>

                      <div className='staff-type-grid-data staff-type-grid'>
                        {griddata.map((items, index) => {
                          return (
                            <ul>
                              <li key={index}>
                                {items.StaffType} <br />
                                <span>{items.ClassRoom}</span>
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.DemandColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.Demand}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.ControlColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.Control}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.ManagerSupportColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.ManagerSupport}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.PeerSupportColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.PeerSupport}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.RelationshipsColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.Relationships}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.RoleColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.Role}
                              </li>
                              <li
                                style={{
                                  backgroundColor: this.boxColor(
                                    items.ChangeColor
                                  ),
                                }}
                                key={index}
                              >
                                {items.Change}
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </ExtendedSubContainer>
            </div>
         
        </div>
      </CommentsMainWrapper>
    );
  }
}

export default connect()(withRouter(StaffResults));
