import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MuiDropDown from '../../../components/common/MuiDropDown';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../../components';
import {
  AppButton,
  AssitanceForm,
  ErrorWrapper,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInner,
  NewSurveyInnerMain,
  NewSurveyWrapper,
} from '../../../resources/styling';
import { ExtendedWrapperMain } from '../../../resources/styling/formStyle';
import { colors } from '../../../resources/theme/colors';
import {
  matCycleService,
  matGroupService,
  surveyService,
} from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { InputWrapper } from './MatCycleStyle';

const { purple, outrageousOrange } = colors;

const AddCycleSchool = (props) => {
  const [matSchools, setMatSchools] = useState([]);
  const [school, setSchool] = useState('');
  const [survey, setSurvey] = useState('');
  const [schoolSurveys, setSchoolSurveys] = useState([]);
  const [disabled, setDisable] = useState(false);

  useEffect(() => {
    initialValues();
    if (props.isOpen) {
      getMatSchools();
    }
  }, [props.isOpen]);

  const getSchoolSurveys = (schoolId) => {
    surveyService
      .getSchoolSurveys(schoolId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setSchoolSurveys(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading mat cycle survey');
      });
  };

  const handleSchoolChange = (option) => {
    const opt = option.target;
    setSchool(opt.value);
    getSchoolSurveys(opt.value);
  };

  const getMatSchools = () => {
    matGroupService
      .getMatSchools(props.matGroupId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setMatSchools(response.data);
        }
      })
      .catch((error) => {
        toast.error('Error loading mat cycle schools');
      });
  };

  const schoolItems =
    matSchools !== null
      ? matSchools.map((item) => {
        let schoolToAdd = [...props.data].filter(
          (school) => school.SchoolId === item.Id
        );
        if (schoolToAdd.length < 1) {
          return (
            <MenuItem key={item.Id} value={item.Id}>
              {item.Name}
            </MenuItem>
          );
        }
      })
      : null;

  const surveyItems =
    schoolSurveys !== null
      ? schoolSurveys.map((item) => {
        return (
          <MenuItem key={item.Id} value={item.Id}>
            {item.Name}
          </MenuItem>
        );
      })
      : null;

  const initialValues = () => {
    setSchool('');
    setSurvey('');
    setSchoolSurveys([]);
    setDisable(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Id: null,
        Survey: survey !== null ? survey : '',
        School: school !== null ? school : '',
        MatCycleId: props.matCycleId,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        School: Yup.array().min(1, 'school is required.').nullable(),
        MatCycleId: Yup.string().required('mat cycle is required.'),
        Survey: Yup.array().min(1, 'survey is required.').nullable(),
      })}
      onSubmit={(values, formProps) => {
        setDisable(true);
        let model = {
          SurveyId: values.Survey,
          SchoolId: values.School,
          MatCycleId: values.MatCycleId,
          IsIncluded: true,
        };
        matCycleService
          .saveCycleSchool(model)
          .then((response) => {
            if (response.success) {
              formProps.resetForm();
              toast.success('school added in cycle successfully.');
              props.handleSubmit();
              initialValues();
            } else {
              toast.error('school not added in cycle. Please try again later.');
              setDisable(false);
            }
          })
          .catch(() => {
            setDisable(false);
          });
      }}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div>
            <AppModel
              open={props.isOpen}
              onClose={props.onClose}
              titleText='Add Cycle School'
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        mt='15px'
                        className='form-column-wrapper'
                      >
                        <InputWrapper>
                          <ExtendedWrapperMain>
                            <MuiDropDown
                              name='School'
                              label='School'
                              labelId='school-label'
                              id='school'
                              value={school}
                              onChange={(option) => {
                                handleSchoolChange(option);
                                setSchool(option.target.value);
                                setFieldValue(
                                  'School',
                                  option.target.value
                                );
                              }}
                            >
                              {schoolItems}
                            </MuiDropDown>
                            <ErrorWrapper>
                              {touched.School && errors.School}
                            </ErrorWrapper>
                          </ExtendedWrapperMain>

                          <ExtendedWrapperMain>
                            <MuiDropDown
                              name='Survey'
                              label='Survey'
                              labelId='survey-label'
                              id='survey'
                              value={survey}
                              onChange={(option) => {
                                setSurvey(option.target.value);
                                setFieldValue(
                                  'Survey',
                                  option.target.value
                                );
                              }}
                            >
                              {surveyItems}
                            </MuiDropDown>
                            <ErrorWrapper>
                              {touched.Survey && errors.Survey}
                            </ErrorWrapper>
                          </ExtendedWrapperMain>
                          <AppButton
                            style={{
                              cursor: disabled
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            disabled={disabled}
                            color={outrageousOrange}
                            type='submit'
                            onClick={handleSubmit}
                          >
                            {disabled ? 'Saving...' : 'Save'}
                          </AppButton>
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={<FooterButtonsWrapper></FooterButtonsWrapper>}
            />
          </div>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(AddCycleSchool);
