import React, { Component } from 'react';
import { arrowDown, arrowUp } from '../../resources/images';
import MatAdditionalQuestionDropDownOptions from './MatAdditionalQuestionDropDownOptions';
import MatAdditionalQuestionDropDownOptionsWithButton from './MatAdditionalQuestionDropDownOptionsWithButton';
import { DropdownStyle } from '../../resources/styling/appStyle';
import { WebStorageNames } from '../../utils';
import { storageService } from '../../services';

class MatAdditionalQuestionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: true,
      accessLevel: 0,
      region: storageService.getItem(WebStorageNames.UsRegion),
    };
    this.dropDownRef = React.createRef();
  }

  componentDidMount = () => {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
  };
  handleChange = () => {
    this.setState({
      showResults: !this.state.showResults,
    });
  };

  handleOptionChange(option) {
    //this.setState({ showOption: option });
  }

  handleClick = (e) => {
    if (!this.dropDownRef.current.contains(e.target)) {
      //this.setState({ showResults: false });
    }
  };

  componentWillMount() {
    //document.addEventListener('mousedown', this.handleClick, true);
  }

  componentWillUnmount() {
    //document.removeEventListener('mousedown', this.handleClick, true);
  }

  render() {
    const {
      options,
      selectedOption,
      handleAddRemoveQuestion,
      withButton = false,
      existingQuestions,
    } = this.props;
    const { accessLevel } = this.state;
    return (
      <DropdownStyle
        selectedOption={selectedOption}
        ref={this.dropDownRef}
        className='category-inner-wrapper'
      >
        <div
          onClick={this.handleChange}
          className={
            this.state.showResults
              ? 'active-option selected-option'
              : 'selected-option'
          }
          data-test-id='selectOption'
        >
          <label>{selectedOption.label}</label>
          {!this.state.showResults && <img src={arrowDown} alt='Arrow down' />}
          {this.state.showResults && <img src={arrowUp} alt='Arrow up' />}
        </div>

        {this.state.showResults &&
          (withButton ? (
            <MatAdditionalQuestionDropDownOptionsWithButton
              options={options}
              existingQuestions={existingQuestions}
              handleAddRemoveQuestion={handleAddRemoveQuestion}
              accessLevel={accessLevel}
              region={this.state.region}
            />
          ) : (
            <MatAdditionalQuestionDropDownOptions
              options={options}
              handleAddRemoveQuestion={handleAddRemoveQuestion}
            />
          ))}
      </DropdownStyle>
    );
  }
}

export default MatAdditionalQuestionDropdown;
