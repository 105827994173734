import React from 'react';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  AppButton,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  InputWrapper,
  AssitanceForm,
  NewSurveyWrapper,
} from '../../resources/styling';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';

const { purple, outrageousOrange } = colors;

const SuccessMessage = styled.div`
  margin-bottom: 20px;
  color: black;
  font-size: 22px;
`;

const ConfirmPopupForm = ({
  isOpen,
  onClose,
  onOk,
  successPopupMessage,
  okButtonText = 'Confirm',
  titleText,
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      validationSchema={Yup.object().shape({})}
      render={() => {
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            titleText={titleText}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid item lg={12} sm={12} md={12} xs={12}>
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <InputWrapper>
                              <SuccessMessage>
                                {successPopupMessage}
                              </SuccessMessage>
                            </InputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtonsWrapper>
                <AppButton
                  color={outrageousOrange}
                  type='button'
                  onClick={onOk}
                >
                  {okButtonText}
                </AppButton>
              </FooterButtonsWrapper>
            }
          />
        );
      }}
    />
  );
};

export default ConfirmPopupForm;
