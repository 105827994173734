import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { AssistanceWrapper, AssistanceWrapperHeading } from '../../resources/styling/appStyle';
import { NewSurveyInputWrapper } from '../../resources/styling';

const {
    lightGray, purple, pictonBlue, midSoftGrey, whiteColor, barleyWhite
} = colors;

/*** Individual Heatmap Selection Form ***/
export const ExtendedNewSurveyInputWrapper = styled(NewSurveyInputWrapper)`
  padding: 10px;
  .info-text {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 21px;
    display: inline-block;
    color: ${purple};
    text-align: center;
    font-size: 16px;
  }
  .school-list-wrapper {
    border: 1px solid ${purple};

    border-radius: 6px;

    .school-title,
    .disabled {
      color: ${purple};
      display: inline-block;
      margin-left: 15px;
    }

    .school-list-body-outer {
      display: block;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 3px;
    }
    .school-list-heading,
    .school-list-body {
      border-bottom: 1px solid ${purple};
      padding: 8px 15px;
      font-size: 14px;
      font-weight: 900;
    }

    .school-list-body {
      border: 0;
      font-weight: 400;
    }
    .staff-members-title,
    .staff-members-count {
      float: right;
      margin-top: 3px;
      color: ${purple};
    }
    .staff-members-count {
      margin-right: 50px;
    }
    .disabled {
      color: ${lightGray};
    }
  }
`;

/*** Staff Role Heatmap ***/
export const AssistanceWrapperHeadingExt = styled(AssistanceWrapperHeading)`
  width: auto;
  ${media.md`
  width: auto;
  `}
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 92.7%;
  left: 50px;
  position: reletive;
  top: -26px !important;
  ${media.md`
  width: 92.7%;
  `}
  ${media.lg`
  width: 92.7%;
  `}
  ${media.xl`
  width: 92.7%;
  top: -26px !important;
  `}   

`;
export const ExtendedHeatMapAssistanceWrapper = styled(AssistanceWrapper)`
    left: 0 !important;  
`;
export const CommentsMainWrapper = styled.div`
.job-role-heading {  
  background-image: none !important;
}
   .mobile-wrapper {
    padding: 0;
    padding-bottom: 0.5rem;
   }
  .left-nav {
    max-width: 190px;
  }

  .staff-result-innercontainer
  {
    width: 100%;    
  }
  @media only screen and (max-width: 700px){
    .staff-result-innercontainer
    {      
      margin-top: 0px;
    }
  }

  .comments-wrapper {
  
    ${media.xl`
      max-width: 90%;
    `}
    ${media.md`
      width: 86%;
    `}
    ${media.lg`
    width: 86%;
    `}
    ${media.xl`
      width: 86%;
    `}
    ${media.sm`
      width: 86%;
   
    `}
  }
  .containerClass {
    padding-top:0;
    width: 100%;
    max-width: 100%;
 
  }
  float: left;
  width: 100%;
  ${media.sm`
    float: none;
  `}
  ${media.xs`
    float: none;
  `}

.tooltip {
            background-color: ${lightGray};
            color: ${midSoftGrey};

          .tooltiptext {
            display: none;
            width: 120px;
            background-color: ${pictonBlue};
            color: ${whiteColor};
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 120%;
            left: -20%;
            opacity: 0;
            transition: opacity 0.7s;
            :after {
              content: '';
              position: absolute;
              top: 100%;
              left: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: ${pictonBlue} transparent transparent transparent;
            }
          }
          :hover .tooltiptext {
            display: inline-block;
            opacity: 1;
          }
        }
      }

    //   div.heatmap-sticky::-webkit-scrollbar {
    //     background: white;    
    //     width: 7px;   
    //     position: relative;
    //     left: 7px;    
    // }
    // div.heatmap-sticky::-webkit-scrollbar-track {
    //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //     border-radius: 10px;
    // }
    // div.heatmap-sticky::-webkit-scrollbar-thumb {
    //     border-radius: 10px;
    //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    // }
    .heatmap-sticky {
        max-height: 400px;
        height: 400px        
        width: 100%;        
    }
    
    // div.heatmap-sticky:hover::-webkit-scrollbar {
    //     background: lightgrey;
    // }
    
    // div.heatmap-sticky::-moz-scrollbar {
    //   background: white;    
    //   width: 8px;
    // }
    // div.heatmap-sticky::-moz-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //   border-radius: 10px;
    // }
    // div.heatmap-sticky::-moz-scrollbar-thumb {
    //   border-radius: 10px;
    //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    // }    
    // div.heatmap-sticky:hover::-moz-scrollbar {
    //   background: lightgrey;
    // }
`;
const StaffRoleHeatmapStyle = {
    ExtendedNewSurveyInputWrapper,
    AssistanceWrapperHeadingExt,
    ExtendedAssistanceWrapper,
    ExtendedHeatMapAssistanceWrapper,
    CommentsMainWrapper
}
export default StaffRoleHeatmapStyle;