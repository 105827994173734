import React, { Component } from 'react';
import { arrowDown, arrowUp } from '../../resources/images';
import {
  DropdownStyle
} from '../../resources/styling/appStyle';

class LearnMoreDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: false
    };
    this.dropDownRef = React.createRef();
  }

  handleChange = () => {
    this.setState({
      showResults: !this.state.showResults
    });
  };

  handleOptionChange(option) {
    this.setState({ showOption: option });
  }

  // handleClick = e => {
  //   if (!this.dropDownRef.current.contains(e.target)) {
  //     this.setState({ showResults: false });
  //   }
  // };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, true);
  }

  render() {
    const { selectedOption } = this.props;
    const Wrapper = this.props.content;
    return (
      <div className='learnmore-positioning'>
        <DropdownStyle
          onClick={this.handleChange}
          selectedOption={selectedOption}
          ref={this.dropDownRef}
          className='category-inner-wrapper learn-more-dropdown'
        >
          <div
            className={
              this.state.showResults
                ? 'active-option selected-option'
                : 'selected-option'
            }
            data-test-id='selectOption'
          >
            {selectedOption.label}
            {!this.state.showResults && (
              <img src={arrowDown} alt='Arrow down' />
            )}
            {this.state.showResults && <img src={arrowUp} alt='Arrow up' />}
          </div>
        </DropdownStyle>
        {this.state.showResults && <Wrapper />}
      </div>
    );
  }
}

export default LearnMoreDropdown;
