import React from 'react';
import { AppLogo } from '../../components';
import { colors } from '../../resources/theme';
import { LeftSectionStyle } from '../../resources/styling/appStyle';
import { SurveyGreetingPresentationStyle } from './SurveyReportPresentationStyle';

const SurveyGreetingPresentation = ({ content, title, id, schoolLogo }) => {
  return (
    <SurveyGreetingPresentationStyle className="survey-greeting" id={id}>
      <div className="left-section">
        <LeftSectionStyle className="survey-category-slide">
          <div>
            <div>
              <h1 className="big-title">{title}</h1>
              <p
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
          <AppLogo type="greeting" color={colors.blackColor} width="100" />
        </LeftSectionStyle>
      </div>
      <div className="right-section">
        <div>
          <img className="greeting-avatar" src={schoolLogo} alt="" />
        </div>
      </div>
    </SurveyGreetingPresentationStyle>
  );
};

export default SurveyGreetingPresentation;
