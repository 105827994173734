import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',                
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        zIndex:'999',
        width:'30%',
        textAlign:'center',
    },    
}));
export default function AnimatedModal({parrdata,type}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button variant="contained" color="secondary" onClick={handleOpen}  >
            Additional Information on source
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}                
            >
                <Fade in={open}>
                  
                    <div className={classes.paper}>                                                                                                
                        <p>
                            <table style={{border: '1px solid black'}} >
                            {type === 'Harrasment' ? 
                            <tr style={{border: '1px solid black'}}><td><h3>The following numbers of staff indicated the origin of harassment was from.</h3>                            
                            </td></tr>
                             : <tr style={{border: '1px solid black'}}><td><h3>The following numbers of staff indicated the origin of bullying was from.</h3></td></tr>}
                                
                                {parrdata.map(Item => (                                                                    
                                 <tr><td style={{border: '1px solid black'}} >{Item}</td></tr>                            
                                ))}                               
                            </table>                                                
                        </p>
                        <Button variant="contained"  color="secondary" onClick={handleClose} >Close</Button>                                                                                                                                 
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}