import { actionTypes } from './actionTypes';

const { SURVEY_COMMENTS, SELECTED_COMMENT, SELECTED_QUESTION } = actionTypes;

export const setSurveyComments = (comments) => (dispatch) => {
  dispatch({ type: SURVEY_COMMENTS, payload: comments });
};

export const setSelectedComment = (comment) => (dispatch) => {
  dispatch({ type: SELECTED_COMMENT, payload: comment });
};

export const setSelectedQuestion = (question) => (dispatch) => {
  dispatch({ type: SELECTED_QUESTION, payload: question });
};

export const commentsActions = {
  setSurveyComments,
  setSelectedComment,
  setSelectedQuestion,
};

const INITIAL_STATE = {
  surveyComments: [],
  selectedComment: null,
  selectedQuestion: null
};

export default (state, action) => {
  state = state ? state : INITIAL_STATE;
  if (action.type === SURVEY_COMMENTS) {
    return { ...state, surveyComments: action.payload };
  } else if (action.type === SELECTED_COMMENT) {
    return {
      ...state,
      selectedComment: action.payload,
    };
  } else if (action.type === SELECTED_QUESTION) {
    return { 
      ...state, 
      selectedQuestion: action.payload, 
    };
  } else {
    return state;
  }
};
