import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ExtendedSubContainer } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { SurveyTypes } from '../../utils/Constants';
import { HeatmapCell } from '../../components';
import { limitAverageValue } from '../../utils/roundScore';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import { SuperExtendedSubContainer, CommentsMainWrapper } from './DashboardStyle';
import { Scrollbars } from 'react-custom-scrollbars-2';

const {  
  whiteColor,
  minttulip,
  yourpink,  
  lightminttulip,
  lightyourpink,
} = colors;

const SortOrder = {
  Newest: 'Support staff',
  Oldest: 'Teaching staff',
};

const QuestionDirection = ({ question, text }) => {
  return (
    <div className='questionDetail'>
      <span>{question}</span>
      <span>{text}</span>
    </div>
  );
};

class ManagementStandardHeatmap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingSelectedOption: {
        value: SortOrder.Newest,
        label: 'Filter by staff type',
      },
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: '' }],
      allStaff: props.heatMapData.AllStaff,
      jobRole: props.heatMapData.JobRole,
      timeAtSchool: props.heatMapData.TimeAtSchool,
      employmentType: props.heatMapData.EmploymentType,
      additionalFilters: props.heatMapData.AdditionalFilters,
      collapseAdditionalFilters: [],
      collapseJobRole: false,
      collapseTimeAtSchool: false,
      collapseEmploymentType: false,
      isShow: false,
      IsActionAllowed: false,
    };
  }

  componentDidMount() {
    IsActionAllowed(RestrictedActions.ColorHeatMaps)
      .then((response) => this.setState({ IsActionAllowed: response.data }))
      .catch();
    var filterCollapse = [];
    this.state.additionalFilters.map((item, index) => {
      filterCollapse[item.FilterName] = false;
    });

    this.setState({
      collapseAdditionalFilters: filterCollapse,
    });
  }

  boxColor(colorValue, boxValue) {
    if (!this.state.IsActionAllowed) return whiteColor;
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return lightminttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return lightyourpink;
    }
    return whiteColor;
  }
  staffBoxColor(colorValue, boxValue) {
    if (!this.state.IsActionAllowed) return whiteColor;
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return lightminttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return lightyourpink;
    }
    return whiteColor;
  }
  checkStaffValue(value) {
    if (value === -1) {
      return <span className='blank-entry'>-</span>;
    }
    return limitAverageValue(value);
  }

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    window.location.search = '?token=' + latestSurveyOption.value;
    this.getSurveyCommentsData(latestSurveyOption.value);
  };

  handleFilterChange = (filterName, IsFilterBenchMarksAvailable) => {
    let collapseState = this.state.collapseAdditionalFilters;
    collapseState[filterName] = (!collapseState[filterName] && IsFilterBenchMarksAvailable);
    this.setState({collapseAdditionalFilters: collapseState,});
  };

  render() {
    const {
      allStaff,
      jobRole,
      timeAtSchool,
      employmentType,
      additionalFilters,
    } = this.state;
    const { questions, categoryName } = this.props;
    return (
      <CommentsMainWrapper>
        <SuperExtendedSubContainer>
          <div className='search-data-container'>
            <div className='staff-result-innercontainer'>
              <div className='search-inner-sub-container'>                
                <div className='staff-type-grid'>                  
                  <span className='heat-map-heading'>
                    Staff Roles &amp; Characteristics
                  </span>
                  <span className='heat-map-grid-heading'>
                    <ul className='upper-heading'>
                      <li>
                        {categoryName === 'AdditionalQuestions'
                          ? 'Additional Questions'
                          : 'Management standards'}
                      </li>
                    </ul>
                    <ul className='questionNoWrapper'>
                      {questions.map((x) => {
                        return (
                          <li>
                            {`Q${x.QuestionId}`}
                            <QuestionDirection
                              question={`Q${x.QuestionId}`}
                              text={x.QuestionText}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </span>
                </div>                
                <div className='staff-type-grid-data staff-type-grid'>
                  <span className='grid-role-heading '>
                    <ul>
                      <li>All staff</li>
                    </ul>
                  </span>
                  <span className='grid-data grid-data-heading'>
                    {allStaff && (
                      <ul>
                        {questions.map((x) => {
                            return (
                                <HeatmapCell Decile={allStaff.QuestionsAvg[`Q${x.QuestionId}Color`]} Value={allStaff.QuestionsAvg[`Q${x.QuestionId}`]} Allow={this.state.IsActionAllowed} Category={categoryName} />
                          );
                        })}
                      </ul>
                    )}
                  </span>
                  <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={400} style={{ width: '100%' }}>
                    {jobRole.IsFilterBenchMarksAvailable ? (
                      <>
                        <span
                          className={`job-role-heading ${
                            (!this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable) ? '' : 'greyStrep'
                          }`}
                          id='jobrole'
                          onClick={() => {
                            this.setState({
                              collapseJobRole: (!this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable),
                            });
                          }}
                        >
                          Job role{' '} {!jobRole.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>}
                        </span>
                        {this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {jobRole.HeatMapModel.map((item) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <li>
                                          {item.StaffType} <span>{item.ClassRoom}</span>
                                        </li>
                                      ) : (<></>)}
                                    </>
                                  );
                                })}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {jobRole.HeatMapModel.map((item, index) => {
                                return (
                                  <>
                                    {item.IsBenchMarkAvailable ? (
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                          <HeatmapCell Decile={item.QuestionsAvg[`Q${x.QuestionId}Color`]} Value={item.QuestionsAvg[`Q${x.QuestionId}`]} Allow={this.state.IsActionAllowed} Category={categoryName} />
                                          );
                                        })}
                                      </ul>
                                    ) : (<></>)}
                                  </>
                                );
                              })}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </>) : <></>
                    }
                    {timeAtSchool.IsFilterBenchMarksAvailable ? (
                      <>
                        <span
                          className={`job-role-heading ${
                            (!this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable) ? '' : 'greyStrep'
                          }`}
                          onClick={() => {
                            this.setState({
                              collapseTimeAtSchool: (!this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable),
                            });
                          }}
                        >
                          Time at school {!timeAtSchool.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>}
                        </span>
                        {this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {timeAtSchool.HeatMapModel.map((item) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <li>
                                          {item.StaffType} <span>{item.ClassRoom}</span>
                                        </li>
                                      ) : (<></>)}
                                    </>
                                  );
                                })}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {timeAtSchool.HeatMapModel.map((item, index) => {
                                return (
                                  <>
                                    {item.IsBenchMarkAvailable ? (
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                          <HeatmapCell Decile={item.QuestionsAvg[`Q${x.QuestionId}Color`]} Value={item.QuestionsAvg[`Q${x.QuestionId}`]} Allow={this.state.IsActionAllowed} Category={categoryName} />
                                          );
                                        })}
                                      </ul>
                                    ) : (<></>)}
                                  </>
                                );
                              })}
                            </span>{' '}
                          </>
                        ) : (
                          <></>
                        )}
                      </>) : <></>
                    }
                    {employmentType.IsFilterBenchMarksAvailable ? (
                      <>
                        <span
                          className={`job-role-heading ${
                            (!this.state.collapseEmploymentType && employmentType.IsFilterBenchMarksAvailable) ? '' : 'greyStrep'
                          }`}
                          onClick={() => {
                            this.setState({
                              collapseEmploymentType: (!this.state.collapseEmploymentType && employmentType.IsFilterBenchMarksAvailable),
                            });
                          }}
                        >
                          Employment type {!employmentType.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>}
                        </span>
                        {this.state.collapseEmploymentType && employmentType.IsFilterBenchMarksAvailable ? (
                          <>
                            <span className='grid-role-heading'>
                              <ul>
                                {employmentType.HeatMapModel.map((item) => {
                                  return (
                                    <>
                                      {item.IsBenchMarkAvailable ? (
                                        <li>
                                          {item.StaffType} <span>{item.ClassRoom}</span>
                                        </li>
                                      ) : (<></>)}
                                    </>
                                  );
                                })}
                              </ul>
                            </span>
                            <span className='grid-data'>
                              {employmentType.HeatMapModel.map((item, index) => {
                                return (
                                  <>
                                    {item.IsBenchMarkAvailable ? (
                                      <ul>
                                        {questions.map((x) => {
                                          return (
                                          <HeatmapCell Decile={item.QuestionsAvg[`Q${x.QuestionId}Color`]} Value={item.QuestionsAvg[`Q${x.QuestionId}`]} Allow={this.state.IsActionAllowed} Category={categoryName} />
                                          );
                                        })}
                                      </ul>
                                    ) : (<></>)}
                                  </>
                                );
                              })}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </>) : <></>
                    }
                    <span>
                      {additionalFilters.map((items, index) => {
                        return (
                          <span>
                            {items.IsFilterBenchMarksAvailable ? (
                              <>
                                <span
                                  className={`job-role-heading ${
                                    (!this.state.collapseAdditionalFilters[items.FilterName] && items.IsFilterBenchMarksAvailable) ? '' : 'greyStrep'
                                  }`}
                                  onClick={this.handleFilterChange.bind(
                                    this,
                                    items.FilterName,
                                    items.IsFilterBenchMarksAvailable
                                  )}
                                >
                                  {items.FilterName} {!items.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>}
                                </span>
                                {this.state.collapseAdditionalFilters[items.FilterName] && items.IsFilterBenchMarksAvailable ? (
                                  <>
                                    <span className='grid-role-heading'>
                                      <ul>
                                        {items.HeatMapModel.map((filter, index) => {
                                          return (
                                            <>
                                              {filter.IsBenchMarkAvailable ? (
                                                <li>{filter.StaffType}</li>
                                              ) : (<></>)}
                                            </>
                                          );
                                        })}
                                      </ul>
                                    </span>
                                    <span className='grid-data'>
                                      {items.HeatMapModel.map((item, index) => {
                                        return (
                                          <>
                                            {item.IsBenchMarkAvailable ? (
                                              <ul>
                                                {questions.map((x) => {
                                              return (                                        
                                                  <HeatmapCell Decile={item.QuestionsAvg[`Q${x.QuestionId}Color`]} Value={item.QuestionsAvg[`Q${x.QuestionId}`]} Allow={this.state.IsActionAllowed} Category={categoryName} />
                                                  );
                                                })}
                                              </ul>
                                            ) : (<></>)}
                                          </>
                                        );
                                      })}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>) : <></>
                            }
                          </span>
                        );
                      })}
                    </span>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </SuperExtendedSubContainer>
      </CommentsMainWrapper>
    );
  }
}
export default connect(null, null)(withRouter(ManagementStandardHeatmap));
