export default class storageService {
  static setItem(keyName, keyValue) {
    localStorage.setItem(keyName, this.processSetData(keyValue));
  }
  static getItem = keyName => {
    return this.processGetData(localStorage.getItem(keyName));
  };
  static removeItem = key => localStorage.removeItem(key);
  static clear = () => localStorage.clear();

  static setToken = ({ token }) => localStorage.setItem('token', token);
  static getToken = keyName => {
    return localStorage.getItem(keyName);
  };
  static removeToken = () => localStorage.removeItem('token');
  static processGetData(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }
  static processSetData(value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    return value.toString();
  }
}
