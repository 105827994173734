import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { ActionPlanMainWrapper } from '../../components/common/ActionPlanStyle';
import { SubContainer } from '../../resources/styling/appStyle';
import { customcursor } from '../../resources/images/index';

const {
  bordergrey, pictonBlue, whiteColor, blackColor, barleyWhite, purple, mandy, outrageousOrange, teal
} = colors;

/*** Account ***/
export const ExtendedActionPlanMainWrapper = styled(ActionPlanMainWrapper)`
  > div {
	margin-top: 0 !important;
  }
`;
export const ExtendedSubContainer = styled(SubContainer)`
  
.uploadDirections{
  margin-bottom: 15px;
	display: block;
  a {
	color: ${pictonBlue};
	text-decoration: underline;
	font-weight: 900;
  }
}
  .search-data-container {
	width: 100%;
	float: left;
	padding-top: 20px;
	padding-bottom: 29px;
  }

  @media only screen and (max-width: 1000px){
	.search-data-container {     
	  padding-top: 40px;      
	}
  }
  .search-inner-container {    
	margin: auto;        
	margin-bottom: 20px;
	font-size: 14px;
	.suggested-actions-wrapper {
	  .suggested-action-list-main {
		float: left;
		margin-bottom: 5px;
	  }
	  .options {
		max-height: inherit;
	  }
	  .category-inner-wrapper {
		.selected-option {
		  font-size: 16px;
		  padding-left: 39px;
		}
	  }
	}
  }


.search-inner-sub-container{
   p.password-instructions{
	  font-size: 14px;
	  color: ${blackColor};      
	  font-weight: 500;
	  margin-bottom: 0;
   }
  
  float: left;
  width: 100%;    
  h2{
	padding: 0 40px 12px 0;    
	font-size: 24px;
	font-weight: 900;  
	color: ${blackColor}; 
	${media.xs`
		font-size: 20px;
		padding: 0;
		margin-top: 0;
	`}  
  }
  .add-btn {
	float: right;
	border-radius: 50%;
	border: 0;
	color: ${whiteColor};
	font-size: 25px;
	background: ${mandy};
	box-shadow: 2px 1px 7px rgba(0, 122, 122, 0.12);
	padding: 3px 10px;
	/* cursor: url(${customcursor}), auto; */
	cursor: pointer;
	margin: -7px -22px 0 0;
  }
  
  .form-body{    
	width: 100%;
	float: left;
	margin: 0;
	.form-holder{
	  width:"100%"; 
	  display:"flex";
	  .form-inner {
		margin-right: 12px;
	  }
	  ${media.xs`
		display: block !important;
	  `}

	  ${media.md`
		display: flex !important;
	  `}
	}
  }


  .title {
	font-family: Avenir;
	font-size: 26px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px;
	text-align: left;
	margin-botton: 20px;
  }
  .details {	
	font-size: 13px;
	border-bottom: #f2f2f2 1px solid;
	padding: 10px;
	position: relative;
	margin: auto;
  }

  .subTitle {
	font-family: Avenir;
	font-size: 16px;
	font-weight: 900;
	text-align: left;
	margin-bottom: 15px;
  }
  .link {
	font-style: normal;
	font-weight: 900;
	font-size: 14px;
	text-align: center;
	color: #53b5e0;
  }
  .orangeTitle {
	font-family: Avenir;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	text-align: left;
	color: #e3bda3;
  }
  .arrowBox {
	width: 39px;
	height: 116px;
	background: #f0fbff;
	border: 1px solid #53b5e0;    
	border-radius: 10px;
  }
  .subscription-panel {
	width: 100%;
	margin: auto;
	margin-bottom: 20px;
	font-size: 14px;
	overflow: hidden;
  }
  .bg {
	animation: pulse 1.2s ease infinite;
	background: ${mandy};
  }
  .bg,
  .button {
	width: 150px;
	height: 50px;
	border-radius: 48px;
	margin-top: 15px;
	margin-bottom: 40px;
  }
  bg {
	animation: pulse 1.2s ease infinite;
	background: ${mandy};
  }

  .button {
	font-size: 14px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 99;
	border: none;
	background: ${mandy};
	background-size: 18px;
	cursor: pointer;
	outline: none;
	color: white;
  }
  .owl-theme .owl-nav [class*='owl-'] {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 31px;
	border-radius: 50px;
	border: #53b5e0 1px solid;
	background: transparent;
	font-size: 16px;
	font-weight: 500;
	color: #53b5e0;
  }
  .owl-theme .owl-nav [class*='owl-']:hover {
	background: #53b5e0;
	color: #ffffff;
  }
  .owl-carousel .owl-nav .owl-next {
	position: absolute;
	right: -50px;
	top: 50%;
	transform: translateY(-50%);
  }

  .owl-carousel .owl-nav .owl-prev {
	position: absolute;
	left: -50px;
	top: 50%;
	transform: translateY(-50%);
  }

  @keyframes pulse {
	0% {
	  transform: scale(1, 1);
	}
	50% {
	  opacity: 0.3;
	}
	100% {
	  transform: scale(1.5);
	  opacity: 0;
	}
  }
  .upgrade-popup-inner {
	width: 75%;
	overflow-y: auto;
  }
}

.license-information{
	border: 1px solid #53B5E0;
	background: #C3E7F6;
	border-radius: 8px;
	padding: 20px;
	.details{
		border: none;
		font-size: 15px;
		font-weight: 500;
		padding: 0 0 8px 0;
		span{
			font-weight: 600;
		}
		.icon-check{
			margin-right: 10px;
			position: relative;
			top: 6px;
		}
	}
}

.btn-upgrade a {	
	background: ${outrageousOrange};
	color: #ff5939;
	text-align: center;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	margin-top: 15px;
	display: inline-block;
	padding: 8px 20px;	
	font-size: 14px;
	font-weight: 600;
	color: ${whiteColor};
	cursor: pointer;
	:hover {
	  background: ${teal};
	  
	}
  }

`;

/*** Account Form***/
export const RegisterInner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

/*** Account Staff CSV***/
export const AccountStaffWrapper = styled.div`
  .form-body {
	width: 100%;
	h2{
	  font-size: 24px;
	  margin-bottom: 30px;
	  ${media.xs`
		font-size: 20px;
		margin: 0 0 10px 0;
	  `}
	}    
	.form-body-inner {
	  width: 100%;
	  float: left;
	  //box-shadow: rgba(0, 122, 122, 0.12) 3px 3px 24px;
	  border: 1px solid ${bordergrey};
	  padding: 20px;
	  border-radius: 8px;
	  background: rgb(255, 255, 255);
	  .uploadDirections {
		display: block;
		margin: 10px 0 30px 0;
		line-height: 25px;
		a {
		  color: ${pictonBlue};
		  cursor: pointer;
		}
		a:hover {
		  text-decoration: underline;
		}
	  }

	  .upload-wrapper {
		width: 74.5%;
		.box {
		  input {
			height: 52px;
		  }
		}
	  }

	  @media only screen and (max-width: 700px){
		.upload-wrapper {
		  width: 100%;          
		}
	  }
	}
  }  

  @media only screen and (max-width: 1000px){
	.search-inner-sub-container {
	  padding: 0px;
	}
  }
`;


/*** Account Subscription ***/
export const AccountExtendedSubContainer = styled(SubContainer)`
  

.uploadDirections{
  margin-bottom: 15px;
	display: block;
  a {
	color: ${pictonBlue};
	text-decoration: underline;
	font-weight: 900;
  }
}
  .search-data-container {
	width: 100%;
	float: left;
	padding-top: 120px;
	padding-bottom: 29px;
  }
  .search-inner-container {
	width: 900px;
	margin: auto;
	margin-bottom: 20px;
	font-size: 14px;

	.suggested-actions-wrapper {
	  .suggested-action-list-main {
		float: left;
		margin-bottom: 5px;
	  }
	  .options {
		max-height: inherit;
	  }
	  .category-inner-wrapper {
		.selected-option {
		  font-size: 16px;
		  padding-left: 39px;
		}
	  }
	}
  }


.search-inner-sub-container{
  padding: 15px 0;
  float: left;
  width: 100%;  
  background: ${whiteColor};  
  margin-bottom: 15px;
  h2{
	padding: 0 40px 12px 40px;
	margin: 14px 0 14px 0;
	font-size: 24px;
	font-weight: 900;    
  }
  .add-btn {
	float: right;
	border-radius: 50%;
	border: 0;
	color: ${whiteColor};
	font-size: 25px;
	background: ${mandy};    
	padding: 3px 10px;    
	cursor: pointer;
  }
  
  .form-body{
	padding: 2px 40px;    
	width: 100%;
	float: left;
	margin: 0;
	.form-holder{
	  width:"100%"; 
	  display:"flex";
	  .form-inner {
		margin-right: 12px;
	  }
	}
  }
}

.subscription-card{	
	height: 580px;
	box-shadow: none;
	border-radius: 8px;
	border: 1px solid ${bordergrey};
	.subscription-title{
		text-align: center;
		color: ${whiteColor};
		min-height: 110px;		
		margin-bottom:20px;	
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.title{	
	font-size: 24px;	
	font-weight: 900;
	margin-bottom:5px;
}
.details{
	font-size: 14px;
	font-weight: 500;
	border-bottom: #f2f2f2 1px solid;
	padding: 10px 0;
	position: relative;

}
.subTitle{	
	font-size: 14px;
	font-weight: 500;		
}
.featureTitle{	
	font-size: 17px;
	font-weight: 600;	
	margin-bottom:15px;	
	padding: 0 15px;
}
.link{	
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	color: ${outrageousOrange};
	border: 1px solid ${outrageousOrange};
	border-radius: 8px;
	padding-top: 12px;
	padding-bottom: 12px;
	cursor: pointer;
	:hover{
		color: ${whiteColor};
		background: ${outrageousOrange};		
	}
}

.subscription-content{
	min-height: 270px;
	display: block;
	width: 100%;
	padding: 0 15px;
	.icon-check{
		fill: #007A7A;
		font-size: 24px;
		margin-right: 10px;		
		vertical-align: sub;
		float: left;
	}
}
.current-plan{	
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	color: ${whiteColor};
	background: ${mandy};
	border: 1px solid ${mandy};
	border-radius: 8px;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-top: 12px;
}
.upgrade-plan{	
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	color: ${whiteColor};
	background: ${teal};
	border: 1px solid ${teal};
	border-radius: 8px;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-top: 12px;
	width: 100%;
}
.compare-plan{	
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	color: ${whiteColor};
	background: ${outrageousOrange};
	border: 1px solid ${outrageousOrange};
	border-radius: 8px;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-top: 12px;
	width: 100%;
}
.orangeTitle{	
	font-size: 22px;	
	font-weight: bold;	
}
.arrowBox{
	width: 39px;
	height: 116px;
	background: #F0FBFF;
	border: 1px solid #53B5E0;
	border-radius: 10px;
}
  .subscription-panel {
	width: 100%;
	margin: auto;    
	margin-bottom: 20px;
	font-size: 14px;
	overflow: hidden;
}
.bg {
  animation: pulse 1.2s ease infinite;
  background: ${mandy};
}
.bg,
.button {
  width: 150px;
  height: 50px;
  border-radius: 48px;
  margin-top: 15px;
  margin-bottom: 40px;
}bg {
  animation: pulse 1.2s ease infinite;
  background: ${mandy};
}

.button {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background:  ${mandy};
  background-size: 18px;
  cursor: pointer;
  outline: none;
  color:white
}

.owl-theme .owl-nav [class*='owl-']{
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 31px;
	border-radius: 50px;
	border: #53B5E0 1px solid;    
	background: transparent;
	font-size: 16px;
	font-weight: 500;
	color: #53B5E0;
}
.owl-theme .owl-nav [class*='owl-']:hover{
	background: #53B5E0;
	color: #ffffff;
}

.owl-carousel .owl-nav .owl-next{
	position: absolute;
	right: -50px;
	top: 50%;
	transform: translateY(-50%);
}

.owl-carousel .owl-nav .owl-prev{
	position: absolute;
	left: -50px;
	top: 50%;
	transform: translateY(-50%);
}

@keyframes pulse {
  0% {
	transform: scale(1, 1);
  }
  50% {
	opacity: 0.3;
  }
  100% {
	transform: scale(1.5);
	opacity: 0;
  }
}
  .upgrade-popup-inner {
	width: 75%;
	overflow-y: auto;
}
`;

const AccountStyle = {
	ExtendedActionPlanMainWrapper,
	ExtendedSubContainer,
	RegisterInner,
	AccountStaffWrapper,	
	AccountExtendedSubContainer
}
export default AccountStyle;