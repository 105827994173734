import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Header } from '../../../components';
import { PageWrapper, CommentReplyWrapper } from '../../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import CommentsReplyCommon from './CommentsReplyCommon';
import { voiceConversationService } from '../../../services';
import { Sender } from '../../../utils/Constants';
import { ExtendedHeadTeacherReplyWrapper, WelcomeHeaderWrapper } from './SurveyVoiceStaffReplyStyle';

class StaffCommentReply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voiceConversation: [],
      isConversationArchive: false,
    };
  }
  componentDidMount() {
    if (!isEmpty(window.location.search)) {
      const token = window.location.search.split('?token=');
      voiceConversationService
        .getVoiceConversation(token[1])
        .then((response) => {
          if (response.success) {
            this.setState({
              voiceConversation: response.data.voiceConversation,
              surveyVoice: response.data.surveyVoice,
              isConversationArchive:
                response.data.surveyVoice === null ? true : false,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }
  render() {
    return (
      <PageWrapper>
        <WelcomeHeaderWrapper>
          <Header />
        </WelcomeHeaderWrapper>

        <ExtendedHeadTeacherReplyWrapper>
          <Grid container spacing={2}>
            
              <Grid item
                lg={2}
                sm={12}
                md={2}
                xs={12}
                className='mobile-wrapper'
              >
                <div className='reply-guide'>
                  All conversations within Welbee are anonymous.
                </div>
              </Grid>
              <Grid item
                lg={10}
                sm={12}
                md={9}
                xs={12}
                className='mobile-wrapper'
              >
                <CommentReplyWrapper>
                  <CommentsReplyCommon
                    surveyVoice={this.state.surveyVoice}
                    voiceConversation={this.state.voiceConversation}
                    sender={Sender.Support}
                    isConversationArchive={this.state.isConversationArchive}
                  />
                </CommentReplyWrapper>
              </Grid>
            
          </Grid>
        </ExtendedHeadTeacherReplyWrapper>
      </PageWrapper>
    );
  }
}

export default connect(null, null)(withRouter(StaffCommentReply));
