import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const MultiSelectDropdown = (props) => {
  return (
    <div className='multi-select-wrapper'>
     
        <div className='examples'>
          <Multiselect
            placeholder='Select Group'
            options={props.data}
            selectedValues={props.selectedValues}
            singleSelect={props.singleSelect}
            displayValue='key'
            ref={props.DDref}
            showCheckbox={true}
            onSelect={props.onSelect}
            onRemove={props.onRemove}
            closeOnSelect={false}
            style={{
              chips: {
                background: '#53B5E0',
                color: '#ffffff',
              },
            }}
          />
        </div>
     
    </div>
  );
};

export default MultiSelectDropdown;
