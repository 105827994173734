import UploadIcon from '@mui/icons-material/Upload';
import { InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import { customStyles } from '../../resources/styling/appStyle';
import { schoolStaffService, storageService } from '../../services';
import { navigationActions } from '../../store/headerStore';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames } from '../../utils';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import automationTestService from '../../services/api/AutomationTestService';

const AutomationTest = (props) => {
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [schoolType, setSchoolType] = useState(null);
  const [staffFileName, setstaffFileName] = useState(null);
  const [staffFile, setstaffFile] = useState(null);


  const { loaderActions } = props;


  const handleChange = (event, values) => {
    let f = event !== null ? event.target.files[0] : null;
    let fName = f !== null ? f.name : null;
    let fExtension = fName !== null ? fName.split('.').pop() : null;

    setFile(f);
    setFileName(fName);
    const model = new FormData();
    model.append('FormFile', f);
    model.append('Filename', fName);

  };

  const handleStaffFileChange = (event, values) => {
    let f = event !== null ? event.target.files[0] : null;
    let fName = f !== null ? f.name : null;
    let fExtension = fName !== null ? fName.split('.').pop() : null;

    setstaffFile(f);
    setstaffFileName(fName);
    const model = new FormData();
    model.append('StaffFormFile', f);
    model.append('staffFileName', fName);

  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        scriptFileUpload: null,
        staffFileUpload: null,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, formProps) => {
        const model = new FormData();
        model.append('FormFile', file);
        model.append('Filename', fileName);

        model.append('StaffFormFile', staffFile);
        model.append('StaffFile', staffFileName);

        model.append('WelbeeType', schoolType);
        console.log(model)
        loaderActions.dataLoading(true);
        automationTestService.saveAutomationTest(model)
          .then((response) => {
            loaderActions.dataLoading(false);
            const { getStaff, handleClose, handleExcelUpload } = props;
            if (response.success) {
              handleClose();
              toast.success(
                'Your Test Files successfully Uploaded.'
              );
            } else {
              setErrorMessage(
                'An error occurred while processing your request, please try again.'
              );
            }
          })
          .catch((error) => {
            loaderActions.dataLoading(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Box className='right-container' spacing={6} sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <AppModel
                onClose={props.handleClose}
                styles={customStyles}
                open={true}
                titleText={'Automation Script'}
                bodyText={
                  <div>

                    <br />
                    <FormControl
                      sx={{ m: 1, width: '100%' }}
                      variant='outlined'
                    >
                      <InputLabel
                        htmlFor='script-upload'
                        variant='outlined'
                      >
                        Upload Script
                      </InputLabel>
                      <OutlinedInput
                        inputProps={{ accept: '.xlsx' }}
                        id='script-upload'
                        name='scriptFileUpload'
                        type='file'

                        endAdornment={
                          <InputAdornment position='end'>
                            <UploadIcon
                              style={{
                                backgroundColor: '#007A7A',
                                color: 'white',
                              }}
                              edge='end'
                            ></UploadIcon>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          console.log(e.target.files[0])
                          setFieldValue('scriptFileUpload', e.target.files[0]);
                          setErrorMessage('');
                          setMessage('');
                          handleChange(e);
                        }}
                        label='Upload Excel'
                      />
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, width: '100%' }}
                      variant='outlined'
                    >
                      <InputLabel
                        htmlFor='staff-upload'
                        variant='outlined'
                      >
                        Upload Staff
                      </InputLabel>

                      <OutlinedInput
                        inputProps={{ accept: '.xlsx' }}
                        id='staff-upload'
                        name='staffFileUpload'
                        type='file'

                        endAdornment={
                          <InputAdornment position='end'>
                            <UploadIcon
                              style={{
                                backgroundColor: '#007A7A',
                                color: 'white',
                              }}
                              edge='end'
                            ></UploadIcon>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          console.log(e.target.files[0])
                          setFieldValue('staffFileUpload', e.target.files[0]);
                          setErrorMessage('');
                          setMessage('');
                          handleStaffFileChange(e);
                        }}
                        label='Upload Excel'
                      />
                    </FormControl>

                    <FormControl
                      sx={{ m: 1, width: '100%' }}
                      variant='outlined'
                    >
                      <InputLabel id="automation_test-label">School Type</InputLabel>
                      <Select
                        labelId="automation_test-label"
                        id="automation_test-select"
                        label="School Type"
                        value={schoolType}
                        onChange={e => {
                          setSchoolType(e.target.value)
                        }}
                      >
                        <MenuItem value={3}>Free</MenuItem>
                        <MenuItem value={4}>Pro</MenuItem>
                        <MenuItem value={5}>Premimum</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, width: '10%', float: 'right' }}
                      variant='outlined'
                    >
                      <Button
                        style={{
                          backgroundColor: '#45338C',
                        }}
                        onClick={handleSubmit}
                        variant='contained'
                      >
                        Upload
                      </Button>

                      {errorMessage ? (
                        <div className='fail-message'>
                          {' '}
                          <br />
                          {errorMessage}{' '}
                        </div>
                      ) : (
                        <div />
                      )}
                    </FormControl>

                  </div>
                }
              />
            </Grid>
          </Box>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(AutomationTest);
