import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
import { height } from '@mui/system';
const getHeatmapCellMd = (level, value) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      } style={{height: '32px'}}
    >
      {value >= 1 ? (
        <>         
          {level}
          <sup>
            {level === 1 ? 'st' : level === 2 ? 'nd' : level === 3 ? 'rd' : 'th'}
          </sup>          
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
const HighestLowestScoringQuestions = (props) => {
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          
          <h1 className='pres-title'>{props.title}</h1>
          <p className='table-title'>
            Table 2: Highest and Lowest Question Scores
          </p>

          <div className='question-table'>
            <Scrollbars
              className='scrollbar-view'

            >
              <div className='job-role-container'>
                <div className='heatmap-title'>
                  The Five Highest Scoring Questions
                </div>
                <div className='heatmap-title' style={{ width: '16.1%' }}>
                  School Decile
                </div>
              </div>
              {props.highScoreQuestions?.map((item) => {
                return (
                  <div className='job-role-container'>
                    <div className='width-20-grey'>{item.Category}</div>
                    <div className='width-60-grey'>
                      Q{item.Order}: {item.Text}
                    </div>
                    
                      {getHeatmapCellMd(item.Decile, item.Decile)}
                    
                  </div>
                );
              })}
              {props.lowScoreQuestions?.length > 0 ? (
                <>
                  {' '}
                  <div className='job-role-container'>
                    <div className='heatmap-title'>
                      The Five Lowest Scoring Questions
                    </div>
                    <div className='heatmap-title' style={{ width: '16.1%' }}>
                      School Decile
                    </div>
                  </div>
                  {props.lowScoreQuestions?.map((item) => {
                    return (
                      <div className='job-role-container'>
                        <div className='width-20-grey'>{item.Category}</div>
                        <div className='width-60-grey'>
                          Q{item.Order}: {item.Text}
                        </div>
                        
                        {getHeatmapCellMd(item.Decile, item.Decile)}
                        
                      </div>
                    );
                  })}{' '}
                </>
              ) : (
                <p className='table-title width-60-grey'>
                  There are no question scoring low.
                </p>
              )}
            </Scrollbars>
          </div>
          <span className='app-logo'><img src={props.welbeeslideslogo} alt=""/></span>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default HighestLowestScoringQuestions;
