import request from '../request';
import { suggestedActionsAPI } from './urls';

export const getSuggestedActions = () => {
  return request({
    url: suggestedActionsAPI.api,
    method: 'GET'
  })
    .then(response => response)
    .catch(error => error);
};

const suggestedActionsService = {
  getSuggestedActions
};

export default suggestedActionsService;
