import React from 'react';
import {
  DashboardWelcomePopupWrapper,
  AppButton,
  ExtendedFooterButtonsWrapper} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { AppModel } from '../../components';
import { dashboardwelcome } from '../../resources/images/index';

const { purple } = colors;

const WelbeeVoiceWelcomePopup = ({
  isOpen,
  onClose,
  onOk,
  children,
  buttonText,
}) => {
  return (
    <DashboardWelcomePopupWrapper className='model-main-wrapper'>
      <AppModel
        open={isOpen}
        onClose={onClose}
        titleText={
          <div className='dashboard-welcome-image'>
            <img src={dashboardwelcome} alt='' />
            <span>to Welbee voice</span>
          </div>
        }
        bodyText={
          <div className='dashboard-welcome-wrapper'>
            Thank you for using welbee voice to encourage all staff to engage in
            regular and open conversations with you. You can visit the User Guide by clicking the link in your dashboard menu.
          </div>
        }        
        footer={
          <ExtendedFooterButtonsWrapper>
            <AppButton color={purple} type='button' onClick={onOk}>
             Go to Dashboard
            </AppButton>
          </ExtendedFooterButtonsWrapper>
        }
      />
      {children}
    </DashboardWelcomePopupWrapper>
  );
};
export default WelbeeVoiceWelcomePopup;
