import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme/colors';
import {
  loginBackground,
  feecircle,
  RegisterBackground,
} from '../../resources/images';

const { pictonBlue, mandy, twilightBlue, teal, whiteColor } = colors;

export const LoginFormInner = styled.div`
  p {
    text-align: center !important;
  }
  .price-vat {
    font-size: 20px;
    position: absolute;
    right: -3px;
    top: 105px;
    font-style: normal;
  }
  .admin-fields {
    margin-top: 15px;
  }
  .fee-big-number {
    color: ${mandy};
    font-size: 52px;
    text-align: center;
    font-weight: 900;
    margin-top: -20px;
    span {
      background: url(${feecircle});
      background-repeat: no-repeat;
      background-size: 100%;
      width: 167px;
      height: 146px;
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
  width: 45%;
  margin: auto;
  padding: 14px;
  ${media.xs`
  width: 100%;
  padding:0;
  `}
  ${media.md`
  width: 58%;
  padding:0;
  `}
  ${media.lg`
  width: 58%;
  padding:0;
  `}
  h2 {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }
`;
export const WelcomeInnerWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
export const InputWrapper = styled.div`
  padding: 10px;
`;
export const FieldWrapper = styled.div`
  position: relative;
  padding-bottom: 8px;
  float: left;
  width: 100%;
  .inner-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    .forget-password,
    .login-btn-wrapper {
      padding: 0;
    }
  }
  button {
    ${media.xs`
  text-align: center;
  width: 100%;
  `}
  }
`;

export const MainWrapper = styled.div`
  .mobile-warning-wrapper {
    position: absolute;
    width: 100%;
    z-index: 10;
    display: none;
    font-size: 20px;
    ${media.xs`
    display: flex;
    height: 100%;
    background: ${twilightBlue};
    justify-content: center;
    align-items: center;
    color: ${mandy};
    flex-direction: column;

  `}
    span {
      display: block;
      font-size: 50px;
      color: ${mandy};
    }
  }
  button {
    margin-bottom: 0;
    float: right;
  }
  
  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  input::-webkit-input-placeholder {
    opacity: 0;
  }
  .forget-password {
    display: flex;
    justify-content: left;
    align-items: center;
    color: ${pictonBlue};
    text-decoration: underline;
    font-weight: 900;
    font-size: 16px;
    margin-top: 15px;
    margin: 0;
    a {
      cursor: pointer;
    }
  }
  .login-main-warpper {
    min-height: 100vh;
  }
  .login-left-panel {
    background: ${teal};
    background-image: url(${loginBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  float: left;
  width: 100%;
`;

export const ExtendedMainWrapper = styled(MainWrapper)`
  .welcome-right-panel {
    background: ${whiteColor};
    margin-left: 49%;
  }
  .login-left-panel {
    background: url(${RegisterBackground});
    background-size: 100%;
    background-position: center top;
    align-items: center;
    width: 50%;
    position: fixed;
    height: 100%;
    img {
      /* margin-top: 50%; */
    }
    .login-button {
      position: absolute;
      left: 45px;
      top: 40px;
      button {
        background: transparent;
        box-shadow: none;
        border: 1px solid ${whiteColor};
        padding: 11px 30px;
        font-weight: 400;
        :nth-child(1) {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const PasswordViewIcon = styled.button`
  position: absolute;
  width: 43px;
  height: 28px;
  right: 13px;
  top: 0.8rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  img {
    margin: 0;
  }
`;
