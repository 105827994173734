import request from '../request';
import { surveyResultAPI } from './urls';

export const get = surveyToken =>
{
  return request({
    url: surveyResultAPI.surveyReport(surveyToken),
    method: 'GET'
  })
    .then(response => response)
    .catch(response => response);
};

export const getStaffPresentationReport = surveyToken =>
{
  return request({
    url: surveyResultAPI.staffPresentationReport(surveyToken),
    method: 'GET'
  })
    .then(response => response)
    .catch(response => response);
};

export const GetSchoolSummaryReport = surveyToken =>
{
  return request({
    url: surveyResultAPI.GetSchoolSummaryReport(surveyToken),
    method: 'GET'
  })
    .then(response => response)
    .catch(response => response);
};

export const GetSchoolSummaryReportPdf = (surveyToken) =>
{
  return request({
    url: surveyResultAPI.GetSchoolSummaryReportPdf(surveyToken),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const checkSummaryReport = schoolId => {
    return request({
        url: surveyResultAPI.checkSummaryReport(schoolId),
        method: 'GET'
    })
        .then(response => response)
        .catch(response => response);
};
const surveyReportService = {
  get,
  getStaffPresentationReport,
  GetSchoolSummaryReport,
  GetSchoolSummaryReportPdf,
  checkSummaryReport,
};

export default surveyReportService;
