import React, { Component } from 'react';
import { arrowDown, fillarrownext, arrowUp } from '../../resources/images';
import DropDownOptions from './DropDownOptions';
import DropDownOptionsWithButton from './DropDownOptionsWithButton';
import { DropdownStyle } from '../../resources/styling/appStyle';
import { WebStorageNames, SortOrder } from '../../utils/';
import { storageService } from '../../services';
import { Children } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const sortingOptions = [
    { value: SortOrder.Newest, label: 'Latest First', haschild: false },
    { value: SortOrder.Oldest, label: 'Oldest First', haschild: false },
    { value: SortOrder.NewMsg, label: 'Un-opened Messages', haschild: false },
    { value: SortOrder.NotReplied, label: 'No Replies', haschild: false },
    { value: SortOrder.AdminMsg, label: 'School Replies', haschild: true, children: [{ value: SortOrder.MyMessages, label: 'My Replies', haschild: false }, { value: SortOrder.AllHeadTeachers, label: 'All Replies', haschild: false },] },
    { value: SortOrder.StaffMsg, label: 'Staff Replies', haschild: true, children: [{ value: SortOrder.MyMessages, label: 'To me', haschild: false }, { value: SortOrder.AllStaffReplies, label: 'To all', haschild: false },] },
];
class MultiLevelDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResults: false,
            accessLevel: 0,
        };
        this.dropDownRef = React.createRef();
        this.subMenuRef = React.createRef();
    }

    componentDidMount = () => {
        const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
        this.setState({ accessLevel: accessLevel });
    }
    handleChange = () => {
        if(!this.state.showResults)
            this.setState({
                showResults: true
            });
    };

    handleOptionChange(option) {
        this.setState({ showOption: option });
    }

    handleClick = e => {
        if (!this.dropDownRef.current.contains(e.target)) {
            this.setState({ showResults: true });
        }
    };
    handleClickOutside = (e) => {
        if (!this.dropDownRef.current.contains(e.target)) {
            this.setState({ showResults: false });
        } }
    componentWillMount() {
        // document.addEventListener('mousedown', this.handleClick, true);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        //document.removeEventListener('mousedown', this.handleClick, true);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    showChildren = (label) => {
        let menus = document.getElementsByName('submenu');
        menus.forEach(menu => menu.style.display = 'none');
        document.getElementById(label).style.display = 'inline';
    }
    hideMenu = (e, option, onChange) => {
        if (!option.haschild) {
            let menus = document.getElementsByName('submenu');
            menus.forEach(menu => menu.style.display = 'none');
        }
        onChange(option);
        this.setState({
            showResults: false
        });
    }
    render() {
        const {
            selectedOption,
            onChange,
            withButton = false
        } = this.props;
        const { accessLevel } = this.state;
        return (
            <DropdownStyle
                onClick={this.handleChange}
                selectedOption={selectedOption}
                ref={this.dropDownRef}
                className='category-inner-wrapper'
            >
                <div
                    className={
                        this.state.showResults
                            ? 'active-option selected-option'
                            : 'selected-option'
                    }
                    data-test-id='selectOption'
                >
                    <label>{selectedOption.label}</label>
                    {!this.state.showResults && <img src={arrowDown} alt='Arrow down' />}
                    {this.state.showResults && <img src={arrowUp} alt='Arrow up' />}
                </div>

                {this.state.showResults &&
                    (
                        <div className='options action-plan-options toplevel-dd' style={{ maxHeight: 300 }}>
                            {sortingOptions &&
                                sortingOptions.map(option => {
                                    return (
                                        option.value !== -1 && (
                                            <span key={option.value} onMouseOver={()=>option.haschild ? this.showChildren(option.label):null } onClick={(e) => option.haschild ? null : this.hideMenu(e,option, onChange)} style={{ position: 'relative' }}>
                                                {option.haschild ?
                                                    <>
                                                        <button ref={this.subMenuRef} onFocus={(event) => {
                                                                this.showChildren(option.label)
                                                        }}>
                                                            {option.label}<ArrowRightIcon fontSize="small" />
                                                        </button>
                                                        <div className={'options ' + (option.label === 'Staff Replies' ? 'multilevel-dd-1' :'multilevel-dd')}style={{ display: 'none' }} id={option.label} name='submenu'>
                                                            {option.children.map((option) => <span key={option.value} onClick={() => {
                                                                this.setState({
                                                                    showResults: false
                                                                });
                                                                onChange(option)
                                                            }}>
                                                                {option.label}
                                                            </span>)}
                                                        </div>
                                                    </>
                                                    :
                                                    option.label
                                                }

                                            </span>
                                        )
                                    );
                                })}

                        </div>
                    )}
            </DropdownStyle>
        );
    }
}

export default MultiLevelDropdown;
