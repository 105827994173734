import React from 'react';
import Chartkick, { LineChart } from 'react-chartkick';
import 'chart.js';

export default function CategoryProgressGraph(props) {
  Chartkick.options = {
    colors: [props.colorValue],
    min:null,
    library: { 
      plugins: {
        datalabels: {
          display: false,
          color: "black"
        }
      },
      scales: { yAxes: [{ gridLines: { display: true }, 
      ticks: { maxTicksLimit: 5, precision: 0.5 } }]}}   

  };

  return (
    <LineChart
      data={props.progressGraphData}
      max={5}
      curve={false}
      legend={true}
      download={true}
      discrete={true}
      points={5}
    />
  );
}
