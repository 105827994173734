import { actionTypes } from './actionTypes';

const { SCHOOLS_LIST_FLAG } = actionTypes;

// Super Admin Actions

const setSchoolsListFlag = isSchoolsWithLiveSurveys => dispatch => {
  dispatch({ type: SCHOOLS_LIST_FLAG, payload: isSchoolsWithLiveSurveys });
};

export const superAdminActions = {
  setSchoolsListFlag
};

// Super Admin Reducers

const INITIAL_STATE = {
  SetSchoolsListFlag: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SCHOOLS_LIST_FLAG:
      return { ...state, SetSchoolsListFlag: action.payload };
    default:
      return state;
  }
};
