import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import {
  AppInputField,
  AppModel,
  FloatingInputLabel,
} from '../../../components';
import {
  AppButton,
  AssitanceForm,
  ErrorWrapper,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInner,
  NewSurveyInnerMain,
  NewSurveyWrapper,
} from '../../../resources/styling';
import { WrapperMain } from '../../../resources/styling/formStyle';
import { colors } from '../../../resources/theme/colors';
import { matCycleService, storageService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { WebStorageNames } from '../../../utils/Constants';
import Box from '@mui/material/Box';
import { DropDownWrapper, InputWrapper } from './MatCycleStyle';

const { purple, outrageousOrange } = colors;

const EditCycle = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [cycleName, setCycleName] = useState('');
  const [matCycle, setMatCycle] = useState('');
  const [matCycleId, setMatCycleId] = useState();
  const [matGroupId] = useState(
    storageService.getItem(WebStorageNames.MatGroupId)
  );
  const [schools, setSchools] = useState([]);
  const [disabled, setDisable] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      initialValues();
      let options = [];
      props.schoolsList.map((data, i) => {
        options.push({
          key: data.Name,
          Id: data.Id,
          cat: i,
        });
      });
      setSchools(options);
    }
  }, [props.isOpen]);

  const initialValues = () => {
    setDisable(false);

    matCycleService
      .getById(props.matCycleId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setMatCycle(response.data);
          setMatCycleId(response.data.Id);
          setCycleName(response.data.Name);
          setStartDate(response.data.StartDate);
        }
      })
      .catch(() => {
        toast.error('Error loading mat cycle.');
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Id: matCycleId !== null ? matCycleId : 0,
        MatGroupId: matGroupId !== null ? matGroupId : '',
        Name: cycleName !== null ? cycleName : '',
        StartDate: startDate !== null ? startDate : '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Name: Yup.string().required('Name is required.').nullable(),
        Schools: Yup.array().min(1, 'Schools are required.'),
        StartDate: Yup.string().required('Start date is required.').nullable(),
      })}
      onSubmit={(values, formProps) => {
        setDisable(true);
        let model = {
          Id: values.Id,
          Name: values.Name,
          StartDate: values.StartDate,
          MatGroupId: values.MatGroupId,
        };
        matCycleService
          .saveCycle(model)
          .then((response) => {
            if (response.success) {
              formProps.resetForm();
              toast.success('record has been updated successfully.');
              props.handleSubmit();
            } else {
              setDisable(false);
              toast.error('record not updated. Please try again later.');
            }
          })
          .catch(() => {
            setDisable(false);
          });
      }}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div>
            <AppModel
              open={props.isOpen}
              onClose={props.onClose}
              titleText='Edit Cycle'
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        mt='15px'
                        className='form-column-wrapper'
                      >
                        <InputWrapper>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Name'
                              autoComplete='nope'
                              name='Name'
                              value={values.Name}
                              onChange={(e) => {
                                setFieldValue('Name', e.target.value);
                                setCycleName(e.target.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.Name && errors.Name}
                            </ErrorWrapper>
                          </WrapperMain>

                          <WrapperMain>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label='Start Date'
                                inputFormat='dd-MM-yyyy'
                                value={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                renderInput={(params) => (
                                  <TextField sx={{ width: 1 }} {...params} />
                                )}
                              />
                            </LocalizationProvider>
                            <ErrorWrapper>
                              {touched.StartDate && errors.StartDate}
                            </ErrorWrapper>
                          </WrapperMain>

                          <AppButton
                            disabled={disabled}
                            color={outrageousOrange}
                            type='submit'
                            style={{
                              cursor: disabled ? 'not-allowed' : 'pointer',
                            }}
                            onClick={handleSubmit}
                          >
                            {disabled ? 'Saving...' : 'Save'}
                          </AppButton>
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={<FooterButtonsWrapper></FooterButtonsWrapper>}
            />
          </div>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(EditCycle);
