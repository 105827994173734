import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Header } from '../../components';
import { PageWrapper, HeadTeacherReplyWrapper, CommentReplyWrapper } from '../../resources/styling/appStyle';
import CommentsReplyCommon from './CommentsReplyCommon';
import { surveyCommentsService } from '../../services';
import { Sender } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import { WelcomeHeaderWrapper } from './SurveyCommentStyle';

class StaffCommentReply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: [],
      isSurveyClosed: false,
    };
  }
  componentDidMount() {
    if (!isEmpty(window.location.search)) {
      const token = window.location.search.split('?id=');
      surveyCommentsService
        .getStaffCommentWithReplies(token[1])
        .then((response) => {
          if (response.success) {
            this.setState({ comment: response.data, isSurveyClosed: response.data.IsSurveyClosed });
          }
        })
        .catch((error) => console.log(error));
    }
  }
  render() {
    return (
      <PageWrapper>
        <WelcomeHeaderWrapper>
          <Header />
        </WelcomeHeaderWrapper>

        <HeadTeacherReplyWrapper>
          <Grid container spacing={2}>
            
            <Grid item lg={2} sm={12} md={2} xs={12}  className='mobile-wrapper'>
              <div className='reply-guide'>
                All conversations within Welbee are anonymous.
              </div>
            </Grid>
            <Grid item lg={10} sm={12} md={9} xs={12} className='mobile-wrapper'>
              <CommentReplyWrapper>
                <CommentsReplyCommon
                  comment={this.state.comment}
                  sender={Sender.Support}
                  isSurveyClosed={this.state.isSurveyClosed}
                />
              </CommentReplyWrapper>
            </Grid>
            
          </Grid>
        </HeadTeacherReplyWrapper>
      </PageWrapper>
    );
  }
}

export default connect(null, null)(withRouter(StaffCommentReply));
