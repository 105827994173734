import { filter, find, map, reduce } from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  surveyService,
  storageService,
  additionalSurveyQuestionService,
  schoolSubscriptionService,
} from '../../services';
import { Dropdown, Header } from '../../components';
import {
  AppButton,
  CircleButton,
  CircleButtonWrapper,
  NextButton,
  PreviousButton,
  ProgressBarWrapper,
  QuestionNavigation,
  QuestionNavigationWrapper,
  QuestionProgress,
  QuestionTitle,
  QuestionWrapper,
  SelectWrapper,
  TextareaWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens, WebStorageNames } from '../../utils/Constants';
import { Actions, Categories, LogEvent } from '../../utils/GoogleAnalytics';
import InputTextarea from './InputTextarea';
import {
  ExtendedSelectWrapper,
  SlideWrapper,
  MobileQuestionHelpGuide,
  QuestionHelpGuide,
  SurveySubmitWrapper,
  SurveySubmitForm,
  QuestionInnerWrapper,
} from './SurveyStyle';

const { teal } = colors;

const harrastmentTypeList = [
  { value: 'students', label: 'Students' },
  { value: 'parents', label: 'Parents' },
  { value: 'colleagues', label: 'Colleagues' },
  { value: 'leaders', label: 'Leaders' },
  { value: 'other', label: 'Other' },
  { value: 'prefer not to say', label: 'Prefer not to say' },
];

const SlideNo = styled.div`
  width: 100%;
  text-align: right;
`;

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      questions: [],
      answers: [],
      currentSlide: 0,
      selectedAnswer: null,
      staffOpinionMaxlength: 500,
      biggestImpactMaxlength: 500,
      nextButtonDisabled: true,
      previousButtonDisabled: true,
      surveyPercentage: 0,
      timeWorkingErrorMessage: '',
      isAdditionalStaffInfo: false,
      IsJobRoleAvailable: false,
      IsEmploymentTypeAvailable: false,
      IsEmploymentStartDateAvailable: false,
      additionaQuestions: [],
      questionCount: 0,
      harrassmentOpenComment: '',
      bullyOpenComment: '',
      showHarrassmentOpenComment: false,
      showBullyOpenComment: false,
    };

    this.EXPLANATION_TEXT = `Please answer each question carefully by selecting one of the options or typing into the provided field.`;

    this.SLIDER_SETTINGS = {
      className: 'center',
      centerMode: true,
      infinite: false,
      centerPadding: '0',
      slidesToShow: 1,
      variableWidth: true,
      speed: 500,
      swipe: false,
      afterChange: this.handleAfterChange,
    };
  }

  handleAfterChange = (currentSlide) => {
    this.handleNavigationButtons({ slideIndex: currentSlide });
    this.setState({ currentSlide: currentSlide });
  };

  handlePreviousClick = () => {
    let { currentSlide, slides } = this.state;
    let slideIndex = currentSlide - 1;
    if (slideIndex === 4) {
      if (
        slides[slideIndex].question.answer.options[3].Selected ||
        slides[slideIndex].question.answer.options[4].Selected
      ) {
        this.setState({ showHarrassmentOpenComment: true });
      } else {
        this.setState({ harrassmentOpenComment: '' });
        this.setState({ showHarrassmentOpenComment: false });
      }
    }
    if (slideIndex === 20) {
      if (
        slides[slideIndex].question.answer.options[3].Selected ||
        slides[slideIndex].question.answer.options[4].Selected
      ) {
        this.setState({ showBullyOpenComment: true });
      } else {
        this.setState({ bullyOpenComment: '' });
        this.setState({ showBullyOpenComment: false });
      }
    }
    this.setState({
      currentSlide: currentSlide - 1,
    });
  };

  componentDidMount() {
    this.checkAddtionalStaffInfo();
    var surveyToken = getQueryStringVal(QueryStringTokens.token);

    schoolSubscriptionService
      .getSchoolSubscriptionsByToken(surveyToken)
      .then((response) => {
        if (response.success) {
          storageService.setItem(
            WebStorageNames.SubscriptionId,
            response.data.SubscriptionId
          );
        }
      });

    additionalSurveyQuestionService
      .checkIsSurveyEditable(surveyToken)
      .then((surveyEditResponse) => {
        if (surveyEditResponse.success)
          storageService.setItem(
            WebStorageNames.IsSurveyEditable,
            surveyEditResponse.data.isSurveyEditable
          );
        additionalSurveyQuestionService
          .getAdditionalQuestions(surveyToken)
          .then((results) => {
            console.log(results, 'results');
            storageService.setItem(
              WebStorageNames.AdditionalQuestions,
              results
            );
            this.getSurveyQuestions().then(() => {
              this.AreDropDownOptionsValid();
              this.handleShowLastSelectedQuestion();
              this.handleAnsweredQuestionsPercentage();
            });
          });
      });
    amplitudeLog('PageView', 'Survey', '');
  }

  componentWillUnmount() {
    clearTimeout(this.handleEnabledSecondScreen);
  }

  checkAddtionalStaffInfo = () => {
    var surveyToken = window.location.search;
    surveyService.checkAddtionalStaffInfo(surveyToken).then((response) => {
      console.log(response);
      this.setState({
        isAdditionalStaffInfo: response.data.IsAdditionalStaffInfo,
        IsJobRoleAvailable: response.data.IsJobRoleAvailable,
        IsEmploymentTypeAvailable: response.data.IsEmploymentTypeAvailable,
        IsEmploymentStartDateAvailable:
          response.data.IsEmploymentStartDateAvailable,
      });
    });
  };

  getSurveyQuestions = () => {
    const { action, history } = this.props;
    action.dataLoading(true);
    var queryString = window.location.search;

    return surveyService
      .getQuestions()
      .then((slides) => {
        const { IsSurveyClosed } = slides;
        if (IsSurveyClosed)
          history.push(routesObj.SurveyComplete.path + queryString);

        console.log('Slides', slides);
        this.LAST_SLIDE = slides.length - 1;
        this.setState({
          slides,
          nextButtonDisabled: false,
          questionCount:
            filter(slides, (slide) => slide.question.answer.type === 'dropdown')
              .length > 0
              ? slides.length - 2
              : slides.length - 1,
        });
        action.dataLoading(false);
      })
      .catch((response) => {
        action.dataLoading(false);
        return response;
      });
  };

  handleShowLastSelectedQuestion = () => {
    const { slides } = this.state;
    let totalAnswered = 0;
    let lastAnsweredIndex = reduce(
      slides,
      (result, value, index) => {
        if (value.question.isAnswered) {
          totalAnswered++;
          return value;
        }
        return totalAnswered;
      },
      {}
    );

    this.setState({
      currentSlide: lastAnsweredIndex,
    });
  };

  handleNavigationButtons = ({ slideIndex }) => {
    const { slides } = this.state;

    if (slideIndex >= slides.length - 3) {
      this.setState({ nextButtonDisabled: false });
      return;
    }
    const currentQuestionsAnswer = find(
      slides[slideIndex].question.answer.options,
      (option) => option.Selected
    );
    this.setState({ nextButtonDisabled: !currentQuestionsAnswer });
  };

  handleCommentChange = (event) => {
    const { value, id } = event.target;
    let { slides } = this.state;
    slides = map(slides, (slide) => {
      const { type } = slide.question.answer;
      if (type === 'comment' && `${slide.question.Id}` === id)
        slide.question.answer.comment = value;
      return slide;
    });
    this.setState({
      slides,
    });
    this.saveSurveyToLocalStorage({ survey: slides });
    return;
  };

  changeMode = () => {
    const MOBILE = 786;
    const width = window.innerWidth;
    if (width <= MOBILE) {
      return false;
    }
    return null;
  };
  getCardWidth = () => {
    const MOBILE = 500;
    const TABLET = 1024;
    const DESKTOP = 1366;
    const LARGE_DESKTOP = 1920;
    const width = window.innerWidth;

    if (width <= MOBILE) {
      return 320;
    }
    if (width <= TABLET) {
      return 600;
    }
    if (width <= DESKTOP) {
      return 880;
    }
    if (width <= LARGE_DESKTOP) {
      return 1400;
    }
    return 500;
  };

  saveSurveyToLocalStorage = ({ survey }) => {
    storageService.setItem(WebStorageNames.SurveyInProgress, survey);
  };

  handleRoleChange = (selectedOption) => {
    this.mapSelectedOption('jobRoleOptions', selectedOption);
  };

  handleEmployeeStatusChange = (selectedOption) => {
    this.mapSelectedOption('employmentStatusOptions', selectedOption);
  };

  handleTimeWorkingInEducationChange = (selectedOption) => {
    this.mapSelectedOption('timeWorkingInEducationOptions', selectedOption);
  };

  handleTimeWorkingInSchoolChange = (selectedOption) => {
    this.mapSelectedOption('timeWorkingInSchoolOptions', selectedOption);
  };

  mapSelectedOption = (optionName, selectedOption) => {
    let { slides } = this.state;
    slides = map(slides, (slide, index) => {
      if (slide.question.answer.type === 'dropdown') {
        const { question } = slide;

        let questionOptions = question.answer.options[optionName];
        // set selected option to true
        questionOptions.options = map(questionOptions.options, (option) => {
          option.Selected = option.value === selectedOption.value;
          return option;
        });

        if (selectedOption.value > 0) questionOptions.isAnswered = true;
        else {
          questionOptions.isAnswered = false;
        }
        // check if if the selected values are already preasent and do the changes to the options accordingly
        if (this.state.IsJobRoleAvailable === true) {
          question.answer.options.jobRoleOptions.isAnswered = true;
        }
        if (this.state.IsEmploymentTypeAvailable === true) {
          question.answer.options.employmentStatusOptions.isAnswered = true;
        }
        if (this.state.IsEmploymentStartDateAvailable === true) {
          question.answer.options.timeWorkingInSchoolOptions.isAnswered = true;
        }

        // check if all options are chosen then mark question as answered
        if (
          question.answer.options.jobRoleOptions.isAnswered === true &&
          question.answer.options.employmentStatusOptions.isAnswered === true &&
          question.answer.options.timeWorkingInSchoolOptions.isAnswered === true
        ) {
          slide.question.isAnswered = true;
        } else {
          slide.question.isAnswered = false;
        }
      }
      return slide;
    });

    this.AreDropDownOptionsValid();

    this.saveSurveyToLocalStorage({ survey: slides });
  };

  AreDropDownOptionsValid = () => {
    return true;
  };

  handleOptionClick = ({ option, questionId, slideIndex }) => {
    const { Id } = option;
    let { slides } = this.state;
    slides = map(slides, (slide) => {
      const { question } = slide;
      if (question.Id === Number(questionId)) {
        question.answer.options = map(question.answer.options, (option) => {
          option.Selected = option.Id === Id;
          return option;
        });
        question.isAnswered = true;
      }
      slide.question = question;
      return slide;
    });
    this.handleNavigationButtons({ slideIndex: slideIndex });
    this.saveSurveyToLocalStorage({ survey: slides });
    this.setState({ slides });
    this.handleAnsweredQuestionsPercentage();

    if (slideIndex === 4) {
      if (
        (slides[slideIndex].question.answer.options[3].Selected ||
          slides[slideIndex].question.answer.options[4].Selected) &&
        !this.state.showHarrassmentOpenComment
      ) {
        this.setState({ showHarrassmentOpenComment: true });
        return;
      }
    }
    if (slideIndex === 20) {
      if (
        (slides[slideIndex].question.answer.options[3].Selected ||
          slides[slideIndex].question.answer.options[4].Selected) &&
        !this.state.showBullyOpenComment
      ) {
        this.setState({ showBullyOpenComment: true });
        return;
      }
    }

    if (
      (slideIndex === 4 || slideIndex === 20) &&
      (slides[slideIndex].question.answer.options[0].Selected ||
        slides[slideIndex].question.answer.options[1].Selected ||
        slides[slideIndex].question.answer.options[2].Selected)
    ) {
      if (slideIndex === 4) {
        this.setState({ harrassmentOpenComment: '' });
      }
      if (slideIndex === 20) {
        this.setState({ bullyOpenComment: '' });
      }
      this.handleNextClick({ slideIndex });
    } else if (slideIndex !== 4 && slideIndex !== 20) {
      this.handleNextClick({ slideIndex });
    }
  };

  handleSurveyComment = ({ name, comments }) => {
    let surveyComment = find(
      comments,
      (comment) => comment.question.answer.name === name
    );

    if (surveyComment) {
      const comment = surveyComment.question.answer.comment;
      return comment ? comment.trim() : '';
    }

    return '';
  };

  handleCommentValue = ({ comments }) => {
    const surveyComments = map(comments, (comment) => {
      if (comment.question.answer.name === 'staffOpinion') {
        comment.question.answer.questiontype = 1;
      } else if (comment.question.answer.name === 'biggestImpact') {
        comment.question.answer.questiontype = 2;
      } else {
        comment.question.answer.questiontype = 3;
      }

      const commentsObj = {
        CommentQuestionType: comment.question.answer.questiontype,
        CommentText: comment.question.answer.comment
          ? comment.question.answer.comment.trim()
          : '',
        QuestionId: comment.question.Id,
      };

      return commentsObj;
    });

    return surveyComments;
  };

  handleSurveySubmit = () => {
    LogEvent(Categories.SurveySubmit, Actions.SurveySubmitButtonClicked);
    amplitudeLog('Survey', 'SubmitAnswers', '');
    amplitudeLog(
      'SurveySubmission',
      this.props.SchoolName + ' SurveySubmission',
      ''
    );

    const { slides } = this.state;
    const comments = filter(
      slides,
      (slide) => slide.question.answer.type === 'comment'
    );
    this.setState({ submitButtonDisabled: true });

    let staffInfoQuestion =
      this.state.isAdditionalStaffInfo === true
        ? null
        : find(slides, (slide) => slide.question.answer.type === 'dropdown');

    const jobRole =
      this.state.isAdditionalStaffInfo === true
        ? null
        : find(
            staffInfoQuestion.question.answer.options.jobRoleOptions.options,
            (option) => option.Selected
          );

    const employmentStatus =
      this.state.isAdditionalStaffInfo === true
        ? null
        : find(
            staffInfoQuestion.question.answer.options.employmentStatusOptions
              .options,
            (option) => option.Selected
          );

    const timeWorkingInSchool =
      this.state.isAdditionalStaffInfo === true
        ? null
        : find(
            staffInfoQuestion.question.answer.options.timeWorkingInSchoolOptions
              .options,
            (option) => option.Selected
          );

    const questionSlides = filter(
      slides,
      (slide) => slide.question.answer.type === 'radio'
    );

    const surveyAnswers = map(questionSlides, (slide) => {
      const { options, questionId } = slide.question.answer;
      const selectedAnswer = find(options, (option) => option.Selected);

      return {
        AnswerType: selectedAnswer.Id,
        QuestionId: questionId,
      };
    });

    var surveyToken = getQueryStringVal(QueryStringTokens.token);

    const survey = {
      JobRole: jobRole == null ? 0 : jobRole.value,
      EmploymentStatus: employmentStatus == null ? 0 : employmentStatus.value,
      TimeWorkingInSchool:
        timeWorkingInSchool == null ? 0 : timeWorkingInSchool.value,
      StaffOpinion:
        comments.length > 0
          ? this.handleSurveyComment({ name: 'staffOpinion', comments })
          : '',
      BiggestImpact:
        comments.length > 0
          ? this.handleSurveyComment({ name: 'biggestImpact', comments })
          : '',
      HarrassmentImpact: this.state.showHarrassmentOpenComment
        ? this.state.harrassmentOpenComment
        : '',
      BullyImpact: this.state.showBullyOpenComment
        ? this.state.bullyOpenComment
        : '',
      SurveyToken: surveyToken,
      SurveyAnswers: surveyAnswers,
      SurveyComments:
        comments.length > 0 ? this.handleCommentValue({ comments }) : comments,
    };

    this.props.action.dataLoading(true);

    surveyService
      .postSurvey({ survey })
      .then((response) => {
        this.props.history.push(
          routesObj.SurveyComplete.path + window.location.search
        );
        storageService.clear(WebStorageNames.SurveyInProgress);
        this.props.action.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.action.dataLoading(false);
      });
  };

  getSelectedOption = (options) => {
    return find(options, (option) => option.Selected);
  };

  getQuestionsAnswer = ({ slide, slideIndex }) => {
    const { type, options, questionId, name, comment } = slide.question.answer;

    if (type === 'dropdown') {
      const {
        jobRoleOptions,
        employmentStatusOptions,
        timeWorkingInSchoolOptions,
      } = options;

      return (
        <ExtendedSelectWrapper>
          <div className='error-message '>
            {this.state.timeWorkingErrorMessage}
          </div>
          {this.state.IsJobRoleAvailable === false ? (
            <Dropdown
              selectedOption={this.getSelectedOption(jobRoleOptions.options)}
              options={jobRoleOptions.options}
              onChange={this.handleRoleChange}
              id='dd-jobrole'
            />
          ) : (
            ''
          )}
          {this.state.IsEmploymentTypeAvailable === false ? (
            <Dropdown
              selectedOption={this.getSelectedOption(
                employmentStatusOptions.options
              )}
              options={employmentStatusOptions.options}
              onChange={this.handleEmployeeStatusChange}
              id='dd-employmentstatus'
            />
          ) : (
            ''
          )}
          {this.state.IsEmploymentStartDateAvailable === false ? (
            <Dropdown
              selectedOption={this.getSelectedOption(
                timeWorkingInSchoolOptions.options
              )}
              options={timeWorkingInSchoolOptions.options}
              onChange={this.handleTimeWorkingInSchoolChange}
              id='dd-timeinschool'
            />
          ) : (
            ''
          )}
        </ExtendedSelectWrapper>
      );
    }
    if (type === 'radio') {
      return (
        <CircleButtonWrapper>
          {map(options, (option, index) => (
            <CircleButton
              data-test-id={`question-${slideIndex}-option-${index + 1}`}
              color={option.Color}
              selected={option.Selected}
              id={option.Id}
              onClick={() =>
                this.handleOptionClick({ option, questionId, slideIndex })
              }
              key={option.Id}
            >
              <span>{option.Answer}</span>
            </CircleButton>
          ))}
        </CircleButtonWrapper>
      );
    }
    if (type === 'comment') {
      return (
        <>
          <QuestionInnerWrapper>
            <QuestionTitle>
              <p className='noteText'>
                {slide.question.NoteText !== 'null'
                  ? slide.question.NoteText
                  : ''}
              </p>
            </QuestionTitle>
          </QuestionInnerWrapper>
          <TextareaWrapper>
            <InputTextarea
              id={slide.question.Id}
              data-id-textarea={`${type}${slideIndex}`}
              placeholder='Enter your comments here and select next to continue'
              value={comment}
              onChange={this.handleCommentChange}
            />
          </TextareaWrapper>
        </>
      );
    }

    if (type === 'none') {
      return (
        <SurveySubmitWrapper>
          <SurveySubmitForm>
            <h1>{slide.question.Title}</h1>
            <p>{slide.question.Description}</p>
            <AppButton
              data-test-id='surveySubmitbtn'
              color={teal}
              onClick={this.handleSurveySubmit}
              disabled={this.state.submitButtonDisabled}
              id='btn-surveysubmit'
            >
              Submit Answers
            </AppButton>
          </SurveySubmitForm>
        </SurveySubmitWrapper>
      );
    }
    return '';
  };

  handleOptionCommentChange = (event) => {
    const { value, id } = event.target;
    if (id === '4') {
      this.setState({ harrassmentOpenComment: value });
    }
    if (id === '20') {
      this.setState({ bullyOpenComment: value });
    }
    return;
  };

  getOptionCommentTextBox = (idx) => {
    return (
      <>
        <TextareaWrapper>
          {idx === 4
            ? 'Please provide additional confidential information to better help us understand whether this is as a result of the behaviour of students, parents, colleagues, leaders or others. You can also select prefer not to say and make multiple choices. Thank you.'
            : 'Please provide additional confidential information to better help us understand whether this is as a result of the behaviour of students, parents, colleagues, leaders or others. You can also select prefer not to say and make multiple choices. Thank you.'}
          <div
            style={{
              marginTop: '20px',
              textAlign: 'center',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {harrastmentTypeList.map((x, i) => (
              <label
                key={i}
                style={{
                  paddingRight: '20px',
                  paddingLeft: '20px',
                  cursor: 'pointer',
                }}
              >
                <input
                  id={idx}
                  type='checkbox'
                  name={x.value}
                  value={x.value}
                  className={x.value}
                  onChange={this.handleHarrChkChange}
                />
                {x.label}
              </label>
            ))}
          </div>
          <div
            id='divErrorHarr'
            style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500' }}
          >
            <span
              id='lblErrorHarr'
              style={{
                paddingRight: '20px',
                paddingLeft: '20px',
                color: 'red',
                fontSize: '13px',
              }}
            >
              Note : Please select at least one of the above options
            </span>
          </div>
          <InputTextarea
            id={idx}
            placeholder='Enter your comments here and select next to continue'
            value={
              idx === 4
                ? this.state.harrassmentOpenComment
                : this.state.bullyOpenComment
            }
            onChange={this.handleOptionCommentChange}
            style={{ display: 'none' }}
          />
        </TextareaWrapper>
      </>
    );
  };

  handleNextClick = ({ slideIndex }) => {
    let { slides } = this.state;
    if (
      slideIndex === 4 &&
      this.state.harrassmentOpenComment === '' &&
      (slides[slideIndex].question.answer.options[3].Selected ||
        slides[slideIndex].question.answer.options[4].Selected)
    ) {
      return;
    }
    if (
      slideIndex === 20 &&
      this.state.bullyOpenComment === '' &&
      (slides[slideIndex].question.answer.options[3].Selected ||
        slides[slideIndex].question.answer.options[4].Selected)
    ) {
      return;
    }

    slides = map(slides, (slide, index) => {
      const { type } = slide.question.answer;
      if (type === 'comment' && index === slideIndex + 1) {
        slide.question.isAnswered = true;
      }
      return slide;
    });

    this.setState({ slides }, () => {
      this.handleAnsweredQuestionsPercentage();
      this.saveSurveyToLocalStorage({ survey: slides });
    });

    setTimeout(() => {
      this.setState({
        currentSlide: slideIndex + 1,
      });
    }, 1000);
  };

  handleHarrChkChange = (event) => {
    const { value, id, checked, name } = event.target;
    if (id === '4') {
      if (checked && name === 'prefer not to say') {
        document.getElementsByClassName('students')[0].checked = false;
        document.getElementsByClassName('students')[0].disabled = true;

        document.getElementsByClassName('parents')[0].checked = false;
        document.getElementsByClassName('parents')[0].disabled = true;

        document.getElementsByClassName('colleagues')[0].checked = false;
        document.getElementsByClassName('colleagues')[0].disabled = true;

        document.getElementsByClassName('leaders')[0].checked = false;
        document.getElementsByClassName('leaders')[0].disabled = true;

        document.getElementsByClassName('other')[0].checked = false;
        document.getElementsByClassName('other')[0].disabled = true;
      }
      if (!checked && name === 'prefer not to say') {
        document.getElementsByClassName('students')[0].disabled = false;
        document.getElementsByClassName('parents')[0].disabled = false;
        document.getElementsByClassName('colleagues')[0].disabled = false;
        document.getElementsByClassName('leaders')[0].disabled = false;
        document.getElementsByClassName('other')[0].disabled = false;
      }
      if (name === 'prefer not to say') {
        if (checked) {
          var curentharrprefnottosayaddedval = '';
          curentharrprefnottosayaddedval =
            curentharrprefnottosayaddedval + ',' + value;
          curentharrprefnottosayaddedval =
            curentharrprefnottosayaddedval.replace(/(^,)|(,$)/g, '');
          this.setState({
            harrassmentOpenComment: curentharrprefnottosayaddedval,
          });
        } else {
          var curentharrprefnottosayremovedval =
            this.state.harrassmentOpenComment;
          var valuetoprefnottosayremove = 'prefer not to say';
          curentharrprefnottosayremovedval =
            curentharrprefnottosayremovedval.replace(
              valuetoprefnottosayremove,
              ''
            );
          curentharrprefnottosayremovedval =
            curentharrprefnottosayremovedval.replace(',,', ',');
          curentharrprefnottosayremovedval =
            curentharrprefnottosayremovedval.replace(/(^,)|(,$)/g, '');
          this.setState({
            harrassmentOpenComment: curentharrprefnottosayremovedval,
          });
        }
      } else {
        if (checked) {
          var curentharraddedval = this.state.harrassmentOpenComment;
          curentharraddedval = curentharraddedval + ',' + value;
          curentharraddedval = curentharraddedval.replace(/(^,)|(,$)/g, '');
          this.setState({ harrassmentOpenComment: curentharraddedval });
        } else {
          var curentharrremovedval = this.state.harrassmentOpenComment;
          var valuetoremove = value;
          curentharrremovedval = curentharrremovedval.replace(
            valuetoremove,
            ''
          );
          curentharrremovedval = curentharrremovedval.replace(',,', ',');
          curentharrremovedval = curentharrremovedval.replace(/(^,)|(,$)/g, '');
          this.setState({ harrassmentOpenComment: curentharrremovedval });
        }
      }
    }

    if (id === '20') {
      if (checked && name === 'prefer not to say') {
        if (document.getElementsByClassName('students')[1]) {
          document.getElementsByClassName('students')[1].checked = false;
          document.getElementsByClassName('students')[1].disabled = true;

          document.getElementsByClassName('parents')[1].checked = false;
          document.getElementsByClassName('parents')[1].disabled = true;

          document.getElementsByClassName('colleagues')[1].checked = false;
          document.getElementsByClassName('colleagues')[1].disabled = true;

          document.getElementsByClassName('leaders')[1].checked = false;
          document.getElementsByClassName('leaders')[1].disabled = true;

          document.getElementsByClassName('other')[1].checked = false;
          document.getElementsByClassName('other')[1].disabled = true;
        } else {
          document.getElementsByClassName('students')[0].checked = false;
          document.getElementsByClassName('students')[0].disabled = true;

          document.getElementsByClassName('parents')[0].checked = false;
          document.getElementsByClassName('parents')[0].disabled = true;

          document.getElementsByClassName('colleagues')[0].checked = false;
          document.getElementsByClassName('colleagues')[0].disabled = true;

          document.getElementsByClassName('leaders')[0].checked = false;
          document.getElementsByClassName('leaders')[0].disabled = true;

          document.getElementsByClassName('other')[0].checked = false;
          document.getElementsByClassName('other')[0].disabled = true;
        }
      }
      if (!checked && name === 'prefer not to say') {
        if (document.getElementsByClassName('students')[1]) {
          document.getElementsByClassName('students')[1].disabled = false;
          document.getElementsByClassName('parents')[1].disabled = false;
          document.getElementsByClassName('colleagues')[1].disabled = false;
          document.getElementsByClassName('leaders')[1].disabled = false;
          document.getElementsByClassName('other')[1].disabled = false;
        } else {
          document.getElementsByClassName('students')[0].disabled = false;
          document.getElementsByClassName('parents')[0].disabled = false;
          document.getElementsByClassName('colleagues')[0].disabled = false;
          document.getElementsByClassName('leaders')[0].disabled = false;
          document.getElementsByClassName('other')[0].disabled = false;
        }
      }
      if (name === 'prefer not to say') {
        if (checked) {
          var curentbullprefnottosayaddedval = '';
          curentbullprefnottosayaddedval =
            curentbullprefnottosayaddedval + ',' + value;
          curentbullprefnottosayaddedval =
            curentbullprefnottosayaddedval.replace(/(^,)|(,$)/g, '');
          this.setState({ bullyOpenComment: curentbullprefnottosayaddedval });
        } else {
          var curentbullprefnottosayremovedval = this.state.bullyOpenComment;
          var valuetoremoveprefnottosaybull = 'prefer not to say';
          curentbullprefnottosayremovedval =
            curentbullprefnottosayremovedval.replace(
              valuetoremoveprefnottosaybull,
              ''
            );
          curentbullprefnottosayremovedval =
            curentbullprefnottosayremovedval.replace(',,', ',');
          curentbullprefnottosayremovedval =
            curentbullprefnottosayremovedval.replace(/(^,)|(,$)/g, '');
          this.setState({ bullyOpenComment: curentbullprefnottosayremovedval });
        }
      } else {
        if (checked) {
          var curentbulladdedval = this.state.bullyOpenComment;
          curentbulladdedval = curentbulladdedval + ',' + value;
          curentbulladdedval = curentbulladdedval.replace(/(^,)|(,$)/g, '');
          this.setState({ bullyOpenComment: curentbulladdedval });
        } else {
          var curentbullremovedval = this.state.bullyOpenComment;
          var valuetoremovebull = value;
          curentbullremovedval = curentbullremovedval.replace(
            valuetoremovebull,
            ''
          );
          curentbullremovedval = curentbullremovedval.replace(',,', ',');
          curentbullremovedval = curentbullremovedval.replace(/(^,)|(,$)/g, '');
          this.setState({ bullyOpenComment: curentbullremovedval });
        }
      }
    }
    return;
  };

  handleAnsweredQuestionsPercentage = () => {
    const { slides } = this.state;
    let totalAnswered = 0;
    const totalAnsweredQuestions = reduce(
      slides,
      (result, value, index) => {
        if (value.type === 'question' && value.question.isAnswered) {
          totalAnswered++;
          return value;
        }
        return totalAnswered > 0 ? totalAnswered : 0;
      },
      {}
    );

    const totalQuestions = filter(
      slides,
      (slide) => slide.type === 'question'
    ).length;
    const surveyPercentage = (totalAnsweredQuestions / totalQuestions) * 100;
    this.setState({ surveyPercentage });
  };

  render() {
    const { slides, currentSlide, surveyPercentage } = this.state;
    return (
      <div>
        <Header />
        <div>
          {currentSlide === 0 && (
            <QuestionHelpGuide>{this.EXPLANATION_TEXT}</QuestionHelpGuide>
          )}
          <SlideWrapper>
            <Carousel
              centerMode
              showArrows={false}
              showIndicators={false}
              showThumbs={false}
              swipeable={false}
              onClickItem={(index, item) => {
                this.setState({ changed: false });
              }}
              selectedItem={this.state.currentSlide}
              showStatus={false}
            >
              {map(slides, (slide, slideIndex) => {
                const { Text } = slide.question;
                return (
                  <QuestionWrapper
                    key={slideIndex}
                    border={slideIndex === this.LAST_SLIDE}
                  >
                    <SlideNo>
                      {slideIndex < this.state.questionCount
                        ? slideIndex + 1 + '/' + this.state.questionCount
                        : ''}
                    </SlideNo>
                    {slideIndex === 0 && (
                      <MobileQuestionHelpGuide>
                        {this.EXPLANATION_TEXT}
                      </MobileQuestionHelpGuide>
                    )}
                    <QuestionInnerWrapper>
                      {Text && (
                        <QuestionTitle
                          length={Text.length}
                          id={'question' + '_' + slideIndex}
                        >
                          {Text}
                        </QuestionTitle>
                      )}

                      {this.getQuestionsAnswer({ slide, slideIndex })}
                    </QuestionInnerWrapper>
                    <QuestionInnerWrapper>
                      {(this.state.showHarrassmentOpenComment &&
                        slideIndex === 4) ||
                      (this.state.showBullyOpenComment && slideIndex === 20)
                        ? this.getOptionCommentTextBox(currentSlide)
                        : null}
                    </QuestionInnerWrapper>
                    <QuestionNavigationWrapper>
                      <QuestionNavigation>
                        {slideIndex > 0 && (
                          <PreviousButton
                            data-test-id='previousButton'
                            onClick={this.handlePreviousClick}
                            id='btn-previous'
                          >
                            Previous
                          </PreviousButton>
                        )}
                        {slideIndex !== this.LAST_SLIDE && (
                          <NextButton
                            data-test-id='nextButton'
                            id='btn-next'
                            disabled={
                              slideIndex === slides.length - 3 ||
                              slideIndex === slides.length - 4
                                ? false
                                : !slide.question.isAnswered
                            }
                            onClick={() => this.handleNextClick({ slideIndex })}
                          >
                            {slides.length - 2 === slideIndex ? 'Next' : 'Next'}
                          </NextButton>
                        )}
                      </QuestionNavigation>
                    </QuestionNavigationWrapper>
                  </QuestionWrapper>
                );
              })}
            </Carousel>
          </SlideWrapper>

          <ProgressBarWrapper>
            <QuestionProgress value={`${surveyPercentage}%`} />
          </ProgressBarWrapper>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
});
const mapStateToProps = (state) => {
  const { surveyRecord } = state.survey;
  return surveyRecord;
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Survey));
