import React from 'react';
import { AppLogo } from '../../components';
import { colors } from '../../resources/theme/colors';
import { QuestionCategoryEvaluationStyle } from '../../resources/styling/appStyle';

const QuestionCategoryEvaluation = ({
  category,
  title,
  content,
  subType,
  id
}) => {
  return (
    <QuestionCategoryEvaluationStyle
      className='survey-category-slide survey-evaluation'
      subType={subType}
      id={id}
    >
      <div>
        <div>
          <span className='small-title abc'>{category}</span>
          <h1 className='big-title'>{title}</h1>
          {content.map((item, index) => {
            return (
              <p className='content number-counter' key={index}>
                <span className='silde-content'>
                  <i className='counter-number'> {index + 1}) </i>
                  {item.Key}
                </span>
                <span className='small-percentag'>{item.Value}</span>
              </p>
            );
          })}
        </div>
      </div>

      <AppLogo type='evaluation' color={colors.whiteColor} width='100' />
    </QuestionCategoryEvaluationStyle>
  );
};

export default QuestionCategoryEvaluation;
