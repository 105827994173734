import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { RegisterWrapper } from '../../resources/styling/appStyle';

const {
    pictonBlue
} = colors;

/*** Options Detail ***/
export const ExtendedRegisterWrapper = styled(RegisterWrapper)`
  margin-top: 0 !important;

  min-height: 100vh;

  label {
    left: 1px;
    top: 2px;
  }
  .styled-checkbox {
    left: 40px;
    top: 4px;
  }

  .styled-checkbox:checked + label:after {
    background: ${pictonBlue};

    box-shadow: 2px 0 0 ${pictonBlue}, 4px 0 0 ${pictonBlue},
      4px -2px 0 ${pictonBlue}, 4px -4px 0 ${pictonBlue},
      4px -6px 0 ${pictonBlue}, 4px -8px 0 ${pictonBlue};
  }

  .checkbox-wrapper {
    font-size: 18px;
    font-weight: 600;
    i {
      font-style: normal;
      font-weight: 400;
    }
  }

  .box-detail {
    font-size: 12px;
    color: ${pictonBlue};
    display: inline-block;
    margin: 10px 0 0 41px;
    line-height: 18px;
  }
  button {
    padding: 14px 26px !important;
    margin-top: 24px !important;
  }
  .options-inner-wrapper {
    min-height: calc(100vh - 57px) !important;
  }
`;
export const RegisterInner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;


const WelbeeOptionStyle = {
    ExtendedRegisterWrapper,
    RegisterInner
}
export default WelbeeOptionStyle;