import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils';
import { HeadTeacherAccess, } from '../../utils/Constants';
import MatAdditionalQuestionForm from './MatAdditionalQuestionForm';
import MatAdditionalQuestionLikertForm from './MatAdditionalQuestionLikertForm';
import { navigationActions } from '../../store/headerStore';
import AddMatQuestion from './AddMatQuestion';
import { storageService, surveyService, matCycleAdditionalQuestionService} from '../../services';
import { parseJwt } from '../../utils/CommonFunctions';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ExtendedSubContainer, QuestionsInnerWrapper } from './MatAdditionalQuestionStyle';

/*** Table Style ***/
const purple = {
	50: '#45338C',
	100: '#45338C',	
  };

const Root = styled('div')(
	({ theme }) => `
	table {	  
	  font-size: 14px !important;
	  border-collapse: collapse;
	  width: 100%;
	  font-weight: 500 !important;
	  margin-top: 15px;
	  margin-bottom: 0 !important;
	}
  
	td,
	th {	  	 
	  padding: 8px !important; 
	  font-size: 14px !important; 
    text-align: left;
	}

	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
	}
  
	th {
	  background-color: ${theme.palette.mode === 'dark' ? purple[50] : purple[100]};
	  color: ${theme.palette.common.white};
	}
	`,
  );

class MatCycleAdditionalQuestions extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);

    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      createDialogOpen: false,
      matQuestionsData: [],
      editRecord: null,
      isEdit: false,
      isLikertEdit: false,
      accessLevel: 0,
      MatCycleId: null,
      MatGroupId: 0
    };
  }

  componentDidMount() {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const matCycleId = window.location.search.split('?cycleId=')[1];
    this.props.navigationActions.activeItemAction({
      item: MenuItems.QuestionBank,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    this.setState({ accessLevel: accessLevel,});
    this.getMatQuestions(matCycleId);
    this.getQuestionBank(matCycleId);
    //this.getSurveyInfo(token);
  }

  getMatQuestions(matCycleId) {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    matCycleAdditionalQuestionService
    .getMatCycleQuestions(matCycleId)
    .then((response) => {
      if (response.success) {
        this.setState({ matQuestionsData: response.data });
      }
      loaderActions.dataLoading(false);
    })
    .catch((error) => {
      console.log(error);
      loaderActions.dataLoading(false);
    });
  };

  getQuestionBank(matCycleId) {
    matCycleAdditionalQuestionService
    .getQuestionBank(matCycleId)
    .then((response) => {
      if (response.success) {
        this.setState({ questionBankOptions: response.data });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  deleteMatQuestion(question){
    matCycleAdditionalQuestionService
    .deleteMatCycleQuestions(question)
    .then((response) => {
      if (response.success) {
        this.setState({ 
          matQuestionsData: response.data.matQuestions,  
          questionBankOptions: response.data.questionBankOption
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  handleCreateQuestion = () => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create Mat first.')
      : this.setState({
          createAddQuestionFormOpen: true,
          editRecord: null,
          isEdit: false,
        });
  };

  handleAddQuestionSubmit = (question) => {
    this.setMatQuestions(question);
    this.setState({ 
      createAddQuestionFormOpen: false, 
      editLikertQuestionFormOpen: false
     });
  };

  addQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ createAddQuestionFormOpen: false });
  };

  addLikertQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ editLikertQuestionFormOpen: false });
  };

  handleQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      createAddQuestionFormOpen: true,
      isEdit: true,
    });
  };

  handleLikertQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      editLikertQuestionFormOpen: true,
      isLikertEdit: true,
    });
  };

  handleQuestionDelete = (question) => {
    this.deleteMatQuestion(question);
  };

  render() {
    const {
      createAddQuestionFormOpen,
      editLikertQuestionFormOpen,
      matQuestionsData,
      editRecord,
      isEdit,
      isLikertEdit,
      accessLevel,
      matCycleId,
      matGroupId
    } = this.state;

    return (
      <div>
        <ExtendedSubContainer>
          <div className='search-data-container'>
            <div className='search-inner-container'>
              {matQuestionsData.length > 0 ? (
                <div className='search-inner-sub-container'>
                  <h2>
                    Additional Survey Questions
                  </h2>
                  <Root>
                    <table>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Question Type</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matQuestionsData.map((question, key) => (
                          <tr key={key}>
                            <td style={{width:'80%'}}>{question.QuestionText}</td>
                            <td className='action-category'>{question.TypeDescription}</td>
                            <td>
                            <Tooltip title="Delete question" aria-label="add">
                              <DeleteIcon 
                                style={{ color: '#E84F6B' }} 
                                onClick={() => {
                                  if (accessLevel === HeadTeacherAccess.Full) {
                                    this.handleQuestionDelete(question);
                                  }
                                }}
                              /> 
                              </Tooltip>
                              &nbsp;&nbsp;
                              {accessLevel === HeadTeacherAccess.Full ? (
                                (question.Category !== 11 ? (
                                  <Tooltip title="Edit question" aria-label="add">
                                    <EditIcon 
                                      color="primary" 
                                      onClick={() => {
                                        if (question.Category === 10) {
                                          this.handleQuestionEdit(question);
                                        } else {
                                          this.handleLikertQuestionEdit(question);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                ) : null)  
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Root>
                </div>
              ) : (
                <QuestionsInnerWrapper>
                  <div className='questions-not-available'>
                    No Additional Questions choosen for current group. 
                  </div>
                </QuestionsInnerWrapper>
              )}
            </div>
          </div>
        </ExtendedSubContainer>

        {createAddQuestionFormOpen ? (
          <MatAdditionalQuestionForm
            isOpen={createAddQuestionFormOpen}
            onClose={this.addQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isEdit}
            className={'xyz'}
            matGroupId={matGroupId}
            matCycleId={matCycleId}
          />
        ) : null}

        {editLikertQuestionFormOpen ? (
          <MatAdditionalQuestionLikertForm
            isOpen={editLikertQuestionFormOpen}
            onClose={this.addLikertQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isLikertEdit}
            className={'xyz'}
            matGroupId={matGroupId}
            matCycleId={matCycleId}
          />
        ) : null}
      </div>
    );          
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(MatCycleAdditionalQuestions));
