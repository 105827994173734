import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import {
  AnalyticsChartWrapper,
  AnalyticsChartPopupWrapper,
  ChartWrapperError,
} from '../../resources/styling/appStyle';
import { Grid, Tooltip, Stack } from '@mui/material';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { ManagmentStandards } from '../../utils/Constants';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { ScrollContainer } from 'react-nice-scroll';
import ManagementStandardChart from '../../components/Charts/ManagementStandardChart';
import CircularProgress from '@mui/material/CircularProgress';
import html2canvas from 'html2canvas';
const sortOrder = {
  none: 0,
  ascending: 1,
  descending: 2,
};

export default function MATMgtStandardAnalytics(props) {
  const [isPopupShowing, setIsPopupShowing] = useState(false);
  const [demandData, setDemandData] = useState(props?.demandAnalytics);
  const [controlData, setControlData] = useState(props?.controlAnalytics);
  const [managerSupportData, setManagerSupportData] = useState(
    props?.managerSupportAnalytics
  );
  const [peerSupportData, setPeerSupportData] = useState(
    props?.peerSupportAnalytics
  );
  const [relationshipData, setRelationshipData] = useState(
    props?.relationshipAnalytics
  );
  const [roleData, setRoleData] = useState(props?.roleAnalytics);
  const [changeData, setChangeData] = useState(props?.changeAnalytics);
  const [sortingOrder, setSortingOrder] = useState({ sortBy: '', order: 0 });
  const [isLoader, setIsLoader] = React.useState(false);
  const [currentSortedCatetgory, setCurrentSortedCategory] = useState('');

  const showGraphPopup = () => {
    setIsPopupShowing(true);
  };

  const handleSort = (sortBy, order, sortedCategory) => {
    setCurrentSortedCategory(sortedCategory);
    const categoryDataMap = {
      'Demands': demandData,
      'Control': controlData,
      'Manager Support': managerSupportData,
      'Peer Support': peerSupportData,
      'Relationships': relationshipData,
      'Role': roleData,
      'Change': changeData,
    };

    // Get the dataset corresponding to the sorted category
    const sortedCategoryData = categoryDataMap[sortedCategory];

    // Sort the data within the sorted category by the specified criterion
    const sortedSortedCategoryData = sortedCategoryData.sort((a, b) => {
        if (order === sortOrder.ascending) {
            return a[sortBy] - b[sortBy];
        } else if (order === sortOrder.descending) {
            return b[sortBy] - a[sortBy];
        }
    });

    // Extract labels that are brought to the top in the sorted category
    const sortedLabels = sortedSortedCategoryData.map(item => item.label);

    // Update the data in all categories based on the sorted labels
    Object.keys(categoryDataMap).forEach(category => {
        const categoryData = categoryDataMap[category];
        // Sort the data based on labels if the category is not the sorted category
        if (category !== sortedCategory) {
            const sortedData = categoryData.sort((a, b) => {
                const labelAIndex = sortedLabels.indexOf(a.label);
                const labelBIndex = sortedLabels.indexOf(b.label);
                return labelAIndex - labelBIndex;
            });
            categoryDataMap[category] = sortedData;
        }
    });

    // Update the state with the sorted data for all categories
    Object.keys(categoryDataMap).forEach(category => {
        switch (category) {
            case 'Demands':
                setDemandData([...categoryDataMap[category]]);
                break;
            case 'Control':
                setControlData([...categoryDataMap[category]]);
                break;
            case 'Manager Support':
                setManagerSupportData([...categoryDataMap[category]]);
                break;
            case 'Peer Support':
                setPeerSupportData([...categoryDataMap[category]]);
                break;
            case 'Relationships':
                setRelationshipData([...categoryDataMap[category]]);
                break;
            case 'Role':
                setRoleData([...categoryDataMap[category]]);
                break;
            case 'Change':
                setChangeData([...categoryDataMap[category]]);
                break;
            default:
                break;
        }
    });

    // Update the sorting order state
    setSortingOrder({ sortBy, order });
};

  const closePopup = () => {
    setIsPopupShowing(false);
  };
  
  const downloadChart = () => {
    setIsLoader(true);
    return new Promise((resolve, reject) => {
      try {
        const mgtChart = 'mgtChart';
        const chartContainer = document.getElementById(mgtChart);
        if (!chartContainer) {
          console.error(`Element with id '${mgtChart}' not found in the DOM.`);
          reject(new Error(`Element with id '${mgtChart}' not found in the DOM.`));
          return;
        }
        const originalWidth = chartContainer.style.width;
        const originalPadding = chartContainer.style.padding;
        chartContainer.style.width = '2330px';
        chartContainer.style.padding = '20px';
        chartContainer.style.backgroundColor = 'white';
        domtoimage
          .toBlob(chartContainer)
          .then((blob) => {
            saveAs(blob, 'ManagmentStabdardGraph.png');
          })
          .catch((error) => {
            console.error('Error generating screenshot:', error);
          })
          .finally(() => {
            chartContainer.style.width = originalWidth;
            chartContainer.style.padding = originalPadding;
            setIsLoader(false);
            resolve();
          });
      } catch (error) {
        setIsLoader(false);
        console.log('ppt-chart-download', error);
        reject(error);
      }
    });
  };
  return (
    <Grid item xs={12} md={12} >
      {props?.isEmptyMessage ? (
        <ChartWrapperError>
          <span className='nodata'>{props?.emptyMessage}</span>
        </ChartWrapperError>
      ) : (
        <>
          <AnalyticsChartWrapper>
            <div id='mgtChart'>
            <Box sx={{ marginBottom: '30px', textAlign: 'center' }}>
              <h5 style={{ display: 'inline-block', margin: '0' }}>
                <p>{props?.selectedMATcharacteristicsTypeLabel} </p>
                <p>{props?.demandAnalytics?.title}</p>
              </h5>
              <div className='large-download-btn'>
                <Tooltip title='Download Chart' arrow>
                {isLoader ? <CircularProgress /> : <ArrowCircleDownIcon className='large-graph-btn' fontSize='inherit' onClick={downloadChart} />}
                </Tooltip>
              </div>
              {isLoader === false?  <Tooltip title='Full Screen View' arrow>
                <FullscreenIcon
                  class='large-graph-btn'
                  style={{
                    display: 'inline-block',
                    float: 'right',
                  }}
                  fontSize='inherit'
                  onClick={() => showGraphPopup()}
                />
              </Tooltip> : <></>}
             
            </Box>
            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                {props?.selectedCategory === ManagmentStandards.All && (
                  <Stack display='flex' flexDirection='row'>                                  
                      <ManagementStandardChart
                            datasets={demandData}
                            width={199}
                            margin={28}
                            TicksHidden={false}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={true}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                    <ScrollContainer alwaysShowTracks={true}>
                      <div className='horizontal-chart'>
                        <div className='compare-bars' >
                          <ManagementStandardChart
                            datasets={demandData}
                            width={300}
                            margin={0}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={controlData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={managerSupportData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={peerSupportData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={relationshipData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={roleData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                          <ManagementStandardChart
                            datasets={changeData}
                            width={312}
                            margin={12}
                            TicksHidden={true}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    </ScrollContainer>
                    
                   
                  </Stack>
                )}

                {props?.selectedCategory === ManagmentStandards.Demand && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={demandData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}

                {props?.selectedCategory === ManagmentStandards.Control && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={controlData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}

                {props?.selectedCategory ===
                  ManagmentStandards.ManagerSupport && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={managerSupportData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}

                {props?.selectedCategory ===
                  ManagmentStandards.Relationships && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={relationshipData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}

                {props?.selectedCategory === ManagmentStandards.Role && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={roleData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}

                {props?.selectedCategory === ManagmentStandards.Change && (
                  //<Stack display='flex' flexDirection='row' isCategory={true}>
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={changeData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                  //</Stack>
                )}

                {props?.selectedCategory === ManagmentStandards.PeerSupport && (                  
                  <ScrollContainer alwaysShowTracks={true}>
                    <div className='horizontal-chart'>
                      <div className='compare-bars' id='mgtChart'>
                        <ManagementStandardChart
                          datasets={peerSupportData}
                          isCategory={true}
                          surveyCount={props?.surveyCount}
                          onSort={handleSort}
                          hideBars={false}
                          currentSortedCatetgory={currentSortedCatetgory}
                        />
                      </div>
                    </div>
                  </ScrollContainer>
                )}
              </Grid>
            </Grid>
            </div>
          </AnalyticsChartWrapper>
        </>
      )}
      {isPopupShowing ? (
        <GraphPopup>          
          <div className='graph-container' id='large-graph'>
            <div className='redCrossButtom'>
              <CloseIcon className='closeIcon' onClick={() => closePopup()} />
            </div>            
              <AnalyticsChartPopupWrapper style={{overflow:'none !important'}}>
                <div className='mb-3' style={{ textAlign: 'center' }}>
                  <h5 className='d-inline-block'>
                    {props?.selectedMATcharacteristicsTypeLabel}
                  </h5>
                </div>
                <Grid container>
                  <Grid item lg={12} md={12} xs={12}>
                    {props?.selectedCategory === ManagmentStandards.All && (
                      <Stack display='flex' flexDirection='row'> 
                       <ManagementStandardChart
                            datasets={demandData}
                            width={199}
                            margin={28}
                            TicksHidden={false}
                            isCategory={false}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={true}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />  
                          <div className='popup-scroll'>
                          <div className='horizontal-chart'>
                            <div className='compare-bars' id='mgtChart'>
                              <ManagementStandardChart
                                datasets={demandData}
                                width={300}
                                margin={0}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={controlData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={managerSupportData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={peerSupportData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={relationshipData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={roleData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                              <ManagementStandardChart
                                datasets={changeData}
                                width={312}
                                margin={12}
                                TicksHidden={true}
                                isCategory={false}
                                surveyCount={props?.surveyCount}
                                onSort={handleSort}
                                hideBars={false}
                                currentSortedCatetgory={currentSortedCatetgory}
                              />
                            </div>
                          </div>
                          </div>
                        
                      </Stack>
                    )}

                    {props?.selectedCategory === ManagmentStandards.Demand && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={demandData}                            
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}

                    {props?.selectedCategory === ManagmentStandards.Control && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={controlData}
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}

                    {props?.selectedCategory ===
                      ManagmentStandards.ManagerSupport && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={managerSupportData}
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}

                    {props?.selectedCategory ===
                      ManagmentStandards.Relationships && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={relationshipData}
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}

                    {props?.selectedCategory === ManagmentStandards.Role && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={roleData}
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}

                    {props?.selectedCategory === ManagmentStandards.Change && (                        
                        <div className='horizontal-chart'>
                          <div className='compare-bars' id='mgtChart'>
                            <ManagementStandardChart
                              datasets={changeData}
                              isCategory={true}
                              surveyCount={props?.surveyCount}
                              onSort={handleSort}
                              hideBars={false}
                              currentSortedCatetgory={currentSortedCatetgory}
                            />
                          </div>
                        </div>
                    )}

                    {props?.selectedCategory ===
                      ManagmentStandards.PeerSupport && (                                            
                      <div className='horizontal-chart'>
                        <div className='compare-bars' id='mgtChart'>
                          <ManagementStandardChart
                            datasets={peerSupportData}
                            isCategory={true}
                            surveyCount={props?.surveyCount}
                            onSort={handleSort}
                            hideBars={false}
                            currentSortedCatetgory={currentSortedCatetgory}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </AnalyticsChartPopupWrapper>
          </div>          
        </GraphPopup>
      ) : (
        ''
      )}
    </Grid>
  );
}
