import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu } from '../../components';
import VoiceOptions from '../../components/common/VoiceOptions';
import NewWelbeeVoiceForm from './NewWelbeeVoiceForm';
import WelbeeReply from './WelbeeReply';
import { welbeeVoiceService, storageService } from '../../services';
import { WebStorageNames } from '../../utils';
import { Sender, SurveyTypes } from '../../utils/Constants';
import { routesObj } from '../../routes';
import { AssistanceWrapperHeading, ConversationBody } from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { ExtendedSearchFilterWrapper, ExtendedAssistanceWrapper, ExtendedVoiceDashboardWrapper } from './WelbeeVoiceStyle';

class HeadteacherVoiceConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      converstionReplyList: [],
      conversationId: 0,
      converstionDetail:
        this.props.location.state !== null
          ? this.props.location.state.data
          : [],
      converstionDescription:
        this.props.location.state !== null
          ? this.props.location.state.voiceDescription
          : '',
      conversationOptions: [{ value: SurveyTypes.any, label: '' }],
      openConverstionPopUp: false,
    };
  }
  closePopUp() {
    this.setState({ openConverstionPopUp: false });
  }
  redirectToStaffManagment() {
    this.props.history.push(routesObj.StaffManagement.path);
  }
  componentDidMount() {
    const id = window.location.search.split('id=')[1];
    welbeeVoiceService
      .getConversationComments(id)
      .then((response) => {
        if (response.success) {
          this.setState({
            converstionReplyList: this.transformCommentsList(response.data),
            conversationId: id,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  transformCommentsList(data) {
    const transformedList = [];
    if(data.length > 0) {
      data.map((item) => {
        if(item.SenderId === null && item.MatGroup != null && item.Sender === 2) {
          const headTeacher = { AdminName: item.MatGroup.FirstName };
          item.HeadTeacher = headTeacher; //item.MatGroup.FirstName;
        }
        transformedList.push(item);
      });
      return transformedList;
    }
    return [];
  }
  render() {
    const { openConverstionPopUp } = this.state;
    return (
      <ExtendedVoiceDashboardWrapper>

        <div className='left-container'>
          <LeftMenu />
        </div>

        <div className='right-container'>
          <ExtendedAssistanceWrapper>
            <AssistanceWrapperHeading>
              <span className='comment-heading'>Welbee voice</span>
              <span> conversation list</span>
            </AssistanceWrapperHeading>
            <VoiceOptions
              showConversationDropDown={false}
              addVoiceButtonClick={() =>
                this.setState({ openConverstionPopUp: true })
              }
            />
          </ExtendedAssistanceWrapper>
          <ExtendedSearchFilterWrapper>
            <div className='subcontainer-inner'>
              <div
                className='back-button'
                onClick={() => {
                  let token = storageService.getItem(
                    WebStorageNames.SurveyToken
                  );

                  this.props.history.push(
                    routesObj.VoiceDashboard.path + '?token=' + token
                  );
                }}
              >
                Back to reply list
              </div>
            </div>
          </ExtendedSearchFilterWrapper>
          <ConversationBody>
            <div className='content-body-inner-wrapper'>
              <WelbeeReply
                converstionReplyList={this.state.converstionReplyList}
                sender={Sender.You}
                converstionId={this.state.conversationId}
                converstionDetail={this.state.converstionDetail}
                converstionDescription={this.state.converstionDescription}
              />
            </div>
          </ConversationBody>
        </div>
        {openConverstionPopUp && (
          <NewWelbeeVoiceForm
            onSubmit={(value) => {
              this.onCreateConverstionClick(value);
            }}
            isLoading={false}
            submitButtonText={'All staff invitation'}
            closeButtonText={'Select staff'}
            isOpen={this.state.openConverstionPopUp}
            onClose={() => {
              this.closePopUp();
            }}
            onSelectStaff={() => this.redirectToStaffManagment()}
          />
        )}
      </ExtendedVoiceDashboardWrapper>
    );
  }
}

export default connect(null, null)(withRouter(HeadteacherVoiceConversation));
