import React from 'react';
import { useTranslation } from 'react-i18next';

const LearnSchool = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        One evidence based approach that can be adopted is the Health and Safety
        Executive’s Management Standards. This is the approach we recommend and
        what we use to underpin the wellbeing survey and reporting of results.
      </p>
      <p>
        These standards can be used to define the characteristics and culture of
        a school, where the risks from work related stress and poor staff
        wellbeing are being effectively managed and controlled.
      </p>
      <p>
        These cover the six key areas of work design, that if not properly
        managed, are associated with poor health and wellbeing, lower
        productivity, increased sickness absence and lower staff retention. By
        measuring your school against these you can determine how staff view
        their working environment and take action, where required, to improve
        it.
      </p>
      <p>The Six Management Standards Are:</p>
      <p className='custom-bullet'>
        <strong>Demands</strong> – this includes anything that places a demand
        on a staff member, such as workload, work patterns, working hours,
        deadlines, their timetable, the time given for them to complete tasks,
        and the {t('behviour')} of students, colleagues and leaders.
      </p>
      <p className='custom-bullet'>
        <strong>Control</strong> – this is the level of autonomy a staff member
        feels they have, including; the say they have over what they do at work;
        how they do their work; the level of choice they have over what to do
        when taking a break; and the level of flexibility available to them.
      </p>
      <p className='custom-bullet'>
        <strong>Support</strong> – this includes the encouragement, sponsorship
        and resources they receive or have access to and are provided by the
        school, line management and colleagues.
      </p>
      <p className='custom-bullet'>
        <strong>Relationships</strong> – this includes the extent to which staff
        {t('behviour')} is appropriate, how well positive working is promoted to
        avoid conflict and how effectively and quickly unacceptable{' '}
        {t('behviour')} is dealt with.
      </p>
      <p className='custom-bullet'>
        <strong>Role</strong> – this includes how well people understand their
        role, what is expected of them, how it fits with the role of their
        department or function and the overall aim of the school and whether
        leaders ensure they do not have conflicting roles.
      </p>
      <p className='custom-bullet'>
        <strong>Change</strong> – how changes at school (large or small) are
        managed and communicated to all staff and stakeholders, including
        effective consultation and whether opportunities are given for staff to
        question leaders.
      </p>

      <p>
        These Management Standards represent a set of conditions, that if
        present, are likely to reflect a high level of staff wellbeing and
        school performance. Having effective Management Standards in place means
        being able to:
      </p>

      <p className='custom-bullet'>
        Demonstrate good practice through a step by step risk assessment
        approach;
      </p>
      <p className='custom-bullet'>
        Assess the current situation using data, surveys and other techniques;
      </p>
      <p className='custom-bullet'>
        Hold discussions and work in partnership with staff to help decide and
        implement practical improvements;
      </p>
      <p className='custom-bullet'>
        Simplify risk assessment for work related stress by:
      </p>

      <p className='costom-sub-bullet'>
        Identifying the main risk factors for work related stress;
      </p>
      <p className='costom-sub-bullet'>
        Helping staff focus on the underlying causes and their prevention; and
      </p>
      <p className='costom-sub-bullet'>
        Providing a yardstick by which the school can measure their current and
        future performance in tackling these key causes of stress.
      </p>

      <p>
        The <b>staff survey</b> you will or have undertaken is a key part of
        these standards. It allows you to better understand the current working
        environment and take the necessary steps to improve it where necessary.
      </p>

      <p>
        As it is important to use an evidence base for any survey and to
        benchmark scores this does mean accepting all questions, even those
        where we know it is likely school staff, and particularly those in the
        classroom may score lower.
      </p>
      <p>
        Once completed you will receive scores for each of these six areas and a
        report against each of the standards and you can use these to identify
        areas for action. By completing future surveys you will be able to track
        trends and see the impact you are making.
      </p>
      <p>
        Your school's scores are benchmarked against other schools who have used
        the survey. Your decile performance is shown and the higher the decile
        the better the comparative performance. For example, if your score is in
        the first decile, it is in the top 10% of schools, while a 10th decile
        score means that more than 90% of schools have scored more highly.
      </p>
      <p>
        A benchmark is also shown against 136 cross-sector {t('organisations')}.
        This was established by the Health and Safety Executive and again your
        decile performance is shown in the same way as it is for schools. This
        will allow you to consider whether or not there may be learning from
        beyond the education sector. Some leaders find this very valuable, while
        others prefer to focus on school comparisons. The choice is yours.
      </p>
      <p>
        You are also provided with comments made by your staff against two open
        questions that we know elicit the most useful feedback and provide
        further insight into what may be happening and actions you can take.
      </p>
      <p>
        As well as reviewing these you are also able to respond anonymously to
        comments, providing you uploaded your staff e-mail addresses and issued
        invitations to the survey directly from your welbee dashboard (see the
        issuing survey invitations section below).
      </p>
      <p>
        This will allow you to seek more information about a comment made,
        provide reassurance, address concerns, ask them to take a specific
        action or provide immediate feedback. It is not suggested that you
        respond to all comments, though having this flexibility will allow you
        to open up and have more honest conversations, while staff will be able
        to maintain their anonymity if they wish.
      </p>

      <p>
        Instructions on how you do this are included in section 2 under the
        staff comments section. There are other resources available, such as a
        presentation you can use with staff to engage with them to share
        results. It is important to involve staff and create shared solutions,
        rather than think of this as something senior leaders need to do
        independently for staff. Wellbeing is everyone’s business.
      </p>
      <p>
        It is also important to appreciate that the results of the survey alone
        can only provide an indication of performance in managing work-related
        stress and improving wellbeing. You will need to share and discuss the
        outcomes of the survey with staff, and may need to explore any issues
        raised in more detail.
      </p>
      <p>
        The intention is that all the data you collect can be used to promote a
        system of continuous improvement. Other school data, such as that for
        staff absence, staff turnover, student {t('behviour')} and outcomes, are
        other important indicators of the prevalence of work-related stress and
        likely staff wellbeing in your school.
      </p>
    </div>
  );
};

export default LearnSchool;
