import { isEmpty, map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { AppModel } from '../../components';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import FooterButtons from '../../components/common/FooterButtons';
import { AppButton } from '../../resources/styling';
import { colors } from '../../resources/theme/colors';
import {
  surveyService,
  storageService,
  schoolStaffService,
  dashboardService,
} from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, WelbeeType } from '../../utils';
import {
  DateFormat,
  DateFormatLocalTime,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import NewSurveyForm from './NewSurveyForm';
import SurveyCommentsForm from './SurveyCommentsForm';
import { TableStyle } from '../../resources/styling/appStyle';
import { SurveysDisabledSchools } from '../../utils/Constants';
import {
  SurveyListWarpper,
  EmptySurveysDiv,
  SurveyListMainWrapper,
} from './SchoolSurveyStyle';
const { mandy, teal } = colors;

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSurveyDialogOpen: false,
      updateSurveyDialogOpen: false,
      surveys: [],
      selectedSurvey: {},
      isSurveyClosed: false,
      commentsDialogueOpen: false,
      SurveyId: 0,
      SuccessPopup: false,
      SurveyCloseDialog: false,
      SurveyReopenDialog: false,
      createdSurvey: null,
      TempSurveyName: '',
      isLoading: false,
      isStaffExist: false,
      isCreateSurveyConfirmation: false,
      onConfirm: '',
      SurveyDeleteDialog: false,
      employeesCount: 0,
      hasAddtionalQuestionFiltersIncluded: true,
    };
  }

  componentDidMount = () => {
    let schoolId = getQueryStringVal('schoolId');
    this.getSchoolSurveys(schoolId);
    this.AddtionalQuestionFiltersIncluded();
    schoolStaffService
      .schoolStaffExist(schoolId)
      .then((response) => {
        if (response.success && response.total > 0) {
          this.setState({ isStaffExist: true });
        }
      })
      .catch((error) => console.log(error));
  };

  getDisableValue = () => {
    let schoolId = getQueryStringVal('schoolId');
    switch (parseInt(schoolId)) {
      case SurveysDisabledSchools.WelbeeDemonstrationPrimarySchool:
        return true;
      case SurveysDisabledSchools.WelbeeDemonstrationPrimarySchoolPremium:
        return true;
      default:
        return false;
    }
  };

  getSchoolSurveys = (schoolId) => {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    surveyService
      .getSchoolSurveys(schoolId)
      .then((response) => {
        this.setState({ surveys: response.data });
        loaderActions.dataLoading(false);
      })
      .catch((error) => console.log(error));
  };

  getTempSurveyName = (date) => {
    let startdate = ' (' + DateFormat(date) + ')';
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    this.setState({ isLoading: true });
    surveyService
      .getNewSurveyName(schoolId)
      .then((response) => {
        this.GetNumberOfEmployees();
        this.setState({
          TempSurveyName: response.data + startdate,
          isLoading: false,
        });
        this.setState({ StartDate: date });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  GetNumberOfEmployees = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    schoolStaffService
      .getNumberOfEmployees(schoolId)
      .then((response) => {
        this.setState({
          employeesCount: response.data === 0 ? '' : response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddNewSurvey = () => {
    this.setState({
      newSurveyDialogOpen: true,
      selectedSurvey: {},
      createdSurvey: null,
      isLoading: false,
    });

    this.GetNumberOfEmployees();
  };

  handleUpdateSurveyClick = (id) => {
    const { surveys } = this.state;
    this.setState({
      selectedSurvey: surveys.find((obj) => obj.Id === id),
      updateSurveyDialogOpen: true,
    });
  };

  handleNewSurveyCancel = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, newSurveyDialogOpen: false });
  };

  handleSurveyCommentCancel = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, commentsDialogueOpen: false });
  };

  handleCommentsSuccessCancel = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, SuccessPopup: false });
  };

  handleUpdateSurveyCancel = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, updateSurveyDialogOpen: false });
  };

  handleOpenCreateSurveyConfirm = () => {
    this.setState({ isCreateSurveyConfirmation: true });
  };

  handleCancelCreateSurveyConfirm = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, isCreateSurveyConfirmation: false });
  };

  handleCloseCreateSurveyConfirm = (event) => {
    event.preventDefault();
    this.setState({ selectedSurvey: {}, isCreateSurveyConfirmation: false });
  };

  handleAddSurveySubmit = (values) => {
    values.StartDate = DateFormatLocalTime(values.StartDate);
    const { surveys } = this.state;
    let schoolId = getQueryStringVal('schoolId');
    values.SchoolId = schoolId;

    surveyService
      .addSurvey(values)
      .then((response) => {
        if (response.success) {
          const surveyObj = response.data;
          let isSurveyToken = false;
          if (surveyObj.SurveyToken) {
            isSurveyToken = true;
          }
          this.setState({
            surveys: [...surveys, surveyObj],
            isSurveyCreated: isSurveyToken,
            createdSurvey: surveyObj,
          });
          this.onCreateSurvey(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      newSurveyDialogOpen: false,
      isCreateSurveyConfirmation: false,
    });
  };

  handleUpdateSurveySubmit = (value) => {
    const { surveys, selectedSurvey } = this.state;
    const obj = selectedSurvey;
    obj.Name = value.Name;
    obj.StartDate = value.StartDate;
    obj.EndDate = value.EndDate;
    obj.EmployeesCount = value.EmployeesCount;

    var updatedSurveys = map(surveys, (survey) => {
      if (survey.Id === obj.Id) survey = obj;
      return survey;
    });

    this.setState({
      surveys: updatedSurveys,
    });

    surveyService
      .updateSurvey(obj)
      .then((res) => {})
      .catch((error) => {
        var revertSurveys = map(surveys, (survey) => {
          if (survey.Id === selectedSurvey.Id) survey = selectedSurvey;
          return survey;
        });
        this.setState({
          surveys: revertSurveys,
        });
      });

    this.setState({ selectedSurvey: {}, updateSurveyDialogOpen: false });
  };

  manuallySendSurvey(token) {
    dashboardService
      .manuallySendSurvey(token)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success('Manual survey email sent.');
        }
      })
      .catch((error) => {});
  }

  handleCloseSurveyDialog = (id, survey) => {
    this.setState({
      SurveyCloseDialog: true,
      SurveyId: id,
      selectedSurvey: survey,
    });
  };

  handleCancelCloseSurveyDialog = () => {
    this.setState({ SurveyCloseDialog: false });
  };

  handleCloseSurvey = (id) => {
    surveyService
      .closeSurvey(id)
      .then((response) => {
        if (response.success) {
          const survey = response.data.find((x) => x.Id === id);
          if (survey) {
            const reportLink = this.getLink(
              'survey-report',
              survey.SurveyToken
            );
            window.location = reportLink;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleReopenDialog = (surveyId, survey) => {
    this.setState({
      SurveyReopenDialog: true,
      SurveyId: surveyId,
      selectedSurvey: survey,
    });
  };

  handleCancelSurveyReopenDialog = () => {
    this.setState({
      SurveyReopenDialog: false,
    });
  };

  handleSurveyReopen = (id) => {
    surveyService
      .reOpenSurvey(id)
      .then((response) => {
        if (response.success)
          this.setState({ surveys: response.data, SurveyReopenDialog: false });
      })
      .catch((error) => console.log(error));
  };

  handleSurveyCommentsDialogue = (Id, survey) => {
    this.setState({
      commentsDialogueOpen: true,
      SurveyId: Id,
      selectedSurvey: survey,
    });
  };
  handleSurveyCommentsSubmit = (values) => {
    surveyService.addSurveyCustomComments(values).then((response) => {
      const { surveys } = this.state;
      let indexOfSurvey = surveys.findIndex((x) => x.Id === values.Id);
      let survey = surveys.find((x) => x.Id === values.Id);

      survey.DemandCustomAdminText = values.DemandCustomAdminText;
      survey.ChangeCustomAdminText = values.ChangeCustomAdminText;
      survey.ControlCustomAdminText = values.ControlCustomAdminText;
      survey.RoleCustomAdminText = values.RoleCustomAdminText;
      survey.RelationshipsCustomAdminText = values.RelationshipsCustomAdminText;
      survey.ManagerCustomAdminText = values.ManagerCustomAdminText;
      survey.PeerCustomAdminText = values.PeerCustomAdminText;

      surveys[indexOfSurvey] = survey;
      this.setState({
        commentsDialogueOpen: false,
        SuccessPopup: true,
        selectedSurvey: survey,
        surveys,
      });
    });
  };
  getLink(type, token) {
    storageService.setItem(
      WebStorageNames.SelectedTab,
      WelbeeType.WelbeeSurveyWithVoice
    );
    storageService.removeItem(WebStorageNames.schoolLogoUrl);
    return `${type}/?token=${token}`;
  }

  onCreationPopupOkPress = () => {
    this.setState({ isSurveyCreated: false });
  };

  onCopyButtonClick = (text) => {
    const ele = document.getElementById('survey-link-to-copy');
    ele.select();
    document.execCommand('copy');
  };

  handleOpenSurveyDeleteDialog = (id, survey) => {
    this.setState({
      SurveyDeleteDialog: true,
      SurveyId: id,
      selectedSurvey: survey,
    });
  };

  handleDeleteSurvey = (surveyId) => {
    let schoolId = getQueryStringVal('schoolId');
    surveyService
      .archiveSurvey(surveyId)
      .then((response) => {
        if (response.success) {
          this.setState({
            SurveyDeleteDialog: false,
          });
          this.getSchoolSurveys(schoolId);
          toast.success('Survey has been deleted successfuly.');
        }
      })
      .catch((error) => console.log(error));
  };

  handleCancelDeleteSurveyDialog = () => {
    this.setState({ SurveyDeleteDialog: false });
  };

  AddtionalQuestionFiltersIncluded = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    surveyService
      .hasAddtionalQuestionFiltersIncluded(schoolId)
      .then((response) => {
        this.setState({
          hasAddtionalQuestionFiltersIncluded:
            response.data.hasAddtionalQuestionFiltersIncluded,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onCreateSurvey = (isReload = false) => {
    if (isReload) window.location.reload();
  };

  render() {
    const {
      newSurveyDialogOpen,
      updateSurveyDialogOpen,
      surveys,
      selectedSurvey,
      SurveyId,
      commentsDialogueOpen,
      SuccessPopup,
      SurveyCloseDialog,
      SurveyReopenDialog,
      isCreateSurveyConfirmation,
      SurveyDeleteDialog,
    } = this.state;
    const successPopupMessage = 'Your Comments have been saved successfully.';
    return (
      <SurveyListWarpper>
        <SurveyListMainWrapper>
          <h1 style={{ marginBottom: '0px' }}>
            Survey List
            <span
              style={{ fontSize: '15px', float: 'right' }}
              onClick={this.handleAddNewSurvey}
            >
              Create Survey
            </span>
          </h1>
          <TableStyle>
            <table>
              <thead>
                <tr>
                  <th>Survey Title</th>
                  <th>Survey %</th>
                  <th>Survey Link</th>
                  <th>Results Link</th>
                  <th>Head Teacher Results</th>
                  <th>Report Link</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {surveys != null && surveys.length > 0 ? (
                  map(surveys, (survey, index) => (
                    <tr key={index}>
                      <td>{survey.Name}</td>
                      <td>{survey.SurveyCompletionPercentage}%</td>
                      <td>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={this.getLink(
                            'survey-welcome',
                            survey.SurveyToken
                          )}
                        >
                          Survey Link
                        </a>
                      </td>
                      <td>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={this.getLink(
                            'survey-result',
                            survey.SurveyToken
                          )}
                        >
                          Survey Results Link
                        </a>
                      </td>
                      <td>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={this.getLink(
                            'head-teacher-survey-result',
                            survey.SurveyToken
                          )}
                        >
                          Head Teacher Results
                        </a>
                      </td>
                      <td>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={this.getLink(
                            'survey-report',
                            survey.SurveyToken
                          )}
                        >
                          Report Link
                        </a>
                      </td>

                      <td>
                        <span className='manualSendOuter'>
                          <AppButton
                            color={teal}
                            type='submit'
                            onClick={() =>
                              this.manuallySendSurvey(survey.SurveyToken)
                            }
                          >
                            Manual link
                          </AppButton>
                          <span class='toolTipText'>
                            You only need to use this if you have added new or
                            additional staff records since the invitation was
                            sent automatically
                          </span>
                        </span>
                        <AppButton
                          color={this.getDisableValue() ? '' : teal}
                          type='submit'
                          onClick={() =>
                            this.getDisableValue()
                              ? {}
                              : this.handleUpdateSurveyClick(survey.Id)
                          }
                        >
                          Update
                        </AppButton>
                        {!survey.IsSurveyClosed ? (
                          <AppButton
                            color={this.getDisableValue() ? '' : mandy}
                            onClick={() =>
                              this.getDisableValue()
                                ? {}
                                : this.handleCloseSurveyDialog(
                                    survey.Id,
                                    survey
                                  )
                            }
                          >
                            Close
                          </AppButton>
                        ) : (
                          <div className='open-close-btn'>
                            <AppButton
                              color={mandy}
                              onClick={() =>
                                this.handleSurveyCommentsDialogue(
                                  survey.Id,
                                  survey
                                )
                              }
                            >
                              Comments
                            </AppButton>
                            <AppButton
                              color={this.getDisableValue() ? '' : mandy}
                              onClick={() =>
                                this.getDisableValue()
                                  ? {}
                                  : this.handleReopenDialog(survey.Id, survey)
                              }
                            >
                              Reopen
                            </AppButton>
                          </div>
                        )}
                        <AppButton
                          color={this.getDisableValue() ? '' : mandy}
                          onClick={() =>
                            this.getDisableValue()
                              ? {}
                              : this.handleOpenSurveyDeleteDialog(
                                  survey.Id,
                                  survey
                                )
                          }
                        >
                          Delete
                        </AppButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <EmptySurveysDiv>No surveys for school</EmptySurveysDiv>
                )}
              </tbody>
            </table>
          </TableStyle>
        </SurveyListMainWrapper>
        {newSurveyDialogOpen ? (
          <NewSurveyForm
            title={'Create New Survey'}
            submitButtonText={'Create Survey'}
            isOpen={newSurveyDialogOpen}
            onClose={this.handleNewSurveyCancel}
            onSubmit={(values) => {
              this.setState({
                StartDate: DateFormat(values.StartDate),
                surveyName: values.TempSurveyName,
                isCreateSurveyConfirmation: true,
                onConfirm: () => {
                  this.handleAddSurveySubmit(values);
                },
              });
            }}
            StartDate={this.state.StartDate}
            surveyName={this.state.TempSurveyName}
            isLoading={this.state.isLoading}
            staffExist={this.state.isStaffExist}
            getTempSurveyName={this.getTempSurveyName}
            EmployeesCount={this.state.employeesCount}
            isAddtionalQuestionFiltersIncluded={
              this.state.hasAddtionalQuestionFiltersIncluded
            }
          />
        ) : !isEmpty(selectedSurvey) ? (
          <NewSurveyForm
            title={'Update Survey'}
            submitButtonText={'Update Survey'}
            isOpen={updateSurveyDialogOpen}
            onClose={this.handleUpdateSurveyCancel}
            onSubmit={this.handleUpdateSurveySubmit}
            surveyName={this.state.TempSurveyName}
            isLoading={false}
            {...selectedSurvey}
            staffExist={this.state.isStaffExist}
            getTempSurveyName={this.getTempSurveyName}
            EmployeesCount={this.state.employeesCount}
            StartDate={this.state.StartDate}
          />
        ) : (
          <div />
        )}
        {commentsDialogueOpen ? (
          <SurveyCommentsForm
            title={'Add Comments'}
            submitButtonText={'Add'}
            isOpen={commentsDialogueOpen}
            onClose={this.handleSurveyCommentCancel}
            handleSubmit={this.handleSurveyCommentsSubmit}
            SurveyId={SurveyId}
            survey={selectedSurvey}
          />
        ) : (
          <div />
        )}
        {SuccessPopup ? (
          <ConfirmPopupForm
            isOpen={SuccessPopup}
            onClose={this.handleCommentsSuccessCancel}
            successPopupMessage={successPopupMessage}
            onOk={this.handleCommentsSuccessCancel}
          />
        ) : (
          <div />
        )}
        {SurveyReopenDialog ? (
          <ConfirmPopupForm
            onClose={this.handleCancelSurveyReopenDialog}
            onOk={() => this.handleSurveyReopen(SurveyId)}
            successPopupMessage={`Do you want to reopen ${selectedSurvey.Name} survey?`}
            isOpen={true}
          />
        ) : (
          <div />
        )}
        {SurveyCloseDialog ? (
          <ConfirmPopupForm
            onClose={this.handleCancelCloseSurveyDialog}
            onOk={() => this.handleCloseSurvey(SurveyId)}
            isOpen={true}
            successPopupMessage={
              'Are you sure you want to close the survey and view the report?'
            }
          />
        ) : (
          <div />
        )}
        {isCreateSurveyConfirmation ? (
          <AppModel
            onClose={this.handleCloseCreateSurveyConfirm}
            open={true}
            titleText={'Confirmation'}
            bodyText={
              <>
                You are creating your survey{' '}
                {<b>{this.state.TempSurveyName}</b>} with a launch date of{' '}
                {<b>{this.state.StartDate}</b>}. Invitations to staff members
                will be sent early that morning.
              </>
            }
            footer={
              <FooterButtons
                submitButtonText={'Confirm'}
                handleSubmit={this.state.onConfirm}
                onClose={this.handleCancelCreateSurveyConfirm}
              />
            }
          />
        ) : (
          <div />
        )}
        {SurveyDeleteDialog ? (
          <ConfirmPopupForm
            onClose={this.handleCancelDeleteSurveyDialog}
            onOk={() => this.handleDeleteSurvey(SurveyId)}
            isOpen={true}
            successPopupMessage={`Are you sure you want to delete the ${selectedSurvey.Name}?`}
          />
        ) : (
          <div />
        )}
      </SurveyListWarpper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(Surveys));
