import React from 'react';
import {
  staffIcons,
  addIcon,
  editIcon,
  deleteIcon,
} from '../../resources/images/index';

const LearnInvitations = () => {
  return (
    <div className='learnmore-positioning learn-invitation'>
      <p>
        From 1st March 2020 there have been two ways you can issue survey
        invitations.
      </p>
      <ol>
        <li className='custom-bullet'>
          Sending a link directly to staff from your own email address (prior to
          1st March 2020 this was the only method in use)
        </li>
        <li className='custom-bullet'>Using the Welbee dashboard</li>
      </ol>
      <p>To unlock all features we recommend you use the Welbee dashboard. </p>
      <p>
        To do this and before creating your survey you will need to upload your
        staff members’ details. Please note that if you have already uploaded
        staff records, for example using Welbee Voice, you will not have to do
        this again and can proceed to create your survey.
      </p>
      <p>
        We only collect the information that we need and click on the links to
        see details of our{' '}
        <a
          href='https://welbee.co.uk/privacy-policy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </a>{' '}
        and how we comply with the{' '}
        <a
          href='https://welbee.co.uk/GDPR/'
          target='_blank'
          rel='noopener noreferrer'
        >
          General Data Protection Regulations
        </a>
        .
      </p>
      <p>
        The information you will need to add includes each staff member’s first
        name, email address and you can also allocate them to groups. Groups are
        not used within the survey, though they are at the centre of Welbee
        Voice, the second product that you can also access.
      </p>
      <p>
        Welbee Voice is available to you at no additional cost and is used to
        obtain more regular and specific staff feedback, including following up
        on the actions you take after analysing your survey results. You can
        access your Voice dashboard by clicking on the word <i>‘Voice’</i> at
        the top of your menu.
      </p>
      <p className='sub-heading'>Uploading your saff records</p>
      <p>
        To upload your staff records use the menu on the left of your dashboard
        and click on <i>Staff Management</i>.
      </p>
      <p>
        Download the CSV template using the link where it says{' '}
        <i>
          ‘Please{' '}
          <span style={{ color: '#53B5E0' }}>download this template</span>’
        </i>
        .
      </p>
      <p>
        First, add your staff members’ first names and email addresses. You
        should be able to copy and paste these from your staff database, for
        example, SIMs. Leave the title for each column in place.
      </p>
      <p>
        To create groups you just need to choose what will work for your school.
        We have deliberately built this flexibly to meet the needs of different
        schools. For example, you can group staff by their department name, year
        group, job role, leadership group, etc. Just use what works best for
        you.
      </p>
      <p>
        You can add more than one group for each staff member to ensure you can
        include each of their peer, job, or other roles, up to a maximum of
        five.
      </p>
      <p>
        Add a group name against each staff member in the columns provided (one
        per column), though you do not need to complete all columns and staff
        can be in as few or as many groups as you need (between 1 and 5).
      </p>
      <p>
        If you are only using a survey to start then you can leave groups blank,
        though we recommend setting this up now if you are or intend to use
        Voice. It is the only administration task needed and once done you have
        full functionality immediately available across the whole of the Welbee
        platform.
      </p>
      <p>
        Once the CSV file is completed, click on the blue upload symbol on the
        right and select the file you have just created or you can drag your
        file across to the box. Your staff records will be added and will now
        show as a list within <i>Staff management</i> and below where you added
        the CSV file.
      </p>
      <p>
        You can use this list to edit or delete existing records and to allocate
        user rights to other staff which will mean they have their own school
        account login.{' '}
      </p>
      <p className='sub-heading'>Allocating user rights</p>
      <p>
        You can assign full, viewing, or admin rights to individual staff
        members, so your senior team and other relevant people can access your
        dashboard.
      </p>
      <ul>
        <li className='custom-bullet'>
          Full rights mean the staff member can upload and edit staff, create
          and close the survey, view all results, and respond to staff comments.
          <p>
            Staff with full access rights can also grant access to other staff
            members. The account originator will receive email notification of
            who has granted access to a staff member and what access level has
            been granted.
          </p>
        </li>
        <li className='custom-bullet'>
          Viewing rights mean staff members can only see results and cannot
          create or close the survey or add and amend staff records.
        </li>
        <li className='custom-bullet'>
          Admin rights mean staff members will only be able to add and edit
          staff records, so you can delegate this to members of your office team
          or any other relevant staff member.
        </li>
      </ul>
      <p>
        These rights can be revoked at any time by the main account holder (the
        person who registered for the account). You can change the main account
        holder by changing the name and email address in your <i>Account</i> tab
        in the menu.
      </p>
      <p>
        When user rights are allocated, these are automatic and the relevant
        staff member will receive an email giving them their login information.
      </p>
      <p className='sub-heading'>Adding new staff records</p>
      <p>
        You can add new staff and groups or add and remove records for existing
        staff when needed.
      </p>

      <p>
        To add new staff, go to <i>Staff management</i> and you can either add
        them manually by entering their details in the boxes shown, or use a new
        CSV file. Adding manually is ideal for one, or a very small number of
        staff and if you are allocating them to existing group(s).
      </p>
      <p>
        If you need to create{' '}
        <img src={addIcon} alt='' style={{ width: '17px' }} /> , remove{' '}
        <img src={deleteIcon} alt='' style={{ width: '17px' }} />, or rename{' '}
        <img src={editIcon} alt='' style={{ width: '17px' }} /> a group you can
        do this using <img src={staffIcons} alt='' /> at the top of the staff
        list.
      </p>
      <p>
        If you want to add multiple staff members and create new groups then you
        should use a new CSV file. Follow the steps above for uploading your CSV
        and these new records will be created.
      </p>
      <p>
        If you are adding new information to an existing staff member then just
        add their email address again in the CSV (the email address is used to
        match records), along with the new information.{' '}
      </p>
      <p>
        If their email address has changed you will need to amend this first
        using the edit button alongside their details.
      </p>
      <p>
        If you need any support with staff records, please email
        <a href='mailto:support@welbee.co.uk'> support@welbee.co.uk</a> or click
        the request assistance button using your <i>Account</i> tab in your
        menu.
      </p>
      <p className='sub-heading'>You are now ready to create your survey.</p>
      <p>
        Select the Dashboard from the left-hand menu. You will see a button
        saying ‘create survey’. Click this and enter the requested details. You
        can accept or adapt the survey name shown, which is your school name and
        the survey number. Select your start date - this is the date that the
        invitations will automatically be sent out to staff members.
      </p>
      <p>
        The survey will close when you click to close it from the dashboard
        (this button will appear after your survey is created).
      </p>
      <p>
        Finally, add the number of staff being invited to participate in the
        survey - this will allow you to see the percentage of staff completing
        it while it remains open and report your participation level after the
        survey is closed.
      </p>

      {/* <p>
        Select ‘Dashboard’ from the left hand menu. You will see a button saying
        create survey. You can accept or adapt the survey name shown, which is
        your school name and the survey number. Select your start date - this is
        the date that the invitations will automatically be sent out to staff
        members.
      </p>
      <p>
        The survey will close when you click to close it from the dashboard
        (this button will appear after your survey is created).
      </p>
      <p>
        Finally add add the number of staff your are inviting to participate in
        the survey - this will allow you to see the number of staff completing
        and report your participation level after the survey is closed.
      </p> */}
      <p className='email-anchor'>
        The first automated invitation email is shown below. This will be sent
        from the email address
        <a
          href='mailto: admin@welbee.co.uk'
          target='_top'
          rel='noopener noreferrer'
        >
          &nbsp;admin@welbee.co.uk&nbsp;
        </a>
        and using an Amazon AWS @amazoneses.com domain. To ensure deliverability
        please ask your IT expert or department to add emails from the address
        and domain to your school’s safe sender list.
      </p>
      <p>
        Without this, it is likely that some staff members’ invitations will be
        delivered to their spam folder. Even after doing this, it is still
        possible an invitation could end up in a spam folder and if any staff
        member tells you they have not received their email, please ask them to
        check.
      </p>
      <span className='invite-email'>
        <p>Dear {'{First Name}'}</p>
        <p>
          Your feedback is important in helping us understand how well we are
          doing in supporting you and your wellbeing.
        </p>
        <p>
          We would like to know what is going well and areas where we might
          focus to make (name of school) a better place to work.
        </p>
        <p>
          Please complete our survey – it should take no more than ten minutes.
          Your responses are completely anonymous, with scores and comments
          aggregated to provide an overall school summary.
        </p>

        <p>Please click here to begin.</p>
      </span>
      <p>
        For those staff members who do not complete the survey, up to two chase
        invitations will follow over the next 9 days, as long as your survey is
        still open.
      </p>

      {/* <p>
        Click on the links to see details of our{' '}
        <a
          style={{ float: 'none', padding: 0 }}
          href='https://welbee.co.uk/privacy-policy/'
          target='_blank'
        >
          Privacy Policy
        </a>{' '}
        and how we comply with the{' '}
        <a
          style={{ float: 'none', padding: 0 }}
          href='https://welbee.co.uk/GDPR/'
          target='_blank'
        >
          General Data Protection Regulations.
        </a>
      </p> */}
      <p>
        Using this approach will also allow you to anonymously respond to
        comments that staff make - see the section on staff comments below.
      </p>
      <p className='sub-heading'>Sending a link directly to staff</p>
      <p>
        If you do not want to send invitations through the Welbee dashboard then
        simply create the survey from the dashboard as above. If no CSV file has
        been uploaded then invitations will not be sent and you will see a link
        highlighted on your screen. Simply copy this and send it to your staff
        members.
      </p>
      <p>
        You will also be able to go to this link directly from the top left of
        your dashboard, where it says ’Survey Link’. You will not be able to
        respond to staff comments and invitation chase emails will not be sent.
      </p>
      <p className='sub-heading'>Running your survey</p>
      <p>Add your school or MAT logo</p>
      <p>
        If you didn’t add your school logo when you first registered your
        account, you can do this at any time. Adding your log means it will show
        in your school dashboard, on your survey and on staff invitations sent
        out.
      </p>
      <p>
        To upload go to your <i>Account</i> tab in the menu and click on{' '}
        <i>upload a logo</i> at the bottom of the first section. Add the file or
        drag it across to the box and your logo will be added to your account.
      </p>
    </div>
  );
};

export default LearnInvitations;
