import React from 'react';

const LearnClosing = () => {
  return (
    <div>
      <p>
        Once your survey is open you will be able to see the number of staff
        participating from within your dashboard.
      </p>
      <p>
      At the agreed time or when you are happy that you have sufficient respondents you can close your survey. 
      Simply click on the close survey button in your dashboard. Your full results and report will then be generated.
      </p>
    </div>
  );
};

export default LearnClosing;
