import ReactGA from 'react-ga';

export const Categories = {
  SurveySubmit: 'SurveySubmit'
};

export const Actions = {
  SurveySubmitButtonClicked: 'Survey Submit Button Clicked'
};

export const LogEvent = (category, action) => {
  ReactGA.event({
    category,
    action
  });
};
