import request from '../request';
import { automationTestAPI } from './urls';


const saveAutomationTest = (model) => {
  return request({
    url: automationTestAPI.saveAutomationTest,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};


const automationTestService = {
 
  saveAutomationTest,
};

export default automationTestService;
