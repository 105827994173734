import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  InputWrapper,
  customStyles,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import {
  schoolStaffService,
  matStaffService,
  storageService,
} from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames } from '../../utils';

const { purple } = colors;

const GrantAccess = (props) => {
  const [matSchool, setMatSchool] = useState('');
  const [defaultLevel, setDefaultLevel] = useState('');
  const { loaderActions } = props;
  const { staffId } = props;
  const [anyAccess, setAnyAccess] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    schoolStaffService
      .CheckStaffAccess(props.staffId)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setAnyAccess(data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (anyAccess)
      setMessage(
        'This staff member already has access to another dashboard - please contact support@welbee.co.uk to proceed.'
      );
    else if (!anyAccess) setMessage('You are about to give access');
  }, [anyAccess]);

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        accessLevel: '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, formProps) => {
        if (!props.isMat) {
          loaderActions.dataLoading(true);
          let model = {
            Id: props.staffId,
            AccessLevel: values.accessLevel,
            IsGranted: true,
          };
          schoolStaffService
            .grantSchoolStaffAccessLevel(model)
            .then((response) => {
              toast.success('Staff login details sent');
              props.handleClose();
              props.getStaff();
              loaderActions.dataLoading(false);
            })
            .catch((error) => {
              loaderActions.dataLoading(false);
            });
        } else {
          const matId = storageService.getItem(WebStorageNames.MatGroupId);
          loaderActions.dataLoading(true);
          let model = {
            Id: props.staffId,
            AccessLevel: 0,
            IsGranted: true,
            MatId: matId,
          };
          matStaffService
            .grantAccessMATLevel(model)
            .then((response) => {
              toast.success('MAT login details sent');
              props.handleClose();
              props.getMatStaff();
              loaderActions.dataLoading(false);
            })
            .catch((error) => {
              loaderActions.dataLoading(false);
            });
        }
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Box className='right-container' spacing={6} sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <AppModel
                onClose={props.handleClose}
                styles={customStyles}
                open={true}
                titleText={'Grant Access'}
                bodyText={
                  <div>
                    <p> {message}</p>
                    {!anyAccess && (
                      <Box
                        component='form'
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete='off'
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name='accessLevel'
                            onChange={(event) => {
                              setFieldValue('accessLevel', event.target.value);
                            }}
                          >
                            {props.isMat && (
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label='Full'
                                checked={true}
                              />
                            )}
                            {!props.isMat && (
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label='Full'
                              />
                            )}
                            {!props.isMat && (
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label='View'
                              />
                            )}
                            {!props.isMat && (
                              <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label='Administration'
                              />
                            )}
                          </RadioGroup>
                        </FormControl>

                        <InputWrapper>
                          <AppButton
                            color={purple}
                            type='button'
                            onClick={handleSubmit}
                          >
                            Grant
                          </AppButton>
                        </InputWrapper>
                      </Box>
                    )}
                  </div>
                }
              />
            </Grid>
          </Box>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(GrantAccess);
