export const config = {
  BASE_URL: 'https://api.welbee.co.uk/api',
  //BASE_URL: 'https://api.welbee.co.uk/api',
  APP_URL: 'https://goog.welbee.co.uk/',
  GOOGLE_ANALYTICS_KEY: 'UA-146282804-1',
  AMPLITUDE_KEY: '2dcd2a2f1518ef6008fdad6c8062ab25',
  HOTJAR: {
    hjid: '1313359',
    hjsv: '6'
  },
};
