import React from 'react';
import { withRouter } from 'react-router-dom';

const AddVoiceDashboardButton = ({ onClick, isFirstConverstaion }) => {
  return (
    <div className='add-voice-button'>
      <button style={{ height: '45px' }} onClick={onClick}>
        New Conversation
      </button>
      {/* {isFirstConverstaion && (
        <span className='add-tool-tip'>
          Click to sent your first staff invitation.
        </span>
      )} */}
    </div>
  );
};

export default withRouter(AddVoiceDashboardButton);
