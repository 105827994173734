import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { theme, gridTheme } from './resources/theme';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that access to theme
  }
});
const loadingMarkup =(
  <div>
    <h3>Loading..,</h3>
  </div>
)
ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
