import React from 'react';
import {
  WrapperStyle,
  DescriptionStyle,
  Title,
  SeparatorStyle,
  ResultsStyle,
  SupportScore,
} from '../../resources/styling/surveyReportStyle';
import ReportScores from './ReportScores';
import RoleWiseReportScores from './RoleWiseReportScores';
import SuggestedPriorityActionsView from './SuggestedPriorityActionsView';
import {
  ViewIndividualAnswers,
  CompareToStaffInOtherSchools,
  ComparativeStrengths,
  AreasForReviewAndPossibleFocus,
  NoWeaknessesTextSchool,
  NoWeaknessesTextHse,
  NoStrengthsTextSchool,
  NoStrengthsTextHse,
} from './Constants';
import {
  limitAverageValue,
  scoreNotAvailableValue,
} from '../../utils/roundScore';
import { useTranslation } from 'react-i18next';

const SupportReport = ({
  reportManagerSupport,
  reportPeerSupport,
  onManagersButtonClick,
  onPeerButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperStyle>
      <div id='support' />
      <h1 className='report-title'>3. Support</h1>
      <p id='supportTitle'>
        This includes the encouragement, sponsorship and resources provided by
        the school, line management and colleagues.
      </p>

      <DescriptionStyle id='supportDescription'>
        <h1 className='report-list-wrapper support-list-wrapper'>
          Description
        </h1>
        <Title className='description-title'>
          The Management Standard is that:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            Staff indicate that they receive adequate information and support
            from their colleagues and {t('linemanagers')}.
          </li>
          <li>
            Systems are in place locally to respond to any individual concerns.
          </li>
        </ul>
        <Title className='description-title'>
          This means that where possible:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            The school has policies and procedures to adequately support staff.
          </li>
          <li>
            Systems are in place to enable and encourage {t('linemanagers')} to
            support their staff.
          </li>
          <li>
            Systems are in place to enable and encourage staff to support their
            colleagues.
          </li>
          <li>
            Staff know what support is available and how and when to access it.
          </li>
          <li>
            Staff know how to access the required resources to do their job.
          </li>
          <li>Staff receive regular and constructive feedback.</li>
        </ul>
      </DescriptionStyle>

      <SeparatorStyle />

      <div id='supportResults'>
        <ResultsStyle className='comp-strength'>
          <h1>
            Results{' '}
            <span className='support-peer'>
              (Support from {t('managersCWManagerforUK')})
            </span>
          </h1>
          <button
            onClick={onManagersButtonClick}
            className='support-individual-answer'
          >
            {ViewIndividualAnswers}
          </button>
          <SupportScore>
            <p
              id='demandsTitle'
              style={{
                'font-size': '20px',
                'font-weight': '700',
                'text-align': 'center',
              }}
            >
              Overall Scores and Deciles
            </p>
            <ReportScores
              Score={reportManagerSupport.Score}
              id='supportScore'
              className='report-score'
              isPaid={true}
            />
            {scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.SupportStaffClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.SupportStaffNonClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.TeachingStaffClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.TeachingStaffNonClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(reportManagerSupport.Score.MiddleLeaderCatAvg)
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(reportManagerSupport.Score.SeniorLeaderCatAvg)
            ) === '-1.00' ? (
              ''
            ) : (
              <>
                <br />
                <p
                  id='demandsTitle'
                  style={{
                    'font-size': '20px',
                    'font-weight': '700',
                    'text-align': 'center',
                  }}
                >
                  Scores by Job Role
                </p>
                <RoleWiseReportScores
                  Score={reportManagerSupport.Score}
                  id='RoleWiseSupportManagerScore'
                  className='report-score'
                />
              </>
            )}
          </SupportScore>

          <ul
            className='report-list-wrapper'
            dangerouslySetInnerHTML={{
              __html: reportManagerSupport.ResultsText,
            }}
          />
          {reportManagerSupport.SchoolStrengths[0] !== NoStrengthsTextSchool ||
          reportManagerSupport.HseStrengths[0] !== NoStrengthsTextHse ? (
            <div>
              <h3 className='undeline-title report-list-wrapper'>
                {ComparativeStrengths}
              </h3>
              <p className='sub-heading report-list-wrapper'>
                {CompareToStaffInOtherSchools}
              </p>
              <ul className='report-list-wrapper'>
                {reportManagerSupport.SchoolStrengths.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
              <p className='sub-heading report-list-wrapper'>
                {t('CompareToStaffInOtherOrganisations')}
              </p>

              <ul className='report-list-wrapper'>
                {reportManagerSupport.HseStrengths.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <br />
          <br />
          <h3 className='undeline-title report-list-wrapper'>
            {AreasForReviewAndPossibleFocus}
          </h3>

          {reportManagerSupport.SchoolWeaknesses[0] !==
            NoWeaknessesTextSchool ||
          reportManagerSupport.HseWeaknesses[0] !== NoWeaknessesTextHse ? (
            <div>
              <div>
                <p className='sub-heading report-list-wrapper'>
                  {CompareToStaffInOtherSchools}
                </p>

                <ul className='report-list-wrapper'>
                  {reportManagerSupport.SchoolWeaknesses.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
              <div>
                <p className='sub-heading report-list-wrapper'>
                  {t('CompareToStaffInOtherOrganisations')}
                </p>

                <ul className='report-list-wrapper'>
                  {reportManagerSupport.HseWeaknesses.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <p className='sub-heading-message-support'>
              {t('AreasForReviewEmptyMessage')}
            </p>
          )}
        </ResultsStyle>
        <div id='supportSuggestions'>
          <SuggestedPriorityActionsView
            SuggestedPriorityActions={
              reportManagerSupport.SuggestedPriorityActions
            }
            heading={`Suggested Priority Actions for ${t(
              'managerCWManagerforUK'
            )} Support`}
          />
        </div>

        <SeparatorStyle />

        <table style={{ 'text-align': 'left' }}>
          <tr>
            <th>
              {/* Peer Support */}
              <ResultsStyle className='comp-strength'>
                <h1 style={{ margin: '0 0 40px 0' }}>
                  Results{' '}
                  <span className='support-peer'>(Support from Peers)</span>
                </h1>
                <button
                  onClick={onPeerButtonClick}
                  className='support-individual-answer'
                >
                  {ViewIndividualAnswers}
                </button>
                <SupportScore>
                  <p
                    id='demandsTitle'
                    style={{
                      'font-size': '20px',
                      'font-weight': '700',
                      'text-align': 'center',
                    }}
                  >
                    Overall Scores and Deciles
                  </p>
                  <ReportScores
                    Score={reportPeerSupport.Score}
                    className='report-score'
                    isPaid={true}
                  />
                  {scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.SupportStaffClassCatAvg
                    )
                  ) === '-1.00' &&
                  scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.SupportStaffNonClassCatAvg
                    )
                  ) === '-1.00' &&
                  scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.TeachingStaffClassCatAvg
                    )
                  ) === '-1.00' &&
                  scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.TeachingStaffNonClassCatAvg
                    )
                  ) === '-1.00' &&
                  scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.MiddleLeaderCatAvg
                    )
                  ) === '-1.00' &&
                  scoreNotAvailableValue(
                    limitAverageValue(
                      reportPeerSupport.Score.SeniorLeaderCatAvg
                    )
                  ) === '-1.00' ? (
                    ''
                  ) : (
                    <>
                      <br />
                      <p
                        id='demandsTitle'
                        style={{
                          'font-size': '20px',
                          'font-weight': '700',
                          'text-align': 'center',
                        }}
                      >
                        Scores by Job Role
                      </p>
                      <RoleWiseReportScores
                        Score={reportPeerSupport.Score}
                        id='RoleWiseSupportPeerScore'
                        className='report-score'
                      />
                    </>
                  )}
                </SupportScore>

                <ul
                  className='report-list-wrapper'
                  style={{ 'font-weight': '500' }}
                  dangerouslySetInnerHTML={{
                    __html: reportPeerSupport.ResultsText,
                  }}
                />
                {reportPeerSupport.SchoolStrengths[0] !==
                  NoStrengthsTextSchool ||
                reportPeerSupport.HseStrengths[0] !== NoStrengthsTextHse ? (
                  <div>
                    <h3 className='undeline-title report-list-wrapper'>
                      {ComparativeStrengths}
                    </h3>
                    <p className='sub-heading report-list-wrapper'>
                      {CompareToStaffInOtherSchools}
                    </p>

                    <ul className='report-list-wrapper'>
                      {reportPeerSupport.SchoolStrengths.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                    <p className='sub-heading report-list-wrapper'>
                      {t('CompareToStaffInOtherOrganisations')}
                    </p>

                    <ul className='report-list-wrapper'>
                      {reportPeerSupport.HseStrengths.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <br />
                <br />
                <h3 className='undeline-title report-list-wrapper'>
                  {AreasForReviewAndPossibleFocus}
                </h3>

                {reportPeerSupport.SchoolWeaknesses[0] !==
                  NoWeaknessesTextSchool ||
                reportPeerSupport.HseWeaknesses[0] !== NoWeaknessesTextHse ? (
                  <div>
                    <div>
                      <p className='sub-heading report-list-wrapper'>
                        {CompareToStaffInOtherSchools}
                      </p>
                      <ul className='report-list-wrapper'>
                        {reportPeerSupport.SchoolWeaknesses.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <p className='sub-heading report-list-wrapper'>
                        {t('CompareToStaffInOtherOrganisations')}
                      </p>
                      <ul className='report-list-wrapper'>
                        {reportPeerSupport.HseWeaknesses.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <p className='sub-heading-message-support'>
                    {t('AreasForReviewEmptyMessage')}
                  </p>
                )}
              </ResultsStyle>
              {/* <SeparatorStyle /> */}
              <div id='supportSuggestions'>
                <SuggestedPriorityActionsView
                  SuggestedPriorityActions={
                    reportPeerSupport.SuggestedPriorityActions
                  }
                  heading='Suggested Priority Actions for Peer Support'
                />
              </div>
            </th>
          </tr>
        </table>
      </div>

      <div id='relation' />
    </WrapperStyle>
  );
};

export default SupportReport;
