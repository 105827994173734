import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import {
  MediaQuery,
  AssistanceWrapper,
  DashboardMainWrapper,
} from '../../resources/styling/appStyle';

const { blazzingBlue, pictonBlue } = colors;

/*** Invoices ***/
export const SchoolSurveyWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  ${MediaQuery}

  .email-link {
    color: ${pictonBlue};
    text-decoration: underline;
    cursor: pointer;
  }
  .rdrInputRange {
    color: #212121;
  }
  .rdrDateRangePickerWrapper {
    border: 1px solid #007a7a;
  }
  .list-action {
    text-align: left !important;
  }
  .table {
    font-size: 14px;
    td,
    th {
      vertical-align: middle;
      padding: 15px;
    }
    tr > th {
      border-bottom: none !important;
    }
    button {
      margin-bottom: 0px;
      padding: 9px 18px;
      margin-right: 5px;
    }
  }
  .comments-search-filters {
    right: 0;
  }
  .test-checkbox {
    display: inline-block;
    width: 30%;
    padding-top: 7px;
  }

  @media only screen and (max-width: 700px) {
    .test-checkbox {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .comments-search-filters {
      width: 100%;
    }
    .floating-label [type='number'] {
      padding: 17px 0px 13px 4px;
    }
    .super-admin-header {
      padding: 0px 20px;
    }
    .test-checkbox {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) {
    .styled-checkbox + label {
      margin-top: 11px;
    }
  }
`;
export const SchoolSurveyInnerMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 216px);
`;
export const SchoolSurveyInner = styled.div`
  display: block;
  margin-top: 30px;
`;
export const PaginationWrapper = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
`;

/*** Super Admin Dashboard ***/
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 100% !important;
  position: initial;
  float: left;
  padding: 30px 50px;
  border-bottom: 1px solid ${blazzingBlue};
  .dashboard-school-name {
    float: right !important;
    margin-bottom: 0px;
  }
`;
export const ExtendedDashboardMainWrapper = styled(DashboardMainWrapper)`
  span {
    float: left;
  }
  .invoice {
    text-align: right !important;
    display: inline-block;
  }
  .invoice button {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-right: 16px;
  }
  @media only screen and (max-width: 700px) {
    .invoice button {
      width: 100%;
    }
  }
`;

const SuperAdminStyle = {
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
  PaginationWrapper,
  ExtendedAssistanceWrapper,
  ExtendedDashboardMainWrapper,
};
export default SuperAdminStyle;
