import React from 'react';
import { SuggestionStyle } from '../../resources/styling/surveyReportStyle';
import { isEmpty } from 'lodash';

function getSuggestions(suggestions) {
  if (suggestions.indexOf('<li>', 0) > -1) {
    return (
      <ol
        className='report-list-wrapper'
        dangerouslySetInnerHTML={{
          __html: !isEmpty(suggestions) ? suggestions : '',
        }}
      />
    );
  } else return <div className='other-suggestions'>{suggestions}</div>;
}

const SuggestedPriorityActionsView = ({
  id,
  heading = 'Suggested Priority Action',
  SuggestedPriorityActions,
}) => {
  return (
    <SuggestionStyle id={id}>
      <h1 className='report-list-wrapper'>{heading}</h1>
      {getSuggestions(SuggestedPriorityActions)}
    </SuggestionStyle>
  );
};

export default SuggestedPriorityActionsView;
