import React from 'react';
import { map } from 'lodash';
import {
  WrapperStyle,
  ResultsStyle,
} from '../../resources/styling/surveyReportStyle';
import { useTranslation } from 'react-i18next';
const NextStepsReport = ({ report, isPaid, routeToAccount }) => {
  const { t } = useTranslation();
  const {
    Demands,
    Control,
    ManagerSupport,
    PeerSupport,
    Relationships,
    Role,
    Change,
    LowestDecileScoreComments,
  } = report;
  return (
    <WrapperStyle id='nextStep'>
      <h1 className='report-title'>{isPaid ? 7 : 8}. Next Steps</h1>
      {isPaid ? (
        <div>
          <ResultsStyle className='next-steps-print'>
            <p>
              This document has been created to support you in reviewing your
              results and planning action. It is not an exhaustive list or
              intended to {t('prioritise')} specific steps, but rather to
              suggest areas where you might want to focus.
            </p>
            <p className='area-celebration-heading'>
              <b>Areas for celebration include:</b>
            </p>
            <ul
              className='report-list-wrapper'
              style={{ padding: '0 30px !Important' }}
            >
              <li
                dangerouslySetInnerHTML={{
                  __html: `Demands - ${Demands.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Control - ${Control.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Manager support - ${ManagerSupport.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Peer support - ${PeerSupport.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Relationships - ${Relationships.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Role - ${Role.Strengths}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Change - ${Change.Strengths}`,
                }}
              />
            </ul>
            <p className='report-list-wrapper'>
              <b>Areas for review, focus and possible action include:</b>
            </p>
            <ul className='report-list-wrapper'>
              <li
                dangerouslySetInnerHTML={{
                  __html: `Demands - ${Demands.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Control - ${Control.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Manager support - ${ManagerSupport.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Peer support - ${PeerSupport.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Relationships - ${Relationships.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Role - ${Role.Weaknesses}`,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: `Change - ${
                    Change.Weaknesses ? Change.Weaknesses : ''
                  }`,
                }}
              />
            </ul>
          </ResultsStyle>
          <ResultsStyle className='next-steps-wrapper next-steps-print'>
            {/*<p className='report-list-wrapper'>*/}
            {/*  Actions that will help with each of these are listed at the end of*/}
            {/*  the relevant section in this report. Reviewing these it is*/}
            {/*  recommended that the top three actions you should take are:*/}
            {/*</p>*/}
            {/*<ol className='report-list-wrapper'>*/}
            {/*  {map(LowestDecileScoreComments, (item, index) => (*/}
            {/*    <li>*/}
            {/*      {item && (*/}
            {/*        <p>*/}
            {/*          <span*/}
            {/*            dangerouslySetInnerHTML={{*/}
            {/*              __html: item,*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </p>*/}
            {/*      )}*/}
            {/*    </li>*/}
            {/*  ))}*/}
            {/*</ol>*/}
            <div className='next-steps-bottomwrapper'>
              <p className='report-list-wrapper'>
                You have much greater knowledge of the context of your school
                and about what is happening now. The above commentary is taken
                from reviewing your results, our general experiences of working
                with schools and our expertise in staff wellbeing and
                performance.
              </p>

              <p className='report-list-wrapper'>
                Given how busy you are, it is suggested that you identify and{' '}
                {t('prioritise')} those actions you believe will have the
                biggest impact, taking into account your own knowledge and we
                will support you in doing this.
              </p>

              <p className='report-list-wrapper'>
                As well as reviewing the results as a leadership team you will
                also want to feedback and review them with staff. This will help
                with ensuring the survey process gives you all the information
                you need and involves staff in {t('prioritising')} and
                implementing action.
              </p>

              <p className='report-list-wrapper'>
                When feeding back and discussing with staff there are three
                areas you might consider.
              </p>

              <p className='report-list-wrapper'>
                1) Celebrate areas that are going well.
              </p>

              <p className='report-list-wrapper'>
                2) Identify areas for quick wins and set out what you will do
                (including how staff should be involved).
              </p>

              <p className='report-list-wrapper'>
                3) Identify areas that need further investigation, including
                asking staff what role they will play – this should not be all
                about what the senior team will do for staff. They also need to
                be involved in improving wellbeing for themselves and others.
              </p>

              <p className='report-list-wrapper'>
                A big part of staff wellbeing relates to the {t('behviour')} of{' '}
                {t('linemanagers')} and research has identified {t('behviours')}{' '}
                that have been proven to prevent and reduce stress in staff. If
                you have an interest in reviewing these competencies, there are
                four self-assessment questionnaires available in the{' '}
                <a href='https://toolkit.welbee.co.uk'>Welbee Toolkit</a>. These
                can be used by all {t('manager')}. We believe it is the actions
                and {t('behviours')} of {t('linemanagers')} that will have the
                most immediate and biggest impact on the wellbeing of staff.
              </p>

              <p className='report-list-wrapper'>
                We are here to support you so please come back with any
                questions.
              </p>

              <p className='report-list-wrapper'>
                You also have a strategy and planning call included with the
                survey to support you further in reviewing your results and
                planning for action as part of your school development plan.
              </p>

              <p className='report-list-wrapper'>
                We look forward to holding this with you.
              </p>
            </div>
          </ResultsStyle>
        </div>
      ) : (
        <div>
          <ResultsStyle className='next-steps-print'>
            <p>
              This report, summarising your results, together with your filtered
              scores and staff comments, will help you plan and take action.
            </p>
            <br />
            <p>
              If you would like to see how your scores compare to other schools
              and the Health and Safety Executive benchmark, receive recommended
              actions, respond to staff comments, and have a results review
              video call to help set out your plans, you can upgrade your
              subscription. See further{' '}
              <a
                href='javascript:void(0);'
                onClick={routeToAccount}
                style={{ color: '#53B5E0', textDecoration: 'none' }}
              >
                details here.
              </a>
            </p>
            <br />
            <p>
              Given how busy you are, it is suggested that you identify and{' '}
              {t('prioritise')} those actions you believe will have the biggest
              impact, taking into account your own knowledge and experience.
            </p>
            <br />
            <p>
              As well as reviewing the results as a leadership team you will
              also want to feedback and review them with staff. This will help
              with ensuring the survey process gives you all the information you
              need and involves staff in finding and {t('prioritising')}{' '}
              solutions.
            </p>
            <br />
            <p className='report-list-wrapper'>
              When feeding back and discussing with staff there are three areas
              you might consider.
            </p>

            <p className='report-list-wrapper'>
              1) Celebrate areas that are going well.
            </p>

            <p className='report-list-wrapper'>
              2) Identify areas for quick wins and set out what you will do
              (including how staff should be involved).
            </p>

            <p className='report-list-wrapper'>
              3) Identify areas that need further investigation, including
              asking staff what role they will play – this should not be all
              about what the senior team will do for staff. They also need to be
              involved in improving wellbeing for themselves and others.
            </p>
            <br />
            <p>
              A big part of staff wellbeing relates to the {t('behviour')}{' '}
              {t('linemanagers')} and research has identified {t('behviours')}{' '}
              that have been proven to prevent and reduce stress in staff. If
              you have an interest in reviewing these competencies, they are
              available in the Welbee Toolkit, which Welbee Pro and Premium
              customers have access to. We believe it is the actions and{' '}
              {t('behviours')} of
              {t('linemanagers')} that will have the most immediate and biggest
              impact.
            </p>
            <br />
          </ResultsStyle>
          <ResultsStyle className='next-steps-wrapper next-steps-print'>
            <div className='next-steps-bottomwrapper'>
              <p className='report-list-wrapper'>
                If you do have any questions or need support, please email
                support@welbee.co.uk, or use the ‘Request Assistance’ button in
                your Account Dashboard.
              </p>
            </div>
          </ResultsStyle>
        </div>
      )}
    </WrapperStyle>
  );
};

export default NextStepsReport;
