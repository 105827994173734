import React from 'react';

const MatAdditionalQuestionDropDownOptions = ({ options, onChange }) => {
  return (
    <div className='options action-plan-options'>
      {options &&
        options.map(option => {
          return (
            option.value !== -1 && (
              <span key={option.value} onClick={() => onChange(option)}>
                {option.label}
              </span>
            )
          );
        })}
    </div>
  );
};

export default MatAdditionalQuestionDropDownOptions;
