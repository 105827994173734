import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../resources/styling';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FloatingTextareaLabel } from '../../components';
import { colors } from '../../resources/theme/colors';
import 'react-day-picker/lib/style.css';
import { AppModel } from '../../components';
import Grid from '@mui/material/Grid';
import { loaderAction } from '../../store/loaderStore';
import { actionPlanService, additionalQuestionService } from '../../services';
import { actionPlanActions } from '../../store/actionPlanStore';
import {
  NewSurveyWrapper,
  NewSurveyInnerMain,
} from '../../resources/styling/appStyle';
import makeStyles from '@mui/styles/makeStyles';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';

import {
  AdditionalQuestionsFormWrapper,
  InputWrapper,
  ExtendedFieldWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  FooterButtonsWrapper,
  ErrorWrapper,
  RadioGroupWrapper,
} from './AdditionalQuestionStyle';

const { mandy, outrageousOrange } = colors;

const selectBoxStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

class AdditionalQuestionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formTitle: props.isEdit ? 'Update Question' : 'Create Question',
      isListOpen: false,
      isSignListOpen: true,
      isEdit: props.isEdit,
      isEmpty: true,
      isLikert: props.isEdit ? props.editRecord.Type === 0 : true,
      type: props.isEdit ? props.editRecord.Type : 0,
      signType: props.isEdit ? props.editRecord.SignType : 1,
      likertFormat: props.isEdit ? props.editRecord.LikertFormat : 0,
      likertData: [],
      isCustomLikert: false,
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: '',
      isValid: false,
      maxLikertId: 0,
      likertId: props.isEdit ? props.editRecord.likertId : 1,
    };
  }

  componentDidMount() {
    this.getLikerts();
  }

  getLikerts() {
    let likertData = [];
    additionalQuestionService
      .getLikertsBySchoolId(this.props.schoolId)
      .then((res) => {
        const maxLikertId = res.data.reduce((prev, current) =>
          prev.Id > current.Id ? prev : current
        );
        console.log('maxLikertId', maxLikertId);
        res.data.map((item) => {
          const {
            Id,
            LikertOption1,
            LikertOption2,
            LikertOption3,
            LikertOption4,
            LikertOption5,
          } = item;
          likertData.push({
            id: Id,
            opts: [
              LikertOption1,
              LikertOption2,
              LikertOption3,
              LikertOption4,
              LikertOption5,
            ],
            isUpdate: Id > 4 ? true : false,
            isDelete: Id > 4 ? true : false,
          });
        });
        console.log('likertData', likertData);
        this.setState({
          likertData: likertData,
          maxLikertId: maxLikertId.Id,
        });
      });
  }

  checkIfValid = (e) => {
    setTimeout(() => {
      const { isCustomLikert, option1, option2, option3, option4, option5 } =
        this.state;
      this.setState({
        isValid:
          isCustomLikert && option1 && option2 && option3 && option4 && option5,
      });
    }, 0);
  };

  render() {
    const { isOpen, onClose, handleSubmit, editRecord, surveyId, schoolId } =
      this.props;
    const {
      formTitle,
      isEdit,
      isLikert,
      type = isEdit ? editRecord.Type : 0,
      signType = isEdit ? editRecord.SignType : 1,
      likertFormat = isEdit ? editRecord.LikertType : 0,
      likertData,
      likertId = isEdit ? editRecord.LikertId : 1,
      isCustomLikert,
      isValid,
      maxLikertId,
    } = this.state;
    const classes = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          QuestionNumber: isEdit ? editRecord.QuestionNumber : 0,
          QuestionText: isEdit ? editRecord.QuestionText : '',
          Type: isEdit ? editRecord.Type : 0,
          Category: isEdit ? editRecord.Category : 10,
          IsIncluded: isEdit ? editRecord.IsIncluded : true,
          IsArchive: false,
          SurveyId: surveyId,
          SchoolId: schoolId,
          SignType: isEdit ? editRecord.SignType : 1,
          LikertFormat: isEdit ? editRecord.LikertFormat : 0,
          IsCustomLikert: false,
          Likert: {
            Id: isEdit ? editRecord.LikertId : 0,
            LikertOption1: '',
            LikertOption2: '',
            LikertOption3: '',
            LikertOption4: '',
            LikertOption5: '',
            schoolId: schoolId,
          },
        }}
        onSubmit={(values, actions) => {
          const { LikertId } = values;
          values.LikertFormat = LikertId > 4 ? 5 : LikertId;
          const { isCustomLikert, isEdit } = this.state;
          values.IsCustomLikert = isCustomLikert;
          if (!isEdit) values.Id = 0;
          actions.setSubmitting(true);
          handleSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          IsCustomLikert: Yup.boolean(),
          QuestionText: Yup.string().trim().required('Question is required.'),
          Type: Yup.string().trim().required('Please select a valid Type'),
          Likert: Yup.object().when('IsCustomLikert', {
            is: true,
            then: Yup.object().shape({
              LikertOption1: Yup.string()
                .trim()
                .required('Likert Option 1 required.'),
              LikertOption2: Yup.string()
                .trim()
                .required('Likert Option 2 required.'),
              LikertOption3: Yup.string()
                .trim()
                .required('Likert Option 3 required.'),
              LikertOption4: Yup.string()
                .trim()
                .required('Likert Option 4 required.'),
              LikertOption5: Yup.string()
                .trim()
                .required('Likert Option 5 required.'),
            }),
          }),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className='styles_modal__gNwvD'>
            <AppModel
              open={isOpen}
              onClose={onClose}
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <NewSurveyInnerMain>
                          <NewSurveyInner>
                            <AdditionalQuestionsFormWrapper>
                              <h2>Add Question</h2>
                              <div className='action-plan-instructions'>
                                Type your question in the box below and choose
                                the question type.
                              </div>

                              <InputWrapper>
                                <ExtendedFieldWrapper>
                                  <FloatingTextareaLabel
                                    label='Question Text'
                                    name='QuestionText'
                                    value={values.QuestionText}
                                    onChange={handleChange}
                                    type='text'
                                    textAreaLabel='Question'
                                  />
                                  <ErrorWrapper>
                                    {touched.QuestionText &&
                                      errors.QuestionText &&
                                      errors.QuestionText}
                                  </ErrorWrapper>
                                </ExtendedFieldWrapper>

                                <Grid container spacing={2}>
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <InputLabel htmlFor='outlined-age-native-simple'>
                                        Question Type
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        native
                                        value={type}
                                        onChange={(event) => {
                                          const questionType = Number(
                                            event.target.value
                                          );
                                          setFieldValue('Type', questionType);
                                          this.setState({
                                            isLikert: questionType === 0,
                                            type: questionType,
                                            isCustomLikert:
                                              questionType === 1
                                                ? false
                                                : isCustomLikert,
                                            likertId:
                                              questionType === 1 &&
                                              likertId > 4 &&
                                              editRecord
                                                ? editRecord.likertId
                                                : likertId,
                                          });
                                        }}
                                        label='Question Type'
                                        inputProps={{
                                          name: 'QuestionType',
                                          id: 'outlined-age-native-simple',
                                        }}
                                      >
                                        <option value={0}>
                                          Likert Question
                                        </option>
                                        <option value={1}>Open Question</option>
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  {isLikert ? ( 
                                  <Grid item lg={6} sm={12} md={6} xs={12}>
                                    <FormControl
                                      variant='outlined'
                                      className={classes.formControl}
                                      fullWidth
                                    >
                                      <InputLabel htmlFor='outlined-age-native-simple'>
                                        Question Nature
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        native
                                        value={signType}
                                        onChange={(event) => {
                                          var questionNature = Number(
                                            event.target.value
                                          );
                                          setFieldValue(
                                            'SignType',
                                            questionNature
                                          );
                                          this.setState({
                                            signType: questionNature,
                                          });
                                        }}
                                        label='Question Sign Type'
                                        inputProps={{
                                          name: 'QuestionSignType',
                                          id: 'outlined-age-native-simple',
                                        }}
                                      >
                                        <option value={1}>
                                          Positive 1 - 5
                                        </option>
                                        <option value={0}>
                                          Negative 5 - 1
                                        </option>
                                      </Select>
                                    </FormControl>
                                  </Grid>    
                                  ) : null}   
                                  {isLikert ? (                            
                                  <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    md={12}
                                    xs={12}
                                    className='radio-group'
                                    mt='10px'
                                  >
                                    <RadioGroupWrapper>
                                      <FormControl component='fieldset'>
                                        <FormLabel>Answer Format</FormLabel>
                                        <RadioGroup
                                          aria-label='likertScale'
                                          name='likertScale'
                                          value={likertId}
                                          onChange={(event) => {
                                            var questionFormat = Number(
                                              event.target.value
                                            );

                                            const isCustLikert =
                                              questionFormat ===
                                              maxLikertId + 1;

                                            this.setState({
                                              likertId: questionFormat,
                                              isCustomLikert: isCustLikert,
                                            });

                                            const fieldValue =
                                              isEdit && !isCustLikert
                                                ? questionFormat
                                                : !isCustLikert
                                                ? questionFormat
                                                : 0;

                                            setFieldValue(
                                              'Likert.Id',
                                              fieldValue
                                            );

                                            setFieldValue(
                                              'IsCustomLikert',
                                              isCustLikert
                                            );
                                          }}
                                        >
                                          {Object.entries(likertData).map(
                                            ([key, value]) => (
                                              <FormControlLabel
                                                value={value.id}
                                                control={<Radio />}
                                                label={value.opts.join(', ')}
                                              />
                                            )
                                          )}
                                          {isLikert && (
                                            <FormControlLabel
                                              value={maxLikertId + 1}
                                              control={<Radio />}
                                              label={'Custom'}
                                            />
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </RadioGroupWrapper>
                                  </Grid>
                                   ) : null} 
                                </Grid>
                                {/* Custom likert */}
                                {isLikert ? ( 
                                isCustomLikert && (
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ marginTop: '0px' }}
                                  >
                                    <Grid item lg={4} sm={12} md={4} xs={12}>
                                      <ExtendedFieldWrapper>
                                        <FormControl
                                          variant='outlined'
                                          className={classes.formControl}
                                          fullWidth
                                        >
                                          <TextField
                                            value={values.Option1}
                                            name='Likert.LikertOption1'
                                            id='likert-option'
                                            label='Likert Option 1'
                                            onChange={(e) => {
                                              var option = e.target.value;
                                              setFieldValue(
                                                'Likert.LikertOption1',
                                                option
                                              );
                                              this.setState({
                                                option1: option,
                                              });
                                              this.checkIfValid();
                                            }}
                                            helperText={
                                              touched.Likert?.LikertOption1
                                                ? errors.Likert?.LikertOption1
                                                : ''
                                            }
                                            error={
                                              touched.Likert?.LikertOption1 &&
                                              Boolean(
                                                errors.Likert?.LikertOption1
                                              )
                                            }
                                          />
                                        </FormControl>
                                      </ExtendedFieldWrapper>
                                    </Grid>
                                    <Grid item lg={4} sm={12} md={4} xs={12}>
                                      <ExtendedFieldWrapper>
                                        <FormControl
                                          variant='outlined'
                                          className={classes.formControl}
                                          fullWidth
                                        >
                                          <TextField
                                            value={values.Option2}
                                            name='Likert.LikertOption2'
                                            id='likert-option'
                                            label='Likert Option 2'
                                            onChange={(e) => {
                                              var option = e.target.value;
                                              setFieldValue(
                                                'Likert.LikertOption2',
                                                option
                                              );
                                              this.setState({
                                                option2: option,
                                              });
                                              this.checkIfValid();
                                            }}
                                            helperText={
                                              touched.Likert?.LikertOption2
                                                ? errors.Likert?.LikertOption2
                                                : ''
                                            }
                                            error={
                                              touched.Likert?.LikertOption2 &&
                                              Boolean(
                                                errors.Likert?.LikertOption2
                                              )
                                            }
                                          />
                                        </FormControl>
                                      </ExtendedFieldWrapper>
                                    </Grid>
                                    <Grid item lg={4} sm={12} md={4} xs={12}>
                                      <ExtendedFieldWrapper>
                                        <FormControl
                                          variant='outlined'
                                          className={classes.formControl}
                                          fullWidth
                                        >
                                          <TextField
                                            value={values.Option3}
                                            name='Likert.LikertOption3'
                                            id='likert-option'
                                            label='Likert Option 3'
                                            onChange={(e) => {
                                              var option = e.target.value;
                                              setFieldValue(
                                                'Likert.LikertOption3',
                                                option
                                              );
                                              this.setState({
                                                option3: option,
                                              });
                                              this.checkIfValid();
                                            }}
                                            helperText={
                                              touched.Likert?.LikertOption3
                                                ? errors.Likert?.LikertOption3
                                                : ''
                                            }
                                            error={
                                              touched.Likert?.LikertOption3 &&
                                              Boolean(
                                                errors.Likert?.LikertOption3
                                              )
                                            }
                                          />
                                        </FormControl>
                                      </ExtendedFieldWrapper>
                                    </Grid>
                                    <Grid item lg={4} sm={12} md={4} xs={12}>
                                      <ExtendedFieldWrapper>
                                        <FormControl
                                          variant='outlined'
                                          className={classes.formControl}
                                          fullWidth
                                        >
                                          <TextField
                                            value={values.Option4}
                                            name='Likert.LikertOption4'
                                            id='likert-option'
                                            label='Likert Option 4'
                                            onChange={(e) => {
                                              var option = e.target.value;
                                              setFieldValue(
                                                'Likert.LikertOption4',
                                                option
                                              );
                                              this.setState({
                                                option4: option,
                                              });
                                              this.checkIfValid();
                                            }}
                                            helperText={
                                              touched.Likert?.LikertOption4
                                                ? errors.Likert?.LikertOption4
                                                : ''
                                            }
                                            error={
                                              touched.Likert?.LikertOption4 &&
                                              Boolean(
                                                errors.Likert?.LikertOption4
                                              )
                                            }
                                          />
                                        </FormControl>
                                      </ExtendedFieldWrapper>
                                    </Grid>
                                    <Grid item lg={4} sm={12} md={4} xs={12}>
                                      <ExtendedFieldWrapper>
                                        <FormControl
                                          variant='outlined'
                                          className={classes.formControl}
                                          fullWidth
                                        >
                                          <TextField
                                            value={values.Option5}
                                            name='Likert.LikertOption5'
                                            id='likert-option'
                                            label='Likert Option 5'
                                            onChange={(e) => {
                                              var option = e.target.value;
                                              setFieldValue(
                                                'Likert.LikertOption5',
                                                option
                                              );
                                              this.setState({
                                                option5: option,
                                              });
                                              this.checkIfValid();
                                            }}
                                            helperText={
                                              touched.Likert?.LikertOption5
                                                ? errors.Likert?.LikertOption5
                                                : ''
                                            }
                                            error={
                                              touched.Likert?.LikertOption5 &&
                                              Boolean(
                                                errors.Likert?.LikertOption5
                                              )
                                            }
                                          />
                                        </FormControl>
                                      </ExtendedFieldWrapper>
                                    </Grid>
                                  </Grid>
                                )
                                ) : null} 
                                {/*  */}
                              </InputWrapper>
                            </AdditionalQuestionsFormWrapper>
                          </NewSurveyInner>
                        </NewSurveyInnerMain>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={
                <FooterButtonsWrapper>
                  <br />
                  <AppButton
                    color={outrageousOrange}
                    type='button'
                    onClick={handleSubmit}
                  >
                    {isEdit ? 'Update' : 'Add'}
                  </AppButton>
                </FooterButtonsWrapper>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionPlanService, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AdditionalQuestionForm);
