import React, { useState, useEffect } from 'react';
import { AppModel } from '../../components';
import { AppButton, FooterButtonsWrapper } from '../../resources/styling';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils/Constants';
import { routesObj } from '../../routes';
import { SurveyLinkWrapper } from './DashboardStyle';

const { purple, outrageousOrange } = colors;
   
const SurveyLink = (props) => {
  const [btnText, setBtnText] = useState('Copy');

  useEffect(() => {
    setBtnText('Copy');
  }, [props.onClose]);

  const copyToClipboard = (content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
  let protocol = window.location.host === 'app.welbee.co.uk' ? 'https://' : 'http://'

  let url =
    protocol +
    window.location.host +
    routesObj.SurveyWelcome.path +
    '?token=' +
    surveyToken;

  return (
    <AppModel      
      open={props.isOpen}
      onClose={props.onClose}
      bodyText={
        <Grid container className='form-inner-wrapper'>
          
          <Grid item
            lg={12}
            sm={12}
            md={12}
            xs={12}
            className='form-column-wrapper'
          >
            <h2>Survey Created</h2>
            <div>
              Please use the survey link below for staff who are not present
              in the staff management list.
              <br />
              You will not be able to respond to comments from staff that use
              it.
            </div>
            <div>
              {' '}
              <br />
            </div>

            <SurveyLinkWrapper>
              <div className='mainWrapper'>
                <div className='survey-lnk'>{url}</div>
                <a
                  id='survey-link-to-copy'
                  className='copy_link'
                  color={purple}
                  onClick={() => {
                    copyToClipboard(url);
                    setBtnText('Copied!');
                  }}
                >
                  {btnText}
                </a>
              </div>
            </SurveyLinkWrapper>
          </Grid>
         
        </Grid>
      }
      footer={
        <FooterButtonsWrapper>
          <AppButton
            color={outrageousOrange}
            type='button'
            onClick={props.onClose}            
          >
            Done
          </AppButton>
        </FooterButtonsWrapper>
      }
    />
  );
};

export default SurveyLink;
