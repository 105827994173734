import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import CategoryProgressGraph from './CategoryProgressGraph';
import { MultiSurveyStandard } from '../../resources/styling/appStyle';
import AssociatedActions from './AssociatedActions';
import { ExtendedCalculationsStyle } from './DashboardStyle';
const { mineShaft } = colors;



class MultiSurveyManagementStandard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            individualResults,
            catogorySurveyAverage,
            ProgressGraphData,
            IsProgressGraphHidden, isAllowed = false
        } = this.props;
        return (
            <MultiSurveyStandard
                className='inner-sub-container'
                value={`${catogorySurveyAverage != null
                        ? catogorySurveyAverage.CategoryColorName
                        : mineShaft
                    }`}
            >
                <span className='header-text'>
                    {individualResults !== null ? individualResults.CategoryTitle : ''}
                </span>
                <ExtendedCalculationsStyle>
                    <ul className='all-score'>

                        <li>
                            {catogorySurveyAverage !== null
                                ? catogorySurveyAverage.OverallCategoryAverage
                                : ''}
                            <span>Overall <br /> Score</span>
                        </li>
                        {(catogorySurveyAverage !== null && catogorySurveyAverage.Category === 8) ? null : (
                        <li>
                            {isAllowed ?(catogorySurveyAverage !== null ? catogorySurveyAverage.SchoolDecilesCategoryAverage 
                            : ''):'---'}
                            <span>School <br /> Decile</span>
                        </li>
                        )}
                        {(catogorySurveyAverage !== null && catogorySurveyAverage.Category === 8) ? null : (
                        <li>
                            {isAllowed ? (catogorySurveyAverage !== null ? catogorySurveyAverage.HseDecilesCategoryAverage
                            : '') : '---'}
                            <span>HSE <br />Decile</span>
                        </li>
                        )}

                        {isAllowed ? <li>
                            {catogorySurveyAverage !== null
                                ? catogorySurveyAverage.SupportStaffCategoryAverage
                                : ''}
                            <span>Support Staff <br /> Score</span>
                        </li> : ''}
                        {isAllowed ? <li>
                            {catogorySurveyAverage !== null
                                ? catogorySurveyAverage.TeachingStaffCategoryAverage
                                : ''}
                            <span>Teaching Staff <br /> Score</span>
                        </li> : ''}
                    </ul>
                </ExtendedCalculationsStyle>
                {!isAllowed ? (<b className='header-text' style={{ paddingBottom: '20px' }}>You can display your comparative scores against the School and Health and Safety Executive benchmarks by upgrading your account. The table of scores below will also show how your staff scores compare with other schools.
                </b>):(<></>)}
                {IsProgressGraphHidden == false ? (
                    <div className='survey-progress-action-wrapper'>
                        <div className='progress-chart '>
                            <h3>Progress</h3>
                            {ProgressGraphData != null ? (
                                <CategoryProgressGraph
                                    progressGraphData={ProgressGraphData}
                                    colorValue={`${catogorySurveyAverage != null
                                            ? catogorySurveyAverage.CategoryColorName
                                            : mineShaft
                                        }`}
                                />
                            ) : null}
                        </div>
                        <AssociatedActions />
                    </div>) : <div></div>}
            </MultiSurveyStandard>
        );
    }
}

export default withRouter(MultiSurveyManagementStandard);
