import React, { Component } from 'react';
import { isEmpty, map } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { surveyResultService, storageService } from '../../services';
import { AdminHeader } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { limitAverageValue } from '../../utils/roundScore';
import { SubContainer, MainContainer, GridMainHeading, SurveyResultTables, TableStyle } from '../../resources/styling/appStyle';

class SurveyResult extends Component {
  constructor(props) {
    super(props);
    this.SURVEY_INITIAL_STATE = {
      QuestionAverage: [],
      CategoryAverage: [],
      OverAllAverage: {
        NoOfSurveysCompleted: '',
        NoOfSurveysSupportStaff: '',
        NoOfTeachingStaff: '',
        OverallSurveyAverage: ''
      }
    };
    this.state = {
      surveyResult: this.SURVEY_INITIAL_STATE,
      error: 'Loading...'
    };
  }

  componentWillMount() {
    if (!isEmpty(window.location.search))
      storageService.setItem('surveyToken', window.location.search);
  }

  componentDidMount() {
    const surveyToken = window.location.search;
    const { action } = this.props;
    action.dataLoading(true);
    surveyResultService
      .getSurveyResult(surveyToken)
      .then(response => {
        const surveyResult = response.data
          ? response.data
          : this.SURVEY_INITIAL_STATE;
        this.setState({
          surveyResult,
          error: response.message
        });
        action.dataLoading(false);
      })
      .catch(response => {
        action.dataLoading(false);
        return response;
      });
  }

  render() {
    const { surveyResult, error } = this.state;
    const {
      QuestionAverage,
      CategoryAverage,
      OverAllAverage
    } = surveyResult;
    return (
      <div>
        <AdminHeader />
        <div>
          <MainContainer>
            <GridMainHeading>
              <h2>How many users picked each answer</h2>
            </GridMainHeading>
            <SubContainer>
              <TableStyle style={{marginBottom: '25px'}}>
                <table>
                    <thead>
                    <tr data-test-id='questionsAverage'>
                        <td>Question no.</td>
                        <td>Never = 1</td>
                        <td>Seldom = 2</td>
                        <td>Sometimes = 3</td>
                        <td>Often = 4</td>
                        <td>Always = 5</td>
                        <td>Question average </td>
                        <td>Average for support staff</td>
                        <td>Average for teaching staff </td>
                        <td>School decile</td>
                        <td>Hse decile </td>
                    </tr>
                    </thead>
                    {QuestionAverage.length > 0 ? (
                    map(QuestionAverage, (questionSurvey, index) => (
                        <tbody key={index}>
                        <tr>
                            <td>{`Question ${index + 1}`}</td>
                            <td>{questionSurvey.Never}</td>
                            <td>{questionSurvey.Seldom}</td>
                            <td>{questionSurvey.Sometimes}</td>
                            <td>{questionSurvey.Often}</td>
                            <td>{questionSurvey.Always}</td>
                            <td>
                            {limitAverageValue(questionSurvey.QuestionAverage)}
                            </td>
                            <td>
                            {limitAverageValue(
                                questionSurvey.SupportStaffAverage
                            )}
                            </td>
                            <td>
                            {limitAverageValue(
                                questionSurvey.TeachingStaffAverage
                            )}
                            </td>
                            <td>{questionSurvey.SchoolDecilesAverage}</td>
                            <td>{questionSurvey.HseDecilesAverage}</td>
                        </tr>
                        </tbody>
                    ))
                    ) : (
                    <tbody>
                        <tr>
                        <td>{error}</td>
                        </tr>
                    </tbody>
                    )}
                </table>
              </TableStyle>
              <TableStyle style={{marginBottom: '25px'}}>
                <table>
                  <thead>
                    <tr data-test-id='catagoryAverage'>
                      <td>Category</td>
                      <td>Overall Category average</td>
                      <td>Support staff category average </td>
                      <td>Teaching staff category average </td>
                      <td>School decile category average </td>
                      <td>Hse decile category average </td>
                    </tr>
                  </thead>
                  {CategoryAverage.length > 0 ? (
                    map(CategoryAverage, (categorySurvey, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>{categorySurvey.CategoryName}</td>
                          <td>
                            {limitAverageValue(
                              categorySurvey.OverallCategoryAverage
                            )}
                          </td>
                          <td>
                            {limitAverageValue(
                              categorySurvey.SupportStaffCategoryAverage
                            )}
                          </td>
                          <td>
                            {limitAverageValue(
                              categorySurvey.TeachingStaffCategoryAverage
                            )}
                          </td>
                          <td>{categorySurvey.SchoolDecilesCategoryAverage}</td>
                          <td>{categorySurvey.HseDecilesCategoryAverage}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tbody>
                      <tr>
                        <td>{error}</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </TableStyle>
              <TableStyle style={{marginBottom: '25px'}}>
                <table>
                  <thead>
                    <tr data-test-id='surveyCompletedAverage'>
                      <td>No. of surveys completed</td>
                      <td>No. of Support staff</td>
                      <td>No. of Teaching staff</td>
                      <td>Overall Survey Average</td>
                    </tr>
                  </thead>
                  {OverAllAverage.NoOfSurveysCompleted !== '' ? (
                    <tbody>
                      <tr>
                        <td>{OverAllAverage.NoOfSurveysCompleted}</td>
                        <td>{OverAllAverage.NoOfSurveysSupportStaff}</td>
                        <td>{OverAllAverage.NoOfTeachingStaff}</td>
                        <td>
                          {limitAverageValue(
                            OverAllAverage.OverallSurveyAverage
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>{error}</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </TableStyle>
            </SubContainer>
          </MainContainer>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(loaderAction, dispatch)
});
export default connect(null, mapDispatchToProps)(SurveyResult);
