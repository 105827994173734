import React, { useState, useEffect } from 'react';
import { AppModel } from '../../components';
import { AppButton, FooterButtonsWrapper } from '../../resources/styling';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { RegistrationLinkLinkWrapper } from './SchoolStyle';

const { outrageousOrange, purple } = colors;

const RegistrationLink = (props) => {
    const [btnText, setBtnText] = useState('Copy');

    useEffect(() => {
        setBtnText('Copy');
    }, [props.onClose]);

    const copyToClipboard = (content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return (
        <AppModel            
            open={props.isOpen}
            onClose={props.onClose}
            bodyText={
                <Grid container className='form-inner-wrapper'>
                    
                    <Grid item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        className='form-column-wrapper'
                    >
                        <h2>{props.title}</h2>
                        <div>
                            {props.discription}
                        </div>
                        <div>
                            {' '}
                            <br />
                        </div>

                        <RegistrationLinkLinkWrapper>
                            <div className='mainWrapper'>
                                <div className='link'>{props.url}</div>
                                <a
                                    id='registationLinkCopy'
                                    className='copy_link'
                                    color={purple}
                                    onClick={() => {
                                        copyToClipboard(props.url);
                                        setBtnText('Copied!');
                                    }}
                                    rel="noopener noreferrer">
                                    {btnText}
                                </a>
                            </div>
                        </RegistrationLinkLinkWrapper>
                    </Grid>
                    
                </Grid>
            }
            footer={
                <FooterButtonsWrapper>
                    <AppButton
                        color={outrageousOrange}
                        type='button'
                        onClick={props.onClose}
                        style={{
                            float: 'right',
                        }}
                    >
                        Done
                    </AppButton>
                </FooterButtonsWrapper>
            }
        />
    );
};
export default RegistrationLink;