import React, { useState, useEffect } from 'react';
import { LeftMenu, MainHeader, SurveyDropDown } from '../../components';
import { routesObj } from '../../routes';
import { storageService, surveyService } from '../../services';
import CloseIcon from '@mui/icons-material/Close';
import {
  WebStorageNames,
  QueryStringTokens,
  Roles,
} from '../../utils/Constants';
import { Carousel } from 'react-responsive-carousel';
import ProgressGraph from './ProgressGraph';
import DashboardWelcomePopup from './DashboardWelcomePopup';
import GraphPopup from './GraphPopup';
import {
  AssistanceWrapper,
  DashboardCommentsWrapper,
} from '../../resources/styling/appStyle';
import { ActiveSurvey, UpcomingTasks, DashboardComments } from './index';
import ScoreTiles from './ScoreTiles';
import Comments from './Comments';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import {
  setTextLengthLimit,
  getQueryStringVal,
  getTransformedSurveyRecordList,
} from '../../utils/CommonFunctions';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import Grid from '@mui/material/Grid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ExtendedDashboardMainWrapper } from './DashboardStyle';
import { EnlargePopup } from './../../resources/styling/appStyle';
import moment from 'moment';

export default function MultiSurveyDashboard(props) {
  const [isShowing, setIsShowing] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showUpgradeMsg, setShowUpgradeMsg] = useState('');
  const [isActionAllowed, setIsActionAllowed] = useState(true);
  const [isQuestionBankAvailable, setIsQuestionBankAvailable] = useState(false);
  const [latestSurveyOption, setLatestSurveyOption] = useState({
    value: '',
    label: 'Latest survey',
  });

  const {
    schoolName,
    activeSurveyDetails,
    strongestScore,
    weakestScore,
    comments,
    ProgressGraphData,
    actionResults,
    highestScore,
    hasAdditionalQuestions,
  } = props.data;
  const isHeatMap =
    activeSurveyDetails !== null && activeSurveyDetails !== ''
      ? activeSurveyDetails.IsHeatMap
      : false;
  const { navigationActions, history, newCommentCount } = props.actions;
  const { isFirstLogin, isOpen, onClose, onOk, onSurveyChange, surveyOptions } =
    props;
  const clickHandler = (e) => {
    navigationActions.activeItemAction({
      item: 'comments',
    });
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      history.push(
        routesObj.SurveyComments.path +
          '?token=' +
          surveyToken +
          '&schoolToken=' +
          schoolToken
      );
    } else
      history.push(routesObj.SurveyComments.path + '?token=' + surveyToken);
  };

  useEffect(() => {
    const res =
      comments &&
      comments
        .flat()
        .some(
          (x) =>
            moment(x.UpdatedDate).format(moment.HTML5_FMT.DATE) >=
            moment().subtract(1, 'months').format(moment.HTML5_FMT.DATE)
        );
    setShowComments(res);
  }, [comments]);
  const showGraph = () => {
    setIsShowing(true);
  };

  const closePopup = () => {
    setIsShowing(false);
  };

  useEffect(() => {
    IsActionAllowed(RestrictedActions.DashboardDecile)
      .then((response) => {
        setIsActionAllowed(response.data);
        if (response.data === false)
          setShowUpgradeMsg(
            'You can display your comparative scores against the School and Health and Safety Executive benchmarks by upgrading your account.'
          );
      })
      .catch();
    IsActionAllowed(RestrictedActions.QuestionBank)
      .then((response) => setIsQuestionBankAvailable(response.data))
      .catch();
  });

  const handleSurveyValueChange = (latestSurveyOption) => {
    const role = storageService.getItem(WebStorageNames.Role);
    setLatestSurveyOption(latestSurveyOption);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    // if (role === Roles.GroupDirector || schoolToken !== null) {
    //   window.location.search =
    //     '?token=' + latestSurveyOption.value + '&schoolToken=' + schoolToken;
    // } else {
    //   window.location.search = '?token=' + latestSurveyOption.value;
    // }

    //getSurveyCommentsData(latestSurveyOption.value);
    onSurveyChange(latestSurveyOption.value);
  };

  return (
    <div>
      {isShowing ? (
        <GraphPopup>
          <div className='graph-container' id='large-graph'>
            <div className='redCrossButtom'>
              <CloseIcon className='closeIcon' onClick={closePopup} />
            </div>
            <EnlargePopup>
              <h2 style={{ marginTop: '0px' }}>Progress</h2>{' '}
              <ProgressGraph
                progressGraphData={ProgressGraphData}
                highestScore={highestScore}
              />
            </EnlargePopup>
          </div>
        </GraphPopup>
      ) : (
        ''
      )}
      {isFirstLogin ? (
        <DashboardWelcomePopup isOpen={isOpen} onClose={onClose} onOk={onOk} />
      ) : (
        <></>
      )}
      <ExtendedDashboardMainWrapper className='containerClass'>
        <div className='left-container'>
          <LeftMenu />
        </div>
        <div className='right-container'>
          <MainHeader />
          <div className='dd-survey'>
            <SurveyDropDown
              // isRole={isRole}
              latestSurveyOptions={surveyOptions}
              latestSurveyOption={latestSurveyOption}
              handleSurveyValueChange={handleSurveyValueChange}
            />
          </div>
          {/* <AssistanceWrapper>
                        <div className='dashboard-school-name'>
                            {setTextLengthLimit(schoolName)}
                            <span>Staff Wellbeing Overview</span>
                        </div>
                        <div className='btn-request-assistance'> 
                            <RequestAssistanceButton />
                        </div>
                    </AssistanceWrapper> */}
          <Grid container spacing={2}>
            {activeSurveyDetails !== null &&
              activeSurveyDetails.surveyToken === null && (
                <div className='dashboard-ui-blocked'>
                  <>
                    <h1 className='survey-in-progress-message'>
                      Please wait whilst we populate your dashboard. Depending
                      on the number of schools, this can take up to 5 minutes.
                    </h1>
                    <div id='loading'></div>
                  </>
                </div>
              )}
            <Grid
              item
              xl={showComments ? 9 : 12}
              lg={showComments ? 8 : 12}
              md={12}
              xs={12}
            >
              <div className='inner-left-container'>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <div
                      className='active-survey-wrapper graph-wrapper'
                      id='pregress-graph'
                    >
                      <h2>Progress</h2>{' '}
                      <span className='large-graph-btn' onClick={showGraph}>
                        Enlarge
                      </span>
                      <ProgressGraph
                        progressGraphData={ProgressGraphData}
                        highestScore={highestScore}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <ScoreTiles
                      strongestScore={strongestScore}
                      weakestScore={weakestScore}
                      IsHeatMap={
                        isHeatMap ||
                        activeSurveyDetails === null ||
                        activeSurveyDetails === ''
                      }
                      isActionAllowed={isActionAllowed}
                      isQuestionBankAvailable={isQuestionBankAvailable}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <div className='tiles-wrapper'>
                      <div className='inner-main'>
                        <div className='active-survey-wrapper-inner '>
                          <ActiveSurvey
                            activeSurveyDetails={activeSurveyDetails}
                            score={strongestScore}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className='upcoming-task-wrapper'>
                      <UpcomingTasks actionResults={actionResults} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {newCommentCount > 0 ? (
              <Grid item xl={3} lg={4} md={12} xs={12} zeroMinWidth>
                <DashboardCommentsWrapper>
                  <div className='inner-right-container multi-survey-outer'>
                    <h2>Latest Comments</h2>
                    {newCommentCount > 0 ? (
                      <div className='new-dashboard-comments'>
                        You have a new reply!{' '}
                      </div>
                    ) : (
                      <></>
                    )}

                    <Scrollbars
                      renderThumbVertical={(props) => (
                        <div {...props} className='thumb-vertical' />
                      )}
                      className='scrollbar-view'
                    >
                      <div className='comments-outer'>
                        <Comments
                          comments={comments}
                          noCommentsMessage='No comments'
                        />
                      </div>
                    </Scrollbars>
                    {/* <Carousel
                                        autoPlay={false}
                                        showThumbs={false}
                                        showIndicators={false}
                                        showStatus={true}
                                        selectedItem={0}>
                                        <Comments
                                            comments={comments}
                                            noCommentsMessage='No comments'
                                        />
                                    </Carousel> */}

                    <div className='multi-survey-see-all-btn'>
                      <button onClick={clickHandler}>See All Comments</button>
                    </div>
                  </div>
                </DashboardCommentsWrapper>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </div>

        <div className='header-text'>{showUpgradeMsg}</div>
      </ExtendedDashboardMainWrapper>
    </div>
  );
}
