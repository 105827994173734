import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu } from '../../components';
import WelbeeReply from './WelbeeReply';
import { AssistanceWrapperHeading, ConversationBody } from '../../resources/styling/appStyle';
import { ExtendedAssistanceWrapper, ExtendedVoiceDashboardWrapper } from './WelbeeVoiceStyle';

class HeadteacherVoiceConversation extends Component {
  render() {
    return (
      <ExtendedVoiceDashboardWrapper>
        <LeftMenu />
        <div className='content-wrapepr'>
          <ExtendedAssistanceWrapper>
            <AssistanceWrapperHeading>
              <span className='comment-heading'>Welbee voice</span>
              <span> conversation list</span>
            </AssistanceWrapperHeading>
          </ExtendedAssistanceWrapper>
          <ConversationBody>
            <div className='content-body-inner-wrapper voice-body-inner-wrapper'>
              <WelbeeReply />
            </div>
          </ConversationBody>
        </div>
      </ExtendedVoiceDashboardWrapper>
    );
  }
}

export default connect(null, null)(withRouter(HeadteacherVoiceConversation));
