import React from 'react';
import { colors } from '../../resources/theme';
import { AppLogo } from '../../components';
import { limitAverageValue } from '../../utils/roundScore';
import {  QuestionCategoryResultsStyle, CircleStyle, SliderMainWrapper } from './SurveyReportPresentationStyle';
import {useTranslation} from 'react-i18next';

const QuestionCategoryResults = ({
  category,
  title,
  content,
  result,
  schoolDecile,
  id
}) => {
  const {t} =useTranslation();
  return (
    <QuestionCategoryResultsStyle
      className='survey-category-slide question-category-result'
      id={id}
    >
      <div className='question-category-results-header'>
        <span className='small-title'>{category}</span>
        <h1 className='big-title'>{title}</h1>
      </div>
      <div className='question-category-results-body'>
        <SliderMainWrapper>
          <CircleStyle className='circle' backgroundColor={colors.pictonBlue}>
            <div className='circle-text'>
              <span className='decile-type'>
                {limitAverageValue(result.OverallCategoryAverage)}
              </span>
              <span>Our Score</span>
            </div>
          </CircleStyle>

          <CircleStyle className='circle' backgroundColor={colors.pictonBlue}>
            <div className='circle-text'>
              <span className='decile-type'>
                {limitAverageValue(result.AverageScore)}
              </span>
              <span>Average Score</span>
            </div>
          </CircleStyle>

          <CircleStyle className='circle' backgroundColor={colors.pictonBlue}>
            <div className='circle-text'>
              <span className='decile-type'>{schoolDecile} </span>
              <span>School Decile</span>
            </div>
          </CircleStyle>

          <CircleStyle className='circle' backgroundColor={colors.pictonBlue}>
            <div className='circle-text'>
              <span className='decile-type'>
                {result.Score.HseDecilesCategoryAverage}{' '}
              </span>
              <span>HSE Decile</span>
              <span className='all-organisations'>All {t('organisations')}</span>
            </div>
          </CircleStyle>
        </SliderMainWrapper>
        <AppLogo type='result' color={colors.blackColor} width='100' />
      </div>
    </QuestionCategoryResultsStyle>
  );
};

export default QuestionCategoryResults;
