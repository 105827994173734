import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DashboardCommentsWrapper } from '../../resources/styling/appStyle';
import BackdropLoader from './BackdropLoader';

const Overlay = styled(DashboardCommentsWrapper)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

class AppLoader extends Component {
  render() {
    const { isDataLoading } = this.props;
    return (
      <div>
        {isDataLoading ? (
          <BackdropLoader isShow={true}></BackdropLoader>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loader }) => {
  const { isDataLoading } = loader;
  return {
    isDataLoading,
  };
};

export default connect(mapStateToProps, {})(AppLoader);
