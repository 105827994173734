import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils';
import { HeadTeacherAccess } from '../../utils/Constants';
import SchoolQuestionForm from './AdditionalQuestionForm';
import AdditionalQuestionLikertForm from './AdditionalQuestionLikertForm';
import { navigationActions } from '../../store/headerStore';
import AddSchoolQuestion from './AddSchoolQuestion';
import {
  storageService,
  surveyService,
  additionalSurveyQuestionService,
} from '../../services';
import { parseJwt } from '../../utils/CommonFunctions';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  ExtendedSubContainer,
  QuestionsInnerWrapper,
} from './AdditionalQuestionStyle';

/*** Table Style ***/
const purple = {
  50: '#45338C',
  100: '#45338C',
};

const Root = styled('div')(
  ({ theme }) => `
	table {	  
	  font-size: 14px !important;
	  border-collapse: collapse;
	  width: 100%;
	  font-weight: 500 !important;
	  margin-top: 15px;
	  margin-bottom: 0 !important;
	}
  
	td,
	th {	  	 
	  padding: 8px !important; 
	  font-size: 14px !important; 
    text-align: left;
	}

	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
	}
  
	th {
	  background-color: ${theme.palette.mode === 'dark' ? purple[50] : purple[100]};
	  color: ${theme.palette.common.white};
	}
	`
);

class SurveyAdditionalQuestions extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);

    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      createDialogOpen: false,
      surveyQuestionsData: [],
      editRecord: null,
      isEdit: false,
      isLikertEdit: false,
      accessLevel: 0,
      surveyId: null,
      schoolId: 0,
    };
  }

  componentDidMount() {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const token = window.location.search.split('?token=')[1];
    this.props.navigationActions.activeItemAction({
      item: MenuItems.QuestionBank,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    this.setState({ accessLevel: accessLevel });
    if (token) {
      this.getSurveyQuestions(token);
      this.getQuestionBank(token);
      this.getSurveyInfo(token);
    }
  }

  getSurveyQuestions(token) {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    additionalSurveyQuestionService
      .getSurveyQuestions(token)
      .then((response) => {
        if (response.success) {
          this.setState({ surveyQuestionsData: response.data });
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }

  getQuestionBank(token) {
    additionalSurveyQuestionService
      .getQuestionBank(token)
      .then((response) => {
        if (response.success) {
          this.setState({ questionBankOptions: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSurveyInfo(token) {
    surveyService
      .getSurveyByToken(token)
      .then((response) => {
        if (response.success) {
          let todayDate = new Date();
          let surveyStartDate = new Date(response.data.StartDate);
          let isSurveyInProgress = todayDate >= surveyStartDate;

          this.setState({
            schoolId: response.data.SchoolId,
            surveyId: response.data.Id,
            isSurveyProgressing: isSurveyInProgress,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setSurveyQuestions(question) {
    additionalSurveyQuestionService
      .setSurveyQuestions(question)
      .then((response) => {
        if (response.success) {
          this.setState({ surveyQuestionsData: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteSurveyQuestion(question) {
    additionalSurveyQuestionService
      .deleteSurveyQuestions(question)
      .then((response) => {
        if (response.success) {
          this.setState({
            surveyQuestionsData: response.data.surveyQuestions,
            questionBankOptions: response.data.questionBankOption,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCreateQuestion = () => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create survey first.')
      : this.setState({
          createAddQuestionFormOpen: true,
          editRecord: null,
          isEdit: false,
        });
  };

  handleAddQuestionSubmit = (question) => {
    this.setSurveyQuestions(question);
    this.setState({
      createAddQuestionFormOpen: false,
      editLikertQuestionFormOpen: false,
    });
  };

  addQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ createAddQuestionFormOpen: false });
  };

  addLikertQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ editLikertQuestionFormOpen: false });
  };

  handleQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      createAddQuestionFormOpen: true,
      isEdit: true,
    });
  };

  handleLikertQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      editLikertQuestionFormOpen: true,
      isLikertEdit: true,
    });
  };

  handleQuestionDelete = (question) => {
    this.deleteSurveyQuestion(question);
  };

  render() {
    const {
      createAddQuestionFormOpen,
      editLikertQuestionFormOpen,
      surveyQuestionsData,
      editRecord,
      isEdit,
      isLikertEdit,
      accessLevel,
      surveyId,
      schoolId,
    } = this.state;

    return (
      <div>
        <ExtendedSubContainer>
          <div className='search-data-container'>
            <div className='search-inner-container'>
              {surveyQuestionsData.length > 0 ? (
                <div className='search-inner-sub-container'>
                  <h2>Additional Survey Questions</h2>
                  <Root>
                    <table>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Question Type</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveyQuestionsData.map((question, key) => (
                          <tr key={key}>
                            <td style={{ width: '80%' }}>
                              {question.QuestionText}
                            </td>
                            <td className='action-category'>
                              {question.TypeDescription}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-evenly',
                                }}
                              >
                                <Tooltip
                                  title='Delete question'
                                  aria-label='add'
                                >
                                  <DeleteIcon
                                    style={{ color: '#E84F6B' }}
                                    onClick={() => {
                                      if (
                                        accessLevel === HeadTeacherAccess.Full
                                      ) {
                                        this.handleQuestionDelete(question);
                                      }
                                    }}
                                    sx={{ fontSize: '20px' }}
                                  />
                                </Tooltip>

                                {accessLevel === HeadTeacherAccess.Full ? (
                                  question.Category !== 11 ? (
                                    <Tooltip
                                      title='Edit question'
                                      aria-label='add'
                                    >
                                      <EditIcon
                                        color='primary'
                                        onClick={() => {
                                          if (question.Category === 10) {
                                            this.handleQuestionEdit(question);
                                          } else {
                                            this.handleLikertQuestionEdit(
                                              question
                                            );
                                          }
                                        }}
                                        sx={{ fontSize: '20px' }}
                                      />
                                    </Tooltip>
                                  ) : null
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Root>
                </div>
              ) : (
                <QuestionsInnerWrapper>
                  <div className='questions-not-available'>
                    No Additional Questions choosen for current surevey.
                  </div>
                </QuestionsInnerWrapper>
              )}
            </div>
          </div>
        </ExtendedSubContainer>

        {createAddQuestionFormOpen ? (
          <SchoolQuestionForm
            isOpen={createAddQuestionFormOpen}
            onClose={this.addQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isEdit}
            className={'xyz'}
            schoolId={schoolId}
            surveyId={surveyId}
          />
        ) : null}

        {editLikertQuestionFormOpen ? (
          <AdditionalQuestionLikertForm
            isOpen={editLikertQuestionFormOpen}
            onClose={this.addLikertQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isLikertEdit}
            className={'xyz'}
            schoolId={schoolId}
            surveyId={surveyId}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SurveyAdditionalQuestions));
